/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { h600, m100 } from 'src/components/Typography/fonts';
import { ReactComponent as NavNext } from '../nav_next.svg';
import { ReactComponent as NavPrev } from '../nav_prev.svg';

export const Section = styled.section`
	width: 100%;
	background-color: var(--date-picker-background);
	padding: 16px;
	border-bottom-left-radius: var(--simple-border-radius);
	border-bottom-right-radius: var(--simple-border-radius);
`;

export const MonthContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	row-gap: 12px;
`;

export const MonthButton = styled.button`
	${m100}
	font-size: 13px;
	color: var(--neutral-300);
	text-transform: capitalize;
	min-width: 73px;
	min-height: 37px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--simple-border-radius);
	user-select: none;

	&:hover {
		background-color: var(--date-picker-hover-background);
		color: var(--text-color-default);
	}

	&.selected {
		color: var(--text-color-default);
	}
	&.selected.selected__0.selected__2 {
		border-radius: var(--simple-border-radius);
	}
	&.selected__0 {
		background-color: var(--date-picker-active-background);
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	&.selected__1 {
		background-color: var(--date-picker-active-range-background);
		border-radius: 0;
	}
	&.selected__2 {
		background-color: var(--date-picker-active-background);
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	&.blocked {
		pointer-events: none;
		opacity: 0.6;
	}
`;

export const Nav = styled.div`
	height: 32px;
	margin: 0 16px 16px;
	display: grid;
	grid-template-columns: 32px 1fr 32px;
	align-items: center;

	p {
		${h600}
		font-size: 16px;
		text-align: center;
		color: var(--text-color-default);
		margin: 0;
	}
`;

export const NavButton = styled.button`
	width: 32px;
	height: 32px;
	border-radius: var(--default-border-radius);
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--neutral-700);
	color: var(--neutral-300);

	&:hover {
		background: var(--neutral-300);
		color: white;
	}
`;

export const NavPrevButton = styled(NavButton).attrs({
	type: 'button',
	children: <NavPrev />,
})``;

export const NavNextButton = styled(NavButton).attrs({
	type: 'button',
	children: <NavNext />,
})`
	align-self: flex-end;
`;
