/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { OrderType } from 'src/constants/contract';
import { useOrderDetails } from './useOrderDetails';
import { useSelector } from 'react-redux';
import { getUserCompanyName } from 'src/_store/selectors';
import { useTranslation } from 'react-i18next';

export const getCounterpartyLabel = (t, order) => {
	const isMyOrder = order.is_my_order;
	const orderType = order.order_type;

	if (!isMyOrder) {
		return orderType === OrderType.Sell ? t('seller') : t('buyer');
	}

	return orderType !== OrderType.Sell ? t('seller') : t('buyer');
};

export const useSellerBrokerBuyer = (order, counters = []) => {
	const { t } = useTranslation();
	const { isMyOrder, isBrokerOrder } = useOrderDetails(order);
	const currentUserCompanyName = useSelector(getUserCompanyName);

	const isSellOrder = order.order_type === OrderType.Sell;

	const orderWithCounters = [order, ...counters];
	const lastCounter = orderWithCounters.at(-1);

	const principal = lastCounter.order_owner_principal;
	const principalCompanyName = principal.company?.name || '---';
	const isPrincipalHidden = principal.hidden;
	const principalCompanyDisplayName = isPrincipalHidden ? t('hidden') : principalCompanyName;

	const counterpartyCompanyName =
		lastCounter.counter_order_user_company?.name || currentUserCompanyName;

	const getSellerCompany = () => {
		if (isSellOrder) {
			return principalCompanyDisplayName;
		}

		return counterpartyCompanyName;
	};

	const getBuyerCompany = () => {
		if (!isSellOrder) {
			return principalCompanyDisplayName;
		}

		return counterpartyCompanyName;
	};

	const getBrokerCompany = () => {
		if (!isBrokerOrder) {
			return null;
		}

		if (isMyOrder) {
			return currentUserCompanyName;
		}

		return lastCounter.order_user_company?.name || order.company?.name;
	};

	return {
		sellerCompany: getSellerCompany(),
		buyerCompany: getBuyerCompany(),
		brokerCompany: getBrokerCompany(),
	};
};

export const getOrderNegotiationTradeLink = (t, order = {}, isCounter) => {
	const locationOrigin = window.location.origin;

	const {
		_key: orderId,
		order_id: counterOrderId,
		environment: orderEnvironment,
		first_counter_id: negotiationId,
		trade_id: tradeId,
	} = order;

	if (isCounter) {
		if (tradeId) {
			return {
				label: t('trade_id'),
				id: tradeId,
				link: `${locationOrigin}/execution/trade/${tradeId}`,
			};
		}

		return {
			label: t('negotiation_id'),
			id: negotiationId,
			link: `${locationOrigin}/?orderEnvironment=${orderEnvironment}&orderId=${counterOrderId}&negotiationId=${negotiationId}`,
		};
	}

	return {
		label: t('order_id'),
		id: orderId,
		link: `${locationOrigin}/?orderEnvironment=${orderEnvironment}&orderId=${orderId}`,
	};
};
