/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { SlideAttachment } from './constants';

export const config = {
	version: 1,
	slides: [
		{
			selector: '#market-switch-toggle',
			id: 'markets',
			attachment: SlideAttachment.BottomLeft,
		},
		{
			selector: '#markets-filters',
			id: 'filters',
			attachment: SlideAttachment.BottomLeft,
		},
		{
			selector: '#markets_table',
			id: 'exchange',
			attachment: SlideAttachment.TopLeftInner,
		},
		{
			selector: '#my_activities',
			id: 'my_activities',
			attachment: SlideAttachment.TopLeft,
		},
		{
			selector: '#new-order-button',
			id: 'create_order',
			attachment: SlideAttachment.BottomRight,
		},
		{
			selector: '#chart-card',
			id: 'chart',
			attachment: SlideAttachment.Left,
		},
		{
			selector: '#forex-card',
			id: 'forex',
			attachment: SlideAttachment.Left,
		},
	],
};
