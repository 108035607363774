/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useLocation } from 'react-router';
import { DrawerVisibleOnPathnames } from '../Drawer/constants';

export const useShouldRenderDrawer = () => {
	const { pathname } = useLocation();

	const routeMatch = DrawerVisibleOnPathnames.includes(pathname);

	return routeMatch;
};
