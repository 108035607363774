/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { useTradeDataContext } from 'src/containers/ExecutionDetails/Paper/TradeDataContext';
import { ContactType } from 'src/constants/contacts';
import { getMyContacts } from 'src/_api';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { PaperTradeStep } from 'src/constants/tradeStatus';
import {
	mapTradeDataToStringTable,
	mapStringDataToStringTable,
} from 'src/containers/ExecutionDetails/helpers/paper/strings';
import { useStringColumns } from 'src/containers/ExecutionDetails/helpers/paper/useStringColumns';
import { StringRow } from './StringCells';
import * as Styled from './styled';

export const StringsTable = ({ stringData }) => {
	const userId = useSelector(getUserId);
	const { tradeStep, tradeId, tradeData, isLoadingTradeString } = useTradeDataContext();

	// Note: according to the PT-834 task we don't want to completely remove the message, just hide it for now
	const canShowInfoMessage = false;
	const tStringMessage = 'TODO';

	const stringUserIds = stringData
		? stringData.map(s => s.counterparty._key).filter(id => id !== userId)
		: [...tradeData.tradeUsersIds].filter(id => id !== userId);

	const { data: contacts = [] } = useQuery(['my-contacts'], () =>
		getMyContacts({
			types: Object.values(ContactType),
			user_id: stringUserIds,
			_limit: stringUserIds.length,
		})
	);

	const tableItems =
		tradeStep === PaperTradeStep.PaperNoString
			? mapTradeDataToStringTable(tradeData)
			: mapStringDataToStringTable(stringData, tradeId);
	const columns = useStringColumns({
		tradeId,
		contacts,
		tradeStep,
		circleTrade: !!tableItems?.[0]?.circleTrade,
	});

	if (isLoadingTradeString) {
		return (
			<Styled.StringWrapper>
				<Loader active inline="centered" size="large" />
			</Styled.StringWrapper>
		);
	}

	return (
		<Styled.StringChatWrapper>
			<Styled.StringWrapper>
				<Styled.OverflowWrapper>
					{canShowInfoMessage && (
						<Styled.StringMessage>
							<InfoIcon />
							<p>{tStringMessage}</p>
						</Styled.StringMessage>
					)}
					<Styled.StringDataTable
						data-test="paper-trade-string-table"
						columns={columns}
						items={tableItems}
						sortable={false}
						selectable={false}
						Row={StringRow}
					/>
				</Styled.OverflowWrapper>
			</Styled.StringWrapper>
			<Styled.ChatFooter />
		</Styled.StringChatWrapper>
	);
};
