/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { IncoType } from 'src/constants/contract';
import {
	PhysicalStepsPath,
	PhysicalFOBFASSteps,
	PhysicalCIFCFRSteps,
} from 'src/constants/physicalTrade';
import { useTradeDataContext } from 'src/containers/ExecutionDetails/Physical/TradeDataContext';
import { ExecutionStepItem } from '../ExecutionStepItem/ExecutionStepItem';
import { StepsInfoBox } from '../StepsInfoBox/StepsInfoBox';
import { getCompletedStepProgress } from './getCompletedStepProgress';
import { ActivityComponent } from '../ActivityComponent/ActivityComponent';
import { ExecutionMessagesFooter } from '../../../../../components/MessagesFooter/MessagesFooter';
import * as Styled from './styled';

export const ExecutionContent = ({ setCompleteButtonDisabled, shouldShowStickyFooter }) => {
	const { pathname } = useLocation();
	const { tradeData, completedSteps } = useTradeDataContext();

	const isFOBorFASinco = [IncoType.FOB, IncoType.FAS].includes(tradeData.inco_id);
	const steps = isFOBorFASinco ? PhysicalFOBFASSteps : PhysicalCIFCFRSteps;

	const isStepActive = stepPath => pathname.includes(stepPath);

	return (
		<>
			<Styled.StepsList $spanColumn={!shouldShowStickyFooter}>
				<Styled.ExecutionSteps>
					{steps.map((step, index) => {
						return (
							<Link to={PhysicalStepsPath[step]} key={index}>
								<ExecutionStepItem
									isActive={isStepActive(PhysicalStepsPath[step])}
									stepProgress={getCompletedStepProgress(
										step,
										completedSteps,
										tradeData
									)}
									step={step}
								/>
							</Link>
						);
					})}
				</Styled.ExecutionSteps>
				<StepsInfoBox />
			</Styled.StepsList>
			<Styled.StepBody $spanColumn={!shouldShowStickyFooter}>
				<div>
					<Outlet context={setCompleteButtonDisabled} />
				</div>
			</Styled.StepBody>
			<Styled.RightColumnWrapper>
				<Styled.ActivityLogWrapper>
					<ActivityComponent />
				</Styled.ActivityLogWrapper>
				<ExecutionMessagesFooter />
			</Styled.RightColumnWrapper>
		</>
	);
};
