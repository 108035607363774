/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo } from 'react';
import { useMyGroupsQuery } from 'src/_api/queries';
import { sortOrder } from 'src/_helpers/sort';

export const useFilteredGroupsList = (selectedGroupIds, filter, usersToHide, userCompanyId) => {
	const { data: { items = [] } = {}, isLoading, isError } = useMyGroupsQuery(
		{
			_sort: 'name',
			_order: sortOrder.asc,
			_limit: 1000,
		},
		{
			keepPreviousData: true,
		}
	);

	const mappedGroups = useMemo(() => {
		return items
			.map(group => {
				const users = [
					...group.users
						.filter(user => !usersToHide.includes(user.contact_user_id))
						.map(user => ({ ...user, isSelected: false })),
				];

				if (!users.length) {
					return undefined;
				}

				return {
					...group,
					lowerCaseName: group.name.toLowerCase(),
					externalUsersCount: users.filter(
						user => !!user.company_id && user.company_id !== userCompanyId
					).length,
					users,
				};
			})
			.filter(Boolean);
	}, [items, usersToHide, userCompanyId]);

	const filteredAndSortedGroups = useMemo(() => {
		const lowerCaseFilter = filter.toLowerCase();

		return (
			[...mappedGroups]
				.filter(group => {
					if (!filter) {
						return group;
					}

					return group.lowerCaseName.includes(lowerCaseFilter);
				})
				.sort((groupA, groupB) => {
					if (selectedGroupIds.includes(groupA._key)) {
						return -1;
					}

					if (selectedGroupIds.includes(groupB._key)) {
						return 1;
					}

					return groupA.lowerCaseName.localeCompare(groupB.lowerCaseName);
				}) || []
		);
	}, [mappedGroups, filter, selectedGroupIds]);

	return {
		filteredAndSortedGroups,
		isLoading,
		isError,
	};
};
