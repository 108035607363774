/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmailFormatValid } from 'src/_helpers/email';
import { ResetEmailForm } from './ResetEmailForm';
import { ROUTES } from 'src/constants/routes';
import { useMutation } from 'react-query';
import { resetUserPasswordRequestEmail } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useTranslation } from 'react-i18next';

export const ResetEmail = () => {
	const { state } = useLocation();
	const [email, setEmail] = useState(state?.resetPasswordEmail || '');
	const [isSubmitted, setSubmitted] = useState(false);
	const navigate = useNavigate();
	const { addToast } = useToast();
	const { t } = useTranslation();

	const handleEmailChange = e => setEmail(e.target.value);
	const handleBackClick = () => navigate(-1);

	const { mutate: resendPasswordResetEmail } = useMutation(
		() => resetUserPasswordRequestEmail({ email }),
		{
			onSuccess: () => {
				navigate(`${ROUTES.sendPasswordResetEmail}?email=${encodeURIComponent(email)}`);
			},
			onError: () => {
				addToast({ kind: 'error', message: t('something_went_wrong'), topPage: true });
			},
		}
	);

	const handleSubmit = e => {
		e.preventDefault();

		setSubmitted(true);

		if (isEmailFormatValid(email)) {
			resendPasswordResetEmail();
		}
	};

	return (
		<ResetEmailForm
			email={email}
			isSubmitted={isSubmitted}
			onSubmit={handleSubmit}
			onBackClick={handleBackClick}
			onEmailChange={handleEmailChange}
		/>
	);
};
