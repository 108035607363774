/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { formatValidityShort } from 'src/_helpers/date';
import {
	tTimeOptions,
	mapApiTimeValue,
	formatTradingSessionWithFullDate,
} from 'src/_helpers/timeRange';
import { useViewOrderDrawerContext } from 'src/components/ViewOrderDrawer/ViewOrderDrawerProvider';
import * as Styled from './styled';

export const CounterPreviewFooter = ({ onSubmit, isSubmitting }) => {
	const { t } = useTranslation();

	const {
		[DrawerContextKeys.viewDrawer]: { counterPreviewData, setCounterPreviewData },
	} = useDrawerContext();

	const {
		orderWithdrawn,
		orderEditedWithoutNegotiation,
		negotiationsWithdrawn,
	} = useViewOrderDrawerContext();

	const hasOrderUpdated =
		orderWithdrawn || orderEditedWithoutNegotiation || negotiationsWithdrawn;

	const validityOption = counterPreviewData.validity_option;
	const validityTime = validityOption ? mapApiTimeValue(validityOption) : null;

	return (
		<Styled.DrawerFooter>
			<Styled.Validity>
				<label>
					{validityOption && validityTime ? t('validity') : t('validity_local_time')}
				</label>
				<div>
					{validityOption
						? validityTime
							? tTimeOptions(t, validityTime.minutes || validityTime.hours * 60)
							: formatTradingSessionWithFullDate(validityOption)
						: formatValidityShort(counterPreviewData.validity)}
				</div>
			</Styled.Validity>
			<Styled.BackButton
				onClick={() => setCounterPreviewData(undefined)}
				disabled={hasOrderUpdated}
			>
				{t('back')}
			</Styled.BackButton>
			<Styled.SubmitButton
				onClick={onSubmit}
				loading={isSubmitting}
				disabled={hasOrderUpdated}
			>
				{t('submit_counter')}
			</Styled.SubmitButton>
		</Styled.DrawerFooter>
	);
};
