/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { FieldContainer } from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { useCounterSpreadStrategy } from './useCounterSpreadStrategy';

export const CalendarSpreadForm = ({ path, lastCounter }) => {
	const { spreadCounterFields } = useCounterSpreadStrategy({ path, lastCounter });

	return (
		<div>
			<FieldContainer>{spreadCounterFields}</FieldContainer>
		</div>
	);
};
