/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { activityTypes } from 'src/constants/activityLogTypes';
import { ReactComponent as CheckCircle } from 'src/assets/icons/check-circle.svg';
import { ReactComponent as FileIcon } from 'src/assets/icons/file.svg';
import { ReactComponent as ShipIcon } from 'src/assets/icons/ship.svg';
import { ReactComponent as ShipReplaceIcon } from 'src/assets/icons/ship-replace.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'src/assets/icons/minus.svg';
import { ReactComponent as RequestIcon } from 'src/assets/icons/request.svg';
import { ReactComponent as ExchangeIcon } from 'src/assets/icons/exchange.svg';
import { ReactComponent as DoubleCheckIcon } from 'src/assets/icons/double_check.svg';

export const checkActivityTypeIcons = type => {
	switch (type) {
		case activityTypes.ACTIVITY_TYPE_MARK_SIGNATURES_COMPLETE:
		case activityTypes.ACTIVITY_TYPE_MARK_SHIPPING_ADVICE_COMPLETE:
		case activityTypes.ACTIVITY_TYPE_MARK_VESSEL_NOMINATION_COMPLETE:
		case activityTypes.ACTIVITY_TYPE_MARK_INVOICING_COMPLETE:
		case activityTypes.ACTIVITY_TYPE_MARK_TRADE_COMPLETE:
		case activityTypes.ACTIVITY_TYPE_MARK_SHIPPING_DOCUMENTS_COMPLETE:
		case activityTypes.ACTIVITY_TYPE_CLOSE_TRADE:
		case activityTypes.ACTIVITY_TYPE_MARK_COMPLETION_COMPLETE:
		case activityTypes.ACTIVITY_TYPE_MARK_CLOSE_COMPLETE:
		case activityTypes.ACTIVITY_TYPE_KYC_ACCEPTED_REQUEST:
		case activityTypes.ACTIVITY_TYPE_MARK_SHIPPING_TENDER_COMPLETE:
			return <CheckCircle />;
		case activityTypes.ACTIVITY_TYPE_LAYCAN_UPDATED:
		case activityTypes.ACTIVITY_TYPE_VESSEL_NAME_UPDATED:
		case activityTypes.ACTIVITY_TYPE_SHIPPING_ADVICE_UPDATED:
		case activityTypes.ACTIVITY_TYPE_BL_NOMINATION_UPDATED:
		case activityTypes.ACTIVITY_TYPE_ETA_DISCHARGE_PORT_UPDATED:
			return (
				<BlueIconWrapper>
					<ShipReplaceIcon />
				</BlueIconWrapper>
			);
		case activityTypes.ACTIVITY_TYPE_BILL_OF_LADING_UPDATED:
			return (
				<BlueIconWrapper>
					<StyledShipIcon />
				</BlueIconWrapper>
			);
		case activityTypes.UPLOADED_DOCUMENT:
		case activityTypes.UPLOADED_MULTIPLE_DOCUMENTS:
		case activityTypes.ACTIVITY_TYPE_KYC_UPLOADED_DOCUMENTS:
			return (
				<IconWrapper>
					<StyledFileIcon />
				</IconWrapper>
			);
		case activityTypes.ACTIVITY_TYPE_KYC_REMOVED_DOCUMENTS:
			return (
				<RedIconWrapper>
					<StyledRemovedFileIcon />
				</RedIconWrapper>
			);
		case activityTypes.ACTIVITY_TYPE_TRADE_ADD_USER:
			return (
				<BlueIconWrapper>
					<StyledPlusIcon />
				</BlueIconWrapper>
			);
		case activityTypes.ACTIVITY_TYPE_TRADE_REMOVE_USER:
			return (
				<RedIconWrapper>
					<MinusIcon />
				</RedIconWrapper>
			);
		case activityTypes.ACTIVITY_TYPE_DOCUMENTS_REQUESTED:
			return (
				<IconWrapper>
					<DocumentRequestedIcon />
				</IconWrapper>
			);
		case activityTypes.ACTIVITY_TYPE_NEW_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_NEW_DIRECT_ORDER_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_DELETED_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_DELETED_DIRECT_ORDER_TRADE_FUTURES_CONTRACT_DETAILS:
			return (
				<IconWrapper>
					<GrayExchangeIcon />
				</IconWrapper>
			);
		case activityTypes.ACTIVITY_TYPE_EXPIRED_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_APPROVED_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_PARTIAL_FILLED_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_EXECUTED_TRADE_FUTURES_CONTRACT_DETAILS:
			return (
				<BlueIconWrapper>
					<ExchangeIcon />
				</BlueIconWrapper>
			);
		case activityTypes.ACTIVITY_TYPE_TRADE_CREATED:
			return (
				<GreenIconWrapper>
					<DoubleCheckIcon />
				</GreenIconWrapper>
			);
		default:
			return <IconWrapper />;
	}
};

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	min-width: 32px;
	width: 32px;
	height: 32px;
	background: var(--neutral-600);
`;

const GrayExchangeIcon = styled(ExchangeIcon)`
	path {
		stroke: var(--neutral-300);
	}
`;

const GreenIconWrapper = styled(IconWrapper)`
	background: var(--green-400);

	svg {
		path {
			fill: var(--neutral-1000);
		}
	}
`;

const BlueIconWrapper = styled(IconWrapper)`
	background: var(--primary-400);

	.circle-out {
		stroke: white;
		full: white;
	}
`;

const RedIconWrapper = styled(IconWrapper)`
	background: var(--red-400);

	.circle-out {
		stroke: white;
		full: white;
	}
`;

const StyledShipIcon = styled(ShipIcon)`
	width: 15px;
`;

const StyledFileIcon = styled(FileIcon)`
	width: 15px;

	path {
		fill: var(--neutral-300);
	}
`;

const StyledRemovedFileIcon = styled(FileIcon)`
	width: 15px;

	path {
		fill: #fff;
	}
`;

const StyledPlusIcon = styled(PlusIcon)`
	rect {
		fill: #fff;
	}
`;

const DocumentRequestedIcon = styled(RequestIcon)`
	path {
		&:not(:last-of-type) {
			stroke: var(--neutral-300);
		}

		&:last-of-type {
			fill: var(--neutral-300) !important;
		}
	}
`;
