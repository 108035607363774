/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { WebsocketChannelType, PrivateWebsocketEventType } from 'src/constants/websockets';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { getMyContactIds } from 'src/_api/my-account.api';

export const MyContactsIdsContext = createContext({
	isMyContact: id => false,
});

export const MyContactsIdsContextProvider = ({ children }) => {
	const [contacts, setContacts] = useState([]);

	useQuery('my-contact-ids', getMyContactIds, {
		select: data => data.contacts_ids,
		onSuccess: list => setContacts(list),
	});

	const isMyContact = useCallback(userId => contacts.includes(userId), [contacts]);

	const contactRemovedCallback = useCallback(message => {
		const contactId = message?.data?.data?.contact_user_id;
		setContacts(list => list.filter(id => id !== contactId));
	}, []);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.ContactRemoved,
		contactRemovedCallback
	);

	const contactAddedCallback = useCallback(message => {
		const contactId = message?.data?.data?.contact_user_id;
		setContacts(list => [...list, contactId]);
	}, []);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.ContactAdded,
		contactAddedCallback
	);

	return (
		<MyContactsIdsContext.Provider
			value={{
				isMyContact,
			}}
		>
			{children}
		</MyContactsIdsContext.Provider>
	);
};

export const useMyContactIds = () => useContext(MyContactsIdsContext);
