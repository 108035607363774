/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DropdownToggleChild } from 'src/components/Dropdown/Dropdown';
import { useSettings } from '../useSettings';

export const ThemeSettingsList = ({ themeSettings }) => {
	const { t } = useTranslation();
	const { currentTheme, updateTheme } = useSettings();

	return (
		<>
			{themeSettings.map(option => (
				<StyledDropdownToggleChild
					key={option.key}
					name={option.key}
					active={option.key === currentTheme}
					className="dropdown-child"
					$hoverColors={[option.labels[0].color, option.labels[1].color]}
					text={
						<LabelWrapper>
							{option.labels.map(({ color, key }, index) => (
								<React.Fragment key={key}>
									<span
										className={`color-${index}`}
										style={option.key === currentTheme ? { color } : {}}
									>
										{t(key)}
									</span>
								</React.Fragment>
							))}
						</LabelWrapper>
					}
					onClick={() => updateTheme(option.key)}
				/>
			))}
		</>
	);
};

const LabelWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 42px;

	span + span {
		&:before {
			content: '•';
			color: white;
			margin: 0 4px;
		}
	}
`;

const StyledDropdownToggleChild = styled(DropdownToggleChild)`
	&:hover > div > .color-0 {
		color: ${({ $hoverColors }) => $hoverColors[0]};
	}

	&:hover > div > .color-1 {
		color: ${({ $hoverColors }) => $hoverColors[1]};
	}
`;
