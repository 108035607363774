/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { PaperTradeStep } from 'src/constants/tradeStatus';
import { AdminPaperNoString } from './AdminPaperNoString';
import { AdminPaperInitialString } from './AdminPaperInitialString';
import { AdminPaperFinalString } from './AdminPaperFinalString';
import * as Styled from './styled';

export const TradeAdminTab = ({ tradeId, tradeStep }) => {
	return (
		<Styled.Container>
			{tradeStep === PaperTradeStep.PaperNoString && <AdminPaperNoString tradeId={tradeId} />}
			{tradeStep === PaperTradeStep.PaperInitialString && (
				<AdminPaperInitialString tradeId={tradeId} />
			)}
			{tradeStep === PaperTradeStep.PaperFormingFinalString && (
				<AdminPaperFinalString tradeId={tradeId} />
			)}
		</Styled.Container>
	);
};
