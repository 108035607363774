/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { Controller } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import { QuoteType } from 'src/constants/contract';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';

const VolumeField = ({ path, t }) => {
	const { orderTypeValue } = useWatchPaperFields(path);

	return (
		<Controller
			mandatory={orderTypeValue === QuoteType.Firm}
			name={toPath(path, 'volume')}
			render={({ field, fieldState: { error } }) => (
				<MaterialNumberInput
					required={orderTypeValue === QuoteType.Firm}
					error={!!error}
					data-test={field.name}
					id={field.name}
					label={t('quantity')}
					fixedDecimalScale={false}
					parameters={t('price_unit_short_metric_ton')}
					isAllowed={value => value.floatValue !== 0}
					{...field}
				/>
			)}
		/>
	);
};

export const volumeField = (path, t) => <VolumeField path={path} t={t} />;
