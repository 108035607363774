/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useLocationStateParam } from 'src/_helpers/router';
import { OnboardingLayout } from 'src/components/OnboardingLayout/OnboardingLayout';
import { useToast } from 'src/components/Toast';
import {
	OnboardingFooter,
	OnboardingHeader,
	OnboardingInfo,
	OnboardingLink,
	OnboardingSeparator,
} from '../SendPasswordResetEmail/styled';
import { useMutation } from 'react-query';
import { resendVerificationEmail } from 'src/_api';
import { ROUTES } from 'src/constants/routes';

export const RegistrationCompleted = () => {
	const { t } = useTranslation();
	const email = useLocationStateParam('email');
	const { addToast } = useToast();

	const { mutate: resendConfirmationEmail } = useMutation(() => resendVerificationEmail(email), {
		onSuccess: () => {
			addToast({
				kind: 'success',
				message: t('email_has_been_sent_again'),
				topPage: true,
			});
		},
		onError: () => {
			addToast({ kind: 'error', message: t('something_went_wrong'), topPage: true });
		},
	});

	if (!email) {
		return <Navigate push to={ROUTES.root} />;
	}

	return (
		<OnboardingLayout>
			<OnboardingHeader data-test="thank-you-message">
				{t('thank_you_for_registering')}
			</OnboardingHeader>
			<OnboardingSeparator />
			<OnboardingInfo>
				<Trans i18nKey={'confrmation_email_sent_to_{{email}}'} values={{ email }}>
					<strong />
				</Trans>
			</OnboardingInfo>
			<OnboardingFooter>
				{t('resend_password_reset_email_info')}
				{t('havent_received_email')}{' '}
				<OnboardingLink onClick={resendConfirmationEmail}>
					{t('resend_email')}
				</OnboardingLink>
			</OnboardingFooter>
		</OnboardingLayout>
	);
};
