/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { H600 } from 'src/components/Typography/fonts';
import { VolumeDisplay } from 'src/components/Volume';
import { formatPrice } from 'src/_helpers/price';
import { parseDateStringAsUTC, formatDateRange } from 'src/_helpers/date';
import { PriceType } from 'src/constants/contract';
import { tPriceUnit } from 'src/constants/product';
import { UserStatus, NameContainer } from 'src/components/UserStatus/UserStatus';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { useSellerBrokerBuyer } from 'src/_helpers/order';
import * as Styled from './styled';

export const AcceptOutrightSummary = ({ order }) => {
	const { t } = useTranslation();

	const { isBrokerOrder, isSellOrder } = useOrderDetails(order);

	const { sellerCompany, buyerCompany, brokerCompany } = useSellerBrokerBuyer(order);

	const brokerLabel = `${t('broker')} (${isSellOrder ? t('seller') : t('buyer')})`;

	return (
		<>
			<Styled.OrderValueRow>
				<Styled.Label>{t('seller')}</Styled.Label>
				<Styled.CompanyName>
					<OverflowText>{sellerCompany}</OverflowText>
				</Styled.CompanyName>
			</Styled.OrderValueRow>
			{isBrokerOrder && (
				<Styled.OrderValueRow>
					<Styled.Label>{brokerLabel}</Styled.Label>
					<NameContainer>
						<UserStatus status={order?.user?.status} />
						<H600>{brokerCompany}</H600>
					</NameContainer>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('buyer')}</Styled.Label>
				<NameContainer>
					<UserStatus status={order?.user?.status} />
					<H600>{buyerCompany}</H600>
				</NameContainer>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('product')}</Styled.Label>
				<H600>{order?.product?.name}</H600>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{order?.runs ? t('total_quantity') : t('quantity')}</Styled.Label>
				<VolumeDisplay as={H600} value={order?.volume} />
			</Styled.OrderValueRow>
			{order?.price_type === PriceType.Basis && order.futures_contract_date && (
				<Styled.OrderValueRow>
					<Styled.Label>{t('futures_contract')}</Styled.Label>
					<H600>{`${order.futures_contract} ${moment(order.futures_contract_date).format(
						'MMM YYYY'
					)}`}</H600>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{tPriceUnit(t, order?.price_unit)}</Styled.Label>
				<H600>{formatPrice(order?.price)}</H600>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('shipment')}</Styled.Label>
				<H600>
					{formatDateRange({
						format: order.delivery_mode,
						startDate: parseDateStringAsUTC(order.delivery_date_from),
						endDate: parseDateStringAsUTC(order.delivery_date_to),
					})}
				</H600>
			</Styled.OrderValueRow>
		</>
	);
};
