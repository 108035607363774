/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserCompanyId } from 'src/_store/selectors';
import { CancelButton, ButtonsWrapper, ModalHeaderWrapper, ModalTitle } from 'src/components/Modal';
import { SearchableContactsList } from 'src/components/SearchableContactsList/SearchableContactsList';
import { ModalContent } from './CommonComponents';
import { GroupsSelector } from './GroupsSelector';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';

export const AddUsersToTradeModalContent = ({
	usersAlreadyInTrade,
	close,
	openSummaryModal,
	openGroupModal,
	selectedUsers,
	setSelectedUsers,
	selectedGroups,
	setSelectedGroups,
}) => {
	const { t } = useTranslation();
	const userCompanyId = useSelector(getUserCompanyId);

	return (
		<>
			<ModalHeaderWrapper data-test="add-users-to-trade-modal-content">
				<ModalTitle>{t('add_new_users')}</ModalTitle>
			</ModalHeaderWrapper>
			<ModalContent className="trade-users-content">
				<SearchableContactsList
					shouldReturnFullObject
					selectedUsers={selectedUsers}
					setSelectedUsers={setSelectedUsers}
					usersToHide={usersAlreadyInTrade}
					noResultsMessage={t('no_search_results')}
					usersLabel
					groupsContent={filter => (
						<GroupsSelector
							filter={filter}
							selectedGroups={selectedGroups}
							setSelectedGroups={setSelectedGroups}
							usersToHide={usersAlreadyInTrade}
							userCompanyId={userCompanyId}
							openGroupModal={openGroupModal}
						/>
					)}
				/>
			</ModalContent>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<PrimaryButton
					data-test="show-summary-of-adding-users-to-trade"
					disabled={selectedUsers.length === 0 && selectedGroups.length === 0}
					onClick={openSummaryModal}
				>
					{t('add_users')}
				</PrimaryButton>
			</ButtonsWrapper>
		</>
	);
};
