/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { RichTextBody } from 'src/containers/News/components/RichTextBody/RichTextBody';
import { media } from 'src/_helpers';
import { h400, h600, h800 } from 'src/components/Typography';

export const GuidebookContainer = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	height: 100%;
	overflow: auto;
	scroll-behavior: smooth;
	background-color: #e5e7ef;
	position: relative;
`;

export const TypeSelectorContainer = styled.div`
	width: fit-content;
	position: absolute;
	left: 400px;
	top: var(--page-h-padding);
`;

export const GuidebookContentContainer = styled.div`
	max-width: 800px;
	margin-left: 64px;
	padding-top: 120px;
	color: var(--neutral-1000);

	${media.ll`
		margin-left: 120px;
	`}
	${media.xl`
		margin-left: 240px;
	`}
`;

export const ChapterContainer = styled.div`
	margin-bottom: 120px;

	&:last-child {
		padding-bottom: 12px;
	}
`;

export const SubChapterContainer = styled.div`
	margin: 64px 0;
`;

const guidebookTextStyle = css`
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.2px;
	font-weight: 400;
`;

export const StyledRichTextBody = styled(RichTextBody)`
	color: var(--neutral-1000);

	p {
		${guidebookTextStyle}

		&.guidebook-image-paragraph {
			font-size: 14px;
			line-height: 18px;
			color: var(--neutral-300);
			margin: 32px 0 16px;

			&_with-caption {
				margin-bottom: 32px;
			}

			img {
				margin-bottom: 16px;
				width: 100%;
				height: auto;
				aspect-ratio: 16 / 9;
				object-fit: contain;
			}
		}
	}

	li {
		${guidebookTextStyle}
	}

	h1,
	h2 {
		color: var(--neutral-1000);
	}

	h2,
	h3,
	h4 {
		margin-bottom: 16px;
	}

	a {
		color: var(--primary-400);
	}

	iframe {
		margin: 16px 0 32px;
		min-height: 450px !important;
		background-color: black;
	}
`;

export const ChapterTitle = styled.h2`
	color: var(--neutral-1000);
	font-weight: var(--font-weight-regular);
	font-size: 40px;
	line-height: 47px;
	margin-bottom: 32px;
`;

export const SubChapterTitle = styled.h3`
	${h800}
	font-weight: var(--font-weight-bold);
	color: var(--neutral-1000);
	margin-bottom: 16px;
`;

const guidebookNavigationContainerWidth = css`
	width: 344px;

	${media.ll`
	width: 360px;
`}
`;

export const GuidebookNavigationContainer = styled.div`
	position: sticky;
	top: 0;
	overflow: auto;
	flex-shrink: 0;
	padding-top: 38px;
	margin-bottom: 68px;
	border-right: 1px solid var(--neutral-100);
	${guidebookNavigationContainerWidth}
`;

const ChapterTitleBaseLink = styled(Link)`
	display: inline-block;
	width 100%;
	transition: color 0.2s ease;
	color: ${({ $active }) => ($active ? 'var(--primary-400)' : 'var(--neutral-400)')};

	&:hover {
		color: var(--primary-400);
	}
`;

export const ChapterTitleLink = styled(ChapterTitleBaseLink)`
	${h600}
	font-weight: var(--font-weight-bold);
	padding: 10px 10px 10px 48px;
`;

export const SubChapterTitleLink = styled(ChapterTitleBaseLink)`
	${h400}
	${({ $active }) => $active && 'font-weight: var(--font-weight-bold);'}
	padding: 10px 10px 10px 64px;
`;

export const GuidebookNavigationActions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0;
	${guidebookNavigationContainerWidth}
	height: 68px;
	background-color: #e5e7ef;
	border-top: 1px solid var(--neutral-100);
	border-right: 1px solid var(--neutral-100);
`;
