/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { h200, h300 } from 'src/components/Typography';
import { Link } from 'src/components/Link/Link';

export const Wrapper = styled.div`
	padding: 4px;
	background: var(--neutral-900);
	color: var(--neutral-200);
	border-radius: 32px;
	display: flex;
`;

export const LightWrapper = styled.div`
	color: var(--neutral-300);
	display: flex;
	height: 25px;
`;

const basePill = css`
	background: none;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;

	&:hover {
		color: var(--text-color-default);
	}
`;

const pill = css`
	${basePill}
	${h300}
	padding: 6px 14px;
	border-radius: 32px;

	&.active {
		background: var(--purple-400);
		color: var(--text-color-default);
	}
`;

const lightPill = css`
	${basePill}
	${h200}
	font-size: 11px;
	padding: 6px 12px;
	height: 25px;
	border: 1px solid transparent;
	border-radius: var(--default-border-radius);
	filter: drop-shadow(0px 2px 30px rgba(66, 0, 255, 0.25));

	&.active {
		border-color: var(--primary-200) !important;
		color: var(--text-color-default);
	}
`;

export const PillLink = styled(Link)`
	${pill}
	height: 32px;
`;

export const Pill = styled.div`
	${pill}
	height: 28px;
`;

export const LightPillLink = styled(Link)`
	${lightPill}
`;

export const LightPill = styled.div`
	${lightPill}
`;
