/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';

const button = css`
	width: 142px;
	height: 36px;

	${media.ll`
		width: 160px;
	`}

	${media.xl`
		width: 190px;
		height: 40px;
	`}
`;

export const CancelButton = styled(SecondaryButton)`
	background: var(--neutral-600);
	&:hover {
		background: var(--neutral-500);
	}

	&.disabled {
		background: var(--neutral-700);
	}

	${button}
`;

export const PreviewButton = styled(PrimaryButton)`
	${button}

	&.disabled {
		background: var(--neutral-700);
	}
`;

export const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;

	${media.ll`
		gap: 16px;
	`}

	.text-input:has(+ &) {
		width: 160px;

		${media.xl`
			width: 190px;
		`}
	}
`;
