/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as ShieldIcon } from 'src/assets/icons/shield_alert_gradient.svg';
import { media } from 'src/_helpers';

export const PrimaryShieldIcon = styled(ShieldIcon)`
	width: 24px;
	height: 24px;
`;

export const InfoWrapper = styled.div`
	padding: 10px 12px;
	background: var(--info-message-gradient);
	border-radius: var(--large-border-radius);
	display: flex;
	align-items: center;
	gap: 12px;

	${media.xl`
		padding: 12px;
		gap: 16px;
	`}
`;
