/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { Tab as SemanticTab } from 'semantic-ui-react';
import { media } from 'src/_helpers';
import { h400, h300 } from 'src/components/Typography/fonts';

export const TradeDetailsWrapper = styled.div`
	padding-top: 31px;
	height: var(--app-fullsize-content-height);
	display: grid;
	grid-template-rows: 123px calc(100% - 123px);

	${media.ll`
		padding-top: 39px;
		grid-template-rows: 135px calc(100% - 135px);
	`}

	${media.xl`
		padding-top: 26px;
		grid-template-rows: 148px calc(100% - 148px);
	`}
`;

export const TradeDetailsBody = styled.div`
	display: flex;
	height: 100%;
	background-color: var(--neutral-900);
`;

export const Tab = styled(SemanticTab)`
	height: 100%;

	& > :nth-child(2) {
		overflow-y: scroll;
		height: calc(100% - 70px);
	}
`;

export const TabWrapper = styled.div`
	width: 500px;

	${media.xl`
		width: 850px;
	`}

	.ui.attached.tabular.menu {
		min-height: 36px;
		${h400}
		width: 100%;
		background: linear-gradient(270deg, var(--neutral-900) 0%, var(--neutral-1000) 100%);
		padding-left: 12px;

		${media.xl`
			min-height: 46px;
			padding-left: 22px
		`}

		.item {
			border-color: var(--neutral-900);
			${h300}

			${media.xl`
				${h400}
			`}
		}

		.active.item {
			position: relative;
			background: linear-gradient(
				0deg,
				rgba(55, 60, 217, 0) 0%,
				rgb(var(--primary-400-rgb), 0.1) 100%
			);
			border-color: transparent;
			background-color: var(--neutral-900);
		}
	}
`;
