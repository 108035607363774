/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Inspection, Role } from 'src/constants/contract';
import { TradeStringStatus } from 'src/constants/tradeStatus';
import { formatPriceWithoutCurrency } from 'src/_helpers/price';

export const mapStringDataToStringTable = (stringsData, tradeId) => {
	if (!stringsData) {
		return [];
	}

	return stringsData.map((item, index, rows) => {
		const isFirstSeller = index === 0;
		const isLastBuyer = index === rows.length - 1;

		const settlementSuggestions =
			item.settlement_suggestions_en || item.settlement_suggestions_cn
				? {
						en: item.settlement_suggestions_en || '',
						zh: item.settlement_suggestions_cn || '',
				  }
				: null;

		return {
			_key: `row-${index}`,
			type: isFirstSeller
				? TradeStringStatus.FirstSeller
				: isLastBuyer
				? TradeStringStatus.FinalBuyer
				: null,
			current: item.current,
			circleTrade: item.circle_trade,
			company: item.company.name,
			user: item.counterparty.name,
			buyPrice: item?.buy_price ? parseFloat(item.buy_price) : null,
			sellPrice: item?.sell_price ? parseFloat(item.sell_price) : null,
			settlement: item?.settlement
				? formatPriceWithoutCurrency(item.settlement, {
						style: 'decimal',
				  })
				: null,
			documentBypass: item.document_bypass,
			isCircleOut: item.is_circle_out,
			userStatus: item.counterparty.status,
			contactUserId: item.counterparty._key,
			settlementSuggestions,
			sellTrade:
				Object.keys(item.sell_trade || {}).length > 0
					? {
							tradeId: item.sell_trade.trade_id || '',
							price: item.sell_trade.price,
							isCurrentTrade: item.sell_trade.trade_id === tradeId,
					  }
					: {},
			isBroker: item.counterparty_role === Role.Broker,
		};
	});
};

export const mapTradeDataToStringTable = tradeData => {
	const sellerPrincipalDefined = tradeData.seller_user._key;
	const buyerPrincipalDefined = tradeData.buyer_user._key;

	const sellerUser = sellerPrincipalDefined
		? tradeData.seller_user
		: tradeData.seller_broker_info;
	const buyerUser = buyerPrincipalDefined ? tradeData.buyer_user : tradeData.buyer_broker_info;

	return [
		{
			_key: tradeData.seller_user_id,
			isUserFromGivenTradeSide: tradeData.isSellerSide,
			type: Inspection.Seller,
			company: sellerUser.company?.name,
			user: sellerUser.name,
			settlement: null,
			intention: tradeData.seller_intention,
			documentBypass: tradeData.seller_document_bypass,
			userStatus: sellerUser.status,
			contactUserId: sellerUser._key,
			sellTrade: {
				tradeId: tradeData._key,
				price: tradeData.price,
				isCurrentTrade: true,
			},
			isBroker: !!sellerUser.broker,
		},
		{
			_key: tradeData.buyer_user_id,
			isUserFromGivenTradeSide: tradeData.isBuyerSide,
			type: Inspection.Buyer,
			company: buyerUser.company?.name,
			user: buyerUser.name,
			buyPrice: tradeData.price,
			settlement: null,
			intention: tradeData.buyer_intention,
			documentBypass: tradeData.buyer_document_bypass,
			userStatus: buyerUser.status,
			contactUserId: buyerUser._key,
			sellTrade: {},
			isBroker: !!buyerUser.broker,
		},
	];
};
