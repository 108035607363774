/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ModalType } from 'src/constants/modal';
import { OrderType, Market, PaperInstruments } from 'src/constants/contract';
import { NegativeButton } from 'src/components/Buttons/NegativeButton';
import {
	ModalHeaderWrapper,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalTitle,
	ModalSubTitle,
	CancelButton,
} from 'src/components/Modal';
import { Checkbox } from 'src/designSystem/Form/Checkbox/Checkbox';
import { useOrderNegotiations } from 'src/_api';

export const WithdrawOrderModalContent = ({
	environment,
	market,
	instrument,
	orderType,
	orderId,
	checkActiveNegotiations,
	hasActiveNegotiations,
	shouldCloseNegotiations,
	setShouldCloseNegotiations,
	close,
	isLoading,
	withdrawOrder,
}) => {
	const { t } = useTranslation();

	const {
		data: negotiations = [],
		isError,
		isLoading: isLoadingNegotiations,
	} = useOrderNegotiations(
		{
			environment,
			orderId,
		},
		{
			enabled: !!checkActiveNegotiations && !!orderId,
		}
	);

	const hasLoadedNegotiations = !isLoadingNegotiations && !isError && negotiations.length > 0;

	const isSpread = market === Market.Paper && instrument === PaperInstruments.Spread;
	const isBid = orderType === OrderType.Buy;
	const subtitleKey = 'withdrawn_order_modal_subtitle';
	const checkboxKey = isSpread
		? 'close_negotiations_spread'
		: isBid
		? 'close_negotiations_bid'
		: 'close_negotiations_offer';

	const titleKey = isSpread
		? 'confirm_spread_withdraw'
		: isBid
		? 'confirm_bid_withdraw'
		: 'confirm_offer_withdraw';

	if (checkActiveNegotiations && isLoadingNegotiations) {
		return;
	}

	return (
		<div>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{t(titleKey)}</ModalTitle>
				<StyledModalSubTitle>
					<SubtitleWrapper>{t(subtitleKey)}</SubtitleWrapper>
					{(hasActiveNegotiations || hasLoadedNegotiations) && (
						<CloseNegotiationsCheckbox
							label={t(checkboxKey)}
							name="closeNegotiations"
							data-test="close-negotiations-checkbox"
							value={JSON.stringify(shouldCloseNegotiations)}
							onChange={() => setShouldCloseNegotiations(!shouldCloseNegotiations)}
						/>
					)}
				</StyledModalSubTitle>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<NegativeButton loading={isLoading} onClick={withdrawOrder}>
					{t('withdraw')}
				</NegativeButton>
			</ButtonsWrapper>
		</div>
	);
};

const SubtitleWrapper = styled.div`
	text-align: center;
	line-height: 21px;
`;

const CloseNegotiationsCheckbox = styled(Checkbox)`
	margin-top: 16px;

	&.ui.checkbox input:focus ~ label {
		color: var(--text-color-secondary);
	}
`;

const StyledModalSubTitle = styled(ModalSubTitle)`
	margin-bottom: 40px;
	width: 100%;
`;
