/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { fetchFromExchangeApi, map404ToEmptyList, map404ToEmptyObject } from './model';
import { articlesNewsCreateSchema } from './model/model.news';

export const createNewsArticle = async body => {
	try {
		await articlesNewsCreateSchema.validate(body);
		return await fetchFromExchangeApi(`forum/create/threads`, {
			method: 'POST',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const editNewsArticle = async body => {
	try {
		return await fetchFromExchangeApi(`forum/update/threads/${body._key}`, {
			method: 'POST',
			body,
		});
	} catch (error) {}
};

export const getNewsArticles = async query => {
	return await fetchFromExchangeApi('forum/list/threads', {
		method: 'GET',
		query,
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getArticlesList = async query => {
	const [requests, totalCount] = await Promise.all([
		getNewsArticles(query),
		getNewsArticles({ ...query, count: true }),
	]);

	return {
		items: requests,
		totalCount: totalCount.count,
	};
};

export const getPublicNewsCategories = async () => {
	const result = await fetchFromExchangeApi(
		'forum/list/forum_categories?_sort=order&_order=ASC',
		{
			method: 'GET',
			mapStatusToResult: map404ToEmptyList,
		}
	);

	return result.map(c => ({
		...c,
		name: c.name.toLowerCase(),
	}));
};

export const getNewsArticle = async ({ articleId }) => {
	const result = await fetchFromExchangeApi(`forum/read/threads/${articleId}`, {
		method: 'GET',
		mapStatusToResult: map404ToEmptyObject,
	});

	return { ...result };
};

export const removeNewsArticle = async ({ articleId }) => {
	try {
		return await fetchFromExchangeApi(`delete/threads/${articleId}`, {
			method: 'DELETE',
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};
