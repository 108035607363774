/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { h600, m100 } from 'src/components/Typography';

export const Section = styled.section`
	width: 100%;
	background-color: var(--date-picker-background);
	padding: 16px;
	min-height: 335px;
	border-bottom-left-radius: var(--big-border-radius);
	border-bottom-right-radius: var(--big-border-radius);
	${m100}
`;

export const PeriodContainer = styled.div`
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	row-gap: 12px;
`;

export const PeriodTitle = styled.div`
	grid-column-start: 1;
	grid-column-end: 5;
	display: flex;
	justify-content: center;
	align-items: center;
	${h600};
	color: var(--text-color-default);
`;

export const ExtendedPeriodTitle = styled(PeriodTitle)`
	margin: 30px 0;
`;

export const PeriodButton = styled.button`
	color: var(--neutral-300);
	white-space: nowrap;
	padding: 0 11px 0 10px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--simple-border-radius);

	&:hover {
		background-color: var(--neutral-300);
		color: var(--text-color-default);
	}

	&.selected {
		background: var(--date-picker-active-background);
		color: var(--text-color-default);
	}

	&.selected__0 {
		background-color: var(--date-picker-active-background);
		border-top-right-radius: var(--simple-border-radius);
		border-bottom-right-radius: var(--simple-border-radius);
	}
`;
