/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo } from 'react';
import { ModalSize, ModalType } from 'src/constants/modal';
import { mergeArrays } from 'src/_helpers/array';
import { StyledModal } from './CommonComponents';
import { UsersSummaryModalContent } from './UsersSummaryModalContent';

export const UsersSummaryModal = ({ tradeId, open, close, isOpen, getSummaryData, submit }) => {
	const { selectedUsers = [], selectedGroups = [] } = getSummaryData();

	const allUsers = useMemo(() => {
		return mergeArrays(
			selectedUsers,
			selectedGroups.flatMap(group => group.users.filter(user => user.isSelected)),
			'contact_user_id'
		);
	}, [selectedGroups, selectedUsers]);

	return (
		<StyledModal
			type={ModalType.Info}
			size={ModalSize.Tiny}
			close={close}
			isOpen={isOpen}
			open={open}
			closeOnDimmerClick={false}
			data-test="add-users-to-trade-summary-modal"
		>
			<UsersSummaryModalContent
				close={close}
				onSubmit={submit}
				selectedUsers={allUsers}
				tradeId={tradeId}
			/>
		</StyledModal>
	);
};
