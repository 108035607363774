/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { usePaperCounterSummary } from 'src/containers/Counter/PaperCounterForm/hooks/usePaperCounterSummary';
import { SummaryRow } from 'src/containers/Order/common/OrderSummaryModal/SummaryRow';
import { PaperInstruments } from 'src/constants/contract';
import { AcceptSpreadSummary } from 'src/containers/OrderDetails/sections/paper/AcceptSpreadSummary';

export const AcceptPaperCounterSummary = ({ order, counters = [] }) => {
	const orderAndCounters = [order, ...counters];
	const lastCounter = orderAndCounters.at(-1);

	const { summaryData } = usePaperCounterSummary({
		order,
		lastCounter,
	});

	if (order.instrument === PaperInstruments.Spread) {
		return <AcceptSpreadSummary order={order} counters={counters} />;
	}

	return summaryData.map(({ title, value }, index) => (
		<SummaryRow key={index} title={title} value={value} />
	));
};
