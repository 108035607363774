/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const EFPNotificationType = {
	NewTradeFuturesContractDetails: 'new_trade_futures_contract_details',
	NewDirectTradeFuturesContractDetails: 'new_direct_order_trade_futures_contract_details',
	DeletedTradeFuturesContractDetails: 'deleted_trade_futures_contract_details',
	DeletedDirectTradeFuturesContractDetails: 'deleted_direct_order_trade_futures_contract_details',
	ApprovedTradeFuturesContractDetails: 'approved_trade_futures_contract_details',
	PartialFilledTradeFuturesContractDetails: 'partial_filled_trade_futures_contract_details',
	ExecutedTradeFuturesContractDetails: 'executed_trade_futures_contract_details',
	ExpiredTradeFuturesContractDetails: 'expired_trade_futures_contract_details',
};

export const EFPNotificationTitle = {
	[EFPNotificationType.NewTradeFuturesContractDetails]: 'notification_new_efp_details',
	[EFPNotificationType.NewDirectTradeFuturesContractDetails]:
		'notification_new_direct_efp_details',
	[EFPNotificationType.DeletedTradeFuturesContractDetails]: 'notification_deleted_efp_details',
	[EFPNotificationType.DeletedDirectTradeFuturesContractDetails]:
		'notification_deleted_direct_efp_details',
	[EFPNotificationType.ApprovedTradeFuturesContractDetails]: 'notification_approved_efp_details',
	[EFPNotificationType.PartialFilledTradeFuturesContractDetails]:
		'notification_partial_filled_efp_details',
	[EFPNotificationType.ExecutedTradeFuturesContractDetails]: 'notification_executed_efp_details',
	[EFPNotificationType.ExpiredTradeFuturesContractDetails]: 'notification_expired_efp_details',
};

export const EFPNotificationText = {
	[EFPNotificationType.NewTradeFuturesContractDetails]: 'notification_new_efp_details_info',
	[EFPNotificationType.NewDirectTradeFuturesContractDetails]:
		'notification_new_direct_efp_details_info',
	[EFPNotificationType.DeletedTradeFuturesContractDetails]:
		'notification_deleted_efp_details_info',
	[EFPNotificationType.DeletedDirectTradeFuturesContractDetails]:
		'notification_deleted_direct_efp_details_info',
	[EFPNotificationType.ApprovedTradeFuturesContractDetails]:
		'notification_approved_efp_details_info',
	[EFPNotificationType.PartialFilledTradeFuturesContractDetails]:
		'notification_partial_filled_efp_details_info',
	[EFPNotificationType.ExecutedTradeFuturesContractDetails]:
		'notification_executed_efp_details_info',
	[EFPNotificationType.ExpiredTradeFuturesContractDetails]:
		'notification_expired_efp_details_info',
};
