/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'src/assets/images/logo_onboarding.svg';
import { media, screenSize, screenHeightSize } from 'src/_helpers/media-queries';

export const Header = styled.header`
	z-index: var(--z-index-top-most);
	padding: 20px 40px;
	margin-bottom: 130px;
	display: none;
	height: 82px;

	@media (min-height: ${screenHeightSize.s}px) and (min-width: ${screenSize.s}px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	${media.l`
		height: 84px;
		padding: 20px 80px;
	`}

	${media.xl`
		height: 88px;
		padding: 20px 100px;
	`}

	.links {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
`;

export const HeaderLink = styled.a`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	letter-spacing: ${({ $isChinese }) => ($isChinese ? '0.05em' : '0.39px')};
	color: #ffffff;
	text-decoration: none;
	transition-property: color;
	transition-duration: 0.3s;

	${media.ll`
        font-size: 15px;
        letter-spacing: 0.45px; 
    `}

	${media.xl`
        font-size: 18px;
        letter-spacing: 0.54px; 
    `}


	&:hover {
		color: rgb(255 255 255 / 75%);
	}

	&.separator::after {
		content: '/';
		color: #ffffff;
		opacity: 0.3;
		display: inline-block;
		vertical-align: middle;
		font-family: 'Montserrat';
		line-height: 22px;
		font-size: 18px;
		padding: 0 6px;

		${media.l`
			padding: 0 16px;
		`}

		${media.ll`
			padding: 0 22px;
		`}

		${media.xl`
			padding: 0 27px 0 24px;
		`}

		&:hover {
			opacity: 1;
		}
	}
`;

export const ButtonLink = styled(Link)`
	box-sizing: border-box;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	white-space: nowrap;
	padding: 0;
	border-color: rgba(255, 255, 255, 0.33);
	color: #ffffff;
	border-width: 2px;
	border-style: solid;
	border-radius: var(--large-border-radius);
	font-family: 'Poppins';
	font-weight: 500;
	font-size: 13px;
	line-height: 21px;
	width: 80px;
	height: 42px;
	line-height: 38px;

	${media.l`
		width: 120px;
		font-size: 13px;
		height: 42px;
		line-height: 38px;
	`}

	${media.ll`
		width: 140px;
		font-size: 15px;
		height: 44px;
		line-height: 40px;
	 `}

	${media.xl`
		width: 160px;
		font-size: 18px;
		height: 48px;
		line-height: 44px;
	`}

	&:hover,
    &:active {
		background: var(--green-400);
		border-color: var(--green-400);
		color: var(--neutral-1000);
	}
`;

export const OnboardingLogo = styled(Logo)`
	width: 132px;

	${media.l`
		width: 166px;
	`}

	${media.ll`
		width: 184px;
	`}

	${media.xl`
		width: 200px;
	`}
`;

export const SideColumn = styled.div`
	flex-basis: 132px;

	${media.l`
		flex-basis: 184px;
	`}

	${media.xl`
        flex-basis: 200px;
    `}

	&.stick-to-end {
		display: flex;
		justify-content: end;
	}
`;
