/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media } from 'src/_helpers/media-queries';
import { getCapitalLetter } from 'src/_helpers/getCapitalLetter';
import { t100, H400, m50 } from 'src/components/Typography';
import { ReactComponent as Shield } from 'src/assets/icons/shield_filled.svg';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { ModalHeaderWrapper, ModalTitle } from 'src/components/Modal';
import { UserData } from 'src/containers/MyAccount/components/_modals/commonModalElements';
import {
	UserListItem,
	InitialsCircleWrapper,
	InitialsCircle,
	UserDetails,
	StyledCompanyName,
} from 'src/components/SearchableContactsList/styledComponents';
import { ModalContent } from './CommonComponents';
import { Avatar, AvatarType } from 'src/shared/components/Avatar/Avatar';

export const GroupUsersModalContent = ({ close, group, users, onUserClick, userCompanyId }) => {
	const { t } = useTranslation();

	if (!group) {
		return null;
	}

	const allUsersCount = users.length;
	const selectedUsersCount = users.filter(user => user.isSelected).length;

	return (
		<>
			<StyledModalHeaderWrapper>
				<ModalTitle>{group.name}</ModalTitle>
				<ModalSubTitle>
					{t('n_users_selected', {
						selected: selectedUsersCount,
						total: allUsersCount,
					})}
				</ModalSubTitle>
			</StyledModalHeaderWrapper>
			<ScrollWrapper className="group-users-list">
				{users.map(user => {
					const name = user.name.split(' ');
					const isExternal = !!user.company_id && user.company_id !== userCompanyId;

					return (
						<UserListItem
							key={user.contact_user_id}
							onClick={() => onUserClick(user.contact_user_id)}
							$selected={user.isSelected}
							className={user.isSelected ? 'contact-item-selected' : 'contact-item'}
						>
							<InitialsCircleWrapper data-test={`contact-${user.contact_user_id}`}>
								<InitialsCircle className="initials">
									<Avatar
										initials={`${getCapitalLetter(name[0])}${getCapitalLetter(
											name[1]
										)}`}
										size={AvatarType.Large}
										backgroundColor={user.avatar_color}
										isSelected={user.isSelected}
									/>
								</InitialsCircle>
							</InitialsCircleWrapper>
							<StyledDetails>
								<UserData>
									<H400>{user.name}</H400>
									<StyledCompanyName>{user.company_name}</StyledCompanyName>
								</UserData>
								{isExternal && (
									<ExternalUser className="external-user">
										<Shield />
										{t('external_user')}
									</ExternalUser>
								)}
							</StyledDetails>
						</UserListItem>
					);
				})}
			</ScrollWrapper>
			<ButtonsWrapper>
				<SecondaryButton darker onClick={() => close(selectedUsersCount)}>
					{t('back')}
				</SecondaryButton>
			</ButtonsWrapper>
		</>
	);
};

const ScrollWrapper = styled(ModalContent)`
	overflow-y: auto;
	max-height: 298px;

	${media.xl`
		max-height: 456px;
	`}

	> div {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
		padding: 0 12px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

const ModalSubTitle = styled.div`
	${t100}
	color: var(--neutral-300);
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	padding-bottom: 4px;
`;

const StyledModalHeaderWrapper = styled(ModalHeaderWrapper)`
	padding: 0 12px;
`;

const StyledDetails = styled(UserDetails)`
	justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
	margin: 32px 13px 0;
	width: calc(100% - 26px);

	${media.xl`
    margin-top: 40px;
`}
`;

const ExternalUser = styled.div`
	${m50}
	color: var(--neutral-300);
	font-size: 12px;
	margin-right: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;

	svg {
		margin-right: 7px;
	}
`;
