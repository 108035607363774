/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { h300 } from 'src/components/Typography/fonts';
import { media } from 'src/_helpers/media-queries';

export const EmptyListMessage = ({ message }) => {
	return (
		<Container>
			<InfoIcon />
			<Message>{message}</Message>
		</Container>
	);
};

const Container = styled.div`
	text-align: center;

	svg {
		width: 24px;
		height: 24px;

		${media.xl`
            width: 32px;
            height: 32px;
        `}

		path {
			fill: var(--neutral-400);
		}
	}
`;

const Message = styled.span`
	${h300}
	color: var(--neutral-400);
	display: block;
	margin-top: 8px;
	font-size: 12px;

	${media.xl`
        font-size: 13px;
        margin-top: 12px;
    `}
`;
