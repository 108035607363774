/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalSize, ModalType } from 'src/constants/modal';
import { getUserCompanyId } from 'src/_store/selectors';
import { StyledModal } from './CommonComponents';
import { GroupUsersModalContent } from './GroupUsersModalContent';

export const GroupUsersModal = ({ close, isOpen, open, getGroupData }) => {
	const { group, updateGroupUsers, deselectGroup } = getGroupData();
	const userCompanyId = useSelector(getUserCompanyId);

	const [users, setUsers] = useState([]);

	useEffect(() => {
		if (group?.users) {
			setUsers(group.users);
		}
	}, [group]);

	const handleUserClick = useCallback(
		userId => {
			const newUsers = users.map(user => {
				if (user.contact_user_id === userId) {
					return {
						...user,
						isSelected: !user.isSelected,
					};
				}

				return user;
			});

			setUsers(newUsers);
			updateGroupUsers(newUsers);
		},
		[updateGroupUsers, users]
	);

	const handleClose = useCallback(() => {
		const selectedUsers = users.filter(user => user.isSelected).length;
		if (selectedUsers === 0) {
			deselectGroup(group);
		}
		close();
	}, [close, deselectGroup, group, users]);

	return (
		<StyledModal
			type={ModalType.Info}
			size={ModalSize.Tiny}
			close={handleClose}
			open={open}
			isOpen={isOpen}
			closeOnDimmerClick={false}
			data-test="group-users"
		>
			<GroupUsersModalContent
				close={handleClose}
				group={group}
				users={users}
				onUserClick={handleUserClick}
				userCompanyId={userCompanyId}
			/>
		</StyledModal>
	);
};
