/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showRefreshToast as showRefreshToastSelector } from 'src/_store/selectors';
import { PermanentToast, PermanentToastContainer } from 'src/components/Toast/PermanentToast';
import { TradeStep } from 'src/constants/tradeStatus';

export const NotificationsComponent = ({ tradeStep, tradeData }) => {
	const { t } = useTranslation();
	const showRefreshToast = useSelector(showRefreshToastSelector);

	const showWaitingCompanyToProvideVesselInformationToast =
		tradeStep === TradeStep.ShippingAdvice &&
		tradeData.isBuyerSide &&
		!tradeData.vessel_name &&
		!tradeData.eta_loading_port;

	const showWaitingCompanyToCompleteThisStageToast =
		tradeStep === TradeStep.ShippingAdvice &&
		tradeData.isBuyerSide &&
		tradeData.vessel_name &&
		tradeData.eta_loading_port;

	const showPleaseProvideVesselInformationShippingTabToast =
		tradeStep === TradeStep.ShippingAdvice &&
		tradeData.isSellerSide &&
		!tradeData.vessel_name &&
		!tradeData.eta_loading_port;

	const showWaitingCompanyToProvideShippingTenderToast =
		tradeData.trade_step === TradeStep.Completion &&
		tradeData.isBuyerSide &&
		(!tradeData.bl_date || !tradeData.bl_quantity);

	const showPleaseProvideShippingTenderToast =
		tradeData.trade_step === TradeStep.Completion &&
		tradeData.isSellerSide &&
		(!tradeData.bl_date || !tradeData.bl_quantity);

	/** HANDLE NOTIFICATIONS FOR SHIPPING TAB HERE */
	return (
		<PermanentToastContainer>
			{showRefreshToast && (
				<PermanentToast
					data-test="refresh-page-notification"
					message={t('new_activities_refresh_page')}
					kind="info"
					button={{
						text: t('refresh'),
						onClick: () => {
							window.location.reload(false);
						},
					}}
				/>
			)}
			{showWaitingCompanyToProvideVesselInformationToast && (
				<PermanentToast
					data-test="waiting-company-to-provide-information-toast"
					message={t('waiting_for_company_to_provide_vessel_information', {
						company_name: tradeData.counterparty.name,
					})}
					kind="warning"
				/>
			)}
			{showWaitingCompanyToCompleteThisStageToast && (
				<PermanentToast
					data-test="waiting-company-to-complete-stage-toast"
					message={t('waiting_for_company_to_complete_this_stage', {
						company_name: tradeData.counterparty.name,
					})}
					kind="warning"
				/>
			)}
			{showPleaseProvideVesselInformationShippingTabToast && (
				<PermanentToast
					data-test="please-provide-vessel-information-shipping-tab-toast"
					message={t('please_provide_vessel_information_shipping_tab')}
					kind="info"
				/>
			)}
			{showPleaseProvideShippingTenderToast && (
				<PermanentToast
					data-test="please-provide-shipping-tender-information-toast"
					message={t('please_provide_shipping_tender_information')}
					kind="info"
				/>
			)}
			{showWaitingCompanyToProvideShippingTenderToast && (
				<PermanentToast
					data-test="waiting-company-to-provide-shipping-tender-toast"
					message={t('waiting_for_company_to_provide_shipping_tender_information', {
						companyName: tradeData.seller_company.name,
					})}
					kind="warning"
				/>
			)}
		</PermanentToastContainer>
	);
};
