/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DocumentIcon } from 'src/assets/icons/trade_document.svg';
import { tTradeDocumentType } from 'src/constants/trade-document-type';
import { Market } from 'src/constants/contract';
import { DocumentUpload } from './DocumentUpload';
import { DocumentRequest } from '../../DocumentRequest/DocumentRequest';
import { DocumentListItem } from '../../DocumentListItem/DocumentListItem';
import { NoDocumentInfo } from './NoDocumentInfo';
import { useTradeDataContext } from 'src/containers/ExecutionDetails/Paper/TradeDataContext';
import * as Styled from './styled';

export const TradeDocumentsComponent = () => {
	const { t } = useTranslation();

	const { tradeData } = useTradeDataContext();

	const usersById = useMemo(() => {
		return tradeData.tradeUsers.reduce((acc, user) => {
			return {
				...acc,
				[user._key]: user,
			};
		}, {});
	}, [tradeData]);

	const allDocuments = useMemo(
		() =>
			[...(tradeData.buyer_documents || []), ...(tradeData.seller_documents || [])].sort(
				(a, b) => new Date(b.file.updated_at) - new Date(a.file.updated_at)
			),
		[tradeData]
	);

	const isPaperMarket = tradeData.market === Market.Paper;

	const requestedDocuments = tradeData.requested_documents_types;
	const hasRequestedNotes = tradeData.has_requested_notes;

	const shouldDisplayInstructionsSummary = !isPaperMarket && requestedDocuments.length > 0;

	return (
		<Styled.TradeDocumentsWrapper data-test="trade-details-documents-component">
			<Styled.TradeDocumentsHeader>
				<Styled.HeaderTitle>{t('documents')}</Styled.HeaderTitle>
				{isPaperMarket ? (
					<DocumentUpload />
				) : (
					<Styled.ButtonsWrapper>
						<DocumentUpload />
						{tradeData.isBuyerSide && (
							<DocumentRequest
								tradeId={tradeData._key}
								alreadyRequestedDocuments={requestedDocuments}
								hasRequestedNotes={hasRequestedNotes}
							/>
						)}
					</Styled.ButtonsWrapper>
				)}
			</Styled.TradeDocumentsHeader>
			{shouldDisplayInstructionsSummary && (
				<Styled.InstructionsContainer>
					<Styled.IconWrapper className={clsx({ isBuyerSide: tradeData.isBuyerSide })}>
						<DocumentIcon />
					</Styled.IconWrapper>
					<Styled.TextWrapper>
						<Styled.SmallTitle>{t('documents_requested_by_buyer')}</Styled.SmallTitle>
						<Styled.InfoMessage>
							{requestedDocuments.map(type => tTradeDocumentType(t, type)).join(', ')}
						</Styled.InfoMessage>
					</Styled.TextWrapper>
				</Styled.InstructionsContainer>
			)}
			{allDocuments.length === 0 && <NoDocumentInfo />}
			<Styled.FileList className={clsx({ isPaperMarket })}>
				{allDocuments.map(document => (
					<DocumentListItem
						key={document.document_id}
						document={document}
						uploadedBy={usersById[document.file?.updated_by]}
					/>
				))}
			</Styled.FileList>
		</Styled.TradeDocumentsWrapper>
	);
};
