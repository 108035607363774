/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTradeLocation } from 'src/_helpers/useTradeLocation';
import { ReactComponent as ChainIcon } from 'src/assets/icons/chain.svg';
import { buildHeaderLink } from 'src/containers/ExecutionDetails/helpers/header';
import { UsersSection } from '../../UsersSection/UsersSection';
import { ButtonsSection } from '../sections/ButtonsSection/ButtonsSection';
import * as Styled from './styled';

export const PaperTradeHeader = ({ tradeData, orderId }) => {
	const { t } = useTranslation();

	const { environment } = tradeData;
	const { tradeId } = useTradeLocation();

	const headerLink = buildHeaderLink({
		environment,
		orderId,
		counterId: tradeData?.first_counter_id,
		circleOutSourceTradeId: tradeData?.circle_out_source_trade_id,
		t,
	});

	return (
		<Styled.TradeHeader data-test="trade-details-header-info">
			<Styled.OrderInfo $notSellerOrBuyer={!tradeData.isSellerOrBuyer}>
				{tradeData.isSellerOrBuyer && (
					<Styled.OrderLink to={headerLink.url}>
						<ChainIcon width="12" height="12" />
						<span data-test={headerLink.dataTest}>{headerLink.text}</span>
					</Styled.OrderLink>
				)}
				<Styled.TradeTitle data-test={`trade-details-header-info-id-${tradeId}`}>
					{t('paper_trade')} #{tradeId}
				</Styled.TradeTitle>
			</Styled.OrderInfo>
			{/*
				// Note: according to the PT-834 task we don't want to completely remove the box, just hide it for now
				<InstructionsBox step={step} intentionEndDate={intentionEndDate} />
			*/}
			<Styled.Actions>
				<Styled.UsersWrapper>
					<UsersSection tradeData={tradeData} />
				</Styled.UsersWrapper>
				<ButtonsSection />
			</Styled.Actions>
		</Styled.TradeHeader>
	);
};
