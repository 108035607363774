/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const generateDays = month => {
	const daysInMonth = month.daysInMonth();
	const firstDayOfMonth = month.clone().date(1);
	const firstDayWeekday = firstDayOfMonth.weekday();

	const days = [];

	/** if month doesn't start in Monday, show few days from the previous month */
	for (let i = 0; i < firstDayWeekday; i++) {
		const date = firstDayOfMonth.clone().subtract(firstDayWeekday - i, 'days');
		const key = date.format('YYYYMMDD');

		days.push({
			current: false,
			key,
			date,
		});
	}

	let lastWeekday;

	/** fill array with days of current month */
	for (let i = 0; i < daysInMonth; i++) {
		const date = firstDayOfMonth.clone().add(i, 'days');
		lastWeekday = date.weekday();
		const key = date.format('YYYYMMDD');

		days.push({
			current: true,
			key,
			date,
		});
	}

	/** fill the row with days of the next month */
	const nextMonthDays = 7 - lastWeekday;
	for (let i = 0; i < nextMonthDays - 1; i++) {
		const date = firstDayOfMonth.clone().add(daysInMonth + i, 'days');
		const key = date.format('YYYYMMDD');

		days.push({
			current: false,
			key,
			date,
		});
	}

	return days;
};
