/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useWatchPaperCounterFields } from '../../helpers/paper/useWatchPaperCounterFields';
import { useTranslation } from 'react-i18next';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import { translateTimeRangeOptions, DefaultValidityOptions } from 'src/_helpers/timeRange';

export const useCounterValidity = () => {
	const { t } = useTranslation();
	const { presetIDValue } = useWatchPaperCounterFields();

	const { data } = useAllPaperPresets(!!presetIDValue);

	const selectedPreset = data?.presets?.find(({ _key }) => _key === presetIDValue);

	const validityOptions = selectedPreset?.validity_options
		? {
				options: translateTimeRangeOptions(selectedPreset.validity_options, t),
				tradingSessions: selectedPreset.trading_sessions
					? {
							header: `trading_session_${selectedPreset.inco_id}_${selectedPreset.product_id}`.toLowerCase(),
							items: selectedPreset.trading_sessions.map(({ end }) => end) || [],
					  }
					: null,
		  }
		: { options: translateTimeRangeOptions(DefaultValidityOptions, t) };

	return validityOptions;
};
