/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { Pagination as SemanticPagination } from 'semantic-ui-react';
import { h200, m100 } from 'src/components/Typography';
import { media } from 'src/_helpers';
import { encodeContentUrl } from 'src/_helpers/encodeContentUrl';
import { ReactComponent as ChevronIcon } from 'src/assets/icons/chevron.svg';
import { Dropdown } from 'semantic-ui-react';

export const PaginationWrapper = styled.span`
	display: flex;
	align-items: center;
	gap: 20px;
	margin: 0 12px;
`;

export const PageNumber = styled.a`
	.ui.menu .item& {
		${m100}
		font-size: 12px;
		color: var(--neutral-300);
		padding: 0 10px;

		${media.xl`
			padding: 0 12px;
		`}
	}
`;

export const StyledPagination = styled(SemanticPagination)`
	.ui.pagination.menu& {
		display: flex;
		align-items: center;
		user-select: none;

		.item {
			min-width: 0;
			height: 15px;
			border-radius: var(--default-border-radius);

			&[tabindex='-1'] {
				pointer-events: none;
			}

			&:first-child {
				margin-right: 10px;
				pointer-events: none;
			}

			&:last-child {
				margin-left: 10px;
				pointer-events: none;
			}

			&:first-child,
			&:last-child {
				width: auto;
				border: none;
				padding: 0;

				&:after {
					display: none;
				}
			}
		}
	}
`;

export const PageInfoWrapper = styled.div`
	color: var(--neutral-300);
	${h200}
	font-size: 11px;
`;

const arrowStyle = css`
	cursor: pointer;
	color: var(--neutral-300);
	position: relative;
	top: -1px;
	pointer-events: auto;

	&:hover {
		color: var(--white);
	}

	&.disabled {
		color: var(--neutral-500);
		pointer-events: none;
	}
`;

export const PrevArrow = styled(ChevronIcon)`
	${arrowStyle}
	transform: rotate(90deg);
`;

export const NextArrow = styled(ChevronIcon)`
	${arrowStyle}
	transform: rotate(-90deg);
`;

export const StyledDropdown = styled(Dropdown)`
	&.ui.dropdown {
		${h200}
		font-size: 11px;
		color: var(--neutral-300);

		& > .dropdown.icon {
			margin-left: 4px;
			&:before {
				${() =>
					encodeContentUrl(
						'<svg width="8" height="6" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 2.20891C9.31658 1.81838 8.68342 1.81838 8.29289 2.20891L5 5.5018L1.70711 2.20891C1.31658 1.81839 0.683418 1.81839 0.292894 2.20891C-0.0976307 2.59943 -0.0976307 3.2326 0.292894 3.62312L4.29289 7.62312C4.68342 8.01365 5.31658 8.01365 5.70711 7.62312L9.70711 3.62312C10.0976 3.2326 10.0976 2.59943 9.70711 2.20891Z" fill="#757ba9"/></svg>'
					)}
			}
		}

		& > .menu {
			border-radius: 4px;
			bottom: calc(100% + 10px);
			left: -17px;
			background: var(--neutral-800);

			& .item {
				${h200}
				font-size: 11px;
				color: var(--neutral-300);

				&:first-child:hover {
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
				}

				&:last-child:hover {
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
				}

				&:hover {
					color: var(--white);
				}
			}
		}
	}
`;

export const RowsPerPageText = styled.span`
	margin-right: 12px;
	color: var(--neutral-300);
	${h200}
	font-size: 11px;
`;
