/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';

export function useOnce(callbackFn, cleanupFn) {
	return useEffect(() => {
		callbackFn();

		if (cleanupFn) {
			return cleanupFn;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
