/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, createContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getGitVersion } from 'src/_api/settings.api';
import { duration } from 'src/constants/duration';

export const GitVersionContext = createContext({
	isDifferentVersion: false,
});

export const GitVersionContextProvider = ({ children }) => {
	const [isDifferentVersion, setIsDifferentVersion] = useState(false);
	const [version, setVersion] = useState(null);

	const { data = {}, isFetched } = useQuery('git-version', getGitVersion, {
		refetchInterval: duration.minute,
	});

	useEffect(() => {
		const compareVersions = value => {
			if (!value) {
				return;
			}

			if (version !== null && version !== value) {
				setIsDifferentVersion(true);
				setVersion(value);
			} else {
				setVersion(value);
			}
		};

		if (isFetched) {
			compareVersions(data?.version);
		}
	}, [data, isFetched, version]);

	return (
		<GitVersionContext.Provider value={{ isDifferentVersion }}>
			{children}
		</GitVersionContext.Provider>
	);
};
