/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo } from 'react';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import { mapApiCodeToPrefixedMinutes } from 'src/_helpers/timeRange';
import { defaultFirmTimeValue, defaultIndicativeTimeValue } from '../model';

export const useDefaultValidities = presetId => {
	const { data } = useAllPaperPresets();

	return useMemo(() => {
		const result = { firm: defaultFirmTimeValue, indicative: defaultIndicativeTimeValue };

		if (presetId && data?.presets) {
			const selectedPreset = data.presets.find(preset => preset._key === presetId);

			if (selectedPreset) {
				const presetFirm = selectedPreset.default_firm_order_validity_option;
				if (presetFirm) {
					result.firm = mapApiCodeToPrefixedMinutes(presetFirm);
				}

				const presetIndicative = selectedPreset.default_indicative_order_validity_option;
				if (presetIndicative) {
					result.indicative = mapApiCodeToPrefixedMinutes(presetIndicative);
				}
			}
		}

		return result;
	}, [presetId, data]);
};
