/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
const UNKNOWN_ERROR = 'unknown_error';
export const PASSWORD_ALREADY_USED = 'password_already_used';
export const SECURITY_REASONS_CHANGE_PASSWORD = 'for_security_reasons_change_password';
export const INCORRECT_LOGIN_OR_PASSWORD = 'incorrect_login_or_password';

export const mapErrorToMessage = error => {
	switch (error) {
		case UNKNOWN_ERROR:
		default:
			return 'error_unknown_error';
	}
};
