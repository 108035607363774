/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useMemo } from 'react';
import { CounterStatus } from 'src/constants/counterStatus';
import { OrderStatus } from 'src/constants/orderStatus';
import { Environment } from 'src/constants/contract';
import { formatDistance, isExpired } from 'src/_helpers/date';
import { useTranslation } from 'react-i18next';
import { useViewOrderDrawerContext } from '../ViewOrderDrawerProvider';

const recentlyUpdated = (a, b) => (a.updated_at > b.updated_at ? -1 : 1);

export const useViewOrderFooter = (order, negotiations) => {
	const { t } = useTranslation();

	const { hasOrderExpired, orderEdited, orderWithdrawn } = useViewOrderDrawerContext();
	const { validity, _key: orderId, environment, status, is_indicative } = order;

	const hasActiveNegotiations = negotiations.some(negotiation =>
		[CounterStatus.Active, CounterStatus.AcceptedNoCounter, CounterStatus.Expired].includes(
			negotiation.status
		)
	);

	const activeNegotiation = useMemo(
		() =>
			negotiations.find(negotiation => {
				return (
					!isExpired(negotiation.validity) &&
					[
						CounterStatus.Active,
						CounterStatus.Accepted,
						CounterStatus.AcceptedNoCounter,
					].includes(negotiation.status)
				);
			}),
		[negotiations]
	);

	const negotiationToReactivate = useMemo(
		() =>
			negotiations
				.filter(negotiation => {
					return (
						isExpired(negotiation.validity) &&
						negotiation.user._key === order.user_id &&
						(order.status === OrderStatus.Active || isExpired(validity)) &&
						negotiation.status !== OrderStatus.Confirmed
					);
				})
				.sort(recentlyUpdated),
		[negotiations, order, validity]
	);

	const isOrderExpired = isExpired(validity) || hasOrderExpired || status === OrderStatus.Expired;

	const canAccept =
		!is_indicative &&
		![OrderStatus.Terminated, OrderStatus.NotAvailable].includes(status) &&
		!isOrderExpired &&
		!activeNegotiation &&
		!negotiationToReactivate.length &&
		!negotiations.some(negotiation => negotiation.status === CounterStatus.Confirmed);

	const canCounter =
		(!negotiations.length &&
			!isOrderExpired &&
			![OrderStatus.Terminated, OrderStatus.NotAvailable].includes(status)) ||
		(!!negotiations.length &&
			!activeNegotiation &&
			!negotiationToReactivate.length &&
			!isOrderExpired &&
			![OrderStatus.Terminated, OrderStatus.NotAvailable].includes(status) &&
			!negotiations.some(negotiation => negotiation.status === CounterStatus.Confirmed));

	const canFirmUp = canCounter && is_indicative;

	const mostRecentlyUpdatedNegotiation = useMemo(() => [...negotiations].sort(recentlyUpdated), [
		negotiations,
	]);

	const counterLink = `${environment}/order/${orderId}/negotiation/create`;

	const buildGoToCounterLink = () => {
		if (!!activeNegotiation) {
			return `/?orderEnvironment=${environment}&orderId=${orderId}&negotiationId=${activeNegotiation.first_counter_id}`;
		}

		if (!!negotiationToReactivate.length) {
			return `/?orderEnvironment=${environment}&orderId=${orderId}&negotiationId=${negotiationToReactivate[0].first_counter_id}`;
		}

		return `/?orderEnvironment=${environment}&orderId=${orderId}&negotiationId=${mostRecentlyUpdatedNegotiation[0]?.first_counter_id}`;
	};

	const hasTradedNegotiationInOtc =
		environment === Environment.OTC &&
		!!negotiations.find(negotiation => {
			return negotiation.status === CounterStatus.Confirmed;
		});

	const canGoToCounter =
		!!activeNegotiation ||
		!!negotiationToReactivate.length ||
		(!!negotiations.length &&
			(isOrderExpired ||
				[OrderStatus.Terminated, OrderStatus.NotAvailable].includes(status))) ||
		hasTradedNegotiationInOtc;

	const footerExpirationData = () => {
		const isLastCounterConfirmed =
			negotiations.at(-1) && negotiations.at(-1).status === CounterStatus.Confirmed;

		if (status === OrderStatus.Terminated || isLastCounterConfirmed) {
			return { label: '', value: '' };
		}

		if (status === OrderStatus.NotAvailable) {
			return {
				label: t('valid_for'),
				value: '---',
			};
		}

		if (isOrderExpired) {
			return {
				label: t('expired'),
				value: formatDistance({
					t,
					startDate: new Date(),
					endDate: validity,
					fullText: true,
					ago: true,
				}),
			};
		}

		return {
			label: t('valid_for'),
			value: formatDistance({ t, startDate: new Date(), endDate: validity, fullText: true }),
		};
	};

	return {
		counterLink,
		goToCounterLink: buildGoToCounterLink(),
		canCounter,
		canAccept,
		canFirmUp,
		canGoToCounter,
		hasActiveNegotiations,
		expirationData: footerExpirationData(),
		hasOrderUpdated: orderEdited || orderWithdrawn,
		hasTradedNegotiationInOtc,
	};
};
