/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import { InformativeField } from './InformativeField';
import { defaultCurrencyUnit } from 'src/constants/contract';
import { formatFloatNumberToPriceFormat } from 'src/_helpers/price';
import { isNil } from 'src/_helpers';

const SecondLegPriceField = ({ path, t }) => {
	const { priceValue, firstLegPriceValue } = useWatchPaperFields(path);
	const { currencyUnitValue } = useWatchPaperFields(path);

	const [currency, unit] = (currencyUnitValue || defaultCurrencyUnit).split('/');

	const currencyUnitLabel = `${currency}/${t(`price_unit_short_${unit}`)}`;

	const secondLegPriceValue = () => {
		if (isNil(priceValue) || isNil(firstLegPriceValue)) {
			return '---';
		}

		return (
			formatFloatNumberToPriceFormat(parseFloat(firstLegPriceValue) - priceValue) +
			' ' +
			currencyUnitLabel
		);
	};

	return (
		<InformativeField
			title={t('second_leg_price')}
			subtitle={secondLegPriceValue()}
			dataTest="second-leg-price"
		/>
	);
};

export const secondLegPriceField = (path, t) => <SecondLegPriceField path={path} t={t} />;
