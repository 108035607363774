/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { isExpired } from 'src/_helpers/date';

export const OrderStatus = {
	Active: 'ACTIVE',
	AcceptedNoCounter: 'ACCEPTED_NO_COUNTER',
	Accepted: 'ACCEPTED',
	Confirmed: 'CONFIRMED',
	Traded: 'TRADED',
	Expired: 'EXPIRED',
	Terminated: 'TERMINATED',
	Rejected: 'REJECTED',
	NotAvailable: 'NOT_AVAILABLE', // artificial status, not returned by the backend
};

export const tOrderStatus = (t, status, validity) => {
	if (status === OrderStatus.NotAvailable) {
		return t('not_available');
	}

	if (status === OrderStatus.Terminated) {
		return t('withdrawn');
	}

	if (isExpired(validity)) {
		return t('expired');
	}

	return '';
};
