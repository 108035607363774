/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { CargoType, PriceType } from 'src/constants/contract';
import { ReactComponent as ShieldAlert } from 'src/assets/icons/shield_alert.svg';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';
import { LoaderSpinning } from 'src/components/Layout/LandingForm';
import styled from 'styled-components';

// TODO: Fetch user preferred filters selection from the backend so this nasty const will no longer be needed;
// Desired solution: to get user settings from BE e.g. getInitialChartFilters();
// For now the current implementation allows chart filters to persist when user reloads the page;
// It works for all filters except Product though (refactor required);
// https://vosbor.atlassian.net/browse/TD-516

export const ChartDefaultFilters = {
	Product: {
		_id: 'products/1142452',
		_key: 'SOYBEANS',
		name: 'Soybeans',
		type_id: '1142084',
	},
	Inco: { _key: 'FOB', name: 'FOB' },
	PriceType: PriceType.Flat,
	Location: {
		countries: [{ _key: 'US', name: 'United States', toggleKey: 'countries' }],
		regions: [],
	},
	CargoType: [CargoType.Bulk],
};

export const ContinuousShipmentPeriod = {
	Spot: 0,
	M1: 1,
	M2: 2,
	M3: 3,
	M6: 6,
};

export function tContinuousShipmentPeriod(t, type) {
	switch (type) {
		case ContinuousShipmentPeriod.Spot:
			return t('spot');
		case ContinuousShipmentPeriod.M1:
			return t('+1_month');
		case ContinuousShipmentPeriod.M2:
			return t('+2_month');
		case ContinuousShipmentPeriod.M3:
			return t('+3_month');
		case ContinuousShipmentPeriod.M6:
			return t('+6_month');
		default:
			return '';
	}
}

export const ChartContainerID = {
	Desktop: 'tv_chart_container',
	Mobile: 'tv_chart_container_mobile',
};

export const PlaceholderType = {
	NoResults: 'no_results',
	InvalidFilters: 'invalid_filters',
	IsLoading: 'is_loading',
};

export const Orientation = {
	Landscape: 'landscape',
	Portrait: 'portrait',
};

export const ChartIntervals = {
	'1D': 'day',
	'1W': 'week',
	'1M': 'month',
	60: 'hour',
};

export const ChartEvent = {
	MobileChartGuide: 'mobile_chart_guide',
	ToggleFullscreen: 'toggle_fullscreen',
};

export const getPlaceholderMeta = (t, type) => {
	switch (type) {
		case PlaceholderType.NoResults:
			return {
				icon: <StyledSearchIcon />,
				text: t('no_results_found'),
			};
		case PlaceholderType.InvalidFilters:
			return {
				icon: <StyledAlert />,
				text: t('select_filters_criteria'),
			};
		case PlaceholderType.IsLoading:
			return {
				icon: <LoaderSpinning />,
				text: t('loading'),
			};
		default:
			return {};
	}
};

const StyledAlert = styled(ShieldAlert)`
	color: var(--yellow-400);
	width: 32px;
	height: 32px;
`;

const StyledSearchIcon = styled(SearchIcon)`
	color: var(--primary-600);
	width: 32px;
	height: 32px;
`;
