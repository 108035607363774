/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, {
	useState,
	forwardRef,
	useMemo,
	createContext,
	useContext,
	isValidElement,
	cloneElement,
} from 'react';
import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useHover,
	useFocus,
	useDismiss,
	useRole,
	useInteractions,
	useMergeRefs,
	FloatingPortal,
} from '@floating-ui/react';
import styled from 'styled-components';
import { m50 } from '../Typography';
import clsx from 'clsx';

const TooltipContext = createContext();

export const useTooltip = ({
	initialOpen = false,
	placement = 'bottom',
	open: controlledOpen,
	onOpenChange: setControlledOpen,
	spacing = 5,
}) => {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(spacing),
			flip({
				crossAxis: placement.includes('-'),
				fallbackAxisSideDirection: 'start',
				padding: spacing,
			}),
			shift({ padding: spacing }),
		],
	});

	const context = data.context;

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen == null,
	});
	const focus = useFocus(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context, { ancestorScroll: true });
	const role = useRole(context, { role: 'tooltip' });

	const interactions = useInteractions([hover, focus, dismiss, role]);

	return useMemo(
		() => ({
			open,
			setOpen,
			...interactions,
			...data,
		}),
		[open, setOpen, interactions, data]
	);
};

export const useTooltipContext = () => {
	const context = useContext(TooltipContext);

	if (context == null) {
		throw new Error('Tooltip components must be wrapped in <Tooltip />');
	}

	return context;
};

export const Tooltip = ({ children, ...options }) => {
	const tooltip = useTooltip(options);

	return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
};

export const TooltipTrigger = forwardRef(({ children, asChild = true, ...props }, propRef) => {
	const context = useTooltipContext();
	const childrenRef = children.ref;
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

	if (asChild && isValidElement(children)) {
		return cloneElement(
			children,
			context.getReferenceProps({
				ref,
				...props,
				...children.props,
				'data-state': context.open ? 'open' : 'closed',
			})
		);
	}

	return (
		<Trigger
			ref={ref}
			data-state={context.open ? 'open' : 'closed'}
			{...context.getReferenceProps(props)}
		>
			{children}
		</Trigger>
	);
});

export const TooltipContent = forwardRef(
	({ style, className, tooltipTestId, fullWidth, ...props }, propRef) => {
		const context = useTooltipContext();
		const ref = useMergeRefs([context.refs.setFloating, propRef]);

		if (!context.open) {
			return null;
		}

		return (
			<FloatingPortal>
				<TooltipContentBody
					ref={ref}
					style={{
						...context.floatingStyles,
						...style,
					}}
					{...context.getFloatingProps(props)}
					className={clsx('tooltip', className)}
					data-test={tooltipTestId}
					fullWidth={fullWidth}
				/>
			</FloatingPortal>
		);
	}
);

const Trigger = styled.span``;

const TooltipContentBody = styled.div`
	&.tooltip {
		${m50};
		padding: 5px 8px;
		border-radius: 4px;
		background: var(--neutral-500);
		color: var(--text-color-default);
		z-index: var(--z-index-tooltip);
		max-width: ${({ fullWidth }) => (fullWidth ? `100%` : `250px`)};
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
	}
`;
