/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getOrderWithCounters, trackAnalytics } from 'src/_api';
import { OrderType } from 'src/constants/contract';
import { CounterStatus } from 'src/constants/counterStatus';
import { getCounterChanges } from '../helpers/getCounterChanges';
import { mapCounterFiles } from '../helpers/mapCounterFiles';
import { getUserId } from 'src/_store/selectors';
import { getCounterUserWithCompany } from '../helpers/getCounterFullUser';
import { mapCounterComments } from '../helpers/mapCounterComments';
import { useCounterHeader } from './useCounterHeader';
import { isFooterTabActive, FooterTabs } from 'src/_helpers/session';

export const useNegotiationView = ({ orderID, environment, activeNegotiationID }) => {
	const queryClient = useQueryClient();
	const [counterIndex, setCounterIndex] = useState(0);
	const [lastCounterSet, setLastCounterSet] = useState(false);
	const userId = useSelector(getUserId);

	const { data = {}, isLoading: isLoadingOrderWithCounters } = useQuery(
		['order_with_counters', orderID, activeNegotiationID],
		() =>
			getOrderWithCounters({
				environment,
				orderId: orderID,
				negotiationId: activeNegotiationID,
			}),
		{
			onSuccess: ({ counters }) => {
				setCounterIndex(counters.length);
				setLastCounterSet(true);
			},
			cacheTime: 0,
			staleTime: 0,
		}
	);

	const { order = {}, counters = [] } = data;

	const allCounters = useMemo(() => [order, ...counters], [order, counters]);

	const hasPreviousCounter = counterIndex > 0;
	const hasNextCounter = counterIndex < counters.length;

	const oppositeOrderType = order.order_type === OrderType.Buy ? OrderType.Sell : OrderType.Buy;
	const counterType = counterIndex % 2 === 1 ? oppositeOrderType : order.order_type;

	const hasActiveCounters = counters.some(counter => counter.status === CounterStatus.Active);

	const currentCounter = useMemo(
		() =>
			!isLoadingOrderWithCounters
				? mapCounter(
						allCounters,
						allCounters[counterIndex],
						counterIndex,
						userId,
						counterType
				  )
				: null,
		[allCounters, counterIndex, counterType, isLoadingOrderWithCounters, userId]
	);

	const previousCounter = counterIndex === 0 ? null : allCounters[counterIndex - 1];

	const counterChanges =
		currentCounter === null ? {} : getCounterChanges(previousCounter, currentCounter);

	const isOnLastCounter = counters.length === counterIndex;

	const { mutate: markCounterAsRead } = useMutation(trackAnalytics, {
		onError: error => {
			console.log('Error on setting analytics: ', error);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries([
				'order_with_counters',
				orderID,
				activeNegotiationID,
			]);
			await queryClient.invalidateQueries(['order', orderID, 'negotiations']);

			if (isFooterTabActive(FooterTabs.MyNegotiations)) {
				await queryClient.invalidateQueries(['my-negotiations']);
			}

			if (isFooterTabActive(FooterTabs.MyTrades)) {
				await queryClient.invalidateQueries(['my-trades']);
			}

			await queryClient.invalidateQueries(['my-unseen-negotiations']);
		},
	});

	useEffect(() => {
		const lastAnalyticsSentAt = currentCounter?.analytics?.created_at;
		const shouldUpdateAnalytics =
			!lastAnalyticsSentAt ||
			moment(currentCounter?.analytics?.created_at).isBefore(currentCounter?.updated_at);

		// track analytics for the last seen counter
		if (isOnLastCounter && currentCounter?._key !== orderID && shouldUpdateAnalytics) {
			markCounterAsRead({ counter_order_id: currentCounter?._key });
		}
	}, [currentCounter, isOnLastCounter, markCounterAsRead, orderID]);

	const setPreviousCounter = () => setCounterIndex(i => i - 1);
	const setNextCounter = () => setCounterIndex(i => i + 1);

	const { headerMessage, headerType, headerSubtitle } = useCounterHeader({
		allCounters,
		currentCounter,
		counterType,
		isOnLastCounter,
	});

	const isViewNotReady = !lastCounterSet || currentCounter === null;

	const countersLength = counters.length;

	return {
		counterIndex,
		hasPreviousCounter,
		setPreviousCounter,
		hasNextCounter,
		setNextCounter,
		currentCounter,
		counterChanges,
		hasActiveCounters,
		isViewNotReady,
		headerMessage,
		headerType,
		headerSubtitle,
		countersLength,
	};
};

const mapCounter = (allCounters = [], counter, counterIndex, userId, counterType) => {
	if (allCounters.length === 0) {
		return { ...counter, counterIndex };
	}

	const [order, ...counters] = allCounters;
	const counterFiles = mapCounterFiles(order, counters);
	const counterComments = mapCounterComments(userId, order, counters);
	const counterUser = getCounterUserWithCompany(counter);

	return {
		...counter,
		counterType,
		comments: counterComments[counterIndex],
		files: counterFiles[counterIndex],
		user: counterUser,
		counterIndex,
	};
};
