/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { listMyNotifications } from 'src/_api';
import { useQueryClient } from 'react-query';
import { hideRefreshToast, showRefreshToast } from 'src/_store/actions';
import { duration } from 'src/constants/duration';
import {
	NotificationType,
	PhysicalExecutionNotifications,
	orderDetailsPageNotificationTypeArray,
} from './model';
import { getUserId } from 'src/_store/selectors/user.selectors';
import { useOnce } from 'src/_helpers/useOnce';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { WebsocketChannelType, PrivateWebsocketEventType } from 'src/constants/websockets';
import { useFeatureFlags } from 'src/featureFlags/FeatureFlagsContext';
import { FlagNames } from 'src/constants/flags';

export function useNotificationUpdater({ orderId, enabled = true }) {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const userId = useSelector(getUserId);
	const isOrderDetailsPage = useLocation().pathname.includes('/order/');
	const isCounterDetailsPage = useLocation().pathname.includes('/negotiation/');
	const isExecutionPage = useLocation().pathname.includes('/execution/trade/');
	const { negotiationId, tradeId } = useParams();

	const featureFlagContext = useFeatureFlags();
	const eventstreamEnabled = featureFlagContext.isFlagEnabled(FlagNames.Eventstream);

	const checkLastNotification = useCallback(
		lastNotification => {
			if (isOrderDetailsPage || isCounterDetailsPage || isExecutionPage) {
				const shouldDispatchRefreshToastForOrderOrCounter =
					orderDetailsPageNotificationTypeArray.some(
						type => type === lastNotification.type
					) &&
					orderId === lastNotification.order_id &&
					userId !== lastNotification.updated_by &&
					lastNotification.type !== NotificationType.OrderConfirmed &&
					negotiationId === lastNotification.first_counter_id;

				const shouldDisplayRefreshToastForExecution =
					PhysicalExecutionNotifications.some(type => type === lastNotification.type) &&
					userId !== lastNotification.updated_by &&
					tradeId === lastNotification.trade_id;

				if (
					shouldDispatchRefreshToastForOrderOrCounter ||
					shouldDisplayRefreshToastForExecution
				) {
					dispatch(showRefreshToast());
				}
			}
		},
		[
			isOrderDetailsPage,
			isCounterDetailsPage,
			orderId,
			userId,
			tradeId,
			dispatch,
			negotiationId,
			isExecutionPage,
		]
	);

	useOnce(() => {
		dispatch(hideRefreshToast());
	});

	useEffect(() => {
		let lastProcessedId = null;
		async function checkLatest() {
			try {
				const lastNotification = (
					await listMyNotifications({ _limit: 1, read: false })
				)?.[0];

				if (lastNotification && lastProcessedId !== lastNotification._key) {
					lastProcessedId = lastNotification._key;
					queryClient.invalidateQueries(['notifications', { read: false }]);

					const loadedNotifications = queryClient.getQueryData([
						'notifications',
						{ read: false },
					]);
					const isAlreadyLoaded = loadedNotifications?.find(
						item => item._key === lastNotification._key
					);

					if (!isAlreadyLoaded) {
						checkLastNotification(lastNotification);
					}
				}
			} catch {
				console.warn('Could not load latest notification');
			}
		}

		let interval;
		if (enabled && !eventstreamEnabled) {
			interval = setInterval(checkLatest, duration.minute);
		}

		return () => {
			window.clearInterval(interval);
		};
	}, [
		queryClient,
		dispatch,
		isOrderDetailsPage,
		isCounterDetailsPage,
		userId,
		orderId,
		enabled,
		eventstreamEnabled,
		checkLastNotification,
	]);

	const newNotificationCallback = useCallback(
		message => {
			const notification = message?.data?.data;
			if (notification) {
				checkLastNotification(notification);
			}
		},
		[checkLastNotification]
	);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.NewNotification,
		newNotificationCallback
	);
}
