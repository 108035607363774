/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { usePresence } from './usePresence';

export const WebsocketPresenceWrapper = () => {
	usePresence();

	return null;
};
