/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FoldableSection } from 'src/components/CreateOrderDrawer/components/FoldableSection/FoldableSection';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { MaterialTextArea } from 'src/designSystem/Form/MaterialInput/MaterialTextArea';
import { MaterialTextInput } from 'src/designSystem/Form/MaterialInput/MaterialTextInput';
import { MaterialMultiSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialMultiSelectInput';
import { CounterpartiesSection } from 'src/components/ViewOrderDrawer/Content/CounterpartiesSection/CounterpartiesSection';
import { ReactComponent as UploadIcon } from 'src/assets/icons/upload.svg';
import { useAdditionalInfoSectionOptions } from './useAdditionalInfoSectionOptions';
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';
import {
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from 'src/components/ViewOrderDrawer/styled';
import { tEnvironment } from 'src/constants/contract';
import { ContextualGuidePhysicalOrderAdditionalInfo } from 'src/components/ContextualGuide';
import { UpwardOptionsWrapper } from 'src/designSystem/Form/MultiSelect/styled';
import { NoApprovedContactsPlaceholder } from 'src/components/CreateOrderDrawer/components/NoApprovedContactsPlaceholder/NoApprovedContactsPlaceholder';
import * as Styled from './styled';

export const AdditionalInfoSection = () => {
	const { t } = useTranslation();

	const {
		formState: { errors },
		getValues,
	} = useFormContext();

	const {
		environment,
		nameVisibility,
		role,
		performanceBond,
		contractTerms,
		contractNumber,
		customContract,
		counterparties,
		documents,
		canHideOptional,
	} = useAdditionalInfoSectionOptions();

	const { typeValue, environmentValue } = useWatchPhysicalFields();

	return (
		<FoldableSection
			title={t('additional_information')}
			GuideComponent={ContextualGuidePhysicalOrderAdditionalInfo}
			canToggle={canHideOptional}
		>
			{environment.disabled ? (
				<SectionGridContent mandatory>
					<SectionGridLabel>{t('environment')}</SectionGridLabel>
					<SectionGridText data-test="additional-information-section-environment">
						{tEnvironment(t, environmentValue)}
					</SectionGridText>
				</SectionGridContent>
			) : (
				<Controller
					mandatory
					name="environment"
					render={({ field }) => (
						<MaterialSelectInput
							required
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('environment')}
							options={environment.options}
							disabled={!environment.ready}
							{...field}
						/>
					)}
				/>
			)}
			<Controller
				mandatory
				name="nameVisibility"
				render={({ field }) => (
					<MaterialSelectInput
						required
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('name')}
						options={nameVisibility.options}
						disabled={!nameVisibility.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name="role"
				render={({ field }) => (
					<MaterialSelectInput
						required
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('role')}
						options={role.options}
						disabled={!role.ready}
						{...field}
					/>
				)}
			/>
			{counterparties.visible && (
				<Controller
					mandatory
					fullWidth
					name="counterparties"
					render={({ field }) => (
						<Styled.WideField>
							<MaterialMultiSelectInput
								required
								key={`counterparties-dropdown${
									counterparties.ready ? '' : '-loading'
								}`}
								error={!!errors[field.name]}
								dataTest={field.name}
								label={t('counterparties')}
								options={counterparties.options}
								groups={counterparties.groups}
								disabled={!counterparties.ready}
								OptionsWrapper={UpwardOptionsWrapper}
								noDataPlaceholder={NoApprovedContactsPlaceholder}
								{...field}
							/>
						</Styled.WideField>
					)}
				/>
			)}
			<Controller
				name="performanceBond"
				mandatory={performanceBond.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('performance_bond')}
						options={performanceBond.options}
						disabled={!performanceBond.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				name="contractTerms"
				mandatory={contractTerms.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('contract_terms')}
						options={contractTerms.options}
						disabled={!contractTerms.ready}
						{...field}
					/>
				)}
			/>
			{contractNumber.enabled && (
				<Controller
					name="contractNumber"
					mandatory={contractNumber.hasValue}
					render={({ field }) => (
						<MaterialTextInput
							error={!!errors[field.name]}
							data-test={field.name}
							id={field.name}
							label={t('contract_number')}
							maxLength={100}
							{...field}
						/>
					)}
				/>
			)}
			{customContract.enabled && (
				<Styled.UploadButton>
					<input
						data-test="custom-contract-file-input"
						type="file"
						title=""
						accept={customContract.accept}
						onChange={customContract.onChange}
						onClick={ev => (ev.target.value = null)}
					/>
					<UploadIcon /> {t('upload_contract')}
				</Styled.UploadButton>
			)}
			<Styled.CustomContract fullWidth mandatory={!!customContract.file}>
				{!!customContract.file && (
					<Styled.UploadedFile
						file={customContract.file}
						handleSuccess={customContract.onUploaded}
						handleDelete={customContract.onDelete}
						isUploading={customContract.isUploading}
						isSmall
						withTooltip
					/>
				)}
			</Styled.CustomContract>
			<Controller
				mandatory
				fullWidth
				name="comment"
				render={({ field }) => (
					<Styled.WideField>
						<MaterialTextArea
							autoGrow
							rows={1}
							resize={false}
							label={t('comment')}
							data-test={field.name}
							{...field}
						/>
					</Styled.WideField>
				)}
			/>
			<Styled.DocumentsTitle mandatory={!!documents.files.length}>
				{t('documents')}
			</Styled.DocumentsTitle>
			<div />
			<Styled.UploadButton disabled={documents.isUploading}>
				<input
					data-test="documents-file-input"
					type="file"
					title=""
					multiple
					accept={documents.accept}
					onChange={documents.onChange}
					onClick={ev => (ev.target.value = null)}
				/>
				<UploadIcon /> {t('upload_documents')}
			</Styled.UploadButton>
			<Styled.Documents fullWidth mandatory={!!documents.files.length}>
				{documents.files.map(({ file, isUploaded, fileId, key }) => (
					<Styled.UploadedFile
						key={key}
						data-key={file.name + ' ' + fileId}
						file={file}
						handleSuccess={documents.onUploaded(key)}
						handleDelete={documents.onDelete(key)}
						isUploading={!isUploaded}
						isSmall
						withTooltip
					/>
				))}
			</Styled.Documents>
			{counterparties.readOnly && (
				<CounterpartiesSection
					mandatory
					fullWidth
					withSeparator
					order={{
						recipients_list: getValues('recipients_list'),
						order_type: typeValue,
					}}
				/>
			)}
		</FoldableSection>
	);
};
