/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerFooter } from '../Drawer/Drawer';
import { MaterialDateTimeInput } from 'src/designSystem/Form/MaterialInput/MaterialDateTimeInput';
import { Controller, useFormContext } from 'react-hook-form';
import { isBeforeToday } from 'src/_helpers/date';
import { useWatchPhysicalFields } from './physical/useWatchPhysicalFields';
import * as Styled from './styled';

export const CreateOrderDrawerFooter = ({
	progress = 0,
	onSubmit,
	onCancel,
	previewAlwaysAvailable = false,
	isSubmitting,
}) => {
	const { t } = useTranslation();

	const {
		formState: { errors },
	} = useFormContext();

	const { allDocumentsUploadedValue, isEditing, validityValue } = useWatchPhysicalFields();

	return (
		<DrawerFooter progress={progress} className="create-order-drawer-footer">
			<Controller
				name="validity"
				render={({ field }) => (
					<MaterialDateTimeInput
						required
						label={t('validity')}
						id="validity"
						error={!!errors[field.name]}
						popupHeaderTitle={t('validity')}
						readOnly
						isTimeDefault={!!validityValue.time}
						filterDate={date => !isBeforeToday(date)}
						renderDateToggleButton
						{...field}
					/>
				)}
			/>
			{isEditing ? (
				<Styled.Buttons>
					<Styled.CancelButton $isEditing onClick={onCancel}>
						{t('cancel')}
					</Styled.CancelButton>
					<Styled.PreviewButton
						onClick={onSubmit}
						disabled={!allDocumentsUploadedValue}
						loading={isSubmitting}
						$isEditing
					>
						{t('save')}
					</Styled.PreviewButton>
				</Styled.Buttons>
			) : (
				<Styled.PreviewButton
					onClick={onSubmit}
					disabled={!allDocumentsUploadedValue && !previewAlwaysAvailable}
				>
					{t('preview_order')}
				</Styled.PreviewButton>
			)}
		</DrawerFooter>
	);
};
