/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import NumberFormat from 'react-number-format';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import { InformativeField } from './InformativeField';

const TotalQuantityField = ({ path, t }) => {
	const { totalVolumeValue, runsValue } = useWatchPaperFields(path);

	return (
		<InformativeField
			title={t('total_quantity')}
			subtitle={
				!!runsValue ? (
					<>
						<NumberFormat
							thousandSeparator
							displayType="text"
							value={totalVolumeValue}
						/>{' '}
						{t('price_unit_short_metric_ton')}
					</>
				) : (
					<>---</>
				)
			}
			dataTest="total-quantity"
		/>
	);
};

export const totalQuantityField = (path, t) => <TotalQuantityField path={path} t={t} />;
