/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useQuery } from 'react-query';
import { listPaperOrderPresets } from 'src/_api/orders.api';

export const useAllPaperPresets = enabled => {
	return useQuery('all-paper-presets', listPaperOrderPresets, {
		enabled,
		staleTime: Infinity,
		select: items => {
			const incos = [];
			const grades = [];

			if (items && items.length) {
				items.forEach(item => {
					if (!grades.includes(item.grade_id)) {
						grades.push(item.grade_id);
					}

					if (!incos.includes(item.inco_id)) {
						incos.push(item.inco_id);
					}
				});
			}

			return { incos, grades, presets: items };
		},
	});
};
