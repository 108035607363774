/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

const refetchInterval = 60000;

export const defaultNotificationsSettings = {
	refetchInterval,
	refetchIntervalInBackground: refetchInterval,
	refetchOnWindowFocus: true,
};
