/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled, { keyframes } from 'styled-components';

export const MenuItemWithIndicator = ({ count, isLoading, isActive = false, IconComponent }) => (
	<IconContainer {...(isLoading && { 'data-test': 'loading' })}>
		<IconComponent color={isActive ? 'white' : 'inherit'} />
		{count > 0 ? (
			<NotificationDot>
				<NotificationNumber className="notification-number" data-test="notification-number">
					{count > 99 ? '99+' : count}
				</NotificationNumber>
			</NotificationDot>
		) : null}
	</IconContainer>
);

const IconContainer = styled.div`
	position: relative;

	&:hover {
		.notification-number {
			animation: none;
		}
	}
`;

const NotificationDot = styled.div`
	position: absolute;
	top: -9px;
	right: -21px;
	border-radius: 50%;
	box-sizing: content-box;
	border: 4px solid var(--neutral-1000);
`;

const pulseAnimation = keyframes`
	0% {
		box-shadow: 0 0 0 0 rgba(237, 82, 82, 0.8);
	}
	80% {
		box-shadow: 0 0 0 10px rgba(237, 82, 82, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(237, 82, 82, 0);
	}
`;

const NotificationNumber = styled.span`
	font-size: 9px;
	font-weight: bold;
	letter-spacing: 0.2px;
	line-height: 9px;
	color: var(--text-color-default);
	width: 22px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--red-400);
	box-shadow: 0 0 0 rgba(237, 82, 82, 0.4);
	animation: ${pulseAnimation} 1s infinite;
	border-radius: 11px;
`;
