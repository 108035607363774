/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { KeyDetailsSection } from 'src/components/ViewOrderDrawer/Content/KeyDetailsSection/KeyDetailsSection';
import { OrderCreatorShipmentSection } from 'src/components/ViewOrderDrawer/Content/OrderCreatorShipmentSection/OrderCreatorShipmentSection';
import { ShipmentSection } from 'src/components/ViewOrderDrawer/Content/ShipmentSection/ShipmentSection';
import { PricingSection } from 'src/components/ViewOrderDrawer/Content/PricingSection/PricingSection';
import { AdditionalInformationSection } from 'src/components/ViewOrderDrawer/Content/AdditionalInformationSection/AdditionalInformationSection';
import { CounterpartiesSection } from 'src/components/ViewOrderDrawer/Content/CounterpartiesSection/CounterpartiesSection';
import { DocumentsSection } from 'src/components/ViewOrderDrawer/Content/DocumentsSection/DocumentsSection';
import { useShouldShowShipmentSection } from 'src/components/ViewOrderDrawer/helpers/useShouldShowShipmentSection';
import * as Styled from 'src/components/ViewOrderDrawer/styled';

export const OrderPreviewContent = () => {
	const {
		[DrawerContextKeys.createDrawer]: { orderPreviewData },
	} = useDrawerContext();

	const shouldShowShipmentSection = useShouldShowShipmentSection(orderPreviewData);
	return (
		<Styled.DrawerContentWrapper>
			<Styled.TopContent>
				<KeyDetailsSection order={orderPreviewData} />
			</Styled.TopContent>
			<Styled.ScrollableContent>
				<OrderCreatorShipmentSection order={orderPreviewData} />
				<PricingSection order={orderPreviewData} />
				<Styled.Divider />
				{shouldShowShipmentSection && (
					<>
						<ShipmentSection order={orderPreviewData} />
						<Styled.Divider />
					</>
				)}
				<AdditionalInformationSection order={orderPreviewData} />
				{!!orderPreviewData.recipients_list?.length && (
					<>
						<Styled.Divider />
						<CounterpartiesSection order={orderPreviewData} showSeenBy={false} />
					</>
				)}
				{orderPreviewData.files.length > 0 && (
					<>
						<Styled.Divider />
						<DocumentsSection order={orderPreviewData} />
					</>
				)}
			</Styled.ScrollableContent>
		</Styled.DrawerContentWrapper>
	);
};
