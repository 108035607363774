/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';

export const Separator = styled.div`
	background: var(--neutral-1000);
	height: 2px;
	width: 100%;
	margin: 16px 0;

	${media.ll`
		margin: 20px 0;
	`}

	${media.xl`
		margin: 24px 0;
	`}

	& + & {
		display: none;
	}
`;
