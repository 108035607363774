/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { trackTradeAnalytics } from 'src/_api';
import { getUserId } from 'src/_store/selectors';

export const useExecutionAnalytics = ({ tradeId, activityLogs = [], on = true }) => {
	const currentUserID = useSelector(getUserId);

	const { mutate: mutateAnalytics } = useMutation(trackTradeAnalytics, {
		onError: error => {
			console.log('Error on setting analytics: ', error);
		},
	});

	useEffect(() => {
		if (on) {
			mutateAnalytics({
				user_id: currentUserID,
				trade_id: tradeId,
				activity_logs: activityLogs,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [on]);
};
