/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Route } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { loadableRetry } from '../loadableRetry';
import { ErrorBoundary } from '../ErrorBoundary';
import { ExecutionDetails } from 'src/containers/ExecutionDetails/ExecutionDetails';

const DetectStep = loadableRetry(
	() => import('src/containers/ExecutionDetails/components/physical/steps/DetectStep'),
	{
		resolveComponent: components => components.DetectStep,
	}
);

const TradeConfirmation = loadableRetry(
	() =>
		import(
			'src/containers/ExecutionDetails/components/physical/steps/TradeConfirmation/TradeConfirmation'
		),
	{
		resolveComponent: components => components.TradeConfirmation,
	}
);

const Signatures = loadableRetry(
	() => import('src/containers/ExecutionDetails/components/physical/steps/Signatures/Signatures'),
	{
		resolveComponent: components => components.Signatures,
	}
);

const Instructions = loadableRetry(
	() =>
		import(
			'src/containers/ExecutionDetails/components/physical/steps/Instructions/Instructions'
		),
	{
		resolveComponent: components => components.Instructions,
	}
);

const ShippingAdvice = loadableRetry(
	() =>
		import(
			'src/containers/ExecutionDetails/components/physical/steps/ShippingAdvice/ShippingAdvice'
		),
	{
		resolveComponent: components => components.ShippingAdvice,
	}
);

const Invoicing = loadableRetry(
	() => import('src/containers/ExecutionDetails/components/physical/steps/Invoicing/Invoicing'),
	{
		resolveComponent: components => components.Invoicing,
	}
);

const VesselNomination = loadableRetry(
	() =>
		import(
			'src/containers/ExecutionDetails/components/physical/steps/VesselNomination/VesselNomination'
		),
	{
		resolveComponent: components => components.VesselNomination,
	}
);

const ShippingTender = loadableRetry(
	() =>
		import(
			'src/containers/ExecutionDetails/components/physical/steps/ShippingTender/ShippingTender'
		),
	{
		resolveComponent: components => components.ShippingTender,
	}
);

const ShippingDocuments = loadableRetry(
	() =>
		import(
			'src/containers/ExecutionDetails/components/physical/steps/ShippingDocuments/ShippingDocuments'
		),
	{
		resolveComponent: components => components.ShippingDocuments,
	}
);

export const physicalExecutionRoutes = {
	path: ROUTES.tradeDetails,
	element: (
		<ErrorBoundary>
			<ExecutionDetails />
		</ErrorBoundary>
	),
	children: (
		<>
			<Route index element={<DetectStep />} />
			<Route
				path={ROUTES.physicalExecutionTradeConfirmation}
				element={<TradeConfirmation />}
			/>
			<Route path={ROUTES.physicalExecutionSignatures} element={<Signatures />} />
			<Route path={ROUTES.physicalExecutionVesselNomination} element={<VesselNomination />} />
			<Route path={ROUTES.physicalExecutionShippingAdvice} element={<ShippingAdvice />} />
			<Route path={ROUTES.physicalExecutionInvoicing} element={<Invoicing />} />
			<Route path={ROUTES.physicalExecutionShippingTender} element={<ShippingTender />} />
			<Route path={ROUTES.physicalExecutionInstructions} element={<Instructions />} />
			<Route
				path={ROUTES.physicalExecutionShippingDocuments}
				element={<ShippingDocuments />}
			/>
			{/* TODO: Remove after steps completion */}
			<Route path="*" element={<span>NOT IMPLEMENTED</span>} />
		</>
	),
};
