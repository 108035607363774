/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import classNames from 'clsx';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';
import { h400 } from '../Typography';
import { InputLabel } from './InputLabel';
import { ReactComponent as ShowPasswordIcon } from 'src/assets/icons/password_show.svg';
import { ReactComponent as HidePasswordIcon } from 'src/assets/icons/password_hide.svg';
import { InputError } from './InputError';
import { ReactComponent as InputValidIcon } from 'src/assets/icons/input_valid.svg';

const PasswordIcon = ({ showPassword }) => {
	return showPassword ? (
		<HidePasswordIcon data-test="hide-password-icon" />
	) : (
		<ShowPasswordIcon data-test="show-password-icon" />
	);
};

const InputeValidIcon = ({ successValidationTestId }) => {
	return (
		<IconPlaceholder>
			<InputValidIcon data-test={successValidationTestId} />
		</IconPlaceholder>
	);
};

export const LabeledInput = ({
	label,
	placeholder,
	onChange,
	name,
	type = 'text',
	value,
	className,
	wrapperClassName,
	rightLabel,
	mandatory = false,
	tabIndex,
	errors = [],
	successValidationTestId,
	dataTestId,
	...rest
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const hasError = errors.some(e => e.hasError);
	const hasErrorMessage = errors.some(e => e.text);

	const errorsToDisplay = errors.filter(e => e.hasError);

	const errorRows = errors.length;

	const additionalClassNames = classNames(
		'form-control',
		hasError ? 'is-invalid' : 'input-field',
		className
	);

	return (
		<div className={wrapperClassName} data-test={dataTestId}>
			<InputLabel label={label} rightLabel={rightLabel} mandatory={mandatory} />
			<StyledInput
				inverted
				fluid
				icon={
					type === 'password' ? (
						<PasswordIconPlaceholder onClick={() => setShowPassword(p => !p)}>
							<PasswordIcon showPassword={showPassword} />
						</PasswordIconPlaceholder>
					) : successValidationTestId ? (
						<InputeValidIcon successValidationTestId={successValidationTestId} />
					) : null
				}
				name={name}
				value={value}
				type={type === 'password' && showPassword === false ? 'password' : ''}
				className={additionalClassNames}
				placeholder={placeholder}
				onChange={onChange}
				tabIndex={tabIndex}
				{...rest}
			/>
			{hasErrorMessage && (
				<ErrorPlaceholder $errorRows={errorRows}>
					{errorsToDisplay.map((em, index) => (
						<InputError message={em.text} key={index} />
					))}
				</ErrorPlaceholder>
			)}
		</div>
	);
};

const PasswordIconPlaceholder = styled.div`
	cursor: pointer;
	position: absolute;
	right: 22px;
	top: 16px;
`;

const IconPlaceholder = styled.div`
	position: absolute;
	right: 16px;
	top: 16px;
`;

const StyledInput = styled(Input)`
	width: 100%;

	.ui.fluid.input& > input {
		margin: 0;
	}

	.ui.input& > input {
		border: 2px solid;
		border-color: #eeedf3;
		border-radius: var(--large-border-radius);
		padding: 16px 20px;
		font-size: 14px;
		box-sizing: border-box;
		height: 56px;

		::placeholder {
			${h400}
			color: var(--neutral-300) !important;
		}

		:focus {
			border-color: var(--primary-400);
		}
	}

	.ui.icon.input& > input {
		padding-right: 50px !important;
	}

	.ui.input&.error > input {
		border-color: var(--red-400);
		background-color: transparent;
	}
`;

export const ErrorPlaceholder = styled.div`
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	height: calc(4px + 16px * ${({ $errorRows }) => $errorRows});
`;
