/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useRef, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { GuidebookContent } from '../Guidebook/components/GuidebookContent';
import { useWebGuidebook } from 'src/_api';
import { useTogglable } from 'src/_helpers/useTogglable';
import * as Styled from './styled';
import { useFeatureFlags } from 'src/featureFlags/FeatureFlagsContext';
import { ReactComponent as CloseMobileGuidebookMenu } from 'src/assets/icons/mobile/close-mobile-guidebook-menu.svg';
import { ReactComponent as OpenMobileGuidebookMenu } from 'src/assets/icons/mobile/open-mobile-guidebook-menu.svg';
import { MobileGuidesNavigation } from 'src/components/MobileGuidesNavigation/MobileGuidesNavigation';
import { ROUTES } from 'src/constants/routes';
import { FullscreenImage } from 'src/components/FullscreenImage/FullscreenImage';

export const MobileGuide = () => {
	const [activeChapter, setActiveChapter] = useState();
	const containerScrollRef = useRef();
	const [isOpen, { toggle: toggleMobileGuidebookMenu }] = useTogglable(false);
	const { isLoaded, featureFlags } = useFeatureFlags();

	const isDisabledByFeatureFlag =
		isLoaded && !featureFlags?.find(ff => ff.name === 'guidebook')?.isActive;

	const { data: guidebookChapters, isFetched, isError, isLoading } = useWebGuidebook(
		{
			version: 'web',
		},
		{
			enabled: !isDisabledByFeatureFlag,
		}
	);

	if (isLoading) {
		return (
			<Styled.GuidebookDimmer active fullOpacity>
				<Loader active />
			</Styled.GuidebookDimmer>
		);
	}

	if ((isFetched && isError) || isDisabledByFeatureFlag) {
		return null;
	}

	return (
		<Styled.MobileGuidebookWrapper ref={containerScrollRef}>
			<GuidebookContent
				chapters={guidebookChapters}
				setActiveChapter={setActiveChapter}
				containerScrollRef={containerScrollRef}
			/>
			<MobileGuidesNavigation
				chapters={guidebookChapters}
				activeChapter={activeChapter}
				isOpen={isOpen}
				toggleMobileGuidebookMenu={toggleMobileGuidebookMenu}
				route={ROUTES.mobileGuide}
			/>
			<Styled.MobileGuidebookMenu onClick={toggleMobileGuidebookMenu}>
				{isOpen ? <CloseMobileGuidebookMenu /> : <OpenMobileGuidebookMenu />}
			</Styled.MobileGuidebookMenu>
			<Styled.GuidebookDimmer active={isOpen} onClick={toggleMobileGuidebookMenu} />
			<FullscreenImage />
		</Styled.MobileGuidebookWrapper>
	);
};
