/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserIsAdmin } from 'src/_store/selectors';
import { TradeDocumentsComponent } from '../components/paper/tabs/TradeDocuments/TradeDocumentsComponent';
import { TradeAdminTab } from '../components/paper/tabs/admin/TradeAdminTab';
import { PaperTradeInfo } from '../components/paper/tabs/PaperTradeInfo/PaperTradeInfo';
import { Market } from 'src/constants/contract';

export const usePaperTabs = ({ tradeData, tradeId, tradeStep }) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(0);
	const isAdmin = useSelector(getUserIsAdmin);

	const paperMarketTabs = useMemo(() => {
		if (tradeData?.market !== Market.Paper) {
			return null;
		}

		const paperTabs = [
			{
				menuItem: t('trade_info'),
				render: () => {
					return tradeData ? <PaperTradeInfo /> : null;
				},
			},
			{
				menuItem: t('documents_tab'),
				render: () => <TradeDocumentsComponent />,
			},
		];

		if (isAdmin) {
			paperTabs.push({
				menuItem: t('admin'),
				render: () => <TradeAdminTab tradeId={tradeId} tradeStep={tradeStep} />,
			});
		}
		return paperTabs;
	}, [t, tradeStep, isAdmin, tradeData, tradeId]);

	return { paperMarketTabs, activeTab, setActiveTab };
};
