/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';

export const AcceptButton = styled(PrimaryButton)`
	&.disabled {
		background-color: var(--neutral-700);
	}
`;

const tooltipButton = css`
	background-color: var(--neutral-700);
	color: var(--neutral-300);
	box-shadow: none;
	cursor: default;
`;

export const InactiveTooltipAcceptButton = styled(PrimaryButton)`
	${tooltipButton}

	&:hover {
		${tooltipButton}
	}
`;
