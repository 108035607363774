/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { isEmailFormatValid } from 'src/_helpers/email';
import { useQueryParams } from 'src/_helpers/router';
import { OnboardingLayout } from 'src/components/OnboardingLayout/OnboardingLayout';
import { Trans, useTranslation } from 'react-i18next';
import * as Styled from './styled';
import { useMutation } from 'react-query';
import { SECURITY_REASONS_CHANGE_PASSWORD, resetUserPasswordRequestEmail } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { ROUTES } from 'src/constants/routes';

export const SendPasswordResetEmail = () => {
	const { email, reason } = useQueryParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { addToast } = useToast();

	useEffect(() => {
		if (!email || !isEmailFormatValid(email)) {
			navigate(ROUTES.login);
		}
	}, [email, navigate]);

	const { mutate: resendPasswordResetEmail } = useMutation(
		() => resetUserPasswordRequestEmail({ email }),
		{
			onSuccess: () => {
				addToast({
					kind: 'success',
					message: t('email_has_been_sent_again'),
					topPage: true,
				});
			},
			onError: () => {
				addToast({ kind: 'error', message: t('something_went_wrong'), topPage: true });
			},
		}
	);

	const changeDueToSecurityReasons = reason === SECURITY_REASONS_CHANGE_PASSWORD;

	return (
		<OnboardingLayout>
			<Styled.OnboardingHeader>
				{changeDueToSecurityReasons
					? t('set_a_new_password')
					: t('resend_password_reset_email_header')}
			</Styled.OnboardingHeader>

			<Styled.OnboardingSeparator />

			<Styled.OnboardingInfo>
				{changeDueToSecurityReasons ? (
					<>
						{t('please_set_password_before_signing_in')}
						<Trans i18nKey="we_have_sent_password_reset_link_to" values={{ email }}>
							<strong />
						</Trans>
					</>
				) : (
					<Trans i18nKey={'resend_password_reset_email_body'} values={{ email }}>
						<strong />
					</Trans>
				)}
			</Styled.OnboardingInfo>

			<Styled.OnboardingFooter>
				{t('resend_password_reset_email_info')}
				{t('havent_received_email')}{' '}
				<Styled.OnboardingLink onClick={resendPasswordResetEmail}>
					{t('resend_email')}
				</Styled.OnboardingLink>
			</Styled.OnboardingFooter>
		</OnboardingLayout>
	);
};
