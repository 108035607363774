/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { t100, m100 } from 'src/components/Typography';

export const LargeCheckbox = styled(SemanticCheckbox)`
	.ui.checkbox& {
		${t100}
		line-height: 20px;
		color: var(--neutral-300);
	}

	.ui.checkbox& label {
		color: var(--neutral-300);
	}

	.ui.checkbox& label:before {
		width: 20px;
		height: 20px;
		color: var(--neutral-300);
		border: 1px solid var(--neutral-400);
		background-color: transparent;

		${({ $isError }) =>
			$isError &&
			`
			border-color: var(--input-error-border-color);
		`}
	}

	.ui.checkbox:hover& label:before {
		border-color: var(--neutral-300);
	}

	.ui.checkbox& label:after {
		background: var(--primary-400);
		border-radius: var(--default-border-radius);
		width: 20px;
		height: 20px;
		color: var(--white);
	}

	.ui.checkbox:hover& label:after {
		background: var(--primary-600);
	}

	&.ui.checkbox.checked.disabled {
		label {
			color: var(--neutral-300);
			opacity: 1;

			&:before {
				background: var(--neutral-800);
				opacity: 0.5;
				border-color: var(--neutral-800);
			}

			&:after {
				background: var(--neutral-800);
				opacity: 0.5;
			}
		}
	}
`;

export const ErrorMessage = styled.span`
	color: var(--input-error-label-text-color);
	display: block;
	margin-top: 4px;
	${m100}
`;
