/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateRange, parseDateStringAsUTC } from 'src/_helpers/date';
import { futuresContractLabel } from 'src/_helpers/futuresContracts';
import { getCounterpartyLabel, useSellerBrokerBuyer } from 'src/_helpers/order';
import { formatPrice } from 'src/_helpers/price';
import { H600 } from 'src/components/Typography';
import { VolumeDisplay } from 'src/components/Volume';
import { tCurrency } from 'src/constants/contract';
import { tPriceUnit } from 'src/constants/product';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';

export const usePaperCounterSummary = ({ order, lastCounter, counterFormData, isFOBParanagua }) => {
	const { t } = useTranslation();

	const { isBrokerOrder, isBasisPrice, isSellOrder } = useOrderDetails(order);

	const counterpartyLabel = getCounterpartyLabel(t, order);

	const counterData = counterFormData || lastCounter;

	const { sellerCompany, buyerCompany, brokerCompany } = useSellerBrokerBuyer(order, [
		lastCounter,
	]);

	const brokerLabel = `${t('broker')} (${isSellOrder ? t('seller') : t('buyer')})`;

	const summaryData = [
		{
			title: t('seller'),
			value: sellerCompany,
		},
		isBrokerOrder && {
			title: brokerLabel,
			value: brokerCompany,
		},
		{
			title: t('buyer'),
			value: buyerCompany,
		},
		{
			title: t('product'),
			value: order.product.name,
		},
		{
			title: counterData.runs ? t('total_quantity') : t('quantity'),
			value: (
				<VolumeDisplay
					as={H600}
					value={isFOBParanagua ? counterData.quantity : counterData.volume}
				/>
			),
		},
		isFOBParanagua && {
			title: t('total_quantity'),
			value: <VolumeDisplay as={H600} value={counterData.volume} />,
		},
		isBasisPrice && {
			title: t('futures_contract'),
			value: `${futuresContractLabel(
				t,
				order.product.futures_contracts,
				order.futures_contract
			)} ${moment(order.futures_contract_date).utc().format('MMM YYYY')}`,
		},
		{
			title: tPriceUnit(t, order.price_unit),
			value: formatPrice(counterData.price, {
				currency: tCurrency(t, order.currency),
			}),
		},
		{
			title: t('shipment'),
			value: formatDateRange({
				format: order.delivery_mode,
				startDate: parseDateStringAsUTC(order.delivery_date_from),
				endDate: parseDateStringAsUTC(order.delivery_date_to),
			}),
		},
	].filter(Boolean);

	return { summaryData, counterpartyLabel };
};
