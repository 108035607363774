/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const ErrorPlaceholder = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	height: 52px;
`;

export const ErrorMessage = styled.div`
	font-family: var(--font-base);
	font-size: 10px;
	color: var(--red-400);

	&::before {
		content: '+';
		display: inline-block;
		transform: rotate(-45deg) translateX(-2px);
		font-size: 16px;
		font-weight: 500;
	}
`;
