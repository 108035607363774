/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import {
	fetchFromExchangeApi,
	map404ToEmptyList,
	map204ToNull,
	map404ToEmptyObject,
} from './model';
import { requestKYCSchema } from './model/model.kyc';
import { KYCTabs, KYCStatus, KYCTypes } from 'src/constants/kyc';
import { getNormalizedCountries } from './products.api';

export const requestKYC = async body => {
	try {
		await requestKYCSchema.validate(body);
		return await fetchFromExchangeApi(`kyc/requests`, {
			method: 'POST',
			body: {
				...body,
			},
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const deleteMyKYC = async () => {
	return await fetchFromExchangeApi('kyc', {
		method: 'DELETE',
		mapStatusToResult: map204ToNull,
	});
};

export const getAreKYCsRequestsAvailable = async query => {
	const result = await fetchFromExchangeApi('kyc/requests_v2', {
		query: {
			...query,
			_limit: 1,
		},
		mapStatusToResult: map404ToEmptyObject,
	});

	if (result.data?.length > 0) {
		return true;
	}

	return false;
};

export const getPendingKYCs = async query => {
	const result = await fetchFromExchangeApi('kyc/requests_v2', {
		query,
		mapStatusToResult: map404ToEmptyObject,
	});

	const items = result.data;
	const cursor = result.meta?.next_page_cursor || '';

	if (items.length === 0) {
		return {
			items: [],
			cursor,
		};
	}

	const countriesList = [
		...new Set(
			items.map(item => {
				if (item.type === KYCTabs.INBOUND) {
					return item.requester_user.company.country_code;
				}
				return item.responder_user.company.country_code;
			})
		),
	];

	const normalizedCountries = await getNormalizedCountries({
		query: { country_id: countriesList },
	});

	const itemsWithCountries = items.map(item => {
		return {
			...item,
			companyCountry:
				item.type === KYCTabs.INBOUND
					? normalizedCountries[item.requester_user.company.country_code]
					: normalizedCountries[item.responder_user.company.country_code],
		};
	});

	return {
		items: itemsWithCountries,
		cursor,
	};
};

export const fetchActiveConversations = async query => {
	const result = await fetchFromExchangeApi('kyc/conversations_v2', {
		query,
		mapStatusToResult: map404ToEmptyObject,
	});

	return {
		items: result.data || [],
	};
};

export const getActiveConversations = async query => {
	const result = await fetchFromExchangeApi('kyc/conversations_v2', {
		query,
		mapStatusToResult: map404ToEmptyObject,
	});

	const items = result.data;
	const cursor = result.meta?.next_page_cursor || '';

	if (items.length === 0) {
		return {
			items: [],
			cursor,
		};
	}

	const countriesList = [
		...new Set(
			items.map(item => {
				if (query.type === KYCTabs.INBOUND) {
					return item.request.requester_user.company.country_code;
				}
				return item.request.responder_user.company.country_code;
			})
		),
	];

	const normalizedCountries = await getNormalizedCountries({
		query: { country_id: countriesList },
	});

	const activeRequests = items.map(({ _key, request }) => {
		return {
			conversationId: _key,
			...request,
			companyCountry:
				query.type === KYCTabs.INBOUND
					? normalizedCountries[request.requester_user.company.country_code]
					: normalizedCountries[request.responder_user.company.country_code],
		};
	});

	return {
		items: activeRequests,
		cursor,
	};
};

export const getActiveConversationsByType = async ({ type, ...query }) => {
	if (!query._limit) {
		query._limit = 20;
	}
	const { items } = await fetchActiveConversations(query);

	const conversation = items.find(item => item.request.type === type);

	return conversation;
};

export const setKYCRequestVisibility = async ({ kycId, status }) => {
	let requestStatus = '';
	if (status === KYCStatus.HIDDEN) {
		requestStatus = 'unhide';
	} else {
		requestStatus = 'hide';
	}

	return await fetchFromExchangeApi(`kyc/requests/${kycId}/${requestStatus}`, {
		method: 'POST',
	});
};

export const acceptKYCRequest = async ({ kycId }) => {
	try {
		return await fetchFromExchangeApi(`kyc/requests/${kycId}/accept`, {
			method: 'POST',
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const fetchKYCConversation = async ({ conversationId }) => {
	return fetchFromExchangeApi(`kyc/conversations/${conversationId}`, {
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getKYCConversation = async ({ conversationId }) => {
	const conversation = await fetchKYCConversation({ conversationId });

	conversation.vaults = (conversation.vaults || []).reduce((acc, vault) => {
		return { ...acc, [vault._key]: vault };
	}, {});

	if (conversation.request.type === KYCTypes.OUTBOUND) {
		conversation.documents = Object.values(conversation.vaults).reduce((acc, vault) => {
			if (vault?.documents) {
				return [...acc, ...vault.documents];
			}
			return [...acc];
		}, []);
	}

	return conversation;
};

export const lockConversationVault = async ({ conversationId, vaultId }) => {
	return await fetchFromExchangeApi(
		`kyc/conversations/${conversationId}/vaults/${vaultId}/lock`,
		{
			method: 'POST',
		}
	);
};

export const unlockConversationVault = async ({ conversationId, vaultId }) => {
	return await fetchFromExchangeApi(
		`kyc/conversations/${conversationId}/vaults/${vaultId}/unlock`,
		{
			method: 'POST',
		}
	);
};

export const counterKYCConversation = async ({ conversationId }) => {
	try {
		return await fetchFromExchangeApi(`kyc/conversations/${conversationId}/counter`, {
			method: 'POST',
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};
