/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useRef } from 'react';
import clsx from 'clsx';
import { toSemanticSort } from 'src/_helpers/sort';
import { OverflowHeaderCell } from 'src/components/Tooltip/OverflowText';
import { useIsOverflowed } from 'src/_helpers/useIsOverflowed';
import * as Styled from './styled';

export const DefaultTableRow = ({ children }) => <tr>{children}</tr>;

export const HeaderCell = ({
	name,
	width,
	label,
	shortLabel,
	disableSort,
	allowedSort,
	alignRightCellText,
	alignCenterCellText,
	onSortChange,
	sort,
	headerCellStyle = {},
	headerClassName,
}) => {
	return (
		<Styled.HeaderCell
			className={clsx(
				headerClassName,
				alignRightCellText
					? 'alignedRight'
					: alignCenterCellText
					? 'alignedCenter'
					: 'alignedLeft'
			)}
			style={{
				textAlign: alignRightCellText ? 'right' : alignCenterCellText ? 'center' : 'left',
				...headerCellStyle,
			}}
			$width={width}
			$disableSort={disableSort}
			sorted={sort?._sort === name ? toSemanticSort(sort._order) : null}
			{...(!disableSort && {
				onClick: () => onSortChange(name, allowedSort),
			})}
		>
			<HeaderCellLabel label={label} shortLabel={shortLabel} />
		</Styled.HeaderCell>
	);
};

export const HeaderCellLabel = ({ label, shortLabel }) => {
	const headerCellRef = useRef();
	const isOverflowed = useIsOverflowed(headerCellRef, null);

	return (
		<OverflowHeaderCell headerCellRef={headerCellRef} tooltipContent={label}>
			<Styled.Label
				ref={headerCellRef}
				className={isOverflowed ? 'label-overflowed' : 'label'}
			>
				<HeaderLabelSpan long={label} short={shortLabel} />
			</Styled.Label>
		</OverflowHeaderCell>
	);
};

export const HeaderLabelSpan = ({ long, short }) => {
	const longAndShort = !!long && !!short;
	return (
		<>
			{short && (
				<Styled.Span className={`short-label ${longAndShort ? 'l-s' : ''}`}>
					{short}
				</Styled.Span>
			)}
			{long && (
				<Styled.Span className={`long-label ${longAndShort ? 'l-s' : ''}`}>
					{long}
				</Styled.Span>
			)}
		</>
	);
};

export const RowColumns = ({ item, columns, ...props }) => {
	return columns.map(
		({
			label,
			shortLabel,
			testId,
			width,
			columnKey,
			name,
			disableSort,
			allowedSort,
			alignRightCellText,
			alignCenterCellText,
			headerCellStyle,
			headerClassName,
			Cell,
			...rest
		}) => (
			<Cell
				key={columnKey || name}
				item={item}
				linkTo={item?.linkTo}
				style={{
					textAlign: alignRightCellText
						? 'right'
						: alignCenterCellText
						? 'center'
						: 'left',
				}}
				$width={width}
				{...(testId && { 'data-test': testId })}
				{...rest}
				{...props}
				className={clsx(rest.className, props.className, { center: alignCenterCellText })}
			/>
		)
	);
};
