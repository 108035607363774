/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { Dropdown } from 'semantic-ui-react';
import { StrokeButton } from 'src/components/Buttons/StrokeButton';
import { ReactComponent as EllipsisIcon } from 'src/assets/icons/ellipsis.svg';

export const DownloadDropdown = styled(Dropdown)`
	&.ui.dropdown .menu {
		background-color: var(--neutral-850);
		width: 150px;
		margin-top: 4px;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
		border-radius: var(--large-border-radius);
		overflow: hidden;
	}
`;

export const DownloadEllipsisIcon = styled(EllipsisIcon)`
	color: var(--white);
`;

export const DownloadButton = styled(StrokeButton)`
	width: 120px;

	div {
		width: 16px;
		height: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	${media.ll`
		width: 124px;
	`}
`;
