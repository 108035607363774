/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CUSTOM_ADDITIONAL_ORDER_DOCUMENTS } from 'src/constants/files';
import { DocumentUploadModal } from '../../DocumentUploadModal/DocumentUploadModal';
import * as Styled from './styled';

export const DocumentUpload = () => {
	const { t } = useTranslation();
	const [uncategoriedFile, setUncategorizedFile] = useState(null);

	const onUploadComplete = useCallback(() => {
		setUncategorizedFile(null);
	}, []);

	const onFileAdded = useCallback(ev => {
		if (ev.target.files && ev.target.files.length > 0) {
			setUncategorizedFile(ev.target.files[0]);
		}
	}, []);

	return (
		<>
			<Styled.UploadButton
				accept={CUSTOM_ADDITIONAL_ORDER_DOCUMENTS}
				onChange={onFileAdded}
				label={t('upload')}
			/>
			<DocumentUploadModal onComplete={onUploadComplete} file={uncategoriedFile} />
		</>
	);
};
