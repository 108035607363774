/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { formatFuturesContractMonthToLetterCode } from './date';
import { PriceType } from 'src/constants/contract';

export const formatProductAndFuturesCode = (productCode = '', futuresContractDate) => {
	if (!futuresContractDate || !productCode) {
		return;
	}

	return productCode + formatFuturesContractMonthToLetterCode(futuresContractDate);
};

export const findSelectedFuturesContractObject = (order = {}) => {
	if (order.price_type !== PriceType.Basis) {
		return null;
	}

	const { futures_contract: futuresContract, product } = order;

	const productFuturesContracts = product.futures_contracts;

	return productFuturesContracts.find(fc => fc.futures_contract_id === futuresContract);
};

export const futuresContractLabel = (t, futuresContractsList = [], selectedFuturesContract) => {
	const productFuturesContract = futuresContractsList.find(
		fc => fc.futures_contract_id === selectedFuturesContract
	);

	return productFuturesContract?.label || selectedFuturesContract;
};
