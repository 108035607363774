/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { StrokeButton } from 'src/components/Buttons/StrokeButton';

export const AddButton = styled(StrokeButton)`
	padding-left: 7px;
	padding-right: 7px;
	margin-top: 24px;

	&:focus {
		border-color: var(--neutral-600);

		&:hover {
			border-color: var(--primary-600);
		}
	}

	& svg {
		width: 15px;
		height: 15px;
	}
`;
