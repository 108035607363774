/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import * as yup from 'yup';

export const sendInvitesSchema = yup.object().shape({
	emails: yup.array().of(yup.string()).required(),
});

export const createGroupSchema = yup.object().shape({
	name: yup.string().required(),
	description: yup.string(),
	users: yup.array().of(yup.string()).min(1),
});

export const createTeamSchema = yup.object().shape({
	name: yup.string().required(),
	description: yup.string(),
	user_ids: yup.array().of(yup.string()).min(1),
});
