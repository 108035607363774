/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const SHOW_REFRESH_TOAST = 'SHOW_REFRESH_TOAST';
export const HIDE_REFRESH_TOAST = 'HIDE_REFRESH_TOAST';

export const showRefreshToast = () => ({
	type: SHOW_REFRESH_TOAST,
});

export const hideRefreshToast = () => ({
	type: HIDE_REFRESH_TOAST,
});
