/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { media } from 'src/_helpers/media-queries';

export const FullscreenImage = () => {
	const {
		state: [visible, setVisible],
		src: [src, setSrc],
	} = useFullscreenImage();

	useEffect(() => {
		setVisible(!!src);
	}, [setVisible, src]);

	return (
		<FullscreenImagePortal>
			{visible && (
				<Background onClick={() => setSrc(null)}>
					<ImageWrapper>
						<img src={src} alt="" />
					</ImageWrapper>
					<CloseButton onClick={() => setSrc(null)}>
						<CloseIcon />
					</CloseButton>
				</Background>
			)}
		</FullscreenImagePortal>
	);
};

export const FullscreenImagePortal = ({ children }) => {
	const { containerRef } = useFullscreenImage();
	return createPortal(children, containerRef.current);
};

const Background = styled.div`
	z-index: 9999;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--neutral-1000);
`;

const ImageWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: calc(100vh - 16px);
	width: calc(100vw - 50px);
	display: flex;
	align-items: center;
	justify-content: center;

	${media.s`	
		height: calc(100vh - 70px);
		width: calc(100vw - 140px);
	`}

	& img {
		max-height: 100%;
		max-width: 100%;
	}
`;

const CloseButton = styled.button`
	width: 32px;
	height: 32px;
	padding: 0;
	position: absolute;
	top: 32px;
	right: 32px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 24px;
		height: 24px;
		color: #ffffff;
	}
`;

export const FullscreenContext = createContext({
	containerRef: null,
	state: [false, {}],
	src: [null, () => {}],
});

export const useFullscreenImage = () => {
	return useContext(FullscreenContext);
};

export const FullscreenProvider = ({ containerRef, children }) => {
	const [visible, setVisible] = useState(false);
	const [image, setImage] = useState(null);

	const value = {
		containerRef,
		state: [visible, setVisible],
		src: [image, setImage],
	};

	return <FullscreenContext.Provider value={value}>{children}</FullscreenContext.Provider>;
};
