/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useInView } from 'react-intersection-observer';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { useFullscreenImage } from 'src/components/FullscreenImage/FullscreenImage';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

import { rehypeLoomVideo } from './rehypeLoomVideo';

export const MarkdownArticleBody = ({ children }) => {
	return (
		<ReactMarkdown
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeRaw, rehypeSanitize, rehypeLoomVideo]}
			components={{
				img: ImageFullscreen,
				a: ArticleLink,
				span: MarkEmoji,
				p: Paragraph,
				iframe: Iframe,
			}}
		>
			{replaceEmojiNumberForSpanWithName(children)}
		</ReactMarkdown>
	);
};

const ImageFullscreen = ({ src, alt }) => {
	const {
		src: [, setImage],
	} = useFullscreenImage();

	return (
		<img
			src={src}
			alt={alt}
			loading="lazy"
			onClick={ev => {
				if (ev?.target?.src) {
					setImage(ev?.target?.src);
				}
			}}
		/>
	);
};

const Paragraph = ({ children, node }) => {
	const hasImage = node.children.some(({ tagName }) => tagName === 'img');
	const hasFewChildren = children.length > 1;

	return (
		<p
			className={
				hasImage
					? `guidebook-image-paragraph${
							hasFewChildren ? ' guidebook-image-paragraph_with-caption' : ''
					  }`
					: undefined
			}
		>
			{children}
		</p>
	);
};

const ArticleLink = ({ href, children }) => {
	const { pathname } = useLocation();
	const target = pathname.includes(`${ROUTES.news}/vosbor`) ? '_self' : '_blank';

	return (
		<a href={href} target={target} rel="noreferrer">
			{children}
		</a>
	);
};

const MarkEmoji = ({ name, children }) => {
	return (
		<span className={name === 'user-content-guidebook-pointer' ? 'guidebook-pointer' : ''}>
			{children}
		</span>
	);
};

const Iframe = ({ node }) => {
	const { ref, inView } = useInView({ triggerOnce: true });

	return (
		<iframe
			ref={ref}
			{...node.properties}
			src={inView ? node.properties.src : undefined}
			title={node.properties.title}
		/>
	);
};

export const replaceEmojiNumberForSpanWithName = markdown => {
	// 'name' attribute is kept by rehypeSanitize
	// but adds the prefix "user-content-" to it
	if (typeof markdown === 'string') {
		return markdown
			.replace(/0️⃣/gi, '<span name="guidebook-pointer">0</span>')
			.replace(/1️⃣/gi, '<span name="guidebook-pointer">1</span>')
			.replace(/2️⃣/gi, '<span name="guidebook-pointer">2</span>')
			.replace(/3️⃣/gi, '<span name="guidebook-pointer">3</span>')
			.replace(/4️⃣/gi, '<span name="guidebook-pointer">4</span>')
			.replace(/5️⃣/gi, '<span name="guidebook-pointer">5</span>')
			.replace(/6️⃣/gi, '<span name="guidebook-pointer">6</span>')
			.replace(/7️⃣/gi, '<span name="guidebook-pointer">7</span>')
			.replace(/8️⃣/gi, '<span name="guidebook-pointer">8</span>')
			.replace(/9️⃣/gi, '<span name="guidebook-pointer">9</span>')
			.replace(/🔟/gi, '<span name="guidebook-pointer">10</span>');
	}
	return markdown;
};
