/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { h400, h500, t150 } from 'src/components/Typography';
import { InfoTitle } from '../../sections/common';
import { UploadButton as BaseUploadButton } from 'src/components/Buttons/UploadButton';

export const FileList = styled.ul`
	display: grid;
	gap: 40px;
	margin-top: 24px;
`;

export const TradeDocumentsWrapper = styled.div`
	display: flex;
	flex-direction: column;

	padding: 25px 32px;

	${media.xl`
		padding: 25px 48px;
	`}
`;

export const TradeDocumentsHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
`;

export const HeaderTitle = styled(InfoTitle)`
	display: flex;
	align-items: center;
	margin-bottom: 0;
`;

export const NoDocumentsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 100px;
	margin-bottom: 20px;
`;

export const NoDocumentsTitle = styled.div`
	margin-top: 25px;
	margin-bottom: 12px;
	${h400}
`;

export const NoDocumentsDescriptions = styled.div`
	color: var(--neutral-300);
	${t150}
`;

export const SmallTitle = styled.h3`
	${h500}
	font-weight: bold;
	color: var(--neutral-300);
	margin-bottom: 8px;
`;

export const InstructionsContainer = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	padding: 28px 24px 30px;
	border: 2px solid var(--primary-400);
	border-radius: var(--large-border-radius);
`;

export const InfoMessage = styled.div`
	${h400}
	color: var(--text-color-default);
`;

export const TextWrapper = styled.div`
	flex: 1;
`;

export const IconWrapper = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: var(--neutral-500);

	&.isBuyerSide {
		background: var(--primary-400);
	}
`;

export const UploadButton = styled(BaseUploadButton)`
	width: auto;
	padding: 12px 24px;
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 8px;
`;
