/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
	SectionContainer,
	SectionTitle,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from '../../styled';
import { tToleranceLabel, tInspection, PortType } from 'src/constants/contract';
import { PortsData } from './PortsData';
import { isNil } from 'src/_helpers';
import { OverflowText } from 'src/components/Tooltip/OverflowText';

export const ShipmentSection = ({ order, changes = {} }) => {
	const { t } = useTranslation();

	return (
		<SectionContainer data-test="shipment-section">
			<SectionTitle>{t('shipment')}</SectionTitle>
			{(order.tolerance || order.inspection || changes.tolerance || changes.inspection) && (
				<SectionGrid>
					{(order.tolerance || changes.tolerance) && (
						<SectionGridContent className={clsx({ changed: changes.tolerance })}>
							<SectionGridLabel>
								<OverflowText>{tToleranceLabel(t, order.inco._key)}</OverflowText>
							</SectionGridLabel>
							<SectionGridText data-test="shipment-section-tolerance">
								{order.tolerance}
								{isNil(order.tolerance) ? '---' : ' %'}
							</SectionGridText>
						</SectionGridContent>
					)}
					{(order.inspection || changes.inspection) && (
						<SectionGridContent className={clsx({ changed: changes.inspection })}>
							<SectionGridLabel>{t('inspection')}</SectionGridLabel>
							<SectionGridText data-test="shipment-section-inspection">
								{order.inspection ? tInspection(t, order.inspection) : '---'}
							</SectionGridText>
						</SectionGridContent>
					)}
				</SectionGrid>
			)}
			{order.ports_loading.length > 0 && (
				<PortsData
					inco={order.inco}
					ports={order.ports_loading}
					portType={PortType.LOADING}
					changes={changes}
				/>
			)}
			{order.ports_discharging.length > 0 && (
				<PortsData
					inco={order.inco}
					ports={order.ports_discharging}
					portType={PortType.DISCHARGE}
					changes={changes}
				/>
			)}
		</SectionContainer>
	);
};
