/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import * as yup from 'yup';

export const createVaultSchema = yup.object().shape({
	name: yup.string().required(),
});

export const renameVaultSchema = yup.object().shape({
	name: yup.string().required(),
});

export const createVaultDocumentsSchema = yup.object().shape({
	file_ids: yup.array().of(yup.string()).required(),
});

export const renameVaultDocumentSchema = yup.object().shape({
	name: yup.string().required(),
});

export const commentVaultDocumentSchema = yup.object().shape({
	comment: yup.string().required(),
});
