/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTogglable } from 'src/_helpers/useTogglable';
import { ToggleIcon } from 'src/components/ToggleIcon/ToggleIcon';

export const DropdownToggleItem = ({ trigger, children, defaultOpen }) => {
	const [isOpen, { toggle }] = useTogglable(defaultOpen);

	const onClickToggle = useCallback(
		ev => {
			ev?.stopPropagation?.();
			toggle();
		},
		[toggle]
	);

	return (
		<>
			{trigger({ isOpen, toggle: onClickToggle })} {isOpen && children}
		</>
	);
};

export const DropdownToggleChild = styled(Dropdown.Item)`
	.ui.dropdown .menu > .item&& {
		background-color: var(--neutral-850) !important;
		padding-left: 50px !important;
		height: 42px;

		&.active {
			background: var(--neutral-850) !important;
			color: #fff;

			&:hover {
				background: var(--neutral-800) !important;
			}
		}
	}
`;

export const DropdownToggleTrigger = ({ isOpen, toggle, children, ...props }) => {
	return (
		<Dropdown.Item selected={isOpen} onClick={toggle} {...props}>
			{children}
			<ToggleIcon isOpen={isOpen} />
		</Dropdown.Item>
	);
};
