/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const Label = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 8px;
`;

export const LeftLabel = styled.span`
	font-family: var(--font-base);
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.2px;

	color: #08134b;
`;
export const RightLink = styled.span`
	font-family: var(--font-base);
	font-size: 12px;
	line-height: 14px;
	text-align: right;

	color: var(--primary-400);
`;

export function InputLabel({ label, rightLabel, mandatory = false }) {
	const { t } = useTranslation();

	return label || rightLabel ? (
		<Label>
			<LeftLabel>
				{label} {!mandatory && <OptionalSpan>- {t('optional')}</OptionalSpan>}
			</LeftLabel>
			{rightLabel && <RightLink>{rightLabel}</RightLink>}
		</Label>
	) : (
		<></>
	);
}

const OptionalSpan = styled.span`
	color: var(--neutral-200);
`;
