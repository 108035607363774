/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { tTradeStep, TradeStep } from 'src/constants/tradeStatus';
import { tTradeDocumentType } from 'src/constants/trade-document-type';
import { WhiteSpan } from 'src/containers/MyAccount/components/_modals/commonModalElements';
import { PhysicalTradeStep, tPhysicalTradeStep } from './physicalTrade';

export const ignoredActivityTypes = ['ADD_COMMENT', 'ADD_THREAD'];

export const activityTypes = {
	ACTIVITY_TYPE_ADD_USER: 'ADD_USER',
	ACTIVITY_TYPE_ADD_SHIPPING_ADVICE_DOCUMENT: 'ADD_SHIPPING_ADVICE_DOCUMENT',
	ACTIVITY_TYPE_VIEW_CONTRACT: 'VIEW_CONTRACT',
	ACTIVITY_TYPE_MARK_SIGNATURES_COMPLETE: 'MARK_SIGNATURES_COMPLETE',
	ACTIVITY_TYPE_MARK_SHIPPING_DOCUMENTS_COMPLETE: 'MARK_SHIPPING_DOCUMENTS_COMPLETE',
	ACTIVITY_TYPE_MARK_SHIPPING_ADVICE_COMPLETE: 'MARK_SHIPPING_ADVICE_COMPLETE',
	ACTIVITY_TYPE_MARK_VESSEL_NOMINATION_COMPLETE: 'MARK_VESSEL_NOMINATION_COMPLETE',
	ACTIVITY_TYPE_BILL_OF_LADING_UPDATED: 'BILL_OF_LADING_UPDATED',
	ACTIVITY_TYPE_MARK_SHIPPING_TENDER_COMPLETE: 'MARK_SHIPPING_TENDER_COMPLETE',
	ACTIVITY_TYPE_MARK_INVOICING_COMPLETE: 'MARK_INVOICING_COMPLETE',
	ACTIVITY_TYPE_LAYCAN_UPDATED: 'LAYCAN_UPDATED',
	ACTIVITY_TYPE_VESSEL_NAME_UPDATED: 'VESSEL_NAME_UPDATED',
	ACTIVITY_TYPE_SHIPPING_ADVICE_UPDATED: 'SHIPPING_ADVICE_UPDATED',
	ACTIVITY_TYPE_BL_NOMINATION_UPDATED: 'BL_NOMINATION_UPDATED',
	ACTIVITY_TYPE_MARK_TRADE_COMPLETE: 'MARK_TRADE_COMPLETE',
	ACTIVITY_TYPE_CLOSE_TRADE: 'CLOSE_TRADE',
	UPLOADED_DOCUMENT: 'UPLOADED_DOCUMENT',
	UPLOADED_MULTIPLE_DOCUMENTS: 'UPLOADED_MULTIPLE_DOCUMENTS',
	ACTIVITY_TYPE_MARK_COMPLETION_COMPLETE: 'MARK_COMPLETION_COMPLETE',
	ACTIVITY_TYPE_MARK_CLOSE_COMPLETE: 'MARK_CLOSE_COMPLETE',
	ACTIVITY_TYPE_KYC_ACCEPTED_REQUEST: 'KYC_ACCEPTED_REQUEST',
	ACTIVITY_TYPE_KYC_UPLOADED_DOCUMENTS: 'KYC_UPLOADED_DOCUMENTS',
	ACTIVITY_TYPE_KYC_REMOVED_DOCUMENTS: 'KYC_REMOVED_DOCUMENTS',
	ACTIVITY_TYPE_TRADE_ADD_USER: 'ADD_USER',
	ACTIVITY_TYPE_TRADE_REMOVE_USER: 'REMOVE_USER',
	ACTIVITY_TYPE_DOCUMENTS_REQUESTED: 'DOCUMENTS_REQUESTED',
	ACTIVITY_TYPE_TRADE_CREATED: 'TRADE_CREATED',
	// exchange for physical activities
	ACTIVITY_TYPE_NEW_TRADE_FUTURES_CONTRACT_DETAILS: 'NEW_TRADE_FUTURES_CONTRACT_DETAILS',
	ACTIVITY_TYPE_NEW_DIRECT_ORDER_FUTURES_CONTRACT_DETAILS:
		'NEW_DIRECT_ORDER_TRADE_FUTURES_CONTRACT_DETAILS',
	ACTIVITY_TYPE_DELETED_TRADE_FUTURES_CONTRACT_DETAILS: 'DELETED_TRADE_FUTURES_CONTRACT_DETAILS',
	ACTIVITY_TYPE_DELETED_DIRECT_ORDER_TRADE_FUTURES_CONTRACT_DETAILS:
		'DELETED_DIRECT_ORDER_TRADE_FUTURES_CONTRACT_DETAILS',
	ACTIVITY_TYPE_EXPIRED_TRADE_FUTURES_CONTRACT_DETAILS: 'EXPIRED_TRADE_FUTURES_CONTRACT_DETAILS',
	ACTIVITY_TYPE_APPROVED_TRADE_FUTURES_CONTRACT_DETAILS:
		'APPROVED_TRADE_FUTURES_CONTRACT_DETAILS',
	ACTIVITY_TYPE_PARTIAL_FILLED_TRADE_FUTURES_CONTRACT_DETAILS:
		'PARTIAL_FILLED_TRADE_FUTURES_CONTRACT_DETAILS',
	ACTIVITY_TYPE_EXECUTED_TRADE_FUTURES_CONTRACT_DETAILS:
		'EXECUTED_TRADE_FUTURES_CONTRACT_DETAILS',
	ACTIVITY_TYPE_ETA_DISCHARGE_PORT_UPDATED: 'ETA_DISCHARGE_PORT_UPDATED',
};

export function checkActivityType(t, type, activityLogData, isCIFCFRTrade = false) {
	switch (type) {
		case activityTypes.ACTIVITY_TYPE_TRADE_CREATED:
			return (
				<Trans
					i18nKey={'trade_created_between_companies'}
					values={{
						buyerCompany: activityLogData.buyer_company?.name || '---',
						sellerCompany: activityLogData.seller_company?.name || '---',
					}}
					components={{
						span: <span className="highlight" />,
					}}
				/>
			);
		case activityTypes.ACTIVITY_TYPE_MARK_SHIPPING_ADVICE_COMPLETE:
			return t('provided_vessel_information_completing_stage');
		case activityTypes.ACTIVITY_TYPE_MARK_INVOICING_COMPLETE:
			return t('marked_closed_{{step}}_as_complete', {
				step: tPhysicalTradeStep(t, PhysicalTradeStep.INVOICING),
			});
		case activityTypes.ACTIVITY_TYPE_MARK_SHIPPING_DOCUMENTS_COMPLETE:
			return t('marked_shipping_documents_as_complete');
		case activityTypes.ACTIVITY_TYPE_MARK_VESSEL_NOMINATION_COMPLETE:
			return t('nominated_vessel_completing_stage');
		case activityTypes.ACTIVITY_TYPE_BILL_OF_LADING_UPDATED:
			return t('provided_BL_information');
		case activityTypes.ACTIVITY_TYPE_MARK_SHIPPING_TENDER_COMPLETE:
			return t('completed_shipping_tender');
		case activityTypes.ACTIVITY_TYPE_LAYCAN_UPDATED: {
			const [laycan_from, divider, laycan_to] = activityLogData?.diff?.new.split(' ');
			return t('updated_laycan_to_{{laycan}}', {
				laycan: `${moment.utc(laycan_from).format('ll')} ${divider} ${moment
					.utc(laycan_to)
					.format('ll')}`,
			});
		}
		case activityTypes.ACTIVITY_TYPE_VESSEL_NAME_UPDATED:
			return t('updated_vessel_information');
		case activityTypes.ACTIVITY_TYPE_BL_NOMINATION_UPDATED:
			return t('updated_shipping_tender_info');
		case activityTypes.ACTIVITY_TYPE_MARK_TRADE_COMPLETE:
			return t('marked_trade_as_complete');
		case activityTypes.ACTIVITY_TYPE_CLOSE_TRADE:
			return t('marked_trade_as_closed');
		case activityTypes.ACTIVITY_TYPE_MARK_SIGNATURES_COMPLETE:
			return t('completed_signatures');
		case activityTypes.UPLOADED_DOCUMENT:
			return (
				<Trans
					i18nKey={
						activityLogData.trade_step
							? 'uploaded_{{document}}_in_stage'
							: 'uploaded_{{document}}'
					}
					values={{
						document: tTradeDocumentType(t, activityLogData.document_type),
						stage: t(activityLogData.trade_step),
					}}
					components={{
						span: <span className="highlight" />,
					}}
				/>
			);
		case activityTypes.UPLOADED_MULTIPLE_DOCUMENTS:
			return (
				<Trans
					i18nKey={
						activityLogData.trade_step
							? 'uploaded_multiple_documents_in_stage'
							: 'uploaded_multiple_documents'
					}
					values={{
						count: activityLogData.uploaded_documents_number,
						stage: t(activityLogData.trade_step),
					}}
					components={{
						span: <span className="highlight" />,
					}}
				/>
			);
		case activityTypes.ACTIVITY_TYPE_DOCUMENTS_REQUESTED: {
			return (
				<Trans
					i18nKey={'requested_document_types'}
					values={{
						documentTypes: activityLogData.trade_documents
							.map(type => tTradeDocumentType(t, type))
							.join(', '),
					}}
					components={{
						span: <span className="highlight" />,
					}}
				/>
			);
		}
		case activityTypes.ACTIVITY_TYPE_MARK_COMPLETION_COMPLETE:
			return t('marked_{{step}}_as_complete', {
				step: tTradeStep(t, TradeStep.Completion),
			});
		case activityTypes.ACTIVITY_TYPE_MARK_CLOSE_COMPLETE:
			return t('marked_closed_{{step}}_as_complete', {
				step: tTradeStep(t, TradeStep.Closed),
			});
		case activityTypes.ACTIVITY_TYPE_KYC_UPLOADED_DOCUMENTS: {
			if (activityLogData.kyc_documents.length === 1) {
				return t('shared_{{document}}', {
					document: activityLogData.kyc_documents,
				});
			} else {
				return t('shared_{{number_documents}}_documents', {
					number_documents: activityLogData.kyc_documents.length,
				});
			}
		}
		case activityTypes.ACTIVITY_TYPE_KYC_REMOVED_DOCUMENTS:
			if (activityLogData.kyc_documents.length === 1) {
				return t('removed_{{document}}', {
					document: activityLogData.kyc_documents[0],
				});
			} else {
				return t('removed_{{number_documents}}_documents', {
					number_documents: activityLogData.kyc_documents.length,
				});
			}
		case activityTypes.ACTIVITY_TYPE_KYC_ACCEPTED_REQUEST:
			return t(
				'from_{{responder_company}}_accepted_inbound_kyc_{{requester_user}}_{{requester_company}}',
				{
					responder_company: activityLogData.responder_company_name,
					requester_user: activityLogData.requester_name,
					requester_company: activityLogData.requester_company_name,
				}
			);
		case activityTypes.ACTIVITY_TYPE_TRADE_ADD_USER:
			return (
				<Trans
					i18nKey="added_{{users}}"
					components={{ span: <WhiteSpan /> }}
					values={{
						users: `${activityLogData.users[0].name} ${
							activityLogData.users.length > 1
								? `+${activityLogData.users.length - 1}`
								: ''
						}`,
					}}
				/>
			);
		case activityTypes.ACTIVITY_TYPE_TRADE_REMOVE_USER:
			return (
				<Trans
					i18nKey="removed_{{user}}"
					components={{ span: <WhiteSpan /> }}
					values={{
						user: activityLogData.users[0].name,
					}}
				/>
			);
		case activityTypes.ACTIVITY_TYPE_NEW_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_NEW_DIRECT_ORDER_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_DELETED_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_DELETED_DIRECT_ORDER_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_EXPIRED_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_APPROVED_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_PARTIAL_FILLED_TRADE_FUTURES_CONTRACT_DETAILS:
		case activityTypes.ACTIVITY_TYPE_EXECUTED_TRADE_FUTURES_CONTRACT_DETAILS:
			return (
				<Trans
					i18nKey={EFPActivityI18nKey[type]}
					values={{
						count: activityLogData.trade_futures_contract_details_number_of_contracts,
					}}
				/>
			);
		case activityTypes.ACTIVITY_TYPE_ETA_DISCHARGE_PORT_UPDATED:
			return t('user_updated_eta_discharge_port');
		default:
			return '';
	}
}

const EFPActivityI18nKey = {
	[activityTypes.ACTIVITY_TYPE_NEW_TRADE_FUTURES_CONTRACT_DETAILS]: 'provided_efp_details',
	[activityTypes.ACTIVITY_TYPE_NEW_DIRECT_ORDER_FUTURES_CONTRACT_DETAILS]:
		'provided_direct_efp_details',
	[activityTypes.ACTIVITY_TYPE_DELETED_TRADE_FUTURES_CONTRACT_DETAILS]: 'deleted_efp_details',
	[activityTypes.ACTIVITY_TYPE_DELETED_DIRECT_ORDER_TRADE_FUTURES_CONTRACT_DETAILS]:
		'deleted_direct_efp_details',
	[activityTypes.ACTIVITY_TYPE_EXPIRED_TRADE_FUTURES_CONTRACT_DETAILS]:
		'marked_expired_efp_details',
	[activityTypes.ACTIVITY_TYPE_APPROVED_TRADE_FUTURES_CONTRACT_DETAILS]: 'accepted_efp_details',
	[activityTypes.ACTIVITY_TYPE_PARTIAL_FILLED_TRADE_FUTURES_CONTRACT_DETAILS]:
		'confirmed_efp_details',
	[activityTypes.ACTIVITY_TYPE_EXECUTED_TRADE_FUTURES_CONTRACT_DETAILS]: 'executed_efp_details',
};
