/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useRef } from 'react';
import clsx from 'clsx';
import { Label } from '../Label/Label';
import { TextArea } from '../TextArea/TextArea';
import * as Styled from './styled';
import { useOnce } from 'src/_helpers/useOnce';
import { useMediaQuery, screenSize } from 'src/_helpers';

export const MaterialTextArea = React.forwardRef(
	(
		{ label, autoGrow = false, controlledState = false, hasChanged = false, id, ...props },
		ref
	) => {
		const hasValue = !!props.value;
		const filledControlledState = controlledState === true && hasChanged === false && hasValue;
		const filledState = controlledState ? hasChanged === true : hasValue;

		const areaRef = useRef();

		const isLargeScreen = useMediaQuery({ 'min-width': screenSize.xl });

		const handleAutoGrow = () => {
			if (areaRef.current) {
				areaRef.current.style.height = '5px';
				areaRef.current.style.height =
					areaRef.current.scrollHeight - (isLargeScreen ? 6 : 0) + 'px';
			}
		};

		useOnce(() => {
			handleAutoGrow();
		});

		return (
			<Styled.InputContainer
				className={clsx(
					'text-input',
					'textarea',
					filledState && 'input-filled',
					filledControlledState && 'input-filled-controlled'
				)}
			>
				<TextArea
					id={id}
					{...props}
					onInput={autoGrow ? handleAutoGrow : undefined}
					ref={areaRef}
				/>
				<Label htmlFor={id}>{label}</Label>
				{hasValue && <Styled.ValidCheckIcon />}
			</Styled.InputContainer>
		);
	}
);
