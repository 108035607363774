/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import * as yup from 'yup';

export const shippingTenderCreateSchema = yup.object().shape({
	date: yup.string().required(),
	quantity: yup.number().required(),
});

export const etaDischargePortCreateSchema = yup.object().shape({
	eta_discharge_port: yup.string().required(),
});

export const vesselNominationCreateSchema = yup.object().shape({
	vessel_name: yup.string().required(),
	laycan_from: yup.string().required(),
	laycan_to: yup.string().required(),
});

export const shippingAdviceCreateSchema = yup.object().shape({
	vessel_name: yup.string().required(),
	eta_loading_port: yup.string().nullable(),
});
