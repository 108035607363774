/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadIcon } from 'src/assets/icons/upload.svg';
import { OrderFile } from 'src/components/ViewOrderDrawer/Content/AdditionalInformationSection/OrderFile/OrderFile';
import { useDocuments } from 'src/components/CreateOrderDrawer/physical/sections/AdditionalInfoSection/useDocuments';
import * as Styled from './styled';

export const DocumentsSection = ({ lastCounter, setIsUploadingFiles }) => {
	const { t } = useTranslation();

	const documents = useDocuments();

	useEffect(() => {
		setIsUploadingFiles(documents.isUploading);
	}, [documents.isUploading, setIsUploadingFiles]);

	return (
		<Styled.SectionContainer data-test="documents-section">
			<Styled.UploadButton disabled={documents.isUploading}>
				<input
					data-test="documents-file-input"
					type="file"
					title=""
					multiple
					accept={documents.accept}
					onChange={documents.onChange}
					onClick={ev => (ev.target.value = null)}
				/>
				<UploadIcon /> {t('upload_documents')}
			</Styled.UploadButton>
			<Styled.SectionTitle>{t('documents')}</Styled.SectionTitle>
			<Styled.FilesContainer>
				{documents.files.map(({ file, isUploaded, fileId, key }) => (
					<Styled.UploadedFile
						key={key}
						data-key={file.name + ' ' + fileId}
						file={file}
						handleSuccess={documents.onUploaded(key)}
						handleDelete={documents.onDelete(key)}
						isUploading={!isUploaded}
						isSmall
						withTooltip
					/>
				))}
				{lastCounter.files.map(file => (
					<OrderFile
						file={file}
						key={`document-section-file-${file._key}`}
						dataTest="additional-information-document"
						withOwner
					/>
				))}
			</Styled.FilesContainer>
		</Styled.SectionContainer>
	);
};
