/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { processTrade } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useMutation, useQueryClient } from 'react-query';
import { useTogglable } from 'src/_helpers/useTogglable';
import { TradeActions } from 'src/constants/tradeStatus';
import {
	Modal,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	ModalSubTitle,
	CancelButton,
} from 'src/components/Modal';
import { useTradeDataContext } from 'src/containers/ExecutionDetails/Paper/TradeDataContext';
import { ModalSize, ModalType } from 'src/constants/modal';

export const useTradeSignatureMutation = ({ tradeId, close }) => {
	const { addToast } = useToast();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	return useMutation(processTrade, {
		onSuccess: async () => {
			queryClient.refetchQueries(['trade', tradeId]);
			queryClient.refetchQueries(['activity_logs', tradeId]);

			close();
		},
		onError: () => {
			addToast({
				message: t('something_went_wrong'),
				kind: 'error',
			});
			close();
		},
	});
};

export const AcceptSignModal = ({ trigger }) => {
	const { t } = useTranslation();
	const [isOpen, { open, close }] = useTogglable();
	const { tradeId, accountUserId, counterpartiesUserName } = useTradeDataContext();

	const { mutate, isLoading } = useTradeSignatureMutation({
		counterpartiesUserName,
		tradeId,
		close,
		accountUserId,
	});

	return (
		<Modal
			data-test="signatures-stage-modal"
			size={ModalSize.Tiny}
			open={open}
			close={close}
			isOpen={isOpen}
			trigger={trigger}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{t('mark_signatures_stage_as_completed')}</ModalTitle>
				<ModalSubTitle style={{ marginBottom: '40px' }}>
					{t('do_you_want_to_sign_this_stage')}
				</ModalSubTitle>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<PrimaryButton
					loading={isLoading}
					onClick={() => mutate({ tradeId, action: TradeActions.Sign })}
				>
					{t('sign')}
				</PrimaryButton>
			</ButtonsWrapper>
		</Modal>
	);
};
