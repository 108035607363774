/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useCallback, useEffect } from 'react';
import { WebsocketChannelType } from 'src/constants/websockets';
import { useSettings } from 'src/components/Menus/UserProfileDropdown/useSettings';
import { UserStatus, useWebsocketsContext } from './WebsocketsProvider';
import { useUserPresenceContext } from './UserPresenceProvider';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';

/**
 * Hook that updates the user presence based on the response from the backend that stores the user's status
 */
export const useUserStatusPresence = () => {
	const { ablyInstance } = useWebsocketsContext();
	const { isOnline = false, isRefetchingSettings } = useSettings();
	const userId = useSelector(getUserId);
	const { setSingleUserPresence } = useUserPresenceContext();

	const updateStatus = useCallback(
		message => {
			if (ablyInstance == null) {
				return;
			}

			const presenceChannel = ablyInstance.channels.get(WebsocketChannelType.Presence);
			if (presenceChannel == null) {
				return;
			}

			presenceChannel.presence.update(message);
		},
		[ablyInstance]
	);

	useEffect(() => {
		const userStatus = isOnline ? UserStatus.Active : UserStatus.Away;
		if (!isRefetchingSettings) {
			updateStatus(userStatus);
			setSingleUserPresence(userId, !isOnline); // Note: optymistic update
		}
	}, [updateStatus, isOnline, userId, setSingleUserPresence, isRefetchingSettings]);
};
