/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { H600, H400 } from 'src/components/Typography';
import { formatDateRange, parseDateStringAsUTC } from 'src/_helpers/date';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { ContentCell } from '../styled';
import { Origins } from './Origins';
import { Market } from 'src/constants/contract';
import { VolumeDisplay } from 'src/components/Volume';
import * as Styled from './styled';

export const ShipmentBox = ({ order, changes = {}, skipShipmentType = false }) => {
	const { t } = useTranslation();

	const { market, product } = order;

	const primaryPort = order.primary_ports[0];

	const originList = order.origin_countries;

	const deliveryDateRange = formatDateRange({
		format: order.delivery_mode,
		startDate: parseDateStringAsUTC(order.delivery_date_from),
		endDate: parseDateStringAsUTC(order.delivery_date_to),
	});

	const hasOrigins = originList.length > 0;

	const isPaperOrder = market === Market.Paper;

	const hasChanges = isPaperOrder
		? changes.volume
		: changes.inco_id ||
		  changes['primary_ports[0].name'] ||
		  changes.delivery_date_from ||
		  changes.delivery_date_to ||
		  changes.delivery_mode;

	return (
		<ContentCell data-test="shipment-box">
			<header>{isPaperOrder ? t('product') : t('shipment')}</header>
			<section>
				<Styled.IncoPortShipmentContainer className={clsx({ changed: hasChanges })}>
					<Styled.ShipIconContainer>
						<Styled.ShipIcon />
					</Styled.ShipIconContainer>
					<Styled.OrderInfo>
						{isPaperOrder ? (
							<Styled.InfoContainer>
								{order.volume ? (
									<VolumeDisplay
										data-test="contract-section-quantity"
										value={order.volume}
										as={H600}
									/>
								) : (
									`--- ${t('mt')}`
								)}
								<Styled.ProductName>{product.name}</Styled.ProductName>
							</Styled.InfoContainer>
						) : (
							<>
								<OverflowText>
									<H600
										as="span"
										data-test="shipment-box-inco-port"
									>{`${order.inco.name} ${primaryPort.name}, ${primaryPort.country_id}`}</H600>
								</OverflowText>
								<OverflowText>
									<H600 as="span" data-test="shipment-box-delivery-range">
										{deliveryDateRange}
									</H600>
								</OverflowText>
							</>
						)}
					</Styled.OrderInfo>
				</Styled.IncoPortShipmentContainer>
				<Styled.PillsContainer $hasOrigins={hasOrigins}>
					{hasOrigins > 0 && <Origins originsList={originList} />}
					{!skipShipmentType && (
						<Styled.BoxPill className={clsx({ changed: changes.shipment_type })}>
							<OverflowText>
								<H400 as="span" data-test="shipment-box-cargo-type">
									{t(`cargo_type_${order.shipment_type}`)}
								</H400>
							</OverflowText>
						</Styled.BoxPill>
					)}
				</Styled.PillsContainer>
			</section>
		</ContentCell>
	);
};
