/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { toPath } from 'src/_helpers';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { useCalendarSpreadFields } from '../../useCalendarSpreadFields';
import { Controller } from 'react-hook-form';

const SpreadTypeField = ({ path, t, lastCounter }) => {
	const { spreadType } = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	return (
		<Controller
			mandatory
			name={toPath(path, 'spreadType')}
			render={({ field, fieldState: { error } }) => (
				<MaterialSelectInput
					required
					error={!!error}
					data-test={field.name}
					label={t('spread_type')}
					options={spreadType.options}
					disabled={!spreadType.ready}
					controlledState
					hasChanged={!spreadType.hasChanged}
					{...field}
				/>
			)}
		/>
	);
};

export const counterSpreadTypeField = props => <SpreadTypeField {...props} />;
