/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { ReactComponent as NotificationsBellSvg } from 'src/assets/icons/notification_bell_button.svg';
import { useQuery, useQueryClient } from 'react-query';
import { notificationsCount } from 'src/_api';
import { MenuItemWithIndicator } from './MenuItemWithIndicator';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { WebsocketChannelType, PrivateWebsocketEventType } from 'src/constants/websockets';
import { defaultNotificationsSettings } from 'src/constants/notifications';

export const notificationsCountQuery = ({ queryKey: [key, filters] }) => {
	return notificationsCount(filters);
};

export const NotificationsIndicator = () => {
	const [count, setCount] = useState(0);
	const queryClient = useQueryClient();

	const { isLoading } = useQuery(
		['notifications', { read: false, count: true }],
		notificationsCountQuery,
		{
			...defaultNotificationsSettings,
			onSuccess: count => {
				setCount(count);
			},
		}
	);

	useWebsocket(WebsocketChannelType.Private, PrivateWebsocketEventType.NewNotification, () =>
		setCount(count => count + 1)
	);

	// TODO: move to EventBus (TD-750)
	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.NotificationsRead,
		({ data }) => {
			const readCount = parseInt(data.data.notifications_read_count);
			setCount(count => count - readCount);
			queryClient.invalidateQueries(['notifications', { read: false }]);
		}
	);

	return (
		<MenuItemWithIndicator
			count={count}
			isLoading={isLoading}
			IconComponent={NotificationsBellSvg}
		/>
	);
};
