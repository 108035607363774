/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { toPath } from 'src/_helpers';
import { useCalendarSpreadFields } from '../../useCalendarSpreadFields';
import { Controller } from 'react-hook-form';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';

const PrincipalVisibilityField = ({ path, t, lastCounter }) => {
	const { principal, principalVisibility } = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	if (!principal.visible) {
		return null;
	}

	return (
		<Controller
			mandatory
			name={toPath(path, 'principalVisibility')}
			render={({ field, fieldState: { error } }) => (
				<MaterialSelectInput
					required
					error={!!error}
					data-test={field.name}
					label={t('principal_name')}
					controlledState
					options={principalVisibility.options}
					hasChanged={principalVisibility.hasChanged}
					disabled={!principalVisibility.ready}
					{...field}
				/>
			)}
		/>
	);
};

export const counterPrincipalVisibilityField = props => <PrincipalVisibilityField {...props} />;
