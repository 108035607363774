/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { ServicePrefix, fetchFromApi, map404ToEmptyObject } from './model';
import { getInitials } from '../helpers/getInitials';
import { getMessageDate } from '../helpers/messages';
import { splitTextToArrayOfMessages } from '../helpers/messages';
import { VosborUser } from '../constants';

export const getConversationMessages = async (conversationId, cursor, direction, limit = 50) => {
	if (!conversationId) {
		return {};
	}

	return fetchFromApi(`conversations/${conversationId}/messages`, {
		method: 'GET',
		urlServicePrefix: ServicePrefix.Chat,
		mapStatusToResult: map404ToEmptyObject,
		query: {
			cursor,
			direction,
			limit,
		},
	});
};

export const createNewMessage = async (conversationId, body) => {
	return await fetchFromApi(`conversations/${conversationId}/messages`, {
		method: 'POST',
		urlServicePrefix: ServicePrefix.Chat,
		body,
	});
};

export const mapConversationMessages = (
	responseData = [],
	participants = {},
	supportConversationId
) => {
	return responseData.map(record => {
		const user = record.created_by
			? participants[record.created_by]
			: {
					...VosborUser,
					id: supportConversationId,
			  };
		const momentDate = moment(record.created_at);

		const { hostname } = window.location;

		// If we are on localhost, we don't need to replace hostname
		const isLocalhost = ['localhost', '127.0.0.1'].includes(hostname);

		const appHostName = hostname.replace('www.', '');

		// Go through attachments as in rare scenario message can intentionally contain hostname as a plaintext
		const mismatchedHostnameElement = (record.attachments || []).find(
			attachment => !attachment.url.includes(appHostName)
		);

		const attachments = isLocalhost
			? record.attachments || []
			: mapAttachments(record.attachments);

		const arrayOfMessages = record.text_translation_key
			? [record.text_translation_key]
			: splitTextToArrayOfMessages(
					isLocalhost
						? record.text
						: replaceTextHostname(record.text, mismatchedHostnameElement, appHostName)
			  );

		return {
			id: record.id,
			arrayOfMessages,
			text_translation_key: record.text_translation_key || null,
			user: {
				id: user.id,
				name: user.first_name,
				company: user.company?.name || '',
				initials: getInitials(user.first_name, user.last_name),
				color: user.avatar_color,
				status: user.status,
			},
			createdAt: record.created_at,
			date: getMessageDate(momentDate),
			time: record.created_at,
			attachments,
		};
	});
};

export const mapAttachments = attachments => {
	if (attachments) {
		const { hostname } = window.location;

		const appHostname = hostname.replace('www.', '');

		return attachments.map(attachment => {
			const { hostname: fullAttachmentHostName } = new URL(attachment.url);

			const attachmentHostName = fullAttachmentHostName.replace('www.', '');

			const url =
				attachmentHostName === appHostname
					? attachment.url
					: attachment.url.replace(attachmentHostName, `api.${appHostname}`);

			return {
				...attachment,
				url,
			};
		});
	}

	return [];
};

export const replaceTextHostname = (text, mismatchedHostnameElement, newHostname) => {
	if (!mismatchedHostnameElement) {
		return text;
	}

	const { hostname: elementHostname } = new URL(mismatchedHostnameElement.url);

	return text.replace(elementHostname, `api.${newHostname}`);
};
