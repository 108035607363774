/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import * as Styled from './styled';

export const Select = React.forwardRef(
	(
		{ className, highlighted, iconRotation = true, noMargin, upward = false, ...selectProps },
		ref
	) => (
		<Styled.Select
			className={clsx(className, {
				disabled: selectProps.disabled,
				error: selectProps.error,
				noMargin: noMargin,
				highlighted,
			})}
			$iconRotation={iconRotation}
			ref={ref}
			{...selectProps}
			upward={upward}
		/>
	)
);
