/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { StyledList, StyledListItem, ListHeader } from '../components/List';
import { minuteOptions } from './model';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const MinuteSelect = React.memo(({ value, onChange }) => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<ListHeader>{t('minute')}</ListHeader>
			<StyledList selection verticalAlign="middle">
				{minuteOptions.map(minute => (
					<StyledListItem
						style={{ textAlign: 'center' }}
						key={minute}
						content={minute.toString().padStart(2, '0')}
						active={value === minute}
						onClick={() => onChange(minute)}
						data-test={`minute-${minute}`}
					/>
				))}
			</StyledList>
		</Wrapper>
	);
});

const Wrapper = styled.div`
	padding-right: 16px;
	padding-left: 16px;
`;
