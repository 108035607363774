/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ModalSize, ModalType } from 'src/constants/modal';
import {
	Modal,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalHeaderWrapper,
	ModalSubTitleWithMargin,
	ModalTitle,
	CancelButton,
} from 'src/components/Modal';
import { NegativeButton } from 'src/components/Buttons/NegativeButton';
import { logoutUserRequest } from 'src/_store/actions';
import { ROUTES } from 'src/constants/routes';
import { useSettings } from 'src/components/Menus/UserProfileDropdown/useSettings';
import { createNewMessage } from 'src/chat/api/messages';

// message sent to support doesn't have to be translated
const ChatMessage = 'I am hereby requesting deletion of my account along with all associated data.';

export const DeleteAccount = () => {
	const [isProcessing, setProcessing] = useState();
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { supportConversationId } = useSettings();

	const handleModalClose = () => {
		navigate(ROUTES.root);
	};

	const navigateWithRedirect = () => {
		navigate(ROUTES.deleteAccountRequestSent);
	};

	const handleConfirm = () => {
		setProcessing(true);
		createNewMessage(supportConversationId, { text: ChatMessage });

		// force navigate to call confirmation page and ingnore /login passed as a param in the saga
		dispatch(logoutUserRequest({ navigate: navigateWithRedirect }));
	};

	return (
		<Modal
			data-test="delete-account-modal"
			size={ModalSize.Tiny}
			close={handleModalClose}
			isOpen
			type={ModalType.Warning}
			closeOnDimmerClick={false}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{t('delete_account_header')}</ModalTitle>
				<ModalSubTitleWithMargin>{t('delete_account_info')}</ModalSubTitleWithMargin>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={handleModalClose} />
				<NegativeButton type="button" onClick={handleConfirm} loading={isProcessing}>
					{t('delete_account_button')}
				</NegativeButton>
			</ButtonsWrapper>
		</Modal>
	);
};
