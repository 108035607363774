/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const CHART_SELECT_PRODUCT = 'CHART_SELECT_PRODUCT';
export const CHART_SELECT_PRICE_TYPE = 'CHART_SELECT_PRICE_TYPE';
export const CHART_SELECT_GRADE = 'CHART_SELECT_GRADE';
export const CHART_SELECT_INCO = 'CHART_SELECT_INCO';
export const CHART_SELECT_LOCATION = 'CHART_SELECT_LOCATION';
export const CHART_SELECT_SHIPMENT = 'CHART_SELECT_SHIPMENT';
export const CHART_RESET_FILTERS = 'CHART_RESET_FILTERS';
export const CHART_SELECT_CARGO_TYPE = 'CHART_SELECT_CARGO_TYPE';
export const CHART_SELECT_PAPER_PRESET = 'CHART_SELECT_PAPER_PRESET';
export const CHART_SELECT_DEFAULT_PAPER_PRESET = 'CHART_SELECT_DEFAULT_PAPER_PRESET';

export const chartSelectProduct = payload => ({ type: CHART_SELECT_PRODUCT, payload });
export const chartSelectPriceType = payload => ({ type: CHART_SELECT_PRICE_TYPE, payload });
export const chartSelectGrade = payload => ({ type: CHART_SELECT_GRADE, payload });
export const chartSelectInco = payload => ({ type: CHART_SELECT_INCO, payload });
export const chartSelectLocation = payload => ({ type: CHART_SELECT_LOCATION, payload });
export const chartSelectShipment = payload => ({ type: CHART_SELECT_SHIPMENT, payload });
export const chartSelectCargoType = payload => ({ type: CHART_SELECT_CARGO_TYPE, payload });
export const chartResetFilters = payload => ({ type: CHART_RESET_FILTERS, payload });
export const chartSelectPaperPreset = payload => ({ type: CHART_SELECT_PAPER_PRESET, payload });
export const chartSelectDefaultPaperPreset = payload => ({
	type: CHART_SELECT_DEFAULT_PAPER_PRESET,
	payload,
});
