/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const tTrackRecordQuantities = t => [
	{ text: `50,000 ${t('mt')}`, value: 50000 },
	{ text: `100,000 ${t('mt')}`, value: 100000 },
	{ text: `500,000 ${t('mt')}`, value: 500000 },
	{ text: `1,000,000 ${t('mt')}`, value: 1000000 },
	{ text: `5,000,000 ${t('mt')}`, value: 5000000 },
	{ text: `10,000,000 ${t('mt')}`, value: 10000000 },
];

export const TrackRecordType = {
	Received: 'received',
	Sent: 'sent',
	Archived: 'archived',
};

export const tTrackRecordType = (t, value) => {
	switch (value) {
		case TrackRecordType.Received:
			return t('inbound');
		case TrackRecordType.Sent:
			return t('outbound');
		case TrackRecordType.Archived:
			return t('archived');
		default:
			return '';
	}
};

export const TrackRecordResult = {
	Pass: 'pass',
	Fail: 'fail',
};

export const tTrackRecordResult = (t, value) => {
	switch (value) {
		case TrackRecordResult.Pass:
			return t('pass');
		case TrackRecordResult.Fail:
			return t('fail');
		default:
			return '---';
	}
};

export const TrackRecordStatus = {
	Pending: 'pending',
	Accepted: 'accepted',
};

export const tTrackRecordStatus = (t, value) => {
	switch (value) {
		case TrackRecordStatus.Pending:
			return t('pending');
		case TrackRecordStatus.Accepted:
			return t('accepted');
		default:
			return '---';
	}
};

export const KycTrackRecordTabIds = {
	RECEIVED: 'received',
	SENT: 'sent',
	ARCHIVED: 'archived',
};

export const KycTrackRecordTabs = [
	{
		id: KycTrackRecordTabIds.RECEIVED,
		name: 'inbound',
	},
	{
		id: KycTrackRecordTabIds.SENT,
		name: 'outbound',
	},
	{
		id: KycTrackRecordTabIds.ARCHIVED,
		name: 'tab_archived',
	},
];

export const TrackRecordState = {
	ARCHIVE: 'archive',
	RESTORE: 'restore',
};
