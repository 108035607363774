/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const ActivityLogWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 24px;
	padding-top: 40px;
`;
