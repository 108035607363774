/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled from 'styled-components';
import { h200, h400 } from 'src/components/Typography';
import { media } from 'src/_helpers';

export const InfoField = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 5px;
`;

export const InfoFieldTitle = styled.div`
	${h200}
	color: var(--neutral-100);
`;

export const InfoFieldSubtitle = styled.div`
	${h400}
	color: var(--white);
`;

export const SpreadPriceContainer = styled.div`
	div + label {
		--_max_width_base: 95%;
		padding-right: 0;
	}

	input + label {
		margin-left: 0 !important;

		span + span {
			width: 46px;
		}
	}
`;

export const CurrencyUnitSeparator = styled.span`
	background: var(--neutral-600);
	width: 2px;
	height: 22px;
	margin-right: 8px;
	border-radius: 50%;
`;

export const FirstLegContainer = styled.div`
	div + label {
		--_max_width_base: 95%;
		padding-right: 0;

		${media.xl`
			width: 100px !important;
		`}
	}

	input + label {
		margin-left: 0 !important;

		span + span {
			width: 46px;
		}
	}
`;
