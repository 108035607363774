/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo, useState } from 'react';
import { END_DATE, START_DATE } from 'react-dates/constants';
import moment from 'moment';
import clsx from 'clsx';
import i18n from 'i18next';
import { generateDays } from './generateDays';
import * as Styled from './styled';

const WEEKDAYS = {
	en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
	zh: ['一', '二', '三', '四', '五', '六', '日'],
};

export const SimpleDateRange = ({
	startDate,
	endDate,
	onDatesChange,
	focusedInput,
	onFocusChange,
	navPrev,
	navNext,
	initialVisibleMonth,
}) => {
	const [visibleMonth, setVisibleMonth] = useState(initialVisibleMonth().utc());

	const month = useMemo(() => moment(visibleMonth), [visibleMonth]);
	const initialMonthName = month.format('MMMM YYYY');

	const onPrevMonthClick = () => setVisibleMonth(month => moment(month).subtract(1, 'month'));

	const onNextMonthClick = () => setVisibleMonth(month => moment(month).add(1, 'month'));

	const days = useMemo(() => generateDays(month), [month]);

	const handleDayClick = date => {
		if (focusedInput === START_DATE) {
			onDatesChange({
				startDate: date.clone(),
				endDate: undefined,
			});
			onFocusChange(END_DATE);
		} else if (date.isBefore(startDate)) {
			onDatesChange({
				startDate: date.clone(),
				endDate: undefined,
			});
		} else {
			onDatesChange({ startDate, endDate: date.clone() });
			onFocusChange(START_DATE);
		}
	};

	return (
		<Styled.Container className="DayPicker CalendarMonth">
			<div className="CalendarMonth_caption">
				<div onClick={onPrevMonthClick}>{navPrev}</div>
				<strong>{initialMonthName}</strong>
				<div onClick={onNextMonthClick}>{navNext}</div>
			</div>
			<Styled.WeekHeader>
				{WEEKDAYS[i18n.language].map(day => (
					<div key={day}>{day}</div>
				))}
			</Styled.WeekHeader>
			<Styled.CalendarDays>
				{days.map(({ current, date, key }) => (
					<span
						key={key}
						onClick={() => handleDayClick(date)}
						className={clsx({
							current,
							start: !!startDate && date.isSame(startDate, 'day'),
							end: !!endDate && date.isSame(endDate, 'day'),
							span:
								!!startDate &&
								!!endDate &&
								date.isBetween(startDate, endDate, 'day'),
						})}
					>
						{date.format('D')}
					</span>
				))}
			</Styled.CalendarDays>
		</Styled.Container>
	);
};
