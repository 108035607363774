/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
	QuoteType,
	tQuoteValueType,
	Currency,
	PriceUnit,
	Role,
	CalendarSpreadPayment,
	NameVisibility,
	tNameVisibility,
} from 'src/constants/contract';
import { useWatchPaperCounterFields } from '../../../helpers/paper/useWatchPaperCounterFields';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import { toPath, isNil } from 'src/_helpers';
import { useOnce } from 'src/_helpers/useOnce';
import { getDefaultValidity } from 'src/components/CreateOrderDrawer/paper/helpers/getDefaultValidity';
import { useQuery } from 'react-query';
import { getMyContacts } from 'src/_api';
import { sortOrder } from 'src/_helpers/sort';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { getNumberOfMonths } from 'src/_helpers/date';
import { useDefaultValidities } from 'src/components/CreateOrderDrawer/paper/helpers/useDefaultValidities';

export const useCalendarSpreadFields = ({ path, lastCounter }) => {
	const { t } = useTranslation();
	const userId = useSelector(getUserId);

	const {
		setValue,
		control: { _defaultValues },
	} = useFormContext();

	const {
		currencyUnitValue,
		orderTypeValue,
		orderTypeChangedValue,
		priceValue,
		firstLegPriceValue,
		volumeValue,
		validityChangedValue,
		forceFirmValue,
		principalIdValue,
		spreadTypeValue,
		principalVisibilityValue,
		runsValue,
		presetIDValue,
	} = useWatchPaperCounterFields(path);

	const { data } = useAllPaperPresets();
	const presets = useMemo(() => data?.presets || [], [data?.presets]);
	const selectedProductPreset = useMemo(() => {
		return presets.find(preset => preset._key === _defaultValues.presetID);
	}, [presets, _defaultValues]);

	useEffect(() => {
		if (selectedProductPreset) {
			setValue('selectedProductPreset', selectedProductPreset);
		}
	}, [selectedProductPreset, setValue]);

	/* Order type */
	const orderTypeOptions = useMemo(
		() =>
			Object.values(QuoteType).map(value => ({
				text: tQuoteValueType(t, value),
				key: value,
				value,
			})),
		[t]
	);

	useOnce(() => {
		if (forceFirmValue) {
			setValue(toPath(path, 'orderType'), QuoteType.Firm);
		} else if (!orderTypeChangedValue && orderTypeValue === QuoteType.Firm) {
			setTimeout(() => {
				setValue(toPath(path, 'orderType'), QuoteType.Indicative);
			}, 0);
		}
	});

	const presetValidities = useDefaultValidities(presetIDValue);

	/**
	 * When order type changes and the validity value wasn't changed, we set 5 minutes for Firm and 24 hours for Indicative
	 */
	useEffect(() => {
		if (validityChangedValue) {
			return;
		}

		setValue('validity', getDefaultValidity(orderTypeValue, presetValidities));
	}, [orderTypeValue, setValue, validityChangedValue, presetValidities]);

	/* Currency & Unit */
	const [currency, unit] = useMemo(() => {
		return currencyUnitValue
			? currencyUnitValue.split('/')
			: [Currency.USD, PriceUnit.MetricTon];
	}, [currencyUnitValue]);

	const isOwner = lastCounter.order_user_id === userId;

	/** BROKER */
	const { data: allContacts = [] } = useQuery(
		[
			'my-contacts',
			{
				_limit: 1000,
				is_my_region: true,
				_sort: 'name',
				_order: sortOrder.asc,
				exclude_my_company: true,
			},
		],
		({ queryKey: [, params] }) => getMyContacts(params),
		{
			enabled: isOwner,
		}
	);

	const isBrokerOrder = lastCounter.role === Role.Broker;

	const shouldShowPrincipal = isBrokerOrder && lastCounter.order_user_id === userId;

	const isPrincipalChangeBlocked =
		lastCounter.order_owner_principal.user && !lastCounter.order_owner_principal.hidden;

	const isPrincipalVisibilityChangeBlocked = !(
		lastCounter.order_owner_principal.user && lastCounter.order_owner_principal.hidden
	);

	const principalNameVisibilityOptions = Object.values(NameVisibility).map(environment => ({
		value: environment,
		text: tNameVisibility(t, environment),
	}));

	useEffect(() => {
		if (isBrokerOrder && principalIdValue && !lastCounter.order_owner_principal.user) {
			setValue(toPath(path, 'principalVisibility'), NameVisibility.Visible);
		}
	}, [isBrokerOrder, principalIdValue, path, setValue, lastCounter.order_owner_principal.user]);

	useEffect(() => {
		if (!principalIdValue) {
			setValue(toPath(path, 'principalVisibility'), NameVisibility.Hidden);
			setValue(toPath(path, 'principalObject'), null);
		}
	}, [path, principalIdValue, setValue]);

	const counterpartyId = lastCounter.user_id;

	const contactsOptions = useMemo(() => {
		return allContacts
			.filter(contact => contact.contact_user_id !== counterpartyId)
			.map(contact => ({
				text: `${contact.name} - ${contact.company_name}`,
				key: contact.contact_user_id,
				value: contact.contact_user_id,
			}));
	}, [allContacts, counterpartyId]);

	useEffect(() => {
		if (!isOwner || !isBrokerOrder) {
			setValue(toPath(path, 'principalObject'), lastCounter.order_owner_principal);
		} else if (principalIdValue) {
			const principalUserObjectToSet = allContacts.find(
				c => c.contact_user_id === principalIdValue
			);

			if (principalUserObjectToSet) {
				setValue(toPath(path, 'principalObject'), {
					company: {
						name: principalUserObjectToSet.company_name,
						avatar_color: principalUserObjectToSet.company_avatar_color,
					},
					user: {
						_key: principalUserObjectToSet.contact_user_id,
						name: principalUserObjectToSet.name,
						avatar_color: principalUserObjectToSet.avatar_color,
					},
					hidden: principalVisibilityValue === NameVisibility.Hidden,
				});
			}
		}
	}, [
		lastCounter,
		path,
		setValue,
		isOwner,
		isBrokerOrder,
		principalIdValue,
		principalVisibilityValue,
		allContacts,
	]);

	useEffect(() => {
		if (volumeValue === '') {
			setValue(toPath(path, 'volume'), null, { shouldValidate: true });
		}
	}, [path, setValue, volumeValue]);

	const previousVolume = _defaultValues.contractPricing[0].volume || null;
	const currentVolume = volumeValue || null;

	const spreadTypeOptions = Object.values(CalendarSpreadPayment).map(sp => ({
		key: sp,
		text: t(sp),
		value: sp,
	}));

	useEffect(() => {
		if (spreadTypeValue === CalendarSpreadPayment.Even) {
			setValue(toPath(path, 'price'), '0');
		}
	}, [path, priceValue, setValue, spreadTypeValue]);

	useEffect(() => {
		// eslint-disable-next-line eqeqeq
		if (spreadTypeValue !== CalendarSpreadPayment.Even && priceValue == 0) {
			setValue(toPath(path, 'price'), null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path, setValue, spreadTypeValue]);

	const calculatedTotalVolume = useMemo(() => {
		if (selectedProductPreset) {
			const monthsMultiplier = selectedProductPreset.multi_month
				? getNumberOfMonths(lastCounter.delivery_date_from, lastCounter.delivery_date_to)
				: 1;

			if (!isNil(runsValue)) {
				return selectedProductPreset.base_quantity * +runsValue * monthsMultiplier;
			} else if (!selectedProductPreset.volume !== undefined) {
				return monthsMultiplier * volumeValue;
			}

			return selectedProductPreset.volume;
		}

		return null;
	}, [
		lastCounter.delivery_date_from,
		lastCounter.delivery_date_to,
		runsValue,
		selectedProductPreset,
		volumeValue,
	]);

	useEffect(() => {
		setValue(toPath(path, 'totalVolume'), calculatedTotalVolume);
	}, [path, setValue, calculatedTotalVolume]);

	return {
		orderType: {
			options: orderTypeOptions,
			hasChanged: _defaultValues.contractPricing[0].orderType !== orderTypeValue,
		},
		price: {
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.contractPricing[0].price != priceValue,
			disabled: spreadTypeValue === CalendarSpreadPayment.Even,
			decimals: selectedProductPreset?.futures_contract ? 3 : 2,
		},
		firstLegPrice: {
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.contractPricing[0].firstLegPrice != firstLegPriceValue,
			decimals: selectedProductPreset?.futures_contract ? 3 : 2,
		},
		volume: {
			// eslint-disable-next-line eqeqeq
			hasChanged: previousVolume != currentVolume,
		},
		unit,
		currency,
		principal: {
			options: contactsOptions,
			ready: !isPrincipalChangeBlocked,
			visible: shouldShowPrincipal,
			hasChanged:
				// eslint-disable-next-line eqeqeq
				_defaultValues.contractPricing[0].order_owner_principal_id != principalIdValue,
			hasValue:
				!!_defaultValues.contractPricing[0].order_owner_principal_id || !!principalIdValue,
		},
		spreadType: {
			options: spreadTypeOptions,
			ready: true,
			hasChanged: _defaultValues.contractPricing[0].spreadType === spreadTypeValue,
		},
		principalVisibility: {
			options: principalNameVisibilityOptions,
			ready: !isPrincipalVisibilityChangeBlocked,
			hasChanged:
				_defaultValues.contractPricing[0].principalVisibility !== principalVisibilityValue,
		},
		runs: {
			// eslint-disable-next-line eqeqeq
			hasChanged: (_defaultValues.contractPricing[0].runs || null) != (runsValue || null),
		},
	};
};
