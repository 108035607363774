/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';

export const ButtonsSectionWrapper = styled.div`
	display: flex;
	margin-left: auto;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
	white-space: nowrap;
	margin-left: 16px;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
	margin-left: 16px;
`;
