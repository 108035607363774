/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
	CargoType,
	Inspection,
	isShipmentInco,
	tCargoType,
	tInspection,
} from 'src/constants/contract';
import { useWatchPhysicalCounterFields } from '../../helpers/useWatchPhysicalCounterFields';
import { useEffect } from 'react';

export const useShipmentCounterFields = () => {
	const { t } = useTranslation();
	const {
		setValue,
		getValues,
		control: { _defaultValues },
	} = useFormContext();

	const portFields = useFieldArray({ name: 'ports' });

	const {
		volumeValue,
		incoValue,
		cargoTypeValue,
		toleranceValue,
		inspectionValue,
		portNames,
		portsArray,
	} = useWatchPhysicalCounterFields();

	/** TOLERANCE */
	const toleranceLabel = !incoValue
		? t('tolerance')
		: isShipmentInco(incoValue)
		? t('tolerance_sellers_option')
		: t('tolerance_buyers_option');

	/** CARGO TYPE */
	const cargoTypeOptions = Object.values(CargoType)
		.map(value => ({
			text: tCargoType(t, value),
			value,
		}))
		.filter(cargoType => {
			if (cargoTypeValue !== CargoType.Bulk && cargoType.value === CargoType.Bulk) {
				return false;
			}
			return true;
		});

	/** INSPECTION */
	const inspectionOptions = Object.values(Inspection).map(value => ({
		text: tInspection(t, value),
		value,
	}));

	/** PORTS */
	const portLabel = !incoValue
		? t('loading_discharge_port')
		: isShipmentInco(incoValue)
		? t('discharge_port')
		: t('loading_port');

	const portVolumeLabel = !incoValue
		? t('loading_discharge_quantity')
		: isShipmentInco(incoValue)
		? t('discharge_quantity')
		: t('loading_quantity');

	const portRateLabel = !incoValue
		? t('loading_discharge_rate')
		: isShipmentInco(incoValue)
		? t('discharge_rate')
		: t('loading_rate');

	const addNewPort = () => {
		portFields.append({ name: '', volume: null, terminal: '', rate: null });
		if (portFields.fields.length === 1) {
			setValue('ports.0.volume', null);
		}
	};

	const deletePort = index => {
		portFields.remove(index);
		if (portFields.fields.length === 2) {
			setValue('ports.0.volume', volumeValue);
		}
	};

	const addPortName = (portKey, portName, countryCode) => {
		const oldPortNames = getValues('portNames') || {};
		if (portKey && portName && !oldPortNames[portKey]) {
			setValue('portNames', {
				...oldPortNames,
				[portKey]: `${portName}, ${countryCode}`,
			});
		}
	};

	const hasPortParamChanged = param => index => {
		const port = portsArray?.[index] || {};
		const defaultPort = _defaultValues.ports?.[index] || {};

		const fieldValue = port?.[param] || null;
		const defaultFieldValue = defaultPort?.[param] || null;

		// eslint-disable-next-line eqeqeq
		return fieldValue != defaultFieldValue;
	};

	const hasPortParamValue = param => index => {
		return !!portsArray[index]?.[param];
	};

	const hasPortValue = index => {
		if (index === 0) {
			return (
				hasPortParamValue('rate')(index) ||
				hasPortParamValue('terminal')(index) ||
				(portsArray.length > 1 && hasPortParamValue('volume')(index))
			);
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (portsArray.length === 1) {
			setValue('ports.0.volume', volumeValue);
		}
	}, [portsArray.length, setValue, volumeValue]);

	return {
		tolerance: {
			label: toleranceLabel,
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.tolerance != toleranceValue,
		},
		cargoType: {
			options: cargoTypeOptions,
			ready: cargoTypeValue !== CargoType.Bulk,
			hasChanged: _defaultValues.cargoType !== cargoTypeValue,
		},
		inspection: {
			options: inspectionOptions,
			ready: true,
			hasChanged:
				!_defaultValues.inspection && !inspectionValue
					? false
					: _defaultValues.inspection !== inspectionValue,
			hasValue: !!_defaultValues.inspection || inspectionValue,
		},
		ports: {
			label: portLabel,
			names: portNames || [],
			list:
				portsArray.length === 1
					? portsArray
					: [portsArray[0], ...portFields.fields.slice(1)],
			hasChanged: hasPortParamChanged('name'),
			hasValue: hasPortValue,
			add: addNewPort,
			addName: addPortName,
			delete: deletePort,
		},
		portVolumes: {
			label: portVolumeLabel,
			maxValue: parseInt(volumeValue),
			ready: portsArray.length > 1,
			defaultValue: volumeValue,
			hasChanged: hasPortParamChanged('volume'),
			hasValue: portsArray.length === 1 ? () => false : hasPortParamValue('volume'),
		},
		portTerminals: {
			hasChanged: hasPortParamChanged('terminal'),
			hasValue: hasPortParamValue('terminal'),
		},
		portRates: {
			label: portRateLabel,
			hasChanged: hasPortParamChanged('rate'),
			hasValue: hasPortParamValue('rate'),
		},
	};
};
