/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeSettings } from 'src/constants/settings';
import { DropdownToggleItem, DropdownToggleTrigger } from 'src/components/Dropdown/Dropdown';
import { T150 } from 'src/components/Typography/fonts';
import { ThemeSettingsList } from './ThemeSettingsList';

export const ThemeSettingsContent = ({ currentTheme }) => {
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState(false);

	const Icon = useMemo(() => {
		const setting = ThemeSettings.find(({ key }) => key === currentTheme);
		return setting?.icon;
	}, [currentTheme]);

	return (
		<DropdownToggleItem
			trigger={({ isOpen, toggle }) => (
				<DropdownToggleTrigger
					isOpen={isOpen}
					toggle={e => {
						e?.stopPropagation?.();
						toggle();
						setIsVisible(prev => !prev);
					}}
					data-test="theme-settings-dropdown"
				>
					<Icon />
					<T150>{t('colour_scheme')}</T150>
				</DropdownToggleTrigger>
			)}
			data-test={`settings-theme`}
		>
			{isVisible && <ThemeSettingsList themeSettings={ThemeSettings} />}
		</DropdownToggleItem>
	);
};
