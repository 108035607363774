/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { isCookieConsentSet, setCookieConsent } from 'src/_helpers/cookies';
import { isStandaloneRoute } from 'src/constants/routes';

export const CookieConsent = () => {
	const [isCookieSet, setIsCookieSet] = useState(isCookieConsentSet());
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const handleAcceptClick = useCallback(() => {
		setCookieConsent();
		setIsCookieSet(true);
	}, []);

	if (isCookieSet || isStandaloneRoute(pathname)) {
		return null;
	}

	return (
		<CookieWrapper data-test="cookie-consent">
			<div>{t('cookie_consent_text')}</div>
			<div>
				<button type="button" onClick={handleAcceptClick}>
					{t('cookie_consent_ok')}
				</button>
			</div>
			<div>
				<a href="https://vosbor.com/privacy-policy/" target="_blank" rel="noreferrer">
					{t('cookie_consent_link_privacy_policy')}
				</a>
			</div>
		</CookieWrapper>
	);
};

const CookieWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100vw;
	overflow: hidden;
	background: var(--text-color-default);
	color: #08134b;
	z-index: 10;
	padding: 16px;

	& > div + div {
		margin-left: 32px;
	}

	a,
	button {
		color: var(--primary-400);
	}
`;
