/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const Container = styled.div`
	padding: 0 10px;

	.CalendarMonth_caption {
		padding-bottom: 27px;
	}
`;

export const WeekHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-bottom: 16px;

	> div {
		width: calc(100% / 7);
		text-align: center;
		color: var(--text-color-secondary);
	}
`;

export const CalendarDays = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	--_height: 38px;
	padding-bottom: 20px;

	> span {
		display: block;
		width: calc(100% / 7);
		height: var(--_height);
		line-height: var(--_height);
		text-align: center;
		color: var(--text-color-secondary);
		cursor: pointer;
		border-radius: var(--simple-border-radius);

		&.current {
			color: var(--text-color-default);
		}

		&.start {
			background-color: var(--purple-400);
		}

		&.end {
			background-color: var(--purple-400);
		}

		&.span {
			background-color: var(--purple-800-alpha);
			border-radius: 0;
			color: var(--text-color-default);
		}

		&:hover {
			color: var(--text-color-default);
			background: var(--purple-800);
		}
	}
`;
