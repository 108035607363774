/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Button } from './Button';
import styled from 'styled-components';

export const PositiveButton = styled(Button)`
	background: var(--green-400);
	color: var(--neutral-1000);

	&:hover {
		background: var(--green-200);
		color: var(--neutral-1000);
	}

	&:active,
	&.active,
	&:focus,
	&.loading {
		background: var(--green-600);
		color: var(--neutral-1000);
	}
`;
