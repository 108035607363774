/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { ROUTES } from './routes';

export const PhysicalTradeStep = {
	TRADE_CONFIRMATION: 'confirmation',
	SIGNATURES: 'signatures',
	SHIPPING_ADVICE: 'shipping_advice',
	VESSEL_NOMINATION: 'vessel_nomination',
	INSTRUCTIONS: 'instructions',
	SHIPPING_DOCUMENTS: 'shipping_documents',
	SHIPPING_TENDER: 'shipping_tender',
	INVOICING: 'invoicing',
};

export const PhysicalCIFCFRSteps = [
	PhysicalTradeStep.TRADE_CONFIRMATION,
	PhysicalTradeStep.SIGNATURES,
	PhysicalTradeStep.SHIPPING_ADVICE,
	PhysicalTradeStep.INSTRUCTIONS,
	PhysicalTradeStep.SHIPPING_DOCUMENTS,
	PhysicalTradeStep.SHIPPING_TENDER,
	PhysicalTradeStep.INVOICING,
];

export const PhysicalFOBFASSteps = [
	PhysicalTradeStep.TRADE_CONFIRMATION,
	PhysicalTradeStep.SIGNATURES,
	PhysicalTradeStep.VESSEL_NOMINATION,
	PhysicalTradeStep.INSTRUCTIONS,
	PhysicalTradeStep.SHIPPING_DOCUMENTS,
	PhysicalTradeStep.SHIPPING_TENDER,
	PhysicalTradeStep.INVOICING,
];

export const PhysicalStepsPath = {
	[PhysicalTradeStep.TRADE_CONFIRMATION]: ROUTES.physicalExecutionTradeConfirmation,
	[PhysicalTradeStep.SIGNATURES]: ROUTES.physicalExecutionSignatures,
	[PhysicalTradeStep.SHIPPING_ADVICE]: ROUTES.physicalExecutionShippingAdvice,
	[PhysicalTradeStep.VESSEL_NOMINATION]: ROUTES.physicalExecutionVesselNomination,
	[PhysicalTradeStep.INSTRUCTIONS]: ROUTES.physicalExecutionInstructions,
	[PhysicalTradeStep.SHIPPING_DOCUMENTS]: ROUTES.physicalExecutionShippingDocuments,
	[PhysicalTradeStep.SHIPPING_TENDER]: ROUTES.physicalExecutionShippingTender,
	[PhysicalTradeStep.INVOICING]: ROUTES.physicalExecutionInvoicing,
};

export const tPhysicalTradeStep = (t, step) => {
	switch (step) {
		case PhysicalTradeStep.TRADE_CONFIRMATION:
			return t('trade_confirmation');
		case PhysicalTradeStep.SIGNATURES:
			return t('signatures');
		case PhysicalTradeStep.SHIPPING_ADVICE:
			return t('shipping_advice');
		case PhysicalTradeStep.VESSEL_NOMINATION:
			return t('vessel_nomination');
		case PhysicalTradeStep.INSTRUCTIONS:
			return t('instructions');
		case PhysicalTradeStep.SHIPPING_DOCUMENTS:
			return t('shipping_documents');
		case PhysicalTradeStep.SHIPPING_TENDER:
			return t('shipping_tender');
		case PhysicalTradeStep.INVOICING:
			return t('invoicing');
		default:
			return '';
	}
};

export const TradeStepStatus = {
	NOT_STARTED: 1,
	COMPLETED_BY_COUNTERPARTY: 2,
	COMPLETED_BY_ME: 3,
	COMPLETED_BY_BOTH: 4,
};

export const ResponseStepStatus = {
	NEW: 'new',
	DONE: 'done',
	BUYER_DONE: 'buyer_done',
	SELLER_DONE: 'seller_done',
};
