/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ReactComponent as RequestIcon } from 'src/assets/icons/request.svg';
import { requestDocumentTypes } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useTogglable } from 'src/_helpers/useTogglable';
import { DocumentRequestModal } from './DocumentRequestModal';
import * as Styled from './styled';

export const DocumentRequest = ({ tradeId, alreadyRequestedDocuments, hasRequestedNotes }) => {
	const { t } = useTranslation();
	const [isOpen, { open, close }] = useTogglable();

	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [requestedTypes, setRequestedTypes] = useState({});
	const [notes, setNotes] = useState('');

	const { isLoading, mutate } = useMutation(requestDocumentTypes, {
		onSettled: async () => {
			await queryClient.refetchQueries(['trade', tradeId]);
			await queryClient.refetchQueries(['activity_logs', tradeId]);

			addToast({
				kind: 'info',
				message: t('selected_documents_were_requested'),
			});

			close();
		},
	});

	const handleDocumentClick = (event, { value }) => {
		event.preventDefault();
		setRequestedTypes(prevTypes => ({
			...prevTypes,
			[value]: !prevTypes[value],
		}));
	};

	useEffect(() => {
		if (!isOpen && Object.keys(requestedTypes).length) {
			setRequestedTypes({});
		}
	}, [isOpen, requestedTypes]);

	const handleSubmit = useCallback(() => {
		const newTypes = Object.keys(requestedTypes).filter(type => requestedTypes[type]);
		const types = [...alreadyRequestedDocuments, ...newTypes];

		mutate({ tradeId, types, notes });
	}, [mutate, notes, alreadyRequestedDocuments, requestedTypes, tradeId]);

	const canSubmit = Object.values(requestedTypes).some(type => type) || notes.length > 0;

	const buttonText =
		hasRequestedNotes || alreadyRequestedDocuments.length > 0
			? t('edit_request')
			: t('request_documents');

	return (
		<DocumentRequestModal
			trigger={
				<Styled.Button data-test="request-documents-button">
					<RequestIcon /> {buttonText}
				</Styled.Button>
			}
			open={open}
			close={close}
			isOpen={isOpen}
			blockedTypes={alreadyRequestedDocuments}
			onDocumentClick={handleDocumentClick}
			setNotes={setNotes}
			isLoading={isLoading}
			onSubmit={handleSubmit}
			canSubmit={canSubmit}
			hasRequestedNotes={hasRequestedNotes}
		/>
	);
};
