/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { Tooltip, TooltipTrigger, TooltipContent } from '../Tooltip/Tooltip';

export const CopyToClipboard = ({
	children,
	resourceToCopy,
	feedbackMessage,
	tooltipDismissTimeout = 3000,
}) => {
	const [tooltipOpened, setTooltipOpened] = useState(false);

	const handleClick = async () => {
		await copyToClipboard(resourceToCopy);
		setTooltipOpened(true);

		setTimeout(() => {
			setTooltipOpened(false);
		}, tooltipDismissTimeout);
	};

	return (
		<Tooltip open={tooltipOpened} onOpenChange={setTooltipOpened}>
			<TooltipTrigger onClick={handleClick}>{children}</TooltipTrigger>
			<TooltipContent>{feedbackMessage}</TooltipContent>
		</Tooltip>
	);
};

export const copyToClipboard = async resourceToCopy => {
	try {
		await navigator.clipboard.writeText(resourceToCopy);
	} catch (error) {
		console.error('Failed to copy: ', error);
	}
};
