/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem } from 'semantic-ui-react';
import { ReactComponent as ShareLinkIcon } from 'src/assets/icons/share-link.svg';
import { ReactComponent as ShareWhatsappIcon } from 'src/assets/icons/share-whatsapp.svg';
import { copyToClipboard } from 'src/components/CopyToClipboard/CopyToClipboard';
import * as Styled from './styled';

const DropdownItemMaxWidth = 170;

/**
 * Unfortunately we cannot use the CopyToClipboard component here, because it internally uses the Tooltip component which updates its own position
 * whenever the parent ref changes. So when we click on the "Copy link" dropdown item, the tooltip renders in correct position, but the the dropdown
 * disappears and then tooltip immediately updates its position to top left window corner.
 */
export const ShareOrder = ({ shareLink, shareText }) => {
	const [messagePosition, setMessagePosition] = useState();
	const [messageVisible, setMessageVisible] = useState();
	const [openToLeft, setOpenToLeft] = useState(false);
	const { t } = useTranslation();

	const wrapperRef = useRef();
	const messageRef = useRef();

	const calculatePosition = () => {
		setMessagePosition({
			left: wrapperRef.current.offsetLeft - messageRef.current.offsetWidth / 2,
			top: wrapperRef.current.offsetTop,
		});
	};

	useEffect(() => {
		if (!wrapperRef.current) {
			return;
		}

		if (
			document.body.offsetWidth - wrapperRef.current.getBoundingClientRect().right <
			DropdownItemMaxWidth
		) {
			setOpenToLeft(true);
		} else {
			setOpenToLeft(false);
		}
	}, []);

	const copyLink = () => {
		copyToClipboard(shareLink);
		setMessageVisible(true);

		setTimeout(() => {
			setMessageVisible(false);
		}, 3000);
	};

	const openWhatsApp = () => {
		window.open(
			'https://api.whatsapp.com/send/?type=custom_url&app_absent=0&text=' +
				encodeURIComponent(shareText)
		);
	};

	return (
		<div>
			<Styled.ShareWrapper ref={wrapperRef}>
				<Dropdown
					direction={openToLeft ? 'left' : 'right'}
					icon={
						<Styled.ShareButtonWrapper>
							<Styled.ShareButton />
						</Styled.ShareButtonWrapper>
					}
					onOpen={calculatePosition}
				>
					<Styled.ShareMenuContainer>
						<DropdownItem onClick={copyLink}>
							<ShareLinkIcon />
							{t('copy_link')}
						</DropdownItem>
						<DropdownItem onClick={openWhatsApp}>
							<ShareWhatsappIcon />
							{t('share_whatsapp')}
						</DropdownItem>
					</Styled.ShareMenuContainer>
				</Dropdown>
			</Styled.ShareWrapper>
			<Styled.ConfirmationMessage
				style={messagePosition}
				ref={messageRef}
				className={clsx({ visible: messageVisible })}
			>
				{t('link_to_order_copied')}
			</Styled.ConfirmationMessage>
		</div>
	);
};
