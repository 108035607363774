/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useState } from 'react';
import { useDebouncedValue } from 'src/shared/useDebouncedValue';

export const useIntersectionObserver = (refs, chapters, isDisabled) => {
	const [intersectedChapter, setIntersectedChapter] = useState();
	const debouncedIntersectedChapter = useDebouncedValue(intersectedChapter, 100);

	const callback = entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				setIntersectedChapter(entry.target.id);
			}
		});
	};

	useEffect(() => {
		if (isDisabled) {
			return;
		}

		const observer = new IntersectionObserver(callback, { rootMargin: '0px 0px -70%' });

		if (refs.current) {
			Object.values(refs.current).forEach(entries => {
				if (entries) {
					observer.observe(entries);
				}
			});
		}

		return () => observer.disconnect();
	}, [refs, chapters, isDisabled]);

	return debouncedIntersectedChapter;
};
