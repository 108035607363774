/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { isNil } from './utils';
import { tCurrency } from 'src/constants/contract';

export const isThreeDigitPrice = (price, maximumFractionDigits = 3) => {
	if (!price) {
		return false;
	}

	const priceString = price.toString();

	// >= because Javascript can't do math :(
	return priceString.includes('.')
		? priceString.split('.')[1]?.length >= maximumFractionDigits
		: false;
};

/** GOT THE SOLUTION FOR NEGATIVE PRICE FORMAT AT https://codepen.io/Cryptoch/pen/gOaKBZP */
export const formatPrice = (price, options = {}) => {
	const stripSymbols = options.currencyDisplay === 'none';
	const internalOptions = stripSymbols ? { ...options, currencyDisplay: 'code' } : options;

	let currencyReplaced = false;

	if (isThreeDigitPrice(price, options.maximumFractionDigits)) {
		const multiplier = Math.pow(10, options.maximumFractionDigits || 3);

		const roundedPrice = Math.round(price * multiplier) / multiplier;
		return options.currency ? `${options.currency} ${roundedPrice}` : roundedPrice.toString();
	}

	let result =
		!isNil(price) &&
		new Intl.NumberFormat('en', {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'code',
			...internalOptions,
		})
			.formatToParts(price)
			.map((item, idx, arr) => {
				if ((item.type === 'currency' || item.type === 'literal') && currencyReplaced) {
					return '';
				}
				const nextCurrency =
					arr[idx + 1] && arr[idx + 1].type === 'currency' && arr[idx + 1].value;

				if (item.type === 'minusSign' && nextCurrency && !currencyReplaced) {
					currencyReplaced = true;
					return `${nextCurrency}\xa0${item.value}`;
				}
				return item.value;
			})
			.join('');

	if (stripSymbols && !isNil(price)) {
		result = result
			.replace(/[a-z]{3}/i, '')
			.replace(/\s/i, '')
			.trim();
	}

	return result;
};

export const formatPriceWithoutCurrency = (price, options = {}) =>
	formatPrice.call(null, price, { ...options, currencyDisplay: 'none' });

export const isPriceDefined = val => !!val || val === 0;

export const formatFloatNumberToPriceFormat = value => {
	const num = parseFloat(value);

	if (num.toString().includes('.')) {
		let decimalPlaces = num.toString().split('.')[1].length;

		if (decimalPlaces > 2) {
			const roundedPrice = Math.round(num * 1000) / 1000;

			const roundedPriceDecimalPlaces = roundedPrice.toString().split('.')[1].length;

			if (roundedPriceDecimalPlaces === 1) {
				return roundedPrice.toFixed(2);
			}

			return roundedPrice.toString();
		}
	}

	return num.toFixed(2);
};

export const getCurrencyAndUnitOfOrderOrCounter = (order, t) => {
	const { currency, price_unit } = order;

	return `${tCurrency(t, currency)}/${t('price_unit_short_' + price_unit, {
		lng: 'en',
	})}`;
};
