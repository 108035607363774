/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export function getApiUrl(location) {
	const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;
	if (REACT_APP_API_HOST) {
		return REACT_APP_API_HOST;
	}

	if (process.env.IS_TEST) {
		return 'http://localhost';
	}
	if (typeof global.window !== 'undefined' && location.hostname.indexOf('127.0.0.1') !== -1) {
		return 'http://127.0.0.1:3010';
	}

	if (location.hostname.indexOf('localhost') > -1) {
		return 'https://api.vosbor.dev';
	}

	return `${location.protocol}//api.${location.hostname.replace('www.', '')}`;
}
