/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';

export const TimePrefix = 'MIN';

export const mapTimeRangeOptionToApi = value => {
	const time = parseInt(value.replace(TimePrefix, ''));
	const hours = Math.floor(time / 60);
	const minutes = time - hours * 60;

	if (hours) {
		return `${hours}h`;
	}

	return `${minutes}m`;
};

export const mapApiTimeValue = code => {
	if (!code) {
		return null;
	}
	const isMinute = code.endsWith('m');
	const isHour = code.endsWith('h');
	const value = parseInt(code.replace('m', '').replace('h', ''));

	if (isMinute) {
		return {
			minutes: value,
		};
	}

	if (isHour) {
		return {
			hours: value,
		};
	}

	return null;
};

export const mapApiCodeToPrefixedMinutes = code => {
	if (!code) {
		return '';
	}

	const { minutes, hours } = mapApiTimeValue(code);

	if (minutes) {
		return `${TimePrefix}${minutes}`;
	}

	if (hours) {
		const mins = hours * 60;
		return `${TimePrefix}${mins}`;
	}

	return '';
};

export const translateTimeRangeOptions = (list, t) =>
	list
		.map(code => {
			const { minutes, hours } = mapApiTimeValue(code);

			if (minutes) {
				return {
					key: `${TimePrefix}${minutes}`,
					value: minutes,
					label: `${minutes}${
						minutes === 1 ? t('time_label_min') : t('time_label_mins')
					}`,
				};
			}

			if (hours) {
				const mins = hours * 60;
				return {
					key: `${TimePrefix}${mins}`,
					value: mins,
					label: `${hours}${hours === 1 ? t('time_label_hour') : t('time_label_hours')}`,
				};
			}

			return false;
		})
		.filter(Boolean);

export const DefaultValidityOptions = ['5m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '24h'];

export const isTimeInMinutes = code => code?.startsWith(TimePrefix);

export const isApiTime = code => code.includes('m') || code.includes('h');

export const getTimeOptionMinutes = code => {
	if (!isTimeInMinutes(code)) {
		return null;
	}
	return parseInt(code.replace(TimePrefix, ''));
};

export const tTimeOptions = (t, value) => {
	const hours = Math.floor(value / 60);
	const minutes = value - hours * 60;

	if (hours) {
		return `${hours}${hours === 1 ? t('time_label_hour') : t('time_label_hours')}`;
	}

	return `${minutes}${minutes === 1 ? t('time_label_min') : t('time_label_mins')}`;
};

export const formatTradingSessionTime = timeString => {
	const today = new Date().toISOString().split('T')[0];
	const zoneDirection = timeString.includes('+') ? '+' : '-';
	const [, zone] = timeString.split(zoneDirection);
	const date = `${today}T${timeString}`;
	const momentDate = moment(date);

	return momentDate.zone(`${zoneDirection}${zone}`).format('hh:mm A');
};

export const formatTradingSessionTimeToLocal = timeString => {
	const today = new Date().toISOString().split('T')[0];
	const date = `${today}T${timeString}`;
	const momentDate = moment(date);

	return momentDate.format('hh:mm A');
};

export const formatTradingSessionWithFullDate = (timeString, iso = false) => {
	const today = new Date().toISOString().split('T')[0];
	const date = `${today}T${timeString}`;
	const now = moment();

	const day = now.isAfter(date) ? moment(date).add(1, 'd') : moment(date);

	if (iso) {
		return day.toISOString();
	}

	return day.format('lll');
};
