/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'src/components/Toast';
import { acceptInvite } from 'src/_api';
import * as Styled from './styled';

export const AcceptInvitationButton = ({ userId, inviteId, onClick }) => {
	const { t } = useTranslation();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const { mutate: acceptInviteMutation, isLoading } = useMutation(acceptInvite, {
		onSuccess: () => {
			queryClient.invalidateQueries(['search-users']);
			queryClient.invalidateQueries(['user-details', userId]);

			addToast({
				message: t('invite_accepted_success'),
			});
		},
		onError: () => {
			addToast({
				message: t('invite_accepted_error'),
				kind: 'error',
			});
		},
	});

	const handleButtonClick = event => {
		event.stopPropagation();
		onClick?.();
		acceptInviteMutation({ inviteId });
	};

	return (
		<Styled.AddToContactsButton onClick={handleButtonClick} loading={isLoading}>
			<span>{t('accept_contact_invitation')}</span>
		</Styled.AddToContactsButton>
	);
};
