/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import * as Styled from './styled';

const SectionHeader = ({ header }) => <Styled.Header>{header}</Styled.Header>;

export const firstLegHeader = (_, t) => <SectionHeader header={t('first_leg')} />;

export const secondLegHeader = (_, t) => <SectionHeader header={t('second_leg')} />;

export const quoteHeader = (_, t) => <SectionHeader header={t('quote')} />;
