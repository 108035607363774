/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { StyledListItem, StyledList, ListHeader } from '../components/List';
import { hourOptions } from './model';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const HourSelect = React.memo(({ value, onChange }) => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<ListHeader>{t('hour')}</ListHeader>
			<StyledList selection verticalAlign="middle">
				{hourOptions.map(hour => (
					<StyledListItem
						style={{ textAlign: 'center' }}
						key={hour}
						content={hour.toString().padStart(2, '0')}
						active={value === hour}
						onClick={() => onChange(hour)}
						data-test={`hour-${hour}`}
					/>
				))}
			</StyledList>
		</Wrapper>
	);
});

const Wrapper = styled.div`
	padding-right: 16px;
	padding-left: 16px;
`;
