/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export function getDecimalLength(num) {
	const str = num?.toString() || '';
	const digits = str.split('.');
	return digits[1]?.length || 0;
}

export function getSpecDirection(spec) {
	return spec.min_enabled ? 'min' : 'max';
}
