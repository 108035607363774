/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { formatDateRange, parseDateStringAsUTC } from 'src/_helpers/date';
import { formatPriceWithoutCurrency } from 'src/_helpers/price';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import {
	SectionContainer,
	SectionTitleWithInfo,
	SectionTitleInfo,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from '../../styled';
import {
	PriceIncrementType,
	QuoteType,
	tQuoteValueType,
	PriceType,
	tPriceType,
	tPriceIncrement,
	tCurrency,
} from 'src/constants/contract';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';

export const PricingSection = ({ order, changes }) => {
	const { t } = useTranslation();

	const { isBrokerOrder } = useOrderDetails(order);

	const hasPrice = order.has_price !== false;
	const quoteType = order.is_indicative ? QuoteType.Indicative : QuoteType.Firm;
	const isBasisPriceType = order.price_type === PriceType.Basis;

	const isCounter = !!changes;

	const futuresContractDate =
		order.futures_contract_date_formatted ||
		(order.futures_contract_date && moment(order.futures_contract_date).format('MMMM YYYY'));

	return (
		<SectionContainer data-test="pricing-section">
			<SectionTitleWithInfo>
				{t('pricing')}
				{isBrokerOrder && (
					<SectionTitleInfo>{t('external_brokerage_fees_may_apply')}</SectionTitleInfo>
				)}
			</SectionTitleWithInfo>
			<SectionGrid>
				{!order.trade_id && (
					<SectionGridContent className={clsx({ changed: changes?.is_indicative })}>
						<SectionGridLabel>
							{isCounter ? t('counter_type') : t('order_type_label')}
						</SectionGridLabel>
						<SectionGridText data-test="pricing-section-order-type">
							{tQuoteValueType(t, quoteType)}
						</SectionGridText>
					</SectionGridContent>
				)}
				<SectionGridContent className={clsx({ changed: changes?.futures_contract })}>
					<SectionGridLabel>{t('price_type')}</SectionGridLabel>
					<SectionGridText data-test="pricing-section-price-type">
						{isBasisPriceType
							? order.futures_contract
							: hasPrice
							? tPriceType(t, order.price_type)
							: t('price_indication_no_price')}
					</SectionGridText>
				</SectionGridContent>
				{hasPrice && isBasisPriceType && (
					<SectionGridContent
						className={clsx({ changed: changes?.futures_contract_date })}
					>
						<SectionGridLabel>{t('futures_month')}</SectionGridLabel>
						<SectionGridText data-test="pricing-section-futures-month">
							{futuresContractDate}
						</SectionGridText>
					</SectionGridContent>
				)}
				{(order.payment || changes?.payment) && (
					<SectionGridContent className={clsx({ changed: changes?.payment })}>
						<SectionGridLabel>{t('payment')}</SectionGridLabel>
						<SectionGridText data-test="pricing-section-payment">
							{order.payment || '---'}
						</SectionGridText>
					</SectionGridContent>
				)}
				{([PriceIncrementType.Monthly, PriceIncrementType.HalfMonthly].includes(
					order.price_increment_type
				) ||
					changes?.price_increment_type) && (
					<>
						<SectionGridContent
							className={clsx({ changed: changes?.price_increment_type })}
						>
							<SectionGridLabel>{t('price_increment')}</SectionGridLabel>
							<SectionGridText data-test="pricing-section-price-increment-type">
								{order.price_increment_type
									? tPriceIncrement(t, order.price_increment_type)
									: '---'}
							</SectionGridText>
						</SectionGridContent>
						<SectionGridContent
							className={clsx({
								changed:
									changes?.price_increment_basis_shipment_from ||
									changes?.price_increment_basis_shipment_to ||
									changes?.price_increment_basis_shipment_mode,
							})}
						>
							<SectionGridLabel>{t('basis_shipment')}</SectionGridLabel>
							<SectionGridText data-test="pricing-section-basis-shipment">
								{order.price_increment_basis_shipment_from ? (
									<OverflowText>
										{formatDateRange({
											format: order.price_increment_basis_shipment_mode,
											startDate: parseDateStringAsUTC(
												order.price_increment_basis_shipment_from
											),
											endDate: parseDateStringAsUTC(
												order.price_increment_basis_shipment_to
											),
										})}
									</OverflowText>
								) : (
									'---'
								)}
							</SectionGridText>
						</SectionGridContent>
						<SectionGridContent className={clsx({ changed: changes?.price_increment })}>
							<SectionGridLabel>{t('increment')}</SectionGridLabel>
							<SectionGridText data-test="pricing-section-price-increment">
								{order.price_increment
									? `${formatPriceWithoutCurrency(order.price_increment, {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
									  })} ${tCurrency(t, order.currency)}/${t(
											`price_unit_short_${order.price_unit}`,
											{
												lng: 'en',
											}
									  )}`
									: '---'}
							</SectionGridText>
						</SectionGridContent>
					</>
				)}
			</SectionGrid>
		</SectionContainer>
	);
};
