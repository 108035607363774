/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { T150 } from 'src/components/Typography/fonts';
import { ReactComponent as LanguageIcon } from 'src/assets/icons/language_switch_icon.svg';
import { Languages } from 'src/constants/settings';
import {
	DropdownToggleItem,
	DropdownToggleTrigger,
	DropdownToggleChild,
} from 'src/components/Dropdown/Dropdown';

export const LanguageSettings = ({ switchToLanguage }) => {
	const { t } = useTranslation();

	// Note: we don't want to use current language (i18n.language) directly, because it can be one of the unsupported languages (es / pt / pl / etc) with fallback to en,
	// so it's better to use the language as a simple flag - if it's Chinese then we select "Chinese" option, if it's anything else - we select "English" option
	const isChinese = i18n.language === Languages.Chinese.code;

	return (
		<DropdownToggleItem
			trigger={({ isOpen, toggle }) => (
				<DropdownToggleTrigger
					isOpen={isOpen}
					toggle={toggle}
					data-test="language-settings-dropdown"
				>
					<LanguageIcon />
					<T150>{t('language')}</T150>
				</DropdownToggleTrigger>
			)}
		>
			<DropdownToggleChild
				className="dropdown-child"
				text={t('language_english')}
				active={!isChinese}
				onClick={switchToLanguage(Languages.English)}
			/>
			<DropdownToggleChild
				className="dropdown-child"
				text={t('language_chinese')}
				active={isChinese}
				onClick={switchToLanguage(Languages.Chinese)}
			/>
		</DropdownToggleItem>
	);
};
