/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip/Tooltip';

export const OriginsListMode = {
	simple: 'simple',
	full: 'full',
	tooltip: 'tooltip',
};

export const formatOriginsList = (list, nameKey, mode = OriginsListMode.simple, t) => {
	if (!list || list.length === 0) {
		return '---';
	}

	let listitems = list;

	if (typeof list[0] === 'object' && !!nameKey) {
		listitems = list.map(item => item[nameKey]);
	}

	listitems.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

	const fullList = listitems.join(', ');

	if (listitems.length === 1 || mode === OriginsListMode.full) {
		return fullList;
	}

	const label = `${listitems[0]} +${listitems.length - 1}`;

	if (mode === OriginsListMode.tooltip) {
		return (
			<Tooltip placement="bottom-start">
				<TooltipTrigger>{t ? t('multiple_origin') : label}</TooltipTrigger>
				<TooltipContent tooltipTestId="origins-tooltip">{fullList}</TooltipContent>
			</Tooltip>
		);
	}

	return label;
};
