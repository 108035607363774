/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { FoldableSection } from 'src/components/CreateOrderDrawer/components/FoldableSection/FoldableSection';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { useQuoteCounterFields } from './useQuoteCounterFields';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { ProductDropdown } from 'src/components/CreateOrderDrawer/components/ProductDropdown/ProductDropdown';
import { PortDropdown } from 'src/components/CreateOrderDrawer/components/PortDropdown/PortDropdown';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import { getShipmentCutOffDate } from 'src/_helpers/date';
import { OrderType } from 'src/constants/contract';
import { MaterialMultiSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialMultiSelectInput';

export const QuoteSection = ({ lastCounter }) => {
	const { t } = useTranslation();

	const {
		formState: { errors },
	} = useFormContext();

	const {
		quote,
		volume,
		inco,
		port,
		date,
		grade,
		harvest,
		speciality,
		origin,
	} = useQuoteCounterFields(lastCounter);

	return (
		<FoldableSection title={t('quote')} canToggle>
			<Controller
				mandatory
				name="type"
				render={({ field }) => (
					<MaterialSelectInput
						required
						data-test={field.name}
						label={t('quote')}
						options={quote.options}
						disabled={!quote.counterable}
						{...field}
						value={field.value === OrderType.Buy ? OrderType.Sell : OrderType.Buy}
					/>
				)}
			/>
			<Controller
				mandatory
				name="volume"
				render={({ field }) => (
					<MaterialNumberInput
						required
						error={!!errors[field.name]}
						data-test={field.name}
						id="volume"
						label={t('quantity')}
						fixedDecimalScale={false}
						controlledState
						hasChanged={volume.hasChanged}
						parameters={t('mt')}
						{...field}
						onChange={value => {
							field.onChange(+value);
						}}
					/>
				)}
			/>
			<Controller
				mandatory
				name="productAndType"
				render={({ field }) => (
					<ProductDropdown
						error={!!errors[field.name]}
						data-test={field.name}
						required
						disabled
						label={t('product')}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name="inco"
				render={({ field }) => (
					<MaterialSelectInput
						required
						search={(list, phrase) => {
							const lowerCasePhrase = phrase.toLowerCase();
							return list.filter(option =>
								option.text.toLowerCase().includes(lowerCasePhrase)
							);
						}}
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('inco')}
						controlledState
						hasChanged={inco.hasChanged}
						options={inco.options}
						disabled={!inco.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name="port"
				render={({ field }) => (
					<PortDropdown
						error={!!errors[field.name]}
						data-test={field.name}
						required
						controlledState
						hasChanged={port.hasChanged}
						label={t('port')}
						onValueUpdate={port.addName}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name="date"
				defaultValue={null}
				render={({ field }) => (
					<MaterialDateInput
						{...field}
						required
						rightAligned
						id={field.name}
						data-test={field.name}
						label={date.label}
						controlledState
						hasChanged={date.hasChanged}
						error={!!errors[field.name]}
						cutOffDate={getShipmentCutOffDate()}
						initialVisibleMonth={date.initialValue}
					/>
				)}
			/>
			{origin.show && (
				<Controller
					name="origin"
					render={({ field }) => (
						<MaterialMultiSelectInput
							key={`origins-dropdown${origin.ready ? '' : '-loading'}`}
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('origin')}
							options={origin.options}
							controlledState
							disabled={!origin.counterable}
							{...field}
						/>
					)}
				/>
			)}
			<Controller
				mandatory
				name="grade"
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						required
						data-test={field.name}
						label={t('grade')}
						options={grade.options}
						disabled={!grade.counterable}
						{...field}
					/>
				)}
			/>
			{harvest.show && (
				<Controller
					name="harvest"
					render={({ field }) => (
						<MaterialSelectInput
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('harvest')}
							options={harvest.options}
							disabled={!harvest.counterable}
							{...field}
						/>
					)}
				/>
			)}
			{speciality.show && (
				<Controller
					name="speciality"
					render={({ field }) => (
						<MaterialSelectInput
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('speciality')}
							options={speciality.options}
							disabled={!speciality.counterable}
							{...field}
						/>
					)}
				/>
			)}
		</FoldableSection>
	);
};
