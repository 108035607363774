/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';

export const h1000 = css`
	font-size: var(--h-1000-font-size);
	font-weight: var(--h-1000-font-weight);
	letter-spacing: var(--h-1000-letter-spacing);
	line-height: var(--h-1000-line-height);
`;

export const H1000 = styled.h1`
	${h1000}
`;

export const h900 = css`
	font-size: var(--h-900-font-size);
	font-weight: var(--h-900-font-weight);
	letter-spacing: var(--h-900-letter-spacing);
	line-height: var(--h-900-line-height);
`;

export const H900 = styled.h1`
	${h900}
`;

export const h800 = css`
	font-size: var(--h-800-font-size);
	font-weight: var(--h-800-font-weight);
	letter-spacing: var(--h-800-letter-spacing);
	line-height: var(--h-800-line-height);
`;

export const H800 = styled.h2`
	${h800}
`;

export const h700 = css`
	font-size: var(--h-700-font-size);
	font-weight: var(--h-700-font-weight);
	letter-spacing: var(--h-700-letter-spacing);
	line-height: var(--h-700-line-height);
`;

export const h700large = css`
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0.2px;
	line-height: 21.09px;
`;

export const H700 = styled.h2`
	${h700}
`;

export const H700Bold = styled.h2`
	${h700}
	font-weight: 700;
`;

export const h600 = css`
	font-size: var(--h-600-font-size);
	font-weight: var(--h-600-font-weight);
	letter-spacing: var(--h-600-letter-spacing);
	line-height: var(--h-600-line-height);
`;

export const H600 = styled.h2`
	${h600}
`;

export const h500 = css`
	font-size: var(--h-500-font-size);
	font-weight: var(--h-500-font-weight);
	letter-spacing: var(--h-500-letter-spacing);
	line-height: var(--h-500-line-height);
`;

export const h500large = css`
	font-size: 15px;
	font-weight: 700;
	letter-spacing: 0.2px;
	line-height: 17.58px;
`;

export const H500 = styled.h3`
	${h500}
`;

export const h400 = css`
	font-size: var(--h-400-font-size);
	font-weight: var(--h-400-font-weight);
	letter-spacing: var(--h-400-letter-spacing);
	line-height: var(--h-400-line-height);
`;

export const h400medium = css`
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 0.2px;
	line-height: 15.23px;
`;

export const h400large = css`
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.2px;
	line-height: 16.41px;
`;

export const H400 = styled.h4`
	${h400}
`;

export const h300 = css`
	font-size: var(--h-300-font-size);
	font-weight: var(--h-300-font-weight);
	letter-spacing: var(--h-300-letter-spacing);
	line-height: var(--h-300-line-height);
`;

export const H300 = styled.h5`
	${h300}
`;

export const h200 = css`
	font-size: var(--h-200-font-size);
	font-weight: var(--h-200-font-weight);
	letter-spacing: var(--h-200-letter-spacing);
	line-height: var(--h-200-line-height);
`;

export const H200 = styled.h6`
	${h200}
`;

export const t200 = css`
	font-size: var(--t-200-font-size);
	font-weight: var(--t-200-font-weight);
	letter-spacing: var(--t-200-letter-spacing);
	line-height: var(--t-200-line-height);
`;

export const T200 = styled.span`
	${t200}
`;

export const t150 = css`
	font-size: var(--t-150-font-size);
	font-weight: var(--t-150-font-weight);
	letter-spacing: var(--t-150-letter-spacing);
	line-height: var(--t-150-line-height);
`;

export const T150 = styled.span`
	${t150}
`;

export const t100 = css`
	font-size: var(--t-100-font-size);
	font-weight: var(--t-100-font-weight);
	letter-spacing: var(--t-100-letter-spacing);
	line-height: var(--t-100-line-height);
`;

export const T100 = styled.span`
	${t100}
`;

export const t50 = css`
	font-size: var(--t-50-font-size);
	font-weight: var(--t-50-font-weight);
	letter-spacing: var(--t-50-letter-spacing);
	line-height: var(--t-50-line-height);
`;

export const T50 = styled.span`
	${t50}
`;

export const m200 = css`
	font-size: var(--m-200-font-size);
	font-weight: var(--m-200-font-weight);
	letter-spacing: var(--m-200-letter-spacing);
	line-height: var(--m-200-line-height);
`;

export const M200 = styled.span`
	${m200}
`;

export const m100 = css`
	font-size: var(--m-100-font-size);
	font-weight: var(--m-100-font-weight);
	letter-spacing: var(--m-100-letter-spacing);
	line-height: var(--m-100-line-height);
`;

export const M100 = styled.span`
	${m100}
`;

export const m50 = css`
	font-size: var(--m-50-font-size);
	font-weight: var(--m-50-font-weight);
	letter-spacing: var(--m-50-letter-spacing);
	line-height: var(--m-50-line-height);
`;

export const m50regular = css`
	font-size: 11px;
	font-weight: 400;
	letter-spacing: 0.2px;
	line-height: 12.89px;
`;

export const M50 = styled.span`
	${m50}
`;

export const Typography = {
	H1000,
	H900,
	H700,
	H600,
	H500,
	H400,
	H300,
	H200,
	T200,
	T150,
	T100,
	T50,
	M200,
	M100,
	M50,
};
