/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Avatar, AvatarType } from 'src/shared/components/Avatar/Avatar';
import { useUserPresenceContext } from 'src/websockets/UserPresenceProvider';
import { isInactiveUser } from 'src/_helpers/isInactiveUser';
import { VisibilityStatus } from 'src/constants/contacts';
import { UserProfileLink } from 'src/components/UserProfileModal/UserProfileLink';
import { UserStatus } from 'src/components/UserStatus/UserStatus';
import { getInitials } from 'src/_helpers/getInitials';
import { OverflowText } from '../Tooltip/OverflowText';
import * as Styled from './styled';

export const UserAvatar = ({
	user,
	onModalActionClick = () => {},
	showCompanyCountry,
	className,
	size = AvatarType.Large,
	isUserHidden = false,
	usernameChildren = null,
}) => {
	const { getUserVisibility } = useUserPresenceContext();
	const userInitials = getInitials(user.first_name, user.last_name);
	const dotType = isUserHidden
		? null
		: getUserVisibility(user._key, user.visibility || VisibilityStatus.OFFLINE);
	return (
		<UserProfileLink
			userId={user._key || user.id}
			onModalActionClick={onModalActionClick}
			isInactive={isInactiveUser({ status: user.status }) || isUserHidden}
		>
			<Styled.UserDetailsContainer data-test="user-avatar">
				<Avatar
					initials={userInitials}
					size={size}
					backgroundColor={user.avatar_color}
					dotType={dotType}
					dotBackgroundColor={'var(--neutral-700)'}
					data-test={`avatar-${userInitials}`}
					className={className}
				/>
				<Styled.UserDetails className="user-avatar-details">
					<Styled.UserNameContainer>
						<div className="user-avatar-username-container">
							<OverflowText>
								<Styled.Name
									data-test={`user-avatar-name-${user._key}`}
									className="user-avatar-username"
								>{`${user.first_name} ${user.last_name}`}</Styled.Name>
							</OverflowText>
							<UserStatus status={user.status} />
						</div>
						{usernameChildren}
					</Styled.UserNameContainer>
					<Styled.UserInfo className="user-company-name">
						<div className="user-avatar-company-name">
							<OverflowText>
								<span>{user.company?.name || user.company}</span>
							</OverflowText>
						</div>
						{showCompanyCountry && user.company?.country_code && (
							<Styled.CompanyCountryCode data-test="order-user-details-country">
								{user.company.country_code}
							</Styled.CompanyCountryCode>
						)}
					</Styled.UserInfo>
				</Styled.UserDetails>
			</Styled.UserDetailsContainer>
		</UserProfileLink>
	);
};
