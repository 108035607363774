/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'querystring';

export function useQueryParams() {
	const querystring = useLocation().search.replace('?', '');

	return useMemo(() => qs.parse(querystring), [querystring]);
}

export const useLocationStateParam = paramName => {
	const location = useLocation();

	if (!location.state || !location.state[paramName]) {
		return null;
	}

	return location.state[paramName];
};
