/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as ShieldAlert } from 'src/assets/icons/shield_alert.svg';
import { H400 } from 'src/components/Typography/fonts';

export const ChartContainer = styled.div`
	height: 100%;
	position: absolute !important;
	top: 0;
	width: 100%;
	left: 0;
`;

export const ShieldAlertIcon = styled(ShieldAlert)`
	color: var(--yellow-400);
	width: 32px;
	height: 32px;
`;

export const PlaceholderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	background: rgba(16, 20, 39, 0.5);
	position: absolute;
	z-index: 2;
`;

export const PlaceholderText = styled(H400)`
	margin-top: 16px;
`;
