/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { useTradeUsersQuery } from 'src/_api/queries';
import { sortOrder } from 'src/_helpers/sort';

export const useExecutionUsers = (tradeData, isModalOpen) => {
	const userId = useSelector(getUserId);
	const { buyer_user_id, seller_user_id, seller_user_ids, buyer_user_ids, brokerId } = tradeData;

	const { data: tradeUsers = [], isLoading } = useTradeUsersQuery(
		{
			_sort: 'name',
			_order: sortOrder.asc,
			_limit: 1000,
		},
		{ enabled: isModalOpen(0) }
	);

	const sortedTradeUsers = useMemo(() => {
		if (!tradeUsers.length) {
			return [];
		}

		const currentUser = tradeUsers.find(({ _key }) => _key === userId);

		if (!currentUser) {
			return tradeUsers;
		}

		return [currentUser, ...tradeUsers.filter(u => u !== currentUser)];
	}, [tradeUsers, userId]);

	const originalTraders = [buyer_user_id, seller_user_id];

	const isBuyerSide = userId => buyer_user_ids.includes(userId);
	const isSellerSide = userId => seller_user_ids.includes(userId);

	const canBeRemoved = tradeUserId => {
		const userIsOriginalTraderAndTraderIsBroker =
			originalTraders.includes(userId) && tradeUserId === brokerId;
		if (
			originalTraders.includes(tradeUserId) ||
			(tradeUserId === brokerId && !userIsOriginalTraderAndTraderIsBroker)
		) {
			return false;
		}

		return (
			(isSellerSide(tradeUserId) && isSellerSide(userId)) ||
			(isBuyerSide(tradeUserId) && isBuyerSide(userId))
		);
	};

	return { canBeRemoved, sortedTradeUsers, isLoading };
};
