/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { fakeContactDetails, UserType } from 'src/constants/userNetwork';
import * as Styled from './styled';

export const UserProfileInfo = ({ user, type }) => {
	const { t } = useTranslation();

	const commonDetailsList = [
		{
			label: 'country',
			value: user.countryName,
		},
		{
			label: 'city',
			value: user.cityName,
		},
	];

	const joinDateDetails = {
		label: 'join_date',
		value: moment(user.joinDate).format('ll'),
	};

	const userDetailsList =
		type === UserType.Contacts
			? [
					...commonDetailsList,
					{
						label: 'phone_number',
						value: user.phoneNumber,
					},
					{
						label: 'email',
						value: user.email,
					},
					{
						label: 'connected_date',
						value: moment(user.connectDate).format('ll'),
					},
					joinDateDetails,
			  ]
			: [...commonDetailsList, ...fakeContactDetails, joinDateDetails];

	return userDetailsList.map(({ label, value, hidden }) => (
		<Styled.DetailsRow key={label} data-test={label}>
			<label>{t(label)}</label>
			{hidden ? <Styled.Hidden>{value}</Styled.Hidden> : <OverflowText>{value}</OverflowText>}
		</Styled.DetailsRow>
	));
};
