/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { PhysicalTradeStep, ResponseStepStatus } from 'src/constants/physicalTrade';

export const useCompletedSteps = tradeData => {
	const statuses = tradeData.step_statuses || {};

	const responseToStepMapping = {
		confirmation: PhysicalTradeStep.TRADE_CONFIRMATION,
		signatures: PhysicalTradeStep.SIGNATURES,
		vessel_nomination: PhysicalTradeStep.VESSEL_NOMINATION,
		shipping_advice: PhysicalTradeStep.SHIPPING_ADVICE,
		instructions: PhysicalTradeStep.INSTRUCTIONS,
		shipping_documents: PhysicalTradeStep.SHIPPING_DOCUMENTS,
		shipping_tender: PhysicalTradeStep.SHIPPING_TENDER,
		invoicing: PhysicalTradeStep.INVOICING,
	};

	const steps = Object.entries(responseToStepMapping).reduce((acc, [responseKey, stepName]) => {
		if (statuses[responseKey] === ResponseStepStatus.DONE) {
			acc.push(stepName);
		}

		return acc;
	}, []);

	return steps;
};
