/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { IncoType } from 'src/constants/contract';
import { PhysicalTradeStep } from 'src/constants/physicalTrade';
import { ROUTES } from 'src/constants/routes';

export const useAllowedSteps = (tradeStep, incoId) => {
	const navigate = useNavigate();

	useEffect(() => {
		const isFOBorFASinco = [IncoType.FOB, IncoType.FAS].includes(incoId);

		if (isFOBorFASinco && tradeStep === PhysicalTradeStep.SHIPPING_ADVICE) {
			navigate(ROUTES.physicalExecutionVesselNomination, { replace: true });
		}

		if (!isFOBorFASinco && tradeStep === PhysicalTradeStep.VESSEL_NOMINATION) {
			navigate(ROUTES.physicalExecutionShippingAdvice, { replace: true });
		}
	}, [incoId, navigate, tradeStep]);
};
