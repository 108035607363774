/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { DataTable } from './DataTable';
import { InfiniteScrollDataTable } from './InfiniteScrollDataTable/InfiniteScrollDataTable';

const tableStyles = css`
	.ui.table& thead tr:first-child > th:first-child {
		padding-left: 22px;
	}

	.ui.table& tbody tr:not(.nested-row):not(.no-entries) > td:first-child {
		/* In paper tables the first row is not a link, just td with text */
		padding-left: 22px;

		> a {
			/* In physical tables the first row is a link */
			padding-left: 22px;
		}
	}
`;

export const BaseOrdersTable = styled(DataTable)`
	${tableStyles}
`;

export const BaseInfiniteScrollOrdersTable = styled(InfiniteScrollDataTable)`
	${tableStyles}
`;
