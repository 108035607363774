/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import qs from 'querystring';
import { API_URL, statusMap, withCSRFTokenHeader } from './model';

function mapFormToUserAPIModel(payload) {
	return {
		first_name: payload.firstname,
		last_name: payload.lastname,
		password: payload.password,
		email: payload.email,
		// Asign the company phone to the user due this is the phone for him within the company
		phone_number: payload.companyPhone,
		temp_company_company_name: payload.companyName,
		temp_company_street_number: payload.companyStreet,
		temp_company_city: payload.companyCity,
		temp_company_zip: payload.companyPostal,
		temp_company_website: payload.companySite,
		temp_company_country_code: payload.companyCountry,
		temp_company_department: payload.companyDepartment,
	};
}

export const registerUser = async payload => {
	const headers = new Headers({
		'Content-Type': 'application/x-www-form-urlencoded',
	});
	return fetch(API_URL + '/emailauth_create', {
		headers,
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		body: qs.stringify(mapFormToUserAPIModel(payload)),
	});
};

// LOG IN
export const loginUser = async payload => {
	let endpoint;
	let headers;
	let body;

	if (payload.code) {
		endpoint = '/2fa_login';
		body = JSON.stringify({
			email: payload.email,
			password: payload.password,
			code: `${payload.code}`,
		});
		headers = {
			'Content-Type': 'application/json',
		};
	} else {
		endpoint = '/emailauth_login';
		body = qs.stringify({
			email: payload.email,
			password: payload.password,
			new_mfa_flow_enabled: true,
			mfa_method: payload.method,
		});
		headers = {
			'Content-Type': 'application/x-www-form-urlencoded',
		};
	}

	const response = await fetch(API_URL + endpoint, {
		headers: new Headers(headers),
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		body,
	});

	let res;
	try {
		res = await response.json();
	} catch (e) {
		console.error(e, response.status);
		return { ok: false, data: {}, status: response.status, error: statusMap[response.status] };
	}
	return { ok: response.ok, data: res, error: res.error, status: response.status };
};

// LOG OUT
export const logoutUser = async () => {
	const response = await fetch(API_URL + '/logout', {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers: {
			...withCSRFTokenHeader(),
		},
	});
	return { ok: response.ok };
};

// RESET
export const resetUserPasswordRequestEmail = async payload => {
	const headers = new Headers({
		'Content-Type': 'application/x-www-form-urlencoded',
	});

	return fetch(API_URL + '/emailauth_reset', {
		headers,
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		body: qs.stringify(payload),
	});
};

//resetUserConfirm
export const resetUserConfirm = async payload => {
	const headers = new Headers({
		'Content-Type': 'application/x-www-form-urlencoded',
	});
	return fetch(API_URL + '/reset-password', {
		headers,
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		body: qs.stringify(payload),
	});
};

// Check auth
export const checkAuth = async () => {
	const response = await fetch(API_URL + '/isauth', {
		method: 'GET',
		credentials: 'include',
	});
	const data = await response.json();

	return { ok: response.ok, data };
};

export const resendVerificationEmail = async email => {
	const response = await fetch(
		API_URL + `/resend_confirmation_email?email=${encodeURIComponent(email)}`,
		{
			method: 'POST',
		}
	);

	return { ok: response.ok };
};
