/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers';
import { h400 } from 'src/components/Typography';
import { ReactComponent as PdfContractIconComponent } from 'src/assets/icons/pdf_contract_icon.svg';
import { ReactComponent as ExternalLinkIconComponent } from 'src/assets/icons/external_link_icon.svg';
import { Info } from '../../sections/common';
import { StrokeButton } from 'src/components/Buttons/StrokeButton';

export const TradeInfoWrapper = styled.div`
	display: block;
	padding: 25px 10px 25px 32px;
	margin-right: 4px;

	${media.xl`
  	padding-left: 40px;
  `}

	.ui.divider {
		margin: 8px 0 32px;

		${media.ll`
      margin: 16px 0 24px;
    `}
	}
`;

export const InfoItemColumn = styled.li`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	width: 120px;
	margin-right: 30px;

	${props =>
		props.$withLink &&
		css`
			width: 100%;

			${media.xl`
        width: 500px;
      `}
		`}
`;

export const Units = styled.span`
	text-transform: lowercase;
`;

export const UrlWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	svg {
		width: 16px;
		height: 16px;
	}

	a {
		color: var(--primary-200);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		${h400}
		font-size: 14px;
		line-height: 16px;

		${media.xl`
    font-size: 15px;
    line-height: 18px;
  `}
	}
`;

export const PdfContractIcon = styled(PdfContractIconComponent)`
	color: var(--neutral-300);
`;

export const ExternalLinkIcon = styled(ExternalLinkIconComponent)`
	color: var(--primary-200);
`;

export const TraderNameInfo = styled(Info)`
	display: flex;
	align-items: center;
	white-space: nowrap;
`;

export const RelativeWrapper = styled.div`
	position: relative;
`;

export const DownloadButton = styled(StrokeButton)`
	position: absolute;
	width: 123px;
	right: 14px;
	top: -10px;
`;
