/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import { tCurrency, tOrderType } from 'src/constants/contract';
import { formatPrice } from './price';
import { formatDateRange, parseDateStringAsUTC } from './date';

export const usePageTitle = pageTitleKey => {
	const { t } = useTranslation();
	const defaultPageTitle = pageTitleKey ? t(pageTitleKey) : document.title;

	// Set initial default title
	useEffect(() => {
		document.title = defaultPageTitle;
	}, [defaultPageTitle]);
};

const numberFormatter = Intl.NumberFormat(i18n.language);

const formatOrder = (t, order) => {
	const parts = [];

	parts.push(
		[
			order.product.name,
			formatPrice(order.price, {
				currency: tCurrency(t, order.currency),
			}),
			order.inco.name,
			order.primary_ports[0].name,
			`${numberFormatter.format(order.volume)}${t('mt')}`,
		]
			.filter(Boolean)
			.join(' ')
	);

	parts.push(
		formatDateRange({
			format: order.delivery_mode,
			startDate: parseDateStringAsUTC(order.delivery_date_from),
			endDate: parseDateStringAsUTC(order.delivery_date_to),
		})
	);

	return parts.join(', ');
};

export const useOrderPageTitle = order => {
	const { t } = useTranslation();

	const orderType = tOrderType(t, order.order_type);
	const company = order.company?.name;
	const formattedOrder = [company, formatOrder(t, order)].filter(Boolean).join(', ');

	usePageTitle(`${orderType} - ${formattedOrder}`);
};

export const useCounterPageTitle = (order, counters, userId) => {
	const { t } = useTranslation();

	const company = order.user_id === userId ? counters[0]?.company?.name : order.company?.name;
	const formattedOrder = [company, formatOrder(t, order)].filter(Boolean).join(', ');

	usePageTitle(`${t('negotiation')} - ${formattedOrder}`);
};

export const useTradePageTitle = (trade, userId) => {
	const { t } = useTranslation();

	const company =
		trade.buyer_user_id === userId ? trade.seller_company?.name : trade.buyer_company?.name;
	const formattedTrade = [company, formatOrder(t, trade)].filter(Boolean).join(', ');

	usePageTitle(`${t('trade')} - ${formattedTrade}`);
};
