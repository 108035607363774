/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';
import { rootSaga } from '../_sagas';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
	(typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		})) ||
	compose;

function initializeStore() {
	const store = createStore(reducer, {}, composeEnhancers(applyMiddleware(sagaMiddleware)));

	sagaMiddleware.run(rootSaga);

	return store;
}

const store = compose()(initializeStore);

export default store();
