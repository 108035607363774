/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { API_URL } from 'src/_api/model';
import { uploadOrderFiles } from 'src/_api/products.api';
import loaderImage from 'src/assets/icons/loader.png';

export const uploadNewsImage = (file, onImageUpload) => {
	return new Promise(async (resolve, reject) => {
		// Note: there's no direct access to the information box, that's why I had to use this dirty hack.
		const infoBox = document.querySelector('.rdw-image-modal-upload-option-label');
		const inputFile = document.querySelector('.rdw-image-modal-upload-option-input');

		const previousContent = infoBox.innerHTML;
		infoBox.innerHTML = `<img src="${loaderImage}" alt="" class="spinner" />`;

		try {
			const { id, metadata } = await uploadOrderFiles({ file });

			if (id && metadata && onImageUpload) {
				onImageUpload(p => [
					...p,
					{
						url: `${API_URL}/v1/download/${id}`,
						id,
						metadata,
					},
				]);

				resolve({
					data: { link: `${API_URL}/v1/download/${id}` },
				});
			}
		} catch (error) {
			infoBox.innerHTML = previousContent;
			inputFile.value = '';
			reject(error);
		}
	});
};
