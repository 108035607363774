/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const mapCounterparties = (t, contacts = [], groups = []) => {
	const mappedGroups = groups.map(group => ({
		id: group._key,
		value: group._key,
		text: group.name,
		search: group.name.toLowerCase(),
		users: group.users.map(user => user.contact_user_id),
		group: true,
	}));

	const mappedContacts = contacts.map(user => ({
		id: user.contact_user_id,
		value: user.contact_user_id,
		text: `${user.name} - ${user.company_name}`,
		search: `${user.name.toLowerCase()} ${user.company_name.toLowerCase()}`,
	}));

	let groupOptions = [];
	let listOptions = [];

	if (mappedGroups.length) {
		groupOptions = [
			{
				text: t('groups'),
				options: mappedGroups,
			},
			{
				text: t('approved_contacts_filter'),
				options: mappedContacts,
			},
		];
	} else {
		listOptions = [...mappedContacts];
	}

	return {
		listOptions,
		groupOptions,
	};
};
