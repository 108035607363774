/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	DropdownToggleItem,
	DropdownToggleTrigger,
	DropdownToggleChild,
} from 'src/components/Dropdown/Dropdown';
import { T150 } from 'src/components/Typography/fonts';
import { ReactComponent as StatusIcon } from 'src/assets/icons/status_icon.svg';

export const VisibilitySettingsDropdown = ({ isOnline, hideVisibility }) => {
	const { t } = useTranslation();

	return (
		<DropdownToggleItem
			trigger={({ isOpen, toggle }) => (
				<DropdownToggleTrigger
					isOpen={isOpen}
					toggle={toggle}
					data-test="visibility-settings-dropdown"
				>
					<StatusIcon />
					<T150>{t('status_user')}</T150>
				</DropdownToggleTrigger>
			)}
		>
			<DropdownToggleChild
				className="dropdown-child"
				text={t('user_active')}
				active={isOnline}
				onClick={() => hideVisibility(false)}
			/>
			<DropdownToggleChild
				className="dropdown-child"
				text={t('away')}
				active={!isOnline}
				onClick={() => hideVisibility(true)}
			/>
		</DropdownToggleItem>
	);
};
