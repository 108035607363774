/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const DocumentaryInstructions = 'Documentary instructions';

export const TradeDocumentType = {
	BillOfLading: 'bill_of_lading',
	ExchangeForPhysicalFutures: 'exchange_for_physical_futures',
	Invoice: 'invoice',
	VesselNomination: 'vessel_nomination',
	LetterOfCredit: 'letter_of_credit',
	CertificateOfOrigin: 'certificate_of_origin',
	LetterOfIntent: 'letter_of_intent',
	PhytosanitaryCertificate: 'phytosanitary_certificate',
	ProofOfInsurance: 'proof_of_insurance',
	CertificateOfWeight: 'certificate_of_weight',
	WarrantyOfTitle: 'warranty_of_title',
	BankGuarantee: 'bank_guarantee',
	CertificateOfFumigation: 'certificate_of_fumigation',
	WarehouseReceipt: 'warehouse_receipt',
	CertificateOfQuality: 'certificate_of_quality',
	ShippingAdvice: 'shipping_advice',
	OrganicCertificate: 'organic_certificate',
	StatementOfFacts: 'statement_of_facts',
	Contract: 'contract',
	CharterParty: 'charter_party',
	OtherDocument: 'other_document',
	HoldInspectionCertificate: 'hold_inspection_certificate',
};

export const RestrictedDocumentTypes = [TradeDocumentType.Invoice, TradeDocumentType.Contract];

export function tTradeDocumentType(t, type) {
	switch (type) {
		case TradeDocumentType.BillOfLading:
			return t('bill_of_lading');
		case TradeDocumentType.ExchangeForPhysicalFutures:
			return t('exchange_for_physical_futures');
		case TradeDocumentType.CharterParty:
			return t('charter_party');
		case TradeDocumentType.StatementOfFacts:
			return t('statement_of_facts');
		case TradeDocumentType.HoldInspectionCertificate:
			return t('hold_inspection_certificate');
		case TradeDocumentType.Contract:
			return t('contract_document_type');
		case TradeDocumentType.VesselNomination:
			return t('vessel_nomination_document');
		case TradeDocumentType.Invoice:
			return t('invoice');
		case TradeDocumentType.CertificateOfOrigin:
			return t('certificate_of_origin');
		case TradeDocumentType.LetterOfCredit:
			return t('letter_of_credit');
		case TradeDocumentType.PhytosanitaryCertificate:
			return t('phytosanitary_certificate');
		case TradeDocumentType.LetterOfIntent:
			return t('letter_of_intent');
		case TradeDocumentType.CertificateOfWeight:
			return t('certificate_of_weight');
		case TradeDocumentType.ProofOfInsurance:
			return t('proof_of_insurance');
		case TradeDocumentType.WarrantyOfTitle:
			return t('warranty_of_title');
		case TradeDocumentType.CertificateOfFumigation:
			return t('certificate_of_fumigation');
		case TradeDocumentType.BankGuarantee:
			return t('bank_guarantee');
		case TradeDocumentType.CertificateOfQuality:
			return t('certificate_of_quality');
		case TradeDocumentType.WarehouseReceipt:
			return t('warehouse_receipt');
		case TradeDocumentType.OrganicCertificate:
			return t('organic_certificate');
		case TradeDocumentType.ShippingAdvice:
			return t('shipping_advice_document');
		case TradeDocumentType.StandByLetterOfCredit:
			return t('stand_by_letter_of_credit');
		case TradeDocumentType.OtherDocument:
			return t('other_document');
		default:
			return '';
	}
}

export function tTradeDocumentTypeOrNotes(t, type, notes) {
	if (type === TradeDocumentType.OtherDocument) {
		return notes;
	}

	return tTradeDocumentType(t, type);
}
