/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as Logo } from 'src/assets/images/logo-dark.svg';
import { ReactComponent as GooglePlayButton } from 'src/assets/images/mobile-app-google-play.svg';
import { ReactComponent as AppStoreButton } from 'src/assets/images/mobile-app-appstore.svg';
import * as Styled from './styled';
import { VosborMobileAppStoreLink, VosborMobileGooglePlayLink } from 'src/constants/mobile';
import { useMobileBrowser } from 'src/_helpers/useMobileBrowser';

export const MobileDownloadPage = ({ children }) => {
	const { t } = useTranslation();

	const { isAndroid } = useMobileBrowser();

	return (
		<Styled.Wrapper>
			<Styled.Header>
				<Logo />
				<Styled.ContactLink
					href="https://www.vosbor.com/contact/"
					target="_blank"
					rel="noreferrer"
				>
					{t('contact')}
				</Styled.ContactLink>
			</Styled.Header>

			<Styled.Content>
				<Styled.ContentHeading>
					<Styled.GetTheAppHeading>{t('mobile_app_get')}</Styled.GetTheAppHeading>
					<Styled.ContentSubHeading>
						{t('mobile_app_get_description')}
					</Styled.ContentSubHeading>
				</Styled.ContentHeading>
				<Styled.ImageWrapper>
					<Styled.Image />
				</Styled.ImageWrapper>
			</Styled.Content>

			<Styled.Footer>
				<Styled.FooterButtons>
					<a href={VosborMobileGooglePlayLink}>
						<GooglePlayButton />
					</a>
					<a href={VosborMobileAppStoreLink}>
						<AppStoreButton />
					</a>
				</Styled.FooterButtons>

				{isAndroid && (
					<Styled.FooterText>
						<Trans
							i18nKey={'mobile_app_footer'}
							components={{
								span: (
									<APKLink
										href="mobile/vosbor_mobile.apk"
										target="_blank"
										rel="noreferrer"
									/>
								),
							}}
						/>
					</Styled.FooterText>
				)}
				{children}
			</Styled.Footer>
		</Styled.Wrapper>
	);
};

const APKLink = ({ children, ...props }) => <a {...props}>{children}</a>;
