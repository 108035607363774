/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { OrderCreatorShipmentSection } from 'src/components/ViewOrderDrawer/Content/OrderCreatorShipmentSection/OrderCreatorShipmentSection';
import { PaperKeyDetailsSection } from 'src/components/ViewOrderDrawer/Content/KeyDetailsSection/PaperKeyDetailsSection';
import { ContractAndPricingSection } from 'src/components/ViewOrderDrawer/Content/Paper/ContractAndPricingSection/ContractAndPricingSection';
import { SpecificationsSection } from 'src/components/ViewOrderDrawer/Content/SpecificationsSection/SpecificationsSection';
import { CounterpartiesSection } from 'src/components/ViewOrderDrawer/Content/CounterpartiesSection/CounterpartiesSection';
import { PaperInstruments } from 'src/constants/contract';
import * as Styled from 'src/components/ViewOrderDrawer/styled';

export const PaperOrderPreviewContent = () => {
	const {
		[DrawerContextKeys.createDrawer]: { orderPreviewData, orderPreviewIndex },
	} = useDrawerContext();

	const order = orderPreviewData[orderPreviewIndex];

	return (
		<>
			<Styled.TopContent>
				<PaperKeyDetailsSection order={order} />
			</Styled.TopContent>
			<Styled.ScrollableContent>
				<OrderCreatorShipmentSection order={order} skipShipmentType />
				<ContractAndPricingSection order={order} />
				{!!order.recipients_list?.length && (
					<>
						<Styled.Divider />
						<CounterpartiesSection order={order} showSeenBy={false} />
					</>
				)}
				<Styled.Divider />
				{order.instrument !== PaperInstruments.Spread && (
					<SpecificationsSection order={order} />
				)}
			</Styled.ScrollableContent>
		</>
	);
};
