/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const getInstrumentAndPriceTypes = preset => {
	return (
		preset?.instruments?.reduce((acc, { price_type, futures_contract, type }) => {
			if (!acc[type]) {
				acc[type] = [];
			}

			if (futures_contract && !acc[type].includes(futures_contract)) {
				acc[type].push(futures_contract);
			} else if (!acc[type].includes(price_type)) {
				acc[type].push(price_type);
			}

			return acc;
		}, {}) || {}
	);
};
