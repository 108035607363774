/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useTranslation } from 'react-i18next';
import { processTrade } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useMutation, useQueryClient } from 'react-query';

export const useCompleteStepMutation = ({ tradeId, onSuccess }) => {
	const { addToast } = useToast();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	return useMutation(processTrade, {
		onSuccess: async () => {
			await queryClient.refetchQueries(['trade', tradeId]);
			queryClient.refetchQueries(['activity_logs', tradeId]);
			onSuccess?.();
		},
		onError: () => {
			addToast({
				message: t('something_went_wrong'),
				kind: 'error',
			});
		},
	});
};
