/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import * as Styled from './styled';

export const defaultLimitOptions = [20, 50, 100];

export const RowsPerPage = ({ limit, setLimit, limitOptions = defaultLimitOptions }) => {
	const { t } = useTranslation();
	const wrapperRef = useRef();
	const [isMenuOpen, setMenuOpen] = useState(false);

	useLayoutEffect(() => {
		if (!isMenuOpen) {
			return;
		}

		const menu = wrapperRef.current.querySelector('.dropdown-menu');
		const menuOption = wrapperRef.current.querySelectorAll('.pagination-option')[0];
		const parentNodeRect = menu.parentNode.getBoundingClientRect();

		const dropdownTriggerTopMargin = 10;
		const dropdownOptionsLeftOffset = 17;

		menu.style.position = 'fixed';
		menu.style.top = `${
			parentNodeRect.top -
			dropdownTriggerTopMargin -
			menuOption.clientHeight * limitOptions.length
		}px`;
		menu.style.bottom = 'unset';
		menu.style.left = `${parentNodeRect.left - dropdownOptionsLeftOffset}px`;
	}, [isMenuOpen, limitOptions]);

	return (
		<div ref={wrapperRef}>
			<Styled.RowsPerPageText data-test="rows-per-page-text">
				{t('rows_per_page')}:
			</Styled.RowsPerPageText>
			<Styled.StyledDropdown
				text={limit?.toString()}
				upward
				data-test="rows-per-page-dropdown"
				onOpen={() => setMenuOpen(true)}
				onClose={() => setMenuOpen(false)}
			>
				<Dropdown.Menu className="dropdown-menu">
					{limitOptions.map(value => (
						<Dropdown.Item
							key={value}
							text={value}
							onClick={() => setLimit(value)}
							className="pagination-option"
						/>
					))}
				</Dropdown.Menu>
			</Styled.StyledDropdown>
		</div>
	);
};
