/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserHomepage } from 'src/_store/selectors';
import { Permission } from 'src/containers/Permissions/Permission';

export const PermissionBoundary = ({ permission, children }) => {
	const userHomepage = useSelector(getUserHomepage);

	return (
		<Permission
			name={permission}
			renderOn={() => children}
			renderOff={() => <Navigate to={userHomepage} />}
		/>
	);
};
