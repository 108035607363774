/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import store from './_store';
import * as serviceWorker from './serviceWorker';
import './semantic-ui-less/semantic.less';
import './styles/styles';
import { App } from './App';
import i18n from './i18n';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ToastProvider } from './components/Toast';
import { StyleSheetManager } from 'styled-components';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import {
	BrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import { ReduxProvider } from './_store/ReduxProvider';
import { FeatureFlagsProvider } from 'src/featureFlags/FeatureFlagsContext';
import { CookieConsent } from 'src/components/CookieConsent/CookieConsent';
import { BrowserVersionCheck } from './components/BrowserVersionCheck/BrowserVersionCheck';
import { GitVersionContextProvider } from './_helpers/useGitVersion';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
	},
});

// separate production and non-production environments based on hostname, as all our builds are production builds so we can't rely on NODE_ENV
const environment =
	window.location.hostname.includes('vosbor.com') ||
	window.location.hostname.includes('vosbor.cn')
		? 'production'
		: 'non-production';

Sentry.init({
	dsn: 'https://58ed8cd7f2af4dbdbdb75fb684d2e6fc@o950211.ingest.sentry.io/5898902',
	release: `vosbor-react@${process.env.REACT_APP_GIT_COMMIT}`, // allows to track crash free sessions per release
	initialScope: {
		user: { id: 'UNKNOWN_USER' },
	},
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
		new Sentry.Replay({
			workerUrl: '/js/sentry.worker.min.js',
		}),
	],
	replaysSessionSampleRate: 0, // don't record crash free sessions
	replaysOnErrorSampleRate: 0.4, // Capture (as a replay) 20% of sessions with an error
	// all our builds except local development are production builds, so we want to set this always to 0.2
	tracesSampleRate: 0.2,
	environment,
	enabled: process.env.NODE_ENV === 'production', // no localhost issues are tracked
	beforeBreadcrumb(breadcrumb) {
		return breadcrumb.category === 'ui.click' ? null : breadcrumb;
	},
});

Sentry.setTag('language', navigator.language || navigator.userLanguage); // not the most accurate method but it's good enough at least for now
Sentry.setTag('environment-host', window.location.hostname);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<StyleSheetManager>
		<BrowserRouter>
			<ToastProvider>
				<QueryClientProvider client={queryClient}>
					{process.env.REACT_APP_ENABLE_RQ_DEVTOOLS === 'true' && (
						<ReactQueryDevtools initialIsOpen={false} />
					)}
					<I18nextProvider i18n={i18n}>
						<ReduxProvider store={store}>
							<FeatureFlagsProvider>
								<GitVersionContextProvider>
									<BrowserVersionCheck />
									<CookieConsent />
									<App />
								</GitVersionContextProvider>
							</FeatureFlagsProvider>
						</ReduxProvider>
					</I18nextProvider>
				</QueryClientProvider>
			</ToastProvider>
		</BrowserRouter>
	</StyleSheetManager>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
