/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState, useCallback } from 'react';

export const useConsecutiveModals = (numberOfModals = 1) => {
	const initialModalsVisibility = Array(numberOfModals).fill(false);
	const [modalsVisibility, setModalsVisibility] = useState(initialModalsVisibility);
	const [modalsData, setModalsData] = useState(Array(numberOfModals).fill({}));

	const isModalOpen = useCallback(index => modalsVisibility[index] === true, [modalsVisibility]);

	const storeModalsData = (index = 0, data = {}) => {
		if (!data || Object.keys(data).length === 0 || index < 0 || index >= numberOfModals) {
			return;
		}

		const newModalsData = modalsData.map((v, i) => {
			if (i === index) {
				return data;
			}

			return v;
		});

		setModalsData(newModalsData);
	};

	const getModalsData = index => modalsData[index] || {};

	const openModal = useCallback(
		(index = 0) => {
			const currentModalVisibility = modalsVisibility[index];

			if (currentModalVisibility || index < 0 || index >= numberOfModals) {
				return;
			}

			const newModalsVisibility = modalsVisibility.map((_v, i) => {
				if (i === index) {
					return true;
				}

				return false;
			});

			setModalsVisibility(newModalsVisibility);
		},
		[modalsVisibility, numberOfModals]
	);

	const closeModal = useCallback(
		(index = 0, modalIndexToOpen = null) => {
			const currentModalVisibility = modalsVisibility[index];

			if (!currentModalVisibility || index < 0 || index >= numberOfModals) {
				return;
			}

			const newModalsVisibility = modalsVisibility.map((_v, i) => {
				if (modalIndexToOpen !== null) {
					if (modalIndexToOpen === i) {
						return true;
					}

					return false;
				}

				if (i === index - 1) {
					return true;
				}

				return false;
			});

			setModalsVisibility(newModalsVisibility);
		},
		[modalsVisibility, numberOfModals]
	);

	const closeAllModals = useCallback(() => setModalsVisibility(initialModalsVisibility), [
		initialModalsVisibility,
	]);

	return {
		openModal,
		closeModal,
		closeAllModals,
		isModalOpen,
		getModalsData,
		storeModalsData,
	};
};
