/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/constants/routes';
import { isAndroidClient, isIOSClient } from 'src/_helpers/useMobileBrowser';
import { MobileDownloadPage } from 'src/components/MobileDownloadPage/MobileDownloadPage';
import * as Styled from './styled';
import { MobilePlatform } from 'src/constants/mobile';

export const DeleteAccountMobile = () => {
	const [isMobileDetected, setMobileDetected] = useState(false);
	const [clientPlatform, setClientPlatform] = useState(null);

	const { t } = useTranslation();

	const navigate = useNavigate();

	useEffect(() => {
		const userAgent = window.navigator.userAgent;

		if (isAndroidClient(userAgent)) {
			setClientPlatform(MobilePlatform.Android);
		}

		if (isIOSClient(userAgent)) {
			setClientPlatform(MobilePlatform.IOS);
		}

		setMobileDetected(true);
	}, []);

	useEffect(() => {
		if (isMobileDetected && !clientPlatform) {
			navigate(ROUTES.root);
		}
	}, [clientPlatform, isMobileDetected, navigate]);

	if (!isMobileDetected) {
		return false;
	}

	if (clientPlatform) {
		return (
			<Styled.FullscreenWrapper>
				<MobileDownloadPage>
					<Styled.MobileInfoWrapper>
						{t('delete_account_use_mobile')}
					</Styled.MobileInfoWrapper>
				</MobileDownloadPage>
			</Styled.FullscreenWrapper>
		);
	}

	return null;
};
