/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const CounterStatus = {
	Active: 'ACTIVE',
	AcceptedNoCounter: 'ACCEPTED_NO_COUNTER',
	Accepted: 'ACCEPTED', // currently not supported (previous implementation allowed to accept counters)
	Confirmed: 'CONFIRMED',
	Expired: 'EXPIRED',
	Terminated: 'TERMINATED',
	NotAvailable: 'NOT_AVAILABLE',
};

export function tCounterStatus(t, status, is_traded = false, validity = null) {
	if (is_traded) {
		return t('traded');
	}
	if (validityStatusCheck(validity, status)) {
		return t('expired');
	}

	switch (status) {
		case CounterStatus.Active:
			return t('status_active');
		case CounterStatus.AcceptedNoCounter:
		case CounterStatus.Accepted:
			return t('accepted');
		case CounterStatus.Confirmed:
			return t('traded');
		case CounterStatus.Expired:
			return t('expired');
		case CounterStatus.Terminated:
			return t('withdrawn');
		case CounterStatus.NotAvailable:
			return t('not_available');
		default:
			return '';
	}
}

const EXPIRABLE_STATUSES = [
	CounterStatus.Active,
	CounterStatus.Accepted,
	CounterStatus.AcceptedNoCounter,
];

export function validityStatusCheck(validity, status) {
	const isValidityExpired = new Date(validity).getTime() < Date.now();
	if (!EXPIRABLE_STATUSES.includes(status) || !isValidityExpired) {
		return false;
	}

	return isValidityExpired;
}

export function tableStatusRowStyle(status, type, validity) {
	switch (status) {
		case CounterStatus.Expired:
			return {
				color: 'var(--neutral-300)',
			};
		case CounterStatus.Active:
			if (type === 'read') {
				return {
					color: 'var(--primary-400)',
				};
			}
			return {
				background: 'var(--call-to-action-gradient)',
				color: '#FFFFFF',
			};
		case CounterStatus.AcceptedNoCounter:
			if (validityStatusCheck(validity, status)) {
				return '';
			}

			if (type === 'read') {
				return {
					color: 'var(--yellow-400)',
				};
			}
			return {
				background: 'var(--yellow-400)',
				color: '#FFFFFF',
			};
		case CounterStatus.Accepted:
			if (type === 'read') {
				return {
					color: 'var(--yellow-400)',
				};
			}
			return {
				background: 'var(--yellow-400)',
				color: '#FFFFFF',
			};
		case CounterStatus.Confirmed:
			return {
				background: 'var(--green-400)',
				color: 'var(--neutral-1000)',
			};
		default:
			return '';
	}
}

export const AfterSubmissionModalType = {
	CommentsOnly: 'comments_only',
	NoChanges: 'no_changes',
	Summary: 'summary',
};
