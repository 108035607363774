/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled from 'styled-components';
import { media } from 'src/_helpers';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { Children } from 'react';

export const ActionButtons = styled.div.attrs(({ children }) => ({
	'data-test': 'action-buttons',
	childrenCount: Children.count(children),
}))`
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	max-width: 304px;
	width: 304px;

	${media.ll`
		max-width: 341px;
		width: 341px;
	`}

	${media.xl`
		max-width: 396px;
		width: 396px;
	`}

	& > * {
		${props => (props.childrenCount === 3 ? 'flex: 1;' : '')}
		max-width: 33%;
	}
`;

export const CounterButton = styled(SecondaryButton)`
	&.darker.disabled {
		background-color: var(--neutral-700);
	}
`;

export const CopyButton = styled(SecondaryButton).attrs({ darker: true })``;

export const AcceptButton = styled(PrimaryButton)`
	&.disabled {
		background-color: var(--neutral-700);
	}
`;
