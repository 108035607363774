/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ActivityLogItem } from './ActivityLogItem/ActivityLogItem';
import * as Styled from './styled';
import {
	DownloadButton,
	DownloadDropdown,
	DownloadEllipsisIcon,
} from '../DownloadTradesButton/styled';
import { useMutation } from 'react-query';
import { sendTradeActivityLogViaEmail, downloadTradeActivityLog } from 'src/_api';
import { useToast } from '../Toast';
import { useTradeLocation } from 'src/_helpers/useTradeLocation';
import { ignoredActivityTypes } from 'src/constants/activityLogTypes';

export const ActivityLog = ({ activityLogItems = [], isFetched, enableDownloadButton = false }) => {
	const { t } = useTranslation();
	const { addToast } = useToast();
	const { tradeId } = useTradeLocation();

	const { mutate: sendTradeActivityLogMutation } = useMutation(sendTradeActivityLogViaEmail, {
		onSuccess: () => {
			addToast({
				message: t('csv_file_sent_to_email_address'),
				kind: 'info',
			});
		},
		onError: () => {
			addToast({
				message: t('something_went_wrong'),
				kind: 'error',
			});
		},
	});

	const shouldRenderDownloadDropdown = enableDownloadButton && isFetched;

	return (
		<>
			<Styled.ActivityLogHeader>
				<Styled.ActivityLogTitle>{t('activity_log')}</Styled.ActivityLogTitle>
				{shouldRenderDownloadDropdown && (
					<DownloadDropdown
						direction="left"
						icon={null}
						trigger={
							<DownloadButton data-test="download-al-dropdown">
								{t('download')}
								<div>
									<DownloadEllipsisIcon />
								</div>
							</DownloadButton>
						}
					>
						<Dropdown.Menu>
							<Dropdown.Item
								text={t('download_csv')}
								onClick={() => downloadTradeActivityLog({ tradeId })}
							/>
							<Dropdown.Item
								text={t('send_via_email')}
								onClick={() =>
									sendTradeActivityLogMutation({
										tradeId,
									})
								}
							/>
						</Dropdown.Menu>
					</DownloadDropdown>
				)}
			</Styled.ActivityLogHeader>
			<Styled.LogsWrapper>
				{isFetched &&
					activityLogItems.map((item, index) => {
						if (ignoredActivityTypes.includes(item.type)) {
							return null;
						}

						return (
							<ActivityLogItem
								key={`activity-log-item-${item.key}`}
								item={item}
								users={item.users}
								index={index}
							/>
						);
					})}
			</Styled.LogsWrapper>
		</>
	);
};
