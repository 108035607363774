/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';
import { ReactComponent as Ship } from 'src/assets/icons/ship.svg';
import { Pill } from 'src/components/Pill/Pill';
import { H600 } from 'src/components/Typography';

export const ShipIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 34px;
	height: 34px;
	min-width: 34px;
	min-height: 34px;
	border-radius: 50%;
	background: var(--neutral-800);

	${media.xl`
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
	`}
`;

export const IncoPortShipmentContainer = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;
	max-width: 190px;

	${media.ll`
		max-width: 216px;
	`}

	${media.xl`
		max-width: 253px;
	`}

	&.changed {
		position: relative;
		z-index: 1;
		width: 100%;

		&:before {
			content: '';
			background: var(--counter-highlight-color);
			inset: -4px -8px;
			border-radius: 32px;
			position: absolute;
			z-index: -1;
		}

		${ShipIconContainer} {
			background: rgba(255, 255, 255, 0.1);

			svg {
				path {
					fill: var(--white);
				}
			}
		}
	}
`;

export const ShipIcon = styled(Ship)`
	width: 20px;
	height: 20px;
	path {
		fill: var(--neutral-300);
	}

	${media.xl`
		width: 24px;
		height: 24px;
	`}
`;

export const OrderInfo = styled.div`
	display: grid;
	gap: 1px;
	max-width: 144px;
	width: 100%;

	${media.ll`
		max-width: 170px;
		padding-top: 2px;
	`}

	${media.xl`
		max-width: 201px;
	`}
`;

export const TextContainer = styled.div`
	display: grid;
`;

export const PillsContainer = styled.div`
	${({ $hasOrigins }) =>
		$hasOrigins &&
		`
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(0px, max-content));
		gap: 4px;
	`}
`;

export const BoxPill = styled(Pill)`
	i.flag {
		margin-right: 3px;

		${media.xl`
			margin-right: 1px;
		`}
	}

	&.changed {
		background-color: var(--primary-800);
	}
`;

export const ProductName = styled(H600)`
	color: var(--neutral-300);
`;

export const InfoContainer = styled.div`
	position: relative;

	& > * {
		position: relative;
		z-index: 1;
	}

	&.changed {
		&:before {
			content: '';
			position: absolute;
			border-radius: 16px;
			background-color: var(--primary-800);
			inset: -6px 0px -3px -6px;
		}
	}
`;
