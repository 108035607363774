/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useLocation } from 'react-router-dom';
import React from 'react';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { usePageTitle } from 'src/_helpers/usePageTitle';

const Container = styled.div`
	max-width: 600px;
	margin: 16px auto;
`;

export const ErrorPage = () => {
	let location = useLocation();

	usePageTitle('title_error');

	return (
		<Container>
			<Message
				negative
				icon="warning circle"
				header="Error!"
				content={location.state?.errorText}
			/>
		</Container>
	);
};
