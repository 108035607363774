/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const changeablePhysicalCounterFields = [
	'price_type',
	'futures_contract',
	'futures_contract_date',
	'price',
	'currency',
	'price_unit',
	'payment',
	'is_indicative',
	'price_increment_type',
	'price_increment_basis_shipment_from',
	'price_increment_basis_shipment_to',
	'price_increment_basis_shipment_mode',
	'price_increment',
	'volume',
	'inco_id',
	'primary_ports.name',
	'primary_ports.volume',
	'primary_ports.terminal',
	'primary_ports.rate',
	'ports_loading.name',
	'ports_loading.volume',
	'ports_loading.terminal',
	'ports_loading.rate',
	'ports_discharging.name',
	'ports_discharging.volume',
	'ports_discharging.terminal',
	'ports_discharging.rate',
	'tolerance',
	'shipment_type',
	'inspection',
	'performance_bond_percent',
	'terms_id',
	'contract_number',
	'delivery_date_from',
	'delivery_date_to',
	'delivery_mode',
	'harvest_year',
	'speciality',
	'file_contract',
	'order_owner_principal_id',
];

export const changeablePaperCounterFields = [
	'volume',
	'runs',
	'price',
	'is_indicative',
	'firstLegPrice',
	'order_owner_principal_id',
	'principalVisibility',
	'spreadType',
];
