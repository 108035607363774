/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { InfoTitle } from '../../../sections/common';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
`;

export const StyledInfoTitle = styled(InfoTitle)`
	margin-bottom: 0px;
`;
