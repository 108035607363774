/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const KYCTypes = {
	INBOUND: 'inbound',
	OUTBOUND: 'outbound',
};

export function tKYCTypes(t, value) {
	switch (value) {
		case KYCTypes.INBOUND:
			// received
			return t('inbound');
		case KYCTypes.OUTBOUND:
			// sent
			return t('outbound');
		default:
			return '';
	}
}

export const KYCTabs = {
	INBOUND: KYCTypes.INBOUND,
	OUTBOUND: KYCTypes.OUTBOUND,
	ARCHIVED: 'archived',
};

export function tKYCCompany(t, type) {
	switch (type) {
		case KYCTypes.INBOUND:
			return t('kyc_company_received');
		case KYCTypes.OUTBOUND:
			return t('kyc_company_sent');
		default:
			return '';
	}
}

export const KYCStatus = {
	PENDING: 'pending',
	HIDDEN: 'hidden',
	ACCEPTED: 'accepted',
	CLOSED: 'closed',
};

export const KYCactiveTabType = {
	[KYCTabs.INBOUND]: {
		type: 'inbound',
	},
	[KYCTabs.OUTBOUND]: {
		type: 'outbound',
	},
	[KYCTabs.ARCHIVED]: {
		status: 'hidden',
	},
};
