/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const sortOrder = {
	asc: 'ASC',
	desc: 'DESC',
};
const semanticUISortOrder = {
	asc: 'ascending',
	desc: 'descending',
};

export function toSemanticSort(order) {
	return order === sortOrder.asc ? semanticUISortOrder.asc : semanticUISortOrder.desc;
}
