/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { visit } from 'unist-util-visit';

const properties = {
	frameBorder: '0',
	webkitallowfullscreen: '',
	mozallowfullscreen: '',
	allowFullScreen: true,
	style: { width: '100%', height: '100%', minHeight: '300px' },
};

function reElement(node, href, index) {
	const url = new URL(href);
	const filename = url.pathname
		.split('/')
		.pop()
		?.replace(/(\?|!|#|$).+/, '');

	node.properties = {
		...properties,
		src: 'https://www.loom.com/embed/' + filename + url.search,
		title: `Video - ${index}`,
	};
	node.tagName = 'iframe';
	node.children = [];
}

// Rehype plugin
// Inspired in rehype-video
// embedding only videos from loom.com
export const rehypeLoomVideo = options => {
	return tree => {
		let videoIndex = 0;
		visit(tree, 'element', (node, index, parent) => {
			const delimiter = /(?:https?:\/\/)(?:www\.)?loom\.com\/([a-z0-9.,_/~#&=;%+?\-\\(\\)]*)/g;
			const child = node.children[0];
			if (node.tagName === 'p' && node.children.length === 1) {
				if (child.type === 'text' && delimiter.test(child.value)) {
					reElement(node, child.value, videoIndex);
					videoIndex++;
				}
				if (
					child.type === 'element' &&
					child.tagName === 'a' &&
					child.properties &&
					typeof child.properties.href === 'string' &&
					delimiter.test(child.properties.href)
				) {
					reElement(node, child.properties.href, videoIndex);
					videoIndex++;
				}
			}
		});
	};
};
