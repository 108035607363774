/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaperTradeStep } from 'src/constants/tradeStatus';
import * as Cell from '../../components/paper/StringsTable/StringCells';
import * as Styled from '../../components/paper/StringsTable/styled';

export const useStringColumns = ({ tradeId, contacts, tradeStep, circleTrade }) => {
	const { t } = useTranslation();

	const settlementColumn = {
		name: 'string-settlement',
		testId: 'string-settlement',
		label: <Styled.SettlementCellLabel>{t('settlement')}</Styled.SettlementCellLabel>,
		Cell: Cell.SettlementCell,
		width: 120,
		disableSort: true,
	};

	const myDocumentByPassColumn = {
		name: 'string-my-document-bypass',
		testId: 'string-my-document-bypass',
		label: (
			<Styled.PaddedLabel>
				{circleTrade ? t('agree_to_circle') : t('document_bypass')}
			</Styled.PaddedLabel>
		),
		Cell: props => <Cell.MyDocumentByPassCell {...props} tradeId={tradeId} />,
		width: {
			default: 100,
			ll: 180,
			xl: 250,
		},
		disableSort: true,
	};

	const documentByPassColumn = {
		name: 'string-document-bypass',
		testId: 'string-document-bypass',
		label: (
			<Styled.PaddedLabel>
				{circleTrade ? t('agree_to_circle') : t('document_bypass')}
			</Styled.PaddedLabel>
		),
		Cell: props => <Cell.DocumentByPassCell {...props} tradeId={tradeId} />,
		width: {
			default: 100,
			ll: 180,
			xl: 250,
		},
		disableSort: true,
	};

	const columnsByStep = {
		[PaperTradeStep.PaperNoString]: [settlementColumn],
		[PaperTradeStep.PaperInitialString]: [settlementColumn, myDocumentByPassColumn],
	};

	const defaultColumns = [settlementColumn, documentByPassColumn];

	const lastColumnWidth = {
		[PaperTradeStep.PaperNoString]: '30%',
		default: 180,
	};

	return [
		{
			name: 'string-company',
			testId: 'string-company',
			label: t('company'),
			Cell: Cell.CompanyCell,
			disableSort: true,
		},
		...(columnsByStep[tradeStep] || defaultColumns),
		{
			name: 'string-message',
			testId: 'string-message',
			label: '',
			Cell: ({ ...props }) => <Cell.BaseMessageCell {...props} contacts={contacts} />,
			disableSort: true,
			width: lastColumnWidth[tradeStep] || lastColumnWidth.default,
		},
	];
};
