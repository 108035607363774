/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { Radio as RadioComponent } from 'semantic-ui-react';
import { t100 } from 'src/components/Typography';

export const Radio = styled(RadioComponent)`
	.ui.radio& {
		${t100}
		color: var(--neutral-300);
		line-height: 20px;
	}

	.ui.radio& label {
		color: var(--neutral-300);
		padding-left: 28px;
	}

	.ui.radio& label:before {
		width: 20px;
		height: 20px;
		background-color: transparent;
		border: 1px solid var(--neutral-400);
	}

	.ui.radio.checkbox& input:checked ~ .box:before,
	.ui.radio.checkbox& input:checked ~ label:before {
		background: var(--selectors-selected-gradient);
		border-color: transparent;
	}

	.ui.radio& label:after {
		width: 20px;
		height: 20px;
		background: var(--white);
	}
`;
