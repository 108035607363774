/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { MaterialDateTimeInput } from 'src/designSystem/Form/MaterialInput/MaterialDateTimeInput';

export const Form = styled.form`
	grid-row: 2 / 4;
	display: grid;
	position: relative;
	overflow: hidden;
	height: 100%;
	background: var(--neutral-900);

	--_footerHeight: 70px;

	${media.ll`
		--_footerHeight: 80px;
	`}

	${media.xl`
		--_footerHeight: 92px;
	`}

	grid-template-rows: 1fr var(--_footerHeight);

	&:after {
		content: '';
		position: absolute;
		top: calc(100% - 40px - var(--_footerHeight));
		left: 0;
		width: 100%;
		height: 40px;
		background: linear-gradient(180deg, #0e132a 0%, rgba(14, 19, 42, 0) 100%);
		transform: rotate(180deg);
		z-index: 1;
	}
`;

const button = css`
	width: ${props => (props.$isEditing ? '130px' : '160px')};

	${media.xl`
		width: 190px;
	`}
`;

export const CancelButton = styled(SecondaryButton)`
	${button}
`;

export const PreviewButton = styled(PrimaryButton)`
	${button}
`;

export const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;

	.text-input:has(+ &) {
		width: 160px;

		${media.xl`
			width: 190px;
		`}
	}
`;

export const FormContent = styled.div`
	overflow: hidden auto;
	margin-right: 4px;
	padding: 9px 0 40px 16px;

	${media.ll`
		padding: 13px 15px 40px 20px;
	`}

	${media.xl`
		padding: 16px 20px 40px 24px;
	`}

		&.create-paper-order-form {
		padding-top: 9px;

		${media.ll`
			padding-top: 15px;
			padding-left: 22px;
		`}

		${media.xl`
			padding-top: 16px;
			padding-left: 24px;
		`}
	}

	&.preview {
		padding: 0 !important;
	}

	.options-container {
		max-width: 100%;
	}
`;

export const ValidityInput = styled(MaterialDateTimeInput)`
	width: 140px;

	${media.ll`
		width: 156px;
	`}

	${media.xl`
		width: 186px;
	`}
`;
