/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { Link } from 'src/components/Link/Link';
import { media } from 'src/_helpers/media-queries';
import { h1000, m50, h800 } from 'src/components/Typography';

export const TradeHeader = styled.div`
	display: flex;
	align-items: center;
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 23px;

	${media.ll`
    padding-bottom: 35px;
  `}

	${media.xl`
    padding-left: 40px;
    padding-right: 48px;
    padding-bottom: 28px;
  `}
`;

export const OrderInfo = styled.div`
	flex: 1;

	${({ $notSellerOrBuyer }) =>
		$notSellerOrBuyer &&
		`
    align-self: start;
  `}

	h1 {
		${h1000}

		${media.xl`
			font-size: 30px;
			line-height: 35px;
		`}

		${({ $notSellerOrBuyer }) =>
			$notSellerOrBuyer &&
			`
			margin-top: 8px;
		`}
	}
`;

export const OrderLink = styled(Link)`
	display: inline-flex;
	align-items: center;
	gap: 8px;
	padding: 7px 13px;
	margin-bottom: 14px;
	background-color: var(--neutral-850);
	border-radius: 16px;
	color: var(--neutral-300);
	${m50}

	svg {
		width: 16px;
		height: 16px;
	}

	&:hover {
		color: #fff;
		background: var(--neutral-700);
	}
`;

export const Actions = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
`;

export const UsersWrapper = styled.div`
	position: relative;
	margin-bottom: 20px;
	height: 42px;

	${media.ll`
    margin-bottom: 32px;
  `}
`;

export const TradeTitle = styled.h3`
	${h800}
	font-size: 18px;

	${media.ll`
		font-size: 20px;
	`}

	${media.xl`
		${h800}
	`}
`;
