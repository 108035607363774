/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ModalSubTitle } from 'src/components/Modal';
import { h400, h500, h600 } from '../Typography';
import { PrimaryButton } from '../Buttons/PrimaryButton';

export const Subtitle = styled(ModalSubTitle)`
	color: var(--text-color-default);
`;

export const ModalContent = styled.div`
	border-top: 1px solid var(--neutral-500);
	margin-top: 19px;
	margin-bottom: 42px;
	padding-top: 24px;
`;

export const Terms = styled.div`
	padding: 12px;
	background: var(--neutral-600);
`;

export const TextWrapper = styled.div`
	overflow: auto;
	height: 427px;
`;

export const LoaderWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const BottomCheck = styled.div`
	margin-bottom: 10px;
	height: 1px;
`;

export const DownloadText = styled.p`
	${h400};
	margin: 12px 0 0;
	color: var(--neutral-100);
`;

export const PdfLink = styled.a`
	color: var(--primary-200);
`;

export const Text = styled.div`
	color: var(--text-color-default);
	padding-right: 12px;
	text-align: justify;

	h2 {
		${h600}
		font-weight: bold;
		margin-bottom: 14px;
	}

	h3 {
		${h500}
		font-weight: bold;
		margin: 14px 0;
	}

	li {
		margin-top: 5px;
	}
`;

export const AcceptButton = styled(PrimaryButton)`
	width: 232px;
`;
