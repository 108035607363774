/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { DropdownMenu } from 'semantic-ui-react';
import { ReactComponent as ShareIcon } from 'src/assets/icons/share.svg';
import { media } from 'src/_helpers';
import { m50 } from 'src/components/Typography';

export const ShareWrapper = styled.div`
	.ui.dropdown {
		display: flex;
		border-radius: 8px;
		background: var(--neutral-600);

		&:hover {
			background: var(--neutral-500);
		}
	}
`;

export const ShareButtonWrapper = styled.div`
	width: 36px;
	height: 36px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;

	${media.xl`
		width: 40px;
		height: 40px;
	`}
`;

export const ShareButton = styled(ShareIcon)`
	width: 24px;
	height: 24px;
	color: var(--white);
	display: block;
`;

export const ShareMenuContainer = styled(DropdownMenu)`
	&.menu.visible {
		margin-bottom: 8px;
		border-radius: var(--large-border-radius) !important;
		padding: 8px 0;

		& .item {
			height: 36px;
			display: flex;
			align-items: center;
			gap: 8px;

			> svg {
				color: var(--neutral-300);
			}

			&:hover {
				> svg {
					color: var(--white);
				}
			}

			> span {
				display: block;
				width: 100%;
			}
		}
	}
`;

export const ConfirmationMessage = styled.div`
	--_iconSize: 24px;
	position: absolute;
	left: 0;
	top: 0;
	${m50}
	padding: 5px 8px;
	border-radius: 4px;
	background: var(--neutral-500);
	color: var(--text-color-default);
	z-index: var(--z-index-tooltip);
	transform: translateY(calc(var(--_iconSize) + 8px)) translateX(calc(var(--_iconSize) / 2));
	visibility: hidden;

	&.visible {
		visibility: visible;
	}
`;
