/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styled';

export const PageInfo = ({ pagination, totalCount }) => {
	const { t } = useTranslation();
	return (
		<Styled.PageInfoWrapper>
			{t('{{x}}-{{y}}_of_{{z}}', {
				x: (pagination.activePage - 1) * pagination.limit + 1,
				y: Math.min(totalCount, pagination.activePage * pagination.limit),
				z: totalCount,
			})}
		</Styled.PageInfoWrapper>
	);
};
