/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled, { css } from 'styled-components';
import { StrokeButton } from './StrokeButton';
import { ReactComponent as UploadIcon } from 'src/assets/icons/upload.svg';

export const UploadButton = ({ className, onChange, multiple, label, accept, error }) => {
	return (
		<UploadButtonStyled className={className} $error={error}>
			<input
				data-test="file-input"
				type="file"
				title=""
				accept={accept}
				multiple={multiple}
				onChange={onChange}
				onClick={ev => {
					ev.target.value = null;
				}}
			/>
			<UploadIcon width={22} height={18} /> {label}
		</UploadButtonStyled>
	);
};

export const UploadButtonStyled = styled(StrokeButton)`
	position: relative;
	padding: 13px 24px;
	flex: 0 0 auto;
	align-self: center;
	width: 210px;

	${props =>
		props.$error &&
		css`
			border-color: var(--input-error-border-color);
		`}

	input {
		position: absolute;
		height: 100%;
		width: 100%;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		opacity: 0;
		z-index: 1;
		cursor: pointer;
	}

	svg {
		color: var(--primary-400);
	}
`;
