/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { API_URL } from 'src/_api';
import { M100 } from 'src/components/Typography';
import { tTradeDocumentTypeOrNotes } from 'src/constants/trade-document-type';
import { UserStatus } from 'src/components/UserStatus/UserStatus';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import * as Styled from './styled';

export const DocumentListItem = ({ document: { file, document_type, notes }, uploadedBy }) => {
	const { t } = useTranslation();

	return (
		<Styled.ListItem key={file._key}>
			<Styled.DocumentIconLink
				download={file.name}
				href={`${API_URL}/v1/download/${file._id}`}
				target="_blank"
			>
				<Styled.FileIcon />
			</Styled.DocumentIconLink>
			<Styled.DocumentInfo>
				<Styled.DocumentInfoMain>
					<OverflowText>
						<Styled.DocumentTypeLink
							download={file.name}
							href={`${API_URL}/v1/download/${file._id}`}
							target="_blank"
						>
							{tTradeDocumentTypeOrNotes(t, document_type, notes)}
						</Styled.DocumentTypeLink>
					</OverflowText>
				</Styled.DocumentInfoMain>
				<Styled.FileName>
					<OverflowText>{file.name}</OverflowText>
				</Styled.FileName>
			</Styled.DocumentInfo>
			<Styled.FileUploadInfo>
				<Styled.NameContainer>
					<OverflowText>
						<M100>{uploadedBy?.name}</M100>
						<UserStatus status={uploadedBy?.status} />
					</OverflowText>
				</Styled.NameContainer>
				<Styled.DotDivider />
				<M100>{moment(file.created_at).format('MMM D, YYYY h:mm A')}</M100>
			</Styled.FileUploadInfo>
		</Styled.ListItem>
	);
};
