/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'src/components/Link/Link';
import { CancelNavigationModal } from 'src/containers/BlockNavigationProvider/CancelNavigationModal';
import { useBlockNavigationContext } from './BlockNavigationProvider';

export const BlockNavigationLink = props => {
	const { isNavigationBlocked, unblockNavigation } = useBlockNavigationContext();

	const navigate = useNavigate();

	if (isNavigationBlocked) {
		return (
			<CancelNavigationModal
				trigger={<Link {...props} onClick={event => event.preventDefault()} />}
				onConfirm={() => {
					unblockNavigation();
					navigate(props.to);
				}}
			/>
		);
	}

	return <Link {...props} />;
};
