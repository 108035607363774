/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ToggleIconSvg } from 'src/assets/icons/toggle_icon.svg';

export const ToggleIcon = ({ isOpen = false, ...rest }) => {
	return <ToggleIconComponent open={isOpen} {...rest} />;
};

const ToggleIconComponent = styled(ToggleIconSvg)`
	transition: 0.2s;
	margin-left: 8px;
	transform: ${props => (props.open ? 'rotate(180deg);' : 'rotate(0)')};
`;
