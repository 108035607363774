/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { searchStorageKey, chartFiltersStorageKey } from 'src/constants/storageKeys';

export const storageKey = {
	chart: chartFiltersStorageKey,
	search: searchStorageKey,
};

export const loadState = key => {
	try {
		const serializedState = localStorage.getItem(key);

		if (serializedState === null) {
			return undefined;
		}

		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

export const saveState = (state, key) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(key, serializedState);
	} catch {
		// ignore write errors
	}
};
