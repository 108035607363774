/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { toPath } from 'src/_helpers';
import { Controller } from 'react-hook-form';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import { getShipmentCutOffDate } from 'src/_helpers/date';
import { useCalendarSpreadSection } from '../../useCalendarSpreadSection';
import * as Styled from './styled';

const FirstLegMonth = ({ path, t }) => {
	const { firstLegMonth } = useCalendarSpreadSection({ path });

	return (
		<Controller
			mandatory
			name={toPath(path, 'firstLegMonth')}
			defaultValue={null}
			render={({ field, fieldState: { error } }) => (
				<Styled.CalendarWrapper>
					<MaterialDateInput
						{...field}
						required
						id={field.name}
						dataTest={field.name}
						label={t('first_leg_shipment')}
						error={!!error}
						cutOffDate={getShipmentCutOffDate()}
						renderDateToggleButton={false}
						allowMultipleMonths={false}
						onChange={value => {
							firstLegMonth.onChange(value);
							field.onChange(value);
						}}
					/>
				</Styled.CalendarWrapper>
			)}
		/>
	);
};

export const firstLegMonth = (path, t) => <FirstLegMonth path={path} t={t} />;
