/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const getCounterUserWithCompany = counter => {
	if (!counter || !counter.user) {
		return null;
	}

	return {
		...counter.user,
		company: counter.company,
	};
};
