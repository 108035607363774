/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useCallback } from 'react';
import { useQuery } from 'react-query';
import { UserStatus } from 'src/constants/user';
import { getMyContacts } from 'src/_api/my-account.api';
import { groupArrayByElementKey } from 'src/_helpers';

const contactsListQuery = ({ queryKey: [, params] }) => {
	return getMyContacts(params);
};

export const useFilteredContactsList = (selectedUsers, filter, usersToHide) => {
	const { data: allContacts = [], isLoading, isError } = useQuery(
		[
			'my-contacts',
			{
				_limit: 1000,
			},
		],
		contactsListQuery,
		{
			keepPreviousData: true,
		}
	);

	const isUserSelected = useCallback(
		userId => !!selectedUsers.length && selectedUsers.includes(userId),
		[selectedUsers]
	);

	const mappedContacts = useMemo(() => {
		return allContacts
			.filter(
				user =>
					user.status !== UserStatus.DEACTIVATED &&
					!usersToHide.includes(user.contact_user_id)
			)
			.map(user => ({
				...user,
				lowerCaseName: user.name.toLowerCase(),
				lowerCaseCompany: (user.company_name || '').toLowerCase(),
				isSelected: isUserSelected(user.contact_user_id),
			}));
	}, [allContacts, usersToHide, isUserSelected]);

	const mappedContactsGroupedBySelection = groupArrayByElementKey(mappedContacts, 'isSelected');

	const filteredAndSortedUsers = useMemo(() => {
		const lowerCaseFilter = filter.toLowerCase();

		return [...mappedContactsGroupedBySelection]
			.sort(a => (a[0].isSelected ? -1 : 1))
			.map(usersList =>
				usersList.sort((a, b) => a.lowerCaseName.localeCompare(b.lowerCaseName))
			)
			.flat()
			.filter(user => {
				if (!filter) {
					return user;
				}

				return (
					user.lowerCaseName.includes(lowerCaseFilter) ||
					user.lowerCaseCompany.includes(lowerCaseFilter)
				);
			});
	}, [mappedContactsGroupedBySelection, filter]);

	return {
		filteredAndSortedUsers,
		isLoading,
		isError,
		allContactsCount: allContacts.length,
	};
};
