/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { Label } from '../Label/Label';
import { DateInput } from '../DateInput/DateInput';
import * as Styled from './styled';

export const MaterialDateInput = React.forwardRef(
	(
		{
			label,
			id,
			required,
			rightAligned,
			controlledState = false,
			hasChanged = false,
			...props
		},
		ref
	) => {
		const hasValue = props.value ? props.value.startDate && props.value.endDate : false;
		const filledControlledState = controlledState === true && hasChanged === false && hasValue;
		const filledState = controlledState ? hasChanged === true : hasValue;

		return (
			<Styled.InputContainer
				className={clsx(
					'date-input',
					rightAligned && 'align-right',
					filledState && 'input-filled',
					filledControlledState && 'input-filled-controlled',
					props.disabled && 'disabled'
				)}
			>
				<DateInput
					id={id}
					openerClassName="date-selector-field"
					{...props}
					popupHeaderTitle={label}
				/>
				<Label htmlFor={id}>
					<span>{label}</span>
					{required && <span className="asterisk">*</span>}
				</Label>
				{hasValue && <Styled.ValidCheckIcon />}
			</Styled.InputContainer>
		);
	}
);
