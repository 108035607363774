/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { m50 } from '../Typography';

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

export const Text = styled.span`
	color: var(--neutral-200);
	${m50}
`;

export const InfoIcon = styled(Info)`
	width: 16px;
	height: 16px;
	color: var(--neutral-200);
`;
