/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useAcceptPhysicalCounterSummary } from './useAcceptPhysicalCounterSummary';
import { SummaryRow } from 'src/containers/Order/common/OrderSummaryModal/SummaryRow';

export const AcceptPhysicalCounterSummary = ({ order, counters = [] }) => {
	const { dataToDisplay } = useAcceptPhysicalCounterSummary(order, counters);

	return dataToDisplay.map(({ title, value }, index) => (
		<SummaryRow key={index} title={title} value={value} />
	));
};
