/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const escapeHref = url => {
	const parts = url.split('?');
	const params = parts[1]?.split('&') || [];
	const escapedParams = params.map((param = '') => {
		return param
			?.split('=')
			.map(elem => encodeURIComponent(elem))
			.join('=');
	});

	return parts[0] + (escapedParams.length ? '?' : '') + escapedParams?.join('&');
};
