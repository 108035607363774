/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';

export const isToday = momentDate => {
	const today = moment().clone().startOf('day');
	return momentDate.isSame(today, 'd');
};

export const isYesterday = momentDate => {
	const yesterday = moment().clone().subtract(1, 'days').startOf('day');
	return momentDate.isSame(yesterday, 'd');
};

export const getMessageDate = date => {
	const today = moment().clone().startOf('day');
	const momentDate = moment(date);

	if (momentDate.isSame(today, 'day')) {
		return momentDate.format('h:mm A');
	}

	if (momentDate.isSame(today, 'year')) {
		return momentDate.format('MMM D');
	}

	return momentDate.format('MMM DD, YYYY');
};
