/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import Fingerprint2 from '@fingerprintjs/fingerprintjs';
import UAParser from 'ua-parser-js';

const fpPromise = Fingerprint2.load();
const parser = new UAParser().getResult();

export const getTrackData = async additionalTrackData => {
	const track = {};
	const fp = await fpPromise;
	const result = await fp.get();
	if (result) {
		track.fp = result.visitorId;
	}
	if (parser.device && (parser.device.model || parser.device.type || parser.device.vendor)) {
		track.device = parser.device;
	}
	if (parser.cpu.architecture) {
		track.cpu = parser.cpu;
	}
	if (parser.browser) {
		track.browser = parser.browser;
	}
	if (parser.os) {
		track.os = parser.os;
	}
	if (parser.engine) {
		track.engine = parser.engine;
	}
	track.url = document.location.href;
	track.type = 'view';

	if (additionalTrackData) {
		for (let field in additionalTrackData) {
			track[field] = additionalTrackData[field];
		}
	}
	return track;
};
