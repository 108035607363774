/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flags } from 'src/featureFlags/Flags';
import { useTradeDataContext } from 'src/containers/ExecutionDetails/Paper/TradeDataContext';
import { AcceptSignModal } from '../../AcceptSignModal/AcceptSignModal';
import { CompleteStepModal } from '../../CompleteStepModal/CompleteStepModal';
import { Permission } from 'src/containers/Permissions/Permission';
import { CloseTradeModal } from '../../CloseTradeModal/CloseTradeModal';
import { FlagNames } from 'src/constants/flags';
import { TradeStep } from 'src/constants/tradeStatus';
import { TradeActions } from 'src/constants/tradeStatus';
import { useTradeLocation } from 'src/_helpers/useTradeLocation';
import { IncoType, Market } from 'src/constants/contract';
import { Permissions } from 'src/constants/permissions';
import { AddUsersToTradeButton } from '../../../AddUsersToTradeButton/AddUsersToTradeButton';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { CopyOrderButton } from 'src/components/CreateOrderDrawer/components/CopyOrderButton/CopyOrderButton';
import * as Styled from './styled';

export const ButtonsSection = () => {
	const { t } = useTranslation();
	const { tradeData, stepCompleted, tradeStep } = useTradeDataContext();
	const { tradeId } = useTradeLocation();
	const currentUserId = useSelector(getUserId);

	const OTCNominee = [tradeData.buyer_user_id, tradeData.seller_user_id].filter(
		id => id !== currentUserId
	);

	const isVesselNameDefined = tradeStep === TradeStep.VesselNomination && !!tradeData.vessel_name;

	const isFOBorFASinco = [IncoType.FOB, IncoType.FAS].includes(tradeData?.inco_id);

	const isCIFCFRincoterm =
		tradeData?.inco_id === IncoType.CIF || tradeData?.inco_id === IncoType.CFR;

	let nextStepLabel = t('mark_as_complete');

	if (tradeData?.isBuyerSide && isFOBorFASinco && tradeStep === TradeStep.VesselNomination) {
		nextStepLabel = t('next');
	}

	if (tradeData?.isSellerSide && isFOBorFASinco && tradeStep === TradeStep.Completion) {
		nextStepLabel = t('next');
	}

	if (tradeData?.isSellerSide && isCIFCFRincoterm && tradeStep === TradeStep.ShippingAdvice) {
		nextStepLabel = t('next');
	}

	const isPaperMarket = tradeData.market === Market.Paper;

	const renderPhysicalMarketLinks = () => (
		<>
			{tradeStep === TradeStep.Signatures && (
				<AcceptSignModal
					trigger={
						<Styled.StyledPrimaryButton
							fixedPadding
							data-test="trade-signatures"
							disabled={stepCompleted}
						>
							{t('sign')}
						</Styled.StyledPrimaryButton>
					}
				/>
			)}

			{tradeStep === TradeStep.ShippingAdvice && (
				<CompleteStepModal
					action={TradeActions.ConfirmShippingAdvice}
					trigger={
						<Styled.StyledPrimaryButton
							fixedPadding
							data-test="complete-step"
							disabled={
								tradeData?.isBuyerSide ||
								!tradeData?.vessel_name ||
								!tradeData?.eta_loading_port
							}
						>
							{nextStepLabel}
						</Styled.StyledPrimaryButton>
					}
				/>
			)}

			{tradeStep === TradeStep.VesselNomination && (
				<CompleteStepModal
					action={TradeActions.ConfirmVesselNomination}
					trigger={
						<Styled.StyledPrimaryButton
							data-test="complete-step"
							disabled={tradeData?.isSellerSide || !isVesselNameDefined}
							fixedPadding
						>
							{nextStepLabel}
						</Styled.StyledPrimaryButton>
					}
				/>
			)}

			{tradeStep === TradeStep.Completion && (
				<CompleteStepModal
					action={TradeActions.Complete}
					trigger={
						<Styled.StyledPrimaryButton
							data-test="complete-step"
							disabled={
								stepCompleted || (tradeData?.isSellerSide && !tradeData?.bl_date)
							}
							fixedPadding
						>
							{nextStepLabel}
						</Styled.StyledPrimaryButton>
					}
				/>
			)}

			{tradeStep === TradeStep.Close && (
				<CloseTradeModal
					trigger={
						<Styled.StyledPrimaryButton
							data-test="complete-step"
							disabled={tradeData?.isSellerSide}
							fixedPadding
						>
							{nextStepLabel}
						</Styled.StyledPrimaryButton>
					}
				/>
			)}
		</>
	);

	return (
		<Styled.ButtonsSectionWrapper data-test="trade-details-button-section">
			<Flags
				authorizedFlags={[FlagNames.MultiTenancy]}
				renderOn={() => (
					<AddUsersToTradeButton
						usersAlreadyInTrade={tradeData?.tradeUsersIds}
						tradeId={tradeId}
					/>
				)}
			/>

			<Permission
				name={Permissions.ORDER_CREATE}
				renderOn={() => (
					<CopyOrderButton
						Component={CopyButton}
						tradeId={tradeData?._key}
						environment={tradeData?.environment}
						market={tradeData?.market}
						recipients={OTCNominee}
					/>
				)}
			/>

			{!isPaperMarket && renderPhysicalMarketLinks()}
		</Styled.ButtonsSectionWrapper>
	);
};

const CopyButton = ({ children, ...props }) => (
	<Styled.StyledSecondaryButton darker fixedPadding {...props}>
		{children}
	</Styled.StyledSecondaryButton>
);
