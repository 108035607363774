/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LoaderSpinning } from 'src/components/Layout/LandingForm';
import {
	UsersLabel as GroupsLabel,
	LoaderWrapper,
} from 'src/components/SearchableContactsList/styledComponents';
import { useFilteredGroupsList } from './useFilteredGroupsList';
import { SingleGroup } from './SingleGroup';

export const GroupsSelector = ({
	filter,
	selectedGroups = [],
	setSelectedGroups,
	usersToHide,
	userCompanyId,
	openGroupModal,
}) => {
	const { t } = useTranslation();
	const selectedGroupIds = selectedGroups.map(group => group._key);

	const { filteredAndSortedGroups, isLoading } = useFilteredGroupsList(
		selectedGroupIds,
		filter,
		usersToHide,
		userCompanyId
	);

	const isGroupSelected = useCallback(
		groupId => !!selectedGroups.length && selectedGroups.find(group => group._key === groupId),
		[selectedGroups]
	);

	const toggleGroupSelection = useCallback(
		group => {
			const groupId = group._key;

			setSelectedGroups(list => {
				return isGroupSelected(groupId)
					? list.filter(({ _key }) => _key !== groupId)
					: [
							...list,
							{
								...group,
								users: group.users.map(user => ({ ...user, isSelected: true })),
							},
					  ];
			});
		},
		[isGroupSelected, setSelectedGroups]
	);

	const updateGroupUsers = useCallback(
		(groupId, users) => {
			const group = selectedGroups.find(group => group._key === groupId);

			setSelectedGroups(list => {
				return [
					...list.filter(group => group._key !== groupId),
					{
						...group,
						users,
					},
				];
			});
		},
		[selectedGroups, setSelectedGroups]
	);

	if (isLoading) {
		return (
			<LoaderWrapper>
				<LoaderSpinning />
			</LoaderWrapper>
		);
	}

	if (!filteredAndSortedGroups.length) {
		return null;
	}

	return (
		<GroupsList data-test="groups-list" className="groups-list">
			<GroupsLabel>{t('groups')}</GroupsLabel>
			{filteredAndSortedGroups.map(group => (
				<SingleGroup
					key={group._key}
					group={group}
					onClick={toggleGroupSelection}
					updateGroupUsers={users => updateGroupUsers(group._key, users)}
					selectedGroups={selectedGroups}
					openGroupModal={openGroupModal}
				/>
			))}
		</GroupsList>
	);
};

const GroupsList = styled.div`
	margin: 20px 0;
	padding-right: 5px;
`;
