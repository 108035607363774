/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as RemoveItemIcon } from 'src/assets/icons/remove_item.svg';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { media } from 'src/_helpers';
import { H300, T50 } from 'src/components/Typography';
import {
	Modal,
	ModalSubTitle,
	ButtonsWrapper,
	IconWrapper as ModalIconWrapper,
} from 'src/components/Modal';

export const Wrapper = styled.div`
	border-radius: 16px;
	background: var(--neutral-900);
	padding: 24px;
	padding-top: 40px;
	width: 284px;
	height: max-content;

	${media.ll`
		width: 300px;
	`}

	${media.xl`
		width: 360px;
	`}
`;

export const SectionDescription = styled(T50)`
	margin-top: 16px;
	color: var(--neutral-300);
	display: block;
`;

export const InputLabel = styled(H300)`
	margin-top: 32px;
	color: var(--neutral-300);

	${media.ll`
		margin-top: 40px;
	`}
`;

export const SendInviteButton = styled(PrimaryButton)`
	margin-top: 24px;
`;

export const EmailInputWrapper = styled.div`
	margin-top: 6px;
`;

export const ErrorMessage = styled.div`
	color: var(--red-400);
	display: flex;
	gap: 7px;
	height: 26px;
	margin-top: 20px;
`;

export const ErrorIcon = styled(RemoveItemIcon)`
	color: var(--red-400);
	width: 10px;
	height: 10px;
	margin-left: 1px;
`;

export const InviteUsersModal = styled(Modal)`
	&.ui.tiny.modal {
		width: 440px;

		${media.ll`
			width: 480px;
		`}

		.content {
			background: var(--neutral-900);
		}
	}
`;

export const ModalContent = styled.div`
	${ModalSubTitle} {
		display: block;
	}
`;

export const ModalButtonsWrapper = styled(ButtonsWrapper)`
	margin-top: 35px;

	${media.ll`
		margin-top: 40px;
	`}
`;

export const IconWrapper = styled(ModalIconWrapper)`
	background: var(--neutral-600);
`;
