/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { useTogglable } from 'src/_helpers/useTogglable';
import {
	Modal,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	CancelButton,
} from 'src/components/Modal';
import { ModalSize, ModalType } from 'src/constants/modal';
import { PhysicalTradeStep, tPhysicalTradeStep } from 'src/constants/physicalTrade';
import { useTradeDataContext } from 'src/containers/ExecutionDetails/Physical/TradeDataContext';
import { useCompleteStepMutation } from './useCompleteStepMutation';
import * as Styled from './styled';

export const CompleteStepModal = ({ trigger }) => {
	const { t } = useTranslation();
	const [isOpen, { open, close }] = useTogglable();
	const { tradeData, activeStep } = useTradeDataContext();
	const tradeId = tradeData._key;
	let message = t('do_you_want_to_mark_this_stage_as_completed?');

	const isSignatures = activeStep === PhysicalTradeStep.SIGNATURES;
	const isShippingTender = activeStep === PhysicalTradeStep.SHIPPING_TENDER;
	const isInvoicing = activeStep === PhysicalTradeStep.INVOICING;
	const isShippingDocuments = activeStep === PhysicalTradeStep.SHIPPING_DOCUMENTS;

	if (isSignatures) {
		message = t('do_you_want_to_mark_signatures_as_completed');
	}

	if (isShippingTender) {
		message = t('do_you_want_to_mark_shipping_tender_as_completed');
	}

	if (isInvoicing) {
		message = t('do_you_want_to_mark_this_step_as_completed_invoices');
	}

	if (isShippingDocuments) {
		message = t('do_you_want_to_mark_shipping_documents_as_completed');
	}

	const { isLoading, mutate } = useCompleteStepMutation({
		tradeId,
		onSuccess: close,
	});

	return (
		<Modal
			data-test="complete-step-modal"
			size={ModalSize.Tiny}
			open={open}
			close={close}
			isOpen={isOpen}
			trigger={trigger}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>
					{t('mark_{{stage}}_as_completed', {
						stage: tPhysicalTradeStep(t, activeStep),
					})}
				</ModalTitle>
				<Styled.SubTitle>{message}</Styled.SubTitle>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<PrimaryButton
					loading={isLoading}
					type="button"
					onClick={() =>
						mutate({
							tradeId,
							stepName: activeStep.toLowerCase(),
						})
					}
				>
					{t('complete')}
				</PrimaryButton>
			</ButtonsWrapper>
		</Modal>
	);
};
