/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { RowsPerPage } from './RowsPerPage';
import { PageInfo } from './PageInfo';
import * as Styled from './styled';

export const Pagination = ({
	pagination,
	totalCount,
	setPagination,
	limitOptions,
	showRowsPerPage = true,
	showPageInfo = true,
}) => {
	const totalPages = Math.ceil(totalCount / pagination.limit);

	return (
		<Styled.PaginationWrapper>
			{showRowsPerPage && (
				<RowsPerPage
					limit={pagination.limit}
					limitOptions={limitOptions}
					setLimit={limit => setPagination({ activePage: 1, limit })}
				/>
			)}
			{showPageInfo && <PageInfo pagination={pagination} totalCount={totalCount} />}
			<Styled.StyledPagination
				activePage={pagination.activePage}
				onPageChange={(e, { activePage }) => setPagination({ ...pagination, activePage })}
				totalPages={totalPages}
				firstItem={null}
				lastItem={null}
				prevItem={{
					content: (
						<Styled.PrevArrow
							data-test="prev-item-button"
							className={clsx(pagination.activePage === 1 && 'disabled')}
						/>
					),
				}}
				nextItem={{
					content: (
						<Styled.NextArrow
							data-test="next-item-button"
							className={clsx(pagination.activePage === totalPages && 'disabled')}
						/>
					),
				}}
				pageItem={{
					children: (Component, props) => {
						return <Component as={Styled.PageNumber} type="pageItem" {...props} />;
					},
				}}
			/>
		</Styled.PaginationWrapper>
	);
};
