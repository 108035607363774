/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { takeLatest } from 'redux-saga/effects';
import {
	REGISTER_USER_REQUEST,
	LOGIN_USER_REQUEST,
	LOGOUT_USER_REQUEST,
	RESET_USER_CONFIRM_REQUEST,
	IS_USER_LOGGED_IN_REQUEST,
} from '../constants/actionTypes';

import {
	registerUser,
	loginUser,
	logoutUser,
	resetUserConfirm,
	isUserLoggedIn,
} from './user.sagas';

export function* rootSaga() {
	yield takeLatest(REGISTER_USER_REQUEST, registerUser);
	yield takeLatest(LOGIN_USER_REQUEST, loginUser);
	yield takeLatest(LOGOUT_USER_REQUEST, logoutUser);
	yield takeLatest(RESET_USER_CONFIRM_REQUEST, resetUserConfirm);
	yield takeLatest(IS_USER_LOGGED_IN_REQUEST, isUserLoggedIn);
}
