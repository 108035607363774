/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled from 'styled-components';
import { Header, Info } from '../ResendConfirmationEmail/ResendEmailForm';

export const OnboardingHeader = styled(Header)`
	white-space: pre-line;
	margin-bottom: 26px;
`;

export const OnboardingInfo = styled(Info)`
	white-space: pre-line;
	margin-bottom: 40px;
`;

export const OnboardingFooter = styled.div`
	color: var(--neutral-600);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: 0.2px;
	white-space: pre-line;
`;

export const OnboardingSeparator = styled.div`
	width: 100px;
	height: 2px;
	margin-bottom: 26px;
	background: var(--primary-200);
`;

export const OnboardingLink = styled.span`
	color: #323ce3;

	&:hover {
		cursor: pointer;
	}
`;
