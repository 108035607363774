/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { getCurrentTzOrDefault } from './timezone';
import moment from 'moment-timezone';

export const getDefaultDateTimeTzValue = () => {
	return {
		localDate: null,
		tz: getCurrentTzOrDefault(),
	};
};

export function timezoneDateToIsoString({ localDate, tz }) {
	const offset = moment.tz(localDate, tz).utcOffset();

	const utcTimestamp =
		localDate.getTime() - localDate.getTimezoneOffset() * 60000 - offset * 60000;

	return new Date(utcTimestamp).toISOString();
}
