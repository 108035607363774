/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { SectionGridContent, SectionGridLabel, SectionGridText } from '../../styled';
import { getOrderNegotiationTradeLink } from 'src/_helpers/order';
import { CopyToClipboard } from 'src/components/CopyToClipboard/CopyToClipboard';
import { useTranslation } from 'react-i18next';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import * as Styled from './styled';

export const CopyOrderNegotiationLink = ({ order, isCounter }) => {
	const { t } = useTranslation();

	if (!order._key) {
		return;
	}

	const { id, label, link } = getOrderNegotiationTradeLink(t, order, isCounter);

	return (
		<SectionGridContent>
			<SectionGridLabel>{label}</SectionGridLabel>
			<SectionGridText data-test="additional-information-section-order-counter-id">
				<Styled.NarrowSectionGridText>
					<Styled.OverflowTextWithTrailingElementWrapper>
						<OverflowText>{`#${id}`}</OverflowText>
						{isCounter && (
							<CopyToClipboard
								resourceToCopy={link}
								feedbackMessage={t('link_to_order_copied')}
							>
								<Styled.OrderIdLinkIcon />
							</CopyToClipboard>
						)}
					</Styled.OverflowTextWithTrailingElementWrapper>
				</Styled.NarrowSectionGridText>
			</SectionGridText>
		</SectionGridContent>
	);
};

export const CopyTradeLink = ({ tradeId, skipCopying = false }) => {
	const { t } = useTranslation();

	const link = `${window.location.origin}/execution/trade/${tradeId}`;

	return (
		<SectionGridText data-test="additional-information-section-trade-id">
			<Styled.NarrowSectionGridText>
				<Styled.OverflowTextWithTrailingElementWrapper>
					<OverflowText>{`#${tradeId}`}</OverflowText>
					{!skipCopying && (
						<CopyToClipboard
							resourceToCopy={link}
							feedbackMessage={t('link_to_order_copied')}
						>
							<Styled.OrderIdLinkIcon />
						</CopyToClipboard>
					)}
				</Styled.OverflowTextWithTrailingElementWrapper>
			</Styled.NarrowSectionGridText>
		</SectionGridText>
	);
};
