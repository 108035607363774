/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'src/components/Toast';
import { inviteUserToContacts } from 'src/_api/my-account.api';
import * as Styled from './styled';

export const AddToContactsButton = ({ userId, onAddToContactsClick }) => {
	const { t } = useTranslation();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const { isLoading, mutate } = useMutation(inviteUserToContacts, {
		onSuccess: data => {
			queryClient.invalidateQueries(['search-users']);
			queryClient.invalidateQueries(['user-details']);
			queryClient.invalidateQueries(['user-network-invites-sent']);
			queryClient.invalidateQueries(['user-network-invites-received']);
			queryClient.invalidateQueries(['user-network-invites-archived']);

			if (!!data?.added) {
				addToast({ message: t('invite_sent') });
			}

			if (data?.errors?.pending_invitations || data?.errors?.already_contact) {
				addToast({
					kind: 'error',
					message: t('invite_contact_error_message'),
				});
			}
		},
		onError: () => {
			addToast({
				kind: 'error',
				message: t('something_went_wrong'),
			});
		},
	});

	const handleButtonClick = event => {
		event.stopPropagation();
		event.preventDefault();
		onAddToContactsClick?.(userId);
		mutate(userId);
	};

	return (
		<Styled.AddToContactsButton
			onClick={handleButtonClick}
			data-test="add-to-contacts-button"
			loading={isLoading}
		>
			<span>{t('add_to_contacts')}</span>
		</Styled.AddToContactsButton>
	);
};
