/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';

export const getGroupItemsByIds = (groups = [], ids = []) => {
	const plainIds = ids.map(id => (typeof id === 'object' ? id.id : id));

	return groups.reduce((list, { options }) => {
		options.forEach(item => {
			if (plainIds.includes(item.id)) {
				list.push(item);
			}
		});
		return list;
	}, []);
};

export const getItemsByIds = (list = [], ids = []) => {
	const plainIds = ids.map(id => (typeof id === 'object' ? id.id : id));
	return list.filter(item => {
		return plainIds.includes(item.id);
	});
};

export const getDisplayValueDefault = list => {
	if (list.length === 0) {
		return '';
	}

	if (list.length === 1) {
		return <span className="single">{list[0].text}</span>;
	}

	return (
		<>
			<span className="multi">{list[0].text}</span>
			<span className="multi">+{list.length - 1}</span>
		</>
	);
};

export const getDisplayValueWithFlag = list => {
	if (list.length === 0) {
		return '';
	}

	if (list.length === 1) {
		return (
			<>
				{!!list[0].flag && <i className={`${list[0].flag} flag`} />}
				<span className="single">{list[0].text}</span>
			</>
		);
	}

	const sortedList = list.sort((a, b) => (a.text > b.text ? 1 : -1));

	return (
		<>
			{!!sortedList[0].flag && <i className={`${sortedList[0].flag} flag`} />}
			<span className="multi">{sortedList[0].text}</span>
			<span className="multi">+{sortedList.length - 1}</span>
		</>
	);
};

const getGroupOptionHeader = text => ({
	text,
	value: text,
	id: text,
	header: true,
});

export const sortAndFilterGroups = (groups, query, selectedItems) => {
	const selectedIds = selectedItems.map(item => item.id);
	const lowerCaseQuery = query.toLowerCase();

	const selectedGroupOptions = [];

	const filteredGroupOptions = groups.reduce((list, { text, options }) => {
		list.push(getGroupOptionHeader(text));

		options.forEach(option => {
			const isSelectedOption = selectedIds.includes(option.id);

			if (lowerCaseQuery === '') {
				if (isSelectedOption) {
					selectedGroupOptions.push(option);
				} else {
					list.push(option);
				}
			} else if (option.search.includes(lowerCaseQuery)) {
				if (isSelectedOption) {
					selectedGroupOptions.push(option);
				} else {
					list.push(option);
				}
			}
		});

		return list;
	}, []);

	return [...selectedGroupOptions, ...filteredGroupOptions];
};

export const sortAndFilterOptions = (options, query, selectedItems) => {
	const selectedIds = selectedItems.map(item => item.id);

	const lowerCaseQuery = query.toLowerCase();

	const filteredOptions =
		lowerCaseQuery === ''
			? [...options]
			: options.filter(option => option.header || option.search.includes(lowerCaseQuery));

	filteredOptions.sort((a, b) => {
		const selectionScore = selectedIds.includes(b.id) - selectedIds.includes(a.id);
		if (selectionScore) {
			return selectionScore;
		}

		return a.text.localeCompare(b.text);
	});

	return filteredOptions;
};

export const renderOptionDefault = option => {
	return <span>{option.text}</span>;
};

export const renderOptionWithFlag = option => {
	return (
		<>
			{!!option.flag && <i className={`${option.flag} flag`} />}
			<span>{option.text}</span>
		</>
	);
};
