/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { h400 } from 'src/components/Typography';

export const Parameters = styled.label`
	${h400}
	color: var(--input-text-color);
	padding-right: 10px;
	white-space: nowrap;
	display: flex;
	align-items: center;

	${media.ll`
    padding-right: 12px;
  `}
`;

export const IconWrapper = styled.label`
	color: var(--input-icon-color);
	padding-right: 10px;
	cursor: text;

	${media.ll`
    padding-right: 12px;
  `}

	svg {
		position: relative;
		top: 1px;
	}
`;

export const FrontIconWrapper = styled(IconWrapper)`
	padding-right: 0;
	padding-left: 14px;
	padding-top: 2px;
`;

export const TextInputWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	height: 36px;
	color: var(--input-text-color);
	background-color: var(--input-background);
	border-radius: var(--input-border-radius);
	border: 2px solid var(--input-background);

	&.disabled,
	&.disabled.error,
	&.disabled.error input,
	&.disabled.highlighted,
	&.disabled.highlighted input {
		color: var(--input-disabled-text-color);
		background-color: var(--input-disabled-background);
		border-color: var(--input-disabled-background);
	}

	.modal &.disabled,
	.modal &.disabled.error,
	.modal &.disabled.error input,
	.modal &.disabled.highlighted,
	.modal &.disabled.highlighted input {
		background-color: var(--input-disabled-background-modal);
		border-color: var(--input-disabled-background-modal);
	}

	&.disabled ${Parameters}, &.disabled ${IconWrapper} {
		color: var(--input-disabled-text-color);
	}

	&.error {
		border-color: var(--input-error-border-color);
	}

	&.highlighted {
		border-color: var(--input-highlighted-border-color);
	}

	${media.ll`
		height: 40px !important;
	`}
`;

export const TextInput = styled.input`
	${h400}
	width: 100% !important;
	padding: 10px 16px;
	color: var(--input-text-color);
	background-color: transparent;
	border: none;
	height: 36px !important;
	border-radius: var(--input-border-radius) !important;

	::placeholder {
		color: var(--input-placeholder-color);
	}

	&.disabled,
	&:disabled,
	&.disabled::placeholder {
		color: var(--input-disabled-text-color) !important;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		height: 0;
		-webkit-box-shadow: 0 0 0 30px var(--input-background) inset !important;
		-webkit-text-fill-color: var(--input-text-color) !important;
	}

	& + ${Parameters} {
		margin-left: -8px;
	}

	&.empty + ${Parameters} {
		color: var(--input-placeholder-color);
	}

	${media.ll`
    	padding: 12px 16px;
		height: 40px !important;
  `}
`;
