/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useFormContext } from 'react-hook-form';
import { getGrades, listIncoTerms, useMultiOriginsOptions } from 'src/_api';
import { OrderType, isShipmentInco, tOrderType } from 'src/constants/contract';
import { Speciality, tSpeciality, getAvailableHarvestYears } from 'src/constants/product';
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';
import { mapGrades } from 'src/_helpers/grades';

export const useQuoteSectionOptions = () => {
	const { t } = useTranslation();
	const [isOriginByGrade, setIsOriginByGrade] = useState(false);

	const { setValue, getValues } = useFormContext();
	const {
		dateValue,
		incoValue,
		productValue,
		originValue,
		gradeValue,
		portValue,
		harvestValue,
		specialityValue,
	} = useWatchPhysicalFields();

	/** QUOTE */
	const quoteOptions = Object.values(OrderType).map(value => ({
		text: tOrderType(t, value),
		value,
	}));

	/** INCO */
	const { data: incoList = [], isLoading: isLoadingInco } = useQuery('inco', listIncoTerms);

	const incoOptions = incoList.map(({ _key, name }) => ({
		text: name,
		value: _key,
	}));

	/** HARVEST */
	const harvestOptions = getAvailableHarvestYears().map(year => ({
		text: year,
		value: year,
	}));

	/** SPECIALITY */
	const specialityOptions = Object.values(Speciality).map(speciality => ({
		text: tSpeciality(t, speciality),
		value: speciality,
	}));

	/** SHIPMENT/DELIVERY DATE */
	const shipmentDeliveryLabel = !incoValue
		? t('shipment_delivery')
		: isShipmentInco(incoValue)
		? t('shipment')
		: t('delivery');

	/** GRADES */
	const originIds = (originValue || []).map(origin =>
		typeof origin === 'string' ? origin : origin.id
	);

	const { data: gradesData = { grades: [], specs: [] }, isLoading: isLoadingGrades } = useQuery(
		['grades', productValue],
		() => getGrades({ product_id: productValue }),
		{
			enabled: !!productValue,
			staleTime: 0,
			cacheTime: 0,
			select: list => mapGrades(t, list, originIds),
		}
	);

	const gradesOptions = useMemo(
		() => gradesData.grades.map(({ text, value }) => ({ text, value })),
		[gradesData.grades]
	);

	useEffect(() => {
		if (isLoadingGrades || !gradeValue) {
			return;
		}

		const gradeObject = gradesData.grades.find(item => item.key === gradeValue);
		const isGradeAvailable = !!gradeObject;

		if (!isGradeAvailable) {
			setValue('grade', null);
		} else {
			setValue('gradeObject', gradeObject);
			setValue('isCustomGrade', gradeObject.is_custom);
			setValue('specs', gradesData.specs[gradeValue]);
		}
	}, [gradeValue, gradesData.specs, gradesData.grades, isLoadingGrades, setValue]);

	/** ORIGINS */
	const { data: originOptions = [], isLoading: isLoadingOrigins } = useMultiOriginsOptions();

	const saveOriginObject = (origins = []) => {
		setValue('originObjects', origins);
	};

	/** PORT */
	const addPortName = (portKey, portName, countryCode) => {
		const oldPortNames = getValues('portNames') || {};
		if (portKey && portName && !oldPortNames[portKey]) {
			setValue('portNames', {
				...oldPortNames,
				[portKey]: `${portName}, ${countryCode}`,
			});
		}
	};

	useEffect(() => {
		if (portValue) {
			setValue('ports.0.name', portValue);
		}
	}, [portValue, setValue]);

	const setOriginByGrade = value => {
		if (!originValue || originValue.length === 0 || isOriginByGrade) {
			const grade = gradesData.grades.find(grade => {
				return grade.key === value;
			});

			if (grade.origins.length === 1) {
				const originObject = originOptions.find(origin => origin.id === grade.origins[0]);
				setValue('origin', [originObject]);
				saveOriginObject([originObject]);
			} else {
				setValue('origin', []);
				saveOriginObject([]);
			}
			setIsOriginByGrade(true);
		}
	};

	return {
		quote: { options: quoteOptions, ready: true },
		inco: { options: incoOptions, ready: !isLoadingInco },
		harvest: { options: harvestOptions, ready: true, hasValue: harvestValue },
		speciality: { options: specialityOptions, ready: true, hasValue: specialityValue },
		date: { label: shipmentDeliveryLabel, initialValue: dateValue?.startDate || new Date() },
		origins: { options: originOptions, ready: !isLoadingOrigins, saveObject: saveOriginObject },
		grades: {
			options: gradesOptions,
			specs: gradesData.specs,
			ready: !!productValue && !isLoadingGrades,
		},
		port: { addName: addPortName },
		setOriginByGrade,
		setIsOriginByGrade,
		canHideOptional: !harvestValue || !specialityValue,
	};
};
