/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { SectionGridText } from '../../styled';
import { Link } from 'react-router-dom';

export const CreatedAtText = styled(SectionGridText)`
	white-space: nowrap;
`;

export const Comment = styled(SectionGridText)`
	word-break: break-all;
`;

export const OrderIdLink = styled(Link)`
	&:hover {
		color: var(--neutral-100);
	}
`;
