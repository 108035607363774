/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useTranslation } from 'react-i18next';
import { HeaderType } from '../helpers/useViewOrderHeader';
import { useCounterDetails } from './useCounterDetails';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { useOrderNegotiations } from 'src/_api';
import { tLastCounterType, tOrderType, PaperInstruments } from 'src/constants/contract';
import { CounterStatus } from 'src/constants/counterStatus';

export const useCounterHeader = ({ allCounters, currentCounter, counterType, isOnLastCounter }) => {
	const { t } = useTranslation();
	const order = allCounters[0];

	const { data: activeNegotiations = [] } = useOrderNegotiations(
		{
			environment: order.environment,
			orderId: order._key,
			status: [CounterStatus.Active, CounterStatus.AcceptedNoCounter],
			counter_order_user_id: allCounters.at(-1)?.counter_order_user_id,
		},
		{
			enabled: !!allCounters.at(-1)?.counter_order_user_id && isOnLastCounter,
		}
	);

	const {
		isMyCounter,
		isCounterExpired,
		isTradedCounter,
		isActiveCounter,
		isNotAvailableCounter,
	} = useCounterDetails(currentCounter);

	const { isMyOrder, isBrokerOrder, isOrderNotAvailable } = useOrderDetails(order);

	const shouldShowCounterExpiredMessage =
		!isMyCounter && isCounterExpired && !isOrderNotAvailable && !activeNegotiations.length;

	let headerType = HeaderType.Default;
	let headerMessage = t('order_details', {
		orderType: tOrderType(t, counterType, currentCounter?.instrument, PaperInstruments.Spread),
	});
	let headerSubtitle;

	if (isTradedCounter) {
		headerType = HeaderType.Success;
		headerMessage = t('trade_confirmed');
	} else if (isOnLastCounter) {
		if (currentCounter?.status === CounterStatus.Terminated) {
			headerType = HeaderType.Info;
			headerMessage = t('order_withdrawn', {
				orderType: tOrderType(t, order.order_type, order.instrument),
			});
			headerSubtitle = '';
		} else if (isNotAvailableCounter) {
			headerType = HeaderType.Info;
			headerMessage = t('order_no_longer_available', {
				orderType: tOrderType(t, order.order_type, order.instrument),
			});
		} else if (currentCounter?.status === CounterStatus.Confirmed) {
			headerType = HeaderType.Info;
			headerMessage = t('order_no_longer_available', {
				orderType: tOrderType(t, order.order_type, order.instrument),
			});
			headerSubtitle = '';
		} else if (isMyCounter && isActiveCounter && !isCounterExpired) {
			headerType = HeaderType.Info;

			if (isMyOrder) {
				headerMessage = t('counter_awaiting_response', {
					counterType: tOrderType(t, order.order_type, order.instrument),
					counterpartyType: t('counterparty').toLowerCase(),
				});
			} else {
				headerMessage = t('counter_awaiting_response', {
					counterType: tLastCounterType(t, order, currentCounter, order.instrument),
					counterpartyType: (isBrokerOrder
						? t('broker')
						: t('counterparty')
					).toLowerCase(),
				});
			}
		} else if (shouldShowCounterExpiredMessage) {
			headerType = HeaderType.Info;
			headerMessage = t('counter_expired_counter_to_continue', {
				counterType: tOrderType(t, counterType, order.instrument),
			});
		} else if (isMyCounter && isCounterExpired) {
			headerType = HeaderType.Info;
			headerMessage = t('counter_has_expired', {
				counterType: tOrderType(t, counterType, order.instrument),
			});
		}
	}

	return {
		headerMessage,
		headerType,
		headerSubtitle,
	};
};
