/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'src/chat/assets/info.svg';
import * as Styled from './styled';
import { Tooltip, TooltipTrigger } from 'src/components/Tooltip/Tooltip';

export const UserStatistics = ({ user, statistics }) => {
	const { t } = useTranslation();

	const username = user.name;

	const list = [
		{
			label: 'response_rate_orders',
			value: statistics.responseRateOrders,
			info: 'response_rate_orders_info',
		},
		{
			label: 'response_rate_counters',
			value: statistics.responseRateCounters,
			info: 'response_rate_counters_info',
		},
		{
			label: 'success_rate_orders',
			value: statistics.successRateOrders,
			info: 'success_rate_orders_info',
		},
		{
			label: 'success_rate_counters',
			value: statistics.successRateCounters,
			info: 'success_rate_counters_info',
		},
	];

	return (
		<>
			<Styled.Statistics>{t('trader_info_copy')}</Styled.Statistics>
			<Styled.Summary>
				{t('#order', { count: statistics.totalOrders })}
				{' · '}
				{t('#counter', { count: statistics.totalCounters })}
			</Styled.Summary>
			<Styled.StatsContainer className="statistics">
				{list.map(({ label, value, info }) => (
					<Styled.DetailsRow key={label} data-test={label}>
						<label>
							{t(label)}
							<Tooltip>
								<TooltipTrigger>
									<InfoIcon />
								</TooltipTrigger>
								<Styled.TooltipContentComponent>
									<>
										<h3>{t(label)}</h3>
										<div>
											<Trans i18nKey={info}>
												<strong>{{ value: value || 0 }}</strong>
												<strong>{{ user: username }}</strong>
											</Trans>
										</div>
									</>
								</Styled.TooltipContentComponent>
							</Tooltip>
						</label>
						{Number.isSafeInteger(value) ? (
							<div>{value}%</div>
						) : (
							<Styled.NoData>{t('no_data')}</Styled.NoData>
						)}
					</Styled.DetailsRow>
				))}
			</Styled.StatsContainer>
		</>
	);
};
