/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { Modal } from 'src/components/Modal';
import { media } from 'src/_helpers';

export const UserLink = styled.span`
	&.full-width {
		width: 100%;
	}

	${({ $hasHoverEffect }) =>
		$hasHoverEffect &&
		`
		&:hover,
		&:hover .user-name {
			color: var(--neutral-100);
		}
	`};

	&:hover {
		cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
	}
`;

export const UserProfileModal = styled(Modal)`
	&.ui.tiny.modal {
		width: 395px;
		height: 657px;

		${media.ll`
			height: 665px;
		`}

		${media.xl`
			width: 440px;
			height: 760px;
		`}

		.modal {
			max-width: unset;
		}

		.content {
			height: 100%;
			padding: 0;

			> div {
				height: 100%;
			}
		}
	}
`;
