/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { toPath } from 'src/_helpers';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { useCalendarSpreadFields } from '../../useCalendarSpreadFields';
import { Controller, useFormContext } from 'react-hook-form';

const QuoteTypeField = ({ path, t, lastCounter }) => {
	const { setValue, trigger, formState } = useFormContext();

	const { orderType } = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	return (
		<Controller
			name={toPath(path, 'orderType')}
			mandatory
			render={({ field, fieldState: { error } }) => (
				<MaterialSelectInput
					error={!!error}
					data-test={field.name}
					label={t('counter_type')}
					options={orderType.options}
					required
					controlledState
					hasChanged={orderType.hasChanged}
					{...field}
					onChange={value => {
						field.onChange(value);
						setValue('orderTypeChanged', true);
						if (formState.isSubmitted) {
							trigger();
						}
					}}
				/>
			)}
		/>
	);
};

export const counterQuoteTypeField = props => <QuoteTypeField {...props} />;
