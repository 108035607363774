/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const downloadLocalFile = file => {
	if (!file) {
		return;
	}

	const reader = new FileReader();
	const link = document.createElement('a');

	reader.onload = ({ target }) => {
		link.setAttribute('href', target.result);
		link.setAttribute('download', file.name);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	reader.readAsDataURL(file);
};
