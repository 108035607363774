/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { useSelector } from 'react-redux';
import { WebsocketChannelType, PublicWebsocketEventType } from 'src/constants/websockets';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { useActiveMarket } from 'src/_routes/useActiveMarket';
import { areFiltersValid, paperChartSelector, physicalChartSelector } from 'src/_store/selectors';
import { Market } from 'src/constants/contract';
import { Dateformat, isDateInRange, getNearestFullTimeFromDateRange } from 'src/_helpers/date';
import { ContinuousShipmentPeriod, ChartIntervals } from 'src/constants/chart';
import { chartResolutionKey } from 'src/constants/storageKeys';
import { getChartData } from 'src/_api';
import { mapPaperFiltersToAPI, mapPhysicalFiltersToAPI } from 'src/_helpers/chart';
import { useChartFilters } from 'src/containers/MobileChart/helpers/useChartFilters';

export const useChartRealTimeUpdates = ({
	widget,
	resetChartDataFn = () => {},
	updateDataPointFn = () => {},
	entriesLength,
	setEntriesLength,
}) => {
	const platformMarket = useActiveMarket();
	const mobileChartFilters = useChartFilters();

	const forcedMarket = mobileChartFilters?.paper ? Market.Paper : Market.Physical;

	const activeMarket = mobileChartFilters ? forcedMarket : platformMarket;

	const selector = activeMarket === Market.Paper ? paperChartSelector : physicalChartSelector;

	const webChartFilters = useSelector(selector);

	const environmentFilters = {
		...(mobileChartFilters?.[activeMarket] || webChartFilters),
		market: activeMarket,
	};

	const paperChartFilters = useSelector(paperChartSelector);
	const physicalChartFilters = useSelector(physicalChartSelector);

	let filtersToApply = {};

	if (mobileChartFilters) {
		filtersToApply = {
			...mobileChartFilters,
		};
	} else {
		filtersToApply = {
			[Market.Paper]: { ...paperChartFilters },
			[Market.Physical]: { ...physicalChartFilters },
		};
	}

	const areChartFiltersValid = areFiltersValid(environmentFilters, activeMarket);

	const selectedInterval = window.localStorage.getItem(chartResolutionKey);

	// TODO: Move to EventBus
	useWebsocket(
		WebsocketChannelType.ExchangeUpdates,
		PublicWebsocketEventType.NewTradePrice,
		async message => {
			const incomingTrade = message.data.data;

			if (
				areChartFiltersValid &&
				matchesCurrentChartFilters(environmentFilters, incomingTrade)
			) {
				// Note: rare scenario but in such cases e.g. many trades being formed at the same time on the edge
				// of the interval we need to request full data from API
				if (!isDateInRange(incomingTrade.created_at, ChartIntervals[selectedInterval])) {
					// Note: both functions need to be called one after another according to TV docs if we want to perform full data refresh without cache
					resetChartDataFn();
					widget.current.activeChart().resetData();

					return;
				}

				// Unmount no data placeholder
				if (entriesLength === 0) {
					setEntriesLength(e => e + 1);
				}

				// Note: otherwise update last data point or create new one based on api request
				// We only request last data point which depends on selected interval
				const dataPointStartDate =
					getNearestFullTimeFromDateRange(ChartIntervals[selectedInterval]) * 1000; // we need to request data starting from the selected interval

				const mapper =
					activeMarket === Market.Paper ? mapPaperFiltersToAPI : mapPhysicalFiltersToAPI;

				const requestObject = mapper(filtersToApply, selectedInterval, dataPointStartDate);

				const updatedDataPoint = await getChartData({ ...requestObject });

				const { time, close, open, high, low, volume } = updatedDataPoint[0];

				updateDataPointFn({
					time,
					close,
					open,
					high,
					low,
					volume,
				});
			}
		}
	);
};

const matchMarket = (filters, trade) => filters.market === trade.market;

const matchPreset = (filters, trade) => filters.paperPreset._key === trade.preset_id;

const matchPriceType = (filters, trade) => filters.priceType === trade.price_type;

const matchShipment = (filters, trade) => {
	if (!filters.shipment || (!filters.shipment.startDate && !filters.shipment.endDate)) {
		return true;
	}

	if (filters.shipment.format === Dateformat.Months) {
		return (
			moment(filters.shipment.startDate).isSame(trade.delivery_date_from, 'month') &&
			moment(filters.shipment.endDate).isSame(trade.delivery_date_to, 'month')
		);
	} else if (filters.shipment.format === Dateformat.Continuous) {
		const monthInterval = ContinuousShipmentPeriod[filters.shipment.continuousPeriod];

		const adjustedMonth = moment(trade.created_at).add(monthInterval, 'months');

		return moment(adjustedMonth).isSame(trade.delivery_date_from, 'month');
	}

	return false;
};

const matchProduct = (filters, trade) => filters.product._key === trade.product_id;

const matchInco = (filters, trade) => filters.inco._key === trade.inco_id;

const matchCargoType = (filters, trade) => {
	return filters.cargoType.length === 0 ? true : filters.cargoType.includes(trade.shipment_type);
};

const matchCountriesAndRegions = (filters, trade) => {
	const filtersCountryIDs = filters.location.countries.map(c => c._key);
	const filtersRegionsIDs = filters.location.regions.map(r => r._key);

	const tradePort = trade.primary_ports[0];

	const { country_id: tradeCountryID, area_global_id: tradeRegionID } = tradePort;

	return filtersCountryIDs.includes(tradeCountryID) || filtersRegionsIDs.includes(tradeRegionID);
};

const matchGrade = (filters, trade) => {
	return !filters.grade ? true : filters.grade._key === trade.grade_id;
};

const paperMatchers = [matchMarket, matchPreset, matchPriceType, matchShipment];

const physicalMatchers = [
	matchMarket,
	matchProduct,
	matchInco,
	matchCargoType,
	matchCountriesAndRegions,
	matchGrade,
	matchShipment,
];

const matchesCurrentChartFilters = (filters, incomingTrade) => {
	const tradeMatchers = filters.market === Market.Paper ? paperMatchers : physicalMatchers;

	return tradeMatchers.every(matcher => matcher(filters, incomingTrade));
};
