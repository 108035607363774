/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { h500, m100 } from 'src/components/Typography';
import { ReactComponent as FileIconSVG } from 'src/assets/icons/file.svg';
import { NameContainer as BaseNameContainer } from 'src/components/UserStatus/UserStatus';

const infoWidth = css`
	width: 145px;

	${media.ll`
		width: 215px;
	`}

	${media.xl`
		width: 355px;
	`}

	.isPaperMarket & {
		width: 110px;

		${media.xl`
			width: 415px;
		`}
	}
`;

export const ListItem = styled.li`
	display: grid;
	grid-template-columns: 32px 1fr 258px;
	gap: 16px;
	align-items: center;
	color: var(--text-color-secondary);

	${media.xl`
		grid-template-columns: 32px 1fr 290px;
	`}
`;

export const DotDivider = styled.div`
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background: var(--text-color-secondary);
	margin: 0 8px;
`;

export const FileUploadInfo = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
`;

export const DocumentInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

export const DocumentInfoMain = styled.div`
	${infoWidth}
`;

export const DocumentTypeLink = styled.a`
	${h500}
	display: inline;
	color: white;

	&:hover {
		color: white;
		text-decoration: underline;
	}
`;

export const FileName = styled.div`
	${m100}
	color: var(--text-color-secondary);
	${infoWidth}
`;

export const FileIcon = styled(FileIconSVG)`
	width: 32px;
	height: 36px;
	color: var(--text-color-secondary);
`;

export const NameContainer = styled(BaseNameContainer)`
	width: 108px;

	${media.xl`
		width: 130px;
	`}

	.deactivated-icon {
		min-width: 14px;
		width: 14px;
	}
`;

export const DocumentIconLink = styled.a`
	display: inherit;

	&:hover {
		& svg {
			color: var(--neutral-200);
		}
	}
`;
