/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { SHOW_REFRESH_TOAST, HIDE_REFRESH_TOAST } from '../actions';

export const initialState = {
	showRefreshToast: false,
};

export function globalReducers(state = initialState, action) {
	const { type } = action;

	switch (type) {
		case SHOW_REFRESH_TOAST:
			return {
				...state,
				showRefreshToast: true,
			};
		case HIDE_REFRESH_TOAST:
			return {
				...state,
				showRefreshToast: false,
			};
		default:
			return state;
	}
}
