/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StepProgress } from '../StepProgress/StepProgress';
import { CompleteStepModal } from '../CompleteStepModal/CompleteStepModal';
import { useStickyFooter } from 'src/containers/ExecutionDetails/Physical/useStickyFooter';
import * as Styled from './styled';

export const StickyFooter = ({ isCompleteButtonDisabled }) => {
	const { t } = useTranslation();

	const { shouldShowCompleteButton, progress, message, isCompleted } = useStickyFooter();

	return (
		<Styled.FooterWrapper>
			<Styled.StepStatus>
				<StepProgress progress={progress} />
				<Styled.StepStatusInfo>
					<Styled.StatusHeader>{t('status')}</Styled.StatusHeader>
					<Styled.Status>{message}</Styled.Status>
				</Styled.StepStatusInfo>
			</Styled.StepStatus>
			{shouldShowCompleteButton && !isCompleted && (
				<CompleteStepModal
					trigger={
						<Styled.CompleteButton
							data-test="complete-step"
							disabled={isCompleteButtonDisabled}
						>
							{t('complete')}
						</Styled.CompleteButton>
					}
				/>
			)}
		</Styled.FooterWrapper>
	);
};
