/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useState } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import './_datepicker.css';
import { START_DATE } from 'react-dates/constants';
import moment from 'moment';
import { PeriodHeader } from './Header';
import { SimpleDateRange } from './SimpleDateRange/SimpleDateRange';
import * as Styled from './styled';

export const isOutsideRange = (day, cutOffDate) => {
	const isAfterToday = moment().isAfter(day, 'day');

	if (cutOffDate) {
		return !moment(day).isSameOrBefore(cutOffDate) || isAfterToday;
	} else {
		return isAfterToday;
	}
};

export const DateRange = ({
	isDateDisabled = isOutsideRange,
	startDate,
	endDate,
	onDatesChange,
	initialVisibleMonth,
	cutOffDate,
	allowPastDates,
	ResetIcon,
}) => {
	const [focusedInput, setFocusedInput] = useState(START_DATE);

	const onDatesChangeCallback = useCallback(
		({ startDate, endDate }) => {
			let start = startDate?.clone().utc(0).startOf('day');
			let end = endDate?.clone().utc(0).endOf('day');

			onDatesChange({
				startDate: start,
				endDate: end,
			});
		},
		[onDatesChange]
	);

	const onClear = useCallback(() => {
		onDatesChange({ startDate: null, endDate: null });
		setFocusedInput(START_DATE);
	}, [onDatesChange]);

	const isSimpleDateRange = !!allowPastDates && !cutOffDate;

	const commonProps = {
		startDate,
		endDate,
		onDatesChange: onDatesChangeCallback,
		focusedInput,
		onFocusChange: focusedInput => {
			setFocusedInput(!focusedInput ? START_DATE : focusedInput);
		},
		navPrev: <Styled.NavPrevButton />,
		navNext: <Styled.NavNextButton data-test="date-selector-next-button" />,
		initialVisibleMonth: () => moment(initialVisibleMonth || startDate),
	};

	return (
		<>
			<PeriodHeader
				startDate={startDate?.format('L')}
				endDate={endDate?.format('L')}
				onClear={onClear}
				ResetIcon={ResetIcon}
			/>
			<Styled.Section className="date-range">
				{isSimpleDateRange ? (
					<SimpleDateRange {...commonProps} />
				) : (
					<DayPickerRangeController
						{...commonProps}
						numberOfMonths={1}
						isOutsideRange={day =>
							!allowPastDates &&
							isDateDisabled(
								day,
								cutOffDate ? moment(+cutOffDate).add(1, 'months') : null
							)
						}
						weekDayFormat={'dd'}
						minimumNights={0}
						daySize={40}
						enableOutsideDays
						verticalBorderSpacing={4}
					/>
				)}
			</Styled.Section>
		</>
	);
};
