/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styled';

export const OTCLabel = () => {
	const { t } = useTranslation();

	return <Styled.Label className="otc-label">{t('OTC')}</Styled.Label>;
};
