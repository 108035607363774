/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { StrokeButton } from 'src/components/Buttons/StrokeButton';
import { h400 } from 'src/components/Typography';

export const MessageButton = styled(StrokeButton)`
	width: 140px;
	font-size: 13px;
	line-height: 15px;

	svg {
		width: 16px;
		height: 16px;
		color: var(--primary-400);
	}
`;

export const AddToContactsButton = styled(PrimaryButton)`
	width: 140px;
	font-size: 13px;
	line-height: 15px;
`;

export const PendingInvitationInfo = styled.div`
	display: flex;
	align-items: center;
	gap: 32px;

	span {
		color: var(--neutral-300);
		width: 64px;
		font-size: 12px;
		line-height: 18px;
		user-select: none;

		${media.xl`
			width: 134px;
			font-size: 13px;
			line-height: 15px;
		`}
	}

	div {
		width: 140px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		border-radius: 8px;
		font-size: 13px;
		line-height: 15px;
		user-select: none;
		color: var(--neutral-300);
		background: var(--neutral-800);
	}
`;

export const ApproveToggle = styled.div`
	${h400}
	width: 129px;
	white-space: nowrap;
	color: var(--neutral-300);
	display: flex;
	align-items: center;

	${media.xl`
		width: 135px;
	`}
`;

export const ToggleSwitch = styled.div`
	--size: 17.5px;
	--spacing: 1.25px;
	width: 40px;
	height: 20px;
	border-radius: var(--size);
	margin-right: 8px;
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	background: ${({ $isApproved }) => ($isApproved ? 'var(--green-900)' : 'var(--neutral-700)')};

	&:after {
		content: '';
		display: block;
		background: ${({ $isApproved }) => ($isApproved ? 'var(--white)' : 'var(--neutral-300)')};
		width: var(--size);
		height: var(--size);
		border-radius: 50%;
		position: absolute;
		transition: all 100ms linear;
		top: var(--spacing);

		${({ $isApproved }) =>
			$isApproved
				? css`
						right: var(--spacing);
				  `
				: css`
						left: var(--spacing);
				  `}
	}

	&:hover {
		&:after {
			background: var(--neutral-100);
		}
	}
`;
