/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { acceptOrder } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useTogglable } from 'src/_helpers/useTogglable';
import { OrderType, tOrderType } from 'src/constants/contract';
import { useNavigate } from 'react-router-dom';
import {
	Modal,
	CancelButton,
	ButtonsWrapper,
	ModalHeaderWrapper,
	ModalIconWrapper,
	ModalSubTitle,
} from 'src/components/Modal';
import { PositiveButton } from 'src/components/Buttons/PositiveButton';
import { ModalSize, ModalType } from 'src/constants/modal';
import { AcceptPaperOrderSummary } from './AcceptPaperOrderSummary/AcceptPaperOrderSummary';
import { AcceptPhysicalOrderSummary } from './AcceptPhysicalOrderSummary/AcceptPhysicalOrderSummary';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import {
	SummaryModalContent,
	SummaryModalTitle,
} from 'src/containers/CounterDetails/Shared/AcceptCounterModal/styled';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { useViewOrderDrawerContext } from 'src/components/ViewOrderDrawer/ViewOrderDrawerProvider';
import { isFooterTabActive, FooterTabs } from 'src/_helpers/session';

export const AcceptOrderModal = ({ order, submitButtonId, trigger }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isOpen, { open, close }] = useTogglable();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const { isOTCOrder, isBrokerOrder } = useOrderDetails(order);
	const userId = useSelector(getUserId);
	const { setAccepteeId } = useViewOrderDrawerContext();

	const { mutate, isLoading } = useMutation(acceptOrder, {
		onSuccess: async ({ _key: negotiationId }) => {
			if (!negotiationId) {
				addToast({
					message: t('order_no_longer_available', {
						orderType: tOrderType(t, order.order_type),
					}),
					kind: 'error',
				});
				await queryClient.invalidateQueries(['order', order._key]);

				close();
				return;
			}

			await queryClient.invalidateQueries(['physical-orders']);

			if (isFooterTabActive(FooterTabs.MyOrders)) {
				await queryClient.invalidateQueries(['my-orders']);
			}

			if (isFooterTabActive(FooterTabs.MyOrderHistory)) {
				await queryClient.invalidateQueries(['my-orders-history']);
			}

			if (isFooterTabActive(FooterTabs.MyTrades)) {
				await queryClient.invalidateQueries(['my-trades']);
			}

			await queryClient.invalidateQueries(['order', order._key]);
			await queryClient.invalidateQueries(['order_with_counters', order._key, negotiationId]);

			navigate(
				`?orderEnvironment=${order.environment}&orderId=${order._key}&negotiationId=${negotiationId}`
			);

			close();
		},
		onError: () => {
			addToast({
				message: t('accept_{{order_type}}_failed', {
					order_type: tOrderType(t, order.order_type).toLowerCase(),
				}),
				kind: 'error',
			});

			close();
		},
	});

	const isBidOrder = order.order_type === OrderType.Buy;
	const modalTitleKey = isBidOrder ? 'confirm_accepting_bid' : 'confirm_accepting_offer';
	const exchangeSubtitleBidInfo = isBrokerOrder
		? 'confirm_accepting_bid_info_broker'
		: 'confirm_accepting_bid_info_counterparty';
	const exchangeSubtitleOfferInfo = isBrokerOrder
		? 'confirm_accepting_offer_info_broker'
		: 'confirm_accepting_offer_info_counterparty';

	const modalSubtitleKey = isBidOrder
		? isOTCOrder
			? 'confirm_accepting_bid_info'
			: exchangeSubtitleBidInfo
		: isOTCOrder
		? 'confirm_accepting_offer_info'
		: exchangeSubtitleOfferInfo;

	const handleAcceptSubmit = () => {
		setAccepteeId(userId);
		mutate({
			id: order._key,
			environment: order.environment,
			version: order.version,
		});
	};

	return (
		<Modal
			data-test="accept-order"
			size={ModalSize.Tiny}
			type={ModalType.Success}
			open={open}
			close={close}
			isOpen={isOpen}
			trigger={trigger}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<SummaryModalTitle>{t(modalTitleKey)}</SummaryModalTitle>
				<ModalSubTitle>
					{t(modalSubtitleKey, {
						counterparty: order.hidden ? t('counterparty_lowercase') : order.user?.name,
					})}
				</ModalSubTitle>
			</ModalHeaderWrapper>
			<SummaryModalContent>
				<AcceptOrderModalContent order={order} />
			</SummaryModalContent>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<PositiveButton
					loading={isLoading}
					id={submitButtonId}
					onClick={handleAcceptSubmit}
					data-test="accept-order-modal-button"
				>
					{t('accept')}
				</PositiveButton>
			</ButtonsWrapper>
		</Modal>
	);
};

const AcceptOrderModalContent = ({ order }) => {
	const { isPaperOrder } = useOrderDetails(order);

	if (isPaperOrder) {
		return <AcceptPaperOrderSummary order={order} />;
	}

	return <AcceptPhysicalOrderSummary order={order} />;
};
