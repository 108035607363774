/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const Speciality = {
	Organic: 'organic',
	GMO: 'gmo',
	NonGMO: 'non-gmo',
	Sustainable: 'sustainable',
	Traceable: 'traceable',
};

export const PKPG_PRODUCT_ID = 'RBD_OLEIN';

export const specialities = Object.values(Speciality);

export function tSpeciality(t, value) {
	switch (value) {
		case Speciality.Organic:
			return t('organic');
		case Speciality.GMO:
			return t('gmo');
		case Speciality.NonGMO:
			return t('non_gmo');
		case Speciality.Sustainable:
			return t('sustainable');
		case Speciality.Traceable:
			return t('traceable');
		default:
			return '';
	}
}

export function getAvailableHarvestYears() {
	const currentYear = new Date().getFullYear();
	return [-2, -1, 0, +1, +2].map(diff => {
		return currentYear + diff;
	});
}

export function tSpecDirection(t, spec) {
	return spec.min_enabled
		? 'Min'
		: spec.max_enabled
		? 'Max'
		: spec.basis_enabled
		? t('spec_basis')
		: '';
}

export const tPriceUnit = (t, priceUnit) => {
	return priceUnit ? t(`price_per_${priceUnit}`) : t(`price_per_mt`);
};

export const tPriceUnitShort = (t, priceUnit) => {
	return priceUnit && priceUnit !== 'mt'
		? t(`price_unit_${priceUnit}`)
		: t(`price_unit_metric_ton`);
};

export const countriesWithoutFlags = [
	'AQ',
	'BQ',
	'BLACKSEA',
	'CW',
	'GG',
	'IM',
	'JE',
	'MF',
	'SX',
	'SS',
];
