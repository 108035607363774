/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router';

export const dummyNavigate = () => null;

export const AppNavigateContext = createContext({
	navigate: dummyNavigate,
});

export const AppNavigateContextProvider = ({ children, navigate }) => {
	return (
		<AppNavigateContext.Provider value={{ navigate }}>{children}</AppNavigateContext.Provider>
	);
};

export const useAppNavigate = () => {
	return useContext(AppNavigateContext);
};

export const useMixedNavigate = () => {
	const { navigate: appNavigate } = useAppNavigate();

	try {
		const routerNavigate = useNavigate();
		return routerNavigate;
	} catch (error) {
		return appNavigate;
	}
};
