/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { ReactComponent as FileIcon } from 'src/assets/icons/file.svg';
import { t100 } from 'src/components/Typography';
import styled from 'styled-components';

export const FileContainer = styled.div`
	display: inline-flex;
	align-items: center;
	gap: 5px;

	a {
		${t100}
		color: #fff;
		text-decoration: none;
		white-space: nowrap;

		&:hover {
			color: var(--neutral-100);
		}
	}

	&.changed {
		position: relative;
		z-index: 1;

		svg {
			color: var(--text-color-default);
		}

		&:before {
			content: '';
			display: block;
			background-color: var(--primary-800);
			inset: -5.5px -4px;
			border-radius: 8px;
			position: absolute;
			z-index: -1;
		}
	}
`;

export const FileIconWrapper = styled.div`
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const FileIconSvg = styled(FileIcon)`
	width: 16px;
	height: 16px;
	color: var(--neutral-200);
	flex-shrink: 0;
`;

export const Separator = styled.span`
	margin: 0 8px;
`;

export const Owner = styled.span`
	span {
		text-transform: capitalize;
	}
`;
