/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';
import { ModalTitle } from 'src/components/Modal';

export const SummaryModalContent = styled.div`
	margin-top: 24px;
	margin-bottom: 40px;
	margin-right: -24px;
	padding-right: 24px;
	max-height: 54vh;
	overflow: auto;

	${media.xl`
		max-height: unset;
		margin-right: 0;
		padding-right: 0;
	`}
`;

export const SummaryModalTitle = styled(ModalTitle)`
	margin-bottom: 8px;
`;
