/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { File } from 'src/components/File/File';
import { ReactComponent as FileIcon } from 'src/assets/icons/file.svg';
import { H400, M100 } from 'src/components/Typography';
import styled from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { ReactComponent as SmallCloseIcon } from 'src/assets/icons/close-small.svg';
import loaderImage from 'src/assets/icons/loader.png';
import { UserStatus } from '../UserStatus/UserStatus';
import clsx from 'clsx';
import { OverflowText } from '../Tooltip/OverflowText';

export function OrderFile({
	file,
	isUploading,
	handleDelete,
	renderFileName,
	className,
	isSmall = false,
	withTooltip = false,
	...props
}) {
	const formattedDate = moment(file?.created_at).format('MMM D, YYYY h:mm A');
	return (
		<StyledFile
			key={file.name}
			data-test={file.name}
			className={clsx(className, isUploading && 'is-uploading')}
			{...props}
		>
			<FileIconSvg />
			{typeof renderFileName === 'function' ? (
				renderFileName({ fileName: file.name })
			) : (
				<div>
					{withTooltip ? (
						<OverflowText>
							<FileName>{file.name}</FileName>
						</OverflowText>
					) : (
						<FileName>{file.name}</FileName>
					)}

					{file?.owner && file?.created_at && (
						<OrderFileDetails>
							<NameContainer>
								<M100>{`${file.owner.first_name} ${file.owner.last_name}`}</M100>
								<UserStatus status={file?.owner?.status} />
							</NameContainer>
							<FormattedDate>{`• ${formattedDate}`}</FormattedDate>
						</OrderFileDetails>
					)}
				</div>
			)}
			{isUploading && <img src={loaderImage} alt="" className="spinner" />}
			{handleDelete && (
				<button type="button" data-test="delete" onClick={handleDelete}>
					{isSmall ? <SmallCloseIcon /> : <CloseIcon width="12px" height="12px" />}
				</button>
			)}
		</StyledFile>
	);
}

const StyledFile = styled(File)`
	&.is-uploading {
		grid-template-columns: 38px auto 20px 20px;
	}
`;

const OrderFileDetails = styled.div`
	display: flex;
	align-items: center;
	margin-top: 8px;
	color: var(--neutral-300);
`;

export const FileIconSvg = styled(FileIcon)`
	margin-right: 16px;
	height: 42px;
	width: 37px;
	color: var(--text-color-secondary);

	${media.xl`
		height: 48px;
		width: 42px;
	`}
`;

const FileName = styled(H400)`
	overflow-wrap: break-word;
	max-width: 200px;
	word-break: break-all;

	${media.ll`
		max-width: 230px;
	`}

	${media.xl`
		max-width: 300px;
	`}
`;

const NameContainer = styled.div`
	display: flex;
	align-items: center;

	.deactivated-icon {
		margin-right: 4px;
	}
`;

const FormattedDate = styled(M100)`
	margin-left: 5px;
`;
