/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmailFormatValid } from 'src/_helpers/email';
import { LabeledInput } from 'src/components/Input/LabeledInput';
import { FormRow } from 'src/components/Layout/LandingForm';
import {
	StyledForm,
	Header,
	Info,
	Buttons,
	BackButton,
	SendButton,
} from 'src/containers/ResendConfirmationEmail/ResendEmailForm';

export const ResetEmailForm = ({ email, isSubmitted, onSubmit, onEmailChange, onBackClick }) => {
	const { t } = useTranslation();

	const isEmailValid = isEmailFormatValid(email);
	const isEmailError = isSubmitted && !isEmailValid;

	return (
		<>
			<Header>{t('reset_form_title')}</Header>
			<Info>{t('resend_email_text')}</Info>
			<StyledForm name="form" onSubmit={onSubmit}>
				<FormRow>
					<LabeledInput
						mandatory
						label={t('email_input_label')}
						name="email"
						placeholder={t('email_input_placeholder')}
						onChange={onEmailChange}
						wrapperClassName="login-form-control"
						tabIndex="1"
						value={email}
						successValidationTestId={isEmailValid ? 'green-mark-email' : null}
						errors={[
							{
								text: t('email_input_error_message'),
								hasError: isEmailError,
							},
						]}
					/>
				</FormRow>
				<Buttons>
					<BackButton
						type="button"
						onClick={onBackClick}
						id="resend_verification_email_back_button"
					>
						{t('back')}
					</BackButton>
					<SendButton type="submit">{t('continue_btn')}</SendButton>
				</Buttons>
			</StyledForm>
		</>
	);
};
