/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { media } from 'src/_helpers';
import styled from 'styled-components';

export const TradeProductInfoWrapper = styled.div`
	display: flex;
	align-self: center;
	padding-left: 32px;
	gap: 32px;

	${media.xl`
		gap: 40px;
		padding-left: 48px;
	`}
`;

export const ProductItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	max-width: calc((100% - 5 * 8px) / 6);

	& h4:first-child {
		color: var(--neutral-300);
	}

	& .overflow-wrapper {
		display: grid;
	}
`;
