/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ModalSubTitle } from 'src/components/Modal';
import { media } from 'src/_helpers';

export const SubTitle = styled(ModalSubTitle)`
	margin-bottom: 25px;

	${media.xl`
		margin-bottom: 40px;
	`}
`;
