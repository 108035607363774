/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ChapterTitleLink, SubChapterTitleLink } from 'src/containers/Guidebook/styled';

export const NavigationMenu = styled.div`
	background: #e5e7ef;
	padding: 64px 32px;
	position: fixed;
	height: 100vh;
	width: 85%;
	top: 0;
	right: ${({ $isOpen }) => ($isOpen ? '0' : '-85%')};
	transition: right 0.5s ease;
	overflow: auto;
	z-index: 2;
`;

export const MobileChapterTitleLink = styled(ChapterTitleLink)`
	padding-left: 0;
`;

export const MobileSubChapterTitleLink = styled(SubChapterTitleLink)`
	padding-left: 16px;
`;
