/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	FoldableSection,
	IconType,
} from 'src/components/CreateOrderDrawer/components/FoldableSection/FoldableSection';
import { useSpecificationsSectionOptions } from './useSpecificationsSectionOptions';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { tSpecDirection } from 'src/constants/product';
import { getDecimalLength } from 'src/_helpers/specs';
import * as Styled from './styled';

export const SpecificationsSection = () => {
	const { t } = useTranslation();

	const {
		formState: { errors },
	} = useFormContext();

	const {
		gradeValue,
		isCustomGrade,
		specsList,
		customSpecsValue,
	} = useSpecificationsSectionOptions();

	if (!gradeValue) {
		return null;
	}

	const hasValidValues = isCustomGrade
		? Object.values(customSpecsValue || {}).some(val => !!val)
		: true;

	const hasErrors = !!errors.customSpecs && !hasValidValues;

	return (
		<FoldableSection
			title={t('specifications')}
			showAll={isCustomGrade}
			iconType={IconType.chevron}
		>
			{isCustomGrade ? (
				<Styled.CustomGradeWrapper fullWidth>
					{specsList.map((spec, index) => (
						<Controller
							key={spec._key}
							name={`customSpecs.spec-${spec._key}`}
							render={({ field }) => (
								<MaterialNumberInput
									error={hasErrors}
									data-test={`specs-${index}`}
									id={field.name}
									label={`${spec.name} ${tSpecDirection(t, spec)}`}
									thousandSeparator
									fixedDecimalScale
									allowNegative={false}
									allowLeadingZero
									decimalScale={getDecimalLength(spec.valid_step)}
									parameters={spec.unit}
									{...field}
								/>
							)}
						/>
					))}
				</Styled.CustomGradeWrapper>
			) : (
				<Styled.SpecsListWrapper fullWidth>
					<Styled.SpecsList>
						{specsList.map((spec, index) => (
							<div key={spec._key}>
								<Styled.Label>{spec.name}</Styled.Label>
								<Styled.Spec>
									<span>{tSpecDirection(t, spec)}</span>
									<Styled.Value>
										{spec.value}
										{spec.unit}
									</Styled.Value>
								</Styled.Spec>
							</div>
						))}
					</Styled.SpecsList>
					<Styled.SpecAdditionalInfoText fullWidth>
						{t('specs_additional_info')}
					</Styled.SpecAdditionalInfoText>
				</Styled.SpecsListWrapper>
			)}
		</FoldableSection>
	);
};
