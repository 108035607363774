/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as ChainIcon } from 'src/assets/icons/link-chain.svg';
import { ROUTES } from 'src/constants/routes';
import { tBoolean } from 'src/constants/contract';
import { RowCell } from 'src/components/Datatable';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { formatPrice } from 'src/_helpers/price';
import { MessageButton } from './MessageButton';
import { UserStatus } from 'src/components/UserStatus/UserStatus';
import { getUserId } from 'src/_store/selectors';
import { isInactiveUser } from 'src/_helpers/isInactiveUser';
import { UserProfileLink } from 'src/components/UserProfileModal/UserProfileLink';
import { MyDocumentBypass } from './MyDocumentBypass';
import { SettlementModal } from './SettlementModal';
import { Languages } from 'src/constants/settings';
import * as Styled from './styled';

export const CompanyCell = ({ item, ...props }) => {
	const { t } = useTranslation();

	const shouldShowTradeInfo = Object.keys(item.sellTrade).length > 0;

	return (
		<Styled.StringCellCompany {...props}>
			<div>
				<Styled.StringCompanyName>
					<OverflowText>
						<span className="company-name">{item.company}</span>
					</OverflowText>
					{t(item.type)} {item.isBroker && `(${t('broker')})`}
				</Styled.StringCompanyName>
				<Styled.NameContainer>
					<UserProfileLink userId={item.contactUserId} isInactive={isInactiveUser(item)}>
						{item.user}
					</UserProfileLink>
					<UserStatus status={item.userStatus} />
				</Styled.NameContainer>
				{shouldShowTradeInfo && <TradeLink {...item.sellTrade} />}
			</div>
		</Styled.StringCellCompany>
	);
};

const TradeLink = ({ price, isCurrentTrade, tradeId }) => {
	const { t } = useTranslation();

	const priceToShow = formatPrice(price) || '---';
	const hasAccessToTrade = !tradeId.endsWith('XXXX');

	const tradeLink =
		isCurrentTrade || !tradeId
			? undefined
			: hasAccessToTrade
			? ROUTES.tradeDetails.replace(':tradeId', tradeId)
			: undefined;

	const content = (
		<>
			<div>
				{hasAccessToTrade && <ChainIcon />}
				<span>
					{t('trade')} #{tradeId}
				</span>
			</div>
			<div>{priceToShow}</div>
		</>
	);

	if (!tradeLink) {
		return (
			<Styled.TradeInfo $isCurrentTrade={isCurrentTrade} $hasAccessToTrade={hasAccessToTrade}>
				{content}
			</Styled.TradeInfo>
		);
	}

	return (
		<Styled.TradeLink
			$isCurrentTrade={isCurrentTrade}
			$hasAccessToTrade={hasAccessToTrade}
			to={tradeLink}
		>
			{content}
		</Styled.TradeLink>
	);
};

export const SettlementCell = ({ item, ...props }) => {
	const shouldShowDetails = !!item.settlementSuggestions;
	// force language to be one of English / Chinese - with English as a fallback
	const language = Object.values(Languages)
		.map(({ code }) => code)
		.includes(i18n.language)
		? i18n.language
		: Languages.English.code;
	const text = item.settlementSuggestions?.[language]?.replaceAll('\n', '\n\n');

	return (
		<Styled.SettlementCell {...props}>
			{shouldShowDetails ? <SettlementModal text={text} companyName={item.company} /> : '---'}
		</Styled.SettlementCell>
	);
};

export const BaseCell = ({ children, ...props }) => (
	<Styled.BaseCell {...props}>{children}</Styled.BaseCell>
);

export const EmptyCell = ({ ...props }) => <RowCell {...props}>---</RowCell>;

export const StringRow = ({ item, children, ...props }) => {
	return (
		<Styled.StringRow $isUserFromGivenTradeSide={item.isUserFromGivenTradeSide} {...props}>
			{children}
		</Styled.StringRow>
	);
};

export const BaseMessageCell = ({ item, contacts, ...props }) => {
	const userId = useSelector(getUserId);

	return (
		<RowCell {...props}>
			<Styled.ActionsCellContent>
				{userId !== item.contactUserId && (
					<MessageButton userId={item.contactUserId} contacts={contacts} />
				)}
			</Styled.ActionsCellContent>
		</RowCell>
	);
};

export const MyDocumentByPassCell = ({ item, tradeId, ...props }) => {
	const { t } = useTranslation();

	const isCurrentTradeInCircle = item.circleTrade;
	const isNotFirstSellerOrLastBuyer = item.type === null;
	const canShowValue = isNotFirstSellerOrLastBuyer || isCurrentTradeInCircle;
	const canShowForm = item.current && canShowValue;

	return (
		<BaseCell {...props}>
			{canShowForm ? (
				<MyDocumentBypass tradeId={tradeId} {...item} />
			) : (
				<Styled.PaddedLabel>
					{canShowValue ? tBoolean(t, item.documentBypass) : '---'}
				</Styled.PaddedLabel>
			)}
		</BaseCell>
	);
};

export const DocumentByPassCell = ({ item, tradeId, ...props }) => {
	const { t } = useTranslation();

	const isCurrentTradeInCircle = item.circleTrade;
	const isNotFirstSellerOrLastBuyer = item.type === null;
	const canShowValue = isNotFirstSellerOrLastBuyer || isCurrentTradeInCircle;

	return (
		<BaseCell {...props}>
			<Styled.PaddedLabel>
				{canShowValue ? tBoolean(t, item.documentBypass) : '---'}
			</Styled.PaddedLabel>
		</BaseCell>
	);
};
