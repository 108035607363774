/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled from 'styled-components';
import { h300, h400 } from 'src/components/Typography';
import { media } from 'src/_helpers';

export const NoApprovedContactsMessage = styled.div`
	${h300}
	color: var(--neutral-300);
	padding: 12px;
	padding-top: 6px;

	${media.xl`
		padding: 16px;
		padding-top: 10px;
	`}

	& a {
		color: var(--primary-200);

		&:hover {
			color: var(--primary-100);
		}
	}
`;

export const ApprovedContactsHeader = styled.div`
	${h400}
	color: var(--neutral-100);
	margin-bottom: 12px;
`;
