/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const formatTradeDocuments = (documents = []) =>
	documents.map(({ document, file }) => ({
		trade_step: document.trade_step,
		document_id: document.document_id,
		document_type: document.document_type,
		notes: document.notes,
		file,
	}));
