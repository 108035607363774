/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { Table as SemanticTable } from 'semantic-ui-react';
import { h400 } from 'src/components/Typography';
import { media } from 'src/_helpers/media-queries';
import { ReactComponent as SpinnerIcon } from 'src/assets/icons/animated_spinner.svg';

export const NoEntriesCell = styled(SemanticTable.Cell)`
	padding: 0 !important;
`;

export const PaginationCell = styled(SemanticTable.HeaderCell)`
	.ui.table th& {
		overflow: visible;
	}
`;

export const EmptyOrders = styled(SemanticTable.Body)`
	display: flex;
	justify-content: center;
	color: var(--text-color-default);
	${h400};
	margin-top: 32px;

	& > tr {
		&:hover {
			background: transparent !important;
		}

		td {
			vertical-align: middle !important;
		}
	}

	&.no-entries-table-body {
		.my-tabs & {
			margin-left: 0;
		}

		margin-left: 28px;

		&.expanded {
			width: 100dvw;
			margin-left: 0;
		}
	}
`;

export const TableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-x: hidden;
	scrollbar-gutter: stable;
	padding-right: 16px;
`;

export const TableHeaderContainer = styled.div`
	overflow: hidden;
	scrollbar-gutter: ${({ $scrollGutter }) => ($scrollGutter ? 'stable' : 'unset')};
	min-height: 29.88px;
	display: flex;
	align-items: center;
`;

export const ScrolledTableContent = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
	width: ${({ $scrollFitContentWidth }) => ($scrollFitContentWidth ? 'fit-content' : 'auto')};
	scrollbar-gutter: ${({ $scrollGutter }) => ($scrollGutter ? 'stable' : 'unset')};
`;

export const Table = styled(SemanticTable)`
	display: flex;
	flex-direction: column;
	width: 100%;

	.ui.table& thead,
	.ui.table& tbody tr {
		display: table;
		width: 100%;
		table-layout: fixed;
	}

	.ui.table& tbody tr.clickable-row {
		cursor: pointer;
	}

	.ui.table& tbody tr.edit-mode-row {
		background-color: var(--neutral-850);
		cursor: default;
		z-index: 0;

		> td {
			cursor: default;
		}

		&.row-changed {
			background: linear-gradient(270deg, var(--neutral-850) 0%, #122037 100%);
		}
	}

	&.ui.table.table-paper-orders thead {
			margin-bottom: 2px;
	}

	&.ui.table thead {
		position: sticky;
		top: 0;
		z-index: 1;

		th.sorted {
			position: relative;
			overflow: initial;

			&::after {
				display: none;
			}

			&.alignedLeft {
				& .label {
					&::before {
						display: none;
					}

					&::after {
						color: #fff;
						font-family: 'Icons';
						opacity: 0.8;
						height: 1em;
						width: auto;
						margin-left: 5px;
						position: absolute;
					}
				}

				&.ascending {
					& .label {
						&::after {
							content: '\f0d8';
						}
					}
				}

				&.descending {
					& .label {
						&::after {
							content: '\f0d7';
						}
					}
				}

				& .label-overflowed {
					&::after {
						display: none;
					}

					&::before {
						color: #fff;
						font-family: 'Icons';
						opacity: 0.8;
						height: 1em;
						width: auto;
						margin-left: 5px;
						float: right;
					}
				}

				&.ascending {
					& .label-overflowed {
						&::before {
							content: '\f0d8';
						}
					}
				}

				&.descending {
					& .label-overflowed {
						&::before {
							content: '\f0d7';
						}
					}
				}
			}

			&.alignedRight {
				&::after {
					display: none;
				}

				&.label,
				&.label-overflowed {
					&::before {
						color: #fff;
						opacity: 0.8;
						height: 1em;
						width: auto;
						position: relative;
					}
				}

				&.ascending {
					.label,
					.label-overflowed {
						&::before {
							margin: 0 0.5em 0 0;
							font-family: 'Icons';
							content: '\f0d8';
						}
					}
				}

				&.descending {
					.label,
					.label-overflowed {
						&::before {
							margin: 0 0.5em 0 0;
							font-family: 'Icons';
							content: '\f0d7';
						}
					}
				}
			}
		}
	}

	&.ui.table-closed.table-paper-orders tr.nested-row td:first-child:not(.owner-cell) *,
	&.ui.table-closed tr:not(.row-open) .column-product-name *,
	&.ui.table-closed tr:not(.row-open) .column-counter-party *,
	&.ui.table-closed tr:not(.row-open) .column-volume-per-mt {
		color: var(--neutral-200);
	}

	&.ui.table-paper-orders .paper-child-table tbody {
		background: var(--neutral-900);
	}

	&.ui.table-closed tr:not(.row-open) td.closed-cell.owner-cell * {
		color: var(--purple-900);
	}

	&.ui.table-closed tr:not(.row-open) td:not(:first-child),
	&.ui.table-closed tr:not(.row-open):not(.no-entries) td * {
		color: var(--neutral-300);
	}
	
	&.ui.table-closed tr.row-closed:not(.row-open) td.column-volume-per-mt:not(:first-child),
	&.ui.table-closed tr.row-closed:not(.row-open) td.column-volume-per-mt * {
		color: var(--neutral-200);
	}

	&.ui.execution-table.table-closed tr td:not(:first-child) * {
		color: var(--neutral-300);
	}

	&.ui.execution-table.table-closed tr td.price-color-sell * {
		color var(--sell-closed);
	}
	
	&.ui.execution-table.table-closed tr td.price-color-buy * {
		color var(--buy-closed);
	}

	&.ui.execution-table.table-closed tr td:first-child *,
	&.ui.execution-table.table-closed tr td.column-volume-per-mt * {
		color: var(--neutral-200);
	}

	&.ui.table-teams-closed tr td.quote-type-cell,
	&.ui.table-teams-closed tr td.price-color-buy,
	&.ui.table-teams-closed tr td.price-color-sell {
		color: var(--text-color-secondary);
	}

	&.ui.table tbody {
		tr.error {
			&:hover {
				background: transparent !important;
			}

			td {
				color: var(--red-900);
				padding-top: 9px;

				div {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;
				}
			}
		}
	}
`;

export const TableForHeader = styled(Table)`
	height: auto !important;
`;

export const TableHeader = styled(SemanticTable.Header)``;

export const TableBody = styled.tbody``;

export const Footer = styled(SemanticTable.Footer)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const HeaderCell = styled(SemanticTable.HeaderCell)`
	${props =>
		props.$disableSort
			? `
			cursor: default !important;
			
			&:hover {
				background: transparent !important;
				color: var(--text-color-secondary) !important;

				&::before {
					display: none !important;
				}
			}
			`
			: `
				cursor: pointer;
			`}

	${props => (typeof props?.$width === 'number' ? `width: ${props.$width}px !important;` : '')};
	${props => (props?.$width?.default ? `width: ${props.$width.default}px !important;` : '')};
	${media.s`
		${props => (props?.$width?.s ? `width: ${props.$width.s}px !important;` : '')}
	`}
	${media.m`
		${props => (props?.$width?.m ? `width: ${props.$width.m}px !important;` : '')}
	`}
	${media.l`
		${props => (props?.$width?.l ? `width: ${props.$width.l}px !important;` : '')}
	`}
	${media.ll`
		${props => (props?.$width?.ll ? `width: ${props.$width.ll}px !important;` : '')}
	`}
	${media.xl`
		${props => (props?.$width?.xl ? `width: ${props.$width.xl}px !important;` : '')}
	`}
`;

export const Label = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Span = styled.span`
	&.short-label.l-s {
		${media.ll`
			display: none;
		`}
	}
	&.long-label.l-s {
		display: none;
		${media.ll`
			display: inline;
		`}
	}
`;

export const NextPageObserver = styled.div`
	min-height: ${props => (props.$collapsed ? '0px' : '55px')};
`;

export const Spinner = styled(SpinnerIcon)`
	width: 40px;
	height: 40px;

	rect {
		fill: var(--neutral-400);
	}
`;
