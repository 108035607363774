/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Modal } from 'src/components/Modal';
import { ReactComponent as Icon } from 'src/assets/icons/document_icon.svg';
import loaderImage from 'src/assets/icons/loader.png';
import { ButtonsWrapper, ModalHeaderWrapper, ModalTitle, IconWrapper } from 'src/components/Modal';
import { ModalSize } from 'src/constants/modal';
import { TermsMarkdown } from './TermsMarkdown';
import * as Styled from './styled';

export const TermsAndConditionsModal = ({ terms, onAccept, isAccepting, pdfLink }) => {
	const { t } = useTranslation();

	const { ref: bottomRef, inView: isBottom } = useInView({
		fallbackInView: true,
		triggerOnce: true,
	});

	return (
		<Modal
			closeOnDimmerClick={false}
			closeOnEscape={false}
			data-test="terms-and-conditions-modal"
			size={ModalSize.Large}
			isOpen
		>
			<ModalHeaderWrapper>
				<IconWrapper>
					<Icon />
				</IconWrapper>
				<ModalTitle>{t('terms_title')}</ModalTitle>
				<Styled.Subtitle>{t('terms_subtitle')}</Styled.Subtitle>
			</ModalHeaderWrapper>
			<Styled.ModalContent>
				<Styled.Terms>
					<Styled.TextWrapper data-test="text-wrapper">
						{terms ? (
							<>
								<TermsMarkdown terms={terms} />
								<Styled.BottomCheck ref={bottomRef}></Styled.BottomCheck>
							</>
						) : (
							<Styled.LoaderWrapper>
								<img src={loaderImage} alt="" className="spinner" />
							</Styled.LoaderWrapper>
						)}
					</Styled.TextWrapper>
				</Styled.Terms>
				<Styled.DownloadText data-test="terms-and-condition-copy">
					{
						<Trans
							i18nKey={'download_copy_terms_and_condition'}
							components={{
								span: (
									<Styled.PdfLink
										data-test="pdf-link"
										href={pdfLink}
										target="_blank"
										rel="noreferrer"
									/>
								),
							}}
						/>
					}
				</Styled.DownloadText>
			</Styled.ModalContent>
			<ButtonsWrapper>
				<Styled.AcceptButton
					onClick={onAccept}
					disabled={!isBottom || isAccepting}
					loading={isAccepting}
				>
					{t('accept_button')}
				</Styled.AcceptButton>
			</ButtonsWrapper>
		</Modal>
	);
};
