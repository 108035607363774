/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderType } from 'src/constants/contract';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { formatValidityShort } from 'src/_helpers/date';
import {
	tTimeOptions,
	formatTradingSessionWithFullDate,
	mapApiTimeValue,
} from 'src/_helpers/timeRange';
import * as Styled from './styled';

export const OrderPreviewFooter = ({ onSubmit, isSubmitting }) => {
	const { t } = useTranslation();

	const {
		[DrawerContextKeys.createDrawer]: { orderPreviewData, setOrderPreviewData },
	} = useDrawerContext();

	const buttonText =
		orderPreviewData.order_type === OrderType.Buy ? t('submit_bid') : t('submit_offer');

	const validityOption = orderPreviewData.validity_option;
	const validityTime = validityOption ? mapApiTimeValue(validityOption) : null;

	return (
		<Styled.DrawerFooter>
			<Styled.Validity>
				<label>
					{validityOption && validityTime ? t('validity') : t('validity_local_time')}
				</label>
				<div>
					{validityOption
						? validityTime
							? tTimeOptions(t, validityTime.minutes || validityTime.hours * 60)
							: formatTradingSessionWithFullDate(validityOption)
						: formatValidityShort(orderPreviewData.validity)}
				</div>
			</Styled.Validity>
			<Styled.BackButton darker onClick={() => setOrderPreviewData(undefined)}>
				{t('back')}
			</Styled.BackButton>
			<Styled.SubmitButton onClick={onSubmit} loading={isSubmitting}>
				{buttonText}
			</Styled.SubmitButton>
		</Styled.DrawerFooter>
	);
};
