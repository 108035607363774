/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlagNames } from 'src/constants/flags';
import { Flags } from 'src/featureFlags/Flags';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';

export const EditOrderButton = ({
	Component = SecondaryButton,
	orderId,
	environment,
	market,
	recipients,
}) => {
	const { t } = useTranslation();

	const {
		[DrawerContextKeys.createDrawer]: {
			visibility: [
				isCreateOrderVisible,
				{ open: openCreateOrderDrawer, close: closeCreateOrderDrawer },
			],
		},
		[DrawerContextKeys.viewDrawer]: {
			visibility: [isViewOrderVisible, { close: closeViewOrderDrawer }],
			setCurrentOrderInfo,
		},
	} = useDrawerContext();

	const handleButtonClick = () => {
		if (isCreateOrderVisible) {
			closeCreateOrderDrawer();
		}
		if (isViewOrderVisible) {
			closeViewOrderDrawer();
			setCurrentOrderInfo(null);
		}
		openCreateOrderDrawer({ orderId, environment, market, recipients, isEditing: true });
	};

	return (
		<Flags
			authorizedFlags={[FlagNames.EditOrder]}
			renderOn={() => (
				<Component data-test="edit-order-button" onClick={handleButtonClick}>
					{t('edit')}
				</Component>
			)}
		/>
	);
};
