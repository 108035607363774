/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import * as Styled from './styled';

export const IncoPortPill = ({ order }) => {
	const { inco, primary_ports } = order;

	const primaryPortName = primary_ports[0].name;

	return (
		<Styled.IncoPortPill data-test="pinco-port-pill">
			<Styled.Inco>{inco.name}</Styled.Inco>
			<Styled.PortName>
				<OverflowText>{primaryPortName}</OverflowText>
			</Styled.PortName>
		</Styled.IncoPortPill>
	);
};
