/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateRange, parseDateStringAsUTC } from 'src/_helpers/date';
import { splitDateIntoLines } from './splitDateIntoLines';
import * as Styled from '../styled';

export const ShipmentPill = ({ order }) => {
	// useTranslation forces component to rerender when the locale changes
	useTranslation();

	const deliveryDate = formatDateRange({
		startDate: parseDateStringAsUTC(order.delivery_date_from),
		endDate: parseDateStringAsUTC(order.delivery_date_to),
		format: order.delivery_mode,
	});

	const dateLines = splitDateIntoLines(deliveryDate);

	return (
		<Styled.GrayPill data-test="shipment-pill">
			{dateLines.map((line, index) => (
				<span key={index}>{line}</span>
			))}
		</Styled.GrayPill>
	);
};
