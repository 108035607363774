/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import {
	fetchFromExchangeApi,
	map404ToEmptyList,
	map404ToEmptyObject,
	map404ToNull,
	mapFalsyResponseToEmptyArray,
} from './model';
import { sortOrder } from 'src/_helpers/sort';
import { trackAnalytics } from './analytics.api';
import { sendInvitesSchema, createGroupSchema, createTeamSchema } from './model/model.contacts';
import { KYCTypes } from 'src/constants/kyc';

export const sendInvite = async body => {
	try {
		await sendInvitesSchema.validate(body);
		return await fetchFromExchangeApi('contact_invitations', {
			method: 'POST',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const approveContactUser = async ({ userId, status }) => {
	const endpointUrl = !!status
		? `search_contacts/${userId}/approve`
		: `search_contacts/${userId}/unapprove`;

	return await fetchFromExchangeApi(endpointUrl, {
		method: 'POST',
	});
};

export const inviteUserToContacts = async userId => {
	return await fetchFromExchangeApi(`search_contacts/${userId}/invite`, {
		method: 'POST',
	});
};

export const toggleInviteVisibility = async ({ inviteId, hideFlag }) => {
	return await fetchFromExchangeApi(`contact_invitations/${inviteId}/${hideFlag}`, {
		method: 'POST',
	});
};

export const acceptInvite = async ({ inviteId }) => {
	return await fetchFromExchangeApi(`contact_invitations/${inviteId}/accept`, {
		method: 'POST',
	});
};

export const getInvites = async query => {
	return fetchFromExchangeApi('contact_invitations', {
		query: {
			_sort: 'created_at',
			_order: sortOrder.desc,
			...query,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getPendingInvitationForUser = async inviteeId => {
	return fetchFromExchangeApi('contact_invitations', {
		query: {
			invitee_id: inviteeId,
			type: KYCTypes.OUTBOUND,
			limit: 1,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getSuggestedContactsList = async ({ exclude_user_id, limit = 8 }) => {
	return fetchFromExchangeApi('contact_suggestions', {
		query: {
			_limit: limit,
			exclude_user_id,
		},
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const getGroupsCount = async () => {
	return fetchFromExchangeApi('contact_groups', {
		query: { count: true, exclude_deactivated_contacts: true },
	});
};

export const getTeamsCount = async () => {
	const result = await fetchFromExchangeApi('teams', {
		query: { count: true, exclude_deactivated_contacts: true },
	});

	return result.meta;
};

export const getInvitationsCount = async () => {
	return fetchFromExchangeApi('contact_invitations', {
		query: { count: true, type: 'inbound', exclude_deactivated: true },
	});
};

export const listGroups = async query => {
	const [items, totalCount] = await Promise.all([
		getGroups(query),
		fetchFromExchangeApi('contact_groups', {
			query: { ...query, count: true },
		}),
	]);

	return {
		items,
		totalCount: totalCount.count,
	};
};

export const getGroups = async query => {
	return fetchFromExchangeApi('contact_groups', {
		query: {
			...query,
			exclude_deactivated_contacts: true,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getTeams = async query => {
	return fetchFromExchangeApi('teams', {
		query: {
			...query,
			exclude_deactivated_contacts: true,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getGroupDetails = ({ groupId, ...query }) => {
	return fetchFromExchangeApi(`contact_groups/${groupId}`, {
		query: {
			...query,
			exclude_deactivated_contacts: true,
		},
		mapStatusToResult: map404ToNull,
	});
};

export const createGroup = async ({ body }) => {
	try {
		await createGroupSchema.validate(body);
		return await fetchFromExchangeApi('contact_groups', {
			method: 'POST',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const updateGroup = async ({ body: { id, ...body } }) => {
	try {
		await createGroupSchema.validate(body);
		return await fetchFromExchangeApi(`contact_groups/${id}`, {
			method: 'POST',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const removeGroup = async ({ groupId }) => {
	try {
		return await fetchFromExchangeApi(`contact_groups/${groupId}`, {
			method: 'DELETE',
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const searchUsers = async query => {
	return fetchFromExchangeApi('search_contacts', {
		query,
		mapStatusToResult: status => {
			if (status === 404) {
				return {
					searched_contacts: [],
				};
			}
		},
	});
};

export const getMyOrdersCompanies = async query => {
	return fetchFromExchangeApi('search_contact_companies', {
		query: {
			...query,
			_limit: 150,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getMyContacts = async query => {
	return fetchFromExchangeApi('my_contacts', {
		query: {
			...query,
			exclude_deactivated: true,
		},
		mapStatusToResult: map404ToEmptyList,
	}).then(mapFalsyResponseToEmptyArray);
};

export const getMyContactsCount = async () => {
	return await getMyContacts({ count: true });
};

export const getUserDetails = async id => {
	return fetchFromExchangeApi(`search_contacts/${id}`, {
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const listMyContacts = async query => {
	const [, items, totalCount] = await Promise.all([
		trackAnalytics(),
		getMyContacts({
			_sort: 'user.name',
			_order: sortOrder.asc,
			_limit: 500,
			...query,
		}),
		getMyContacts({ ...query, count: true }),
	]);

	return {
		items,
		totalCount: totalCount.count,
	};
};

export const removeUserFromContacts = async contactId => {
	return await fetchFromExchangeApi(`my_contacts/${contactId}/delete`, {
		method: 'POST',
	});
};

export const notificationsCount = async query => {
	const result = await fetchFromExchangeApi('notifications_v2', {
		query: {
			...query,
			count: true,
		},
	});

	return result.count;
};

export const listMyNotifications = async query => {
	const result = await fetchFromExchangeApi('notifications_v2', {
		query: { ...query },
		mapStatusToResult: map404ToEmptyObject,
	});

	return {
		items: result.data,
		cursor: result.meta?.next_page_cursor || '',
	};
};

export const markNotificationsAsRead = async ids => {
	const result = await fetchFromExchangeApi('notifications', {
		method: 'POST',
		query: {
			action: 'mark_as_read',
		},
		body: {
			notification_ids: ids,
		},
	});

	if (result.failed_to_update?.length) {
		throw Error('Could not mark some notifications as read');
	}
	return result;
};

export const markAllNotificationsAsRead = async () => {
	const result = await fetchFromExchangeApi('notifications', {
		method: 'POST',
		query: {
			action: 'mark_all_as_read',
		},
		mapStatusToResult: map404ToNull,
	});

	if (result?.failed_to_update?.length) {
		throw Error('Could not mark some notifications as read');
	}

	return result;
};

export const getTraderStatistics = ({ userId }) => {
	return fetchFromExchangeApi(`trader_statistics/${userId}`, {
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const getMyContactIds = async () => {
	return fetchFromExchangeApi('my_contacts_ids', {
		mapStatusToResult: map404ToEmptyList,
	}).then(mapFalsyResponseToEmptyArray);
};

export const listMyTeams = async query => {
	return await fetchFromExchangeApi('teams', {
		query,
		mapStatusToResult: map404ToEmptyList,
	}).then(res => res.data);
};

export const createTeam = async ({ body }) => {
	try {
		await createTeamSchema.validate(body);
		return await fetchFromExchangeApi('teams', {
			method: 'POST',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const getTeamDetails = ({ teamId, ...query }) => {
	return fetchFromExchangeApi(`teams/${teamId}`, {
		query: {
			...query,
			exclude_deactivated_contacts: true,
		},
		mapStatusToResult: map404ToNull,
	});
};

export const removeTeam = async ({ teamId }) => {
	try {
		return await fetchFromExchangeApi(`teams/${teamId}`, {
			method: 'DELETE',
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const updateTeam = async ({ body: { id, ...body } }) => {
	try {
		await createTeamSchema.validate(body);
		return await fetchFromExchangeApi(`teams/${id}`, {
			method: 'PUT',
			body,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const acceptTeamInvitation = async ({ teamId }) => {
	try {
		return await fetchFromExchangeApi(`teams/${teamId}/respond_to_invitation`, {
			method: 'POST',
			body: {
				decision: 'approve',
			},
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};
