/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip/Tooltip';
import * as Styled from './styled';

export const UserApprovedBadge = () => {
	const { t } = useTranslation();

	return (
		<Tooltip placement="bottom">
			<TooltipTrigger>
				<Styled.ApprovedContactBadge data-test="approved-counterparty-badge" />
			</TooltipTrigger>
			<TooltipContent>{t('approved_counterparty')}</TooltipContent>
		</Tooltip>
	);
};
