/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { h400 } from 'src/components/Typography/fonts';

export const UsersInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	${h400};
	margin-left: auto;
	height: 42px;
`;

export const UsersList = styled.div`
	cursor: pointer;
	display: flex;
`;

export const UserItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	margin-left: -10px;
`;
