/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const InfoItem = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	margin-right: 16px;
`;
