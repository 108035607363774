/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeaderWrapper, ModalTitle, ModalSubTitle, CancelButton } from 'src/components/Modal';
import { ReactComponent as Icon } from 'src/assets/icons/invite_user.svg';
import { H200 } from 'src/components/Typography';
import { TextInput } from 'src/designSystem/Form/TextInput/TextInput';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import * as Styled from './styled';

export const InviteViaEmailModalContent = React.forwardRef(
	({ onInvitationSend, isLoading, error, close }, ref) => {
		const { t } = useTranslation();

		return (
			<>
				<ModalHeaderWrapper>
					<Styled.IconWrapper>
						<Icon />
					</Styled.IconWrapper>
					<ModalTitle>{t('invite_via_email')}</ModalTitle>
				</ModalHeaderWrapper>
				<Styled.ModalContent>
					<ModalSubTitle>{t('invite_via_email_instructions')}</ModalSubTitle>
					<Styled.InputLabel>{t('email_address')}</Styled.InputLabel>
					<Styled.EmailInputWrapper>
						<TextInput placeholder={t('type_email')} ref={ref} />
					</Styled.EmailInputWrapper>
					<Styled.ErrorMessage>
						{error && (
							<>
								<Styled.ErrorIcon />
								<H200 data-test="invitation-error">{t(error)}</H200>
							</>
						)}
					</Styled.ErrorMessage>
				</Styled.ModalContent>
				<Styled.ModalButtonsWrapper>
					<CancelButton onClick={close} />
					<PrimaryButton
						onClick={onInvitationSend}
						data-test="submit-invitation"
						loading={isLoading}
					>
						{t('add_to_contacts')}
					</PrimaryButton>
				</Styled.ModalButtonsWrapper>
			</>
		);
	}
);
