/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { OrderType } from 'src/constants/contract';

export const getCounterType = (counter, userId) => {
	if (counter.order_user_id === userId) {
		return counter.order_type;
	}

	return counter.order_type === OrderType.Buy ? OrderType.Sell : OrderType.Buy;
};
