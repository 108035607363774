/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { usePaperOrderContext } from 'src/components/CreateOrderDrawer/paper/CreatePaperOrderDrawerContent';
import { QuoteType, PaperInstruments, SpreadPriceFormat } from 'src/constants/contract';
import { FormattedCurrencyUnit } from './SpreadPriceField';
import * as Styled from './styled';

const FirstLegPriceField = ({ path, t }) => {
	const { selectedPreset: selectedProductPresetValue } = usePaperOrderContext();
	const { orderTypeValue } = useWatchPaperFields(path);

	const selectedPresetSpreadPriceFormat = selectedProductPresetValue.instruments.find(
		i => i.type === PaperInstruments.Spread
	).spread_price_format;

	const isFieldRequired = orderTypeValue === QuoteType.Firm;
	const decimalScale = selectedPresetSpreadPriceFormat === SpreadPriceFormat.Spread ? 2 : 3;

	return (
		<Controller
			name={toPath(path, 'firstLegPrice')}
			mandatory={isFieldRequired}
			render={({ field, fieldState: { error } }) => (
				<Styled.FirstLegContainer>
					<MaterialNumberInput
						error={!!error}
						data-test="firstLegPriceCurrencyUnit"
						id={field.name}
						label={t('first_leg_price')}
						required={isFieldRequired}
						allowNegative
						parameters={<FormattedCurrencyUnit path={path} t={t} />}
						decimalScale={decimalScale}
						{...field}
					/>
				</Styled.FirstLegContainer>
			)}
		/>
	);
};

export const firstLegPriceField = (path, t) => <FirstLegPriceField path={path} t={t} />;
