/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const mapGrades = (t, data, origin = []) => {
	let grades = [];
	let specs = {};
	const availableGrades = !!origin.length
		? data.filter(grade => {
				if (!origin.length) {
					return grade.is_custom;
				}

				if (grade.origin_country_ids.length) {
					return grade.origin_country_ids.some(o => origin.includes(o));
				}

				return true;
		  })
		: data;

	availableGrades.forEach(item => {
		grades.push({
			text: item.is_custom ? t('custom') : item.name,
			key: item._key,
			value: item._key,
			is_custom: !!item.is_custom,
			origins: item.origin_country_ids,
		});

		specs[item._key] = item.gradespecs.map(gradespec => {
			// we just keep the value of the gradespec and use the spec itself
			return {
				value: gradespec.value,
				...gradespec.spec,
			};
		});
	});

	grades.sort((a, b) => {
		if (a.is_custom) {
			return 1;
		}
		if (b.is_custom) {
			return -1;
		}

		return a.text.localeCompare(b.text);
	});

	return { grades, specs };
};
