/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { TradeStepStatus } from 'src/constants/physicalTrade';
import { ReactComponent as CheckCircle } from 'src/assets/icons/check_alternative.svg';
import * as Styled from './styled';

const circleStatus = {
	[TradeStepStatus.NOT_STARTED]: <Styled.EmptyCircle data-test="status-not-started" />,
	[TradeStepStatus.COMPLETED_BY_COUNTERPARTY]: (
		<Styled.CompletedByCounterpartyCircle data-test="status-completed-by-counterparty">
			<CheckCircle />
		</Styled.CompletedByCounterpartyCircle>
	),
	[TradeStepStatus.COMPLETED_BY_ME]: (
		<Styled.CompletedByCounterpartyCircle data-test="status-completed-by-user">
			<CheckCircle />
		</Styled.CompletedByCounterpartyCircle>
	),
	[TradeStepStatus.COMPLETED_BY_BOTH]: (
		<Styled.CompletedCircle data-test="status-completed">
			<CheckCircle />
		</Styled.CompletedCircle>
	),
};

export const StepProgress = ({ progress }) => {
	return <Styled.CircleContainer>{circleStatus[progress]}</Styled.CircleContainer>;
};
