/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { H400 } from 'src/components/Typography';
import { countriesWithoutFlags } from 'src/constants/product';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip/Tooltip';
import * as Styled from './styled';

export const Origins = ({ originsList }) => {
	const { t } = useTranslation();
	const originNames = originsList
		.map(origin => origin?.name)
		.filter(Boolean)
		.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

	const showFlag =
		originsList.length === 1 && !countriesWithoutFlags.includes(originsList[0]._key);

	return (
		<Styled.BoxPill>
			{originsList.length > 1 ? (
				<Tooltip>
					<OverflowText>
						<TooltipTrigger>
							<H400 as="span" data-test="shipment-box-origin">
								{t('multiple_origin')}
							</H400>
						</TooltipTrigger>
					</OverflowText>
					<TooltipContent>{originNames.join(', ')}</TooltipContent>
				</Tooltip>
			) : (
				<>
					{showFlag && (
						<>
							<i
								className={`${originsList[0]._key
									.toLowerCase()
									.substring(0, 2)} flag`}
							/>{' '}
						</>
					)}
					<OverflowText>
						<H400 as="span" data-test="shipment-box-origin">
							{originsList[0].name}
						</H400>
					</OverflowText>
				</>
			)}
		</Styled.BoxPill>
	);
};
