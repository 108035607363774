/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';
import { h200 } from 'src/components/Typography';
import { FieldContainer } from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { StrokeButton } from 'src/components/Buttons/StrokeButton';
import { ReactComponent as DeleteIconSVG } from 'src/assets/icons/delete.svg';

export const PortSection = styled(FieldContainer)`
	> div:first-of-type {
		grid-row: 1 / span 2;
	}

	.collapsed & .optional {
		display: none;
	}
`;

export const PortLabel = styled.div`
	${h200}
	color: var(--neutral-100);
	margin-bottom: 3px;
`;

export const Button = styled(StrokeButton)`
	border-width: 1px;
	height: 36px;

	${media.xl`
		height: 40px;
	`}

	&:hover,
	&:focus {
		border-color: var(--neutral-200);
	}
`;

export const DeleteIconWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 36px;

	${media.xl`
		height: 40px;
	`}
`;

export const DeleteIcon = styled(DeleteIconSVG)`
	cursor: pointer;
	color: var(--neutral-500);

	&:hover {
		color: var(--neutral-200);
	}
`;
