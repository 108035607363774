/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { OrderFile } from '../AdditionalInformationSection/OrderFile/OrderFile';
import * as Styled from '../../styled';

export const DocumentsSection = ({ order }) => {
	const { t } = useTranslation();

	const files = [...order.files].sort((a, b) => {
		const now = new Date().toISOString();

		return (a.updated_at || now) > (b.updated_at || now) ? -1 : 1;
	});

	return (
		<Styled.SectionContainer data-test="documents-section">
			<Styled.SectionTitle>{t('documents')}</Styled.SectionTitle>
			<Styled.FilesContainer>
				{files.map(file => (
					<OrderFile
						file={file}
						key={`document-section-file-${file._key}`}
						dataTest="additional-information-document"
						className={clsx({ changed: file.changed })}
						withOwner
					/>
				))}
			</Styled.FilesContainer>
		</Styled.SectionContainer>
	);
};
