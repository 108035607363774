/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { OverflowText } from './OverflowText';
import * as Styled from './styled';

export const OverflowTextWithLabel = ({ text, label: LabelComponent, separate = true }) => {
	if (!LabelComponent) {
		return <OverflowText>{text}</OverflowText>;
	}

	return (
		<Styled.OverflowTextWithLabelWrapper className={clsx({ separated: separate })}>
			<OverflowText>{text}</OverflowText>
			<LabelComponent />
		</Styled.OverflowTextWithLabelWrapper>
	);
};
