/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { toPath } from 'src/_helpers';
import { Controller } from 'react-hook-form';
import { useCalendarSpreadSection } from '../../useCalendarSpreadSection';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { OrderType } from 'src/constants/contract';

const FirstLegQuote = ({ path, t }) => {
	const { firstLegOrderType } = useCalendarSpreadSection({ path });

	return (
		<Controller
			mandatory
			name={toPath(path, 'type')}
			defaultValue={OrderType.Buy}
			render={({ field, fieldState: { error } }) => (
				<MaterialSelectInput
					required
					error={!!error}
					data-test={field.name}
					label={t('first_leg_quote')}
					options={firstLegOrderType.options}
					disabled={!firstLegOrderType.ready}
					{...field}
				/>
			)}
		/>
	);
};

export const firstLegQuote = (path, t) => <FirstLegQuote path={path} t={t} />;
