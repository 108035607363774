/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { FieldContainer as BaseFieldContainer } from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { SectionMeta as BaseSectionMeta } from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';

export const FieldContainer = styled(BaseFieldContainer)`
	.wide_price {
		div + label {
			max-width: initial !important;
			background: transparent;
		}

		&.input-filled-controlled > label,
		&.input-filled > label,
		div:focus-within ~ label {
			background: var(--neutral-900);
		}
	}
`;

export const FieldsRow = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

export const RemoveButton = styled.button`
	border: 0;
	background: transparent;
	height: 24px;
	color: var(--neutral-300);
	display: flex;
	max-width: fit-content;
	gap: 10px;
	justify-content: space-between;
	padding-left: 0;
	margin-top: 10px;
	margin-bottom: 20px;
	align-items: center;

	&:hover {
		color: var(--white);
	}

	&:last-of-type {
		margin-bottom: 0;
	}
`;

export const WideField = styled.div`
	grid-column: span 2;

	& .ui.active.selection.dropdown.upward .menu {
		margin-bottom: 12px;
	}
`;

export const CreateOrderDrawerDivider = styled.div`
	width: 100%;
	height: 1px;
	background: var(--neutral-1000);
	margin: 24px 0;
`;

export const CurrencyUnitSeparator = styled.span`
	background: var(--neutral-600);
	width: 2px;
	height: 22px;
	margin-right: 8px;
	border-radius: 50%;
`;

export const CalendarWrapper = styled.div`
	&:nth-child(3n) {
		.date-selector-popup {
			right: 0;

			&::before {
				right: 16px;
				left: unset;
			}
		}
	}
	&:nth-child(3n + 1) {
		.date-selector-popup {
			left: 0;

			&::before {
				left: 16px;
				right: unset;
			}
		}
	}
`;

export const TotalQuantityWrapper = styled.div`
	margin-top: 22px;
`;

export const FieldsSeparator = styled.div`
	grid-column: 1 / span 3;
	background: var(--neutral-1000);
	height: 2px;
	margin: 8px 0;
`;

export const SectionMeta = styled(BaseSectionMeta)`
	margin-bottom: 12px;
`;
