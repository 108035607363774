/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const InviteType = {
	INBOUND: 'inbound',
	OUTBOUND: 'outbound',
};

export function tInviteType(t, inviteType) {
	switch (inviteType) {
		case InviteType.INBOUND:
			return t('inbound');
		case InviteType.OUTBOUND:
			return t('outbound');
		default:
			return '';
	}
}

export const InviteStatus = {
	HIDDEN: 'hidden',
	ACTIVE: 'active',
};

export const InviteTabs = {
	INBOUND: 'inbound',
	OUTBOUND: 'outbound',
	ARCHIVED: 'archived',
};

export const GroupModalMode = {
	CREATE: 'create',
	EDIT_META: 'edit_meta',
	EDIT_USERS: 'edit_users',
};

export const VisibilityStatus = {
	ONLINE: 'online',
	OFFLINE: 'offline',
};

export const ContactType = {
	STRING: 'string',
	CONTACT: 'contact',
};

export const getUserStatusDotColor = visibility => {
	if (visibility === VisibilityStatus.ONLINE) {
		return 'var(--green-400)';
	}

	return 'var(--red-400)';
};

export const UserTeamStatus = {
	MEMBER: 'member',
	INVITED: 'invited',
};
