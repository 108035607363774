/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { saveState, storageKey } from 'src/_helpers/searchState';

export const PersistStoreState = () => {
	const searchState = useSelector(state => state.search);
	const chartState = useSelector(state => state.chart);

	useEffect(() => {
		saveState(searchState, storageKey.search);
	}, [searchState]);

	useEffect(() => {
		if (chartState) {
			saveState(chartState, storageKey.chart);
		}
	}, [chartState]);

	return null;
};
