/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { fetchFromApi, ServicePrefix, map404ToEmptyObject, map404ToEmptyList } from './model';
import { getInitials } from '../helpers/getInitials';
import {
	ChatLastMessageImage,
	ConversationType,
	PictureI18nKey,
	SupportConversationTitle,
	TopicType,
} from '../constants';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';

export const getConversationsList = async query => {
	const result = await fetchFromApi('conversations', {
		method: 'GET',
		query,
		urlServicePrefix: ServicePrefix.Chat,
		mapStatusToResult: map404ToEmptyObject,
	});

	return {
		items: result.conversations,
		cursor: result.meta?.next_page_cursor || '',
	};
};

export const getKYCAndExecutionAvailability = async () => {
	const executionResponse = await getConversationTopicsCount({ type: TopicType.Execution });
	const kycResponse = await getConversationTopicsCount({ type: TopicType.KYC });

	return {
		[ConversationType.Execution]: executionResponse.count > 0,
		[ConversationType.KYC]: kycResponse.count > 0,
	};
};

export const getConversationTopicsCount = async ({ tagId, type }) => {
	const query = { type };
	if (tagId) {
		query['tags.id'] = tagId;
	}
	return fetchFromApi('conversations/count', {
		method: 'GET',
		query,
		urlServicePrefix: ServicePrefix.Chat,
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const useConversationTopicsCountQuery = (query, config) => {
	const userId = useSelector(getUserId);

	return useQuery(
		['conversation-topics-count', query, userId],
		() => getConversationTopicsCount(query),
		config
	);
};

export const getConversation = async conversationId => {
	return fetchFromApi(`conversations/${conversationId}`, {
		method: 'GET',
		urlServicePrefix: ServicePrefix.Chat,
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const getUnreadConversationsCount = async () => {
	return fetchFromApi('info/count', {
		method: 'GET',
		urlServicePrefix: ServicePrefix.Chat,
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const getConversationParticipants = async ({ groupId }) => {
	return fetchFromApi(`conversations/${groupId}/members`, {
		method: 'GET',
		urlServicePrefix: ServicePrefix.Chat,
		mapStatusToResult: map404ToEmptyList,
	});
};

export const useConversationParticipantsQuery = (query, config) => {
	const userId = useSelector(getUserId);

	return useQuery(
		['conversation-participants', query, userId],
		() => getConversationParticipants(query),
		config
	);
};

export const getExistingConversationWithUser = async userId => {
	return fetchFromApi(`conversations?member=${userId}&type=${ConversationType.Instant}`, {
		method: 'GET',
		urlServicePrefix: ServicePrefix.Chat,
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const createNewConversation = async body => {
	return await fetchFromApi('conversations', {
		method: 'POST',
		urlServicePrefix: ServicePrefix.Chat,
		body,
	});
};

export const mapRecentConversations = (responseData = [], currentUserId) => {
	return responseData.map((record, index) => mapSingleConversation(record, index, currentUserId));
};

export const mapSingleConversation = (conversation, index, currentUserId) => {
	const user = conversation.participants.find(({ id }) => id !== currentUserId);

	const isSupport =
		conversation.type === ConversationType.Instant &&
		conversation.title === SupportConversationTitle;

	return {
		id: conversation.id,
		title: `${user.first_name} ${user.last_name}`.trim(),
		unreadCount: conversation.unread_count,
		lastReadMessageId: conversation.last_read,
		lastMessageId: conversation.last_message || 0,
		user: mapUserData(user, isSupport),
		type: conversation.type,
		participants: conversation.participants.reduce((dict, participant) => {
			return {
				...dict,
				[participant.id]: participant,
			};
		}, {}),
		lastMessageText: conversation.message?.is_image
			? ChatLastMessageImage
			: conversation.message?.text || '',
		lastMessageDate: conversation.message?.created_at || '',
	};
};

export const mapUserData = (user, isSupport = false) => ({
	id: user.id,
	initials: isSupport ? 'S' : getInitials(user.first_name, user.last_name),
	visibility: isSupport ? null : user.visibility,
	background: user.avatar_color,
	name: `${user.first_name} ${user.last_name}`,
	company: user.company?.name || '',
	status: user.status,
});

export const mapTopics = (data = [], currentUserId) => {
	return data.map((record, index) => mapSingleTopic(record, currentUserId));
};

export const mapSingleTopic = (topic, currentUserId) => {
	const users = topic.participants.reduce((acc, user) => {
		acc[user.id] = mapUserData(user);

		return acc;
	}, {});

	return {
		id: topic.id,
		title: topic.title,
		unreadCount: topic.unread_count,
		type: topic.type,
		user: users[currentUserId],
		participants: topic.participants.reduce((dict, participant) => {
			return {
				...dict,
				[participant.id]: participant,
			};
		}, {}),
		lastMessageText: topic.message.text || '',
		lastMessageTranslationKey: topic.message.is_image
			? PictureI18nKey
			: topic.message.text_translation_key,
		lastMessageDate: topic.message.created_at,
		lastMessageAuthor: topic.message.created_by ? users[topic.message.created_by] : null,
		lastReadMessageId: topic.last_read,
		lastMessageId: topic.last_message || 0,
	};
};
