/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { h600 } from 'src/components/Typography';
import { media } from 'src/_helpers';

export const CalendarWrapper = styled.div`
	&:nth-child(3n) {
		.date-selector-popup {
			right: 0;

			&::before {
				right: 16px;
				left: unset;
			}
		}
	}
	&:nth-child(3n + 1) {
		.date-selector-popup {
			left: 0;

			&::before {
				left: 16px;
				right: unset;
			}
		}
	}
`;

export const Header = styled.div`
	height: 36px;
	display: flex;
	align-items: center;
	${h600}
	color: var(--text-color-default);
	grid-column: span 3;

	${media.xl`
		height: 40px;
	`}
`;
