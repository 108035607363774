/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { OrderCreatorBox } from './OrderCreatorBox/OrderCreatorBox';
import { ShipmentBox } from './ShipmentBox/ShipmentBox';
import * as Styled from './styled';

export const OrderCreatorShipmentSection = ({ order, changes, skipShipmentType = false }) => {
	const isCounter = !!changes;

	return (
		<Styled.OrderCreatorShipmentSectionContainer data-test="order-creator-shipment-section">
			<ShipmentBox order={order} skipShipmentType={skipShipmentType} changes={changes} />
			<OrderCreatorBox order={order} isCounter={isCounter} changes={changes} />
		</Styled.OrderCreatorShipmentSectionContainer>
	);
};
