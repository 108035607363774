/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import { useDownloadTradesButton } from './useDownloadTradesButton';
import * as Styled from './styled';

export const DownloadTradesButton = ({ tradeId, tradeStep, market }) => {
	const { t } = useTranslation();

	const { downloadTrades, sendViaEmail } = useDownloadTradesButton({
		tradeStep,
		market,
	});

	return (
		<Styled.DownloadDropdown
			direction="left"
			icon={null}
			trigger={
				<Styled.DownloadButton data-test={'download-dropdown-button'}>
					{t('download')}
					<div>
						<Styled.DownloadEllipsisIcon />
					</div>
				</Styled.DownloadButton>
			}
		>
			<Dropdown.Menu>
				<Dropdown.Item
					text={t('download_csv')}
					onClick={() => downloadTrades({ tradeId })}
				/>
				<Dropdown.Item
					text={t('send_via_email')}
					onClick={() => sendViaEmail({ tradeId })}
				/>
			</Dropdown.Menu>
		</Styled.DownloadDropdown>
	);
};
