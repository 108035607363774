/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { H600 } from 'src/components/Typography';
import { toPath } from 'src/_helpers';
import { PaperInstruments } from 'src/constants/contract';
import { useWatchPaperCounterFields } from 'src/components/ViewOrderDrawer/CounterForm/helpers/paper/useWatchPaperCounterFields';
import {
	SectionMeta,
	SectionWrapper,
} from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { OutrightRow } from './outright/OutrightRow';
import { CalendarSpreadInfo } from './spread/CalendarSpreadInfo';
import { CalendarSpreadForm } from './spread/CalendarSpreadForm';
import { Divider } from 'src/components/ViewOrderDrawer/styled';
import * as Styled from 'src/components/CreateOrderDrawer/paper/sections/ContractAndPricingSection/styled';

export const ContractAndPricingSection = ({ path = 'contractPricing', lastCounter }) => {
	const { t } = useTranslation();

	const { instrumentValue } = useWatchPaperCounterFields();

	const commonProps = {
		path: toPath(path, 0),
		lastCounter,
	};

	return (
		<SectionWrapper>
			{instrumentValue === PaperInstruments.Spread ? (
				<>
					<Divider $topSpacing={8} $bottomSpacing={24} />
					<CalendarSpreadForm {...commonProps} />
					<Divider $topSpacing={24} />
					<CalendarSpreadInfo {...commonProps} />
				</>
			) : (
				<>
					<SectionMeta>
						<H600>{t('quote')}</H600>
					</SectionMeta>
					<Styled.FieldsRow>
						<OutrightRow {...commonProps} />
					</Styled.FieldsRow>
				</>
			)}
		</SectionWrapper>
	);
};
