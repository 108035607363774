/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useContext } from 'react';
import { Link as ReactRouterLink, NavLink } from 'react-router-dom';
import { GitVersionContext } from 'src/_helpers/useGitVersion';

export const LinkTypes = {
	LINK: 'link',
	NAVLINK: 'navLink',
};

export const Link = ({ children, type = LinkTypes.LINK, ...props }) => {
	const { isDifferentVersion } = useContext(GitVersionContext);

	if (type === LinkTypes.LINK) {
		return (
			<ReactRouterLink {...props} reloadDocument={isDifferentVersion}>
				{children}
			</ReactRouterLink>
		);
	} else {
		return (
			<NavLink {...props} reloadDocument={isDifferentVersion}>
				{children}
			</NavLink>
		);
	}
};
