/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import loadable from '@loadable/component';

export const retry = (fn, options = {}) => {
	const { retries, interval } = { retries: 2, interval: 1000, ...options };
	return new Promise((resolve, reject) => {
		fn()
			.then((...p) => {
				resolve(...p);
			})
			.catch(error => {
				setTimeout(() => {
					console.warn(`retry:${retries}`);
					if (retries <= 1) {
						if (error?.name === 'ChunkLoadError') {
							window?.location?.reload(true);
						}

						reject(error);
						return;
					}

					retry(fn, { retries: retries - 1, interval }).then(resolve, reject);
				}, interval);
			});
	});
};

export const loadableRetry = (fn, options) => loadable(() => retry(fn, options), options);
