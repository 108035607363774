/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { useTogglable } from 'src/_helpers/useTogglable';
import { useCompleteStepMutation } from '../CompleteStepModal/CompleteStepModal';
import { useShowCongratulations } from '../CongratulationsModal/CongratulationsModal';
import { TradeActions } from 'src/constants/tradeStatus';
import { useTradeLocation } from 'src/_helpers/useTradeLocation';
import {
	Modal,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	ModalSubTitle,
	CancelButton,
} from 'src/components/Modal';
import { ModalSize, ModalType } from 'src/constants/modal';

export const CloseTradeModal = ({ trigger }) => {
	const { t } = useTranslation();
	const [isOpen, { open, close }] = useTogglable();
	const { tradeId } = useTradeLocation();
	const { open: showCongratulations } = useShowCongratulations();

	const { isLoading, mutate } = useCompleteStepMutation({
		tradeId,
		onSuccess: () => {
			close();
			showCongratulations();
		},
	});

	return (
		<Modal
			data-test="close-trade"
			size={ModalSize.Tiny}
			open={open}
			close={close}
			isOpen={isOpen}
			trigger={trigger}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{t('close_the_trade')}</ModalTitle>
				<ModalSubTitle style={{ marginBottom: '40px' }}>
					{t('would_you_like_to_close_this_trade?')}
				</ModalSubTitle>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<PrimaryButton
					loading={isLoading}
					onClick={() => mutate({ tradeId, action: TradeActions.Close })}
				>
					{t('close')}
				</PrimaryButton>
			</ButtonsWrapper>
		</Modal>
	);
};
