/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderType, PaperInstruments } from 'src/constants/contract';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { formatValidityShort } from 'src/_helpers/date';
import {
	formatTradingSessionWithFullDate,
	tTimeOptions,
	mapApiTimeValue,
} from 'src/_helpers/timeRange';
import { useFormContext, useWatch } from 'react-hook-form';
import { mapDataToApi } from '../../paper/model';
import * as Styled from './styled';

export const PaperOrderPreviewFooter = ({ onSubmit, mutate, isSubmitting }) => {
	const { t } = useTranslation();

	const {
		[DrawerContextKeys.createDrawer]: {
			orderPreviewData,
			setOrderPreviewData,
			orderPreviewIndex,
			setOrderPreviewIndex,
		},
	} = useDrawerContext();

	const ordersCount = useWatch({ name: 'contractPricing' })?.length || 1;

	const getButtonText = () => {
		if (orderPreviewIndex === 0 && ordersCount === 1) {
			if (orderPreviewData[orderPreviewIndex].instrument === PaperInstruments.Spread) {
				return t('submit_spread');
			}

			return orderPreviewData[orderPreviewIndex].order_type === OrderType.Buy
				? t('submit_bid')
				: t('submit_offer');
		}

		return t('confirm') + ' ' + (orderPreviewIndex + 1) + '/' + ordersCount;
	};

	const { getValues } = useFormContext();

	const orderPreviewHandler = () => {
		if (orderPreviewIndex === 0 && !orderPreviewData) {
			onSubmit();
			return;
		}

		if (orderPreviewIndex + 1 < ordersCount) {
			setOrderPreviewIndex(p => p + 1);
		} else {
			const orders = mapDataToApi(getValues());
			mutate({ orders });
		}
	};

	const validityOption = orderPreviewData[orderPreviewIndex].validity_option;

	const validityTime = validityOption ? mapApiTimeValue(validityOption) : null;

	return (
		<Styled.DrawerFooter>
			<Styled.Validity>
				<label>
					{validityOption && validityTime ? t('validity') : t('validity_local_time')}
				</label>
				<div>
					{validityOption
						? validityTime
							? tTimeOptions(t, validityTime.minutes || validityTime.hours * 60)
							: formatTradingSessionWithFullDate(validityOption)
						: formatValidityShort(orderPreviewData[orderPreviewIndex].validity)}
				</div>
			</Styled.Validity>
			<Styled.BackButton
				darker
				onClick={() => {
					setOrderPreviewIndex(0);
					setOrderPreviewData(undefined);
				}}
			>
				{t('back')}
			</Styled.BackButton>
			<Styled.SubmitButton onClick={orderPreviewHandler} loading={isSubmitting}>
				{getButtonText()}
			</Styled.SubmitButton>
		</Styled.DrawerFooter>
	);
};
