/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState } from 'react';
import { createPhysicalOrderSchema } from './model';
import {
	DrawerPriceType,
	Environment,
	PriceIncrementType,
	isBasisPrice,
} from 'src/constants/contract';
import { isNil } from 'src/_helpers';

export const usePhysicalProgressBar = formMethods => {
	const [progress, setProgress] = useState(0);

	formMethods.watch(fields => {
		const requiredFields = Object.keys(createPhysicalOrderSchema.fields).filter(
			fieldName => createPhysicalOrderSchema.fields[fieldName]?._exclusive?.required
		);

		let allRequiredFields = requiredFields.length;
		let filledFields = Object.keys(fields).reduce((sum, fieldName) => {
			// can't use the regular rule for the validity, because it's an object
			if (fieldName === 'validity') {
				return fields[fieldName].localDate ? sum + 1 : sum;
			}

			if (!requiredFields.includes(fieldName) || !fields[fieldName]) {
				return sum;
			}

			return sum + 1;
		}, 0);

		/** CUSTOM GRADES */
		if (fields.isCustomGrade) {
			allRequiredFields += 1;
			if (fields.customSpecs && Object.values(fields.customSpecs).some(val => val)) {
				filledFields += 1;
			}
		}

		/** PORTS */
		if (fields.ports?.length > 1) {
			allRequiredFields += fields.ports.length - 1;
			const filledPortNames = fields.ports.map(port => port.name).filter(name => name).length;
			filledFields += filledPortNames - 1;
		}

		/** FUTURES MONTH */
		if (isBasisPrice(fields.priceType)) {
			allRequiredFields += 1;
			if (fields.futuresMonth) {
				filledFields += 1;
			}
		}

		/** PRICE */
		if (fields.priceType !== DrawerPriceType.NoPrice) {
			allRequiredFields += 1;

			if (isBasisPrice(fields.priceType)) {
				if (!isNil(fields.price)) {
					filledFields += 1;
				}
			} else if (fields.price > 0) {
				filledFields += 1;
			}
		}

		/** PRICE INCREMENT */
		if (fields.priceIncrementType && fields.priceIncrementType !== PriceIncrementType.None) {
			allRequiredFields += 2;
			if (
				fields.priceIncrementBasisShipment?.startDate &&
				fields.priceIncrementBasisShipment?.endDate
			) {
				filledFields += 1;
			}
			if (fields.priceIncrement) {
				filledFields += 1;
			}
		}

		/** OTC COUNTERPARTIES */
		if (fields.environment === Environment.OTC) {
			allRequiredFields += 1;
			if (fields.counterparties) {
				filledFields += 1;
			}
		}

		const progress = Math.round((100 * filledFields) / allRequiredFields);
		setProgress(progress);
	});

	return progress;
};
