/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { trackAnalytics } from 'src/_api';
import { getUserId } from 'src/_store/selectors/user.selectors';
import { useDrawerContext, DrawerContextKeys } from '../../Drawer/DrawerContext';
import { useOrderDetailsQuery, useOrderNegotiations } from 'src/_api/queries';
import { sortOrder } from 'src/_helpers/sort';
import { Environment } from 'src/constants/contract';

export const useViewOrderDrawer = () => {
	const drawerKey = DrawerContextKeys.viewDrawer;
	const contextData = useDrawerContext();
	const userId = useSelector(getUserId);

	const {
		currentOrderInfo,
		visibility: [isVisible],
		counterFormEnabled,
		enableCounterForm,
		setCounterPreviewData,
	} = contextData[drawerKey];

	const {
		data: order = {},
		isFetched: isOrderFetched,
		isLoading: isLoadingOrder,
		isRefetching: isRefetchingOrder,
	} = useOrderDetailsQuery(
		{
			environment: currentOrderInfo?.environment,
			orderId: currentOrderInfo?.orderId,
		},
		{
			enabled: !!currentOrderInfo?.orderId && !!currentOrderInfo?.environment,
		}
	);

	const { _key: orderId, environment } = order;

	const {
		data: negotiations = [],
		isFetched: areNegotiationsFetched,
		isLoading: isLoadingNegotiations,
		isRefetching: isRefetchingNegotiations,
	} = useOrderNegotiations(
		{
			environment,
			orderId,
			_order: sortOrder.desc,
			_sort: 'updated_at',
		},
		{
			enabled: !!orderId,
		}
	);

	const { mutate: viewOrder } = useMutation(trackAnalytics, {
		onError: error => {
			console.log('Error on setting analytics: ', error);
		},
	});

	useEffect(() => {
		const isOTCOrder = order?.environment === Environment.OTC;

		if (
			(isOTCOrder && !order?.recipients?.length) ||
			(isOTCOrder && order?.recipients?.includes(userId))
		) {
			viewOrder({ order_otc_id: orderId });
		} else if (order?.environment === Environment.Exchange) {
			viewOrder({ order_id: orderId });
		}
	}, [orderId, order?.recipients, order?.environment, userId, viewOrder]);

	const isLoading = isLoadingOrder || isLoadingNegotiations;
	const isRefetching = isRefetchingOrder || isRefetchingNegotiations;
	const isFetched = isOrderFetched || areNegotiationsFetched;

	const [activeNegotiationID, setActiveNegotiationID] = useState(null);

	useEffect(() => {
		setActiveNegotiationID(currentOrderInfo?.negotiationId || order._key);
	}, [currentOrderInfo?.negotiationId, order._key]);

	return {
		isLoading,
		isRefetching,
		isFetched,
		isVisible,
		drawerKey,
		order,
		negotiations,
		enableSidebar: !isLoading && isFetched && order.is_my_order,
		activeNegotiationID,
		setActiveNegotiationID,
		counterFormEnabled,
		enableCounterForm,
		setCounterPreviewData,
	};
};
