/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCountry } from 'src/_api/queries';
import { TradeInfoWrapper, Type, Info } from '../../../sections/common';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { isInactiveUser } from 'src/_helpers/isInactiveUser';
import { UserStatus } from 'src/components/UserStatus/UserStatus';
import { UserProfileLink } from 'src/components/UserProfileModal/UserProfileLink';
import { DownloadTradesButton } from 'src/components/DownloadTradesButton/DownloadTradesButton';
import { CompanyHeader } from 'src/constants/contract';
import * as StyledTradeInfo from '../styledTradeInfoComponents';
import * as Styled from './styled';
import { DashEmpty } from 'src/components/DashEmpty/DashEmpty';

export const CounterpartiesSection = ({
	sellerInfo,
	buyerInfo,
	brokerInfo,
	tradeId,
	tradeStep,
	market,
}) => {
	const { t } = useTranslation();

	const { data: sellerCountry } = useCountry({ _key: sellerInfo?.country });
	const { data: buyerCountry } = useCountry({ _key: buyerInfo?.country });
	const { data: brokerCountry } = useCountry({ _key: brokerInfo?.company?.country_code });

	const brokerLabel = `${t('broker')} (${t(CompanyHeader[brokerInfo?.order_type_position])})`;

	return (
		<StyledTradeInfo.RelativeWrapper>
			<Styled.HeaderContainer>
				<Styled.StyledInfoTitle>{t('counterparties')}</Styled.StyledInfoTitle>
				<DownloadTradesButton tradeId={tradeId} tradeStep={tradeStep} market={market} />
			</Styled.HeaderContainer>
			<TradeInfoWrapper>
				<StyledTradeInfo.InfoItemColumn>
					<Type data-test="trade-details-counterparties-seller-company">
						{t('seller')}
					</Type>
					<Info data-test={'trade-details-order-info-seller-company'}>
						<OverflowText>
							<DashEmpty value={sellerInfo?.company} />
						</OverflowText>
					</Info>
				</StyledTradeInfo.InfoItemColumn>
				<StyledTradeInfo.InfoItemColumn>
					<Type data-test="trade-details-counterparties-seller-country">
						{t('country')}
					</Type>
					<Info data-test={`trade-details-order-info-seller-country`}>
						<OverflowText>
							<DashEmpty value={sellerCountry?.name} />
						</OverflowText>
					</Info>
				</StyledTradeInfo.InfoItemColumn>
				<StyledTradeInfo.InfoItemColumn>
					<Type data-test="trade-details-counterparties-seller-name">{t('trader')}</Type>
					<StyledTradeInfo.TraderNameInfo
						data-test={`trade-details-order-info-seller-name`}
					>
						{sellerInfo?.trader_name ? (
							<UserProfileLink
								userId={sellerInfo?.id}
								isInactive={isInactiveUser(sellerInfo)}
							>
								{sellerInfo?.trader_name}
								<UserStatus status={sellerInfo?.status} />
							</UserProfileLink>
						) : (
							'---'
						)}
					</StyledTradeInfo.TraderNameInfo>
				</StyledTradeInfo.InfoItemColumn>
			</TradeInfoWrapper>
			{brokerInfo && (
				<TradeInfoWrapper>
					<StyledTradeInfo.InfoItemColumn>
						<Type data-test="trade-details-counterparties-seller-company">
							{brokerLabel}
						</Type>
						<Info data-test={'trade-details-order-info-seller-company'}>
							<OverflowText>{brokerInfo?.company?.name}</OverflowText>
						</Info>
					</StyledTradeInfo.InfoItemColumn>
					<StyledTradeInfo.InfoItemColumn>
						<Type data-test="trade-details-counterparties-seller-country">
							{t('country')}
						</Type>
						<Info data-test={`trade-details-order-info-seller-country`}>
							<OverflowText>{brokerCountry?.name}</OverflowText>
						</Info>
					</StyledTradeInfo.InfoItemColumn>
					<StyledTradeInfo.InfoItemColumn>
						<Type data-test="trade-details-counterparties-seller-name">
							{t('trader')}
						</Type>
						<StyledTradeInfo.TraderNameInfo
							data-test={`trade-details-order-info-seller-name`}
						>
							<UserProfileLink
								userId={brokerInfo?._key}
								isInactive={isInactiveUser(brokerInfo)}
							>
								{brokerInfo?.name}
								<UserStatus status={brokerInfo?.status} />
							</UserProfileLink>
						</StyledTradeInfo.TraderNameInfo>
					</StyledTradeInfo.InfoItemColumn>
				</TradeInfoWrapper>
			)}
			<TradeInfoWrapper>
				<StyledTradeInfo.InfoItemColumn>
					<Type data-test="trade-details-counterparties-buyer-company">{t('buyer')}</Type>
					<Info data-test={`trade-details-order-info-buyer-company`}>
						<OverflowText>
							<DashEmpty value={buyerInfo?.company} />
						</OverflowText>
					</Info>
				</StyledTradeInfo.InfoItemColumn>
				<StyledTradeInfo.InfoItemColumn>
					<Type data-test="trade-details-counterparties-buyer-country">
						{t('country')}
					</Type>
					<Info data-test={`trade-details-order-info-buyer-country`}>
						<OverflowText>
							<DashEmpty value={buyerCountry?.name} />
						</OverflowText>
					</Info>
				</StyledTradeInfo.InfoItemColumn>
				<StyledTradeInfo.InfoItemColumn>
					<Type data-test="trade-details-counterparties-buyer-name">{t('trader')}</Type>
					<StyledTradeInfo.TraderNameInfo
						data-test={`trade-details-order-info-buyer-name`}
					>
						{buyerInfo?.trader_name ? (
							<UserProfileLink userId={buyerInfo?.id}>
								{buyerInfo?.trader_name}
								<UserStatus status={buyerInfo?.status} />
							</UserProfileLink>
						) : (
							<DashEmpty value={buyerInfo?.trader_name} />
						)}
					</StyledTradeInfo.TraderNameInfo>
				</StyledTradeInfo.InfoItemColumn>
			</TradeInfoWrapper>
		</StyledTradeInfo.RelativeWrapper>
	);
};
