/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { map404ToEmptyList, fetchFromExchangeApi } from './model';

export const listForexRates = async query => {
	const result = await fetchFromExchangeApi('list/forex_rates', {
		method: 'GET',
		query,
		mapStatusToResult: map404ToEmptyList,
	});

	return result.map(mapForexQuote);
};

function mapForexQuote(quote, index) {
	return {
		...quote,
		favourite: index === 0,
	};
}
