/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { getInitials } from 'src/_helpers/getInitials';
import { InviteType } from 'src/constants/contacts';

export const mapUserDetails = (user = {}) => {
	return {
		id: user._key,
		approved: user.approved,
		avatarColor: user.avatar_color,
		name: user.name,
		initials: getInitials(user.name),
		companyId: user.company_id,
		companyName: user.company_name || '---',
		countryName: user.company_country_name || '---',
		cityName: user.company_city || '---',
		phoneNumber: user.phone_number || '---',
		email: user.email,
		countryAbbr: user.company_country_code,
		visibility: user.visibility,
		joinDate: user.user_created_at,
		connectDate: user.contact_created_at,
		isMyContact: user.is_my_contact,
		invitationSent:
			user.invitation?.type === InviteType.OUTBOUND
				? moment(user.invitation.created_at).fromNow()
				: undefined,
		invitationReceived:
			user.invitation?.type === InviteType.INBOUND ? user.invitation.created_at : undefined,
		inviteId: user.invitation?._key,
		showActivity: !!user.show_activity,
	};
};
