/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { h400, t100 } from 'src/components/Typography';
import { StrokeButton } from 'src/components/Buttons/StrokeButton';

export const Comment = styled.div`
	padding: 12px 20px;
	border-left: 2px solid;
	border-color: var(--counterparty-comment-color);

	&.isOwn {
		border-color: var(--owner-comment-color);
	}

	&.changed {
		background-color: var(--counter-highlight-color);
		border-radius: var(--large-border-radius);
		border-color: var(--counter-highlight-color);

		span {
			color: var(--text-color-default) !important;
		}
	}
`;

export const Header = styled.h3`
	${h400}
	color: var(--text-color-default);
	margin-bottom: 16px;

	span:first-of-type {
		color: var(--counterparty-comment-color);

		.isOwn & {
			color: var(--owner-comment-color);
		}
	}

	span:last-of-type {
		color: var(--neutral-300);
		padding-left: 0.4em;
	}
`;

export const Body = styled.div`
	${t100}
	color: var(--text-color-default);
`;

export const Button = styled(StrokeButton)`
	margin-top: 16px;
	width: 190px;
`;

export const NoComments = styled.div`
	${h400}
	color: var(--text-color-secondary);
`;
