/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { arrayOfNElements } from 'src/_helpers/array';

export const getMonthObject = date => ({
	date,
	value: date.format('YYYY-MM'),
	label: date.format('MMM YYYY'),
});

export const parseDate = date => (date ? getMonthObject(moment(date)) : null);

export const generate12MonthsFromDate = (startDate = null) => {
	return arrayOfNElements(12).map(num => {
		const date = moment(startDate).utc(0).add(num, 'M');

		return getMonthObject(date);
	});
};

export const getPrevYear = startDate => {
	return moment(startDate).subtract(1, 'y');
};

export const getNextYear = startDate => {
	return moment(startDate).add(1, 'y');
};

export const getYearsFromArray = months => {
	const years = months.reduce((list, item) => {
		const year = item.date.format('Y');
		if (!list.includes(year)) {
			list.push(year);
		}

		return list;
	}, []);

	return years.join('/');
};

export const isOutOfRange = (month, cutOffDate) =>
	!!cutOffDate && moment(cutOffDate).isBefore(month, 'month');

export const isDateInThePast = momentDate => {
	const currentMonthStart = moment().startOf('month');

	return momentDate.isBefore(currentMonthStart);
};

export const getInitialMonthDisplayed = (startDate = null) => {
	const now = Date.now();

	if (!startDate) {
		return now;
	}

	const currentMonthStart = moment().utc(0).startOf('month');
	const startDateMonthStart = moment(startDate).utc(0).startOf('month');

	const diffInYears = startDateMonthStart.diff(currentMonthStart, 'Y');

	if (diffInYears === 0) {
		return now;
	}

	return parseInt(currentMonthStart.add(diffInYears, 'Y').format('x'));
};
