/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';

export const CloseButton = styled(PrimaryButton)`
	width: 192px;
`;
