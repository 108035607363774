/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { API_URL } from 'src/_api';
import { FILES_PREFIX } from 'src/constants/files';

export const downloadFile = file => {
	if (!file) {
		return;
	}

	let id = file._id || file.id;
	if (!id.startsWith(FILES_PREFIX)) {
		id = `${FILES_PREFIX}${id}`;
	}

	const link = document.createElement('a');
	link.setAttribute('href', `${API_URL}/v1/download/${id}`);
	link.setAttribute('download', file.name);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
