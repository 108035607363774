/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as Badge } from 'src/assets/icons/badge_icon.svg';
import { media } from 'src/_helpers';

const AvatarSizes = {
	small: {
		circle: '24',
		text: '10',
		offset: '0',
	},
	medium: {
		circle: '34',
		text: '14',
		offset: '1',
	},
	large: {
		circle: '42',
		text: '14',
		offset: '1',
	},
};

const DotBase = styled.div`
	width: 7px;
	height: 7px;
	border-radius: 50%;
	border: 1px solid var(--neutral-1000);

	.medium > & {
		width: 8px;
		height: 8px;
		bottom: 2px;
		right: 2px;
		border-width: 2px;
	}

	.large > & {
		width: 10px;
		height: 10px;
		bottom: 2px;
		right: 2px;
		border-width: 2px;
	}
`;

export const OnlineDot = styled(DotBase)`
	background-color: var(--green-600);
	border: 1px solid var(--green-600);
	${({ $dotBackgroundColor }) =>
		$dotBackgroundColor && `box-shadow: 0 0 0 1px ${$dotBackgroundColor};`};

	.medium > & {
		border-width: 2px;
		outline-width: 2px;
	}
	.large > & {
		border-width: 2px;
		outline-width: 2px;
	}
`;

export const OfflineDot = styled(DotBase)`
	${({ $dotBackgroundColor }) => `background-color: ${$dotBackgroundColor};`}
	border: 1px solid var(--neutral-300);
	${({ $dotBackgroundColor }) =>
		$dotBackgroundColor && `box-shadow: 0 0 0 1px ${$dotBackgroundColor};`};

	.medium > & {
		border-width: 1px;
		outline-width: 2px;
	}
	.large > & {
		border-width: 1px;
		outline-width: 2px;
	}
`;

export const ActiveOfflineDot = styled(OfflineDot)`
	border-color: #fff;

	.small > &,
	.medium > &,
	.large > & {
		border-width: 1px;
	}
`;

export const ActiveConversationDot = styled(DotBase)`
	background-color: #fff;
	border-color: #fff;

	${({ $dotBackgroundColor }) =>
		$dotBackgroundColor && `box-shadow: 0 0 0 1px ${$dotBackgroundColor};`};

	.medium > & {
		border-width: 2px;
		outline-width: 2px;
	}
	.large > & {
		border-width: 2px;
		outline-width: 2px;
	}
`;

export const AvatarCircle = styled.div`
	width: ${({ $size }) => AvatarSizes[$size].circle}px;
	height: ${({ $size }) => AvatarSizes[$size].circle}px;
	line-height: ${({ $size, $withBorder }) =>
		$withBorder ? AvatarSizes[$size].circle - 4 : AvatarSizes[$size].circle}px;
	text-align: center;
	color: ${({ $selected, $textColor }) =>
		$selected ? 'var(--neutral-900)' : $textColor ? $textColor : '#fff'};
	background-color: ${({ $backgroundColor, $selected }) =>
		$selected ? '#fff' : $backgroundColor || `var(--yellow-600)`};
	border-radius: 50%;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: ${({ $size }) => AvatarSizes[$size].text}px;
	letter-spacing: 0.2px;
	position: relative;
	flex-shrink: 0;
	user-select: none;

	.status-dot {
		position: absolute;
		bottom: ${({ $size }) => AvatarSizes[$size].offset}px;
		right: ${({ $size }) => AvatarSizes[$size].offset}px;
	}

	${({ $withBorder }) => $withBorder && `border: 2px solid var(--neutral-900);`}
	${({ $borderColor }) => $borderColor && `border-color: ${$borderColor};`}
	${({ $borderSize }) =>
		$borderSize &&
		`
			border-style: solid;
			border-width: ${$borderSize};
			align-items: center;
			display: flex;
			justify-content: center;
		`}
`;

export const MyContactBadge = styled.div`
	position: absolute;
	bottom: -7px;
	right: -11px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: var(--neutral-800);

	${media.xl`
		width: 24px;
		height: 24px;
		bottom: -7px;
		right: -13px;
	`}
`;

export const BadgeIcon = styled(Badge)`
	width: 13px;
	height: 13px;

	path {
		fill: var(--neutral-100);
	}

	${media.xl`
		width: 15px;
		height: 15px;
	`}
`;
