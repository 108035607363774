/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H600, T100 } from 'src/components/Typography/fonts';
import { ReactComponent as ZeroNotifications } from 'src/assets/icons/zero_notifications.svg';

export function NoNotifications() {
	const { t } = useTranslation();

	return (
		<Content>
			<ZeroNotifications />
			<PrimaryText>{t('no_notifications')}</PrimaryText>
			<SecondaryText>{t('you_dont_have_notifications')}</SecondaryText>
		</Content>
	);
}

const PrimaryText = styled(H600)`
	margin-top: 32px;
	margin-bottom: 12px;
`;
const SecondaryText = styled(T100)`
	color: var(--text-color-secondary);
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 110px 0;
`;
