/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import * as Styled from './styled';

export const Pill = ({ className, children }) => {
	return (
		<Styled.Container className={className}>
			<Styled.Content>{children}</Styled.Content>
		</Styled.Container>
	);
};
