/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { H400 } from 'src/components/Typography/fonts';
import * as Styled from './styled';

export const InfoMessage = ({ label, ...rest }) => {
	return (
		<Styled.InfoWrapper {...rest}>
			<Styled.PrimaryShieldIcon />
			<H400>{label}</H400>
		</Styled.InfoWrapper>
	);
};
