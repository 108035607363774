/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { M100, M200 } from 'src/components/Typography/fonts';
import { colorsByKind, useNotification } from './model';
import { CreatedAt } from './NotificationListItem';

export function NotificationsTapeItem({ item, onNavigation }) {
	const { created_at } = item;
	const {
		kind,
		title,
		url,
		state,
		translationKey,
		text,
		textHighlighted,
		...info
	} = useNotification(item);

	const onClick = useCallback(ev => onNavigation(ev, item), [onNavigation, item]);

	if (kind === 'todo') {
		return null;
	}

	return (
		<li>
			<ListItemLink
				to={{
					pathname: url,
					state,
				}}
				onClick={onClick}
			>
				<Title kind={kind}>{title}</Title>
				<PrimaryText>
					{translationKey ? (
						<Description>
							<Trans i18nKey={translationKey} values={{ ...info }}>
								<HighlightedText />
							</Trans>
						</Description>
					) : (
						<>
							{textHighlighted && <>{textHighlighted},&nbsp;</>}
							<Description>{text}</Description>
						</>
					)}
				</PrimaryText>
				<StyledCreatedAt createdAt={created_at} />
			</ListItemLink>
		</li>
	);
}

const StyledCreatedAt = styled(CreatedAt)`
	color: white;
`;

const PrimaryText = styled(M100)`
	display: flex;
	margin-right: 8px;
`;

const HighlightedText = styled(M100)`
	color: #ffffff;
`;

export const Description = styled(M100)`
	color: var(--text-color-secondary);
`;

export const Title = styled(M200)`
	padding: 4px 8px;
	color: ${({ kind }) => colorsByKind[kind]?.color || 'var(--text-color-default)'};
	background-color: ${({ kind }) => colorsByKind[kind]?.background || 'var(--neutral-600)'};
	border-radius: 10px;
	margin-right: 8px;
`;

export const ListItemLink = styled(Link)`
	display: flex;
	align-items: center;
	margin-top: 8px;
	padding: 8px 12px;

	&:hover {
		color: inherit;
	}
`;
