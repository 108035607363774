/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { AcceptCounterModal } from 'src/containers/CounterDetails/Shared/AcceptCounterModal/AcceptCounterModal';
import { Permission } from 'src/containers/Permissions/Permission';
import { Permissions } from 'src/constants/permissions';
import { useTranslation } from 'react-i18next';
import { useCounterFooter } from './useCounterFooter';
import { useMyContactIds } from 'src/websockets/MyContactsIdsProvider';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { ChatButton } from '../components/ChatButton/ChatButton';
import { CounterButton } from '../components/common';
import { useViewOrderDrawerContext } from '../ViewOrderDrawerProvider';
import { AcceptButton } from '../components/common';
import { useMediaQuery, screenSize } from 'src/_helpers';
import * as Styled from './styled';

export const CounterButtons = ({ order, counter, isLastCounter, setCounterForm, negotiation }) => {
	const { t } = useTranslation();

	const { canAccept, canCounter, canFirmUp, lastCounter } = useCounterFooter({
		order,
		counter,
		isLastCounter,
	});

	const { isMyContact } = useMyContactIds();
	const { isMyOrder } = useOrderDetails(order);

	const counterpartyId = isMyOrder
		? negotiation.counter_order_user_id
		: negotiation.order_user_id;

	const { negotiationsWithdrawn, negotiationUpdated, tradeCreated } = useViewOrderDrawerContext();

	const isSmallScreen = useMediaQuery({ 'max-width': screenSize.ll - 1 });

	return (
		<>
			{isMyContact(counterpartyId) && (
				<ChatButton userId={counterpartyId} skipIcon={isSmallScreen} />
			)}
			{canCounter && (
				<Permission
					name={Permissions.ORDER_NEGOTIATION}
					renderOn={() => (
						<CounterButton
							darker
							data-test="counter-order-button"
							onClick={() => setCounterForm(true, counter)}
							disabled={negotiationsWithdrawn || negotiationUpdated || tradeCreated}
						>
							{t('counter')}
						</CounterButton>
					)}
				/>
			)}
			{canFirmUp && (
				<Styled.FirmUpButton
					data-test="firm-up-order-button"
					disabled={negotiationsWithdrawn || negotiationUpdated || tradeCreated}
					onClick={() =>
						setCounterForm(true, {
							...counter,
							forceFirm: true,
						})
					}
				>
					{t('firm_up')}
				</Styled.FirmUpButton>
			)}
			{canAccept && (
				<Permission
					name={Permissions.ORDER_NEGOTIATION}
					renderOn={() => (
						<AcceptCounterModal
							order={order}
							counters={[counter]}
							negotiationId={lastCounter.first_counter_id}
							trigger={
								<AcceptButton
									data-test="accept-order-button"
									disabled={
										negotiationsWithdrawn || negotiationUpdated || tradeCreated
									}
								>
									{t('accept')}
								</AcceptButton>
							}
							isDrawer
						/>
					)}
				/>
			)}
		</>
	);
};
