/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { useCalendarSpreadSection } from '../../useCalendarSpreadSection';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';

const SecondLegFuturesMonth = ({ path, t }) => {
	const { secondLegFuturesMonth } = useCalendarSpreadSection({ path });
	const { setValue } = useFormContext();

	return (
		<Controller
			name={toPath(path, 'secondLegFuturesMonth')}
			mandatory
			render={({ field, fieldState: { error } }) => (
				<MaterialSelectInput
					required
					error={!!error}
					data-test={field.name}
					label={t('second_leg_futures')}
					options={secondLegFuturesMonth.options}
					{...field}
					onChange={value => {
						field.onChange(value);
						setValue(toPath(path, 'secondLegFuturesMonthChanged'), true);
					}}
				/>
			)}
		/>
	);
};
export const secondLegFuturesMonth = (path, t) => <SecondLegFuturesMonth path={path} t={t} />;
