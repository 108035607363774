/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { toPath, isNil } from 'src/_helpers';
import {
	OrderType,
	tLegQuote,
	Currency,
	PriceUnit,
	CalendarSpreadPayment,
	PaperInstruments,
} from 'src/constants/contract';
import { useWatchPaperFields } from '../../../useWatchPaperFields';
import { isNextMonthOrAfter, toUTCUnix, getNumberOfMonths } from 'src/_helpers/date';
import { usePaperOrderContext } from '../../../CreatePaperOrderDrawerContent';
import { genFutureContractMonths } from 'src/containers/Order/PhysicalOrder/Groups/Pricing/FuturesContractDate';
import { calculateClosestFuturesMonth } from '../../../helpers/calculateClosestFuturesMonth';

export const useCalendarSpreadSection = ({ path }) => {
	const { t } = useTranslation();

	const { setValue } = useFormContext();
	const { selectedPreset: selectedProductPresetValue } = usePaperOrderContext();

	const {
		typeValue,
		firstLegMonthValue,
		secondLegMonthValue,
		firstLegFuturesMonthChangedValue,
		secondLegFuturesMonthChangedValue,
		availableInstruments,
		instrumentValue,
		priceTypeValue,
		volumeValue,
		isEditing,
		isCopying,
		spreadTypeValue,
		priceValue,
		deliveryValue,
		runsValue,
	} = useWatchPaperFields(path);

	useEffect(() => {
		if (selectedProductPresetValue) {
			const spreadPriceFormat = selectedProductPresetValue.instruments.find(
				i => i.type === PaperInstruments.Spread
			)?.spread_price_format;

			if (spreadPriceFormat) {
				setValue('spreadPriceFormat', spreadPriceFormat);
			}
		}
	}, [selectedProductPresetValue, setValue]);

	/** 1st leg quote */
	const legQuoteOptions = useMemo(
		() =>
			Object.values(OrderType).map(value => ({
				text: tLegQuote(t, value),
				key: value,
				value,
			})),
		[t]
	);

	/** Set default 1st leg quote when not defined */
	useEffect(() => {
		if (!typeValue) {
			setValue(toPath(path, 'type'), OrderType.Buy);
		}
	}, [typeValue, path, setValue]);

	/** Set 2nd leg quote as opposite to 1st leg quote */
	useEffect(() => {
		if (typeValue) {
			setValue(
				toPath(path, 'secondLegQuote'),
				typeValue === OrderType.Buy ? OrderType.Sell : OrderType.Buy
			);
		}
	}, [typeValue, path, setValue]);

	/** 2st leg month should always be after 1st leg month */
	const onFirstLegMonthChange = value => {
		if (
			secondLegMonthValue &&
			!isNextMonthOrAfter(secondLegMonthValue.startDate, value.startDate)
		) {
			setValue(toPath(path, 'secondLegMonth'), { format: 'months' });
		}
	};

	/** check if all priceTypeOptions are available for the currently selected instrument */
	useEffect(() => {
		if (instrumentValue && priceTypeValue) {
			if (
				availableInstruments[instrumentValue] &&
				!availableInstruments[instrumentValue].includes(priceTypeValue)
			) {
				setValue(toPath(path, 'priceType'), availableInstruments[instrumentValue][0]);
			}
		}
	}, [availableInstruments, instrumentValue, path, priceTypeValue, setValue]);

	/* Futures months */
	const selectedFuturesContractMonths = useMemo(() => {
		if (!selectedProductPresetValue) {
			return [];
		}

		return (
			selectedProductPresetValue?.product?.futures_contracts?.find(
				fc => fc.futures_contract_id === priceTypeValue
			)?.future_months || []
		);
	}, [priceTypeValue, selectedProductPresetValue]);

	const futuresMonthOptions = useMemo(() => {
		return genFutureContractMonths(selectedFuturesContractMonths).map(value => ({
			key: value.valueOf(),
			text: moment(value).format('MMM YYYY'),
			value: toUTCUnix(value),
		}));
	}, [selectedFuturesContractMonths]);

	/** 1st leg futures month */
	useEffect(() => {
		if (!firstLegMonthValue || firstLegFuturesMonthChangedValue || isEditing || isCopying) {
			return;
		}

		const closestFuturesMonth = calculateClosestFuturesMonth(
			firstLegMonthValue,
			futuresMonthOptions
		);

		setValue(toPath(path, 'firstLegFuturesMonth'), closestFuturesMonth?.value || null);
	}, [
		firstLegFuturesMonthChangedValue,
		firstLegMonthValue,
		futuresMonthOptions,
		isCopying,
		isEditing,
		path,
		setValue,
	]);

	/** 2nd leg futures month */
	useEffect(() => {
		if (!secondLegMonthValue || secondLegFuturesMonthChangedValue || isEditing || isCopying) {
			return;
		}

		const closestFuturesMonth = calculateClosestFuturesMonth(
			secondLegMonthValue,
			futuresMonthOptions
		);

		setValue(toPath(path, 'secondLegFuturesMonth'), closestFuturesMonth?.value || null);
	}, [
		secondLegFuturesMonthChangedValue,
		secondLegMonthValue,
		futuresMonthOptions,
		isCopying,
		isEditing,
		path,
		setValue,
	]);

	useEffect(() => {
		const selectedFuturesContract = selectedProductPresetValue.product.futures_contracts?.find(
			fc => fc.futures_contract_id === priceTypeValue
		);

		const unit = selectedFuturesContract ? selectedFuturesContract.unit : PriceUnit.MetricTon;

		setValue(toPath(path, 'currencyUnit'), `${selectedProductPresetValue.currency}/${unit}`);
	}, [
		path,
		priceTypeValue,
		selectedProductPresetValue.currency,
		selectedProductPresetValue.product.futures_contracts,
		setValue,
	]);

	useEffect(() => {
		if (volumeValue === '') {
			setValue(toPath(path, 'volume'), null, { shouldValidate: true });
		}
	}, [path, setValue, volumeValue]);

	const spreadTypeOptions = Object.values(CalendarSpreadPayment).map(sp => ({
		key: sp,
		text: t(sp),
		value: sp,
	}));

	useEffect(() => {
		if (spreadTypeValue === CalendarSpreadPayment.Even) {
			setValue(toPath(path, 'price'), '0');
		}
	}, [path, priceValue, setValue, spreadTypeValue]);

	useEffect(() => {
		// eslint-disable-next-line eqeqeq
		if (spreadTypeValue !== CalendarSpreadPayment.Even && priceValue == 0) {
			setValue(toPath(path, 'price'), null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path, setValue, spreadTypeValue]);

	const calculatedTotalVolume = useMemo(() => {
		if (selectedProductPresetValue) {
			const monthsMultiplier = selectedProductPresetValue.multi_month
				? getNumberOfMonths(deliveryValue?.startDate, deliveryValue?.endDate)
				: 1;

			if (!isNil(runsValue)) {
				return selectedProductPresetValue.base_quantity * +runsValue * monthsMultiplier;
			} else if (!selectedProductPresetValue.volume !== undefined) {
				return monthsMultiplier * volumeValue;
			}

			return selectedProductPresetValue.volume;
		}

		return null;
	}, [
		deliveryValue?.endDate,
		deliveryValue?.startDate,
		runsValue,
		selectedProductPresetValue,
		volumeValue,
	]);

	useEffect(() => {
		setValue(toPath(path, 'totalVolume'), calculatedTotalVolume);
	}, [path, setValue, calculatedTotalVolume]);

	return {
		firstLegOrderType: { options: legQuoteOptions, ready: true },
		secondLegQuote: { options: legQuoteOptions, ready: false },
		firstLegMonth: { onChange: onFirstLegMonthChange },
		firstLegFuturesMonth: { options: futuresMonthOptions },
		secondLegFuturesMonth: { options: futuresMonthOptions },
		currency: Currency.USD,
		unit: PriceUnit.Bushel,
		price: {
			disabled: spreadTypeValue === CalendarSpreadPayment.Even,
		},
		spreadType: {
			options: spreadTypeOptions,
			ready: true,
		},
	};
};
