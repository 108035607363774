/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useCallback, useContext, useState } from 'react';
import { areSetsEqual } from 'src/_helpers/set';
import { VisibilityStatus } from 'src/constants/contacts';

const defaultValues = {
	isUserActive: userId => false,
	updateUsersPresence: list => {},
	/**
	 * Get user visibility based on presence from websockets
	 *
	 * @param {string} userId
	 * @param {'online' | 'offline'} [initialUserVisibility] visibility retrieved from the backend displayed before websocket initialization
	 * @returns {'online' | 'offline'}
	 */
	getUserVisibility: (userId, initialUserVisibility) =>
		initialUserVisibility || VisibilityStatus.OFFLINE,
	/**
	 * Get user visibility based on presence from websockets
	 *
	 * @param {string} userId
	 * @param {boolean} [isUserHidden] is user hidden in presence
	 * @returns {void}
	 */
	setSingleUserPresence: (userId, isUserHidden) => {},
};

export const UserPresenceContext = createContext(defaultValues);

export const UserPresenceProvider = ({ children }) => {
	const [userPresence, setUsersPresence] = useState(new Set());
	const isUserActive = userId => userPresence.has(userId);
	const getUserVisibility = (userId, initialUserVisibility) => {
		if (userPresence.size === 0) {
			return initialUserVisibility || VisibilityStatus.OFFLINE;
		}

		return isUserActive(userId) ? VisibilityStatus.ONLINE : VisibilityStatus.OFFLINE;
	};

	const updateUsersPresence = useCallback(newPresenceSet => {
		setUsersPresence(list => {
			if (areSetsEqual(list, newPresenceSet)) {
				return list;
			}

			return newPresenceSet;
		});
	}, []);

	const setSingleUserPresence = useCallback((userId, isUserHidden) => {
		setUsersPresence(list => {
			const shouldIgnoreUpdate =
				(isUserHidden && !list.has(userId)) || (!isUserHidden && list.has(userId));
			if (shouldIgnoreUpdate) {
				return list;
			}

			const clonedUserPresence = new Set(list);
			if (isUserHidden) {
				clonedUserPresence.delete(userId);
			} else {
				clonedUserPresence.add(userId);
			}

			return clonedUserPresence;
		});
	}, []);

	const contextValue = {
		isUserActive,
		updateUsersPresence,
		getUserVisibility,
		setSingleUserPresence,
	};

	return (
		<UserPresenceContext.Provider value={contextValue}>{children}</UserPresenceContext.Provider>
	);
};

export const useUserPresenceContext = () => useContext(UserPresenceContext);
