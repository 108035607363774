/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useManageFiles = (user, defaultValue = []) => {
	const [files, setFiles] = useState(defaultValue);
	const onChangeHandler = useCallback(
		({ target }, callback) => {
			if (target.files && target.files.length > 0) {
				const newFiles = Array.from(target.files).map(file => {
					if (user) {
						file.owner = {
							first_name: user.first_name,
							last_name: user.last_name,
						};
					}

					file.created_at = Date.now();
					return { file, key: uuidv4(), isUploaded: false };
				});
				setFiles(files => [...files, ...newFiles]);
				callback?.();
			}
		},
		[user]
	);

	const deleteHandler = useCallback(
		fileKeyToDelete => () => {
			setFiles(prevFiles => {
				const newFiles = [...prevFiles];
				const indexToDelete = newFiles.findIndex(({ key }) => key === fileKeyToDelete);

				if (indexToDelete >= 0) {
					newFiles.splice(indexToDelete, 1);
				}

				return newFiles;
			});
		},
		[]
	);

	const successHandler = useCallback(
		fileKeyToUpdate => id => {
			setFiles(prevFiles => {
				const newFiles = [...prevFiles];
				const indexToUpdate = newFiles.findIndex(({ key }) => key === fileKeyToUpdate);

				if (indexToUpdate >= 0) {
					newFiles[indexToUpdate].isUploaded = true;
					newFiles[indexToUpdate].fileId = id;
				}

				return newFiles;
			});
		},
		[]
	);

	return { files, onChangeHandler, successHandler, deleteHandler };
};
