/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FoldableSection } from 'src/components/CreateOrderDrawer/components/FoldableSection/FoldableSection';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { PortDropdown } from '../../../components/PortDropdown/PortDropdown';
import { useShipmentSectionOptions } from './useShipmentSectionOptions';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { MaterialTextInput } from 'src/designSystem/Form/MaterialInput/MaterialTextInput';
import { ContextualGuidePhysicalOrderShipment } from 'src/components/ContextualGuide';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { MAXIMUM_TERMINAL_NAME_LENGTH } from '../../model';
import * as Styled from './styled';

export const ShipmentSection = () => {
	const { t } = useTranslation();

	const {
		formState: { errors },
	} = useFormContext();

	const {
		tolerance,
		cargoType,
		inspection,
		ports,
		portVolumes,
		portTerminals,
		portRates,
	} = useShipmentSectionOptions();

	const portErrors = errors
		? Object.entries(errors).reduce((acc, [key, value]) => {
				if (key === 'ports' && value) {
					value.forEach((error, index) => {
						if (error?.name) {
							acc[`ports.${index}.name`] = error.name;
						}
					});
				}

				return acc;
		  }, {})
		: {};

	return (
		<FoldableSection
			title={t('shipment')}
			GuideComponent={ContextualGuidePhysicalOrderShipment}
		>
			<Controller
				mandatory
				name="tolerance"
				render={({ field }) => (
					<MaterialNumberInput
						error={!!errors[field.name]}
						data-test={field.name}
						id={field.name}
						label={tolerance.label}
						fixedDecimalScale={false}
						allowNegative={false}
						decimalScale={0}
						parameters={'%'}
						isAllowed={({ value }) => !value || (value <= 100 && value >= 0)}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name="cargoType"
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('cargo_type')}
						options={cargoType.options}
						required={cargoType.ready}
						disabled={!cargoType.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				name="inspection"
				mandatory={inspection.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('inspection')}
						options={inspection.options}
						disabled={!inspection.ready}
						{...field}
					/>
				)}
			/>
			{ports.list.map((port, index) => {
				return (
					<Styled.PortSection
						fullWidth
						key={port.id || index}
						mandatory={ports.hasValue(index)}
					>
						{index === 0 ? (
							<div>
								<Styled.PortLabel>{ports.label} 1</Styled.PortLabel>
								<OverflowText>
									{ports.names[ports.list[0].name] || '---'}
								</OverflowText>
							</div>
						) : (
							<Controller
								name={`ports.${index}.name`}
								render={({ field }) => (
									<PortDropdown
										error={!!portErrors[field.name]}
										data-test={field.name}
										required
										label={`${ports.label} ${index + 1}`}
										onValueUpdate={ports.addName}
										{...field}
									/>
								)}
							/>
						)}
						<Controller
							name={`ports.${index}.volume`}
							render={({ field }) => (
								<MaterialNumberInput
									className={clsx({ optional: !portVolumes.hasValue(index) })}
									error={
										!!errors[field.name] ||
										parseInt(field.value) > portVolumes.maxValue
									}
									data-test={field.name}
									id={field.name}
									label={portVolumes.label}
									fixedDecimalScale={false}
									disabled={!portVolumes.ready}
									parameters={t('mt')}
									{...field}
									onChange={value => field.onChange(value || null)}
									value={
										portVolumes.ready ? field.value : portVolumes.defaultValue
									}
								/>
							)}
						/>
						<Controller
							name={`ports.${index}.terminal`}
							render={({ field }) => (
								<MaterialTextInput
									className={clsx({ optional: !portTerminals.hasValue(index) })}
									error={!!errors[field.name]}
									data-test={field.name}
									id={field.name}
									label={t('terminal')}
									maxLength={MAXIMUM_TERMINAL_NAME_LENGTH}
									{...field}
									value={field.value || ''}
								/>
							)}
						/>
						<Controller
							name={`ports.${index}.rate`}
							render={({ field }) => (
								<MaterialNumberInput
									className={clsx({ optional: !portRates.hasValue(index) })}
									error={!!errors[field.name]}
									data-test={field.name}
									id={field.name}
									label={portRates.label}
									parameters={t('mt/day')}
									fixedDecimalScale={false}
									{...field}
									onChange={value => field.onChange(value || null)}
								/>
							)}
						/>
						<Styled.DeleteIconWrapper>
							{index > 0 && <Styled.DeleteIcon onClick={() => ports.delete(index)} />}
						</Styled.DeleteIconWrapper>
					</Styled.PortSection>
				);
			})}
			{!!ports.list?.[0].name ? (
				<Styled.PortSection fullWidth>
					<div>
						<Styled.Button onClick={ports.add}>
							<PlusIcon />
							{t('add_port')}
						</Styled.Button>
					</div>
				</Styled.PortSection>
			) : (
				<div />
			)}
		</FoldableSection>
	);
};
