/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const ConversationType = {
	Instant: 'instant',
	Execution: 'execution',
	KYC: 'kyc',
	New: 'new',
};

export const TopicType = {
	Execution: 'execution_topic',
	KYC: 'kyc_topic',
};

export const ConversationTopicType = {
	[ConversationType.Execution]: TopicType.Execution,
	[ConversationType.KYC]: TopicType.KYC,
};

export const TopicToConversationTypeMap = {
	[TopicType.Execution]: ConversationType.Execution,
	[TopicType.KYC]: ConversationType.KYC,
	[ConversationType.Instant]: ConversationType.Instant,
};

export const ChatStoreKey = {
	Conversation: 'conversation',
	Topic: 'topic',
};

export const ChatView = {
	NewMessage: 'NewMessage',
	Conversation: 'Conversation',
};

export const MessagesRefetchInterval = 3000;

export const chatConversationsQueryName = 'chat-recent-conversations';
export const chatExecutionGroupsQueryName = 'chat-execution-groups';
export const chatKYCGroupsQueryName = 'chat-kyc-groups';
export const chatTopicsQueryName = 'chat-topics';
export const chatUnreadConversationsQueryName = 'chat-unread-conversations-count';
export const chatKYCAndExecutionAvailabilityQueryName = 'chat-kyc-execution-availability';
export const chatConversationMessagesQueryName = 'chat-conversation-messages';
export const chatTopicMessagesQueryName = 'chat-topic-messages';

export const cursorDirection = {
	GTE: 'gte',
	GT: 'gt',
	LTE: 'lte',
	LT: 'lt',
};

export const ScrollDirection = {
	Top: 'top',
	Bottom: 'bottom',
};

export const tempMessageId = 'temp-message';
export const blankConversationPrefix = 'new-conversation';

export const SupportConversation = 'support';
export const Vosbor = 'Vosbor';
export const SupportConversationTitle = 'Support  (Vosbor)';

export const ChatLastMessageImage = 'last-message-is-image';

export const PictureI18nKey = 'user_sent_picture';

export const VosborUser = {
	first_name: Vosbor,
	last_name: '',
	company: { name: Vosbor },
	avatar_color: '#DD7B08',
	visibility: 'online',
};

export const EmptyUnreadState = {
	[ConversationType.Instant]: [],
	[ConversationType.Execution]: [],
	[ConversationType.KYC]: [],
};

export const EmptyAvailabilityState = {
	[ConversationType.Execution]: false,
	[ConversationType.KYC]: false,
};

export const GroupConversation = {
	[ConversationType.New]: false,
	[ConversationType.Instant]: false,
	[ConversationType.Execution]: true,
	[ConversationType.KYC]: true,
};
