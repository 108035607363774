/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSkipMountEffect } from 'src/_helpers/useSkipMountEffect';
import { useTranslation } from 'react-i18next';
import { useFirstRender } from './useFirstRender';

export function toPath(...nodes) {
	return nodes
		.filter(node => node !== null && node !== undefined)
		.reduce((curr, next) => {
			if (!curr) {
				return next;
			}
			return curr + '.' + next;
		});
}

export const getPath = (path, o) => {
	return path.reduce((a, key) => a?.[key], o);
};

export function useAutoSelect(name, data, prop = 'value') {
	const isFirstRender = useFirstRender();
	const { setValue, register } = useFormContext();

	useEffect(() => {
		if (data.length === 1) {
			if (isFirstRender) {
				register(name, { value: data[0][prop] });
			} else {
				setValue(name, data[0][prop], {
					shouldValidate: true,
				});
			}
		}
	}, [data, name, register, setValue, prop, isFirstRender]);
}

export function useResetFormValueOnChange(name, deps) {
	const { setValue } = useFormContext();

	useSkipMountEffect(() => setValue(name, null), [...deps, setValue, name]);
}

export function useEnumOptions(enumObj, translate) {
	const { t } = useTranslation();

	return useMemo(
		() =>
			Object.values(enumObj).map(value => ({
				key: value,
				text: translate(t, value),
				value,
			})),
		[enumObj, translate, t]
	);
}
