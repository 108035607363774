/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers';
import { h900, H900, h600, H200, H400, M100 } from 'src/components/Typography';
import { ReactComponent as ChevronIcon } from 'src/assets/icons/chevron.svg';

export const ContentCell = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: end;
`;

export const PaperContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
`;

export const TopPills = styled.div`
	margin-bottom: 20px;
	margin-top: 8x;
	display: grid;
	grid-template-columns: 170px 1fr 1fr;
	grid-gap: 8px;

	${props =>
		props.$isSpread &&
		css`
			grid-template-columns: 1fr max-content max-content !important;
		`}

	${media.ll`
		grid-template-columns: 170px 1fr 1fr;
		grid-gap: 16px;
	`}

	${media.xl`
		grid-template-columns: 214px 1fr 1fr;
		grid-gap: 16px;
	`}
`;

export const DetailsTitle = styled(H900)`
	color: #fff;

	&.changed {
		position: relative;
		z-index: 1;
		display: inline-block;
		padding: 0 10px;

		${media.xl`
			padding: 0 12px;
		`}

		&:before {
			content: '';
			background-color: var(--counter-highlight-color);
			inset: -4px 0;
			border-radius: var(--large-border-radius);
			position: absolute;
			z-index: -1;
		}
	}
`;

export const GradeSpecsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 9px;
	margin-top: 7px;

	${media.xl`
		margin-top: 8px;
	`}
`;

export const SpecsDropdown = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	border-radius: 8px;
	width: 90px;
	height: 30px;
	background: var(--neutral-800);
	padding: 6px 8px;
	color: #fff;
	user-select: none;
	${h600}

	${media.xl`
		width: 93px;
	`}
`;

export const SpecsChevron = styled(ChevronIcon)`
	color: #fff;
	transition: transform 300ms ease;
	width: 16px;
	height: 16px;

	${({ $isOpen }) =>
		$isOpen &&
		`
			transform: rotate(180deg);
			transition: transform 400ms ease-in-out;
	`}
`;

export const SpecsContainer = styled.div`
	margin-top: 16px;
	max-height: 0px;
	overflow: hidden;
	transition: max-height 300ms ease;

	${media.ll`
		margin-top: 20px;
	`}

	${media.xl`
		margin-top: 24px;
	`}

	${({ $isOpen }) =>
		$isOpen &&
		`
		max-height: 999px;
		transition: max-height 400ms ease-in-out;
	`}
`;

export const SpecsListContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 111px);
	gap: 8px 8px;

	${media.ll`
		grid-template-columns: repeat(4, 124px);
	`}

	${media.xl`
		gap: 16px 16px;
		grid-template-columns: repeat(4, 138px);
	`}
`;

export const SpecName = styled(H200)`
	color: var(--neutral-100);
`;

export const SpecContentCell = styled(ContentCell)`
	justify-content: flex-start;
	gap: 3px;

	${media.xl`
		gap: 5px;
	`}
`;

export const SpecLabel = styled(H400)`
	color: var(--neutral-200);
`;

export const SpecValue = styled(H400)`
	color: #fff;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const SpecValueContainer = styled.div`
	display: flex;
`;

export const SpecAdditionalInfoText = styled(M100)`
	display: inline-block;
	margin-top: 12px;
	margin-bottom: 20px;
	color: var(--neutral-300);

	${media.ll`
		margin-bottom: 24px;
	`}

	${media.xl`
		margin-bottom: 28px;
	`}
`;

export const KeyDetailsPill = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	padding: 8px 12px;
	height: 61px;

	${media.xl`
		padding: 10px 16px;
		border-radius: 16px;
		height: 68px;
	`};
`;

export const GrayPill = styled(KeyDetailsPill)`
	justify-content: flex-end;
	background: var(--neutral-500);
	color: var(--white);
	flex-grow: 0;

	span {
		${h600}
		white-space: nowrap;
	}

	span + span {
		${h900}
	}

	span:only-child {
		${h900}
	}
`;

export const SpreadPill = styled.div`
	display: flex;
	flex-direction: row;
	border-radius: var(--bigger-border-radius);
	padding: 10px 16px;
	height: 61px;
	background: var(--neutral-500);
	color: var(--white);
	gap: 4px;

	${media.xl`
		padding: 12px 20px;
		height: 68px;
		gap: 8px;
	`};

	div {
		display: flex;
		flex-direction: column;
	}

	.separator {
		${h900}
		line-height: 21px;
		align-self: flex-end;
	}

	span {
		${h600}
		white-space: nowrap;
	}

	span + span {
		${h900}
	}

	span:only-child {
		${h900}
	}
`;
