/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState } from 'react';
import { createPaperOrderSchema } from './model';
import {
	Environment,
	PriceType,
	PaperInstruments,
	QuoteType,
	SpreadPriceFormat,
} from 'src/constants/contract';

export const usePaperProgressBar = formMethods => {
	const [progress, setProgress] = useState(0);

	formMethods.watch(fields => {
		const requiredFields = Object.keys(createPaperOrderSchema.fields).filter(
			fieldName => createPaperOrderSchema.fields[fieldName]?._exclusive?.required
		);

		let allRequiredFields = requiredFields.length;
		let filledFields = Object.keys(fields).reduce((sum, fieldName) => {
			// can't use the regular rule for the validity, because it's an object
			if (fieldName === 'validity') {
				return fields[fieldName].localDate ? sum + 1 : sum;
			}

			if (!requiredFields.includes(fieldName) || !fields[fieldName]) {
				return sum;
			}

			return sum + 1;
		}, 0);

		const { runsRequired, instrument, selectedProductPreset } = fields;

		const isCalendarSpread = instrument === PaperInstruments.Spread;

		if (fields.contractPricing?.length > 0) {
			const arrayOfFields = fields.contractPricing.reduce((acc, field) => {
				const {
					counterparties,
					fullContactsList,
					delivery,
					futuresMonth,
					priceType,
					type,
					volume,
					runs,
					currencyUnit,
					firstLegMonth,
					firstLegFuturesMonth,
					secondLegMonth,
					secondLegFuturesMonth,
					firstLegPrice,
					secondLegDelivery,
					// ignored fields
					secondLegQuote,
					firstLegFuturesMonthChanged,
					secondLegFuturesMonthChanged,
					totalVolume,
					principal,
					principalUser,
					principalVisibility,
					spreadType,
					...rest
				} = field;

				if (isCalendarSpread) {
					const spreadPriceFormat = selectedProductPreset?.instruments.find(
						i => i.type === instrument
					).spread_price_format;

					if (spreadPriceFormat === SpreadPriceFormat.PayCash) {
						return [
							...acc,
							{
								...rest,
								...(field.orderType === QuoteType.Firm && {
									firstLegPrice,
									volume,
								}),
								firstLegMonth,
								firstLegFuturesMonth,
								secondLegMonth,
								spreadType,
								secondLegFuturesMonth,
								...(field.environment === Environment.OTC && {
									counterparties: field.counterparties,
								}),
							},
						];
					}

					return [
						...acc,
						{
							...rest,
							...(field.orderType === QuoteType.Firm && {
								...(runsRequired && { runs }),
								firstLegPrice,
							}),
							delivery,
							secondLegDelivery,
						},
					];
				}

				return [
					...acc,
					{
						...rest,
						type,
						delivery,
						currencyUnit,
						priceType,
						...(priceType !== PriceType.Flat && {
							futuresMonth,
						}),
						...(field.orderType === QuoteType.Firm && {
							volume,
							...(runsRequired && { runs }),
						}),
						...(field.environment === Environment.OTC && {
							counterparties: field.counterparties,
						}),
					},
				];
			}, []);

			const { totalCount, filledCount } = countFilledFieldsInArray(arrayOfFields);
			allRequiredFields += totalCount;
			filledFields += filledCount;
		}

		const progress = Math.round((100 * filledFields) / allRequiredFields);
		setProgress(progress);
	});

	return progress;
};

const countFilledFieldsInArray = data => {
	let filledCount = 0;
	let totalCount = 0;

	for (const item of data) {
		const fieldNames = Object.keys(item);
		totalCount += fieldNames.length;

		filledCount += fieldNames.filter(field => {
			if (field === 'delivery' || field === 'secondLegDelivery') {
				return !!item[field]?.startDate && !!item[field]?.endDate;
			}
			if (['firstLegMonth', 'secondLegMonth'].includes(field)) {
				return !!item[field]?.startDate;
			}
			return !!item[field];
		}).length;
	}

	return {
		filledCount,
		totalCount,
	};
};
