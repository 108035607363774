/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { MarkdownArticleBody } from 'src/components/Markdown/MarkdownArticleBody';
import { H800, T200 } from 'src/components/Typography';
import { useIntersectionObserver } from '../helpers/useIntersectionObserver';
import { useIsScrolling } from '../helpers/useIsScrolling';
import {
	ChapterContainer,
	ChapterTitle,
	GuidebookContentContainer,
	StyledRichTextBody,
	SubChapterContainer,
	SubChapterTitle,
} from '../styled';

export const GuidebookContent = memo(({ chapters, setActiveChapter, containerScrollRef }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const linksRefs = useRef({});
	const { isScrollingToElement, handleIsScrolling } = useIsScrolling(containerScrollRef);
	const intersectedChapter = useIntersectionObserver(linksRefs, chapters, isScrollingToElement);

	useEffect(() => {
		setActiveChapter(intersectedChapter);
	}, [intersectedChapter, setActiveChapter]);

	useEffect(() => {
		const { hash } = location;
		const chapterKey = hash?.replace('#', '');
		const element = linksRefs.current?.[chapterKey];
		let interval;

		if (element) {
			let elementPosition = element.offsetTop;

			//interval is needed to be sure that everything is loaded and in right position
			interval = setInterval(() => {
				const currentElementPosition = element.offsetTop;

				if (elementPosition === currentElementPosition) {
					handleIsScrolling(true, () => setActiveChapter(chapterKey));
					element.scrollIntoView();
					clearInterval(interval);
				}

				elementPosition = currentElementPosition;
			}, 100);
		}

		return () => clearInterval(interval);
	}, [location, setActiveChapter, handleIsScrolling]);

	return (
		<GuidebookContentContainer className="guidebook-container">
			{chapters?.map(({ anchor, title, description, subChapters }) => (
				<ChapterContainer key={anchor}>
					<div ref={el => (linksRefs.current[anchor] = el)} id={anchor}>
						<ChapterTitle>{title}</ChapterTitle>
						<StyledRichTextBody>
							<MarkdownArticleBody>{description}</MarkdownArticleBody>
						</StyledRichTextBody>
					</div>

					{subChapters.map(
						({
							anchor: subChapterAnchor,
							title: subChapterTitle,
							description: subChapterDescription,
						}) => (
							<SubChapterContainer
								key={subChapterAnchor}
								ref={el => (linksRefs.current[subChapterAnchor] = el)}
								id={subChapterAnchor}
							>
								<SubChapterTitle>{subChapterTitle}</SubChapterTitle>
								<StyledRichTextBody>
									<MarkdownArticleBody>
										{subChapterDescription}
									</MarkdownArticleBody>
								</StyledRichTextBody>
							</SubChapterContainer>
						)
					)}
				</ChapterContainer>
			))}
			{!chapters?.length && (
				<>
					<H800>{t('user_guide')}</H800>
					<T200>{t('no_data')}</T200>
				</>
			)}
		</GuidebookContentContainer>
	);
});
