/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';

export const OrderCreatorShipmentSectionContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
	width: 468px;

	${media.ll`
		width: 520px;
	`}

	${media.xl`
		gap: 16px;
		width: 602px;
	`}
`;

export const ContentCell = styled.div`
	max-width: 230px;

	${media.ll`
		max-width: 256px;
	`}

	${media.xl`
		max-width: 293px;
	`}

	header {
		background: var(--neutral-800);
		height: 51px;
		line-height: 51px;
		border-top-left-radius: var(--bigger-border-radius);
		border-top-right-radius: var(--bigger-border-radius);
		padding-left: 20px;
	}

	section {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
		padding: 12px 20px 24px;
		background: var(--neutral-850);
		height: 126px;
		border-bottom-left-radius: var(--bigger-border-radius);
		border-bottom-right-radius: var(--bigger-border-radius);
	}
`;
