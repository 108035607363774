/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled, { css } from 'styled-components';
import { media } from 'src/_helpers';

export const CircleContainer = styled.div`
	filter: drop-shadow(0px 4px 4px rgba(57, 87, 191, 0.03));
`;

const circle = css`
	border-radius: 50%;
	width: 19.2px;
	height: 19.2px;

	& svg {
		width: 8px;
		height: 7px;
	}

	${media.xl`
		width: 24px;
		height: 24px;
		outline: 8px solid rgb(48 55 95 / 30%);

		& svg {
			width: 10px;
			height: 9px;
		}
	`}
`;

export const EmptyCircle = styled.div`
	border-radius: 50%;
	border: 2px solid var(--neutral-600);
	${circle}
	${media.xl`
		outline: none;
	`}
`;

export const CompletedByMeCircle = styled.div`
	border: 2px solid var(--green-200);
	filter: drop-shadow(0px 4px 4px rgba(57, 87, 191, 0.03));
	outline: 6px solid rgb(48 55 95 / 30%);
	${circle};
`;

export const CompletedByCounterpartyCircle = styled.div`
	border: 2px solid var(--green-200);
	filter: drop-shadow(0px 4px 4px rgba(57, 87, 191, 0.03));
	outline: 6px solid rgb(48 55 95 / 30%);
	display: flex;
	align-items: center;
	justify-content: center;
	${circle}

	& path {
		stroke: var(--green-200);
	}
`;

export const CompletedCircle = styled.div`
	border-radius: 50%;
	border: 3px solid var(--green-200);
	background: var(--green-200);
	box-shadow: 0px 4px 4px rgba(57, 143, 191, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	outline: 6px solid rgb(48 55 95 / 30%);
	${circle}

	& path {
		stroke: var(--neutral-1000);
	}
`;
