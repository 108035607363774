/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useActiveMarket } from 'src/_routes/useActiveMarket';

const MultipleOrderActoinsContext = createContext({
	isSelected: false,
	isListEmpty: false,
	areAllSelected: false,
	areAnyOrdersSelected: false,
	toggleOrderSelection: () => {},
	selectAll: () => {},
	clearSelection: () => {},
	addSelectionKeys: () => {},
	selectedOrderIds: [],
	selectedOrderType: null,
	selectedOrderEnvironment: null,
	removeSelectionKeys: () => {},
});

export const MultipleOrderActionsProvider = ({ children }) => {
	// selectionMap format: { key: [isSelected, orderType]}
	// isSelected = true / false
	// orderType = bid / offer / calendar_spread
	// environment
	const [selectionMap, setSelectionMap] = useState(new Map());

	const [areAllSelected, setAllSelected] = useState(false);

	const isSelected = orderId => selectionMap.get(orderId)?.[0] === true;

	const market = useActiveMarket();

	// Clear selected orders when the market changes
	useEffect(() => {
		clearSelection();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [market]);

	const addSelectionKeys = useCallback(list => {
		setSelectionMap(prevState => {
			const newState = new Map(prevState);
			list.forEach(([id, ...meta]) => {
				if (!newState.has(id)) {
					newState.set(id, [false, ...meta]);
				}
			});
			return newState;
		});
	}, []);

	const removeSelectionKeys = useCallback(list => {
		setSelectionMap(prevState => {
			const newState = new Map(prevState);
			list.forEach(id => {
				if (newState.has(id)) {
					newState.delete(id);
				}
			});
			return newState;
		});
	}, []);

	const toggleOrderSelection = useCallback(
		orderId => {
			setSelectionMap(prevState => {
				const newState = new Map(prevState);
				const previousOrderState = prevState.get(orderId);
				const [flag, ...meta] = previousOrderState;
				newState.set(orderId, [!flag, ...meta]);
				return newState;
			});
			if (areAllSelected) {
				setAllSelected(false);
			}
		},
		[areAllSelected]
	);

	const selectAll = useCallback(flag => {
		setAllSelected(flag);
		setSelectionMap(prevState => {
			const newState = new Map(prevState);
			newState.forEach((_, key, map) => {
				const [, ...meta] = map.get(key);
				map.set(key, [flag, ...meta]);
			});
			return newState;
		});
	}, []);

	const clearSelection = useCallback(() => {
		setAllSelected(false);
		setSelectionMap(new Map());
	}, []);

	const isListEmpty = selectionMap.size === 0;
	const selectedOrders = Array.from(selectionMap.values()).filter(array => array[0]);
	const areAnyOrdersSelected = selectedOrders.length > 0;
	const selectedOrderIds = Array.from(selectionMap.entries())
		.filter(([key, [flag, meta]]) => flag === true)
		.map(([key]) => key);

	// we only care about the type and environment if the single order is selected
	const selectedOrderType =
		selectedOrderIds.length === 1 ? selectionMap.get(selectedOrderIds[0])[1] : null;
	const selectedOrderEnvironment =
		selectedOrderIds.length === 1 ? selectionMap.get(selectedOrderIds[0])[2] : null;

	useEffect(() => {
		if (selectionMap.size === 0) {
			setAllSelected(false);
		}
	}, [selectionMap.size]);

	return (
		<MultipleOrderActoinsContext.Provider
			value={{
				isSelected,
				isListEmpty,
				areAllSelected,
				areAnyOrdersSelected,
				toggleOrderSelection,
				selectAll,
				clearSelection,
				addSelectionKeys,
				selectedOrderIds,
				selectedOrderType,
				selectedOrderEnvironment,
				removeSelectionKeys,
			}}
		>
			{children}
		</MultipleOrderActoinsContext.Provider>
	);
};

export const useMultipleOrderActions = () => useContext(MultipleOrderActoinsContext);
