/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import * as Styled from './styled';

export const NoApprovedContactsPlaceholder = () => {
	const { t } = useTranslation();

	return (
		<Styled.NoApprovedContactsMessage>
			<Styled.ApprovedContactsHeader>
				{t('approved_contacts_filter')}
			</Styled.ApprovedContactsHeader>
			<Trans i18nKey="no_approved_contacts_message">
				<Link to={ROUTES.userNetwork} />
			</Trans>
		</Styled.NoApprovedContactsMessage>
	);
};
