/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useFeatureFlags } from './FeatureFlagsContext';

export const Flags = ({
	authorizedFlags = [],
	children,
	renderOn = () => {},
	renderOff = () => {},
}) => {
	const { featureFlags } = useFeatureFlags();

	const matchingFlags = featureFlags.filter(flag => {
		if (Array.isArray(authorizedFlags)) {
			return flag.isActive && authorizedFlags.includes(flag.name);
		} else {
			return flag.isActive && flag.name === authorizedFlags;
		}
	});

	const resolveRender = () => (children ? children : renderOn());

	return matchingFlags.length ? resolveRender() : renderOff();
};
