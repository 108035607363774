/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled from 'styled-components';

export const ContractLink = styled.a`
	display: flex;
	gap: 6px;
	line-height: 1;
	align-items: end;
	color: var(--primary-100);
`;
