/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalSVG } from 'src/assets/icons/close-modal.svg';
import * as Styled from './styled';

export const ContextualGuide = ({ i18nKey }) => {
	const { t } = useTranslation(['contextual_guide']);

	const gtagTriggerID = i18nKey + '_guide';

	const dialogRef = useRef();

	const openDialog = () => {
		dialogRef.current.showModal();
	};

	const handleClickOutside = e => {
		if (e.target.tagName.toLowerCase() === 'dialog') {
			dialogRef.current.close();
		}
	};

	const closeDialog = () => {
		dialogRef.current.close();
	};

	const termsKey = `${i18nKey}.list`;
	const termsList = t(termsKey, { returnObjects: true });
	const hasTerms = termsList !== termsKey;

	const subHeaderKey = `${i18nKey}.subheader`;
	const subHeader = t(subHeaderKey);
	const hasSubHeader = subHeader !== subHeaderKey;

	const additionalInfoKey = `${i18nKey}.info2`;
	const additionalInfo = t(additionalInfoKey, { joinArrays: '\n\n' });
	const hasAdditionalInfo = additionalInfo !== additionalInfoKey;

	const additionalTermsKey = `${i18nKey}.list2`;
	const additionalTermsList = t(additionalTermsKey, { returnObjects: true });
	const hasAdditionalTerms = additionalTermsList !== additionalTermsKey;

	return (
		<>
			<Styled.InfoButton
				type="button"
				onClick={openDialog}
				data-test={`${i18nKey}_button`}
				id={gtagTriggerID}
			>
				<Styled.InfoIcon />
			</Styled.InfoButton>
			<Styled.Dialog
				ref={dialogRef}
				onClick={handleClickOutside}
				data-test={`${i18nKey}_dialog`}
			>
				<Styled.Body>
					<Styled.CloseButton type="button" onClick={closeDialog}>
						<CloseModalSVG />
					</Styled.CloseButton>
					<Styled.Title>{t(`${i18nKey}.title`)}</Styled.Title>
					<Styled.ScrollableArea>
						<div>
							<Styled.Description>
								{t(`${i18nKey}.info`, { joinArrays: '\n\n' })}
							</Styled.Description>
							{hasTerms && <TermsList termsList={termsList} />}
							{hasSubHeader && <Styled.SubHeader>{subHeader}</Styled.SubHeader>}
							{hasAdditionalInfo && (
								<Styled.Description>{additionalInfo}</Styled.Description>
							)}
							{hasAdditionalTerms && <TermsList termsList={additionalTermsList} />}
						</div>
					</Styled.ScrollableArea>
					<Styled.Footer />
				</Styled.Body>
			</Styled.Dialog>
		</>
	);
};

const TermsList = ({ termsList }) => (
	<Styled.Terms>
		{termsList.map(({ title, text }, index) => (
			<Fragment key={index}>
				<dt>{title}</dt>
				<dd>{text}</dd>
			</Fragment>
		))}
	</Styled.Terms>
);
