/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { QuoteType, OrderType } from 'src/constants/contract';

export const getTypeLabel = (t, isIndicative, type) => {
	const quoteType = isIndicative ? QuoteType.Indicative : QuoteType.Firm;
	const orderType = type === OrderType.Sell ? 'offer' : 'bid';

	return t(`${quoteType}_${orderType}`);
};
