/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState, useLayoutEffect } from 'react';
import { MobilePlatform } from 'src/constants/mobile';

export const isAndroidClient = userAgent => /android/i.test(userAgent);
export const isIOSClient = userAgent => /iPad|iPhone|iPod/.test(userAgent);

export const useMobileBrowser = () => {
	const [clientPlatform, setClientPlatform] = useState(null);

	const detectMobileBrowser = () => {
		if (
			!window?.navigator ||
			'ontouchstart' in document.documentElement === false ||
			window.matchMedia('(pointer:coarse)').matches === false
		) {
			return;
		}

		const userAgent = window.navigator.userAgent;

		if (isAndroidClient(userAgent)) {
			setClientPlatform(MobilePlatform.Android);
		}

		if (isIOSClient(userAgent)) {
			setClientPlatform(MobilePlatform.IOS);
		}
	};

	useLayoutEffect(() => {
		detectMobileBrowser();

		window.addEventListener('resize', detectMobileBrowser);

		return () => {
			window.removeEventListener('resize', detectMobileBrowser);
		};
	}, []);

	return {
		isMobile: clientPlatform !== null,
		isAndroid: clientPlatform === MobilePlatform.Android,
	};
};
