/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { tOrderType, OrderType, isShipmentInco, IncoType } from 'src/constants/contract';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { listIncoTerms, getGrades, useMultiOriginsOptions } from 'src/_api';
import { useMemo } from 'react';
import { mapGrades } from 'src/_helpers/grades';
import { getAvailableHarvestYears, tSpeciality, Speciality } from 'src/constants/product';
import { useWatchPhysicalCounterFields } from '../../helpers/useWatchPhysicalCounterFields';

export const useQuoteCounterFields = lastCounter => {
	const { t } = useTranslation();

	const {
		getValues,
		setValue,
		control: { _defaultValues },
	} = useFormContext();

	const {
		volumeValue,
		productValue,
		incoValue,
		portValue,
		dateValue,
		originValue,
		portNames,
		gradeValue,
		customSpecsValue,
	} = useWatchPhysicalCounterFields();

	// Quote
	const quoteOptions = Object.values(OrderType).map(value => ({
		text: tOrderType(t, value),
		value,
	}));

	// Inco
	const { data: incoList = [], isLoading: isLoadingInco } = useQuery('inco', listIncoTerms);

	const incoOptions = incoList
		.map(({ _key, name }) => ({
			text: name,
			value: _key,
		}))
		.filter(inco => {
			if (incoValue === IncoType.RENDU && inco.value !== IncoType.RENDU) {
				return false;
			} else if (incoValue !== IncoType.RENDU && inco.value === IncoType.RENDU) {
				return false;
			}
			return true;
		});

	// Shipment
	const shipmentDeliveryLabel = !incoValue
		? t('shipment_delivery')
		: isShipmentInco(incoValue)
		? t('shipment')
		: t('delivery');

	// Origin
	const originIds = (originValue || []).map(origin =>
		typeof origin === 'string' ? origin : origin.id
	);

	const { data: originOptions = [], isFetched: isLoadingOrigins } = useMultiOriginsOptions();

	useEffect(() => {
		if (originOptions.length && originValue) {
			const origins = originOptions.filter(origin => {
				if (originValue.includes(origin.id)) {
					return true;
				}
				return false;
			});
			setValue('originObjects', origins);
		}
	}, [originOptions, originValue, setValue]);

	// Grade
	const { data: gradesData = { grades: [], specs: [] }, isLoading: isLoadingGrades } = useQuery(
		['grades', productValue],
		() => getGrades({ product_id: productValue }),
		{
			enabled: !!productValue,
			staleTime: 0,
			cacheTime: 0,
			select: list => mapGrades(t, list, originIds),
		}
	);

	const gradesOptions = useMemo(
		() => gradesData.grades.map(({ text, value }) => ({ text, value })),
		[gradesData.grades]
	);

	useEffect(() => {
		if (isLoadingGrades || !gradeValue) {
			return;
		}

		const gradeObject = gradesData.grades.find(item => item.key === gradeValue);

		if (gradeObject) {
			setValue('gradeObject', gradeObject);
			setValue('isCustomGrade', gradeObject.is_custom);
			setValue('customSpecs', gradeObject.is_custom ? customSpecsValue : null);
			setValue('specs', gradesData.specs[gradeValue]);
		} else {
			setValue('gradeObject', {});
			setValue('isCustomGrade', false);
			setValue('customSpecs', null);
			setValue('specs', {});
		}
	}, [
		gradeValue,
		gradesData.specs,
		gradesData.grades,
		isLoadingGrades,
		setValue,
		customSpecsValue,
		gradesData,
	]);

	// Harvest
	const harvestOptions = getAvailableHarvestYears().map(year => ({
		text: year,
		value: year,
	}));

	// Speciality
	const specialityOptions = Object.values(Speciality).map(speciality => ({
		text: tSpeciality(t, speciality),
		value: speciality,
	}));

	// Port
	const addPortName = (portKey, portName, countryCode) => {
		const oldPortNames = getValues('portNames') || {};
		const name = `${portName}, ${countryCode}`;

		if (portKey && portName && !oldPortNames[portKey]) {
			setValue('portNames', {
				...oldPortNames,
				[portKey]: name,
			});
			setValue('ports.0.name', portKey);
		}
	};

	useEffect(() => {
		if (portValue && portNames) {
			setValue('ports.0.name', portValue);
		}
	}, [portNames, portValue, setValue]);

	return {
		quote: {
			options: quoteOptions,
			counterable: false,
		},
		volume: {
			hasChanged: _defaultValues.volume !== volumeValue,
		},
		inco: {
			options: incoOptions,
			ready: !isLoadingInco && incoValue !== IncoType.RENDU,
			hasChanged: _defaultValues.inco !== incoValue,
		},
		port: {
			hasChanged: _defaultValues.port !== portValue,
			addName: addPortName,
		},
		date: {
			label: shipmentDeliveryLabel,
			hasChanged: !(
				_defaultValues.date.startDate === dateValue.startDate &&
				_defaultValues.date.endDate === dateValue.endDate &&
				_defaultValues.date.format === dateValue.format
			),
		},
		origin: {
			options: originOptions,
			counterable: false,
			ready: !isLoadingOrigins,
			show: lastCounter.origin_countries.length !== 0,
		},
		grade: {
			counterable: false,
			options: gradesOptions,
			specs: gradesData.specs,
		},
		harvest: {
			options: harvestOptions,
			counterable: false,
			show: !!lastCounter.harvest_year,
		},
		speciality: {
			options: specialityOptions,
			counterable: false,
			show: !!lastCounter.speciality,
		},
	};
};
