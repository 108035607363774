/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUser } from 'src/_store/selectors';
import { createOrder, updateOrder } from 'src/_api/orders.api';
import {
	createPhysicalOrderSchema,
	mapDataToApi,
	defaultValues,
	mapPreviewDataToOrderView,
} from './model';
import { DrawerContextKeys, useDrawerContext } from '../../Drawer/DrawerContext';
import { OrderType } from 'src/constants/contract';
import { usePhysicalProgressBar } from './usePhysicalProgressBar';
import { HeaderType } from '../../Drawer/constants';
import { isFooterTabActive, FooterTabs } from 'src/_helpers/session';
import { getFormErrorMessage } from 'src/_helpers/getFormErrorMessage';

export const useCreatePhysicalOrderForm = formValues => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const {
		addMyOrderId,
		[DrawerContextKeys.createDrawer]: {
			orderPreviewData,
			setOrderPreviewData,
			visibility: [isOpen, { close }],
			setTemporaryHeader,
			setIsFormDirty,
		},
	} = useDrawerContext();

	const baseResolver = yupResolver(createPhysicalOrderSchema, { abortEarly: false });
	const resolver = async (data, ctx, options) => {
		return baseResolver(data, { volume: data.volume }, options);
	};

	const formMethods = useForm({
		resolver,
		defaultValues: formValues || defaultValues,
	});
	const {
		formState: { isDirty },
		reset,
	} = formMethods;

	useEffect(() => {
		if (isDirty && isOpen) {
			setIsFormDirty(isDirty);
		} else {
			setIsFormDirty(false);
		}
	}, [setIsFormDirty, isDirty, isOpen]);

	const progress = usePhysicalProgressBar(formMethods);

	const invalidateOrders = id => {
		reset({}, { keepValues: true });
		addMyOrderId(id);
		queryClient.invalidateQueries(['physical-orders']);
		queryClient.invalidateQueries(['order', id]);

		if (isFooterTabActive(FooterTabs.MyOrders)) {
			queryClient.invalidateQueries(['my-orders']);
		}
	};

	const { mutate: updateOrderMutation, isLoading: isSavingOrder } = useMutation(updateOrder, {
		onSuccess: ({ _key }, { environment }) => {
			if (_key) {
				setTemporaryHeader({
					type: HeaderType.Info,
					message: t('changes_saved'),
				});
				close();
				invalidateOrders(_key);
				navigate(`/?orderEnvironment=${environment}&orderId=${_key}`);
			} else {
				setTemporaryHeader({
					type: HeaderType.Warning,
					message: t('order_creation_failed'),
				});
			}
		},
		onError: () => {
			setTemporaryHeader({
				type: HeaderType.Warning,
				message: t('order_creation_failed'),
			});
		},
	});

	const { mutate: createOrderMutation, isLoading: isCreatingOrder } = useMutation(createOrder, {
		onSuccess: ({ _key }, { environment }) => {
			if (_key) {
				close();
				invalidateOrders(_key);
				navigate(`/?orderEnvironment=${environment}&orderId=${_key}`);
				setTemporaryHeader({
					type: HeaderType.Info,
					message:
						orderPreviewData.order_type === OrderType.Buy
							? t('bid_created')
							: t('offer_created'),
				});
			} else {
				setTemporaryHeader({
					type: HeaderType.Warning,
					message: t('order_creation_failed'),
				});
			}
			reset({}, { keepValues: true });
		},
		onError: () => {
			setTemporaryHeader({ type: HeaderType.Warning, message: t('order_creation_failed') });
		},
	});

	const onSubmit = data => {
		const body = mapDataToApi(data);

		if (data.isEditing) {
			body.id = data._key;
			updateOrderMutation(body);
		} else if (orderPreviewData) {
			createOrderMutation(body);
		} else {
			setOrderPreviewData(mapPreviewDataToOrderView(body, data, user));
		}
	};

	const onSubmitError = errors => {
		const errorMessage = getFormErrorMessage(errors, t);
		setTemporaryHeader({ type: HeaderType.Warning, message: errorMessage });
	};

	return {
		formMethods,
		isSubmitting: isSavingOrder || isCreatingOrder,
		progress,
		onSubmit: formMethods.handleSubmit(onSubmit, onSubmitError),
	};
};
