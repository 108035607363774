/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';

import { showRefreshToast } from 'src/_store/actions';
import { fetchActivityLogs } from 'src/_api';
import { duration } from 'src/constants/duration';
import { getUserId } from 'src/_store/selectors/user.selectors';

export function useActivityLogsUpdater(queryId, data, api) {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const userId = useSelector(getUserId);

	useEffect(() => {
		let lastProcessedId = null;
		async function checkLatest() {
			try {
				const { queryParam } = api;
				const lastActivityLog = (
					await fetchActivityLogs({ _limit: 1, [queryParam]: queryId })
				)?.[0];

				if (lastActivityLog && lastProcessedId !== lastActivityLog._key) {
					lastProcessedId = lastActivityLog._key;

					const isAlreadyLoaded = data?.some(item => item._key === lastActivityLog._key);

					if (!isAlreadyLoaded && lastActivityLog.user_id !== userId) {
						dispatch(showRefreshToast());
					}
				}
			} catch {
				console.warn('Could not load latest activity log');
			}
		}

		const interval = setInterval(checkLatest, duration.minute);

		return () => {
			window.clearInterval(interval);
		};
	}, [queryClient, queryId, dispatch, data, api, userId]);
}
