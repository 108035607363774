/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip/Tooltip';
import * as Styled from './styled';
import { Colors } from 'src/constants/colors';

export const AvatarType = {
	Small: 'small',
	Medium: 'medium',
	Large: 'large',
};

export const BorderSize = {
	large: '3px',
	medium: '2px',
	small: '1px',
};

export const DotType = {
	Online: 'online',
	Offline: 'offline',
	Active: 'active',
	ActiveOffline: 'activeOffline',
};

export const Avatar = ({
	size = AvatarType.Small,
	backgroundColor,
	dotBackgroundColor,
	initials,
	dotType,
	isSelected = false,
	withBorder = false,
	textColor,
	className,
	borderColor,
	borderSize,
	withApprovedContactBadge,
	...params
}) => {
	const { t } = useTranslation();

	return (
		<Styled.AvatarCircle
			className={clsx(className, size)}
			$size={size}
			$backgroundColor={backgroundColor}
			$selected={isSelected}
			$withBorder={withBorder}
			$textColor={textColor}
			$borderColor={borderColor}
			$borderSize={borderSize}
			{...params}
		>
			{initials?.toUpperCase()}
			{dotType && <StatusDot dotType={dotType} dotBackgroundColor={dotBackgroundColor} />}
			{withApprovedContactBadge && (
				<Tooltip>
					<TooltipTrigger>
						<Styled.MyContactBadge data-test="order-creator-my-approved-contacts">
							<Styled.BadgeIcon />
						</Styled.MyContactBadge>
					</TooltipTrigger>
					<TooltipContent>{t('approved_counterparty')}</TooltipContent>
				</Tooltip>
			)}
		</Styled.AvatarCircle>
	);
};

export const StatusDot = ({ dotType, dotBackgroundColor = Colors.Neutral1000 }) => {
	switch (dotType) {
		case DotType.Online:
			return (
				<Styled.OnlineDot $dotBackgroundColor={dotBackgroundColor} className="status-dot" />
			);

		case DotType.Offline:
			return (
				<Styled.OfflineDot
					$dotBackgroundColor={dotBackgroundColor}
					className="status-dot"
				/>
			);

		case DotType.ActiveOffline:
			return (
				<Styled.ActiveOfflineDot
					$dotBackgroundColor={dotBackgroundColor}
					className="status-dot"
				/>
			);

		default:
			return (
				<Styled.ActiveConversationDot
					$dotBackgroundColor={dotBackgroundColor}
					className="status-dot"
				/>
			);
	}
};
