/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { tQuoteValueType, QuoteType, tEnvironment } from 'src/constants/contract';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import {
	SectionContainer,
	SectionTitleWithInfo,
	SectionTitleInfo,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from '../../../styled';
import { ReactComponent as ExternalLinkIconSVG } from 'src/assets/icons/external_link_icon.svg';
import * as Styled from './styled';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';

export const OutrightSection = ({ order, changes = {} }) => {
	const { t } = useTranslation();

	const { isBrokerOrder } = useOrderDetails(order);
	const { data, isLoading: isLoadingPresets } = useAllPaperPresets();

	const preset = isLoadingPresets
		? {}
		: data?.presets?.find(({ _key }) => _key === order.preset_id);

	const areRunsRequired = preset?.runs;

	const quoteType = order.is_indicative ? QuoteType.Indicative : QuoteType.Firm;
	const orderTypeLabel =
		order.counterIndex > 0 || order.is_counter_preview
			? t('counter_type')
			: t('order_type_label');

	return (
		<SectionContainer data-test="contract-and-pricing-section">
			<SectionTitleWithInfo>
				{t('quote')}
				{isBrokerOrder && (
					<SectionTitleInfo>{t('external_brokerage_fees_may_apply')}</SectionTitleInfo>
				)}
			</SectionTitleWithInfo>
			<SectionGrid>
				{!order.trade_id && (
					<SectionGridContent className={clsx({ changed: changes.is_indicative })}>
						<SectionGridLabel>{orderTypeLabel}</SectionGridLabel>
						<SectionGridText data-test="pricing-section-order-type">
							{tQuoteValueType(t, quoteType)}
						</SectionGridText>
					</SectionGridContent>
				)}
				{areRunsRequired && (
					<SectionGridContent className={clsx({ changed: changes.runs })}>
						<SectionGridLabel>{t('runs')}</SectionGridLabel>
						<SectionGridText data-test="contract-and-pricing-section-runs">
							{order.runs || '---'}
						</SectionGridText>
					</SectionGridContent>
				)}
				{order.futures_contract && (
					<SectionGridContent>
						<SectionGridLabel>{t('futures_contract')}</SectionGridLabel>
						<SectionGridText data-test="contract-and-pricing-section-futures-contract">
							{`${order.futures_contract} ${moment(order.futures_contract_date)
								.utc()
								.format('MMM YYYY')}`}
						</SectionGridText>
					</SectionGridContent>
				)}
				{!order.trade_id && (
					<SectionGridContent>
						<SectionGridLabel>{t('environment')}</SectionGridLabel>
						<SectionGridText data-test="contract-and-pricing-section-environment">
							{tEnvironment(t, order.environment)}
						</SectionGridText>
					</SectionGridContent>
				)}
				<SectionGridContent>
					<SectionGridLabel>{t('contract_terms')}</SectionGridLabel>
					<SectionGridText data-test="contract-and-pricing-section-contract">
						<Styled.ContractLink
							target="_blank"
							rel="noreferrer"
							href={order.terms.original_link}
						>
							{order.terms.name} <ExternalLinkIconSVG />
						</Styled.ContractLink>
					</SectionGridText>
				</SectionGridContent>
			</SectionGrid>
		</SectionContainer>
	);
};
