/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const mapCounterFiles = (order, allCounters = []) => {
	const orderWithCounters = [order, ...allCounters];

	return orderWithCounters.map((_, index) => {
		let files = order.files || [];
		for (let i = 0; i < index; i++) {
			files = [
				...files.map(file => ({ ...file, changed: false })),
				...(allCounters[i].files
					? allCounters[i].files.map(file => ({ ...file, changed: true }))
					: []),
			];
		}
		return files;
	});
};
