/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useLocation } from 'react-router';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/constants/routes';
import { TradeStep } from 'src/constants/tradeStatus';
import { exportTrades, getMyTradebookData, sendTradesViaEmail } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useMutation } from 'react-query';

const tradesCountQuery = ({ queryKey: [key, params] }) => {
	return getMyTradebookData(params);
};

export const useDownloadTradesButton = ({ tradeStep, market }) => {
	const { pathname } = useLocation();
	const isClosedTrades = pathname.includes(ROUTES.closedTrades) || tradeStep === TradeStep.Closed;
	const { addToast } = useToast();
	const { t } = useTranslation();

	const { data = {}, isFetched } = useQuery(
		[
			'trades-count',
			{
				...(isClosedTrades && { step: TradeStep.Closed }),
				market,
			},
		],
		tradesCountQuery
	);

	const { mutate: mutateSendViaEmail } = useMutation(sendTradesViaEmail, {
		onSuccess: () => {
			addToast({
				message: t('csv_file_sent_to_email_address'),
				kind: 'info',
			});
		},
		onError: err => {
			addToast({
				message: t('something_went_wrong'),
				kind: 'error',
			});
		},
	});

	const canDownloadTrades = isFetched && data?.items?.length > 0;

	const errorToast = () => {
		addToast({
			message: t('no_trades_to_download'),
			kind: 'error',
		});
	};

	const downloadTrades = ({ tradeId }) => {
		if (canDownloadTrades) {
			exportTrades({
				query: {
					market,
					...(tradeId && { trade_id: tradeId }),
					...(isClosedTrades && { step: TradeStep.Closed }),
				},
			});
		} else {
			errorToast();
		}
	};

	const sendViaEmail = ({ tradeId }) => {
		if (canDownloadTrades) {
			mutateSendViaEmail({
				query: {
					market,
					send_email: true,
					...(tradeId && { trade_id: tradeId }),
					...(isClosedTrades && { step: TradeStep.Closed }),
				},
			});
		} else {
			errorToast();
		}
	};

	return {
		downloadTrades,
		sendViaEmail,
	};
};
