/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FolderIcon } from 'src/assets/icons/folder.svg';
import * as Styled from './styled';

export const NoDocumentInfo = () => {
	const { t } = useTranslation();

	return (
		<Styled.NoDocumentsWrapper>
			<FolderIcon />
			<Styled.NoDocumentsTitle>{t('no_documents')}</Styled.NoDocumentsTitle>
			<Styled.NoDocumentsDescriptions>
				{t('there_are_no_documents_uploaded_yet')}
			</Styled.NoDocumentsDescriptions>
		</Styled.NoDocumentsWrapper>
	);
};
