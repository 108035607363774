/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { T200 } from 'src/components/Typography';
import { StrokeButton } from 'src/components/Buttons/StrokeButton';
import { IconWrapper as BaseIconWrapper } from 'src/components/Modal';

export const Button = styled(StrokeButton)`
	width: auto;
	padding: 12px 24px;
`;

export const DocumentTypesGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-gap: 10px;
`;

export const NotesWrapper = styled.div`
	margin-top: 32px;
	margin-bottom: 40px;

	textarea {
		height: 110px;
	}
`;

export const NotesSeparator = styled.div`
	margin-bottom: 40px;
`;

export const Subtitle = styled(T200)`
	border-bottom: 1px solid var(--neutral-500);
	display: block;
	text-align: center;
	padding-bottom: 24px;
	margin-bottom: 24px;
`;

export const IconWrapper = styled(BaseIconWrapper)`
	background: transparent;
`;
