/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext, useState, useCallback } from 'react';

const HighlightItemContext = createContext({
	highlightedItemId: null,
	highlightItem: () => {},
});

export const HighlightItemProvider = ({ children }) => {
	const [highlightedItemId, setHighlightedItemId] = useState(null);

	const highlightItem = useCallback(id => setHighlightedItemId(id), []);

	return (
		<HighlightItemContext.Provider value={{ highlightedItemId, highlightItem }}>
			{children}
		</HighlightItemContext.Provider>
	);
};

export const useHighlightItemContext = () => useContext(HighlightItemContext);
