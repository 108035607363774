/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Fragment, useRef } from 'react';
import * as Styled from './styled';
import { useScrollToActiveElement } from 'src/containers/Guidebook/helpers/useScrollToActiveElement';

export const MobileGuidesNavigation = ({
	chapters = [],
	activeChapter,
	isOpen,
	toggleMobileGuidebookMenu,
	route,
}) => {
	const linksRefs = useRef({});
	const containerRef = useRef();

	useScrollToActiveElement(linksRefs, containerRef, activeChapter);

	return (
		<Styled.NavigationMenu $isOpen={isOpen} ref={containerRef}>
			{chapters.map(({ anchor, title, subChapters }) => (
				<Fragment key={anchor}>
					<Styled.MobileChapterTitleLink
						to={`${route}#${anchor}`}
						$active={activeChapter === anchor}
						ref={el => (linksRefs.current[anchor] = el)}
						onClick={toggleMobileGuidebookMenu}
					>
						{title}
					</Styled.MobileChapterTitleLink>
					{subChapters.map(({ anchor: subChapterAnchor, title: subChapterTitle }) => (
						<Styled.MobileSubChapterTitleLink
							key={subChapterAnchor}
							to={`${route}#${subChapterAnchor}`}
							$active={activeChapter === subChapterAnchor}
							ref={el => (linksRefs.current[subChapterAnchor] = el)}
							onClick={toggleMobileGuidebookMenu}
						>
							{subChapterTitle}
						</Styled.MobileSubChapterTitleLink>
					))}
				</Fragment>
			))}
		</Styled.NavigationMenu>
	);
};
