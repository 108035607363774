/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useTranslation } from 'react-i18next';
import * as Styled from './styled';

export const CopyLastOrderButton = ({ children, ...props }) => {
	const { t } = useTranslation();

	return (
		<Styled.Button darker {...props}>
			{t('copy_last_order')}
		</Styled.Button>
	);
};
