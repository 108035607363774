/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CUSTOM_CONTRACT_FILE_TYPE } from 'src/constants/files';
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';
import { CUSTOM_CONTRACT_TERMS } from 'src/constants/contract';

export const useCustomContract = () => {
	const { setValue } = useFormContext();

	const [customContractFile, setCustomContractFile] = useState();
	const [isUploadingContract, setUploadingContract] = useState(false);

	const { customContractFileValue, contractTermsValue } = useWatchPhysicalFields();

	useEffect(() => {
		if (!customContractFile && customContractFileValue?._key) {
			customContractFileValue.id = customContractFileValue.id || customContractFileValue._key;
			setCustomContractFile(customContractFileValue);
		}
	}, [customContractFile, customContractFileValue, setValue]);

	const onCustomContractFileChange = ({ target }) => {
		if (customContractFile) {
			deleteCustomContract();
		}
		setTimeout(() => {
			if (target.files && target.files[0]) {
				setCustomContractFile(target.files[0]);
				setUploadingContract(true);
			}
		}, 0);
	};

	const deleteCustomContract = (shouldValidate = true) => {
		setCustomContractFile(undefined);
		setUploadingContract(false);
		setValue('file_contract_id', null, { shouldValidate });
		setValue('file_contract', null);
	};

	const handleUploadSuccess = (id, file) => {
		const uploadedFile = file instanceof File ? file : file.file;
		setValue('file_contract_id', id);
		setValue('file_contract', uploadedFile);
		setUploadingContract(false);
	};

	return {
		accept: CUSTOM_CONTRACT_FILE_TYPE,
		onChange: onCustomContractFileChange,
		onDelete: deleteCustomContract,
		onUploaded: handleUploadSuccess,
		file: customContractFile,
		isUploading: isUploadingContract,
		enabled: contractTermsValue === CUSTOM_CONTRACT_TERMS,
	};
};
