/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderType, PaperInstruments } from 'src/constants/contract';
import { Tooltip, TooltipTrigger, TooltipContent } from 'src/components/Tooltip/Tooltip';
import * as Styled from './styled';

export const NoNegotiations = ({ orderType, instrument, isExpanded }) => {
	const { t } = useTranslation();

	const info = t('no_negotiations', {
		orderType: (instrument === PaperInstruments.Spread
			? t('spread')
			: orderType === OrderType.Buy
			? t('bid')
			: t('offer')
		).toLowerCase(),
	});

	return (
		<Styled.NoNegotiations>
			{isExpanded ? (
				<Styled.InfoIcon />
			) : (
				<Tooltip placement="bottom-start">
					<TooltipTrigger>
						<Styled.InfoIcon />
					</TooltipTrigger>
					<TooltipContent>{info}</TooltipContent>
				</Tooltip>
			)}
			{isExpanded && <Styled.NoNegotiationsInfo>{info}</Styled.NoNegotiationsInfo>}
		</Styled.NoNegotiations>
	);
};
