/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { getCapitalLetter } from 'src/_helpers/getCapitalLetter';
import { UsersModal } from '../../../../components/UsersModal/UsersModal';
import { useConsecutiveModals } from 'src/_helpers/useConsecutiveModals';
import { RemoveUserFromTradeModal } from '../paper/RemoveUserFromTradeModal/RemoveUserFromTradeModal';
import { Avatar, AvatarType } from 'src/shared/components/Avatar/Avatar';
import { useExecutionUsers } from './useExecutionUsers';
import * as Styled from './styled';

export const UsersSection = ({ tradeData }) => {
	const { tradeUsers } = tradeData;
	const {
		openModal,
		closeModal,
		isModalOpen,
		getModalsData,
		storeModalsData,
	} = useConsecutiveModals(2);

	const renderActivityUsers = () => {
		return tradeUsers.map((user, index) => {
			const userInitials = `${getCapitalLetter(user?.first_name)}${getCapitalLetter(
				user?.last_name
			)}`;

			if (index > 3) {
				return null;
			}

			const avatarInitials = index === 3 ? `+${tradeUsers.length - 3}` : userInitials;
			const avatarColor = index === 3 ? 'var(--neutral-500)' : user.avatar_color;

			return (
				<Styled.UserItem key={`${user?._key}`}>
					<Avatar
						initials={avatarInitials}
						size={AvatarType.Large}
						backgroundColor={avatarColor}
						withBorder
					/>
				</Styled.UserItem>
			);
		});
	};

	const { canBeRemoved, sortedTradeUsers, isLoading } = useExecutionUsers(tradeData, isModalOpen);

	return (
		<Styled.UsersInfoWrapper data-test="trade-details-users-section">
			<UsersModal
				users={sortedTradeUsers}
				openModal={openModal}
				closeModal={closeModal}
				isModalOpen={isModalOpen}
				storeModalsData={storeModalsData}
				canBeRemoved={canBeRemoved}
				isLoading={isLoading}
				trigger={
					<Styled.UsersList data-test="trade-details-users-list">
						{renderActivityUsers()}
					</Styled.UsersList>
				}
			/>
			<RemoveUserFromTradeModal
				getModalsData={getModalsData}
				openModal={openModal}
				closeModal={closeModal}
				isModalOpen={isModalOpen}
			/>
		</Styled.UsersInfoWrapper>
	);
};
