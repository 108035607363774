/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { toPath } from 'src/_helpers';
import { useCalendarSpreadFields } from '../../useCalendarSpreadFields';
import { Controller } from 'react-hook-form';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import * as Styled from './styled';

const PrincipalField = ({ path, t, lastCounter }) => {
	const { principal } = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	if (!principal.visible) {
		return null;
	}

	return (
		<Controller
			fullWidth
			mandatory={principal.hasValue}
			name={toPath(path, 'principalId')}
			render={({ field, fieldState: { error } }) => (
				<Styled.WideField>
					<MaterialSelectInput
						key={`principal-dropdown${principal.ready ? '' : '-loading'}`}
						error={!!error}
						data-test={field.name}
						label={t('principal')}
						options={principal.options}
						disabled={!principal.ready}
						controlledState
						hasChanged={principal.hasChanged}
						upward
						search
						searchIcon
						clearable
						{...field}
					/>
				</Styled.WideField>
			)}
		/>
	);
};

export const counterPrincipalField = props => <PrincipalField {...props} />;
