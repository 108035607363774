/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { media } from 'src/_helpers';
import styled from 'styled-components';

export const StepsList = styled.div`
	background: var(--neutral-900);
	padding: 24px 8px 4px 22px;
	overflow-y: auto;
	margin-top: 8px;
	${({ $spanColumn }) => $spanColumn && 'grid-row: span 2;'}

	${media.ll`
		padding: 32px 8px 4px 24px;
		margin-top: 24px;
	`}

	${media.xl`
		padding: 32px 20px 2px 48px;
	`}
`;

export const ExecutionSteps = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

export const StepBody = styled.div`
	background: var(--neutral-900);
	margin-top: 8px;
	${({ $spanColumn }) => $spanColumn && 'grid-row: span 2;'}

	${media.ll`
		margin-top: 24px;
	`}

	> div {
		padding: 32px 20px 24px 32px;
		margin-right: 8px;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;

		${media.xl`
			padding: 40px 36px 24px 48px;
			margin-right: 8px;
		`}
	}
`;

export const RightColumnWrapper = styled.div`
	background: var(--neutral-900);
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	grid-row: span 2;
	border-left: 2px solid var(--neutral-1000);

	${media.ll`
		margin-top: 24px;
	`};
`;

export const ActivityLogWrapper = styled.div`
	height: 100%;
	overflow: hidden;
`;
