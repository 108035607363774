/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Languages } from 'src/constants/settings';
import { ROUTES } from 'src/constants/routes';
import { isVosborCn } from 'src/_helpers/domain';
import { LanguageDropdown } from '../LanguageDropdown/LanguageDropdown';
import * as Styled from './styled';

export const OnboardingHeader = () => {
	const { t, i18n } = useTranslation();
	const isChinese = i18n.language === Languages.Chinese.code;

	const websiteUrl = isVosborCn() ? 'https://vosbor.cn/' : 'https://vosbor.com/';

	return (
		<Styled.Header>
			<Styled.SideColumn>
				<a href={websiteUrl} data-test="logo-link">
					<Styled.OnboardingLogo />
				</a>
			</Styled.SideColumn>
			<div className="links">
				<Styled.HeaderLink
					id="what_we_do"
					href="https://vosbor.com#whatwedo"
					className="separator"
					$isChinese={isChinese}
				>
					{t('what')}
				</Styled.HeaderLink>
				<Styled.HeaderLink
					id="how_it_works"
					href="https://vosbor.com#howitworks"
					className="separator"
					$isChinese={isChinese}
				>
					{t('how')}
				</Styled.HeaderLink>
				<Styled.HeaderLink
					id="why"
					href="https://vosbor.com#whyvosbor"
					className="separator"
					$isChinese={isChinese}
				>
					{t('why')}
				</Styled.HeaderLink>
				<Styled.HeaderLink
					id="who_we_are"
					href="https://vosbor.com#whoweare"
					className="separator"
					$isChinese={isChinese}
				>
					{t('who')}
				</Styled.HeaderLink>
				<Styled.HeaderLink
					id="contact"
					href="https://vosbor.com/contact"
					className="separator"
					$isChinese={isChinese}
				>
					{t('contact')}
				</Styled.HeaderLink>
				<LanguageDropdown />
			</div>
			<Styled.SideColumn className="stick-to-end">
				<Styled.ButtonLink id="login_button" to={ROUTES.register}>
					{t('register_btn')}
				</Styled.ButtonLink>
			</Styled.SideColumn>
		</Styled.Header>
	);
};
