/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const isEmailFormatValid = email => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const obfuscateEmail = (email = '') => {
	// maximum number of stars to use in obfuscated username or domain. We don't want the result to be too long
	const MAX_STARS = 8;
	const MIN_STARS = 3;

	if (!email) {
		return '';
	}

	const [username, domain] = email.split('@');
	const obfuscatedUsername = `${username[0]}`.padEnd(
		Math.min(Math.max(username.length - 1, MIN_STARS), MAX_STARS),
		'*'
	);
	const domainTokens = domain.split('.');
	const lastToken = domainTokens[domainTokens.length - 1];
	const strippedDomain = domain.replace(`.${lastToken}`, '');
	const lastDomainChar = strippedDomain[strippedDomain.length - 1];
	const obfuscatedDomain = `${lastDomainChar}`.padStart(
		Math.min(Math.max(strippedDomain.length - 1, MIN_STARS), MAX_STARS),
		'*'
	);
	return `${obfuscatedUsername}@${obfuscatedDomain}.${lastToken}`;
};
