/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styled';

export const StepsInfoBox = () => {
	const { t } = useTranslation();

	return (
		<Styled.Wrapper>
			<Styled.InfoIcon />
			<Styled.InfoText>{t('trade_info_box_text')}</Styled.InfoText>
		</Styled.Wrapper>
	);
};
