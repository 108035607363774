/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { Link } from 'src/components/Link/Link';
import { Form, Table } from 'semantic-ui-react';
import { media } from 'src/_helpers/media-queries';
import { h400, m50, t100 } from 'src/components/Typography';
import { DataTable, RowCell } from 'src/components/Datatable';
import { StrokeButton } from 'src/components/Buttons/StrokeButton';
import { ExecutionMessagesFooter } from 'src/components/MessagesFooter/MessagesFooter';

export const StringWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 24px 8px 0 16px;
	border-left: 2px solid var(--neutral-1000);
	max-height: calc(100% - 78px);

	${media.ll`
		padding: 32px 8px 0 16px;
	`}

	${media.xl`
		max-height: calc(100% - 102px);
		padding: 48px 20px 0 24px;
	`}
`;

export const StringMessage = styled.div`
	display: flex;
	align-items: center;
	border-left: 2px solid var(--primary-400);
	background: linear-gradient(90deg, rgb(55 60 217 / 10%) 0%, rgb(16 20 39 / 10%) 101.58%);
	padding: 15px 14px;
	margin-bottom: 28px;

	${media.ll`
		margin-bottom: 43px;
	`}

	svg {
		color: var(--primary-400);
		margin-right: 14px;
	}

	p {
		flex: 1;
		${t100}
		font-size: 14px;
		line-height: 21px;
	}
`;

export const StringCellCompany = styled(RowCell)`
	.ui.table & {
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		height: 80px;
		letter-spacing: 0.2px;

		> div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 4px;
			position: relative;
			height: 100%;

			> div:last-of-type {
				font-size: 12px;
			}
		}

		span.company-name {
			color: var(--text-color-default);
			margin-right: 4px;
		}
	}
`;

const tradeLinkContent = css`
	position: absolute;
	background: ${({ $isCurrentTrade }) =>
		$isCurrentTrade ? 'var(--primary-200)' : 'var(--neutral-700)'};
	color: ${({ $hasAccessToTrade }) =>
		$hasAccessToTrade ? 'var(--text-color-default)' : 'var(--neutral-300)'};
	border-radius: 8px;
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 13px;
	${m50}
	font-size: 12px;
	top: 66px;
	gap: 34px;

	${media.ll`
		gap: 47px;
	`}

	&:hover {
		color: ${({ $hasAccessToTrade }) =>
			$hasAccessToTrade ? 'var(--text-color-default)' : 'var(--neutral-300)'};
	}

	svg {
		color: ${({ $hasAccessToTrade, $isCurrentTrade }) =>
			$hasAccessToTrade && $isCurrentTrade
				? 'var(--text-color-default)'
				: 'var(--primary-200)'};
	}

	div:first-of-type {
		display: flex;
		align-items: center;
		gap: 8px;
		flex: 1;

		span {
			display: block;
			white-space: nowrap;
			max-width: 130px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;

export const TradeLink = styled(Link)`
	${tradeLinkContent}
`;

export const TradeInfo = styled.div`
	${tradeLinkContent}
`;

export const TradeId = styled.span`
	display: block;
	white-space: nowrap;
`;

export const NameContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;

	${media.xl`
		font-size: 13px !important;
	`}
`;

export const StringCompanyName = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	font-size: 12px;

	${media.ll`
		font-size: 13px;
	`}

	${media.xl`
		font-size: 14px;
		line-height: 21px;
	`}

	> div {
		margin-right: 5px;
	}
`;

export const BaseCell = styled(RowCell)`
	${h400}
	font-size: 14px;
	line-height: 16px;
`;

export const SettlementCell = styled(BaseCell)`
	.ui.table td& {
		padding-right: 10px !important;

		${media.ll`
			padding-right: 30px !important;
		`}

		${media.xl`
			padding-right: 20px !important;
		`}
	}
`;

export const PaddedLabel = styled.span`
	padding-left: 20px;

	${media.ll`
		padding-left: 27px;
	`}
`;

export const SettlementCellLabel = styled.span`
	padding-right: 10px;
	padding-left: 0;

	${media.ll`
		padding-right: 30px;
	`}

	${media.xl`
		padding-right: 20px;
	`}
`;

export const StringRow = styled(Table.Row)`
	height: 80px;
	border-radius: 8px;
	box-shadow: ${({ $isUserFromGivenTradeSide }) =>
		`inset 0px 0px 0px 2px ${
			$isUserFromGivenTradeSide ? 'var(--primary-200)' : 'transparent'
		}`};
	background: var(--neutral-850) !important;
	overflow: visible;

	td {
		overflow: visible !important;
	}

	.ui.selectable.table tbody &:hover {
		background: var(--neutral-850);
	}

	& + & {
		margin-top: 8px;
	}

	form + form {
		margin-left: 10px;
	}
`;

export const ActionsCellContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding-right: 16px;

	svg {
		margin-right: 10px;
	}

	span {
		display: block;
	}

	button {
		${media.ll`
			padding: 12px 25px;
		`}
	}
`;

export const MessageButton = styled(StrokeButton)`
	padding: 12px;

	${media.xl`
		padding: 12px 16px;
	`}

	svg {
		width: 16px;
		height: 16px;
		color: var(--primary-400);
	}

	span {
		flex: 1;
	}
`;

export const FormField = styled(Form.Field)`
	&.field {
		margin: 0 !important;

		.ui.toggle.buttons {
			button.toggle-button {
				font-size: 14px;
				line-height: 16px;
				padding: 9px 12px;

				${media.ll`
					padding: 9px 16px;
				`}
			}
		}
	}
`;

export const StringDataTable = styled(DataTable)`
	overflow: visible !important;
	margin-bottom: 24px !important;

	&.ui.table {
		border-collapse: separate;
		border-spacing: 16px 8px;
		height: unset;
		width: calc(100% - 4px);
		margin-right: 4px;
		margin-top: 0px !important;

		td,
		th {
			padding-left: 0;
			padding-right: 0;
		}

		${media.ll`
			border-spacing: 20px 8px;
		`}

		thead {
			background: none;
			position: static;

			th {
				padding-bottom: 0;
				padding-top: 0;
			}
		}

		&:only-child {
			margin-top: 24px;

			${media.ll`
				margin-top: 32px;
			`}
		}
	}
`;

export const MergedCells = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const OverflowWrapper = styled.div`
	height: calc(100% - 45px);
	overflow-y: auto;
`;

export const DetailsLink = styled.span`
	font-size: 14px;
	color: var(--primary-200);
	cursor: pointer;
`;

export const SettlementText = styled.div`
	font-size: 14px;
	color: var(--neutral-300);
	line-height: 21px;
	white-space: pre-wrap;
	margin: 8px 0 40px;
`;

export const StringChatWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const ChatFooter = styled(ExecutionMessagesFooter)`
	border-left: 2px solid var(--neutral-1000);
	border-top: 2px solid var(--neutral-1000);
	background: var(--neutral-900);
`;
