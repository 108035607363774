/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { PaperPorts, DrawerPriceType, PriceType } from 'src/constants/contract';
import { paranaguaDefaultVolume } from '../model';

export const getDefaultValuesForPreset = preset => {
	if (!preset) {
		return {};
	}

	const isParanagua = preset.loading_port?._key === PaperPorts.PARANAGUA;

	const values = {};

	if (isParanagua) {
		values.volume = paranaguaDefaultVolume;
	} else if (preset.base_quantity) {
		values.volume = preset.base_quantity;
	} else if (preset.volume) {
		values.volume = preset.volume;
	} else {
		values.volume = null;
	}

	if (isParanagua) {
		values.priceType = DrawerPriceType.CBOT;
	} else if (preset.price_type === PriceType.Flat) {
		values.priceType = preset.price_type;
		values.futuresMonth = null;
	} else if (preset.price_type === PriceType.Basis) {
		values.priceType = preset.futures_contract;
	}

	if (preset.runs) {
		values.runs = 1;
		values.runsRequired = true;
	} else {
		values.runs = null;
		values.runsRequired = false;
	}

	return values;
};
