/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { getInitials } from './getInitials';
import { isToday, isYesterday } from './dates';
import { tempMessageId } from '../constants';

export const sortMessages = (messages = []) => [...messages].sort((a, b) => (a.id > b.id ? 1 : -1));

export const getMessagesIds = (list = []) => list.map(({ id }) => id);

export const getMessageDate = momentDate => {
	if (isToday(momentDate)) {
		return 'today';
	} else if (isYesterday(momentDate)) {
		return 'yesterday';
	}

	return momentDate.format('LL');
};

export const getTempMessage = (message, user) => {
	const now = moment();

	return {
		id: message.id || tempMessageId,
		...message,
		user: {
			id: user.id,
			name: `${user.first_name} ${user.last_name}`,
			initials: getInitials(user.first_name, user.last_name),
			color: user.avatar_color,
			company: user.company?.name || '',
			status: user.status,
		},
		date: getMessageDate(now),
		time: new Date().toISOString(),
		createdAt: new Date().toISOString(),
	};
};

export const markSpecialMessages = (messages, lastReadMessageId, currentUserId) => {
	let firstUnreadFound = false;

	return messages.map((message, index) => {
		const isTempMessage = message.id.startsWith(tempMessageId) || message.wasTemp === true;
		const isNewMessage =
			message.user.id !== currentUserId && !isTempMessage && message.id > lastReadMessageId;
		const isPreviousMessageNew =
			index > 0 &&
			messages[index - 1].user.id !== currentUserId &&
			messages[index - 1].id > lastReadMessageId;
		const isDifferentDay =
			new Date(messages[index - 1]?.createdAt).getDate() !==
			new Date(message.createdAt).getDate();
		const sameUserAndDay =
			index > 0 && messages[index - 1].user.id === message.user.id && !isDifferentDay;

		const shouldBeMergedWithPrevious = isTempMessage
			? sameUserAndDay
			: (!isNewMessage && sameUserAndDay) || (isPreviousMessageNew && sameUserAndDay);

		const newMessage = {
			...message,
			isTemp: isTempMessage,
			isNew: !firstUnreadFound && isNewMessage,
			shouldBeMergedWithPrevious,
		};

		if (isNewMessage) {
			firstUnreadFound = true;
		}

		return newMessage;
	});
};

export const filterMergedMessages = (currentMessages, newMessages) => {
	const newMessagesIds = getMessagesIds(newMessages);

	return [
		...(currentMessages || []).filter(({ id }) => !newMessagesIds.includes(id)),
		...newMessages,
	];
};

export const enrichWithPendingMessage = (messages, newMessages, activeAsset = {}, userId) => {
	const tempMessages = activeAsset.participants
		? newMessages.map(newMessage =>
				getTempMessage(newMessage, activeAsset.participants[userId])
		  )
		: [];

	return [...messages, ...tempMessages].filter(Boolean);
};

// This function splits the text in an array so we can split images from normal text
// with this separation we can know when we should render bubbles (text) or not (images)
export const splitTextToArrayOfMessages = text => {
	const regex = /(!\[\]\([^)]+\))/;
	return text ? text.split(regex).filter(part => part.trim()) : [''];
};
