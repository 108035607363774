/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TradeInfoWrapper, InfoTitle } from '../common';
import { Comment } from 'src/components/Comment/Comment';

export const CommentsSection = ({ comments, orderOwnerId }) => {
	const { t } = useTranslation();
	return (
		<>
			<InfoTitle>{t('comments')}</InfoTitle>
			<TradeInfoWrapper>
				{comments.map(({ comment, user, index: commentIndex, created_at }, index) => (
					<Comment
						key={`comment-${user._key}-${index}`}
						counterNumber={commentIndex}
						isOwn={user._key === orderOwnerId}
						order={{
							hidden: false,
							is_identity_revealed: true,
							user,
							created_at,
						}}
						message={comment}
						showLocalTime
						showLongLabel
					/>
				))}
			</TradeInfoWrapper>
		</>
	);
};
