/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { searchOrdersByFilter, listGroupedPaperOrders, listTeamOrders } from 'src/_api/orders.api';
import { getMyTradebookData, getTeamTrades } from 'src/_api/trades.api';
import { listMyNotifications } from 'src/_api/my-account.api';
import { getPendingKYCs, getActiveConversations } from 'src/_api/kyc.api';

export const useBaseInfiniteOrdersQuery = (
	query,
	queryKey,
	fetchFunction,
	additionalSelectFunction,
	config
) => {
	const searchQuery = ({ pageParam = { ...query } }) => fetchFunction({ ...pageParam });
	const userId = useSelector(getUserId);
	const queryClient = useQueryClient();

	const queryKeyWithUser = Array.isArray(queryKey) ? [...queryKey, userId] : [queryKey, userId];

	const result = useInfiniteQuery(queryKeyWithUser, searchQuery, {
		select: data => {
			const flatPages = data.pages.reduce((acc, page) => {
				return [...acc, ...page.items];
			}, []);

			if (typeof additionalSelectFunction === 'function') {
				return additionalSelectFunction(flatPages);
			}

			return flatPages;
		},
		getNextPageParam: (lastPage, allPages) => {
			const cursor = lastPage.cursor;

			if (lastPage.items.length < query._limit || cursor === '') {
				return undefined;
			}

			if (cursor) {
				return { ...query, next_page_cursor: cursor };
			}

			return { ...query };
		},
		...config,
	});

	const invalidateQuery = () => {
		queryClient.invalidateQueries(queryKeyWithUser);
	};

	return {
		...result,
		invalidateQuery,
	};
};

export const useInfinitePhysicalOrdersQuery = query => {
	return useBaseInfiniteOrdersQuery(query, ['physical-orders', query], searchOrdersByFilter);
};

export const useInfiniteGroupedPaperOrdersQuery = query => {
	return useBaseInfiniteOrdersQuery(
		query,
		['grouped-paper-orders', query],
		listGroupedPaperOrders
	);
};

export const useInfiniteTradesQuery = query => {
	return useBaseInfiniteOrdersQuery(query, ['trades', query], getMyTradebookData);
};

export const useInfinitePendingKYCQuery = query => {
	return useBaseInfiniteOrdersQuery(query, ['kyc-pending-requests', query], getPendingKYCs);
};

export const useInfiniteKYCActiveConversationsQuery = query => {
	return useBaseInfiniteOrdersQuery(
		query,
		['kyc-active-conversations', query],
		getActiveConversations
	);
};

export const useInfiniteNotificationsQuery = additionalSelect => {
	return useBaseInfiniteOrdersQuery({}, ['notifications'], listMyNotifications, additionalSelect);
};

export const useInfiniteTeamExchangeQuery = query => {
	const userId = useSelector(getUserId);
	return useBaseInfiniteOrdersQuery(
		query,
		['team-exchange-orders', userId, query],
		listTeamOrders
	);
};

export const useInfiniteTeamOTCQuery = query => {
	const userId = useSelector(getUserId);
	return useBaseInfiniteOrdersQuery(query, ['team-otc-orders', userId, query], listTeamOrders);
};

export const useInfiniteTeamTradesQuery = query => {
	const userId = useSelector(getUserId);
	return useBaseInfiniteOrdersQuery(query, ['team-trades-orders', userId, query], getTeamTrades);
};
