/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '../Link/Link';
import { Table } from 'semantic-ui-react';
import { media } from 'src/_helpers';

const BaseCell = ({ linkTo, children, columnKey, ...props }) => {
	if (!linkTo) {
		return <Table.Cell {...props}>{children}</Table.Cell>;
	}

	return (
		<LinkedCell {...props}>
			<Link className="cell-link" to={linkTo}>
				{children}
			</Link>
		</LinkedCell>
	);
};

export const RowCell = styled(BaseCell).attrs({
	singleLine: true,
})`
	${props => (typeof props?.$width === 'number' ? `width: ${props.$width}px !important;` : '')};
	${props => (props?.$width?.default ? `width: ${props.$width.default}px !important;` : '')};
	${media.s`
		${props => (props?.$width?.s ? `width: ${props.$width.s}px !important;` : '')}
	`}
	${media.m`
		${props => (props?.$width?.m ? `width: ${props.$width.m}px !important;` : '')}
	`}
	${media.l`
		${props => (props?.$width?.l ? `width: ${props.$width.l}px !important;` : '')}
	`}
	${media.ll`
		${props => (props?.$width?.ll ? `width: ${props.$width.ll}px !important;` : '')}
	`}
	${media.xl`
		${props => (props?.$width?.xl ? `width: ${props.$width.xl}px !important;` : '')}
	`} 

	&.relative {
		& > span,
		div {
			position: relative;
		}
	}
`;

const LinkedCell = styled(Table.Cell)`
	.ui.table & {
		padding: 0 !important;
		overflow: inherit;
	}

	> a.cell-link {
		display: block;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 6px 8px;

		&:hover {
			color: inherit;
		}
	}
`;

export const HighligthCell = styled(RowCell)`
	position: relative;
	color: ${props =>
		props.$isOwnOrder ? 'var(--purple-400)' : 'var(--highlight-color, #ffffff)'};

	&.closed-cell {
		color: ${props =>
			props.$isOwnOrder
				? 'var(--purple-800)'
				: 'var(--highlight-color, --neutral-200)'} !important;
	}
`;

export const HighligthLabel = styled.label`
	color: #ffffff;
`;

export const ChildCell = styled(RowCell)`
	position: relative;
	color: ${props =>
		props.$isOwnOrder ? 'var(--purple-400)' : 'var(--highlight-color, #ffffff)'};

	&.closed-cell {
		color: ${props =>
			props.$isOwnOrder
				? 'var(--purple-800)'
				: 'var(--highlight-color, --neutral-200)'}!important;
	}
`;

export const PriceColorsCell = styled(RowCell)`
	color: ${props => (props.$isBid ? 'var(--buy)' : 'var(--sell)')};
`;

export const ColoredQuoteTypeCell = styled(RowCell)`
	${props =>
		props.$isFirm &&
		css`
			color: ${props => (props.$isBid ? 'var(--buy)' : 'var(--sell)')};
		`}

	> div {
		display: flex;
		align-items: center;
	}

	& .otc-label {
		margin-left: 4px;
	}
`;
