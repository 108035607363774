/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useQuery } from 'react-query';
import { getTraderStatistics } from 'src/_api';

const traderStatisticsQuery = ({ queryKey: [, params] }) => {
	return getTraderStatistics(params);
};

const mapStatistics = data => {
	return {
		responseRateOrders: Math.round((data.orders_response_count / data.orders_count) * 100),
		responseRateCounters: Math.round(
			(data.negotiations_response_count / data.negotiations_count) * 100
		),
		successRateOrders: Math.round((data.orders_success_count / data.orders_count) * 100),
		successRateCounters: Math.round(
			(data.negotiations_success_count / data.negotiations_count) * 100
		),
		totalOrders: data.orders_count,
		totalCounters: data.negotiations_count,
	};
};

export const useTraderStatistics = (userId, enabled) => {
	const { data, isFetched } = useQuery(['trader-statistics', { userId }], traderStatisticsQuery, {
		select: data => {
			return mapStatistics(data);
		},
		enabled,
	});

	return { statistics: data, areStatsFetched: isFetched };
};
