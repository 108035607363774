/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TradeDataContext } from './TradeDataContext';
import { getUserId } from 'src/_store/selectors';
import { useTradeString } from 'src/_api';
import { CongratulationsModal } from '../components/paper/CongratulationsModal/CongratulationsModal';
import { NotificationsComponent } from '../components/NotificationsComponent/NotificationsComponent';
import { PaperTradeHeader } from '../components/paper/PaperTradeHeader/PaperTradeHeader';
import { useTradeNotifications } from '../hooks/useTradeNotifications';
import { usePaperTabs } from '../hooks/usePaperTabs';
import { Market } from 'src/constants/contract';
import { PaperTradeStep } from 'src/constants/tradeStatus';
import { useTradePageTitle } from 'src/_helpers/usePageTitle';
import { tPriceUnit } from 'src/constants/product';
import { formatPaperTradeDetails } from './formatPaperTradeDetails';
import { StringsTable } from '../components/paper/StringsTable/StringsTable';
import { getIntentionEndDate } from 'src/_helpers/date';
import { ERRORS } from 'src/_routes/ErrorBoundary';
import { useExecutionAnalytics } from '../hooks/useExecutionAnalytics';
import * as Styled from './styled';

export const PaperExecution = ({ data, isLoadingTradeDetails, isFetched, isError, tradeId }) => {
	const { t } = useTranslation();
	const { state, pathname } = useLocation();
	const accountUserId = useSelector(getUserId);
	const navigate = useNavigate();
	const currentLocale = moment.locale();

	const isTradeDetailsError =
		isError || (!isLoadingTradeDetails && isFetched && Object.keys(data).length === 0);

	const priceLabel = tPriceUnit(t, data?.price_unit);

	const tradeData = useMemo(() => {
		return formatPaperTradeDetails(t, data, accountUserId, { priceLabel });
		// NOTE: we want to recalculate order details when the locale changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, data, accountUserId, priceLabel, currentLocale]);

	const isPKPGTrade = !!tradeData?.runs;

	useExecutionAnalytics({ tradeId, on: isFetched });

	useEffect(() => {
		if (isTradeDetailsError || isPKPGTrade) {
			throw new Error(ERRORS.accessDenied);
		}
	}, [isFetched, isTradeDetailsError, isPKPGTrade]);

	const fetchedTradeStep = tradeData?.trade_step;

	const isAllowedStep = Object.values(PaperTradeStep).includes(fetchedTradeStep);
	const tradeStep = isAllowedStep ? fetchedTradeStep : PaperTradeStep.PaperNoString;

	const stepCompleted = tradeData?.step_completed;

	const counterpartiesUserName = tradeData?.counterparties_user_name;

	const tradeMarket = tradeData?.market;

	const circleOutSourceTradeId = tradeData?.circle_out_source_trade_id;

	const { data: stringData, isLoading: isLoadingTradeString } = useTradeString(
		{
			trade_id: tradeId,
		},
		{
			enabled:
				isFetched &&
				tradeMarket === Market.Paper &&
				tradeData.trade_step !== PaperTradeStep.PaperNoString,
		}
	);

	useTradeNotifications({ tradeData, stepCompleted, tradeStep });

	const { paperMarketTabs, activeTab, setActiveTab } = usePaperTabs({
		tradeData,
		tradeId,
		tradeStep,
	});

	useEffect(() => {
		if (!isNaN(Number(state?.tabToOpen))) {
			setActiveTab(state.tabToOpen);
		}
	}, [state?.tabToOpen, setActiveTab]);

	useTradePageTitle(data, accountUserId);

	const handleTabChange = (e, { activeIndex }) => {
		setActiveTab(activeIndex);
		navigate(pathname, { state: activeIndex });
	};

	return isLoadingTradeDetails ? null : (
		<TradeDataContext.Provider
			value={{
				tradeData,
				isLoadingTradeDetails,
				tradeId,
				accountUserId,
				tradeStringData: stringData,
				isLoadingTradeString,
				tradeStep,
				stepCompleted,
				counterpartiesUserName,
				tradeMarket,
				circleOutSourceTradeId,
			}}
		>
			<NotificationsComponent tradeStep={tradeStep} tradeData={tradeData} />
			<Styled.TradeDetailsWrapper>
				<PaperTradeHeader
					tradeData={tradeData}
					step={tradeData?.trade_step}
					intentionEndDate={getIntentionEndDate(tradeData)}
					orderId={tradeData?.header_info.orderId}
				/>
				<Styled.TradeDetailsBody data-test="trade-details-body">
					<Styled.TabWrapper>
						<Styled.Tab
							activeIndex={activeTab}
							onTabChange={handleTabChange}
							panes={paperMarketTabs}
						/>
					</Styled.TabWrapper>
					{isFetched && <StringsTable stringData={stringData} />}
				</Styled.TradeDetailsBody>
			</Styled.TradeDetailsWrapper>
			<CongratulationsModal />
		</TradeDataContext.Provider>
	);
};
