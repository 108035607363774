/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { Modal, ButtonsWrapper, ModalHeaderWrapper } from 'src/components/Modal';
import { ReactComponent as RemoveItemIcon } from 'src/assets/icons/remove_item.svg';

export const StyledModalHeaderWrapper = styled(ModalHeaderWrapper)`
	margin-right: 24px;
	margin-bottom: 24px;
`;

export const User = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 16px;

	&:last-child {
		padding-bottom: 0;
	}
`;

export const ModalContent = styled.div`
	margin-bottom: 20px;
	padding-right: 6px;
	overflow-y: auto;
	min-height: 30px;
	max-height: calc(58px * 7);
	margin-right: 8px;
	padding-bottom: 1px;

	${media.xl`
		margin-right: 12px;
		margin-bottom: 40px;
		max-height: 417px;
	`}
`;

export const UserList = styled.div`
	padding-right: 14px;

	${media.xl`
		padding-right: 20px;
	`}
`;

export const StyledRemoveIcon = styled(RemoveItemIcon)`
	color: var(--neutral-300);
	width: 12px;
	height: 12px;
	cursor: pointer;
	margin-left: 16px;

	&:hover {
		color: #fff;
	}
`;

export const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-right: 12px;
`;

export const RemoveIconWrapper = styled.span`
	display: flex;
	width: 28px;
`;

export const StyledModal = styled(Modal)`
	&.ui.tiny.modal {
		max-height: 618px;
		${media.xl`
			max-height: 675px;
		`}

		.content {
			padding: 32px 0px 20px 24px;

			${media.xl`
				padding: 32px 0px 32px 32px;
			`}
		}
	}
`;

export const StyledButtonsWrapper = styled(ButtonsWrapper)`
	padding-right: 24px;
`;
