/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import classNames from 'clsx';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { isEmailFormatValid } from 'src/_helpers/email';
import { FormRow, Form, ErrorMessage } from 'src/components/Layout/LandingForm';
import { LabeledInput } from 'src/components/Input/LabeledInput';
import { Button, ButtonSize } from 'src/components/Buttons/Button';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';

export const ResendEmailForm = ({
	email,
	onSubmit,
	onEmailChange,
	hasBackButton,
	isLoading,
	isSubmitted,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const isEmailError = isSubmitted && !isEmailFormatValid(email);

	return (
		<>
			<Header>{t('resend_verification_email')}</Header>
			<Info>{t('resend_email_text')}</Info>
			<StyledForm name="form" onSubmit={onSubmit}>
				<FormRow>
					<LabeledInput
						mandatory
						label={t('email_input_label')}
						name="email"
						placeholder={t('email_input_placeholder')}
						onChange={onEmailChange}
						className={classNames('form-control', isEmailError && 'is-invalid')}
						wrapperClassName="login-form-control"
						tabIndex="1"
						value={email}
					/>
					{isEmailError && <ErrorMessage>{t('email_input_error_message')}</ErrorMessage>}
				</FormRow>
				<Buttons>
					{hasBackButton && (
						<BackButton
							size={ButtonSize.Large}
							onClick={() => navigate(-1)}
							id="resend_verification_email_back_button"
						>
							{t('back')}
						</BackButton>
					)}
					<SendButton
						size={ButtonSize.Large}
						type="submit"
						loading={isLoading}
						id="resend_verification_email_submit_button"
					>
						{t('resend_email_button')}
					</SendButton>
				</Buttons>
			</StyledForm>
		</>
	);
};

export const StyledForm = styled(Form)`
	margin: 0 auto 0;
`;

export const Header = styled.h2`
	color: var(--login-header-color);
	font-size: 24px;
	line-height: 28px;
	margin-bottom: 20px;
`;

export const Info = styled.div`
	color: var(--neutral-600);
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	margin-bottom: 48px;
`;

export const Buttons = styled.div`
	margin-top: 60px;
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: stretch;
`;

export const BackButton = styled(Button)`
	width: 104px;
	line-height: 56px;
	text-align: center;
	color: var(--neutral-1000);
	font-weight: 400;
	font-size: 14px;
`;

export const SendButton = styled(PrimaryButton)`
	box-shadow: 0px 2px 30px rgba(66, 0, 255, 0.25);
	height: 56px;
	flex: 1;

	&.loading {
		opacity: 0.6 !important;
	}
`;
