/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { StyledList, StyledListItem, ListHeader } from '../components/List';
import { tzList } from './model';
import { useTranslation } from 'react-i18next';

export const TimezoneSelect = React.memo(({ value, onChange }) => {
	const { t } = useTranslation();

	return (
		<div>
			<ListHeader align="left">{t('timezone')}</ListHeader>
			<StyledList selection verticalAlign="middle">
				{tzList.map(tz => (
					<StyledListItem
						key={tz.value}
						content={tz.name}
						active={value === tz.value}
						onClick={() => onChange(tz.value)}
					/>
				))}
			</StyledList>
		</div>
	);
});
