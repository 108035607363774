/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { uploadNewsImage } from 'src/_helpers/uploadNewsImage';
import { uploadImageIcon, boldIcon, italicIcon, linkIcon, unlinkIcon, emojiIcon } from './svg';

export const newsPublishingToolbarConfig = (onImageUpload = () => {}) => ({
	options: ['inline', 'link', 'emoji', 'image', 'blockType'],
	link: {
		link: {
			icon: linkIcon,
		},
		unlink: {
			icon: unlinkIcon,
		},
		defaultTargetOption: '_blank',
	},
	blockType: {
		inDropdown: false,
		options: ['H1', 'H2'],
	},
	inline: {
		options: ['bold', 'italic'],
		bold: {
			icon: boldIcon,
		},
		italic: {
			icon: italicIcon,
		},
	},
	emoji: {
		icon: emojiIcon,
		emojis: ['😀', '😂', '😉', '🤔', '😫', '👍', '👎', '👏'],
	},
	image: {
		icon: uploadImageIcon,
		urlEnabled: true,
		uploadEnabled: true,
		uploadCallback: file => uploadNewsImage(file, onImageUpload),
		defaultSize: {
			height: 'auto',
			width: 400,
		},
		alt: { present: false, mandatory: false, alignmentEnabled: false },
	},
});
