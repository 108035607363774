/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';

export function useOnClickOutside(ref, handler, stopPropagation = true) {
	useEffect(() => {
		if (handler) {
			const clickHandler = ev => {
				if (ref?.current?.contains && !ref.current.contains(ev.target)) {
					if (stopPropagation) {
						ev.stopPropagation();
					}
					handler(ev);
				}
			};

			document.addEventListener('click', clickHandler, true);
			return () => {
				document.removeEventListener('click', clickHandler, true);
			};
		}
	}, [ref, handler, stopPropagation]);
}
