/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip/Tooltip';
import { tOrderType } from 'src/constants/contract';
import * as Styled from './styled';

export const SeenBy = ({ viewedAt, counterpartyName, orderType }) => {
	const { t } = useTranslation();
	const order = tOrderType(t, orderType).toLowerCase();
	const time = moment(viewedAt).fromNow(true);

	return (
		<Styled.Container>
			<Tooltip>
				<TooltipTrigger>
					<Styled.InfoIcon data-test="info-icon" />
				</TooltipTrigger>
				<TooltipContent>
					{viewedAt
						? t('counterparty_has_seen_order_time_ago', {
								counterparty: counterpartyName,
								order,
								time,
						  })
						: t('counterparty_has_not_seen_order_time_ago', {
								counterparty: counterpartyName,
								order,
						  })}
				</TooltipContent>
			</Tooltip>
			<Styled.Text>
				{viewedAt
					? t('seen_time_ago', {
							time,
					  })
					: t('not_seen_yet')}
			</Styled.Text>
		</Styled.Container>
	);
};
