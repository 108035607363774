/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

const chartDefaultState = null;

export const useChartState = chartStateStorageKey => {
	const loadChartState = () => {
		const chartSettings = localStorage.getItem(chartStateStorageKey);

		if (!chartSettings) {
			saveChartState(chartDefaultState);
			return chartDefaultState;
		}

		return JSON.parse(chartSettings);
	};

	const saveChartState = value =>
		localStorage.setItem(chartStateStorageKey, JSON.stringify(value));

	return {
		saveChartState,
		chartState: loadChartState(),
	};
};
