/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { getShipmentCutOffDate } from 'src/_helpers/date';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import * as Styled from '../../styled';

const FirstLegShipmentField = ({ path, t }) => {
	return (
		<Controller
			mandatory
			name={toPath(path, 'delivery')}
			defaultValue={null}
			render={({ field, fieldState: { error } }) => (
				<Styled.CalendarWrapper>
					<MaterialDateInput
						{...field}
						required
						id={field.name}
						dataTest={field.name}
						label={t('first_leg_shipment')}
						error={!!error}
						cutOffDate={getShipmentCutOffDate()}
						renderDateToggleButton={false}
					/>
				</Styled.CalendarWrapper>
			)}
		/>
	);
};

export const firstLegShipmentField = (path, t) => <FirstLegShipmentField path={path} t={t} />;
