/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { isExpired } from 'src/_helpers/date';
import { getUserId } from 'src/_store/selectors';
import { useSelector } from 'react-redux';
import { CounterStatus } from 'src/constants/counterStatus';

export const useCounterDetails = counter => {
	counter = counter ?? {};

	const userId = useSelector(getUserId);

	const isCounterExpired = isExpired(counter.validity);
	const isMyCounter = counter.user_id === userId;
	const isTradedCounter = !!counter.trade_id;
	const isActiveCounter = counter.status === CounterStatus.Active;
	const isNotAvailableCounter = counter.status === CounterStatus.NotAvailable;

	return {
		isCounterExpired,
		isMyCounter,
		isTradedCounter,
		isActiveCounter,
		isNotAvailableCounter,
	};
};
