/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Currency,
	DrawerPriceType,
	OrderType,
	PriceUnit,
	defaultCurrencyUnit,
	tDrawerPriceType,
	tOrderType,
	PriceType,
} from 'src/constants/contract';
import { useWatchPaperFields } from '../../../useWatchPaperFields';
import { genFutureContractMonths } from 'src/containers/Order/PhysicalOrder/Groups/Pricing/FuturesContractDate';
import moment from 'moment';
import { getNumberOfMonths, toUTCUnix } from 'src/_helpers/date';
import { useFormContext } from 'react-hook-form';
import { usePaperOrderContext } from '../../../CreatePaperOrderDrawerContent';
import { calculateClosestFuturesMonth } from '../../../helpers/calculateClosestFuturesMonth';
import { toPath, isNil } from 'src/_helpers';

export const useOutrightSection = ({ path }) => {
	const { t } = useTranslation();
	const { setValue, unregister } = useFormContext();
	const { selectedPreset: selectedProductPresetValue } = usePaperOrderContext();

	const {
		presetIDValue,
		availableInstruments,
		instrumentValue,
		currencyUnitValue,
		priceTypeValue,
		isEditing,
		isCopying,
		volumeValue,
		runsValue,
		deliveryValue,
		futuresMonthChangedValue,
	} = useWatchPaperFields(path);

	/* Quote */
	const quoteOptions = Object.values(OrderType).map(value => ({
		text: tOrderType(t, value),
		value,
	}));

	/* Currency & Unit */
	const [currency, unit] = useMemo(() => {
		return currencyUnitValue
			? currencyUnitValue.split('/')
			: [Currency.USD, PriceUnit.MetricTon];
	}, [currencyUnitValue]);

	useEffect(() => {
		if (selectedProductPresetValue && priceTypeValue) {
			const selectedFuturesContract = selectedProductPresetValue.product.futures_contracts?.find(
				fc => fc.futures_contract_id === priceTypeValue
			);

			if (!selectedFuturesContract) {
				setValue(toPath(path, 'currencyUnit'), defaultCurrencyUnit);
				return;
			}

			const { currency, unit } = selectedFuturesContract;
			setValue(toPath(path, 'currencyUnit'), `${currency}/${unit}`);
		}
	}, [setValue, priceTypeValue, selectedProductPresetValue, path]);

	/* Price type */
	const priceTypeOptions = useMemo(() => {
		let priceTypes = [];

		// price type not defined meaning it's a wild card: Flat + all basis defined in futures contracts
		if (!selectedProductPresetValue?.price_type) {
			const futuresContractsPriceTypes =
				selectedProductPresetValue?.product?.futures_contracts?.map(
					fc => fc.futures_contract_id
				) || [];

			priceTypes = [DrawerPriceType.Flat, ...futuresContractsPriceTypes];
		} else if (selectedProductPresetValue?.price_type === DrawerPriceType.Flat) {
			priceTypes = [DrawerPriceType.Flat];
		} else {
			priceTypes = [DrawerPriceType[selectedProductPresetValue.futures_contract]];
		}

		return priceTypes
			.filter(item => availableInstruments?.[instrumentValue]?.includes(item))
			.map(value => ({
				text: tDrawerPriceType(t, value),
				key: value,
				value,
			}));
	}, [availableInstruments, instrumentValue, selectedProductPresetValue, t]);

	/** check if all priceTypeOptions are available for the currently selected instrument */
	useEffect(() => {
		if (instrumentValue && priceTypeValue) {
			if (
				availableInstruments[instrumentValue] &&
				!availableInstruments[instrumentValue].includes(priceTypeValue)
			) {
				setValue(toPath(path, 'priceType'), availableInstruments[instrumentValue][0]);
			}
		}
	}, [availableInstruments, instrumentValue, path, priceTypeValue, setValue]);

	/* Futures months */
	const selectedFuturesContractMonths = useMemo(() => {
		if (!selectedProductPresetValue) {
			return [];
		}

		return (
			selectedProductPresetValue?.product?.futures_contracts?.find(
				fc => fc.futures_contract_id === priceTypeValue
			)?.future_months || []
		);
	}, [priceTypeValue, selectedProductPresetValue]);

	const futuresMonthOptions = useMemo(() => {
		return genFutureContractMonths(selectedFuturesContractMonths).map(value => ({
			key: value.valueOf(),
			text: moment(value).format('MMM YYYY'),
			value: toUTCUnix(value),
		}));
	}, [selectedFuturesContractMonths]);

	const calculatedTotalVolume = useMemo(() => {
		if (selectedProductPresetValue) {
			const monthsMultiplier = selectedProductPresetValue.multi_month
				? getNumberOfMonths(deliveryValue?.startDate, deliveryValue?.endDate)
				: 1;

			if (!isNil(runsValue)) {
				return selectedProductPresetValue.base_quantity * +runsValue * monthsMultiplier;
			} else if (!selectedProductPresetValue.volume !== undefined) {
				return monthsMultiplier * volumeValue;
			}

			return selectedProductPresetValue.volume;
		}

		return null;
	}, [
		deliveryValue?.endDate,
		deliveryValue?.startDate,
		runsValue,
		selectedProductPresetValue,
		volumeValue,
	]);

	useEffect(() => {
		if (volumeValue === '') {
			setValue(toPath(path, 'volume'), null, { shouldValidate: true });
		}
	}, [path, setValue, volumeValue]);

	useEffect(() => {
		setValue(toPath(path, 'totalVolume'), calculatedTotalVolume);
	}, [path, setValue, calculatedTotalVolume]);

	useEffect(() => {
		if (futuresMonthChangedValue || isEditing || isCopying) {
			return;
		}

		const closestFuturesMonth = calculateClosestFuturesMonth(
			deliveryValue,
			futuresMonthOptions
		);

		setValue(toPath(path, 'futuresMonth'), closestFuturesMonth?.value || null);
	}, [
		isCopying,
		isEditing,
		deliveryValue,
		futuresMonthChangedValue,
		futuresMonthOptions,
		path,
		setValue,
	]);

	const hasVolumeDefined = selectedProductPresetValue?.volume;
	const hasRunsSupport = selectedProductPresetValue?.runs;

	const shouldDisplayPriceType = selectedProductPresetValue && priceTypeOptions.length > 1;
	const shouldDisplayQuantityField = !hasRunsSupport && !hasVolumeDefined;

	const isBasisPriceTypeSelected = priceTypeValue !== DrawerPriceType.Flat;

	const shouldDisplayFuturesMonths = () => {
		if (selectedProductPresetValue?.price_type === PriceType.Basis) {
			return true;
		} else if (
			selectedProductPresetValue?.price_type === undefined &&
			isBasisPriceTypeSelected
		) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		if (!hasRunsSupport) {
			unregister(toPath(path, 'runs'));
		} else {
			setValue('runsRequired', true);
		}
	}, [hasRunsSupport, path, setValue, unregister]);

	return {
		quote: { options: quoteOptions, ready: true },
		priceType: { options: priceTypeOptions, ready: !!presetIDValue },
		futuresMonth: { options: futuresMonthOptions, ready: !!presetIDValue },
		currency,
		unit,
		selectedProductPresetValue,
		meta: {
			isBasisPriceTypeSelected,
			shouldDisplayFuturesMonths: shouldDisplayFuturesMonths(),
			shouldDisplayPriceType,
			shouldDisplayQuantityField,
			hasRunsSupport,
		},
	};
};
