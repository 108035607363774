/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Children, useEffect } from 'react';
import clsx from 'clsx';
import { useTogglable } from 'src/_helpers/useTogglable';
import * as Styled from './styled';

export const IconType = {
	default: 'default',
	chevron: 'chevron',
};

export const FoldableSection = ({
	title,
	children,
	showAll = false,
	canToggle = true,
	iconType = IconType.default,
	GuideComponent,
}) => {
	const [showOptional, { open, toggle }] = useTogglable(showAll);

	useEffect(() => {
		if (showAll) {
			open();
		}
	}, [open, showAll]);

	const renderSectionElements = () =>
		Children.map(children, child => {
			if (!child) {
				return null;
			}

			const isMandatory = child.props?.mandatory;
			const isFullWidth = child.props?.fullWidth;

			return (
				<FieldWrapper
					expanded={showOptional}
					shouldShow={isMandatory || showOptional}
					isFullWidth={isFullWidth}
				>
					{child}
				</FieldWrapper>
			);
		});

	const ExpandIcon = iconType === IconType.chevron ? <Styled.ExpandIcon /> : <Styled.PlusIcon />;
	const CollapseIcon =
		iconType === IconType.chevron ? <Styled.CollapseIcon /> : <Styled.MinusIcon />;

	return (
		<Styled.SectionWrapper>
			<Styled.SectionMeta>
				<Styled.Title>
					<span>{title}</span>
					{!!GuideComponent && <GuideComponent />}
				</Styled.Title>
				<Styled.StrokeButton onClick={toggle} disabled={!canToggle}>
					{showOptional || !canToggle ? CollapseIcon : ExpandIcon}
				</Styled.StrokeButton>
			</Styled.SectionMeta>
			<Styled.FieldContainer>{renderSectionElements()}</Styled.FieldContainer>
		</Styled.SectionWrapper>
	);
};

const FieldWrapper = ({ shouldShow, expanded, children, isFullWidth }) => {
	return shouldShow ? (
		<Styled.Child $fullWidth={isFullWidth} className={clsx({ expanded, collapsed: !expanded })}>
			{children}
		</Styled.Child>
	) : null;
};
