/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { media } from 'src/_helpers';
import styled from 'styled-components';

export const GridWrapper = styled.div`
	display: grid;
	grid-template-columns: 220px 1fr 428px;
	grid-template-rows: 100px calc(100% - 176px) 76px;
	margin-top: 10px;
	height: 100%;
	overflow: hidden;

	${media.ll`
		grid-template-columns: 248px 1fr 483px;
	`}

	${media.xl`
		grid-template-columns: 338px 937px auto;
		grid-template-rows: 100px calc(100% - 200px) 100px;
	`}
`;
