/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as MagnifyingGlassIcon } from 'src/assets/icons/search.svg';
import { TextInput } from '../TextInput/TextInput';

export const ClearIcon = styled(CrossIcon)`
	color: var(--white);
	cursor: pointer;
`;

export const SearchIcon = styled(MagnifyingGlassIcon)`
	color: var(--neutral-300);
	padding-right: 0;
`;

// Default background should be --neutral-700, but at User-Network we keep --neutral-900
export const SearchInput = styled(TextInput)`
	position: relative;
	background-color: var(--input-background);
	border: 2px solid var(--input-background);

	height: 40px !important;

	&::after {
		content: '';
		display: block;
		position: absolute;
		background: linear-gradient(180deg, var(--neutral-700) 0%, transparent 100%);
		transform: rotate(90deg);
		width: 25px;
		height: 25px;
		right: 30px;
	}

	& .last-icon {
		display: flex;
	}
`;
