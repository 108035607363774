/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const FlagNames = {
	KYC: 'kyc',
	DeleteKYC: 'delete_kyc',
	TrackRecord: 'track_record',
	TrackRecordProduct: 'track_record_product',
	MultiTenancy: 'multi_tenancy',
	ChangeLanguage: 'change_language',
	Chat: 'chat',
	ChatGroups: 'chat_groups',
	News: 'news',
	PaperOtcOrders: 'paper_otc_orders',
	OverviewPage: 'overview_page',
	Eventstream: 'eventstream',
	Guidebook: 'guidebook',
	EditOrder: 'edit_order',
	ExchangeFirmOrders: 'firm_exchange_orders_enabled', // Note: flag applies only for physical market for the time being
};

export const AllFlagsEnabled = Object.values(FlagNames).map(flagName => ({
	name: flagName,
	isActive: true,
}));
