/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState, useEffect } from 'react';

export const useUpdateEvery = intervalTime => {
	const [, setState] = useState(0);

	useEffect(() => {
		let interval;

		if (intervalTime > 0) {
			interval = setInterval(() => setState(state => state + 1), intervalTime);
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [setState, intervalTime]);
};
