/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { h500 } from 'src/components/Typography/fonts';

export const File = styled.div`
	min-width: 144px;
	display: inline-grid;
	align-items: center;
	grid-gap: 16px;
	grid-template-columns: ${props => (props.$smallIcon ? '22px' : '38px')} auto 20px;
	color: var(--text-color-default);

	span {
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		${h500}
	}

	button {
		color: var(--text-color-default);
		cursor: pointer;
		font-weight: 100;
		font-size: 20px;
	}
`;

export const FileSection = styled.div`
	display: flex;
	gap: 24px;
	width: 100%;
	flex-wrap: wrap;

	&:not(:empty) + button {
		margin-top: 24px;
	}
`;
