/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const getInitials = (name = '', lastName = '') => {
	if (!lastName) {
		const tokens = name.trim().toUpperCase().split(' ');
		const firstToken = tokens[0];
		const lastToken = tokens[tokens.length - 1];

		if (firstToken === lastToken) {
			return firstToken[0];
		}

		return `${firstToken[0]}${lastToken[0]}`;
	}

	return `${name.toUpperCase()[0]}${lastName.toUpperCase()[0]}`;
};
