/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { isUserLoggedInRequest } from './actions';

export const ReduxProvider = ({ children, ...params }) => {
	return (
		<Provider {...params}>
			<AppWrapper>{children}</AppWrapper>
		</Provider>
	);
};

const AppWrapper = ({ children }) => {
	const dispatch = useDispatch();
	// Immediately fires the action to check if user is Logged In
	useEffect(() => {
		dispatch(isUserLoggedInRequest());
	}, [dispatch]);
	return <>{children}</>;
};
