/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers';
import { t50, h700 } from 'src/components/Typography/fonts';
import { ReactComponent as InfoSVG } from 'src/assets/icons/info.svg';

const text = css`
	${t50}
	font-size: 12px;

	${media.xl`
        font-size: 13px;
    `}
`;

export const InfoIcon = styled(InfoSVG)`
	width: 16px;
	height: 16px;
	color: var(--neutral-200);
`;

export const InfoButton = styled.button`
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0;
`;

export const Dialog = styled.dialog`
	--v-padding: 32px;
	--h-padding: 24px;
	--scrollbar-padding: 20px;
	cursor: default;

	${media.xl`
		--v-padding: 40px;
		--h-padding: 32px;
	`}

	&::backdrop {
		background: var(--primary-1000);
		opacity: 50%;
	}

	position: relative;
	border-radius: var(--bigger-border-radius);
	border: 0;
	background-color: var(--neutral-850);
	color: var(--neutral-100);
	width: 440px;
	overflow: hidden;
	padding: 0;

	${media.xl`
        width: 480px;
    `}

	&:before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 183px;
		background: linear-gradient(180deg, rgba(54, 60, 219, 0.2) 0%, rgba(33, 37, 70, 0) 100%);
		z-index: -1;
	}
`;

export const Body = styled.div`
	inset: 0;
`;

export const CloseButton = styled.button`
	position: absolute;
	right: 24px;
	top: 24px;
	padding: 0;
	margin: 0;

	svg {
		opacity: 50%;

		path {
			fill: var(--white);
		}
	}

	&:hover {
		svg {
			opacity: 1;
		}
	}
`;

export const ScrollableArea = styled.div`
	padding-left: var(--h-padding);
	padding-right: calc(var(--v-padding) - var(--scrollbar-padding));
	margin-right: var(--scrollbar-padding);
	overflow: auto;
	max-height: calc(100vh - 200px);
	scrollbar: thin;
`;

export const Title = styled.h3`
	${h700}
	font-weight: 700;
	color: var(--text-color-default);
	margin: 0 0 16px 0;
	padding: var(--v-padding) var(--h-padding) 0;
`;

export const SubHeader = styled.h3`
	${h700}
	font-weight: 700;
	color: var(--text-color-default);
	margin: 32px 0 16px;
`;

export const Description = styled.pre`
	font-family: var(--font-base);
	${text}
	white-space: pre-line;
	margin: 0;
	padding: 0;

	& + dl {
		margin-top: 24px;
	}
`;

export const Terms = styled.dl`
	display: grid;
	grid-template-columns: 100px auto;
	grid-gap: 8px;
	margin: 0;
	padding: 0;
	${text}

	${media.xl`
        grid-gap: 12px;
    `}

	dt, dd {
		margin: 0;
		padding: 0;
	}

	dt {
		font-weight: 700;
		color: var(--text-color-default);
	}

	& + pre {
		margin-top: 32px;
	}
`;

export const Footer = styled.div`
	height: var(--v-padding);
`;
