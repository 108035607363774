/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const emit = function (...params) {
	const { eventName, subscriptionName, data, error } = composeEmitParameters(params);

	if (!!error) {
		throw Error(error);
	}

	const event = new CustomEvent(subscriptionName, { detail: { eventName, data } });
	document.dispatchEvent(event);
};

const composeEmitParameters = params => {
	let data = null;
	let error = null;

	const eventName = params
		.reduce((acc, p) => {
			if (typeof p === 'string' && data === null) {
				acc.push(p);
			} else if (data === null) {
				data = p;
			}

			return acc;
		}, [])
		.join('.');

	const subscriptionName = eventName.split('.')?.[0];

	if (params.length < 1) {
		return { error: 'Emitted event with not enough params. At least "eventName" is needed.' };
	}

	if (!eventName || eventName.length === 0 || /^\.+$/gi.test(eventName)) {
		return { error: 'Emitted event with wrong params. eventName has to be a notempty string.' };
	}

	return { eventName, subscriptionName, data, error };
};

/**
 * return emit
 */
export const useEmit = (...params) => {
	if (params.length === 0) {
		return emit;
	}

	return emit.bind(null, ...params);
};
