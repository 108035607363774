/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const FilterType = {
	All: 'all',
	Bid: 'bid',
	Offer: 'offer',
};

export const FilterQueryParam = {
	[FilterType.Bid]: 'buy',
	[FilterType.Offer]: 'sell',
};

export const OTCType = {
	All: 'all',
	Sent: 'sent',
	Received: 'received',
};
