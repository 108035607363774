/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import * as Styled from './styled';
import { H400 } from 'src/components/Typography';
import { OverflowArray, OverflowText } from 'src/components/Tooltip/OverflowText';

export const TradeProductInfo = ({ tradeData }) => {
	const { t } = useTranslation();

	const {
		header_info: { volume, price, price_unit = 'mt', product, inco, primary_ports },
		counterparties_seller_info: { company: seller_company_name },
		counterparties_buyer_info: { company: buyer_company_name },
		isBuyerSide,
	} = tradeData;

	const incoWithPortNames = primary_ports
		.map((port, index) => {
			if (index === 0) {
				return `${inco} ${port?.name}`;
			}

			return port?.name;
		})
		.filter(Boolean);

	return (
		<Styled.TradeProductInfoWrapper>
			{isBuyerSide ? (
				<Styled.ProductItem data-test="trade-details-header-info-product">
					<H400>{t('seller')}</H400>
					<H400 className="overflow-wrapper">
						<OverflowText>{seller_company_name || '---'}</OverflowText>
					</H400>
				</Styled.ProductItem>
			) : (
				<Styled.ProductItem data-test="trade-details-header-info-product">
					<H400>{t('buyer')}</H400>
					<H400 className="overflow-wrapper">
						<OverflowText>{buyer_company_name || '---'}</OverflowText>
					</H400>
				</Styled.ProductItem>
			)}
			<Styled.ProductItem data-test="trade-details-header-info-product">
				<H400>{t('product')}</H400>
				<H400 className="overflow-wrapper">
					<OverflowText>{product}</OverflowText>
				</H400>
			</Styled.ProductItem>
			<Styled.ProductItem data-test="trade-details-header-info-inco-port">
				<H400>{t('inco')}</H400>
				<H400>
					<OverflowArray texts={incoWithPortNames} />
				</H400>
			</Styled.ProductItem>
			<Styled.ProductItem data-test="trade-details-header-info-quantity">
				<H400>{t('trade_quantity')}</H400>
				<H400 className="overflow-wrapper">
					<OverflowText>
						<NumberFormat thousandSeparator displayType="text" value={volume} />{' '}
						{t('mt')}
					</OverflowText>
				</H400>
			</Styled.ProductItem>
			<Styled.ProductItem data-test="trade-details-header-info-price">
				<H400>{t(`price_per_${price_unit}`)}</H400>
				<H400 className="overflow-wrapper">
					<OverflowText>{price}</OverflowText>
				</H400>
			</Styled.ProductItem>
			{!!tradeData.vessel_name && (
				<Styled.ProductItem data-test="trade-details-header-info-vessel_name">
					<H400>{t(`vessel_name`)}</H400>
					<H400 className="overflow-wrapper">
						<OverflowText>{tradeData.vessel_name}</OverflowText>
					</H400>
				</Styled.ProductItem>
			)}
		</Styled.TradeProductInfoWrapper>
	);
};
