/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useSelector } from 'react-redux';
import { getUser } from 'src/_store/selectors/user.selectors';

export const useCurrentUser = () => {
	const currentUser = useSelector(getUser);

	return {
		user: {
			_key: currentUser.session._key,
			name: currentUser.session.name,
			avatar_color: currentUser.avatar_color,
		},
		company: {
			name: currentUser.company_name,
			avatar_color: currentUser.company_avatar_color,
		},
	};
};
