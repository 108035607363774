/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useState } from 'react';
import { DrawerContextKeys, useDrawerContext } from '../Drawer/DrawerContext';
import { ViewPhysicalOrderDrawerContent } from './ViewPhysicalOrderDrawerContent';
import { ViewPaperOrderDrawerContent } from './ViewPaperOrderDrawerContent';
import { Market } from 'src/constants/contract';
import { DrawerContent } from '../Drawer/Drawer';
import * as Styled from './styled';

export const ViewOrderDrawerContent = ({
	order,
	isLastCounter,
	changes,
	shouldShowAdditionalInfoSection,
}) => {
	const [isScrolling, setIsScrolling] = useState(false);
	const { setTemporaryHeader } = useDrawerContext()[DrawerContextKeys.viewDrawer];

	useEffect(() => {
		return () => {
			setTemporaryHeader(null);
		};
	}, [order._key, setTemporaryHeader]);

	const handleScroll = event => {
		if (event.target.scrollTop > 0) {
			setIsScrolling(true);
		} else {
			setIsScrolling(false);
		}
	};

	return (
		<DrawerContent>
			<Styled.DrawerContentWrapper>
				{order.market === Market.Physical ? (
					<ViewPhysicalOrderDrawerContent
						order={order}
						isScrolling={isScrolling}
						handleScroll={handleScroll}
						changes={changes}
						isLastCounter={isLastCounter}
						shouldShowAdditionalInfoSection={shouldShowAdditionalInfoSection}
					/>
				) : (
					<ViewPaperOrderDrawerContent
						order={order}
						isScrolling={isScrolling}
						handleScroll={handleScroll}
						changes={changes}
						isLastCounter={isLastCounter}
					/>
				)}
			</Styled.DrawerContentWrapper>
		</DrawerContent>
	);
};
