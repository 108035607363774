/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const getRealIsDirty = ({ isDirty, dirtyFields = {}, touchedFields }) => {
	if (!isDirty) {
		return false;
	}

	if (dirtyFields.instrument || dirtyFields.presetID) {
		return true;
	}

	const dirtySections = dirtyFields.contractPricing;

	if (!dirtySections) {
		return false;
	}

	if (dirtySections.length > 1) {
		return true;
	}

	const isDirtyAndTouched = Object.entries(dirtySections[0]).some(([fieldName, fieldValue]) => {
		if (fieldValue && touchedFields?.contractPricing?.[0]?.[fieldName]) {
			return true;
		}

		return false;
	});

	return isDirtyAndTouched;
};
