/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { LandscapeMobileChart } from './components/LandscapeMobileChart';
import { PortraitMobileChart } from './components/PortraitMobileChart';
import { useQueryParams } from 'src/_helpers/router';
import i18n from 'i18next';
import { useComponentWillMount } from 'src/_helpers/useComponentWillMount';

export const MobileChart = () => {
	const { portrait, lang } = useQueryParams();

	const language = ['zh', 'en'].includes(lang) ? lang : 'en';

	useComponentWillMount(() => {
		i18n.changeLanguage(language);
	});

	if (portrait === 'true') {
		return <PortraitMobileChart />;
	}

	return <LandscapeMobileChart />;
};
