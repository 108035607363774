/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import {
	REGISTER_USER_REQUEST,
	REGISTER_USER_SUCCESS,
	REGISTER_USER_ERROR,
	LOGIN_USER_REQUEST,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_ERROR,
	LOGIN_SET_SELECTED_2FA_METHOD,
	LOGIN_USER_SHOW_2FA,
	LOGIN_USER_SHOW_TERMS_AND_CONDITIONS,
	LOGIN_2FA_INCORRECT_CODE,
	LOGOUT_USER_REQUEST,
	RESET_USER_CONFIRM_REQUEST,
	RESET_USER_CONFIRM_SUCCESS,
	RESET_USER_CONFIRM_ERROR,
	LOGOUT_USER_ERROR,
	LOGOUT_USER_SUCCESS,
	USER_IS_LOGGED_IN,
	USER_IS_NOT_LOGGED_IN,
	IS_USER_LOGGED_IN_REQUEST,
	RESET_USER_ERRORS,
	SET_AVATAR_COLOR,
	SET_COMPANY_NAME,
	SET_COMPANY_AVATAR_COLOR,
} from 'src/constants/actionTypes';
import { ROUTES } from 'src/constants/routes';
import { TwoFactorMethod, TwoFactorStatus } from 'src/constants/user';

export const loggedOutInitialState = {
	isLoggedIn: false,
	isRegistered: false,
	isLoading: false,
	isPasswordChanged: false,
	error: '',
	isLoggingOut: false,
	isCheckingAuth: false,
	mfaMethods: null,
	phone: null,
	selected2FAMethod: null,
	showTermsAndConditions: false,
	requestedPath: ROUTES.root,
};

export const appInitialState = {
	...loggedOutInitialState,
	isCheckingAuth: true,
};

export function userReducers(state = appInitialState, { type, payload }) {
	switch (type) {
		case IS_USER_LOGGED_IN_REQUEST: {
			return {
				...state,
				isCheckingAuth: true,
			};
		}
		case USER_IS_NOT_LOGGED_IN: {
			return {
				...state,
				requestedPath: payload,
				isCheckingAuth: false,
			};
		}
		case REGISTER_USER_REQUEST: {
			return {
				...state,
				isLoading: true,
				error: '',
			};
		}
		case REGISTER_USER_SUCCESS: {
			return {
				...state,
				...payload,
				isRegistered: true,
				isLoading: false,
				error: '',
			};
		}
		case REGISTER_USER_ERROR: {
			return {
				...state,
				...payload,
				isRegistered: false,
				isLoading: false,
				error: payload.error,
			};
		}
		case LOGIN_USER_REQUEST: {
			return {
				...state,
				isLoading: true,
				error: '',
			};
		}
		case LOGIN_USER_SUCCESS:
		case USER_IS_LOGGED_IN: {
			return {
				...state,
				...payload,
				isCheckingAuth: false,
				isRegistered: false,
				isLoggedIn: true,
				isLoading: false,
				error: '',
				mfaMethods: null,
			};
		}
		case LOGIN_USER_ERROR: {
			return {
				...state,
				...payload,
				isLoggedIn: false,
				isLoading: false,
				error: payload.error,
			};
		}
		case LOGIN_SET_SELECTED_2FA_METHOD: {
			return {
				...state,
				selected2FAMethod: payload['2fa_method'],
				phone: payload.phone_number,
				isLoading: false,
			};
		}
		case LOGIN_USER_SHOW_2FA: {
			// if we receive an array instead of object, fallback to default values
			const mfaMethods = Array.isArray(payload['2fa_methods'])
				? {
						[TwoFactorMethod.Email]: TwoFactorStatus.Available,
						[TwoFactorMethod.SMS]: (payload['2fa_methods'] || []).includes(
							TwoFactorMethod.SMS
						)
							? TwoFactorStatus.Available
							: TwoFactorStatus.PhoneNotProvided,
				  }
				: payload['2fa_methods'];

			return {
				...state,
				isLoggedIn: false,
				isLoading: false,
				mfaMethods,
			};
		}
		case LOGIN_USER_SHOW_TERMS_AND_CONDITIONS: {
			return {
				...state,
				isLoggedIn: false,
				isLoading: false,
				showTermsAndConditions: true,
			};
		}
		case LOGIN_2FA_INCORRECT_CODE: {
			return {
				...state,
				isLoggedIn: false,
				isLoading: false,
				error: 'invalid_2fa_code',
			};
		}
		case LOGOUT_USER_REQUEST: {
			return {
				...state,
				isLoggingOut: true,
			};
		}
		case LOGOUT_USER_ERROR: {
			return {
				...state,
				isLoggingOut: false,
				error: payload.error,
			};
		}
		case LOGOUT_USER_SUCCESS: {
			return loggedOutInitialState;
		}

		case RESET_USER_CONFIRM_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case RESET_USER_CONFIRM_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case RESET_USER_CONFIRM_ERROR:
			return {
				...state,
				error: payload.error,
				isLoading: false,
			};
		case RESET_USER_ERRORS:
			return {
				...state,
				error: '',
			};
		// TODO: Remove this action entirely once we have company name and avatar color in JWT
		case SET_AVATAR_COLOR:
			return {
				...state,
				avatar_color: payload,
			};
		case SET_COMPANY_NAME:
			return {
				...state,
				company_name: payload,
			};
		case SET_COMPANY_AVATAR_COLOR:
			return {
				...state,
				company_avatar_color: payload,
			};
		default:
			return state;
	}
}
