/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const parseMyNewTrades = list =>
	list.map(item => {
		const url = `/?orderEnvironment=${item.environment}&orderId=${item.order_id}&negotiationId=${item.first_counter_id}`;

		return {
			...item,
			url,
		};
	});
