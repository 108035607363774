/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
/* eslint-disable no-useless-escape */
import { draftToMarkdown } from 'markdown-draft-js';
import htmlToDraft from 'html-to-draftjs';
// Due the path 'marked/lib/marked' is not an exported path
// in package.json of markedjs, the following line:
// import { parse, use } from 'marked/lib/marked';
// generate errors on the build
// Also due the path declared in 'main' in markedjs package.json
// is a esm module, and that is not supported by jest we will map the standard import
// only for jest as 'marked/lib/marked'. Check package.json section 'jest' for more details.
import { parse, use } from 'marked';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { newsPublishingToolbarConfig } from 'src/components/ReactDraftWysiwyg/config';

export const parseMarkdownForTeaser = markdown => {
	if (!markdown) {
		return '';
	}

	let md = markdown;
	// TODO: Use parser or tokenizer the way so you can individually disable links in thread teaser only
	// Disable making bullet points
	// TODO: Headings are now enabled but again, we need to disable them on teasers only; probably and this would require 2 independent tokenizers
	// https://vosbor.atlassian.net/browse/TD-509
	const tokenizer = {
		list: () => {},
		listitem: () => {},
	};

	use({ tokenizer });

	md = md.replace(/\+{2}([^\+]*)\+{2}/gi, '$1'); // remove underline - not supported by markdown out of the box

	const markdownLines = (md || '').split('\n\n');

	return parse(markdownLines[0]);
};

export const parseMarkdown = markdown => {
	if (!markdown) {
		return '';
	}

	let md = markdown;
	// Disable making bullet points
	const tokenizer = {
		list: () => {},
		listitem: () => {},
	};

	use({ tokenizer });

	md = md.replace(/\+{2}([^\+]*)\+{2}/gi, '<u>$1</u>'); // Apply underline - not supported by markdown out of the box

	let parsedMarkdown = parse(md)
		.replace(/<a /gi, '<a target="_blank" class="markdown-link" ') // Open every link in a new tab
		.replace(/&amp;gt;/gi, '&gt;');

	parsedMarkdown = replaceEmojiNumberForHtml(parsedMarkdown).trim();

	newsPublishingToolbarConfig().emoji.emojis.forEach(emoji => {
		parsedMarkdown = parsedMarkdown.replace(emoji, `<span class="icon-emoji">${emoji}</span>`);
	});

	return parsedMarkdown;
};

const replaceEmojiNumberForHtml = markdown => {
	return markdown
		.replace(/0️⃣/gi, '<span class="guidebook-pointer">0</span>')
		.replace(/1️⃣/gi, '<span class="guidebook-pointer">1</span>')
		.replace(/2️⃣/gi, '<span class="guidebook-pointer">2</span>')
		.replace(/3️⃣/gi, '<span class="guidebook-pointer">3</span>')
		.replace(/4️⃣/gi, '<span class="guidebook-pointer">4</span>')
		.replace(/5️⃣/gi, '<span class="guidebook-pointer">5</span>')
		.replace(/6️⃣/gi, '<span class="guidebook-pointer">6</span>')
		.replace(/7️⃣/gi, '<span class="guidebook-pointer">7</span>')
		.replace(/8️⃣/gi, '<span class="guidebook-pointer">8</span>')
		.replace(/9️⃣/gi, '<span class="guidebook-pointer">9</span>')
		.replace(/🔟/gi, '<span class="guidebook-pointer">10</span>');
};

export const convertToMarkdown = state => {
	const result = draftToMarkdown(convertToRaw(state.getCurrentContent()), {
		entityItems: {
			IMAGE: {
				open: () => '',
				close: entity => `![](${entity['data'].src})`,
			},
		},
		styleItems: {
			UNDERLINE: {
				open: () => '++',
				close: () => '++',
			},
		},
	});

	return result;
};

export const convertToDraft = text => {
	// Apply underline - not supported by markdown out of the box
	let html = text.replace(/\+{2}([^\+]*)\+{2}/gi, '<u>$1</u>');
	html = parse(html, { xhtml: true });

	// Note: without the dummy paragraph at the end, we won't be able to delete image if there's no text after it...
	if (html.endsWith('alt=""/></p>\n')) {
		html += '<p></p>\n';
	}

	const blocksFromHtml = htmlToDraft(html);
	const { contentBlocks, entityMap } = blocksFromHtml;
	const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

	return EditorState.createWithContent(contentState);
};
