/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { processTrade } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useMutation, useQueryClient } from 'react-query';
import { useTogglable } from 'src/_helpers/useTogglable';
import { tTradeStep } from 'src/constants/tradeStatus';
import {
	Modal,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	ModalSubTitle,
	CancelButton,
} from 'src/components/Modal';
import { useTradeDataContext } from 'src/containers/ExecutionDetails/Paper/TradeDataContext';
import { ModalSize, ModalType } from 'src/constants/modal';

export const useCompleteStepMutation = ({ tradeId, onSuccess }) => {
	const { addToast } = useToast();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	return useMutation(processTrade, {
		onSuccess: async () => {
			await queryClient.refetchQueries(['trade', tradeId]);
			queryClient.refetchQueries(['activity_logs', tradeId]);
			onSuccess?.();
		},
		onError: () => {
			addToast({
				message: t('something_went_wrong'),
				kind: 'error',
			});
		},
	});
};

export const CompleteStepModal = ({ trigger, action }) => {
	const { t } = useTranslation();
	const [isOpen, { open, close }] = useTogglable();
	const { tradeId, tradeStep } = useTradeDataContext();

	const { isLoading, mutate } = useCompleteStepMutation({
		tradeId,
		onSuccess: close,
	});

	return (
		<Modal
			data-test="complete-step"
			size={ModalSize.Tiny}
			open={open}
			close={close}
			isOpen={isOpen}
			trigger={trigger}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>
					{t('mark_the_{{stage}}_stage_as_completed', {
						stage: tTradeStep(t, tradeStep),
					})}
				</ModalTitle>
				<ModalSubTitle style={{ marginBottom: '40px' }}>
					{t('do_you_want_to_mark_this_stage_as_completed?')}
				</ModalSubTitle>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<PrimaryButton
					loading={isLoading}
					type="button"
					onClick={() => mutate({ tradeId, action })}
				>
					{t('mark_as_complete')}
				</PrimaryButton>
			</ButtonsWrapper>
		</Modal>
	);
};
