/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Button } from './Button';
import styled from 'styled-components';

export const SecondaryButton = styled(Button)`
	background: var(--neutral-400);
	color: var(--white);

	&:hover {
		background: var(--neutral-300);
		color: var(--white);
	}

	&:active,
	&.active,
	&:focus,
	&.loading {
		background: var(--neutral-500);
		color: var(--white);
	}

	&.darker {
		background: var(--neutral-600);

		&:hover {
			background: var(--neutral-500);
			color: var(--white);
		}

		&.disabled,
		&:disabled {
			background: var(--neutral-900);
		}

		&:active,
		&:focus,
		&.loading {
			background: var(--neutral-700);
			color: var(--white);
		}
	}
`;
