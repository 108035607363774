/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const physicalTradeBookColumnWidth = {
	'counterparty.name': { default: 96, xl: 126 },
	broker: { default: 78, ll: 88, xl: 126 },
	trade_step: { default: 88, xl: 126 },
	'product.name': { default: 88, xl: 116 },
	vessel_name: { default: 88, xl: 106 },
	shipment: { default: 128, ll: 118, xl: 166 },
	volume: { default: 58, xl: 86 },
	price: { default: 63, ll: 68, xl: 76 },
	currency: { default: 58, xl: 66 },
	position: { default: 48, xl: 76 },
	price_type: { default: 48, xl: 66 },
	bl_date: { default: 70, ll: 78, xl: 86 },
	bl_quantity: { default: 68, ll: 78, xl: 86 },
	'inco.name': { default: 48, xl: 56 },
	'primary_ports[0].name': { default: 58, xl: 96 },
	payment: { default: 48, xl: 76 },
	created_at: { default: 115, ll: 92, xl: 96 },
};

export const paperTradeBookColumnWidth = {
	'counterparty.name': { default: 78, ll: 120, xl: 126 },
	broker: { default: 78, ll: 120, xl: 126 },
	trade_step: { default: 70, xl: 126 },
	'product.name': { default: 70, xl: 126 },
	shipment: { default: 70, xl: 126 },
	volume: { default: 68, xl: 96 },
	price: { default: 88, xl: 104 },
	position: { default: 68, xl: 96 },
	price_type: { default: 68, xl: 96 },
	futures_market: { default: 90, xl: 116 },
	futures_contract_date: { default: 90, xl: 116 },
	'inco.name': { default: 68, xl: 76 },
	'primary_ports[0].name': { default: 88, xl: 126 },
	runs: { default: 68, xl: 76 },
	created_at: { default: 100, ll: 104, xl: 126 },
};

export const TradePosition = {
	Long: 'long',
	Short: 'short',
};

export const Tradebook = 'tradebook';
