/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import background from 'src/assets/images/mobile-app-view.png';
import { media } from 'src/_helpers';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	z-index: 2;
	width: 100vw;
	height: 100dvh;
`;

export const Header = styled.div`
	height: 69px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--white);
	padding: 0 24px 0;
	flex-shrink: 0;

	svg {
		width: 132px;
		height: 16px;
	}
`;

export const ContactLink = styled.a`
	color: #47474f;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.42px;

	&:hover {
		text-decoration: none;
		color: #47474f;
	}
`;

export const ContentHeading = styled.div`
	margin-top: 30px;
`;

export const GetTheAppHeading = styled.span`
	color: var(--blue-vosbor-blue-400, #4200ff);
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 36px;
	letter-spacing: 0.804px;
	text-transform: uppercase;
`;

export const ContentSubHeading = styled.h1`
	color: #47474f;
	font-family: Poppins;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 129.5%; /* 31.08px */
	letter-spacing: 0.12px;
`;

export const Content = styled.div`
	padding: 0 24px 0;
	display: flex;
	flex-direction: column;
	background: #fefae3;
	flex-grow: 1;
`;

export const ImageWrapper = styled.div`
	margin: 42px 0;
	flex: 1;
	display: flex;
	justify-content: center;
`;

export const Image = styled.div`
	background: url(${background});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	flex: 1;
	max-width: 300px;
	@media (orientation: landscape) {
		background: none;
	}
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
	background: #fefae3;
`;

export const FooterText = styled.span`
	color: #47474f;
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 129.5%; /* 18.13px */
	letter-spacing: 0.07px;
	white-space: pre-line;
	margin-top: 16px;
	padding: 0 24px 0;

	${media.s`
		gap: 11px;
	`}

	& a {
		color: var(--primary-200);
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 129.5%;
		letter-spacing: 0.07px;
		text-decoration-line: underline;
	}
`;

export const FooterButtons = styled.div`
	display: flex;
	justify-content: center;
	gap: 8px;
	padding: 0 24px 0;

	${media.s`
		gap: 16px;
	`}

	& a {
		& svg {
			display: block;
			width: 100%;
			height: inherit;
		}
	}
`;
