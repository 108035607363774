/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import * as Styled from './styled';

export const SearchInput = ({ onClear, FrontIconComponent = Styled.SearchIcon, ...props }) => {
	const isInputFilled = !!props.value?.length;

	return (
		<Styled.SearchInput
			iconComponent={
				isInputFilled && <Styled.ClearIcon data-test="clear-input" onClick={onClear} />
			}
			frontIconComponent={<FrontIconComponent data-test="search-icon" />}
			{...props}
		/>
	);
};
