/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import {
	SectionContainer,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
	SectionTitle,
} from '../../styled';
import { useTranslation } from 'react-i18next';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { tSpecDirection } from 'src/constants/product';
import * as Styled from './styled';

export const SpecificationsSection = ({ order }) => {
	const { t } = useTranslation();

	const { grade } = order;

	return (
		<SectionContainer data-test="specifications-section">
			<SectionTitle>{t('specifications')}</SectionTitle>
			<SectionGrid>
				{grade.gradespecs.map(({ _key, spec, value }) => {
					return (
						<SectionGridContent key={_key}>
							<SectionGridLabel>
								<OverflowText>{spec.name}</OverflowText>
							</SectionGridLabel>
							<SectionGridText>
								<OverflowText>
									<Styled.SpecDirection>
										{tSpecDirection(t, spec)}
									</Styled.SpecDirection>{' '}
									{value}
									{spec.unit}
								</OverflowText>
							</SectionGridText>
						</SectionGridContent>
					);
				})}
			</SectionGrid>
		</SectionContainer>
	);
};
