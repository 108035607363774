/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const cookieConsentName = 'vosborcookie';
export const cookieConsentValue = 'true';
const daysToExpire = 999;

export const setCookieConsent = () => {
	const date = new Date();
	date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + date.toUTCString();
	document.cookie = cookieConsentName + '=' + cookieConsentValue + '; ' + expires + '; path=/';
};

export const isCookieConsentSet = () => {
	const name = `${cookieConsentName}=`;
	const cDecoded = decodeURIComponent(document.cookie);
	const cArr = cDecoded.split('; ');
	const cookie = cArr.find(token => token.indexOf(name) === 0);

	return cookie ? cookie.substring(name.length) === cookieConsentValue : false;
};
