/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';

const BlockNavigationContext = createContext({
	isNavigationBlocked: false,
	blockNavigation: (reason, unblockCallback) => {},
	unblockNavigation: () => {},
});

export const BlockNavigationProvider = ({ children }) => {
	const [navigationBlockedReason, setNavigationBlockReason] = useState(null);
	const [unblockCallback, setUnblockCallback] = useState(null);

	const blockNavigation = useCallback((reason, callback) => {
		setNavigationBlockReason(reason);
		setUnblockCallback(() => callback);
	}, []);

	const unblockNavigation = useCallback(() => {
		unblockCallback?.();
		setNavigationBlockReason(null);
		setUnblockCallback(null);
	}, [unblockCallback]);

	const isNavigationBlocked = useMemo(() => navigationBlockedReason !== null, [
		navigationBlockedReason,
	]);

	return (
		<BlockNavigationContext.Provider
			value={{
				isNavigationBlocked,
				navigationBlockedReason,
				blockNavigation,
				unblockNavigation,
			}}
		>
			{children}
		</BlockNavigationContext.Provider>
	);
};

export const useBlockNavigationContext = () => useContext(BlockNavigationContext);
