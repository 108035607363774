/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { h500 } from 'src/components/Typography';
import styled from 'styled-components';

export const Avatar = styled.div`
	position: relative;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border: 2px solid;
	border-color: ${props => props.$color};
	color: var(--text-color-default);
	background: transparent;
	font-size: 10px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.2px;
	border-radius: 50%;
	text-transform: uppercase;

	.active & {
		background: transparent !important;
		color: var(--text-color-default) !important;
		border-color: var(--text-color-default);
	}
`;

export const Title = styled.div`
	${h500}

	&,
	& + div {
		width: 142px;
	}
`;
