/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { uploadOrderFiles } from 'src/_api';

import { useOnce } from 'src/_helpers/useOnce';
import { useToast } from 'src/components/Toast';
import { useTranslation } from 'react-i18next';
import { OrderFile } from 'src/components/OrderFile/OrderFile';
import { downloadLocalFile } from 'src/_helpers/downloadLocalFile';
import { downloadFile } from 'src/_helpers/downloadFile';

export const withUpload = Component => {
	return ({ file, handleDelete, handleSuccess, ...rest }) => {
		const { mutate } = useMutation(uploadOrderFiles, {
			onError: error => {
				if (error.name !== 'AbortError') {
					handleDelete();
					addToast({
						message: t('failed_to_add_file'),
						kind: 'error',
					});
				}
			},
			onSuccess: (data, { file }) => {
				file.id = data._key;
				file._key = data._key;
				handleSuccess(data._key, file);
			},
		});
		const { t } = useTranslation();
		const { addToast } = useToast();
		const [removed, setRemoved] = useState(false);
		const controller = useRef(new AbortController());

		useOnce(() => {
			if (!file.id && !removed) {
				if (!controller.current || controller.current.aborted) {
					controller.current = new AbortController();
				}

				mutate({ file, signal: controller.current?.signal });
			}
		});

		const onDelete = useCallback(() => {
			if (controller.current) {
				controller.current.abort();
			}

			handleDelete(file?.id);
			setRemoved(true);
		}, [handleDelete, file]);

		const handleLocalFileDownload = useCallback(({ target }, file) => {
			if (target.tagName === 'H4') {
				if (file.created_at) {
					downloadFile(file);
				} else {
					downloadLocalFile(file);
				}
			}
		}, []);

		return (
			<Component
				file={file}
				handleDelete={onDelete}
				onClick={event => handleLocalFileDownload(event, file)}
				{...rest}
			/>
		);
	};
};

export const UploadedFile = styled(withUpload(OrderFile))`
	div:hover {
		h4 {
			cursor: pointer;
			text-decoration: underline;
		}
	}
`;
