/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { CUSTOM_CONTRACT_TERMS, PaperPorts, OrderType } from 'src/constants/contract';
import { formatPrice } from 'src/_helpers/price';
import { formatTradeDocuments } from 'src/_helpers/formatTradeDocuments';

export const formatPaperTradeDetails = (t, response, accountUserId, settings = {}) => {
	if (!response) {
		return null;
	}

	settings.priceLabel = settings.priceLabel || 'price_per_mt';

	const {
		volume,
		product,
		price,
		buyer_company,
		buyer_user,
		seller_company,
		seller_user,
		delivery_date_from,
		order_id,
		grade,
		price_type,
		price_unit,
		futures_contract,
		futures_contract_date,
		delivery_date_to,
		created_at,
		trade_step,
		buyer_done,
		seller_done,
		seller_documents,
		buyer_documents,
		intention_duration,
		declaration_duration,
		users = [],
		buyer_user_ids = [],
		seller_user_ids = [],
		terms_id,
		terms,
		inco_id,
		currency,
		seller_broker_user,
		buyer_broker_user,
		seller_broker_company,
		buyer_broker_company,
	} = response;

	const isSellerSide = seller_user_ids.includes(accountUserId);
	const isBuyerSide = buyer_user_ids.includes(accountUserId);

	const tradeDate = created_at ? moment(created_at).format('LL') : null;

	const dateFormatMonths = (date_from, date_to) => {
		const date_from_year = moment.utc(date_from).format('YYYY');
		const date_from_month = moment.utc(date_from).format('MMM');
		const date_to_year = moment.utc(date_to).format('YYYY');
		const date_to_month = moment.utc(date_to).format('MMM');
		let returnedMonth = '';
		let returnedYear = '';

		if (date_from_year === date_to_year) {
			returnedYear = date_from_year;
		} else {
			returnedYear = `${date_from_year}/${date_to_year}`;
		}

		if (date_from_month === date_to_month) {
			returnedMonth = `${date_from_month}`;
		} else {
			returnedMonth = `${date_from_month} - ${date_to_month}`;
		}

		return `${returnedMonth} ${returnedYear}`;
	};

	const termsId = () => {
		if (terms_id === CUSTOM_CONTRACT_TERMS && response.order_user_id !== accountUserId) {
			return null;
		}

		return terms_id;
	};

	const loadingPorts = response.ports_loading;
	const dischargingPorts = response.ports_discharging;

	const isFOBParanagua = loadingPorts[0]?._key === PaperPorts.PARANAGUA;

	const getContractName = () => {
		if (isFOBParanagua) {
			return `${inco_id} ${loadingPorts[0]?.name}`;
		}
		return `${inco_id} ${dischargingPorts[0]?.name}`;
	};

	return {
		...response,
		// NOTE: Uncomment for testing purposes
		// trade_step: trade_step === 'signatures' ? 'paper_intention' : trade_step,
		trade_step,
		step_completed: isSellerSide ? seller_done : buyer_done,
		header_info: {
			orderId: order_id,
			volume: volume,
			price: formatPrice(price),
			price_unit,
			shipment: delivery_date_from && moment(delivery_date_from).format('MMMM YYYY'),
			product: product?.name,
			trade_date: tradeDate,
		},
		counterparties_user_name: isSellerSide ? buyer_user?.name : seller_user?.name,
		counterparties_seller_info: {
			id: seller_user?._key,
			company: seller_company?.name,
			trader_name: seller_user?.name,
			country: seller_company?.country_code,
			status: seller_user?.status,
		},
		counterparties_buyer_info: {
			id: buyer_user?._key,
			company: buyer_company?.name,
			trader_name: buyer_user?.name,
			country: buyer_company?.country_code,
			status: buyer_user?.status,
		},
		contract: [
			{ label: 'product', value: product?.name },
			{ label: 'contract', value: getContractName() },
			{
				label: 'contract_terms',
				description: terms.description,
				value: terms?.original_link ? { url: terms.original_link } : '',
			},
			{
				label: 'shipment',
				value:
					delivery_date_from &&
					delivery_date_to &&
					dateFormatMonths(delivery_date_from, delivery_date_to),
			},
			{
				label: 'quantity',
				value: volume,
				unit: t('mt'),
				isNumberFormat: true,
			},
			{
				label: 'price',
				value: `${formatPrice(price, {
					currencyDisplay: 'none',
				})} ${currency}/${t(`price_unit_short_${price_unit}`, { lng: 'en' })}`,
			},
			futures_contract && {
				label: 'futures_contract',
				value: `${futures_contract} ${moment(futures_contract_date).format('MMM YYYY')}`,
			},
		].filter(Boolean),
		spec: {
			name: grade?.name,
			specs: grade?.gradespecs,
		},
		contract_pricing: {
			price_type: t(price_type),
			futures_market: futures_contract,
			futures_month:
				futures_contract_date && moment(futures_contract_date).format('MMMM YYYY'),
		},
		additional_information: {
			created_at: tradeDate,
		},
		isSellerSide,
		isBuyerSide,
		account_user_info: isSellerSide
			? {
					user: seller_user,
					company: seller_company,
			  }
			: {
					user: buyer_user,
					company: buyer_company,
			  },
		seller_documents: formatTradeDocuments(seller_documents),
		buyer_documents: formatTradeDocuments(buyer_documents),
		intention_duration,
		declaration_duration,
		tradeUsers: users,
		tradeUsersIds: [...buyer_user_ids, ...seller_user_ids],
		buyer_user_ids,
		seller_user_ids,
		terms_id: termsId(),
		isSellerOrBuyer: seller_user?._key === accountUserId || buyer_user?._key === accountUserId,
		...(seller_broker_user && {
			seller_broker_info: {
				...seller_broker_user,
				broker: true,
				company: { ...seller_broker_company },
				order_type_position: OrderType.Sell,
			},
		}),
		...(buyer_broker_user && {
			buyer_broker_info: {
				...buyer_broker_user,
				broker: true,
				company: { ...buyer_broker_company },
				order_type_position: OrderType.Buy,
			},
		}),
		seller_user: {
			...seller_user,
			company: {
				...seller_company,
			},
		},
		buyer_user: {
			...buyer_user,
			company: {
				...buyer_company,
			},
		},
	};
};
