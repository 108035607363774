/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState, useEffect } from 'react';

export const useDebouncedValue = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		if (delay === 0) {
			setDebouncedValue(value);
		} else {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			return () => {
				clearTimeout(handler);
			};
		}
	}, [value, delay]);

	return debouncedValue;
};
