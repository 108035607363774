/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from 'src/components/UserAvatar/UserAvatar';
import { SectionContainer, SectionTitle } from '../../styled';
import { AvatarType } from 'src/shared/components/Avatar/Avatar';
import { SeenBy } from 'src/components/SeenBy/SeenBy';
import * as Styled from './styled';

export const CounterpartiesSection = ({ order, showSeenBy = true, withSeparator = false }) => {
	const { t } = useTranslation();

	const recipientIds = order.recipients_list.map(({ _key }) => _key);

	const counterpartiesUsers = order.recipients_list
		.filter((value, index) => index === recipientIds.indexOf(value._key))
		.filter(({ _key }) => _key !== order.user_id);

	return (
		<SectionContainer data-test="counterparties-section">
			{withSeparator && <Styled.Separator />}
			<SectionTitle>{t('counterparties')}</SectionTitle>
			<Styled.InfoMessage label={t('counterparties_visible_to_you')} />
			<Styled.SectionWrapper>
				{counterpartiesUsers.map(user => (
					<Styled.CounterpartyContainer key={user._key}>
						{showSeenBy && (
							<SeenBy
								viewedAt={user.viewed_at}
								counterpartyName={`${user.first_name} ${user.last_name}`}
								orderType={order.order_type}
							/>
						)}
						<UserAvatar
							user={user}
							key={`counterparties-section-user-${user._key}`}
							size={AvatarType.Medium}
						/>
					</Styled.CounterpartyContainer>
				))}
			</Styled.SectionWrapper>
		</SectionContainer>
	);
};
