/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { H400, T100, t100, H300 } from 'src/components/Typography/fonts';

export const StyledH300 = styled(H300)`
	margin: 24px 0 16px;
`;

export const UsersList = styled.div`
	margin: 16px 0 16px;
	overflow: auto;
	padding-right: 5px;
	height: 172px;
	overflow-x: hidden;

	${media.xl`
		height: 230px;
		margin-top: 24px;
	`};

	.trade-users-content & {
		height: 395px;
		margin: 20px 0 8px;

		${media.xl`
			height: 417px;
		`}
	}
`;

export const UserListItem = styled.div`
	position: relative;
	display: flex;
	padding: 7px 8px;
	border-radius: 8px;
	background: ${({ $selected }) => ($selected ? 'var(--neutral-800)' : 'transparent')};

	&:not(.contact-item-not-selectable) {
		cursor: pointer;

		&:hover {
			background: var(--neutral-800);
		}
	}

	&:not(:last-child) {
		margin-bottom: 2px;
	}
`;

export const NoContactsWarning = styled.div`
	${t100}
	margin: 24px 0;
	width: 100%;
	text-align: center;
	color: var(--neutral-300);
`;

export const InitialsCircleWrapper = styled.div`
	width: 58px;
	height: 100%;
	user-select: none;
`;

export const InitialsCircle = styled.div`
	margin-right: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;

	${({ selected }) =>
		selected &&
		`
		background: var(--primary-400);
		border: 2px solid var(--primary-400);
	`}

	&:hover {
		background: rgba(66, 0, 255, 0.3);
	}
`;

export const UserDetails = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
`;

const userDataText = css`
	white-space: nowrap;
	max-width: 354px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StyledCompanyName = styled(T100)`
	color: var(--neutral-300);
	margin-top: 2px;
	${userDataText}
	max-width: 200px;
`;

export const StyledUserName = styled(H400)`
	${userDataText}
`;

export const LoaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const UsersLabel = styled.div`
	margin-bottom: 16px;

	${media.xl`
		margin-bottom: 20px;
	`}
`;
