/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const mapIdsToArray = tradeIds =>
	tradeIds
		.split(',')
		.map(id => id.trim())
		// eslint-disable-next-line eqeqeq
		.filter(id => id == parseInt(id));

export const getListOfValidTradeIds = stringData =>
	stringData
		.map(item => {
			const tradeId = item.sell_trade?.trade_id;

			// eslint-disable-next-line eqeqeq
			if (!tradeId || parseInt(tradeId) != tradeId) {
				return false;
			}

			return tradeId;
		})
		.filter(Boolean);
