/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { media } from 'src/_helpers';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import styled from 'styled-components';

export const ExecutionWrapper = styled.div`
	background: var(--neutral-850);
	display: flex;
	padding: 18px 32px;
	justify-content: space-between;
	align-items: center;

	${media.xl`
		padding: 30px 48px;
  `}
`;

export const KYCWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid var(--neutral-600);
	padding: 28px 0;
	margin-left: 32px;

	${media.xl`
		margin-left: 48px;
  `}
`;

export const ShowChatButton = styled(PrimaryButton)`
	width: 200px;
	height: 40px;
	background: var(--purple-400);

	&:hover {
		background: var(--purple-200);
	}
`;
