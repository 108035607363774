/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { Controller } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import { QuoteType } from 'src/constants/contract';

const RunsField = ({ path, t }) => {
	const { orderTypeValue } = useWatchPaperFields(path);
	const isFieldRequired = orderTypeValue === QuoteType.Firm;

	return (
		<Controller
			mandatory={isFieldRequired}
			name={toPath(path, 'runs')}
			defaultValue={null}
			render={({ field, fieldState: { error } }) => (
				<MaterialNumberInput
					{...field}
					required={isFieldRequired}
					id={field.name}
					data-test={field.name}
					label={t('runs')}
					error={!!error}
					fixedDecimalScale
					decimalScale={0}
					allowNegative={false}
					isAllowed={({ formattedValue }) => formattedValue !== '0'}
				/>
			)}
		/>
	);
};

export const runsField = (path, t) => <RunsField path={path} t={t} />;
