/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { h300 } from '../Typography';

export const PasswordStrengthDetails = ({ password, polluted }) => {
	const { t } = useTranslation();

	return (
		<PasswordStrengthDetailsContainer className={polluted ? 'polluted' : ''}>
			<Indicator className={has15Characters(password) ? 'on' : ''}>
				{t('password_strength_15_characters')}
			</Indicator>
			<Separator />
			<Indicator className={hasUppercase(password) ? 'on' : ''}>
				{t('password_strength_uppercase')}
			</Indicator>
			<Separator />
			<Indicator className={hasLowercase(password) ? 'on' : ''}>
				{t('password_strength_lowercase')}
			</Indicator>
			<Separator />
			<Indicator className={hasDigit(password) ? 'on' : ''}>
				{t('password_strength_digit')}
			</Indicator>
			<Separator />
			<Indicator className={hasSpecialCharacter(password) ? 'on' : ''}>
				{t('password_strength_special_character')}
			</Indicator>
		</PasswordStrengthDetailsContainer>
	);
};

const has15Characters = txt => txt?.length >= 15;
const hasUppercase = txt => /[A-Z]/.test(txt);
const hasLowercase = txt => /[a-z]/.test(txt);
const hasDigit = txt => /[0-9]/.test(txt);
const hasSpecialCharacter = txt => /[\W_]/.test(txt);

export const isStrong = txt => {
	return (
		has15Characters(txt) &&
		hasUppercase(txt) &&
		hasLowercase(txt) &&
		hasDigit(txt) &&
		hasSpecialCharacter(txt)
	);
};

const PasswordStrengthDetailsContainer = styled.div`
	margin-top: 8px;
`;

const Indicator = styled.span`
	${h300}
	display: inline-block;
	color: var(--neutral-100);
	&.on {
		color: var(--green-800);
	}
	.polluted &:not(.on) {
		color: var(--red-400);
	}
`;

const Separator = styled.span`
	display: inline-block;
	width: 4px;
	height: 4px;
	background-color: #eeedf3;
	border-radius: 50%;
	margin: 4px;
`;
