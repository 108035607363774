/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const isVersionGreaterThan = (version, minimalVersion) => {
	const verArray = version.split('.');
	const minArray = minimalVersion.split('.');

	for (let i = 0; i < verArray.length; i += 1) {
		if (minArray[i]) {
			const value = parseInt(verArray[i], 10);
			const minValue = parseInt(minArray[i], 10);

			if (value > minValue) {
				return true;
			}

			if (value < minValue) {
				return false;
			}
		}
	}

	return false;
};
