/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { TradeStepStatus, tPhysicalTradeStep } from 'src/constants/physicalTrade';
import { useTranslation } from 'react-i18next';
import { StepProgress } from '../StepProgress/StepProgress';
import * as Styled from './styled';

export const ExecutionStepItem = ({
	isActive,
	stepProgress = TradeStepStatus.NOT_STARTED,
	clickHandler,
	step,
}) => {
	const { t } = useTranslation();

	return (
		<Styled.StepItemWrapper onClick={clickHandler} $isActive={isActive}>
			{isActive && <Styled.ActiveBar />}
			<span>{tPhysicalTradeStep(t, step)}</span>
			<StepProgress progress={stepProgress} />
		</Styled.StepItemWrapper>
	);
};
