/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useCallback } from 'react';
import moment from 'moment';
import { useEditOrdersContext } from './EditOrdersProvider';
import {
	formatTradingSessionWithFullDate,
	tTimeOptions,
	getTimeOptionMinutes,
} from 'src/_helpers/timeRange';
import { useDefaultValidities } from 'src/components/CreateOrderDrawer/paper/helpers/useDefaultValidities';
import { QuoteType, SpreadPriceFormat } from 'src/constants/contract';
import { FieldNames } from './constants';

export const useNumericInputField = (order, defaultValue, fieldName) => {
	const {
		canChange,
		changeOrder,
		getChanges,
		registerDefaultValue,
		getMeta,
	} = useEditOrdersContext();

	const presetValidities = useDefaultValidities(order.preset_id);

	const orderId = order._key;
	const changedValue = getChanges(orderId, fieldName);
	const value = changedValue === undefined ? defaultValue : changedValue;
	const hasChanged = !!changedValue && changedValue !== defaultValue;
	const isDisabled = !hasChanged && !canChange(orderId);

	const orderMeta = getMeta(orderId) || {};

	const isSpreadError =
		orderMeta.spreadPriceFormat === SpreadPriceFormat.PayCash ? !value : false;
	const isError = (!!defaultValue && !value) || isSpreadError;

	useEffect(() => {
		registerDefaultValue(orderId, fieldName, defaultValue);
	}, [defaultValue, fieldName, orderId, registerDefaultValue]);

	const handleChange = useCallback(
		value => {
			// change value of the field
			changeOrder(orderId, fieldName, value);

			// change validity if not changed manually
			const currentValidity = getChanges(orderId, FieldNames.Validity);

			if (currentValidity) {
				return;
			}

			const currentQuoteType = getChanges(orderId, FieldNames.QuoteType);

			changeOrder(
				orderId,
				FieldNames.Validity,
				getDefaultValidityForOrder(order.is_indicative, currentQuoteType, presetValidities)
			);
		},
		[changeOrder, fieldName, getChanges, order.is_indicative, orderId, presetValidities]
	);

	return {
		defaultValue,
		value,
		hasChanged,
		isDisabled,
		isError,
		handleChange,
	};
};

export const useSelectInputField = (order, defaultValue, fieldName) => {
	const { canChange, changeOrder, getChanges, registerDefaultValue } = useEditOrdersContext();

	const presetValidities = useDefaultValidities(order.preset_id);

	const orderId = order._key;
	const changedValue = getChanges(orderId, fieldName);
	const value = changedValue || defaultValue;
	const hasChanged = !!changedValue && changedValue !== defaultValue;
	const isDisabled = !hasChanged && !canChange(orderId);

	const handleChange = useCallback(
		value => {
			// change value of the field
			changeOrder(orderId, fieldName, value);

			// change validity if not changed manually
			const currentValidity = getChanges(orderId, FieldNames.Validity);

			if (currentValidity) {
				return;
			}

			const currentQuoteType =
				fieldName === FieldNames.QuoteType
					? value
					: getChanges(orderId, FieldNames.QuoteType);

			changeOrder(
				orderId,
				FieldNames.Validity,
				getDefaultValidityForOrder(order.is_indicative, currentQuoteType, presetValidities)
			);
		},
		[changeOrder, fieldName, getChanges, order.is_indicative, orderId, presetValidities]
	);

	useEffect(() => {
		registerDefaultValue(orderId, fieldName, defaultValue);
	}, [defaultValue, fieldName, orderId, registerDefaultValue]);

	return {
		defaultValue,
		value,
		hasChanged,
		isDisabled,
		handleChange,
	};
};

export const translateValidity = (t, validity, tradingSessionsList) => {
	if (Number.isInteger(validity)) {
		return tTimeOptions(t, validity);
	}

	const tradingSessionItem = tradingSessionsList?.find(({ value, label }) => value === validity);

	if (tradingSessionItem) {
		return tradingSessionItem.label;
	}

	return validity;
};

export const getDefaultValidityForOrder = (
	indicativeOrderFlag,
	quoteTypeOverride,
	presetValidities
) => {
	const isIndicative = quoteTypeOverride
		? quoteTypeOverride === QuoteType.Indicative
		: indicativeOrderFlag;

	return isIndicative
		? getTimeOptionMinutes(presetValidities.indicative)
		: getTimeOptionMinutes(presetValidities.firm);
};

export const mapValidityOptionToISOString = value => {
	if (Number.isInteger(value)) {
		return moment().utc().add(value, 'minutes').toISOString();
	}

	// trading session string
	return formatTradingSessionWithFullDate(value, true);
};

export const mapValidityOptionToApi = value => {
	if (Number.isInteger(value)) {
		const time = value;
		const hours = Math.floor(time / 60);
		const minutes = time - hours * 60;

		if (hours) {
			return `${hours}h`;
		}

		return `${minutes}m`;
	}

	// trading session string
	return value;
};
