/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';

/**
 * params [eventName]
 */
export const useSubscribe = function (eventName, callback) {
	useEffect(() => {
		if (typeof eventName !== 'string') {
			throw Error('useSubscribe parameter "eventName" must be a string.');
		}
		const subscriptionName = eventName.split('.')[0];

		const cbWrapper = ({ detail }) => {
			const { eventName: ev, data } = detail;
			if (ev.indexOf(eventName) === 0) {
				callback(ev, data);
			}
		};
		document.addEventListener(subscriptionName, cbWrapper);
		return () => document.removeEventListener(subscriptionName, cbWrapper);
	}, [eventName, callback]);
};
