/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Button } from '../Button';
import styled from 'styled-components';

export const ToastPrimaryButton = styled(Button)`
	background: var(--primary-800);
	color: var(--white);

	&:hover {
		background: var(--primary-600);
		color: var(--white);
	}

	&:active,
	&.active,
	&:focus,
	&.loading {
		background: var(--primary-800);
		color: var(--white);
	}
`;
