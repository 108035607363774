/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext, useState } from 'react';

export const TemporaryApprovedContactsContext = createContext({
	getTemporaryApproved: id => false,
	setTemporaryApproved: id => new Map(),
});

export const TemporaryApprovedContactsProvider = ({ children }) => {
	const [state, setState] = useState(new Map());

	const setTemporaryApproved = (id, newValue) => {
		setState(prevState => {
			const newState = new Map(prevState);
			newState.set(id, newValue);
			return newState;
		});
	};

	const getTemporaryApproved = id => state.get(id);

	return (
		<TemporaryApprovedContactsContext.Provider
			value={{ getTemporaryApproved, setTemporaryApproved }}
		>
			{children}
		</TemporaryApprovedContactsContext.Provider>
	);
};

export const useTemporaryApprovedContacts = () => useContext(TemporaryApprovedContactsContext);
