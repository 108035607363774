/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H500, m100 } from 'src/components/Typography/fonts';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { Loader } from 'semantic-ui-react';
import { NoNotifications } from './NoNotifications';
import { NotificationType, useMarkAsReadMutation, useMarkAllAsReadMutation } from './model';
import { NotificationListItem } from './NotificationListItem';
import { Button } from 'src/components/Buttons/styled';
import { useInView } from 'react-intersection-observer';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { WebsocketChannelType, PrivateWebsocketEventType } from 'src/constants/websockets';
import { useInfiniteNotificationsQuery } from 'src/_api/infinite-queries';

const removeRepetitionsInNotifications = arr => {
	const map = new Map();
	arr.forEach(n => {
		if (map.has(n._key)) {
			// comparing two string in JSON format: the biggest is the newest.
			if (map.get(n._key).updated_at < n.updated_at) {
				map.set(n._key, n);
			}
		} else {
			map.set(n._key, n);
		}
	});
	return [...map.values()];
};

export const Notifications = ({ onClose }) => {
	const { t } = useTranslation();
	const { ref: loaderRef, inView: isBottom } = useInView();

	const containerRef = useRef();

	const { data, isFetching, fetchNextPage, hasNextPage, refetch } = useInfiniteNotificationsQuery(
		removeRepetitionsInNotifications
	);

	const newNotificationCallback = useCallback(() => {
		refetch();
	}, [refetch]);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.NewNotification,
		newNotificationCallback
	);

	useEffect(() => {
		if (isBottom && hasNextPage) {
			fetchNextPage();
		}
	}, [isBottom, hasNextPage, fetchNextPage]);

	const { mutate } = useMarkAsReadMutation();
	const { mutate: markAllAsRead, isLoading: markAllAsReadPending } = useMarkAllAsReadMutation();

	const onNavigation = useCallback(
		(ev, item) => {
			if (!item.read) {
				mutate([item._key]);
			}
			onClose();
		},
		[onClose, mutate]
	);

	return (
		<NotificationsContainer ref={containerRef}>
			<Header>
				<H500>{t('notifications')}</H500>
				<HeaderActions>
					<MarkAllAsReadButton
						disabled={markAllAsReadPending}
						onClick={markAllAsRead}
						data-test="mark-all-as-read"
					>
						{t('mark_all_as_read')}
					</MarkAllAsReadButton>
					<CloseButton onClick={onClose} data-test="close-notifications-menu">
						<CloseIcon />
					</CloseButton>
				</HeaderActions>
			</Header>
			{!isFetching && data?.length === 0 && <NoNotifications />}
			{data?.length > 0 && (
				<NotificationList>
					{data
						.filter(item => Object.values(NotificationType).includes(item.type))
						.map(item => (
							<NotificationListItem
								key={item._key}
								item={item}
								onNavigation={onNavigation}
							/>
						))}
				</NotificationList>
			)}
			{hasNextPage && (
				<StyledLoaderSpan ref={loaderRef}>
					<Loader active inline="centered" />
				</StyledLoaderSpan>
			)}
		</NotificationsContainer>
	);
};

const NotificationList = styled.ul`
	margin-top: 18px;
`;

const NotificationsContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: var(--neutral-800);
	overflow: auto;
	position: relative;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 19px 32px;
	background-color: var(--neutral-600);
	position: sticky;
	top: 0;
`;

const MarkAllAsReadButton = styled(Button)`
	height: 32px;
	${m100}
	text-decoration: underline;
`;

const CloseButton = styled(Button)`
	width: 32px;
	height: 32px;
	min-height: 0;
	padding: 0;
	border-radius: 50%;
	background: var(--neutral-800);
	color: var(--neutral-300);

	svg {
		width: 12px;
		height: 12px;
	}
`;

const HeaderActions = styled.div`
	display: grid;
	grid-gap: 24px;
	grid-auto-flow: column;
`;

const StyledLoaderSpan = styled.span`
	padding-bottom: 10px;
`;
