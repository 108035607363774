/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MessageIcon } from 'src/assets/icons/messages.svg';
import { ChatEvent } from 'src/shared/constants';
import { useEmit } from 'src/shared/useEmit';
import * as Styled from './styled';

export const MessageButton = ({ userId, onClick, ...props }) => {
	const { t } = useTranslation();

	const openChatConversation = useEmit(ChatEvent.Open, {
		userId,
	});

	const handleButtonClick = event => {
		event.stopPropagation();
		event.preventDefault();
		openChatConversation();
		onClick?.();
	};

	return (
		<Styled.MessageButton type="button" onClick={handleButtonClick} {...props}>
			<MessageIcon />
			<span>{t('message')}</span>
		</Styled.MessageButton>
	);
};
