/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ModalSubTitle } from 'src/components/Modal';

export const SubTitle = styled(ModalSubTitle)`
	margin-bottom: 40px;
	width: 368px;
	font-size: 14px;
	line-height: 21px;
`;
