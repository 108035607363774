/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { forwardRef, useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { searchPorts } from 'src/_api';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { getPort } from 'src/_api/products.api';
import { formatPortName } from 'src/_helpers';
import { useDebouncedValue } from 'src/shared/useDebouncedValue';

const search = async ({ queryKey: [key, { _key, q }] }) => {
	return _key ? [await getPort(_key)] : searchPorts({ q });
};

export const PortDropdown = forwardRef(
	({ debounceTime = 500, onValueUpdate, value, ...props }, ref) => {
		const { t } = useTranslation();
		const [searchText, setSearchText] = useState('');

		const q = useDebouncedValue(searchText, debounceTime);
		const isQueryAwaiting = !!searchText.length && searchText !== q;
		const queryParam = q.length ? { q } : value ? { _key: value } : null;

		const { data = [], error, isLoading } = useQuery(['ports', queryParam], search, {
			enabled: !!queryParam,
			cacheTime: 0,
			staleTime: 0,
		});

		const options = useMemo(() => {
			return data.map(port => ({ text: formatPortName(port), value: port._key }));
		}, [data]);

		useEffect(() => {
			if (onValueUpdate && data.length) {
				if (!value) {
					onValueUpdate('');
				} else {
					const port = data.find(item => item._key === value);
					if (port) {
						onValueUpdate(value, port.name, port.country_id);
					}
				}
			}
		}, [data, onValueUpdate, value]);

		const loading = isLoading || isQueryAwaiting;

		return (
			<MaterialSelectInput
				error={!!error}
				options={options}
				search={() => options}
				searchIcon
				loading={loading}
				noResultsMessage={loading || !searchText.length ? null : t('no_results_found')}
				onSearchChange={(ev, { searchQuery }) => {
					setSearchText(searchQuery);
				}}
				value={value}
				{...props}
			/>
		);
	}
);
