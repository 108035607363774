/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateRange, parseDateStringAsUTC } from 'src/_helpers/date';
import { futuresContractLabel } from 'src/_helpers/futuresContracts';
import { OriginsListMode, formatOriginsList } from 'src/_helpers/origins';
import { formatPrice } from 'src/_helpers/price';
import { H600 } from 'src/components/Typography';
import { VolumeDisplay } from 'src/components/Volume';
import { OrderType, PriceType, tCurrency } from 'src/constants/contract';
import { tPriceUnit } from 'src/constants/product';

export const useAcceptPaperOrderSummary = order => {
	const { t } = useTranslation();

	const counterpartyLabel = order?.order_type === OrderType.Sell ? t('seller') : t('buyer');

	const originValue =
		order?.origin_countries || (order?.origin_country ? [order.origin_country] : null);

	const isBasisPriceType = order?.price_type === PriceType.Basis;

	const dataToDisplay = [
		{
			title: counterpartyLabel,
			value: order.hidden ? t('hidden') : order.company?.name,
		},
		{
			title: t('trader'),
			value: order.hidden ? t('hidden') : order?.user?.name,
		},
		{
			title: t('product'),
			value: order?.product.name,
		},
		originValue && {
			title: t('origin'),
			value: formatOriginsList(originValue, 'name', OriginsListMode.tooltip, t),
		},
		{
			title: t('inco'),
			value: order.inco,
		},
		originValue && {
			title: t('origin'),
			value: formatOriginsList(originValue, 'name', OriginsListMode.tooltip, t),
		},
		{
			title: t('grade'),
			value: order?.grade.name,
		},
		{
			title: t('quantity'),
			value: <VolumeDisplay as={H600} value={order.volume} />,
		},
		isBasisPriceType && {
			title: t('futures_market'),
			value:
				futuresContractLabel(t, order.futures_contracts, order.futures_contract_id) || null,
		},
		{
			title: tPriceUnit(t, order.price_unit),
			value: formatPrice(order.price, {
				currency: tCurrency(t, order.currency),
			}),
		},
		{
			title: t('shipment'),
			value: formatDateRange({
				format: order.delivery_mode,
				startDate: parseDateStringAsUTC(order.delivery_date_from),
				endDate: parseDateStringAsUTC(order.delivery_date_to),
			}),
		},
		{
			title: t('inco'),
			value: `${order.inco.name}, ${order.primary_ports[0].name}${
				order.primary_ports.length > 1 ? ` +${order.primary_ports.length - 1}` : ''
			}`,
		},
	].filter(Boolean);

	return { dataToDisplay };
};
