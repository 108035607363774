/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Loader } from 'semantic-ui-react';

export const SuspenseFallback = () => {
	return (
		<div>
			<Loader active />
		</div>
	);
};
