/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSubscribe } from 'src/shared/useSubscribe';
import { useTogglable } from 'src/_helpers/useTogglable';
import { InviteViaEmailCard } from 'src/containers/UserNetwork/components/Invites/InviteViaEmailCard/InviteViaEmailCard';
import { ModalSize, ModalType } from 'src/constants/modal';
import { ChatEvent } from 'src/shared/constants';
import { InviteUsersModal } from './styled';

export const InviteViaEmailModal = () => {
	const [isOpen, { open, close }] = useTogglable();

	useSubscribe(ChatEvent.InviteUser, () => {
		open();
	});

	return (
		<InviteUsersModal
			type={ModalType.Info}
			size={ModalSize.Tiny}
			close={close}
			open={open}
			isOpen={isOpen}
			data-test="invite-users-modal"
		>
			<InviteViaEmailCard close={close} isModal />
		</InviteUsersModal>
	);
};
