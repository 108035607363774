/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { media } from 'src/_helpers';
import styled, { keyframes } from 'styled-components';

const skeletonAnimation = keyframes`
	0% { opacity: 1 }
	50% {opacity: 0.5 }
	100% { opacity: 1 }
`;

export const SkeletonButton = styled.div`
	width: 160px;
	height: 36px;
	background: var(--neutral-700);
	border-radius: 8px;
	opacity: 1;
	animation: ${skeletonAnimation} 1.5s infinite;

	${media.xl`
		width: 190px;
		height: 40px;
	`}
`;

export const SkeletonBox = styled.div`
	width: 173px;
	height: 48px;
	background: var(--neutral-800);
	border-radius: 16px;
	opacity: 1;
	animation: ${skeletonAnimation} 1.3s infinite;
`;

export const SkeletonPill = styled(SkeletonBox)`
	align-self: flex-end;
`;

export const BigSkeletonBox = styled(SkeletonBox)`
	height: 144px;
	width: 100%;
	margin-top: 24px;
`;

export const SkeletonLine = styled(SkeletonBox)`
	border-radius: 8px;
	height: 30px;
	width: 245px;
`;

export const ShortSkeletonLine = styled(SkeletonLine)`
	width: 93px;
	height: 30px;
`;

export const SkeletonGrid = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 7px;
	margin-top: 10px;
`;

export const SkeletonLineGrid = styled(SkeletonGrid)`
	flex-direction: column;
`;
