/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTogglable } from 'src/_helpers/useTogglable';
import { Sidebar } from 'semantic-ui-react';
import { Dimmer } from '../Dimmer/Dimmer';

export const SidebarContext = createContext({
	containerRef: null,
	state: [false, {}],
});

export function useSidebar() {
	return useContext(SidebarContext);
}
export function useSidebarToggler() {
	return useSidebar().state;
}

export function SidebarProvider({ containerRef, children }) {
	const state = useTogglable();
	const [isVisible] = state;

	const value = useMemo(
		() => ({
			containerRef,
			state,
		}),
		[containerRef, state]
	);

	return (
		<SidebarContext.Provider value={value}>
			<Dimmer style={{ zIndex: 20 }} active={isVisible} />
			{children}
		</SidebarContext.Provider>
	);
}

export const SidebarPortal = ({ children }) => {
	const { containerRef } = useSidebar();
	const [DOMReady, setDOMReady] = useState(false);

	useEffect(() => {
		setDOMReady(true);
	}, []);

	if (!DOMReady) {
		return <></>;
	}

	return createPortal(children, containerRef.current);
};

export function PageSidebar({ children, ...props }) {
	const [isVisible, { open, close }] = useTogglable(props.visible);

	return (
		<SidebarPortal>
			<Sidebar onVisible={open} onHidden={close} {...props}>
				{isVisible && children}
			</Sidebar>
		</SidebarPortal>
	);
}
