/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useUserStatusPresence } from './useUserStatusPresence';

export const UserStatusPresenceWrapper = () => {
	useUserStatusPresence();

	return null;
};
