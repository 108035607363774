/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { useDrawerContext, DrawerContextKeys } from './DrawerContext';
import { useVisibilityClassName } from './useVisibilityClassName';
import * as Styled from './styled';

const DrawerPortal = ({ children }) => {
	const { containerRef } = useDrawerContext();

	return containerRef.current ? createPortal(children, containerRef.current) : null;
};

export const Drawer = ({
	contextKey = DrawerContextKeys.viewDrawer,
	dataTest,
	isVisible,
	className,
	children,
	withSidebar,
}) => {
	const contextData = useDrawerContext();
	const [isMinimized] = contextData[contextKey].minimize;

	const visibilityClassName = useVisibilityClassName(contextKey, isVisible);

	return (
		<DrawerPortal>
			<Styled.Drawer
				className={clsx(visibilityClassName, className)}
				data-test={`${dataTest}-drawer`}
				$isMinimized={isMinimized}
				$withSidebar={withSidebar}
			>
				{children}
			</Styled.Drawer>
		</DrawerPortal>
	);
};

export const DrawerContent = ({ children }) => {
	return <Styled.DrawerContent className="drawer-content">{children}</Styled.DrawerContent>;
};

export const DrawerFooter = ({ children, progress, className }) => {
	return (
		<Styled.DrawerFooter className={clsx('drawer-footer', className)} $progress={progress}>
			{children}
		</Styled.DrawerFooter>
	);
};

export const OrderDrawerFooter = ({ children }) => {
	return <Styled.OrderDrawerFooter>{children}</Styled.OrderDrawerFooter>;
};
