/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import LanguageDetector from 'i18next-browser-languagedetector';
import { Languages, MomentLocales } from 'src/constants/settings';
import { isVosborCn } from './domain';

export const languageDetector = new LanguageDetector();

const domainLanguageDetector = {
	name: 'domainLanguageDetector',
	lookup({ lookupLocalStorage }) {
		const savedLanguage = localStorage.getItem(lookupLocalStorage);

		const supportedLanguages = Object.keys(MomentLocales);

		return supportedLanguages.includes(savedLanguage)
			? savedLanguage
			: isVosborCn()
			? Languages.Chinese.code
			: Languages.English.code;
	},
};

languageDetector.addDetector(domainLanguageDetector);
