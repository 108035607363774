/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useActivityLogs } from 'src/_api';
import { useActivityLogsUpdater } from 'src/components/ActivityLog/hooks/useActivityLogsUpdater';
import { useTradeLocation } from 'src/_helpers/useTradeLocation';
import { checkActivityType } from 'src/constants/activityLogTypes';
import { ActivityLog } from 'src/components/ActivityLog/ActivityLog';
import { checkActivityTypeIcons } from 'src/components/ActivityLog/helpers/checkActivityTypeIcons';
import { useTradeDataContext } from 'src/containers/ExecutionDetails/Physical/TradeDataContext';
import { IncoType } from 'src/constants/contract';
import { useExecutionAnalytics } from 'src/containers/ExecutionDetails/hooks/useExecutionAnalytics';
import * as Styled from './styled';

const useActivityLogItems = () => {
	const { t } = useTranslation();
	const { tradeId, api } = useTradeLocation();
	const { tradeData } = useTradeDataContext();
	const { data = [], isLoading, isFetched } = useActivityLogs({
		trade_id: tradeId,
		api,
	});

	useActivityLogsUpdater(tradeId, data, api);

	useExecutionAnalytics({ tradeId, activityLogs: data.map(({ _key }) => _key), on: isFetched });

	const isCIFCFRTrade = [IncoType.CIF, IncoType.CFR].includes(tradeData.inco_id);

	const activityLogItems = data.map(
		({ user_id, created_at, type, _key, user_name, seen_by, user_status, ...rest }) => {
			return {
				key: _key,
				userName: user_name,
				date: moment(created_at).format(),
				info: checkActivityType(t, type, { ...rest }, isCIFCFRTrade),
				icon: checkActivityTypeIcons(type),
				type,
				users: seen_by || [],
				userStatus: user_status,
			};
		}
	);

	return {
		activityLogItems,
		isLoading,
		isFetched,
	};
};

export const ActivityComponent = () => {
	const { activityLogItems, isFetched } = useActivityLogItems();

	return (
		<Styled.ActivityLogWrapper data-test="trade-details-activity-log-component">
			<ActivityLog
				activityLogItems={activityLogItems}
				isFetched={isFetched}
				enableDownloadButton
			/>
		</Styled.ActivityLogWrapper>
	);
};
