/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled from 'styled-components';

export const SpecDirection = styled.span`
	color: var(--neutral-300);
`;
