/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import { calculateSpreadPrice } from 'src/_helpers/spread';
import { InformativeField } from './InformativeField';
import { OrderType, defaultCurrencyUnit } from 'src/constants/contract';
import { formatFloatNumberToPriceFormat } from 'src/_helpers/price';

const SecondLegPayCashPriceField = ({ path, t }) => {
	const { priceValue, typeValue, spreadTypeValue, firstLegPriceValue } = useWatchPaperFields(
		path
	);
	const { currencyUnitValue } = useWatchPaperFields(path);

	const [currency, unit] = (currencyUnitValue || defaultCurrencyUnit).split('/');

	const currencyUnitLabel = `${currency}/${t(`price_unit_short_${unit}`)}`;

	const secondLegPriceValue = () => {
		if (!priceValue || !firstLegPriceValue) {
			return '---';
		}

		const isFirstLegBuyer = typeValue === OrderType.Buy;

		const spreadValuePrice = calculateSpreadPrice(isFirstLegBuyer, spreadTypeValue, priceValue);

		return (
			formatFloatNumberToPriceFormat(parseFloat(firstLegPriceValue) - spreadValuePrice) +
			' ' +
			currencyUnitLabel
		);
	};

	return (
		<InformativeField
			title={t('second_leg_price')}
			subtitle={secondLegPriceValue()}
			dataTest="second-leg-price"
		/>
	);
};

export const secondLegPayCashPriceField = (path, t) => (
	<SecondLegPayCashPriceField path={path} t={t} />
);
