/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { InfoMessage as Message } from './InfoMessage/InfoMessage';
import { SectionSeparator } from 'src/components/CreateOrderDrawer/components/SectionSeparator/SectionSeparator';
import { media } from 'src/_helpers';

export const Separator = styled(SectionSeparator)`
	margin-top: 0;
`;

export const InfoMessage = styled(Message)`
	margin-bottom: 16px;
`;

export const SectionWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 230px);
	grid-gap: 16px 8px;

	${media.ll`
		grid-template-columns: repeat(2, 256px);
	`}

	${media.xl`
		grid-gap: 20px 16px;
		grid-template-columns: repeat(2, 291px);
	`}

	.user-avatar-details {
		margin-left: 12px;
		gap: 0px;
		max-width: 186px;

		${media.ll`
			max-width: 210px;
		`}

		${media.xl`
			max-width: 245px;
		`}
	}

	.user-avatar-company-name,
	.user-avatar-username-container {
		max-width: 186px;

		${media.ll`
			max-width: 210px;
		`}

		${media.xl`
			max-width: 245px;
		`}
	}
`;

export const CounterpartyContainer = styled.div`
	display: grid;
	gap: 8px;
`;
