/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trackAnalytics } from 'src/_api/analytics.api';
import { API_URL } from 'src/_api';
import { Toast } from 'src/components/Toast/Toast';
import { ANALYTICS } from 'src/constants/analytics';
import { formatPrice, formatPriceWithoutCurrency } from 'src/_helpers/price';
import {
	formatDateRange,
	parseDateStringAsUTC,
	formatMonthsSpread,
	formatMultiMonthsSpread,
	Dateformat,
} from 'src/_helpers/date';
import {
	tCurrency,
	PaperInstruments,
	tLegQuote,
	positionToOrderType,
	SpreadPriceFormat,
	tCalendarSpreadPayment,
} from 'src/constants/contract';
import { TradePosition } from 'src/constants/tradebook';
import { calculatePayCashDirection } from 'src/_helpers/spread';

export const MyNewTradesItems = ({ items, onClick }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Wrapper data-test="my-new-trades">
			{items.map(item => {
				const isSpread = item.instrument === PaperInstruments.Spread;
				const isPayCashSpread =
					isSpread && item.spread_details.price_format === SpreadPriceFormat.PayCash;

				let price;

				const priceUnit = t(`price_unit_short_${item.price_unit}`, {
					lng: 'en',
				});

				const currencyAndUnit = `${item.currency}/${priceUnit}`;

				if (isSpread) {
					if (isPayCashSpread) {
						const spreadDirection = calculatePayCashDirection(
							item.position === TradePosition.Long,
							item.price
						);

						price = `${tCalendarSpreadPayment(
							t,
							spreadDirection
						)} ${formatPriceWithoutCurrency(Math.abs(item.price))} ${currencyAndUnit}`;
					} else {
						const formattedPrice = formatPrice(item.price, {
							currency: tCurrency(t, item.currency),
						});

						price = item.price > 0 ? `+${formattedPrice}` : formattedPrice;
					}
				} else {
					price = formatPrice(item.price, {
						currency: tCurrency(t, item.currency),
					});
				}

				const shipment = isSpread
					? isPayCashSpread
						? formatMonthsSpread(
								parseDateStringAsUTC(item.delivery_date_from),
								parseDateStringAsUTC(item.spread_details.delivery_date_from)
						  )
						: formatMultiMonthsSpread(
								{
									startDate: item.delivery_date_from,
									endDate: item.delivery_date_to,
									format: Dateformat.Months,
								},
								{
									startDate: item.spread_details.delivery_date_from,
									endDate: item.spread_details.delivery_date_to,
									format: Dateformat.Months,
								}
						  )
					: formatDateRange({
							startDate: parseDateStringAsUTC(item.delivery_date_from),
							endDate: parseDateStringAsUTC(item.delivery_date_to),
							format: item.delivery_mode,
					  });

				const counterType = positionToOrderType(item.position);
				const shipmentInfo = isSpread
					? `${tLegQuote(t, counterType)} ${shipment}`
					: shipment;

				return (
					<PermanentToast
						key={item.url}
						link={{
							text: t('details'),
							name: `trade-button-${item.url}`,
							to: item.url,
							onClick: () => {
								onClick(item.url);
								navigate(item.url);
							},
						}}
						onClose={async () => {
							await trackAnalytics({
								type: ANALYTICS.Ignore,
								url: `${API_URL}${item.url}`,
								counter_order_id: item.counter_order_id,
							});
							onClick(item.url);
						}}
					>
						<strong>{t('trade_confirmed')}:</strong>
						<span data-test="new-trade-company">{item.counterparty?.name}</span>
						<span data-test="new-trade-product">{item.product.name}</span>
						<span data-test="new-trade-price">{price}</span>
						<span data-test="new-trade-inco-port">
							{item.inco.name} {item.primary_ports[0].name}
						</span>
						<NumberFormat
							data-test="new-trade-quantity"
							thousandSeparator
							displayType="text"
							value={item.volume}
							suffix={` ${t('mt')}`}
						/>
						<span data-test="new-trade-shipping">{shipmentInfo}</span>
					</PermanentToast>
				);
			})}
		</Wrapper>
	);
};

export const Wrapper = styled.div`
	z-index: var(--z-index-toast-trade-confirmation);
`;

export const PermanentToast = ({ message, kind = 'success', ...rest }) => (
	<StyledToast kind={kind} message={message} {...rest} />
);

const StyledToast = styled(Toast)`
	margin: 0 auto;
	z-index: var(--z-index-toast-trade-confirmation);

	right: 0;
	left: 0;
	position: fixed;
	top: 56px;

	span {
		&::before {
			content: ' ';
		}
		&::after {
			content: ' |';
		}

		&:last-of-type::after {
			content: '';
		}
	}
`;
