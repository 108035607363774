/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { h400, T100 } from 'src/components/Typography/fonts';

export const CommentWrapper = styled.div`
	padding-left: 18px;
	margin-bottom: 20px;
	border-left: 2px solid;
	border-color: ${({ $isOwn }) =>
		$isOwn ? 'var(--owner-comment-color)' : 'var(--counterparty-comment-color)'};
`;

export const CommentHeader = styled.div`
	margin-bottom: 16px;
	color: ${({ $isOwn }) =>
		$isOwn ? 'var(--owner-comment-color)' : 'var(--counterparty-comment-color)'};

	> * {
		display: inline-block;
	}
`;

export const CommentAuthor = styled.span`
	${h400}
	margin-right: 8px;
`;

export const CommentSubtitle = styled.span`
	${h400}
	color: var(--neutral-300);
	white-space: nowrap;
`;

export const CommentMessage = styled(T100)`
	display: block;
	font-size: 14px;
	line-height: 21px;
	white-space: pre-line;
`;
