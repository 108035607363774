/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserHomepage } from 'src/_store/selectors';
import { useTradeDetails } from 'src/_api';
import { Market, IncoType } from 'src/constants/contract.js';
import { PaperExecution } from './Paper/PaperExecution';
import { PhysicalExecution } from './Physical/PhysicalExecution';

export const ExecutionDetails = () => {
	const { tradeId } = useParams();
	const userHomepage = useSelector(getUserHomepage);

	const { data = {}, isLoading, isFetched, isError } = useTradeDetails(
		{
			tradeId,
		},
		{
			enabled: !!tradeId,
		}
	);

	const dataKeysLength = Object.keys(data).length;

	if (!isFetched) {
		return null;
	}

	if (dataKeysLength === 0 || data.inco_id === IncoType.RENDU) {
		return <Navigate to={userHomepage} />;
	}

	if (dataKeysLength > 0) {
		if (data.market === Market.Paper) {
			return (
				<PaperExecution
					data={data}
					isLoadingTradeDetails={isLoading}
					isFetched={isFetched}
					isError={isError}
					tradeId={tradeId}
				/>
			);
		} else {
			return <PhysicalExecution data={data} />;
		}
	}
};
