/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { objectsEqual } from 'src/_helpers/object';

export const arrayOfNElements = length => Array.from(Array(length).keys());

export const mergeArrays = (arr1 = [], arr2 = [], key = '') => {
	const result = [...arr1];
	const usedKeys = arr1.map(item => item[key]);

	arr2.forEach(item => {
		if (!usedKeys.includes(item[key])) {
			result.push(item);
		}
	});

	return result;
};

export const groupArrayByElementKey = (arr = [], key) => {
	if (!arr.some(el => key in el)) {
		return arr;
	}

	return arr.reduce((acc, curr) => {
		const existingGroupIndex = acc.findIndex(el => el[0][key] === curr[key]);

		if (!acc[existingGroupIndex]) {
			return acc.concat([[curr]]);
		} else {
			acc[existingGroupIndex].push(curr);
		}

		return acc;
	}, []);
};

export const arraysOfObjectsEqual = (a1, a2) =>
	a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
