/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { forwardRef } from 'react';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar.svg';
import { DateSelector } from 'src/components/DateSelector/DateSelector';
import { TextInput } from '../TextInput/TextInput';

export const DateInput = forwardRef(
	(
		{
			placeholder,
			error,
			highlighted,
			required,
			name,
			onBlur,
			dataTest = 'date-selector-field',
			openerClassName,
			...dateSelectorProps
		},
		inputRef
	) => (
		<DateSelector
			{...dateSelectorProps}
			opener={({ label, setOpen, ...openerProps }) => (
				<TextInput
					ref={inputRef}
					placeholder={placeholder}
					{...openerProps}
					value={label}
					name={name}
					wrapperProps={{
						onClick: () => setOpen(true),
						role: 'button',
						'data-test': dataTest,
					}}
					readOnly
					iconComponent={<CalendarIcon />}
					error={error}
					highlighted={highlighted}
					required={required}
					onBlur={onBlur}
					className={openerClassName}
				/>
			)}
		/>
	)
);
