/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { Dimmer } from 'src/components/Dimmer/Dimmer';

export const GuidebookDimmer = styled(Dimmer)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

export const MobileGuidebookWrapper = styled.div`
	background-color: #e5e7ef;
	position: relative;
	height: 100%;
	overflow: auto;
	scroll-behavior: smooth;

	& .guidebook-container {
		margin: 48px 16px;
		padding: 0;
	}
`;

export const MobileGuidebookMenu = styled.div`
	width: 56px;
	height: 56px;
	border-radius: 50%;
	position: fixed;
	bottom: 34px;
	right: 28px;
	box-shadow: 17px 31px 34px 0px rgba(6, 15, 34, 0.1);
	z-index: 2;
`;
