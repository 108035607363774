/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { PeriodHeader } from 'src/components/DateSelector/popup/Header';
import { h400, m100 } from 'src/components/Typography';
import { ContinuousShipmentPeriod, tContinuousShipmentPeriod } from 'src/constants/chart';

export const ContinuousRange = ({ continuousPeriod, onContinuousPeriodChange }) => {
	const { t } = useTranslation();

	const supportedPeriods = useMemo(
		() =>
			Object.keys(ContinuousShipmentPeriod).map(c =>
				tContinuousShipmentPeriod(t, ContinuousShipmentPeriod[c])
			),
		[t]
	);

	const buildContinuousEndDate = useCallback(() => {
		if (!continuousPeriod) {
			return;
		}

		return moment().add(ContinuousShipmentPeriod[continuousPeriod], 'M').format('DD/MM/YY');
	}, [continuousPeriod]);

	const getClassNames = useCallback(
		period => (continuousPeriod === period ? `selected selected__0` : ''),
		[continuousPeriod]
	);

	const onContinuousPeriodChangeCallback = useCallback(
		continuousPeriod => {
			onContinuousPeriodChange({
				continuousPeriod,
			});
		},
		[onContinuousPeriodChange]
	);

	const onClear = useCallback(() => onContinuousPeriodChangeCallback(), [
		onContinuousPeriodChangeCallback,
	]);

	return (
		<>
			<PeriodHeader
				startDate={continuousPeriod && moment().format('DD/MM/YY')}
				endDate={buildContinuousEndDate(continuousPeriod)}
				onClear={onClear}
				continuousPeriod={tContinuousShipmentPeriod(
					t,
					ContinuousShipmentPeriod[continuousPeriod]
				)}
				isContinuous
				ResetIcon={StyledResetIcon}
			/>
			<Section>
				<PeriodContainer>
					<PeriodTitle>{t('continuous_contract_chart')}</PeriodTitle>
					{Object.keys(ContinuousShipmentPeriod).map((period, index) => (
						<PeriodButton
							type="button"
							key={index}
							onClick={() => onContinuousPeriodChangeCallback(period)}
							className={getClassNames(period)}
						>
							{supportedPeriods[index]}
						</PeriodButton>
					))}
				</PeriodContainer>
			</Section>
		</>
	);
};

const Section = styled.section`
	width: 100%;
	background-color: var(--neutral-500);
	padding: 16px;
	border-bottom-left-radius: var(--big-border-radius);
	border-bottom-right-radius: var(--big-border-radius);
	${m100}
`;

const PeriodContainer = styled.div`
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	row-gap: 12px;
`;

const PeriodTitle = styled.div`
	grid-column-start: 1;
	grid-column-end: 6;
	display: flex;
	justify-content: center;
	align-items: center;
	${h400};
	color: var(--text-color-default);
`;

const PeriodButton = styled.button`
	${m100};
	font-size: 13px;
	color: var(--neutral-300);
	text-transform: capitalize;
	white-space: nowrap;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 17px 11px;
	border-radius: 6px;

	&:hover {
		background-color: var(--neutral-800);
	}

	&.selected__0 {
		background: var(--purple-400);
		box-shadow: 0px 2px 30px rgba(66, 0, 255, 0.25);
		color: var(----text-color-default);
	}
`;

const StyledResetIcon = styled(CloseIcon)`
	width: 12px;
	height: 12px;
	color: var(--neutral-300);

	&:hover {
		color: var(--text-color-default);
	}
`;
