/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const useShouldShowShipmentSection = (order, changes = {}) => {
	const hasLoadingPortDetails =
		order.ports_loading.length === 1
			? !!order.ports_loading[0].rate ||
			  !!order.ports_loading[0].terminal ||
			  changes['ports_loading[0].rate'] ||
			  changes['ports_loading[0].terminal']
			: order.ports_loading.length > 1;

	const hasDischargingPortDetails =
		order.ports_discharging.length === 1
			? !!order.ports_discharging[0].rate ||
			  !!order.ports_discharging[0].terminal ||
			  changes['ports_discharging[0].rate'] ||
			  changes['ports_discharging[0].terminal']
			: order.ports_discharging.length > 1;

	const shouldShowShipmentSection =
		!!order.tolerance ||
		!!changes.tolerance ||
		!!order.inspection ||
		!!changes.inspection ||
		hasLoadingPortDetails ||
		hasDischargingPortDetails;

	return shouldShowShipmentSection;
};
