/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import clsx from 'clsx';
import { DrawerContextKeys, useDrawerContext } from '../../Drawer/DrawerContext';
import { ProductSection } from './sections/ProductSection/ProductSection';
import { SectionSeparator } from '../components/SectionSeparator/SectionSeparator';
import { useCreatePaperOrderForm } from './useCreatePaperOrderForm';
import { ContractAndPricingSection } from './sections/ContractAndPricingSection/ContractAndPricingSection';
import { PaperOrderPreviewContent } from '../components/OrderPreview/PaperOrderPreviewContent';
import * as Styled from '../styled';
import { CreatePaperOrderDrawerFooter } from '../CreatePaperOrderDrawerFooter';
import { PaperOrderPreviewFooter } from '../components/OrderPreview/PaperOrderPreviewFooter';

export const PaperOrderContext = createContext({
	selectedPreset: null,
	setSelectedPreset: () => {},
});

export const usePaperOrderContext = () => useContext(PaperOrderContext);

export const CreatePaperOrderDrawerContent = ({ formValues }) => {
	const [selectedPreset, setSelectedPreset] = useState(null);
	const [canChangeInstrument, setCanChangeInstrument] = useState(true);
	const { formMethods, isSubmitting, onSubmit, progress, mutate } = useCreatePaperOrderForm(
		formValues,
		selectedPreset
	);

	const {
		[DrawerContextKeys.createDrawer]: {
			orderPreviewData,
			visibility: [, { close }],
		},
	} = useDrawerContext();

	const isPreviewMode = !!orderPreviewData;

	const handleRowCountChange = rowCount => {
		setCanChangeInstrument(rowCount === 1);
	};

	return (
		<FormProvider {...formMethods}>
			<Styled.Form onSubmit={onSubmit}>
				<Styled.FormContent
					className={clsx({ preview: isPreviewMode }, 'create-paper-order-form')}
				>
					{isPreviewMode ? (
						<PaperOrderPreviewContent />
					) : (
						<PaperOrderContext.Provider value={{ selectedPreset, setSelectedPreset }}>
							<ProductSection canChangeInstrument={canChangeInstrument} />
							<SectionSeparator />
							<ContractAndPricingSection onRowCountChange={handleRowCountChange} />
						</PaperOrderContext.Provider>
					)}
				</Styled.FormContent>
				{isPreviewMode ? (
					<PaperOrderPreviewFooter
						onSubmit={mutate}
						isSubmitting={isSubmitting}
						mutate={mutate}
					/>
				) : (
					<CreatePaperOrderDrawerFooter
						selectedPreset={selectedPreset}
						onSubmit={onSubmit}
						progress={progress}
						previewAlwaysAvailable
						onCancel={close}
						isSubmitting={isSubmitting}
					/>
				)}
			</Styled.Form>
		</FormProvider>
	);
};
