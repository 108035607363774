/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Input } from 'semantic-ui-react';
import { TextArea } from 'src/designSystem/Form/TextArea/TextArea';
import styled from 'styled-components';
import { ReactComponent as RemoveItemIcon } from 'src/assets/icons/remove_item.svg';

export const StyledInput = styled(Input)`
	width: 100%;
	border-radius: 2px;

	& input {
		background: var(--neutral-500) !important;
	}
`;

export const StyledTextarea = styled(TextArea)`
	height: 88px;
	margin-top: 16px;
`;

export const ModalContent = styled.div`
	margin-top: 42px;
`;

export const UserData = styled.div`
	display: flex;
	flex-direction: column;
`;
export const StyledRemoveIcon = styled(RemoveItemIcon)`
	color: var(--neutral-300);
	width: 12px;
	height: 12px;
	cursor: pointer;
	margin-right: 5px;

	&:hover {
		color: #fff;
	}
`;

export const WhiteSpan = styled.span`
	color: #fff;
`;
