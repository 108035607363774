/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { combineReducers } from 'redux';
import { userReducers } from './user.reducers';
import { searchReducers } from './search.reducers';
import { chartReducers } from './chart.reducers';
import { globalReducers } from './global.reducers';

export default combineReducers({
	user: userReducers,
	search: searchReducers,
	chart: chartReducers,
	global: globalReducers,
});
