/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { T200 } from 'src/components/Typography';
import { ButtonsWrapper, ModalTitle, IconWrapper as BaseIconWrapper } from 'src/components/Modal';
import { TextInput } from 'src/designSystem/Form/TextInput/TextInput';

export const StyledButtonsWrapper = styled(ButtonsWrapper)`
	margin-top: 40px;
`;

export const DocumentTypesGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-gap: 10px;
	padding-left: 16px;

	label {
		white-space: nowrap;
	}
`;

export const DocumentTypeInput = styled(TextInput)`
	margin-top: 32px;
`;

export const SelectDocument = styled(T200)`
	border-bottom: 1px solid var(--neutral-500);
	display: block;
	text-align: center;
	padding-bottom: 24px;
	margin-bottom: 24px;
`;

export const StyledModalTitle = styled(ModalTitle)`
	margin-bottom: 8px;
`;

export const IconWrapper = styled(BaseIconWrapper)`
	background: transparent;
`;
