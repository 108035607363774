/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useEffect } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
	QuoteType,
	tQuoteValueType,
	DrawerPriceType,
	tDrawerPriceType,
	Market,
	PriceIncrementType,
	tPriceIncrement,
	defaultCurrencyUnit,
	Currency,
	PriceUnit,
	Payment,
} from 'src/constants/contract';
import { useWatchPhysicalCounterFields } from '../../helpers/useWatchPhysicalCounterFields';
import { getProducts } from 'src/_api';
import { duration } from 'src/constants/duration';
import { isBasisPrice } from 'src/constants/contract';
import { genFutureContractMonths } from 'src/containers/Order/PhysicalOrder/Groups/Pricing/FuturesContractDate';
import { toUTCUnix } from 'src/_helpers/date';
import { useOnce } from 'src/_helpers/useOnce';

export const usePricingCounterFields = ({ lastCounter }) => {
	const { t } = useTranslation();
	const {
		setValue,
		control: { _defaultValues },
	} = useFormContext();

	const {
		priceTypeValue,
		orderTypeValue,
		orderTypeChangedValue,
		productValue,
		priceIncrementTypeValue,
		paymentValue,
		priceValue,
		currencyUnitValue,
		priceIncrementValue,
		futuresMonthValues,
		priceIncrementBasisShipmentValue,
	} = useWatchPhysicalCounterFields();

	const isPriceBasis = isBasisPrice(priceTypeValue);

	const { data: products = [] } = useQuery(['products', Market.Physical], getProducts, {
		staleTime: duration.hour,
	});

	/** Order type */
	const orderTypeOptions = useMemo(
		() =>
			Object.values(QuoteType).map(value => ({
				text: tQuoteValueType(t, value),
				key: value,
				value,
			})),
		[t]
	);

	useOnce(() => {
		if (lastCounter.forceFirm) {
			setValue('orderType', QuoteType.Firm);
		} else if (orderTypeValue === QuoteType.Firm && !orderTypeChangedValue) {
			setValue('orderType', QuoteType.Indicative);
		}
	});

	/* Price type */
	const selectedProductFutureContracts = useMemo(() => {
		return products.find(product => product._key === productValue)?.futures_contracts || [];
	}, [products, productValue]);

	const priceTypeOptions = useMemo(() => {
		const options = [
			DrawerPriceType.Flat,
			...selectedProductFutureContracts.map(fc => fc.futures_contract_id),
		];

		return options.map(value => ({
			text: tDrawerPriceType(t, value),
			key: value,
			value,
		}));
	}, [selectedProductFutureContracts, t]);

	const onPriceTypeChange = value => {
		if (isBasisPrice(value)) {
			setValue('futuresMonth', null);
		}
	};

	useEffect(() => {
		if (priceTypeValue === DrawerPriceType.NoPrice) {
			setValue('priceType', null);
		}
	}, [orderTypeValue, priceTypeValue, setValue]);

	/* Futures Month */
	const selectedFuturesContractMonths = useMemo(() => {
		return (
			selectedProductFutureContracts.find(fc => fc.futures_contract_id === priceTypeValue)
				?.future_months || []
		);
	}, [priceTypeValue, selectedProductFutureContracts]);

	const futuresMonthOptions = useMemo(() => {
		return genFutureContractMonths(selectedFuturesContractMonths).map(value => ({
			key: value.valueOf(),
			text: moment(value).format('MMM YYYY'),
			value: toUTCUnix(value),
		}));
	}, [selectedFuturesContractMonths]);

	useEffect(() => {
		if (priceTypeValue === DrawerPriceType.Flat) {
			setValue('futuresMonth', null);
		}
	}, [priceTypeValue, setValue]);

	/* Currency & Unit */
	const currencyUnitOptions = useMemo(() => {
		if (priceTypeValue === DrawerPriceType.Flat) {
			return [
				{
					key: defaultCurrencyUnit,
					text: `${Currency.USD}/${t(`price_unit_short_${PriceUnit.MetricTon}`, {
						lng: 'en',
					})}`,
					value: defaultCurrencyUnit,
				},
				{
					key: `${Currency.EUR}/${PriceUnit.MetricTon}`,
					text: `${Currency.EUR}/${t(`price_unit_short_${PriceUnit.MetricTon}`, {
						lng: 'en',
					})}`,
					value: `${Currency.EUR}/${PriceUnit.MetricTon}`,
				},
			];
		}

		const selectedFuturesContract = selectedProductFutureContracts.find(
			fc => fc.futures_contract_id === priceTypeValue
		);

		if (selectedFuturesContract) {
			const { currency, unit } = selectedFuturesContract;

			return [
				{
					key: `${currency}/${unit}`,
					text: `${currency}/${t(`price_unit_short_${unit}`, { lng: 'en' })}`,
					value: `${currency}/${unit}`,
				},
			];
		}

		return [];
	}, [priceTypeValue, selectedProductFutureContracts, t]);

	useEffect(() => {
		if (priceTypeValue !== DrawerPriceType.Flat) {
			const selectedFuturesContract = selectedProductFutureContracts.find(
				fc => fc.futures_contract_id === priceTypeValue
			);

			if (selectedFuturesContract) {
				const { currency, unit } = selectedFuturesContract;
				setValue('currencyUnit', `${currency}/${unit}`);
				return;
			}
		}
	}, [currencyUnitValue, priceTypeValue, selectedProductFutureContracts, setValue, t]);

	useEffect(() => {
		if (priceTypeValue === DrawerPriceType.Flat) {
			setValue(
				'currencyUnit',
				`${Currency.USD}/${t(`price_unit_short_${PriceUnit.MetricTon}`)}`
			);
		}
	}, [priceTypeValue, setValue, t]);

	const currencyUnitChangeHandler = value => {
		setValue('currencyUnit', value);
	};

	/* Price increment type */
	const priceIncrementTypeOptions = useMemo(
		() =>
			Object.values(PriceIncrementType).map(value => ({
				text: tPriceIncrement(t, value),
				key: value,
				value,
			})),
		[t]
	);

	useEffect(() => {
		if (priceIncrementTypeValue === PriceIncrementType.None) {
			setValue('price_increment', undefined);
			setValue('priceIncrementBasisShipment', undefined);
		}
	}, [priceIncrementTypeValue, setValue]);

	/* Payment */
	const paymentOptions = useMemo(
		() =>
			Object.values(Payment).map(value => ({
				text: value,
				key: value,
				value,
			})),
		[]
	);

	/* Meta */
	const [currency, unit] = useMemo(() => {
		return currencyUnitValue
			? currencyUnitValue.split('/')
			: [Currency.USD, PriceUnit.MetricTon];
	}, [currencyUnitValue]);

	/* Price increment */
	const priceIncrementOptions = [
		{
			key: `${currency}/${unit}`,
			text: `${currency}/${t(`price_unit_short_${unit}`)}`,
			value: `${currency}/${unit}`,
		},
	];

	const isFlatOrNoPrice = [DrawerPriceType.NoPrice, DrawerPriceType.Flat].includes(
		priceTypeValue
	);
	const currencyUnitInitialValue = useMemo(() => {
		if (!isFlatOrNoPrice) {
			const selectedFuturesContract = selectedProductFutureContracts.find(
				fc => fc.futures_contract_id === priceTypeValue
			);

			if (selectedFuturesContract) {
				const { currency, unit } = selectedFuturesContract;
				return `${currency}/${unit}`;
			}
		}

		return;
	}, [isFlatOrNoPrice, priceTypeValue, selectedProductFutureContracts]);

	useEffect(() => {
		if (!currencyUnitOptions.map(cu => cu.value).includes(currencyUnitValue)) {
			const { currency, price_unit } = lastCounter;
			setValue('currencyUnit', currencyUnitInitialValue || `${currency}/${price_unit}`);
		}
	}, [currencyUnitInitialValue, currencyUnitOptions, currencyUnitValue, setValue, lastCounter]);

	return {
		orderType: {
			options: orderTypeOptions,
			hasChanged: _defaultValues.orderType !== orderTypeValue,
		},
		priceType: {
			options: priceTypeOptions,
			ready: !!productValue,
			hasChanged: _defaultValues.priceType !== priceTypeValue,
			onChange: onPriceTypeChange,
		},
		futuresMonth: {
			options: futuresMonthOptions,
			ready: true,
			hasChanged: futuresMonthValues && _defaultValues.futuresMonth !== futuresMonthValues,
		},
		price: {
			isBasis: isPriceBasis,
			error: priceValue === 0 && !!priceTypeValue && !isPriceBasis,
		},
		priceCurrencyUnit: {
			options: currencyUnitOptions,
			ready: false,
			onOptionChange: currencyUnitChangeHandler,
			disabled: true,
			hasChanged:
				// eslint-disable-next-line eqeqeq
				_defaultValues.price != priceValue ||
				_defaultValues.currencyUnit !== currencyUnitValue,
		},
		payment: {
			options: paymentOptions,
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.payment != paymentValue,
			hasValue: !!_defaultValues.payment || paymentValue,
		},
		priceIncrementType: {
			options: priceIncrementTypeOptions,
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.priceIncrementType != priceIncrementTypeValue,
			hasValue:
				_defaultValues.priceIncrementType !== PriceIncrementType.None ||
				(priceIncrementTypeValue && priceIncrementTypeValue !== PriceIncrementType.None),
			isSet: [PriceIncrementType.HalfMonthly, PriceIncrementType.Monthly].includes(
				priceIncrementTypeValue
			),
		},
		priceIncrement: {
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.priceIncrement != priceIncrementValue,
			hasValue: !!_defaultValues.priceIncrement || priceIncrementValue,
			options: priceIncrementOptions,
			optionValue: currencyUnitValue,
		},
		priceIncrementBasisShipment: {
			initialValue: new Date(),
			hasChanged:
				// eslint-disable-next-line eqeqeq
				_defaultValues.priceIncrementBasisShipment?.startDate !=
					priceIncrementBasisShipmentValue?.startDate ||
				// eslint-disable-next-line eqeqeq
				_defaultValues.priceIncrementBasisShipment?.endDate !=
					priceIncrementBasisShipmentValue?.endDate,
			hasValue:
				!!_defaultValues.priceIncrement || priceIncrementBasisShipmentValue?.startDate,
		},
		currency,
		canHideOptional: !paymentValue || priceIncrementTypeValue === PriceIncrementType.None,
	};
};
