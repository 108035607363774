/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const mapCounterComments = (userId, order, allCounters = []) => {
	const list = [order, ...allCounters];

	return list.map((_, index) => {
		let comments = [];
		for (let i = 0; i <= index; i++) {
			comments = [
				list[i].comment_order
					? {
							index: i,
							key: list[i]._key,
							comment: list[i].comment_order,
							author: list[i].user?.name || '',
							isOwn: list[i].user?._key === userId,
							time: list[i].created_at,
							changed: i !== 0,
					  }
					: {},
				...comments.map(comment => ({
					...comment,
					...(Object.keys(comment).length > 0 ? { changed: false } : {}),
				})),
			];
		}
		return comments;
	});
};
