/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { FoldableSection } from 'src/components/CreateOrderDrawer/components/FoldableSection/FoldableSection';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { MaterialTextArea } from 'src/designSystem/Form/MaterialInput/MaterialTextArea';
import { MaterialTextInput } from 'src/designSystem/Form/MaterialInput/MaterialTextInput';
import { ReactComponent as UploadIcon } from 'src/assets/icons/upload.svg';
import { useAdditionalInfoCounterFields } from './useAdditionalInfoCounterFields';
import { CommentsSection } from 'src/components/ViewOrderDrawer/Content/CommentsSection/CommentsSection';
import * as Styled from 'src/components/CreateOrderDrawer/physical/sections/AdditionalInfoSection/styled';

export const AdditionalInfoSection = ({ lastCounter }) => {
	const { t } = useTranslation();

	const {
		formState: { errors },
	} = useFormContext();

	const {
		performanceBond,
		contractTerms,
		contractNumber,
		customContract,
		canHideOptional,
		principal,
		comments,
	} = useAdditionalInfoCounterFields(lastCounter);

	return (
		<FoldableSection title={t('additional_information')} canToggle={canHideOptional}>
			<Controller
				name="performanceBond"
				mandatory={performanceBond.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('performance_bond')}
						options={performanceBond.options}
						controlledState
						hasChanged={performanceBond.hasChanged}
						{...field}
					/>
				)}
			/>
			<Controller
				name="contractTerms"
				mandatory={contractTerms.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('contract_terms')}
						options={contractTerms.options}
						disabled={!contractTerms.ready}
						controlledState
						hasChanged={contractTerms.hasChanged}
						{...field}
					/>
				)}
			/>
			{contractNumber.enabled && (
				<Controller
					name="contractNumber"
					mandatory={contractNumber.hasValue}
					render={({ field }) => (
						<MaterialTextInput
							error={!!errors[field.name]}
							data-test={field.name}
							id={field.name}
							label={t('contract_number')}
							maxLength={100}
							controlledState
							hasChanged={contractNumber.hasChanged}
							{...field}
						/>
					)}
				/>
			)}
			{customContract.enabled && !customContract.file && (
				<Styled.UploadButton mandatory>
					<input
						data-test="custom-contract-file-input"
						type="file"
						title=""
						accept={customContract.accept}
						onChange={customContract.onChange}
						onClick={ev => (ev.target.value = null)}
					/>
					<UploadIcon /> {t('upload_contract')}
				</Styled.UploadButton>
			)}
			<Styled.CustomContract fullWidth mandatory>
				{!!customContract.file && (
					<Styled.UploadedFile
						file={customContract.file}
						handleSuccess={customContract.onUploaded}
						handleDelete={customContract.onDelete}
						isUploading={customContract.isUploading}
						isSmall
						withTooltip
					/>
				)}
			</Styled.CustomContract>
			{principal.visible && (
				<Controller
					fullWidth
					mandatory={principal.hasValue}
					name="principalId"
					render={({ field }) => (
						<Styled.WideField>
							<MaterialSelectInput
								key={`principal-dropdown${principal.ready ? '' : '-loading'}`}
								error={!!errors[field.name]}
								data-test={field.name}
								label={t('principal')}
								options={principal.options}
								disabled={!principal.ready}
								controlledState
								hasChanged={principal.hasChanged}
								search
								searchIcon
								{...field}
							/>
						</Styled.WideField>
					)}
				/>
			)}
			<Controller
				mandatory
				fullWidth
				name="comment"
				render={({ field }) => (
					<Styled.WideField>
						<MaterialTextArea
							autoGrow
							rows={1}
							resize={false}
							label={t('comment')}
							data-test={field.name}
							{...field}
						/>
					</Styled.WideField>
				)}
			/>
			<CommentsSection
				mandatory
				fullWidth
				markChanges={false}
				counter={{ ...lastCounter, comments }}
				isCounterForm
			/>
		</FoldableSection>
	);
};
