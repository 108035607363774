/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useConsecutiveModals } from 'src/_helpers/useConsecutiveModals';
import { AddUsersToTradeModal } from './AddUsersToTradeModal';
import { UsersSummaryModal } from './UsersSummaryModal';
import { GroupUsersModal } from './GroupUsersModal';

const MODALS = {
	AddUsers: 0,
	Summary: 1,
	Group: 2,
};

export const ModalsWrapper = ({ tradeId, usersAlreadyInTrade, trigger }) => {
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedGroups, setSelectedGroups] = useState([]);

	const {
		openModal,
		closeModal,
		closeAllModals,
		isModalOpen,
		storeModalsData,
		getModalsData,
	} = useConsecutiveModals(3);

	const queryClient = useQueryClient();

	const handleOnSubmit = useCallback(async () => {
		await queryClient.invalidateQueries(['trade', tradeId]);
		await queryClient.invalidateQueries(['activity_logs', tradeId]);
		closeAllModals();
		setSelectedUsers([]);
		setSelectedGroups([]);
	}, [closeAllModals, queryClient, tradeId]);

	const openSummaryModal = useCallback(() => {
		storeModalsData(MODALS.Summary, { selectedUsers, selectedGroups });
		openModal(MODALS.Summary);
	}, [openModal, selectedGroups, selectedUsers, storeModalsData]);

	const openGroupModal = useCallback(
		data => {
			storeModalsData(MODALS.Group, data);
			openModal(MODALS.Group);
		},
		[openModal, storeModalsData]
	);

	const closeMainModal = useCallback(() => {
		closeModal(MODALS.AddUsers);
		setSelectedUsers([]);
		setSelectedGroups([]);
	}, [closeModal]);

	return (
		<>
			<AddUsersToTradeModal
				trigger={trigger}
				usersAlreadyInTrade={usersAlreadyInTrade}
				close={closeMainModal}
				isOpen={isModalOpen(MODALS.AddUsers)}
				open={() => openModal(MODALS.AddUsers)}
				openSummaryModal={openSummaryModal}
				openGroupModal={openGroupModal}
				selectedUsers={selectedUsers}
				selectedGroups={selectedGroups}
				setSelectedUsers={setSelectedUsers}
				setSelectedGroups={setSelectedGroups}
			/>
			<UsersSummaryModal
				close={() => closeModal(MODALS.Summary, MODALS.AddUsers)}
				isOpen={isModalOpen(MODALS.Summary)}
				open={() => openModal(MODALS.Summary)}
				getSummaryData={() => getModalsData(MODALS.Summary)}
				tradeId={tradeId}
				submit={handleOnSubmit}
			/>
			<GroupUsersModal
				close={() => closeModal(MODALS.Group, MODALS.AddUsers)}
				isOpen={isModalOpen(MODALS.Group)}
				open={() => openModal(MODALS.Group)}
				getGroupData={() => getModalsData(MODALS.Group)}
			/>
		</>
	);
};
