/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { WebsocketChannelType } from 'src/constants/websockets';
import { useWebsocketsContext, UserStatus } from './WebsocketsProvider';
import { useUserPresenceContext } from './UserPresenceProvider';
import { useEmit } from 'src/shared/useEmit';
import { ChatEvent } from 'src/shared/constants';

const initialStatus = UserStatus.Away;

export const usePresence = () => {
	const { ablyInstance } = useWebsocketsContext();
	const { updateUsersPresence } = useUserPresenceContext();
	const emit = useEmit();

	const presenceChannel = ablyInstance?.channels.get(WebsocketChannelType.Presence);

	useEffect(() => {
		const asyncEffect = async () => {
			if (presenceChannel == null) {
				return;
			}

			const updatePresence = async () => {
				if (presenceChannel == null) {
					return;
				}
				const snapshot = await presenceChannel.presence.get();
				const newPresenceList = createPresenceListSet(snapshot);
				updateUsersPresence(newPresenceList);
				emit(ChatEvent.UpdateUserPresenceList, { presenceList: newPresenceList });
			};

			presenceChannel.presence.subscribe('enter', updatePresence);
			presenceChannel.presence.subscribe('leave', updatePresence);
			presenceChannel.presence.subscribe('update', updatePresence);

			await presenceChannel.presence.enter(initialStatus);

			await updatePresence();
		};

		asyncEffect();

		return () => {
			if (presenceChannel == null) {
				return;
			}

			if (presenceChannel.state === 'attached') {
				presenceChannel.presence.leave();
			}
			presenceChannel.presence.unsubscribe('enter');
			presenceChannel.presence.unsubscribe('leave');
			presenceChannel.presence.unsubscribe('update');
		};
	}, [presenceChannel, updateUsersPresence, emit]);
};

const createPresenceListSet = list => {
	const result = new Set();
	if (list == null || list.length === 0) {
		return result;
	}

	const grouped = list.reduce((groups, { clientId, data, timestamp }) => {
		const lastOccurrence = groups[clientId];
		if (lastOccurrence) {
			if (lastOccurrence.timestamp < timestamp) {
				return {
					...groups,
					[clientId]: { clientId, data, timestamp },
				};
			}
			return groups;
		}

		return {
			...groups,
			[clientId]: { clientId, data, timestamp },
		};
	}, {});

	Object.values(grouped).forEach(({ clientId, data }) => {
		if (data === UserStatus.Active) {
			result.add(clientId);
		}
	});

	return result;
};
