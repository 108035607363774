/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { Button } from 'src/components/Buttons/Button';
import { media } from 'src/_helpers';

export const ButtonGroup = styled.div`
	padding: 2px;
	background: var(--neutral-800);
	border-radius: 8px;
	border: 2px solid transparent;
	height: 36px;

	display: inline-flex;
	flex-direction: row;
	vertical-align: baseline;
	margin: 0;

	${({ $isError }) =>
		$isError &&
		css`
			border-color: var(--input-error-border-color);
		`}

	${media.ll`
    height: 40px;
  `}
`;

const disabledValueStyle = css`
	background: var(--neutral-700);
	color: var(--neutral-400);
`;

export const ToggleButton = styled(Button)`
	border-radius: 5px;
	color: var(--neutral-300);
	height: 100%;

	&.active {
		background: var(--primary-400);
		color: var(--white);
	}

	&.disabled {
		color: var(--neutral-500);
		&:nth-child(1),
		&:nth-child(2) {
			${({ $disabledValue }) => $disabledValue && disabledValueStyle}
		}
	}

	${({ $width }) =>
		$width
			? css`
					padding-left: 0;
					padding-right: 0;
					text-align: center;
					width: ${$width}px;
			  `
			: null}
`;
