/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/Toast';
import { TradeStep } from 'src/constants/tradeStatus';

const timeout = 10_000;

export function useTradeNotifications({ tradeData, stepCompleted, tradeStep }) {
	const { addToast } = useToast();
	const { t } = useTranslation();

	const hasBuyerSkippedInstructions =
		!tradeData?.instructions?.types?.length && !tradeData?.instructions?.notes;

	useEffect(() => {
		if (!tradeData?.counterparty?.name) {
			return;
		}
		if (stepCompleted && tradeStep === TradeStep.Signatures) {
			return addToast({
				message: t('waiting_for_{{user}}_to_sign', {
					user: tradeData.counterparty.name,
				}),
				kind: 'warning',
				timeout,
			});
		}

		if (tradeStep === TradeStep.VesselNomination) {
			if (tradeData.isSellerSide) {
				return addToast({
					message: t('waiting_for_{{user}}_to_provide_vessel_nomination_document', {
						user: tradeData.counterparty.name,
					}),
					kind: 'warning',
					timeout,
				});
			} else {
				return addToast({
					message: t('please_upload_vessel_nomination_document'),
					kind: 'info',
					timeout,
				});
			}
		}

		if (tradeStep === TradeStep.Completion && stepCompleted) {
			return addToast({
				message: t('waiting_for_{{user}}_to_complete_the_stage', {
					user: tradeData.counterparty.name,
				}),
				kind: 'warning',
				timeout,
			});
		}

		if (tradeStep === TradeStep.Close && tradeData.isSellerSide) {
			return addToast({
				message: t('waiting_for_{{user}}_to_complete_the_stage', {
					user: tradeData.counterparty.name,
				}),
				kind: 'warning',
				timeout,
			});
		}
	}, [
		addToast,
		hasBuyerSkippedInstructions,
		stepCompleted,
		t,
		tradeData.counterparty.name,
		tradeData.isBuyerSide,
		tradeData.isSellerSide,
		tradeStep,
	]);
}
