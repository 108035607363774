/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { KeyDetailsPill } from '../styled';
import { h600, h900 } from 'src/components/Typography';

export const IncoPortPill = styled(KeyDetailsPill)`
	background: var(--neutral-850);
	color: var(--white);
	justify-content: center;
	width: 100%;
	overflow: hidden;

	& span {
		text-transform: capitalize;
	}
`;

export const Inco = styled.span`
	${h600}
`;

export const PortName = styled.span`
	${h900}
`;
