/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { useWatchPaperCounterFields } from 'src/components/ViewOrderDrawer/CounterForm/helpers/paper/useWatchPaperCounterFields';
import { useCalendarSpreadFields } from '../../useCalendarSpreadFields';
import { QuoteType } from 'src/constants/contract';

const RunsField = ({ path, t, lastCounter }) => {
	const { setValue } = useFormContext();
	const { runs } = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	const { orderTypeValue } = useWatchPaperCounterFields(path);
	const isFieldRequired = orderTypeValue === QuoteType.Firm;

	useEffect(() => {
		if (isFieldRequired) {
			setValue('runsRequired', true);
		} else {
			setValue('runsRequired', false);
		}
	}, [isFieldRequired, setValue]);

	return (
		<Controller
			mandatory={isFieldRequired}
			name={toPath(path, 'runs')}
			defaultValue={null}
			render={({ field, fieldState: { error } }) => (
				<MaterialNumberInput
					{...field}
					required={isFieldRequired}
					id={field.name}
					data-test={field.name}
					label={t('runs')}
					error={!!error}
					fixedDecimalScale
					decimalScale={0}
					allowNegative={false}
					isAllowed={({ formattedValue }) => formattedValue !== '0'}
					controlledState
					hasChanged={runs.hasChanged}
				/>
			)}
		/>
	);
};

export const counterRunsField = props => <RunsField {...props} />;
