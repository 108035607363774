/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { forwardRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useFormContext } from 'react-hook-form';
import { getProducts } from 'src/_api';
import { Market } from 'src/constants/contract';
import { duration } from 'src/constants/duration';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { useWatchPhysicalFields } from '../../physical/useWatchPhysicalFields';

export const ProductDropdown = forwardRef(({ ...props }, ref) => {
	const { t } = useTranslation();

	const { productValue } = useWatchPhysicalFields();
	const { setValue } = useFormContext();

	const { data = [], error, isLoading } = useQuery(['products', Market.Physical], getProducts, {
		staleTime: duration.hour,
	});

	useEffect(() => {
		if (data.length && productValue) {
			setValue(
				'productObject',
				data.find(product => product._key === productValue)
			);
		}
	}, [data, productValue, setValue]);

	const options = useMemo(() => {
		return data.map(product => ({
			text: product.name,
			value: `${product._key}|${product.type_id}`,
		}));
	}, [data]);

	return (
		<MaterialSelectInput
			error={!!error}
			options={options}
			search={(list, phrase) => {
				const lowerCasePhrase = phrase.toLowerCase();

				return list.filter(option => option.text.toLowerCase().includes(lowerCasePhrase));
			}}
			loading={isLoading}
			disabled={isLoading}
			noResultsMessage={isLoading ? null : t('no_results_found')}
			{...props}
		/>
	);
});
