/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Drawer } from '../Drawer/Drawer';
import { ViewOrderDrawerHeader } from './ViewOrderDrawerHeader';
import { ViewOrderDrawerContent } from './ViewOrderDrawerContent';
import { ViewOrderDrawerFooter } from './ViewOrderDrawerFooter';
import { ViewOrderDrawerSkeleton } from './ViewOrderDrawerSkeleton/ViewOrderDrawerSkeleton';
import { useViewOrderDrawer } from './helpers/useViewOrderDrawer';
import { ViewOrderDrawerProvider } from './ViewOrderDrawerProvider';
import { NegotiationsSidebar } from './NegotiationsSidebar/NegotiationsSidebar';
import { NegotiationView } from './Counter/NegotiationView';
import { CounterForm } from './CounterForm/CounterForm';
import { useShouldRenderDrawer } from '../CreateOrderDrawer/useShouldRenderDrawer';

export const ViewOrderDrawer = () => {
	const {
		isVisible,
		drawerKey,
		isLoading,
		isRefetching,
		isFetched,
		order,
		negotiations,
		enableSidebar,
		activeNegotiationID,
		setActiveNegotiationID,
		counterFormEnabled,
		enableCounterForm,
		setCounterPreviewData,
	} = useViewOrderDrawer();

	const [counterToBeCountered, setCounterToBeCountered] = useState(null);

	const negotiation = useMemo(
		() => negotiations.find(({ first_counter_id }) => first_counter_id === activeNegotiationID),
		[activeNegotiationID, negotiations]
	);

	const navigate = useNavigate();

	const setCounterForm = useCallback(
		(enableForm, counter, redirectionUrl) => {
			enableCounterForm(enableForm);
			setCounterToBeCountered(counter);
			setCounterPreviewData(undefined);

			if (redirectionUrl) {
				navigate(redirectionUrl);
			}
		},
		[enableCounterForm, setCounterPreviewData, navigate]
	);

	useEffect(() => {
		if (activeNegotiationID) {
			setCounterForm(false, null);
		}
	}, [activeNegotiationID, setCounterForm]);
	const shouldRenderDrawer = useShouldRenderDrawer();
	const hasNoAccessToOrder = isFetched && !order._key;

	if (!shouldRenderDrawer || hasNoAccessToOrder) {
		return null;
	}

	return (
		<Drawer
			isVisible={isVisible}
			dataTest="view-order"
			contextKey={drawerKey}
			withSidebar={enableSidebar}
		>
			{isLoading ? (
				<ViewOrderDrawerSkeleton />
			) : (
				isFetched && (
					<>
						{enableSidebar && (
							<NegotiationsSidebar
								order={order}
								negotiations={negotiations}
								activeNegotiationID={activeNegotiationID}
								setActiveNegotiationID={setActiveNegotiationID}
							/>
						)}
						<ViewOrderDrawerProvider order={order} negotiation={negotiation}>
							{counterFormEnabled ? (
								<CounterForm
									order={order}
									negotiation={negotiation}
									setCounterForm={setCounterForm}
									lastCounter={counterToBeCountered}
								/>
							) : negotiation ? (
								<NegotiationView
									order={order}
									negotiation={negotiation}
									setCounterForm={setCounterForm}
									activeNegotiationID={activeNegotiationID}
								/>
							) : (
								<>
									<ViewOrderDrawerHeader
										order={order}
										negotiations={negotiations}
									/>
									<ViewOrderDrawerContent order={order} />
									<ViewOrderDrawerFooter
										order={order}
										negotiations={negotiations}
										setCounterForm={setCounterForm}
										isLoading={isRefetching}
									/>
								</>
							)}
						</ViewOrderDrawerProvider>
					</>
				)
			)}
		</Drawer>
	);
};
