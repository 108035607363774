/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { useTogglable } from 'src/_helpers/useTogglable';
import { getUserId } from 'src/_store/selectors';
import { UserProfileModal } from './UserProfileModal';
import * as Styled from './styled';

const activeElements = ['A', 'BUTTON', 'SVG', 'PATH'];

export const UserProfileLink = ({
	userId,
	isInactive = false,
	children,
	onModalActionClick,
	hasHoverEffect = true,
	className,
}) => {
	const [isOpen, { open, close }] = useTogglable();
	const currentUserId = useSelector(getUserId);

	const isNotClickableItem = isInactive || currentUserId === userId;

	if (isNotClickableItem) {
		return children;
	}

	const handleOpen = e => {
		const target = e.target.tagName.toUpperCase();

		if (!activeElements.includes(target)) {
			open();
		}
	};

	const handleButtonClick = () => {
		close();
		onModalActionClick?.();
	};

	return (
		<>
			<Styled.UserLink
				onClick={handleOpen}
				$clickable={!isNotClickableItem}
				$hasHoverEffect={hasHoverEffect}
				className={className}
			>
				{children}
			</Styled.UserLink>
			{isOpen && (
				<UserProfileModal userId={userId} close={close} onButtonClick={handleButtonClick} />
			)}
		</>
	);
};
