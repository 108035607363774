/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import * as Styled from './styled';

export const TextInput = forwardRef(
	(
		{
			className,
			error,
			highlighted,
			parameters,
			iconComponent,
			frontIconComponent,
			wrapperProps,
			disableIconLabel = false,
			disabled,
			...inputProps
		},
		inputRef
	) => {
		const labelForId = disableIconLabel ? undefined : inputProps.id;

		return (
			<Styled.TextInputWrapper
				{...wrapperProps}
				className={clsx(className, {
					disabled,
					error,
					highlighted,
				})}
			>
				{frontIconComponent ? (
					<Styled.FrontIconWrapper htmlFor={labelForId} aria-hidden>
						{frontIconComponent}
					</Styled.FrontIconWrapper>
				) : null}
				<Styled.TextInput
					ref={inputRef}
					{...inputProps}
					className={clsx({ disabled, empty: inputProps.value?.length === 0 })}
					disabled={disabled}
				/>
				{iconComponent ? (
					<Styled.IconWrapper htmlFor={labelForId} aria-hidden className="last-icon">
						{iconComponent}
					</Styled.IconWrapper>
				) : null}
				{parameters ? (
					<Styled.Parameters htmlFor={labelForId} aria-hidden>
						{parameters}
					</Styled.Parameters>
				) : null}
			</Styled.TextInputWrapper>
		);
	}
);
