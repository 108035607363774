/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { createContext, useContext, useState } from 'react';
import { useMyUnseenNegotiations } from 'src/_api';
import {
	WebsocketChannelType,
	PrivateWebsocketEventType,
	PublicWebsocketEventType,
} from 'src/constants/websockets';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { useQueryClient } from 'react-query';
import { isFooterTabActive, FooterTabs } from 'src/_helpers/session';

export const UnseenActivitiesContext = createContext({
	unseenNegotiationsCount: 0,
	unseenTradesCount: 0,
	decreaseNegotiationsCount: () => {},
	decreaseTradesCount: () => {},
});

const set0AtMax = count => Math.max(0, count - 1);

export const UnseenActivitiesProvider = ({ children }) => {
	const [unseenNegotiationsCount, setUnseenNegotiationsCount] = useState(0);
	const [unseenTradesCount, setUnseenTradesCount] = useState(0);
	const currentUserId = useSelector(getUserId);
	const queryClient = useQueryClient();

	useMyUnseenNegotiations({
		onSuccess: data => {
			if (data) {
				setUnseenNegotiationsCount(data.active_negotiations);
				setUnseenTradesCount(data.confirmed_negotiations);
			}
		},
		refetchInterval: 60000,
	});

	useWebsocket(WebsocketChannelType.Private, PrivateWebsocketEventType.NewCounter, message => {
		if (message.data.data.user_id !== currentUserId) {
			setUnseenNegotiationsCount(count => count + 1);
		}
	});

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.NewTrade,
		async message => {
			if (message.data.data.accepter_id !== currentUserId) {
				setUnseenTradesCount(count => count + 1);
			}

			if (isFooterTabActive(FooterTabs.MyTrades)) {
				await queryClient.invalidateQueries(['my-trades']);
			}

			if (isFooterTabActive(FooterTabs.MyOrders)) {
				await queryClient.invalidateQueries(['my-orders']);
			}

			if (isFooterTabActive(FooterTabs.MyNegotiations)) {
				await queryClient.invalidateQueries(['my-negotiations']);
			}

			if (isFooterTabActive(FooterTabs.MyNegotiationHistory)) {
				await queryClient.invalidateQueries(['my-negotiation-history']);
			}

			if (isFooterTabActive(FooterTabs.MyOrderHistory)) {
				await queryClient.invalidateQueries(['my-orders-history']);
			}
		}
	);

	const withdrawnOrderCallback = async () => {
		await queryClient.invalidateQueries(['my-unseen-negotiations']);
	};

	useWebsocket(
		WebsocketChannelType.Private,
		PublicWebsocketEventType.WithdrawnOrder,
		withdrawnOrderCallback
	);

	useWebsocket(
		WebsocketChannelType.ExchangeUpdates,
		PublicWebsocketEventType.WithdrawnOrder,
		withdrawnOrderCallback
	);

	return (
		<UnseenActivitiesContext.Provider
			value={{
				unseenNegotiationsCount,
				unseenTradesCount,
				decreaseNegotiationsCount: () => setUnseenNegotiationsCount(set0AtMax),
				decreaseTradesCount: () => setUnseenTradesCount(set0AtMax),
			}}
		>
			{children}
		</UnseenActivitiesContext.Provider>
	);
};

export const useUnseenActivitiesContext = () => {
	return useContext(UnseenActivitiesContext);
};
