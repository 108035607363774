/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useToast, Toast } from 'src/components/Toast';

export const PermanentToastContainer = styled.div`
	z-index: var(--z-index-toast-permanent);
`;

export const PermanentToast = ({ message, kind = 'warning', ...rest }) => {
	const { increasePermanentToastsCount, decreasePermanentToastsCount } = useToast();

	useEffect(() => {
		increasePermanentToastsCount?.();

		return () => {
			decreasePermanentToastsCount?.();
		};
	}, [decreasePermanentToastsCount, increasePermanentToastsCount]);

	return <StyledToast kind={kind} message={message} {...rest} />;
};

const StyledToast = styled(Toast)`
	margin: 0 auto;
	z-index: var(--z-index-toast-permanent);

	right: 0;
	left: 0;
	position: fixed;
	top: 56px;
`;
