/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const TradeStep = {
	Signatures: 'signatures',
	ShippingAdvice: 'shipping_advice',
	VesselNomination: 'vessel_nomination',
	Completion: 'completion',
	Close: 'close',
	Closed: 'closed',
	Intention: 'paper_intentions',
	Declaration: 'paper_declarations',
};

// TODO - remove these 3 old steps
// https://vosbor.atlassian.net/browse/TD-517
export const SimplifiedTradeStep = {
	Intention: 'paper_intentions',
	PreShipmentStart: 'paper_pre_shipment_start',
	PostShipmentStart: 'paper_post_shipment_start',
};

export const PaperTradeStep = {
	// new list - see chart for details
	// blob:https://vosbor.atlassian.net/e54bc514-a0ff-4665-98d7-376021cda3ef#media-blob-url=true&id=7461bea2-a855-4c04-8f41-276b4054e10b&contextId=21099&collection=
	PaperNoString: 'paper_no_string',
	PaperInitialString: 'paper_initial_string',
	PaperFormingFinalString: 'paper_forming_final_string',
	PaperFinalString: 'paper_final_string',
	PaperShipment: 'paper_shipment',
	Closed: 'closed',
};

export const TradeStringStatus = {
	FirstSeller: 'first_seller',
	FinalBuyer: 'final_buyer',
};

export const TradeActions = {
	Sign: 'SIGN',
	ConfirmShippingAdvice: 'CONFIRM_SHIPPING_ADVICE',
	ConfirmVesselNomination: 'CONFIRM_VESSEL_NOMINATION',
	ConfirmShippingTender: 'CONFIRM_SHIPPING_TENDER',
	ConfirmShippingDocuments: 'CONFIRM_SHIPPING_DOCUMENTS',
	Complete: 'COMPLETE',
	Close: 'CLOSE',
	ProvideIntention: 'PROVIDE_INTENTION',
	ProvideDeclaration: 'PROVIDE_DECLARATION',
};

export function tTradeStep(t, status) {
	switch (status) {
		case TradeStep.Signatures:
			return t('signatures');
		case TradeStep.ShippingAdvice:
			return t('shipping_advice');
		case TradeStep.Completion:
			return t('settlement');
		case TradeStep.VesselNomination:
			return t('vessel_nomination');
		case TradeStep.Close:
		case TradeStep.Closed:
			return t('closed_trades_step');
		case SimplifiedTradeStep.Intention:
			return t('intention');
		case SimplifiedTradeStep.PreShipmentStart:
		case SimplifiedTradeStep.PostShipmentStart:
			return t('settlement');
		default:
			return '';
	}
}

export const TradeDecision = {
	Settlement: 'settlement',
	Physical: 'physical',
};

export function tTradeDecision(t, decision) {
	switch (decision) {
		case TradeDecision.Physical:
			return t('physical');
		case TradeDecision.Settlement:
			return t('settlement_activity');
		default:
			return '';
	}
}

export const TradeTabs = {
	Info: 0,
	Documents: 1,
	Shipping: 2,
	CircleOut: 4,
};
