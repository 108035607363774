/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useDrawerContext, DrawerContextKeys } from '../Drawer/DrawerContext';
import { Drawer } from '../Drawer/Drawer';
import { DrawerHeader } from '../Drawer/DrawerHeader/DrawerHeader';
import { HeaderType } from '../Drawer/constants';
import { useShouldRenderDrawer } from './useShouldRenderDrawer';
import { CreatePhysicalOrderDrawerContent } from './physical/CreatePhysicalOrderDrawerContent';
import { CreatePaperOrderDrawerContent } from './paper/CreatePaperOrderDrawerContent';
import { Market, PaperInstruments } from 'src/constants/contract';
import { ContextualGuidePaperOrder } from 'src/components/ContextualGuide';
import { CopyOrder } from './CopyOrder';
import { OrderType } from 'src/constants/contract';
import { useMarket } from 'src/_routes/useActiveMarket';
import { useCopyDetails } from './useCopyDetails';
import { ViewOrderDrawerSkeleton } from '../ViewOrderDrawer/ViewOrderDrawerSkeleton/ViewOrderDrawerSkeleton';

export const CreateOrderDrawer = () => {
	const { t } = useTranslation();
	const [contentKey, setContentKey] = useState();
	const [market] = useMarket();

	const shouldRenderDrawer = useShouldRenderDrawer();

	const drawerKey = DrawerContextKeys.createDrawer;
	const {
		[drawerKey]: {
			visibility: [isVisible],
			minimize: [isMinimized],
			copyParams,
			orderPreviewData,
			orderPreviewIndex,
		},
	} = useDrawerContext();

	/**
	 * whenever the drawer visibility changes (which means the drawer is open), the new contentKey is regenerated
	 * causing the whole CreateOrderDrawerContent component to be recreated from scratch (with blank form)
	 */
	useEffect(() => {
		if (isVisible) {
			const editId = copyParams?.isEditing ? 'edit' : 'create';
			const id =
				copyParams?.orderId ||
				copyParams?.tradeId ||
				copyParams?.negotiationId ||
				editId ||
				null;
			const randomId = uuid();
			setContentKey(`${id}-${copyParams?.market}-${randomId}`);
		} else {
			setContentKey(null);
		}
	}, [
		copyParams?.market,
		copyParams?.isEditing,
		copyParams?.orderId,
		copyParams?.tradeId,
		copyParams?.negotiationId,
		isVisible,
	]);

	const getDrawerTitle = () => {
		if (!orderPreviewData || isMinimized) {
			if (copyParams?.isEditing) {
				return t('edit_order');
			} else {
				return t('create_order');
			}
		} else if (Array.isArray(orderPreviewData)) {
			const singleOrderPreview = orderPreviewData[orderPreviewIndex];

			if (singleOrderPreview.instrument === PaperInstruments.Spread) {
				return t('calendar_spread_preview');
			}

			return singleOrderPreview.order_type === OrderType.Buy
				? t('preview_bid')
				: t('preview_offer');
		} else {
			if (orderPreviewData.instrument === PaperInstruments.Spread) {
				return t('calendar_spread_preview');
			}

			return orderPreviewData.order_type === OrderType.Buy
				? t('preview_bid')
				: t('preview_offer');
		}
	};

	const canShowCopyLast = !orderPreviewData && !isMinimized;

	let forcedOrderParams = {};

	if (copyParams) {
		const { environment, orderId, negotiationId, tradeId, ...restCopyParams } = copyParams;

		forcedOrderParams = { ...restCopyParams };
	}

	const { data: copyData = null, isLoading: isLoadingCopyData } = useCopyDetails({
		environment: copyParams?.environment,
		orderId: copyParams?.orderId,
		tradeId: copyParams?.tradeId,
		negotiationId: copyParams?.negotiationId,
		forcedOrderParams,
	});

	const orderMarket = copyParams?.market || market;

	return (
		<Drawer
			isVisible={isVisible && shouldRenderDrawer}
			dataTest="create-order"
			contextKey={drawerKey}
			className="create-order"
		>
			{isLoadingCopyData ? (
				<ViewOrderDrawerSkeleton />
			) : (
				<>
					<DrawerHeader
						hasMinimize
						message={getDrawerTitle()}
						contextKey={drawerKey}
						type={HeaderType.MyOrder}
						isVisible={isVisible && shouldRenderDrawer}
						canShowCopyLast={canShowCopyLast}
						orderMarket={orderMarket}
						GuideComponent={
							orderMarket === Market.Paper ? ContextualGuidePaperOrder : undefined
						}
					/>
					{contentKey && (
						<>
							{copyData ? (
								<CopyOrder
									{...copyParams}
									contentKey={contentKey}
									copyData={copyData}
								/>
							) : market === Market.Physical ? (
								<CreatePhysicalOrderDrawerContent key={contentKey} />
							) : (
								<CreatePaperOrderDrawerContent key={contentKey} />
							)}
						</>
					)}
				</>
			)}
		</Drawer>
	);
};
