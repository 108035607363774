/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { FoldableSection } from 'src/components/CreateOrderDrawer/components/FoldableSection/FoldableSection';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { usePricingCounterFields } from './usePricingCounterFields';
import { useWatchPhysicalCounterFields } from '../../helpers/useWatchPhysicalCounterFields';
import { MaterialNumberInputWithOptions } from 'src/designSystem/Form/MaterialInput/MaterialNumberInputWithOptions';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';

export const PricingSection = ({ lastCounter }) => {
	const { t } = useTranslation();

	const {
		formState: { errors },
		trigger,
		setValue,
	} = useFormContext();

	const {
		orderType,
		priceType,
		futuresMonth,
		price,
		priceCurrencyUnit,
		payment,
		priceIncrementType,
		priceIncrement,
		priceIncrementBasisShipment,
		canHideOptional,
	} = usePricingCounterFields({ lastCounter });

	const { priceTypeValue, currencyUnitValue } = useWatchPhysicalCounterFields();

	return (
		<FoldableSection title={t('pricing')} canToggle={canHideOptional}>
			<Controller
				name="orderType"
				mandatory
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('counter_type')}
						options={orderType.options}
						controlledState
						hasChanged={orderType.hasChanged}
						required
						{...field}
						onChange={val => {
							field.onChange(val);
							setValue('orderTypeChanged', true);
						}}
					/>
				)}
			/>
			<Controller
				name="priceType"
				mandatory
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('price_type')}
						options={priceType.options}
						controlledState
						hasChanged={priceType.hasChanged}
						disabled={!priceType.ready}
						required
						{...field}
						onChange={val => {
							priceType.onChange(val);
							field.onChange(val);
							trigger('price');
						}}
					/>
				)}
			/>
			{price.isBasis && (
				<Controller
					name="futuresMonth"
					mandatory
					render={({ field }) => (
						<MaterialSelectInput
							required
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('futures_month')}
							controlledState
							hasChanged={futuresMonth.hasChanged}
							options={futuresMonth.options}
							disabled={!futuresMonth.ready}
							{...field}
						/>
					)}
				/>
			)}
			<Controller
				name="price"
				mandatory
				render={({ field }) => (
					<MaterialNumberInputWithOptions
						error={price.error || !!errors[field.name]}
						data-test="priceCurrencyUnit"
						label={t('price')}
						options={priceCurrencyUnit.options}
						onOptionChange={priceCurrencyUnit.onOptionChange}
						optionValue={currencyUnitValue}
						disableDropdown={priceCurrencyUnit.disabled}
						required={priceTypeValue}
						hasChanged={priceCurrencyUnit.hasChanged}
						controlledState
						allowNegative={price.isBasis}
						forceNumber={false}
						decimalScale={price.isBasis ? 3 : 2}
						{...field}
					/>
				)}
			/>
			<Controller
				name="payment"
				mandatory={payment.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('payment')}
						controlledState
						hasChanged={payment.hasChanged}
						options={payment.options}
						{...field}
					/>
				)}
			/>
			<Controller
				name="priceIncrementType"
				mandatory={priceIncrementType.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('price_increment')}
						controlledState
						hasChanged={priceIncrementType.hasChanged}
						options={priceIncrementType.options}
						{...field}
					/>
				)}
			/>
			{priceIncrementType.isSet && (
				<Controller
					name="priceIncrementBasisShipment"
					mandatory={priceIncrementType.hasValue || priceIncrementBasisShipment.hasValue}
					render={({ field }) => (
						<MaterialDateInput
							{...field}
							required
							rightAligned={!price.isBasis}
							allowPastDates
							controlledState
							hasChanged={priceIncrementBasisShipment.hasChanged}
							data-test={field.name}
							label={t('basis_shipment')}
							error={!!errors[field.name]}
							initialVisibleMonth={priceIncrementBasisShipment.initialValue}
						/>
					)}
				/>
			)}
			{priceIncrementType.isSet && (
				<Controller
					name="priceIncrement"
					mandatory={priceIncrementType.hasValue || priceIncrement.hasValue}
					render={({ field }) => (
						<MaterialNumberInputWithOptions
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('increment')}
							options={priceIncrement.options}
							optionValue={priceIncrement.optionValue}
							disableDropdown
							required
							hasChanged={priceIncrement.hasChanged}
							controlledState
							allowNegative={false}
							forceNumber={false}
							{...field}
						/>
					)}
				/>
			)}
		</FoldableSection>
	);
};
