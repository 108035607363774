/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { SectionGridContent, SectionGridLabel, SectionGridText, SectionGrid } from '../../styled';
import { IncoType } from 'src/constants/contract';
import { formatPortName } from 'src/_helpers/port';
import { VolumeDisplay } from 'src/components/Volume';
import { OverflowText } from 'src/components/Tooltip/OverflowText';

export const PortsData = ({ inco, ports, portType, changes = {} }) => {
	const { t } = useTranslation();

	const shouldShowRate = inco._key !== IncoType.RENDU;

	return (
		<>
			{ports.map((port, index) => (
				<SectionGrid key={`shipment-section-${port._key}-${portType}-port`}>
					<SectionGridContent
						className={clsx({ changed: changes[`primary_ports[${index}].name`] })}
					>
						<SectionGridLabel>
							{t(`${portType}_port`)} {port.order || index + 1}
						</SectionGridLabel>
						<SectionGridText
							data-test={`shipment-section-${portType}-port-${index + 1}`}
						>
							<OverflowText>{formatPortName(port)}</OverflowText>
						</SectionGridText>
					</SectionGridContent>
					{(port.volume || changes[`primary_ports[${index}].volume`]) && (
						<SectionGridContent
							className={clsx({ changed: changes[`primary_ports[${index}].volume`] })}
						>
							<SectionGridLabel>{t(`${portType}_quantity`)}</SectionGridLabel>
							<SectionGridText
								data-test={`shipment-section-${portType}-quantity-${index + 1}`}
							>
								{port.volume ? <VolumeDisplay value={port.volume} /> : '---'}
							</SectionGridText>
						</SectionGridContent>
					)}
					{(port.terminal || changes[`primary_ports[${index}].terminal`]) && (
						<SectionGridContent
							className={clsx({
								changed: changes[`primary_ports[${index}].terminal`],
							})}
						>
							<SectionGridLabel>{t('terminal')}</SectionGridLabel>
							<SectionGridText
								data-test={`shipment-section-${portType}-port-terminal-${
									index + 1
								}`}
							>
								<OverflowText>{port.terminal || '---'}</OverflowText>
							</SectionGridText>
						</SectionGridContent>
					)}
					{shouldShowRate && (port.rate || changes[`primary_ports[${index}].rate`]) && (
						<SectionGridContent
							className={clsx({ changed: changes[`primary_ports[${index}].rate`] })}
						>
							<SectionGridLabel>{t(`${portType}_rate`)}</SectionGridLabel>
							<SectionGridText
								data-test={`shipment-section-${portType}-port-rate-${index + 1}`}
							>
								{port.rate ? (
									<OverflowText>
										<NumberFormat
											thousandSeparator
											displayType="text"
											value={port.rate}
											suffix={` ${t('mt/day')}`}
										/>
									</OverflowText>
								) : (
									'---'
								)}
							</SectionGridText>
						</SectionGridContent>
					)}
				</SectionGrid>
			))}
		</>
	);
};
