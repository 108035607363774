/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import * as Styled from './styled';

export const TextArea = forwardRef(({ error, ...props }, ref) => (
	<Styled.Wrapper className={clsx({ error, disabled: props.disabled })}>
		<Styled.TextArea
			ref={ref}
			{...props}
			className={clsx(props.className, { 'no-resize': props.resize === false })}
		/>
	</Styled.Wrapper>
));
