/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const setPanesHeight = (tvWidget, percentages) => {
	const panes = tvWidget.activeChart().getPanes();
	const heights = [panes[0].getHeight(), panes[1].getHeight()];
	const sumHeights = heights[0] + heights[1];
	panes[0].setHeight(percentages[0] * sumHeights);
	panes[1].setHeight(percentages[1] * sumHeights);
};
