/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FooterDots } from './FooterDots';
import { useShowSlide } from './useShowSlide';
import * as Styled from './styled';

export const TourSlides = ({ slides }) => {
	const [slideIndex, setSlideIndex] = useState(0);

	const { t } = useTranslation(['tour']);

	const { isVisible, spotlightRef, containerRef, hideTour } = useShowSlide(slides, slideIndex);

	const isLastSlide = slideIndex === slides.length - 1;

	if (!isVisible || slides.length === 0) {
		return null;
	}

	const slideId = slides[slideIndex].id;
	const slideAttachment = slides[slideIndex].attachment;
	const title = t(`${slideId}.title`);
	const text = t(`${slideId}.text`, { joinArrays: '\n\n' });

	return (
		<>
			<Styled.Overlay>
				<Styled.Spotlight ref={spotlightRef} />
			</Styled.Overlay>
			<Styled.Container
				ref={containerRef}
				id={`${slideId}_tour`}
				className={clsx(slideAttachment)}
			>
				<Styled.Pointer />
				<Styled.CloseButton onClick={hideTour} />
				<Styled.Title>{title}</Styled.Title>
				<Styled.Text>{text}</Styled.Text>
				<Styled.Actions>
					<FooterDots length={slides.length} currentIndex={slideIndex} />
					{isLastSlide ? (
						<button type="button" onClick={hideTour}>
							{t('common.finish')}
						</button>
					) : (
						<button type="button" onClick={() => setSlideIndex(slideIndex + 1)}>
							{t('common.next')}
						</button>
					)}
				</Styled.Actions>
			</Styled.Container>
		</>
	);
};
