/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getMyLastOrder } from 'src/_api';
import { getUserId } from 'src/_store/selectors';

export const useGetLastOrderId = ({ market, isEnabled }) => {
	const userId = useSelector(getUserId);
	const { data: orderId } = useQuery(
		['last-order-id', market, userId],
		() => getMyLastOrder({ market }),
		{
			enabled: !!isEnabled,
			staleTime: 0,
			cacheTime: 0,
			select: result => {
				if (!result.data?.length) {
					return null;
				}
				return result.data[0];
			},
		}
	);

	return orderId;
};
