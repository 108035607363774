/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { calculatePayCashDirection, calculateSpreadPrice } from 'src/_helpers/spread';
import {
	QuoteType,
	tQuoteValueType,
	SpreadPriceFormat,
	OrderType,
	tCalendarSpreadPayment,
} from 'src/constants/contract';
import { formatPriceWithoutCurrency } from 'src/_helpers/price';
import { useTranslation } from 'react-i18next';

export const useSpreadPricePill = order => {
	const { t } = useTranslation();

	const spreadPriceFormatStrategy = order.spread_details.price_format;
	const spreadTypeFromForm = order.spread_details.spread_type;

	const { price, is_indicative, trade_id, is_order_preview, is_counter_preview } = order;

	const isPreview = is_order_preview || is_counter_preview;

	const orderOrCounterTypeForCalculations = order.counter_order_type || order.order_type;

	const isBuyer = orderOrCounterTypeForCalculations === OrderType.Buy;

	// price comes as +/-/0 from the BE but it's absolute in form
	const priceForCalculations = !isPreview
		? price
		: calculateSpreadPrice(isBuyer, spreadTypeFromForm, price);

	const payCashDirection = isPreview
		? spreadTypeFromForm
		: calculatePayCashDirection(isBuyer, priceForCalculations);

	const isTraded = !!trade_id;
	const quoteType = is_indicative ? QuoteType.Indicative : QuoteType.Firm;
	const formattedPrice = formatPriceWithoutCurrency(price);
	const formattedAbsolutePrice = formatPriceWithoutCurrency(Math.abs(priceForCalculations));

	const pricePillValues = {
		[SpreadPriceFormat.Spread]: {
			firstLine: isTraded ? t('traded') : tQuoteValueType(t, quoteType),
			secondLine: price > 0 ? `+${formattedPrice}` : formattedPrice,
		},
		[SpreadPriceFormat.PayCash]: {
			firstLine: isTraded ? t('traded') : tCalendarSpreadPayment(t, payCashDirection),
			secondLine: formattedAbsolutePrice,
		},
	};

	const { firstLine, secondLine } = pricePillValues[spreadPriceFormatStrategy];

	return { firstLine, secondLine };
};
