/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSpreadStrategy } from './useSpreadStrategy';

export const CalendarSpreadRow = ({ path, spreadStrategy }) => {
	const { spreadStrategyFields } = useSpreadStrategy(path, spreadStrategy);

	return <>{spreadStrategyFields}</>;
};
