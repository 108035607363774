/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import * as Styled from './styled';

export const Checkbox = ({ label, value, onChange, error, errorMessage, ...rest }) => {
	return (
		<>
			<Styled.LargeCheckbox
				label={label}
				value={value}
				onChange={onChange}
				$isError={error}
				{...rest}
			/>
			{error && errorMessage && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
		</>
	);
};
