/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState, useRef, useCallback, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { ROUTES } from 'src/constants/routes';
import { useMediaQuery, screenSize } from 'src/_helpers';
import { setPosition, showElement, getContainerPositions } from './helpers';
import { MaxNumberOfRetries, ContainerWidth } from './constants';

export const useShowSlide = (slides, slideIndex) => {
	const [isVisible, setVisible] = useState(true);
	const [retryNo, setRetryNo] = useState(0);

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const isLargeScreen = useMediaQuery({ 'min-width': screenSize.xl });

	const spotlightRef = useRef();
	const containerRef = useRef();

	const showSlide = useCallback(() => {
		const element = document.body.querySelector(slides[slideIndex].selector);
		if (!element) {
			return false;
		}

		const rect = element.getBoundingClientRect();
		setPosition(spotlightRef, rect);

		const containerHeight = containerRef.current.offsetHeight;

		const containerPosition = getContainerPositions({
			rect,
			containerWidth: isLargeScreen ? ContainerWidth.Large : ContainerWidth.Normal,
			containerHeight,
			attachment: slides[slideIndex].attachment,
		});

		setPosition(containerRef, containerPosition);

		showElement(containerRef);

		return true;
	}, [slides, slideIndex, isLargeScreen]);

	/** try to show the Tour, but if the required node doesn't exist yet, wait for 1 second and try again */
	useLayoutEffect(() => {
		if (slides.length === 0) {
			return;
		}

		const didShow = showSlide();
		if (!didShow) {
			setTimeout(
				() =>
					setRetryNo(retryNo => {
						if (retryNo === MaxNumberOfRetries) {
							return retryNo;
						}
						return retryNo + 1;
					}),
				1000
			);
		} else {
			window.addEventListener('resize', showSlide);
		}

		return () => {
			window.removeEventListener('resize', showSlide);
		};
	}, [showSlide, retryNo, slides.length]);

	const hideTour = async () => {
		setVisible(false);
		window.removeEventListener('resize', showSlide);

		await queryClient.invalidateQueries('settings');

		navigate(`${ROUTES.root}`, {
			state: { showAppTour: false },
			replace: true,
		});
	};

	return {
		isVisible,
		hideTour,
		spotlightRef,
		containerRef,
	};
};
