/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { NotificationsTape } from './Notifications/NotificationsTape';
import { HeaderMenu } from './HeaderMenu';
import { MyNewTrades } from 'src/containers/MyNewTrades/MyNewTrades';

export const HeaderMenuWrapper = () => {
	return (
		<>
			<HeaderMenu />
			<NotificationsTape />
			<MyNewTrades />
		</>
	);
};
