/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useState } from 'react';
import { useSubscribe } from 'src/shared/useSubscribe';
import { ReactComponent as ChatIcon } from 'src/assets/icons/chat_icon.svg';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { WebsocketChannelType, PrivateWebsocketEventType } from 'src/constants/websockets';
import { MenuItemWithIndicator } from './MenuItemWithIndicator';
import { ChatEvent } from 'src/shared/constants';
import { getUserId } from 'src/_store/selectors';
import { useSelector } from 'react-redux';
import { useEmit } from 'src/shared/useEmit';
import { useQuery } from 'react-query';
import {
	ConversationType,
	EmptyUnreadState,
	TopicToConversationTypeMap,
	chatUnreadConversationsQueryName,
} from 'src/chat/constants';
import { getUnreadConversationsCount } from 'src/chat/api/conversations';
import { defaultNotificationsSettings } from 'src/constants/notifications';

export const ChatIndicator = ({ suppressEventEmission }) => {
	const [unread, setUnread] = useState(EmptyUnreadState);
	const [isChatVisible, setChatVisibility] = useState(false);
	const currentUserId = useSelector(getUserId);
	const emit = useEmit();
	const [activeConversationId, setActiveConversationId] = useState(null);

	useSubscribe(ChatEvent.VisibilityStatus, (...params) => {
		const data = params?.[1] || {};
		setChatVisibility(data.isVisible || false);
	});

	useSubscribe(ChatEvent.MarkConversationsAsRead, (...params) => {
		const data = params?.[1] || null;

		if (!data) {
			return;
		}

		const conversationIds = data.conversationIds.map(id => id.split('/').at(-1));

		setUnread(current => ({
			...current,
			[data.conversationType]: current[data.conversationType].filter(
				id => !conversationIds.includes(id)
			),
		}));
	});

	useSubscribe(ChatEvent.UpdateActiveConversationId, (...params) => {
		const { conversationId } = params?.[1] || {};
		setActiveConversationId(conversationId);
	});

	useQuery([chatUnreadConversationsQueryName, currentUserId], getUnreadConversationsCount, {
		...defaultNotificationsSettings,
		onSuccess: data => {
			const unread = { ...EmptyUnreadState };
			if (data?.unread_instant_conversations_ids) {
				unread[ConversationType.Instant] = [...data.unread_instant_conversations_ids];
			}
			if (data?.unread_execution_conversations_ids) {
				unread[ConversationType.Execution] = [...data.unread_execution_conversations_ids];
			}
			if (data?.unread_kyc_conversations_ids) {
				unread[ConversationType.KYC] = [...data.unread_kyc_conversations_ids];
			}
			setUnread(unread);
		},
	});

	const chatNewMessageCallback = useCallback(
		websocketMessage => {
			const { message, conversation } = websocketMessage.data.data;

			if (message.created_by !== currentUserId) {
				const conversationType = TopicToConversationTypeMap[conversation.type];

				const shouldIgnoreMessage =
					unread[conversationType].includes(conversation.id) ||
					(isChatVisible && activeConversationId === conversation.id);

				if (!shouldIgnoreMessage) {
					setUnread(current => ({
						...current,
						[conversationType]: [...current[conversationType], conversation.id],
					}));
				}
			}

			if (!suppressEventEmission) {
				emit(ChatEvent.NewMessage, { message, conversation });
			}
		},
		[activeConversationId, currentUserId, emit, isChatVisible, suppressEventEmission, unread]
	);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.Chat,
		chatNewMessageCallback
	);

	const totalUnreadCount =
		unread[ConversationType.Instant].length +
		unread[ConversationType.Execution].length +
		unread[ConversationType.KYC].length;

	return (
		<MenuItemWithIndicator
			count={totalUnreadCount}
			isLoading={false}
			isActive={isChatVisible}
			IconComponent={ChatIcon}
		/>
	);
};
