/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { QuoteType } from 'src/constants/contract';
import { mapValidityOptionToApi } from './helpers';

export const FieldNames = {
	NameVisibility: 'visibility',
	Runs: 'runs',
	Volume: 'volume',
	Price: 'price',
	QuoteType: 'quote_type',
	Validity: 'validity',
};

export const NameVisibility = {
	Visibile: 'visible',
	Hidden: 'hidden',
};

export const FieldsApiMap = {
	[FieldNames.NameVisibility]: 'order_owner_principal_hidden',
	[FieldNames.Price]: 'price',
	[FieldNames.Volume]: 'volume',
	[FieldNames.Runs]: 'runs',
	[FieldNames.QuoteType]: 'is_indicative',
	[FieldNames.Validity]: 'validity_option',
};

export const FieldApiValuesMap = {
	[FieldNames.NameVisibility]: {
		[NameVisibility.Hidden]: true,
		[NameVisibility.Visibile]: false,
	},
	[FieldNames.QuoteType]: {
		[QuoteType.Indicative]: true,
		[QuoteType.Firm]: false,
	},
	[FieldNames.Validity]: mapValidityOptionToApi,
};

// BE restriction - max 25 orders can be edited at the same time
export const ChangesLimit = 25;
