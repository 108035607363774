/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import * as Styled from './styled';

export const InformativeField = ({ title, subtitle, dataTest }) => {
	return (
		<Styled.InfoField data-test={dataTest}>
			<Styled.InfoFieldTitle>{title}</Styled.InfoFieldTitle>
			<Styled.InfoFieldSubtitle>{subtitle}</Styled.InfoFieldSubtitle>
		</Styled.InfoField>
	);
};
