/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useState } from 'react';
import { DrawerContextKeys } from './DrawerContext';

export const useVisibilityClassName = (contextKey, isVisible) => {
	const [visibilityClassName, setVisibilityClassName] = useState(() => {
		if (contextKey === DrawerContextKeys.viewDrawer) {
			return !isVisible ? 'offscreen' : '';
		}

		return 'offscreen';
	});

	useEffect(() => {
		if (contextKey === DrawerContextKeys.viewDrawer) {
			setVisibilityClassName(!isVisible ? 'offscreen' : '');
		} else if (isVisible) {
			setVisibilityClassName('visible');
			setTimeout(() => {
				setVisibilityClassName('');
			}, 0);
		} else {
			setVisibilityClassName('offscreen');
		}
	}, [contextKey, isVisible]);

	return visibilityClassName;
};
