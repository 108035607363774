/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { h300 } from 'src/components/Typography';

export const Label = styled.label`
	${h300}
	color: var(--input-label-text-color);
	margin-bottom: 6px;

	&.error {
		color: var(--input-error-label-text-color);
	}

	&.required::after {
		content: '*';
		color: var(--input-label-required-text-color);
		margin-left: 4px;
	}
`;

export const Required = styled.span``;
