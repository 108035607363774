/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { Flags } from 'src/featureFlags/Flags';
import { FlagNames } from 'src/constants/flags';
import { Permission } from 'src/containers/Permissions/Permission';
import { Permissions } from 'src/constants/permissions';
import { useSelector } from 'react-redux';
import { getUserCompanyId } from 'src/_store/selectors';
import { useRequestKYCButton } from './useRequestKYCButton';
import * as Styled from './styled';

export const RequestKYCButton = ({
	responderUserId,
	responderUserCompanyId,
	counterStyle,
	close,
}) => {
	const userCompanyId = useSelector(getUserCompanyId);
	const isSameCompanyUser = userCompanyId === responderUserCompanyId;

	const { label, ...buttonProps } = useRequestKYCButton({
		responderUserId,
		responderUserCompanyId,
		close,
	});

	if (isSameCompanyUser) {
		return null;
	}

	return (
		<>
			<Permission
				name={Permissions.KYC_REQUEST}
				renderOn={() => (
					<Flags
						authorizedFlags={[FlagNames.KYC]}
						renderOn={() => (
							<Styled.KYCButton darker={counterStyle} {...buttonProps}>
								{label}
							</Styled.KYCButton>
						)}
					/>
				)}
			/>
		</>
	);
};
