/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useDrawerContext, DrawerContextKeys } from 'src/components/Drawer/DrawerContext';
import { ViewOrderDrawerContent } from 'src/components/ViewOrderDrawer/ViewOrderDrawerContent';
import { useCounterPreview } from './useCounterPreview';

export const CounterPreviewContent = () => {
	const {
		[DrawerContextKeys.viewDrawer]: { counterPreviewData },
	} = useDrawerContext();

	const { shouldShowAdditionalInfoSection } = useCounterPreview(counterPreviewData);

	return (
		<ViewOrderDrawerContent
			order={counterPreviewData}
			isLastCounter
			changes={counterPreviewData.changes}
			shouldShowAdditionalInfoSection={shouldShowAdditionalInfoSection}
		/>
	);
};
