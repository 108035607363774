/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NegativeButton } from 'src/components/Buttons/NegativeButton';
import { useTogglable } from 'src/_helpers/useTogglable';
import {
	Modal,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	CancelButton,
} from 'src/components/Modal';
import { ModalSize, ModalType } from 'src/constants/modal';
import * as Styled from './styled';

export const ConfirmDiscardChangesModal = ({ onConfirm, onCancel, isCounter }) => {
	const { t } = useTranslation();
	const [isOpen, { open, close }] = useTogglable(true);

	const message = isCounter
		? t('confirm_discard_counter_changes_message')
		: t('confirm_discard_changes_message');

	return (
		<Modal
			data-test="confirm-discard-changes-modal"
			size={ModalSize.Tiny}
			open={open}
			close={close}
			isOpen={isOpen}
			type={ModalType.Warning}
			closeOnDimmerClick={false}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{t('confirm_discard_changes_title')}</ModalTitle>
				<Styled.SubTitle>{message}</Styled.SubTitle>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={onCancel} />
				<NegativeButton type="button" onClick={onConfirm}>
					{t('discard_changes_button')}
				</NegativeButton>
			</ButtonsWrapper>
		</Modal>
	);
};
