/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { KeyDetailsSection } from './Content/KeyDetailsSection/KeyDetailsSection';
import { OrderCreatorShipmentSection } from './Content/OrderCreatorShipmentSection/OrderCreatorShipmentSection';
import { PricingSection } from './Content/PricingSection/PricingSection';
import { ShipmentSection } from './Content/ShipmentSection/ShipmentSection';
import { AdditionalInformationSection } from './Content/AdditionalInformationSection/AdditionalInformationSection';
import { CommentsSection } from './Content/CommentsSection/CommentsSection';
import { CounterpartiesSection } from './Content/CounterpartiesSection/CounterpartiesSection';
import { DocumentsSection } from './Content/DocumentsSection/DocumentsSection';
import { useShouldShowShipmentSection } from './helpers/useShouldShowShipmentSection';
import * as Styled from './styled';

export const ViewPhysicalOrderDrawerContent = ({
	order,
	changes,
	isLastCounter,
	isScrolling,
	handleScroll,
	shouldShowAdditionalInfoSection = true,
}) => {
	const shouldShowShipmentSection = useShouldShowShipmentSection(order, changes);

	const isCounter = !!changes;

	return (
		<>
			<Styled.TopContent $isScrolling={isScrolling}>
				<KeyDetailsSection order={order} changes={changes} isLastCounter={isLastCounter} />
			</Styled.TopContent>
			<Styled.ScrollableContent onScroll={handleScroll}>
				<OrderCreatorShipmentSection order={order} changes={changes} />
				<Styled.DrawerContentDetailsWrapper>
					<PricingSection order={order} changes={changes} />
					<Styled.Divider />
					{shouldShowShipmentSection && (
						<>
							<ShipmentSection order={order} changes={changes} />
							<Styled.Divider />
						</>
					)}
					{shouldShowAdditionalInfoSection && (
						<AdditionalInformationSection order={order} changes={changes} />
					)}
					{isCounter && (
						<>
							<Styled.Divider />
							<CommentsSection counter={order} />
						</>
					)}
					{!!order.recipients_list?.length && (
						<>
							<Styled.Divider />
							<CounterpartiesSection order={order} />
						</>
					)}
					{order.files.length > 0 && (
						<>
							<Styled.Divider />
							<DocumentsSection order={order} />
						</>
					)}
				</Styled.DrawerContentDetailsWrapper>
			</Styled.ScrollableContent>
		</>
	);
};
