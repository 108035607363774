/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { keyframes } from 'styled-components';
import { media } from 'src/_helpers';

export const Box = styled.div`
	border-radius: 1px;
	background: var(--neutral-700);
`;

const articlesSkeletonAnimation = keyframes`
	0% { opacity: 0.2 }
	50% {opacity: 0.2 }
	100% { opacity: 0.2 }
`;

export const ArticlesSkeleton = styled.div`
	border: 2px solid var(--neutral-800);
	background: var(--neutral-700);
	border-radius: 8px;
	max-width: 460px;
	height: 142px;
	opacity: 0.2;
	animation: ${articlesSkeletonAnimation} 2s infinite;
`;

export const FeaturedArticlesSkeleton = styled.div`
	width: calc((100vw / 4) - (var(--page-h-padding) * 2));
	height: 380px;
	border-radius: 6px;
	background: var(--neutral-700);
	padding: 185px 16px 20px;
	opacity: 0.8;
	animation: ${articlesSkeletonAnimation} 2s infinite;

	${media.ll`
		width: calc((1280px / 4) - (var(--page-h-padding) * 2))
    `}

	${media.xl`
		width: calc((1440px / 4) - (var(--page-h-padding) * 2))
    `}
`;
