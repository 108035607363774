/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { H200, H700 } from 'src/components/Typography';
import { TextInput } from 'src/designSystem/Form/TextInput/TextInput';
import * as Styled from './styled';

export const InviteViaEmailCardComponent = React.forwardRef(
	({ onInvitationSend, isLoading, error }, ref) => {
		const { t } = useTranslation();

		return (
			<Styled.Wrapper data-test="invite-via-email-card">
				<H700>{t('add_to_contact_via_email')}</H700>
				<Styled.SectionDescription>
					{t('add_to_contact_via_email_description')}
				</Styled.SectionDescription>
				<Styled.InputLabel>{t('email_address')}</Styled.InputLabel>
				<Styled.EmailInputWrapper>
					<TextInput placeholder={t('email_address_example')} ref={ref} />
				</Styled.EmailInputWrapper>
				<Styled.ErrorMessage>
					{error && (
						<>
							<Styled.ErrorIcon />
							<H200 data-test="invitation-error">{t(error)}</H200>
						</>
					)}
				</Styled.ErrorMessage>
				<Styled.SendInviteButton
					onClick={onInvitationSend}
					data-test="submit-invitation"
					loading={isLoading}
				>
					{t('send_invite')}
				</Styled.SendInviteButton>
			</Styled.Wrapper>
		);
	}
);
