/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const ANALYTICS = {
	View: 'view',
	Ignore: 'ignore',
	Popup: 'popup',
};
