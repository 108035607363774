/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as ChainIcon } from 'src/assets/icons/link-chain.svg';
import { media } from 'src/_helpers';

export const OverflowTextWithTrailingElementWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 3px;

	${media.ll`
		gap: 4px;
	`}

	${media.xl`
		gap: 5px;
	`}
`;

export const OrderIdLinkIcon = styled(ChainIcon)`
	width: 12px;
	height: 12px;
	color: var(--neutral-300);
	flex-basis: 12px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-bottom: 2px;

	&:hover {
		color: var(--neutral-200);
		cursor: pointer;
	}
`;

export const NarrowSectionGridText = styled.div`
	width: 111px;

	${media.ll`
		width: 124px;
	`}

	${media.xl`
		width: 139px;
	`}
`;
