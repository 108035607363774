/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const FullscreenWrapper = styled.div`
	position: absolute;
	inset: 0;
	z-index: calc(2 * var(--z-index-top-most));
`;

export const MobileInfoWrapper = styled.div`
	color: #47474f;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.42px;
	padding: 16px 24px 0;
	text-align: center;
`;
