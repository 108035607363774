/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { Modal, ButtonsWrapper } from 'src/components/Modal';

export const ModalContent = styled.div`
	margin-top: 24px;
`;

export const StyledModal = styled(Modal)`
	&.ui.tiny.modal {
		.content {
			padding: 40px 17px;
		}

		${ButtonsWrapper} {
			margin: 32px 23px 0;
			width: calc(100% - 46px);

			${media.xl`
				margin-top: 40px;
			`}
		}
	}
`;
