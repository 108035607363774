/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { arrayOfNElements } from 'src/_helpers';
import * as Styled from './styled';

export const FooterDots = ({ length, currentIndex }) => (
	<Styled.Dots>
		{arrayOfNElements(length).map(index => (
			<Styled.Dot key={index} className={clsx({ current: index === currentIndex })} />
		))}
	</Styled.Dots>
);
