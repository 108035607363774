/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getFeaturesFlagList } from 'src/_api/settings.api';

const developmentFeatureFlagOverrides = process.env.REACT_APP_FEATURES_OVERRIDE;

const parseFlagsList = flags =>
	Object.entries(flags).map(([key, value]) => ({
		name: key,
		isActive: value,
	}));

export const FeatureFlagsContext = createContext({
	featureFlags: [],
	isFlagEnabled: () => false,
	isLoaded: false,
});

export const FeatureFlagsProvider = ({ children, testingFeatureFlags }) => {
	const [loadedFlags, setLoadedFlags] = useState([]);
	const [loadFromAPI, setLoadFromAPI] = useState(false);

	const developmentFeatureFlags = useMemo(() => {
		let flags = [];

		if (developmentFeatureFlagOverrides) {
			developmentFeatureFlagOverrides.split(',').forEach(flagName => {
				flags.push({ name: flagName, isActive: true });
			});
		}

		return flags;
	}, []);

	const { data = [], isFetched } = useQuery('features-flag-list', getFeaturesFlagList, {
		staleTime: Infinity,
		enabled: loadFromAPI && !testingFeatureFlags,
	});

	useEffect(() => {
		if (window.featureFlags) {
			// if the feature flags were injected to index.html, use them
			setLoadedFlags(window.featureFlags);
		} else {
			setLoadFromAPI(true);
		}
	}, [setLoadedFlags, setLoadFromAPI]);

	const featureFlags = testingFeatureFlags
		? testingFeatureFlags
		: !!developmentFeatureFlags.length
		? developmentFeatureFlags
		: parseFlagsList({ ...loadedFlags, ...data });
	const isFlagEnabled = flag => featureFlags.find(f => f.name === flag)?.isActive || false;

	return (
		<FeatureFlagsContext.Provider
			value={{
				featureFlags: featureFlags || [],
				isFlagEnabled,
				isLoaded: isFetched,
			}}
		>
			{children}
		</FeatureFlagsContext.Provider>
	);
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
