/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useTranslation } from 'react-i18next';
import { PhysicalTradeStep, TradeStepStatus } from 'src/constants/physicalTrade';
import { useTradeDataContext } from './TradeDataContext';
import { getCompletedStepProgress } from '../components/physical/ExecutionContent/getCompletedStepProgress';

export const useStickyFooter = () => {
	const { t } = useTranslation();
	const { tradeData, activeStep, completedSteps } = useTradeDataContext();

	const isCompleted = completedSteps.includes(activeStep);

	const isSignatures = activeStep === PhysicalTradeStep.SIGNATURES;
	const isShippingAdvice = activeStep === PhysicalTradeStep.SHIPPING_ADVICE;
	const isInvoicing = activeStep === PhysicalTradeStep.INVOICING;
	const isVesselNomination = activeStep === PhysicalTradeStep.VESSEL_NOMINATION;
	const isShippingTender = activeStep === PhysicalTradeStep.SHIPPING_TENDER;
	const isInstructions = activeStep === PhysicalTradeStep.INSTRUCTIONS;
	const isShippingDocuments = activeStep === PhysicalTradeStep.SHIPPING_DOCUMENTS;

	let progress = TradeStepStatus.NOT_STARTED;

	let message = '';
	let shouldShowCompleteButton = true;

	if (isSignatures) {
		progress = getCompletedStepProgress(
			PhysicalTradeStep.SIGNATURES,
			completedSteps,
			tradeData
		);

		message = {
			[TradeStepStatus.NOT_STARTED]: t('awaiting_for_you_and_counterparty_complete_stage'),
			[TradeStepStatus.COMPLETED_BY_ME]: t(
				'you_have_already_completed_this_stage_awaiting_counterparty'
			),
			[TradeStepStatus.COMPLETED_BY_COUNTERPARTY]: t(
				'stage_completed_by_counterparty_awaiting_your_completion'
			),
			[TradeStepStatus.COMPLETED_BY_BOTH]: t('stage_completed'),
		}[progress];

		shouldShowCompleteButton = {
			[TradeStepStatus.NOT_STARTED]: true,
			[TradeStepStatus.COMPLETED_BY_ME]: false,
			[TradeStepStatus.COMPLETED_BY_COUNTERPARTY]: true,
			[TradeStepStatus.COMPLETED_BY_BOTH]: false,
		}[progress];
	}

	if (isShippingAdvice || isShippingTender || isInvoicing) {
		progress = isCompleted ? TradeStepStatus.COMPLETED_BY_BOTH : TradeStepStatus.NOT_STARTED;

		if (isCompleted) {
			message = t('stage_completed');
		} else {
			message = tradeData.isBuyerSide
				? t('awaiting_for_counterparty_to_complete_this_stage')
				: t('awaiting_for_you_to_complete_this_stage');
		}
	}

	if (isVesselNomination || isInstructions) {
		progress = isCompleted ? TradeStepStatus.COMPLETED_BY_BOTH : TradeStepStatus.NOT_STARTED;

		if (isCompleted) {
			message = t('stage_completed');
		} else {
			message = tradeData.isBuyerSide
				? t('awaiting_for_you_to_complete_this_stage')
				: t('awaiting_for_counterparty_to_complete_this_stage');
		}
	}

	if (isShippingDocuments) {
		progress = isCompleted ? TradeStepStatus.COMPLETED_BY_BOTH : TradeStepStatus.NOT_STARTED;
		shouldShowCompleteButton = !tradeData.isSellerSide;

		if (isCompleted) {
			message = t('stage_completed');
		} else {
			message = tradeData.isBuyerSide
				? t('awaiting_for_you_to_complete_this_stage_shipping_documents')
				: t('awaiting_for_counterparty_to_complete_this_stage_shipping_documents');
		}
	}

	if (isShippingAdvice || isVesselNomination || isInstructions) {
		shouldShowCompleteButton = false;
	}

	if (isShippingTender || isInvoicing) {
		shouldShowCompleteButton = tradeData.isSellerSide;
	}

	return {
		shouldShowCompleteButton,
		progress,
		message,
		isCompleted,
	};
};
