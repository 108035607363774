/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { arrayOfNElements } from 'src/_helpers/array';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { Box } from 'src/components/Skeleton/Skeleton';

export function DatatableLoading({ columns }) {
	return arrayOfNElements(4).map(index => (
		<Table.Row active={false} key={index} style={{ opacity: 0.4 - index * 0.1 }}>
			{columns.map(({ width, columnKey, name }) => (
				<Table.Cell
					key={columnKey || name}
					style={{
						width,
					}}
				>
					<Placeholder />
				</Table.Cell>
			))}
		</Table.Row>
	));
}

const Placeholder = styled(Box)`
	height: 12px;
`;
