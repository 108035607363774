/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PermanentToast } from '../Toast/PermanentToast';

export const RefreshToast = () => {
	const { t } = useTranslation();

	return (
		<PermanentToast
			data-test="refresh-page-notification"
			message={t('new_activities_refresh_page')}
			kind="info"
			button={{
				text: t('refresh'),
				onClick: () => {
					window.location.reload(false);
				},
			}}
		/>
	);
};
