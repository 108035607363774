/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Label } from '../Label/Label';
import { TextInput } from '../TextInput/TextInput';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar.svg';
import { DateTimeTzPicker } from 'src/components/DateTimeTzPicker';
import { getFormattedDateTime } from 'src/_helpers/date';
import * as Styled from './styled';

export const MaterialDateTimeInput = React.forwardRef(
	(
		{
			label,
			id,
			icon,
			onChange,
			value,
			labelBackground = 'var(--neutral-800)',
			required,
			filterDate,
			renderDateToggleButton,
			popupHeaderTitle,
			popupWrapper = Styled.DrawerPopup,
			withEllipsis,
			controlledState = false,
			hasChanged = false,
			className,
			isTimeDefault,
			validityOptions,
			...props
		},
		ref
	) => {
		const { t } = useTranslation();

		const hasValue = !!value.localDate;
		const filledControlledState = controlledState === true && hasChanged === false && hasValue;
		const filledState = controlledState ? hasChanged === true : hasValue;

		const displayValue = getFormattedDateTime(value, t);

		return (
			<Styled.InputContainer
				className={clsx(
					'text-input',
					filledState && 'input-filled',
					filledControlledState && 'input-filled-controlled'
				)}
				$labelBackground={labelBackground}
			>
				<DateTimeTzPicker
					filterDate={filterDate}
					onChange={onChange}
					skipWrapper
					value={value || ''}
					renderDateToggleButton={renderDateToggleButton}
					popupHeaderTitle={popupHeaderTitle}
					Wrapper={popupWrapper}
					skipTriggerWrapper
					isTimeDefault={isTimeDefault}
					validityOptions={validityOptions}
				>
					<TextInput
						id={id}
						{...props}
						value={displayValue}
						className={clsx(withEllipsis && 'apply-ellipsis', className)}
					/>
				</DateTimeTzPicker>
				<Label htmlFor={id}>
					{label}
					{required && <span className="asterisk">*</span>}
				</Label>
				{hasValue ? (
					<Styled.ValidCheckIcon />
				) : (
					<Styled.IconWrapper>
						<CalendarIcon />
					</Styled.IconWrapper>
				)}
			</Styled.InputContainer>
		);
	}
);
