/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router';
import * as Styled from './styled';

export const TypeSelector = ({ options = [] }) => {
	return (
		<Styled.Wrapper>
			{options.map(({ text, path, onClick, isActive }, index) =>
				path ? (
					<PillLink text={text} path={path} key={text} index={index} />
				) : (
					<Pill
						text={text}
						isActive={isActive}
						onClick={onClick}
						key={text}
						index={index}
					/>
				)
			)}
		</Styled.Wrapper>
	);
};

const Pill = ({ text, onClick, index, isActive }) => {
	const { t } = useTranslation();

	return (
		<Styled.Pill
			data-test={`pill-${index}`}
			className={isActive ? 'active' : ''}
			onClick={onClick}
		>
			{t(text)}
		</Styled.Pill>
	);
};

const PillLink = ({ text, path, index }) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const isActive = path => matchPath({ path, end: false }, pathname);

	return (
		<Styled.PillLink
			key={text}
			to={path}
			data-test={`pill-link-${index}`}
			className={isActive(path) ? 'active' : ''}
		>
			{t(text)}
		</Styled.PillLink>
	);
};
