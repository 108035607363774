/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useRef } from 'react';

export const useComponentWillMount = (cb, dependencies = []) => {
	const willMount = useRef(true);

	if (willMount.current) {
		cb();
	}

	willMount.current = false;
};
