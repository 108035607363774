/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import * as momentTimezone from 'moment-timezone';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getOrderNegotiationTradeLink } from 'src/_helpers/order';
import {
	formatDateRange,
	getSpreadMonths,
	parseDateStringAsUTC,
	formatFuturesContractMonthToLetterCode,
	getSpreadMultiMonths,
	Dateformat,
} from 'src/_helpers/date';
import { tLegQuote, Role, OrderType, SpreadPriceFormat } from 'src/constants/contract';
import { formatPriceWithoutCurrency, formatFloatNumberToPriceFormat } from 'src/_helpers/price';
import { Market, PaperInstruments, tOrderType, PriceType } from 'src/constants/contract';
import { formatTzOffset } from 'src/components/DateTimeTzPicker';
import { OrderStatus } from 'src/constants/orderStatus';
import {
	findSelectedFuturesContractObject,
	formatProductAndFuturesCode,
} from 'src/_helpers/futuresContracts';
import { calculatePayCashDirection } from 'src/_helpers/spread';

export const useShareOrder = order => {
	const { t } = useTranslation();
	const { link } = getOrderNegotiationTradeLink(t, order, false);

	let textLines = [];

	const isSpread = order.instrument === PaperInstruments.Spread;

	const volume = order.volume ? new Intl.NumberFormat('en').format(order.volume) : null;

	const orderType = isSpread ? t('spread_order') : tOrderType(t, order.order_type).toLowerCase();

	if (order.market === Market.Paper) {
		textLines = [
			t('share_message_header', { orderType }) + '\n',
			`${t('product')}: ${order.product.name} ${order.inco.name} ${
				order.primary_ports[0].name
			}`,
			`${t('price')}: ${formatPrice(t, order)}`,
			volume && `${t('quantity')}: ${volume} mt`,
			`${t('shipment')}: ${formatPaperShipment(t, order)}`,
			formatOwner(t, order),
			formatValidity(t, order),
			' ',
			link,
		].filter(Boolean);
	} else {
		const grade = order.grade.is_custom ? t('custom_grade') : order.grade.name;

		textLines = [
			t('share_message_header', { orderType }) + '\n',
			`${t('product')}: ${order.product.name}, ${grade}`,
			`${t('price')}: ${formatPrice(t, order)}`,
			`${t('quantity')}: ${volume} mt`,
			`${t('shipment')}: ${formatPhysicalShipment(t, order)}`,
			formatOwner(t, order),
			formatValidity(t, order),
			' ',
			link,
		];
	}

	return {
		shareLink: link,
		shareText: textLines.filter(Boolean).join('\n'),
	};
};

const formatOwner = (t, order) => {
	if (order.hidden) {
		return;
	}

	const label =
		order.role === Role.Broker
			? t('broker')
			: order.order_type === OrderType.Buy
			? t('buyer')
			: t('seller');

	return `${label}: ${order.company.name}`;
};

const formatValidity = (t, order) => {
	if (order.status !== OrderStatus.Active) {
		return;
	}

	const date = moment(order.validity).format('D MMM YYYY, h:mmA');
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const offset = momentTimezone.tz(timeZone).utcOffset();

	const tzText = offset === 0 ? 'GMT' : formatTzOffset(offset);

	return `${t('available_until')}: ${date} ${tzText}`;
};

const formatPhysicalShipment = (t, order) => {
	const ports = order.primary_ports;

	const date = formatDateRange({
		startDate: parseDateStringAsUTC(order.delivery_date_from),
		endDate: parseDateStringAsUTC(order.delivery_date_to),
		format: order.delivery_mode,
	});

	const portLabel = ports.length === 1 ? ports[0].name : `${ports[0].name} +${ports.length - 1}`;

	return `${order.inco.name} ${portLabel}, ${date}`;
};

const formatPaperShipment = (t, order) => {
	if (order.instrument === PaperInstruments.Spread) {
		const firstLegQuote = tLegQuote(t, order.order_type);
		const secondLegQuote =
			order.order_type === OrderType.Buy
				? tLegQuote(t, OrderType.Sell)
				: tLegQuote(t, OrderType.Buy);

		const isPayCashSpread = order.spread_details?.price_format === SpreadPriceFormat.PayCash;
		const [firstLegtMonth, secondLegMonth] = isPayCashSpread
			? getSpreadMonths(order.delivery_date_from, order.spread_details.delivery_date_from)
			: getSpreadMultiMonths(
					{
						startDate: order.delivery_date_from,
						endDate: order.delivery_date_to,
						format: Dateformat.Months,
					},
					{
						startDate: order.spread_details.delivery_date_from,
						endDate: order.spread_details.delivery_date_to,
						format: Dateformat.Months,
					}
			  );

		return `${firstLegQuote} ${firstLegtMonth} x ${secondLegQuote} ${secondLegMonth}`;
	}

	return formatDateRange({
		startDate: parseDateStringAsUTC(order.delivery_date_from),
		endDate: parseDateStringAsUTC(order.delivery_date_to),
		format: order.delivery_mode,
	});
};

const formatPrice = (t, order) => {
	if (!order.has_price) {
		return '---';
	}

	const isSpread = order.instrument === PaperInstruments.Spread;
	const isPayCashSpread =
		isSpread && order.spread_details?.price_format === SpreadPriceFormat.PayCash;
	const isBuyer = order.order_type === OrderType.Buy;
	const payCashDirection = calculatePayCashDirection(isBuyer, order.price);
	const spreadPrice = formatFloatNumberToPriceFormat(Math.abs(order.price));
	const currencyUnit = `${order.currency}/${t('price_unit_short_' + order.price_unit)}`;

	const price = formatPriceWithoutCurrency(order.price);
	const basePrice = !isSpread
		? price
		: isPayCashSpread
		? t(payCashDirection) + ' ' + spreadPrice
		: order.price > 0
		? `+${price}`
		: price;

	const futuresContract = findSelectedFuturesContractObject(order);

	const defaultProductAndFuturesCode =
		futuresContract &&
		formatProductAndFuturesCode(futuresContract.product_code, order.futures_contract_date);

	const futures = isSpread
		? `${defaultProductAndFuturesCode}/${formatFuturesContractMonthToLetterCode(
				order.spread_details.futures_contract_date
		  )}`
		: defaultProductAndFuturesCode;

	const priceText =
		order.price_type === PriceType.Basis
			? `${basePrice} ${futures} ${currencyUnit}`
			: `${basePrice} ${currencyUnit}`;

	return priceText;
};
