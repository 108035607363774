/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Styled from './styled';

export const OrderEditedMessage = ({ order }) => {
	const { t } = useTranslation();

	if (!order.version_created_at) {
		return null;
	}

	const time = moment(order.version_created_at).fromNow(true);

	return <Styled.Edited>{t('edited_time_ago', { time })}</Styled.Edited>;
};
