/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getCapitalLetter } from 'src/_helpers/getCapitalLetter';
import { LoaderSpinning } from 'src/components/Layout/LandingForm';
import { UserData } from 'src/containers/MyAccount/components/_modals/commonModalElements';
import {
	StyledH300,
	UsersList,
	UserListItem,
	NoContactsWarning,
	InitialsCircleWrapper,
	InitialsCircle,
	UserDetails,
	StyledCompanyName,
	StyledUserName,
	LoaderWrapper,
	UsersLabel,
} from './styledComponents';
import { useFilteredContactsList } from './useFilteredContactsList';
import { Avatar, AvatarType } from 'src/shared/components/Avatar/Avatar';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';
import { SearchInput } from 'src/designSystem/Form/SearchInput/SearchInput';
import { useUserPresenceContext } from 'src/websockets/UserPresenceProvider';

/**
 * Note: setting `shouldReturnFullObject` to `true` means that `selectedUsers` prop contains array of full user objects (with name, company etc).
 * If the flag is set to `false` (default), the `selectedUsers` is expected to be an array of identifiers (strings).
 * The `setSelectedUsers` callback will be called with the same form of object, so if `shouldReturnFullObject` is `true` - it will be called with array of objects
 * and if it's set to `false` - the param will be array of string identifiers.
 */

export const SearchableContactsList = ({
	selectedUsers,
	setSelectedUsers,
	usersToHide = [],
	label = '',
	noResultsMessage = '',
	usersLabel = false,
	shouldReturnFullObject = false,
	groupsContent = null,
	error = false,
}) => {
	const { t } = useTranslation();
	const [filter, setFilter] = useState('');
	const { getUserVisibility } = useUserPresenceContext();

	const selectedUsersIds = shouldReturnFullObject
		? selectedUsers.map(user => user.contact_user_id)
		: selectedUsers;

	const handleFiltering = event => setFilter(event.target.value);
	const isUserSelected = userId => !!selectedUsersIds.length && selectedUsersIds.includes(userId);

	const {
		filteredAndSortedUsers,
		isLoading,
		isError,
		allContactsCount,
	} = useFilteredContactsList(selectedUsersIds, filter, usersToHide);

	const toggleUserSelection = user => {
		const userId = user.contact_user_id;

		if (shouldReturnFullObject) {
			setSelectedUsers(list => {
				return isUserSelected(userId)
					? list.filter(({ contact_user_id }) => contact_user_id !== userId)
					: [...list, user];
			});
		} else {
			setSelectedUsers(list => {
				return isUserSelected(userId)
					? list.filter(id => id !== userId)
					: [...list, userId];
			});
		}
	};

	if (isLoading) {
		return (
			<LoaderWrapper>
				<LoaderSpinning />
			</LoaderWrapper>
		);
	}

	return (
		<div data-test="searchable-contacts-list">
			{label && <StyledH300>{label}</StyledH300>}
			<SearchInput
				value={filter}
				placeholder={t('search_users')}
				onChange={handleFiltering}
				onClear={() => setFilter('')}
				disabled={isLoading || isError || !allContactsCount}
				data-test="search-users-input"
				error={error}
			/>
			<UsersList data-test="users-list" className="users-list">
				{!filteredAndSortedUsers.length || isError ? (
					<NoContactsWarning>
						{noResultsMessage || t('no_contacts_to_add')}
					</NoContactsWarning>
				) : (
					<>
						{groupsContent ? groupsContent(filter) : null}
						{usersLabel && <UsersLabel>{t('users')}</UsersLabel>}
						{filteredAndSortedUsers.map((user, i) => {
							const name = user.name.split(' ');
							const isSelected = isUserSelected(user.contact_user_id);

							return (
								<UserListItem
									key={i}
									onClick={() => toggleUserSelection(user)}
									$selected={isSelected}
									className={
										isSelected ? 'contact-item-selected' : 'contact-item'
									}
									data-test={`contact-item-${user._key}`}
								>
									<InitialsCircleWrapper data-test={`contact-${user._key}`}>
										<InitialsCircle className="initials" $selected={isSelected}>
											<Avatar
												initials={`${getCapitalLetter(
													name[0]
												)}${getCapitalLetter(name[1])}`}
												size={AvatarType.Large}
												backgroundColor={user.avatar_color}
												dotType={getUserVisibility(
													user.contact_user_id,
													user.visibility
												)}
												dotBackgroundColor="var(--neutral-700)"
											/>
										</InitialsCircle>
									</InitialsCircleWrapper>
									<UserDetails>
										<UserData>
											<StyledUserName>{user.name}</StyledUserName>
											<StyledCompanyName>
												{user.company_name}
											</StyledCompanyName>
										</UserData>
									</UserDetails>
									{isSelected && <StyledCheckIcon />}
								</UserListItem>
							);
						})}
					</>
				)}
			</UsersList>
		</div>
	);
};

const StyledCheckIcon = styled(CheckIcon)`
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	width: 16px;
	height: 16px;
`;
