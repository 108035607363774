/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/Toast';
import background from 'src/assets/images/terms-background.png';
import { useTermsAndConditions } from 'src/_api/queries';
import { acceptTermsAndConditions } from 'src/_api/settings.api';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';

export const TermsAndConditions = () => {
	const { t } = useTranslation();
	const { addToast } = useToast();

	const { mutate, isLoading: isAccepting } = useMutation(acceptTermsAndConditions, {
		onError: () => {
			addToast({
				message: t('terms_accept_failed'),
				kind: 'error',
			});
		},
		onSuccess: () => {
			window.location.reload();
		},
	});

	const { data: termsAndConditions } = useTermsAndConditions();

	const handleAccept = () => {
		mutate({
			version: termsAndConditions.region
				? termsAndConditions._key
				: termsAndConditions.version,
		});
	};

	return (
		termsAndConditions && (
			<Wrapper>
				<TermsAndConditionsModal
					terms={termsAndConditions.text}
					onAccept={handleAccept}
					isAccepting={isAccepting}
					pdfLink={termsAndConditions.pdf_link}
				/>
			</Wrapper>
		)
	);
};

const Wrapper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: var(--neutral-1000) 0 0 url(${background}) no-repeat;
	background-size: stretch;
`;
