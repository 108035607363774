/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isVersionGreaterThan } from 'src/_helpers/isVersionGreaterThan';
import { ToastNotification } from '../Toast';

// key is exact vendor name
const minimalVersions = {
	'Google Inc.': {
		regexp: /\sChrome\/([^\s]*)/,
		requiredVersion: '81.0.4044', // released on 2020-04-07
	},
	'Apple Computer, Inc.': {
		regexp: /\sVersion\/([^\s]*)/,
		requiredVersion: '14.0', // released 2020-09-16
	},
};

export const BrowserVersionCheck = () => {
	const [isOutdated, setIsOutdated] = useState(false);
	const [isToastVisible, setIsToastVisible] = useState(false);

	const { t } = useTranslation();

	useEffect(() => {
		const userAgent = window.navigator?.userAgent;
		const vendor = window.navigator?.vendor;

		if (!userAgent || !vendor) {
			return;
		}

		if (minimalVersions[vendor]) {
			const definition = minimalVersions[vendor];
			const matches = userAgent.match(definition.regexp);

			if (matches?.length === 2) {
				if (isVersionGreaterThan(definition.requiredVersion, matches[1])) {
					setIsOutdated(true);
					setIsToastVisible(true);
				}
			}
		}
	}, []);

	if (!isOutdated || !isToastVisible) {
		return null;
	}

	return (
		<ToastNotification
			message={t('browser_outdated')}
			kind="warning"
			onClose={() => setIsToastVisible(false)}
		/>
	);
};
