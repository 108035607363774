/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import {
	PhysicalTradeStep,
	ResponseStepStatus,
	TradeStepStatus,
} from 'src/constants/physicalTrade';

export const getCompletedStepProgress = (step, completedSteps, tradeData) => {
	if (completedSteps.includes(step)) {
		return TradeStepStatus.COMPLETED_BY_BOTH;
	}

	if (step === PhysicalTradeStep.SIGNATURES) {
		const statuses = tradeData.step_statuses;

		if (!statuses) {
			/**	use old approach when there're no statuses in the response */
			return calculateLegacySignaturesCompletion(tradeData);
		}

		if (tradeData.isBuyerSide) {
			if (statuses.signatures === ResponseStepStatus.BUYER_DONE) {
				return TradeStepStatus.COMPLETED_BY_ME;
			}

			if (statuses.signatures === ResponseStepStatus.SELLER_DONE) {
				return TradeStepStatus.COMPLETED_BY_COUNTERPARTY;
			}
		}

		if (tradeData.isSellerSide) {
			if (statuses.signatures === ResponseStepStatus.BUYER_DONE) {
				return TradeStepStatus.COMPLETED_BY_COUNTERPARTY;
			}

			if (statuses.signatures === ResponseStepStatus.SELLER_DONE) {
				return TradeStepStatus.COMPLETED_BY_ME;
			}
		}
	}

	return TradeStepStatus.NOT_STARTED;
};

const calculateLegacySignaturesCompletion = tradeData => {
	if (tradeData.isBuyerSide) {
		if (tradeData.buyer_done) {
			return TradeStepStatus.COMPLETED_BY_ME;
		}

		if (tradeData.seller_done) {
			return TradeStepStatus.COMPLETED_BY_COUNTERPARTY;
		}
	}

	if (tradeData.isSellerSide) {
		if (tradeData.buyer_done) {
			return TradeStepStatus.COMPLETED_BY_COUNTERPARTY;
		}

		if (tradeData.seller_done) {
			return TradeStepStatus.COMPLETED_BY_ME;
		}
	}

	return TradeStepStatus.NOT_STARTED;
};
