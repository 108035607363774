/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatDateRange, parseDateStringAsUTC } from 'src/_helpers/date';
import { futuresContractLabel } from 'src/_helpers/futuresContracts';
import { useSellerBrokerBuyer } from 'src/_helpers/order';
import { OriginsListMode, formatOriginsList } from 'src/_helpers/origins';
import { formatPrice } from 'src/_helpers/price';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { OverflowArray } from 'src/components/Tooltip/OverflowText';
import { VolumeDisplay } from 'src/components/Volume';
import { tCurrency, tPriceType } from 'src/constants/contract';
import { tPriceUnit } from 'src/constants/product';

export const useAcceptPhysicalOrderSummary = order => {
	const { t } = useTranslation();
	const { isBrokerOrder, isBasisPrice, isSellOrder } = useOrderDetails(order);

	const originValue =
		order?.origin_countries || (order?.origin_country ? [order.origin_country] : null);

	const incoWithPortNames = order.primary_ports
		.map((port, index) => {
			if (index === 0) {
				return `${order.inco.name}, ${port?.name}`;
			}

			return port?.name;
		})
		.filter(Boolean);

	const brokerLabel = `${t('broker')} (${isSellOrder ? t('seller') : t('buyer')})`;

	const { sellerCompany, buyerCompany, brokerCompany } = useSellerBrokerBuyer(order);

	const dataToDisplay = [
		{
			title: t('seller'),
			value: sellerCompany,
		},
		isBrokerOrder && {
			title: brokerLabel,
			value: brokerCompany,
		},
		{
			title: t('buyer'),
			value: buyerCompany,
		},
		{
			title: t('product'),
			value: `${order.product.name}, ${order.grade.name}`,
		},
		{
			title: t('inco'),
			value: <OverflowArray texts={incoWithPortNames} />,
		},
		originValue && {
			title: t('origin'),
			value: formatOriginsList(originValue, 'name', OriginsListMode.tooltip, t),
		},
		{
			title: t('quantity'),
			value: <VolumeDisplay value={order.volume} />,
		},
		{
			title: t('shipment'),
			value: formatDateRange({
				format: order.delivery_mode,
				startDate: parseDateStringAsUTC(order.delivery_date_from),
				endDate: parseDateStringAsUTC(order.delivery_date_to),
			}),
		},
		{
			title: t('price_type'),
			value: tPriceType(t, order.price_type),
		},
		{
			title: tPriceUnit(t, order.price_unit),
			value: formatPrice(order.price, {
				currency: tCurrency(t, order.currency),
			}),
		},
		isBasisPrice && {
			title: t('futures_contract'),
			value: `${futuresContractLabel(
				t,
				order.product.futures_contracts,
				order.futures_contract
			)} ${moment(order.futures_contract_date).format('MMM YYYY')}`,
		},
	].filter(Boolean);

	return { dataToDisplay };
};
