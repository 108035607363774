/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import * as Ably from 'ably';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserId, userAuthLoggedIn, userAuthLoggingOut } from 'src/_store/selectors';
import { fetchFromExchangeApi, map404ToEmptyObject } from 'src/_api/model';
import { useFeatureFlags } from 'src/featureFlags/FeatureFlagsContext';
import { FlagNames } from 'src/constants/flags';

export const UserStatus = {
	Active: 'active',
	Away: 'away',
};

const fetchAuthToken = async () => {
	const result = await fetchFromExchangeApi('event_stream/token', {
		method: 'POST',
		mapStatusToResult: map404ToEmptyObject,
	});
	return result.token;
};

/**
 * @typedef {import('ably').Types.RealtimePromise} RealtimePromise
 */

const defaultValues = {
	/**
	 *
	 * @type {RealtimePromise|null}
	 */
	ablyInstance: null,
};

export const WebsocketsContext = createContext(defaultValues);

export const WebsocketsProvider = ({ children }) => {
	/**
	 *
	 * @type {[RealtimePromise|null, React.Dispatch<React.SetStateAction<RealtimePromise|null>>]}
	 */
	const [ablyInstance, setAblyInstance] = useState(null);
	const userId = useSelector(getUserId);
	const isLoggedIn = useSelector(userAuthLoggedIn);
	const isLoggingOut = useSelector(userAuthLoggingOut);
	const featureFlagContext = useFeatureFlags();
	const eventstreamEnabled = featureFlagContext.isFlagEnabled(FlagNames.Eventstream);

	useEffect(() => {
		const connectToAbly = async () => {
			try {
				if (
					userId &&
					isLoggedIn &&
					!isLoggingOut &&
					eventstreamEnabled &&
					ablyInstance == null
				) {
					const ablyClientInstance = createRealtime(userId);

					setAblyInstance(ablyClientInstance);
				}
			} catch (e) {
				console.error('Ably connection error:', e.message);
			}
		};

		connectToAbly();

		if (isLoggingOut) {
			ablyInstance?.close();
			setAblyInstance(null);
		}
	}, [userId, isLoggedIn, isLoggingOut, eventstreamEnabled, ablyInstance]);

	const contextValue = {
		ablyInstance,
	};

	return <WebsocketsContext.Provider value={contextValue}>{children}</WebsocketsContext.Provider>;
};

export const useWebsocketsContext = () => useContext(WebsocketsContext);

const createRealtime = userId =>
	new Ably.Realtime.Promise({
		recover: (lastConnectionDetails, cb) => {
			if (lastConnectionDetails.clientId === userId) {
				cb(true);
			} else {
				cb(false);
			}
		},
		authCallback: async (_tokenParams, callback) => {
			try {
				const token = await fetchAuthToken();
				callback(null, token);
			} catch (error) {
				callback(error, null);
			}
		},
	});
