/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { useWatch } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { getInstrumentAndPriceTypes } from './helpers/getInstrumentAndPriceTypes';

export const useWatchPaperFields = path => {
	const presetIDValue = useWatch({ name: 'presetID' });
	const instrumentValue = useWatch({ name: 'instrument' });
	const currencyUnitValue = useWatch({ name: toPath(path, 'currencyUnit') });
	const orderTypeValue = useWatch({ name: toPath(path, 'orderType') });
	const priceTypeValue = useWatch({ name: toPath(path, 'priceType') });
	const priceValue = useWatch({ name: toPath(path, 'price') });
	const runsValue = useWatch({ name: toPath(path, 'runs') });
	const deliveryValue = useWatch({ name: toPath(path, 'delivery') });
	const secondLegDeliveryValue = useWatch({ name: toPath(path, 'secondLegDelivery') });
	const volumeValue = useWatch({ name: toPath(path, 'volume') });
	const totalVolumeValue = useWatch({ name: toPath(path, 'totalVolume') });
	const counterpartiesValue = useWatch({ name: toPath(path, 'counterparties') }) || [];
	const environmentValue = useWatch({ name: toPath(path, 'environment') });
	const typeValue = useWatch({ name: toPath(path, 'type') });
	const roleValue = useWatch({ name: toPath(path, 'role') });
	const principalValue = useWatch({ name: toPath(path, 'principal') });
	const isEditing = useWatch({ name: 'isEditing' }) || false;
	const isCopying = useWatch({ name: 'isCopying' }) || false;
	const contractPricingValues = useWatch({ name: 'contractPricing' });
	const validityChangedValue = useWatch({ name: 'validityChanged' });
	const validityValue = useWatch({ name: 'validity' });
	const futuresMonthChangedValue = useWatch({ name: 'futuresMonthChanged' });
	const spreadTypeValue = useWatch({ name: toPath(path, 'spreadType') });

	const firstLegMonthValue = useWatch({ name: toPath(path, 'firstLegMonth') });
	const firstLegPriceValue = useWatch({ name: toPath(path, 'firstLegPrice') });
	const secondLegMonthValue = useWatch({ name: toPath(path, 'secondLegMonth') });
	const firstLegFuturesMonthChangedValue = useWatch({
		name: toPath(path, 'firstLegFuturesMonthChanged'),
	});
	const secondLegFuturesMonthChangedValue = useWatch({
		name: toPath(path, 'secondLegFuturesMonthChanged'),
	});

	// Artificial field; not validated and used to store full preset object
	const selectedProductPresetValue = useWatch({ name: 'selectedProductPreset' });

	const availableInstruments = getInstrumentAndPriceTypes(selectedProductPresetValue);

	return {
		presetIDValue,
		selectedProductPresetValue,
		availableInstruments,
		instrumentValue,
		currencyUnitValue,
		orderTypeValue,
		priceValue,
		priceTypeValue,
		runsValue,
		deliveryValue,
		numberOfDeliveryMonthsValue: getNumberOfDeliveryMonths(deliveryValue),
		secondLegDeliveryValue,
		volumeValue,
		totalVolumeValue,
		environmentValue,
		isEditing,
		isCopying,
		counterpartiesValue,
		typeValue,
		contractPricingValues,
		validityValue,
		validityChangedValue,
		futuresMonthChangedValue,
		firstLegMonthValue,
		secondLegMonthValue,
		firstLegFuturesMonthChangedValue,
		secondLegFuturesMonthChangedValue,
		spreadTypeValue,
		firstLegPriceValue,
		roleValue,
		principalValue,
	};
};

export const getNumberOfDeliveryMonths = (deliveryDateValue = {}) => {
	if (deliveryDateValue.startDate && !deliveryDateValue.endDate) {
		return 1;
	} else if (deliveryDateValue.startDate && deliveryDateValue.endDate) {
		return moment(deliveryDateValue.endDate).diff(deliveryDateValue.startDate, 'months') + 1;
	}

	return 0;
};
