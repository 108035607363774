/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { useCallback } from 'react';
import { mapThemeToColors } from 'src/_api/settings.api';
import { ThemeColors } from 'src/constants/settings';
import { setNestedProperty } from './object';
import { theme } from 'src/containers/MobileChart/helpers/useCreateWidgetOptions';
import { Market } from 'src/constants/contract';
import { ContinuousShipmentPeriod } from 'src/constants/chart';
import { getCargoTypeFilterValues } from './getCargoTypeFilterValues';

export const cssVar = varName => {
	return getComputedStyle(document.documentElement).getPropertyValue(varName).trim();
};

const getColorByName = colorName => {
	if (colorName === ThemeColors.green) {
		return cssVar('--green-400');
	}

	if (colorName === ThemeColors.red) {
		return cssVar('--red-400');
	}

	if (colorName === ThemeColors.blue) {
		return cssVar('--primary-200');
	}

	if (colorName === ThemeColors.orange) {
		return cssVar('--yellow-200');
	}
};

export const getBuyColor = theme => getColorByName(mapThemeToColors[theme]?.buy_color);
export const getSellColor = theme => getColorByName(mapThemeToColors[theme]?.sell_color);

export const useAdjustedChartSettings = (chartState, saveChartState, isPortrait) => {
	const adjustChartColors = useCallback(
		(buyColor, sellColor) => {
			let adjustedChartSettings;

			// Volume
			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[1].sources[0].state.palettes.volumePalette.colors.0.color',
				isPortrait ? theme.neutral400 : sellColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[1].sources[0].state.palettes.volumePalette.colors.1.color',
				isPortrait ? theme.neutral400 : buyColor
			);

			// Main series
			// Bars
			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.barStyle.upColor',
				buyColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.barStyle.downColor',
				sellColor
			);

			// Candles
			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.candleStyle.upColor',
				buyColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.candleStyle.downColor',
				sellColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.candleStyle.borderUpColor',
				buyColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.candleStyle.borderDownColor',
				sellColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.candleStyle.wickUpColor',
				buyColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.candleStyle.wickDownColor',
				sellColor
			);

			// Hollow Candles styles
			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.hollowCandleStyle.upColor',
				buyColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.hollowCandleStyle.downColor',
				sellColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.hollowCandleStyle.borderUpColor',
				buyColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.hollowCandleStyle.borderDownColor',
				sellColor
			);

			// Heikin
			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.haStyle.upColor',
				buyColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.haStyle.downColor',
				sellColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.haStyle.borderUpColor',
				buyColor
			);

			adjustedChartSettings = setNestedProperty(
				chartState,
				'charts[0].panes[0].sources[0].state.haStyle.borderDownColor',
				sellColor
			);

			// Save so it's persistent
			saveChartState(adjustedChartSettings);

			// Load with adjusted settings right after
			try {
				window?.tvWidget?.load(adjustedChartSettings);
			} catch {}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[chartState, saveChartState]
	);

	return { adjustChartColors };
};

export const mapPaperFiltersToAPI = (filters, interval, dateFrom) => {
	return {
		interval,
		date_from: dateFrom || moment().subtract(5, 'years').startOf('month').valueOf(),
		date_to: moment().valueOf(),
		order_details: {
			preset_id: filters[Market.Paper].paperPreset?._key,
			price_type: filters[Market.Paper].priceType,
			delivery_date_from:
				filters[Market.Paper].shipment?.startDate &&
				new Date(filters[Market.Paper].shipment?.startDate).valueOf(),
			delivery_date_to:
				filters[Market.Paper].shipment?.endDate &&
				new Date(filters[Market.Paper].shipment?.endDate).valueOf(),
			continuous_date_offset:
				filters[Market.Paper].shipment?.continuousPeriod &&
				ContinuousShipmentPeriod[filters[Market.Paper].shipment.continuousPeriod],
		},
	};
};

export const mapPhysicalFiltersToAPI = (filters, interval, dateFrom) => {
	const { shipment, product, inco, grade, location, priceType, cargoType } = filters[
		Market.Physical
	];

	return {
		interval,
		date_from: dateFrom || moment().subtract(5, 'years').startOf('month').valueOf(),
		date_to: moment().valueOf(),
		order_details: {
			product_id: product?._key,
			inco_id: inco?._key,
			grade_id: grade?._key,
			price_type: priceType,
			delivery_date_from: shipment?.startDate && new Date(shipment?.startDate).valueOf(),
			delivery_date_to: shipment?.endDate && new Date(shipment?.endDate).valueOf(),
			location: {
				primary_ports_country_ids: location.countries.map(p => p._key),
				primary_ports_region_ids: location.regions.map(p => p._key),
			},
			continuous_date_offset:
				shipment?.continuousPeriod && ContinuousShipmentPeriod[shipment.continuousPeriod],
			shipment_type: cargoType && getCargoTypeFilterValues(cargoType),
		},
	};
};
