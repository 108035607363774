/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useMemo, useState } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Languages } from 'src/constants/settings';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { media } from 'src/_helpers';
import { captureError } from 'src/_helpers/error';

const LANGUAGES_TEXT = {
	en: 'language_english_shortcut',
	zh: 'language_chinese',
};

export const LanguageDropdown = ({ className }) => {
	const { t } = useTranslation();
	const LanguagesArray = useMemo(() => Object.values(Languages), []);
	const [activeLanguageCode, setActiveLanguageCode] = useState(
		() => (LanguagesArray.find(({ code }) => i18n.language === code) || Languages.English).code
	);

	const handleSwitchLanguage = useCallback(({ code, moment: languageMoment }) => {
		moment.locale(languageMoment);
		i18n.changeLanguage(code, err => {
			if (err) captureError(err, 'warning');
		});
		setActiveLanguageCode(code);
	}, []);

	return (
		<StyledDropdown text={t(LANGUAGES_TEXT[activeLanguageCode])} className={className}>
			<Dropdown.Menu>
				{LanguagesArray.map(
					language =>
						language.code !== activeLanguageCode && (
							<Dropdown.Item
								key={language.code}
								text={t(LANGUAGES_TEXT[language.code])}
								onClick={() => {
									handleSwitchLanguage(language);
								}}
							/>
						)
				)}
			</Dropdown.Menu>
		</StyledDropdown>
	);
};

const StyledDropdown = styled(Dropdown)`
	&.ui.dropdown {
		font-family: 'Poppins';
		text-transform: uppercase;
		font-weight: 500;
		font-size: 13px;
		line-height: 21px;
		letter-spacing: 0.03em;

		${media.ll`
			font-size: 15px;
			line-height: 22px;
		`}

		${media.xl`
			font-size: 18px;
			line-height: 27px;
		`}

		.dropdown.icon {
			margin-left: 7px;
			top: 1px;

			${media.ll`
				top: 0px;
			`}
		}

		.menu {
			background: transparent;

			.item {
				color: white;
				font: inherit;
				padding: 0;

				&:hover {
					background: transparent;
				}
			}
		}
	}
`;
