/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { toPath } from 'src/_helpers';
import { useCalendarSpreadFields } from '../../useCalendarSpreadFields';
import { Controller } from 'react-hook-form';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { tCurrency, SpreadPriceFormat } from 'src/constants/contract';
import * as Styled from './styled';

const SpreadPriceField = ({ path, t, lastCounter }) => {
	const { price, currency, unit } = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	const formattedCurrencyUnit = (
		<>
			<Styled.CurrencyUnitSeparator />
			<span>{`${tCurrency(t, currency)}/${t(`price_unit_short_${unit}`, {
				lng: 'en',
			})}`}</span>
		</>
	);

	const allowNegativePrice = lastCounter.spread_details.price_format === SpreadPriceFormat.Spread;

	return (
		<Controller
			name={toPath(path, 'price')}
			mandatory
			render={({ field, fieldState: { error } }) => (
				<MaterialNumberInput
					error={!!error || (!field.value && !price.disabled)}
					data-test="priceCurrencyUnit"
					label={t('spread_price')}
					required={!price.disabled}
					decimalScale={price.decimals}
					allowNegative={allowNegativePrice}
					disabled={price.disabled}
					parameters={formattedCurrencyUnit}
					controlledState
					hasChanged={price.hasChanged}
					{...field}
				/>
			)}
		/>
	);
};

export const counterSpreadPriceField = props => <SpreadPriceField {...props} />;
