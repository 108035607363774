/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { DrawerHeader } from '../Drawer/DrawerHeader/DrawerHeader';
import { useViewOrderHeader } from './helpers/useViewOrderHeader';
import { useViewOrderDrawerContext } from './ViewOrderDrawerProvider';
import { HeaderType } from '../Drawer/constants';

export const ViewOrderDrawerHeader = ({ order, negotiations }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const { type, message } = useViewOrderHeader(order, negotiations);

	const {
		orderEdited,
		orderWithdrawn,
		setOrderAndNegotiationUpToDate,
	} = useViewOrderDrawerContext();

	if (orderEdited || orderWithdrawn) {
		return (
			<DrawerHeader
				type={HeaderType.Info}
				message={t('order_was_updated')}
				onRefresh={() => {
					setOrderAndNegotiationUpToDate();
					queryClient.invalidateQueries(['order', order._key]);
				}}
			/>
		);
	}

	return <DrawerHeader type={type} message={message} />;
};
