/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { FieldContainer } from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { M100, h200, h400medium } from 'src/components/Typography';

export const CustomGradeWrapper = styled(FieldContainer)``;

export const SpecsListWrapper = styled.div``;

export const SpecsList = styled(FieldContainer)`
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 8px;
	row-gap: 8px;
`;

export const Label = styled.div`
	${h200}
	font-size: 10px;
	color: var(--neutral-100);
	padding: 3px 0;
`;

export const Value = styled.div`
	color: var(--text-color-default);
`;

export const Spec = styled.div`
	${h400medium}
	color: var(--neutral-200);
	display: flex;
	align-items: center;
	gap: 0.4em;
`;

export const SpecAdditionalInfoText = styled(M100)`
	display: inline-block;
	margin-top: 16px;
	color: var(--neutral-300);
`;
