/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { t100 } from 'src/components/Typography';

export const Wrapper = styled.div`
	background-color: var(--input-background);
	border-radius: var(--input-border-radius);
	padding: 10px 16px;

	&.disabled {
		background-color: var(--input-disabled-background);
	}

	&.error {
		border: 1px solid var(--input-error-border-color);
	}
`;

export const TextArea = styled.textarea`
	${t100}
	display: block;
	width: 100%;
	color: var(--input-text-color);
	background-color: transparent;
	border: none;
	resize: vertical;

	&.no-resize {
		resize: none;
	}

	&::placeholder {
		color: var(--input-placeholder-color);
		${t100}
	}

	&:disabled,
	&:disabled::placeholder {
		color: var(--input-disabled-text-color);
		border-color: var(--input-disabled-background);
		resize: none;
	}
`;
