/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import * as yup from 'yup';

export const articlesNewsCreateSchema = yup.object().shape({
	name: yup.string().required(),
	category_id: yup.string().required(),
	text: yup.string().required(),
	hidden: yup.boolean(),
});
