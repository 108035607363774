/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { UserStatus } from 'src/constants/user';
import { isNil } from './utils';

export const isInactiveUser = (user = {}) => {
	return !isNil(user.status) && user.status !== UserStatus.APPROVED;
};
