/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styled';

export const WelcomePopup = ({ onClick }) => {
	const { t } = useTranslation(['tour']);

	return (
		<>
			<Styled.Overlay />
			<Styled.WelcomePopup>
				<Styled.PopupContent>
					<Styled.LogoImage />
					<Styled.Header>{t('common.welcome')}</Styled.Header>
					<Styled.Info>{t('common.info')}</Styled.Info>
					<Styled.Button onClick={onClick}>{t('common.get_started')}</Styled.Button>
				</Styled.PopupContent>
			</Styled.WelcomePopup>
		</>
	);
};
