/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import ReactMarkdown from 'react-markdown';
import * as Styled from './styled';

export const TermsMarkdown = ({ terms }) => (
	<Styled.Text>
		<ReactMarkdown>{terms}</ReactMarkdown>
	</Styled.Text>
);
