/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { createTradeDocumentsBypass } from 'src/_api/trades.api';
import { useToast } from 'src/components/Toast';
import { DocumentBypassToggle } from './DocumentBypassToggle';

// this message is received when there's a DB lock caused by racing. We should ignore it
const errorMessageToIgnore = 'failed to write document bypass';

export const MyDocumentBypass = ({ tradeId, documentBypass }) => {
	const { addToast } = useToast();
	const { t } = useTranslation();

	const { mutate } = useMutation(createTradeDocumentsBypass, {
		onSettled: result => {
			if (result?.error && result.error.message !== errorMessageToIgnore) {
				addToast({
					kind: 'error',
					message: t('sending_intention_failed'),
				});
			}
		},
	});

	const handleDocumentBypassChange = value => mutate({ tradeId, documentsBypass: value });

	return (
		<DocumentBypassToggle
			documentBypass={documentBypass}
			onChange={handleDocumentBypassChange}
		/>
	);
};
