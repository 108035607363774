/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import {
	Modal,
	CancelButton,
	ButtonsWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	ModalIconWrapper,
	ModalSubTitle,
} from 'src/components/Modal';
import { NegativeButton } from 'src/components/Buttons/NegativeButton';
import { WhiteSpan } from 'src/containers/MyAccount/components/_modals/commonModalElements';
import { useToast } from 'src/components/Toast';
import { removeUserFromTrade } from 'src/_api/trades.api';
import { useTradeLocation } from 'src/_helpers/useTradeLocation';
import { ModalSize, ModalType } from 'src/constants/modal';

export const RemoveUserFromTradeModal = ({ openModal, closeModal, isModalOpen, getModalsData }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { addToast } = useToast();
	const { tradeId } = useTradeLocation();
	const { traderId, traderName } = getModalsData(0);

	const { mutate: removeUserMutation, isLoading: isRemoving } = useMutation(removeUserFromTrade, {
		onSuccess: () => {
			queryClient.refetchQueries(['trade-users']);
			queryClient.invalidateQueries(['trade', tradeId]);
			queryClient.invalidateQueries(['activity_logs', tradeId]);
			closeModal(1);
		},
		onError: () => {
			addToast({
				message: t('something_went_wrong'),
				kind: 'error',
			});
		},
	});

	return (
		<Modal
			type={ModalType.Warning}
			size={ModalSize.Tiny}
			close={() => closeModal(1)}
			open={() => openModal(1)}
			isOpen={isModalOpen(1)}
			data-test="remove-user-from-trade-modal"
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />

				<ModalTitle>{t('confirm_removing_user')}</ModalTitle>
				<StyledModalSubTitle>
					<Trans
						i18nKey="are_you_sure_to_remove_from_trade"
						components={{ span: <WhiteSpan /> }}
						values={{
							traderName,
						}}
					/>
				</StyledModalSubTitle>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={() => closeModal(1)} />
				<NegativeButton
					loading={isRemoving}
					onClick={() => removeUserMutation({ tradeId, traderId })}
				>
					{t('remove')}
				</NegativeButton>
			</ButtonsWrapper>
		</Modal>
	);
};

const StyledModalSubTitle = styled(ModalSubTitle)`
	margin-bottom: 40px;
	text-align: center;
`;
