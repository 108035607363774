/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from 'src/_api';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import * as Styled from './styled';

const filePrefix = 'files/';

export function OrderFile({ file, dataTest, className, withOwner }) {
	const { t } = useTranslation();

	let id = file._id || file.id;
	if (!id.startsWith(filePrefix)) {
		id = `${filePrefix}${id}`;
	}

	return (
		<Styled.FileContainer data-test={dataTest} className={className}>
			<Styled.FileIconWrapper>
				<Styled.FileIconSvg />
			</Styled.FileIconWrapper>
			<OverflowText>
				<a
					href={`${API_URL}/v1/download/${id}`}
					target="_blank"
					download={file.name}
					data-test={`${file.name}-${file._key}`}
					rel="noreferrer"
				>
					{file.name}
					{withOwner && file.owner && (
						<>
							<Styled.Separator>•</Styled.Separator>
							<Styled.Owner>
								<span>{t('by')}</span> {file.owner.name}
							</Styled.Owner>
						</>
					)}
				</a>
			</OverflowText>
		</Styled.FileContainer>
	);
}
