/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { arrayOfNElements } from 'src/_helpers/array';
import { Select } from 'src/designSystem/Form/Select/Select';
import { toUTCUnix } from 'src/_helpers/date';
import { allMonths } from 'src/constants/settings';

export const genFutureContractMonths = (allowedMonths = allMonths) => {
	const initialMonthFromLoadingPeriod = moment().startOf('month').toDate();
	const December = 11;

	const amountOfMonthsInTwoYearsTillDecember = Math.max(
		0,
		moment([moment(new Date()).add(2, 'years').get('year'), December]).diff(
			moment([
				moment(initialMonthFromLoadingPeriod).get('year'),
				moment(initialMonthFromLoadingPeriod).get('month'),
			]),
			'months'
		)
	);

	const availableMonthsInTwoYearsTillDecember = arrayOfNElements(
		amountOfMonthsInTwoYearsTillDecember + 1
	).map(index =>
		moment(initialMonthFromLoadingPeriod).startOf('month').add(index, 'months').toDate()
	);

	return arrayOfNElements(amountOfMonthsInTwoYearsTillDecember + 1)
		.map(index => moment(initialMonthFromLoadingPeriod).add(index, 'months').toDate())
		.filter(
			date =>
				allowedMonths.includes(date.getMonth()) &&
				availableMonthsInTwoYearsTillDecember.some(
					availableDate =>
						availableDate.getMonth() === date.getMonth() &&
						availableDate.getFullYear() === date.getFullYear()
				)
		);
};

export const FuturesContractDateDropdown = React.forwardRef(
	({ value, onChange, allowedMonths, ...props }, ref) => {
		const options = useMemo(() => {
			return genFutureContractMonths(allowedMonths).map(value => ({
				key: value.valueOf(),
				text: moment(value).format('MMM YYYY'),
				value: toUTCUnix(value),
			}));
		}, [allowedMonths]);

		const parsedValue = useMemo(() => {
			return value ? moment(value).valueOf() : null;
		}, [value]);

		useEffect(() => {
			if (parsedValue && options.length) {
				const isValueAllowed = options.some(({ value }) => value === parsedValue);

				if (!isValueAllowed) {
					onChange(undefined);
				}
			}
		}, [onChange, options, parsedValue]);

		return <Select options={options} value={parsedValue} onChange={onChange} {...props} />;
	}
);
