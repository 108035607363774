/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { Label } from '../Label/Label';
import { TextInput } from '../TextInput/TextInput';
import * as Styled from './styled';

export const MaterialTextInput = React.forwardRef(
	(
		{ label, id, required, controlledState = false, hasChanged = false, className, ...props },
		ref
	) => {
		const hasValue = !!props.value;
		const filledControlledState = controlledState === true && hasChanged === false && hasValue;
		const filledState = controlledState ? hasChanged === true : hasValue;

		return (
			<Styled.InputContainer
				className={clsx(
					'text-input',
					filledState && 'input-filled',
					filledControlledState && 'input-filled-controlled',
					className
				)}
			>
				<TextInput id={id} {...props} />
				<Label htmlFor={id}>
					{label}
					{required && <span className="asterisk">*</span>}
				</Label>
				{hasValue && <Styled.ValidCheckIcon />}
			</Styled.InputContainer>
		);
	}
);
