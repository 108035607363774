/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { loadableRetry } from '../loadableRetry';
import { ErrorBoundary } from '../ErrorBoundary';

const UserNetwork = loadableRetry(() => import('src/containers/UserNetwork/UserNetwork'), {
	resolveComponent: components => components.UserNetwork,
});

const UserNetworkContactDetails = loadableRetry(
	() => import('src/containers/UserNetwork/Contacts/UserDetails'),
	{
		resolveComponent: components => components.UserDetailsPage,
	}
);

const UserNetworkApprovedUsers = loadableRetry(
	() => import('src/containers/UserNetwork/Contacts/ApprovedUsers'),
	{
		resolveComponent: components => components.ApprovedUsersPage,
	}
);

const UserNetworkContacts = loadableRetry(
	() => import('src/containers/UserNetwork/Contacts/Contacts'),
	{
		resolveComponent: components => components.ContactsPage,
	}
);

const UserNetworkAllUsers = loadableRetry(
	() => import('src/containers/UserNetwork/Contacts/AllUsers'),
	{
		resolveComponent: components => components.AllUsersPage,
	}
);

const UserNetworkGroups = loadableRetry(
	() => import('src/containers/UserNetwork/Groups/Groups/Groups'),
	{
		resolveComponent: components => components.Groups,
	}
);

const UserNetworkGroupDetails = loadableRetry(
	() => import('src/containers/UserNetwork/Groups/GroupDetails/GroupDetails'),
	{
		resolveComponent: components => components.GroupDetails,
	}
);

const UserNetworkGroupEdit = loadableRetry(
	() => import('src/containers/UserNetwork/Groups/GroupEdit/GroupEdit'),
	{
		resolveComponent: components => components.GroupEdit,
	}
);

const UserNetworkGroupRemove = loadableRetry(
	() => import('src/containers/UserNetwork/Groups/GroupRemove/GroupRemove'),
	{
		resolveComponent: components => components.GroupRemove,
	}
);

const UserNetworkTeams = loadableRetry(
	() => import('src/containers/UserNetwork/Teams/Teams/Teams'),
	{
		resolveComponent: components => components.Teams,
	}
);

const UserNetworkTeamDetails = loadableRetry(
	() => import('src/containers/UserNetwork/Teams/TeamDetails/TeamDetails'),
	{
		resolveComponent: components => components.TeamDetails,
	}
);

const UserNetworkTeamRemove = loadableRetry(
	() => import('src/containers/UserNetwork/Teams/TeamRemove/TeamRemove'),
	{
		resolveComponent: components => components.TeamRemove,
	}
);

const UserNetworkTeamEdit = loadableRetry(
	() => import('src/containers/UserNetwork/Teams/TeamEdit/TeamEdit'),
	{
		resolveComponent: components => components.TeamEdit,
	}
);

const UserNetworkInvites = loadableRetry(
	() => import('src/containers/UserNetwork/Invites/Invites'),
	{
		resolveComponent: components => components.Invites,
	}
);

const UserNetworkSentInvites = loadableRetry(
	() => import('src/containers/UserNetwork/Invites/SentInvites/SentInvites'),
	{
		resolveComponent: components => components.SentInvites,
	}
);

const UserNetworkReceivedInvites = loadableRetry(
	() => import('src/containers/UserNetwork/Invites/ReceivedInvites/ReceivedInvites'),
	{
		resolveComponent: components => components.ReceivedInvites,
	}
);

const UserNetworkArchivedInvites = loadableRetry(
	() => import('src/containers/UserNetwork/Invites/ArchivedInvites/ArchivedInvites'),
	{
		resolveComponent: components => components.ArchivedInvites,
	}
);

export const userNetworkRoutes = {
	path: ROUTES.userNetwork,
	element: (
		<ErrorBoundary>
			<UserNetwork />
		</ErrorBoundary>
	),
	children: (
		<>
			<Route index element={<Navigate to={ROUTES.userNetworkApprovedContacts} />} />
			<Route
				path={ROUTES.userNetworkApprovedContacts}
				element={<UserNetworkApprovedUsers />}
				children={
					<Route
						path={ROUTES.userNetworkUserId}
						element={<UserNetworkContactDetails />}
					/>
				}
			/>
			<Route
				path={ROUTES.userNetworkContacts}
				element={<UserNetworkContacts />}
				children={
					<Route
						path={ROUTES.userNetworkUserId}
						element={<UserNetworkContactDetails />}
					/>
				}
			/>
			<Route path={ROUTES.userNetworkAllUsers} element={<UserNetworkAllUsers />} />
			<Route
				path={ROUTES.userNetworkGroups}
				element={<UserNetworkGroups />}
				children={
					<>
						<Route
							path={ROUTES.userNetworkGroupDetails}
							element={<UserNetworkGroupDetails />}
							children={
								<>
									<Route
										path={ROUTES.userNetworkGroupEdit}
										element={<UserNetworkGroupEdit />}
									/>
									<Route
										path={ROUTES.userNetworkGroupRemove}
										element={<UserNetworkGroupRemove />}
									/>
								</>
							}
						/>
					</>
				}
			/>
			<Route
				path={ROUTES.userNetworkTeams}
				element={<UserNetworkTeams />}
				children={
					<>
						<Route
							path={ROUTES.userNetworkTeamDetails}
							element={<UserNetworkTeamDetails />}
							children={
								<>
									<Route
										path={ROUTES.userNetworkTeamRemove}
										element={<UserNetworkTeamRemove />}
									/>
									<Route
										path={ROUTES.userNetworkTeamEdit}
										element={<UserNetworkTeamEdit />}
									/>
								</>
							}
						/>
					</>
				}
			/>
			<Route
				path={ROUTES.userNetworkInvites}
				element={<UserNetworkInvites />}
				children={
					<>
						<Route
							path={ROUTES.userNetworkInvitesReceived}
							element={<UserNetworkReceivedInvites />}
						/>
						<Route
							path={ROUTES.userNetworkInvitesSent}
							element={<UserNetworkSentInvites />}
						/>
						<Route
							path={ROUTES.userNetworkInvitesArchived}
							element={<UserNetworkArchivedInvites />}
						/>
					</>
				}
			/>
		</>
	),
};
