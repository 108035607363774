/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { CargoType, CargoTypeGroup } from 'src/constants/contract';

export const getCargoTypeFilterValues = (values = []) => {
	return values.reduce((result, item) => {
		if (item === CargoTypeGroup.Container) {
			result.push(CargoType.Container20, CargoType.Container40, CargoType.ContainerOther);
		} else {
			result.push(item);
		}
		return result;
	}, []);
};
