/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { acceptCounter } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useTogglable } from 'src/_helpers/useTogglable';
import { isExpired } from 'src/_helpers/date';
import {
	Modal,
	CancelButton,
	ButtonsWrapper,
	ModalHeaderWrapper,
	ModalIconWrapper,
	ModalSubTitle,
} from 'src/components/Modal';
import { ModalType, ModalSize } from 'src/constants/modal';
import { PositiveButton } from 'src/components/Buttons/PositiveButton';
import { AcceptPaperCounterSummary } from './AcceptPaperCounterSummary';
import { AcceptPhysicalCounterSummary } from './AcceptPhysicalCounterSummary';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { tLastCounterType, Market, PaperInstruments } from 'src/constants/contract';
import { useViewOrderDrawerContext } from 'src/components/ViewOrderDrawer/ViewOrderDrawerProvider';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { isFooterTabActive, FooterTabs } from 'src/_helpers/session';
import * as Styled from './styled';

export const AcceptCounterModal = ({ order, counters, trigger, negotiationId }) => {
	const lastCounter = counters?.[counters.length - 1];
	const { t } = useTranslation();
	const [isOpen, { open, close }] = useTogglable();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const userId = useSelector(getUserId);
	const isSpread = order.instrument === PaperInstruments.Spread;

	const { isMyOrder, isHiddenOrder } = useOrderDetails(order);

	const { setAccepteeId } = useViewOrderDrawerContext();

	const shouldDisplayAlternativeSubheading =
		isMyOrder && isHiddenOrder && !lastCounter.is_identity_revealed;

	const subheadingTranslationKey = shouldDisplayAlternativeSubheading
		? isSpread
			? 'confirm_accepting_spread_counter_hidden'
			: 'confirm_accepting_counter_hidden'
		: isSpread
		? 'confirm_accepting_spread_counter_info'
		: 'confirm_accepting_counter';

	const { mutate, isLoading } = useMutation(acceptCounter, {
		onSuccess: async ({ error }) => {
			if (error && isExpired(lastCounter.validity)) {
				addToast({
					message: t('counter_expired_counter_to_continue', {
						counterType: tLastCounterType(t, order, lastCounter),
					}),
					kind: 'info',
				});
			} else {
				if (order.market === Market.Physical) {
					await queryClient.invalidateQueries(['physical-orders']);
				}

				if (isFooterTabActive(FooterTabs.MyOrders)) {
					await queryClient.invalidateQueries(['my-orders']);
				}

				if (isFooterTabActive(FooterTabs.MyNegotiations)) {
					await queryClient.invalidateQueries(['my-negotiations']);
				}

				if (isFooterTabActive(FooterTabs.MyNegotiations)) {
					await queryClient.invalidateQueries(['my-negotiations']);
				}

				if (isFooterTabActive(FooterTabs.MyNegotiationHistory)) {
					await queryClient.invalidateQueries(['my-negotiation-history']);
				}

				if (isFooterTabActive(FooterTabs.MyTrades)) {
					await queryClient.invalidateQueries(['my-trades']);
				}

				await queryClient.invalidateQueries(['order', order._key]);
				await queryClient.invalidateQueries([
					'order_with_counters',
					order._key,
					negotiationId,
				]);
			}

			close();
		},
		onError: () => {
			addToast({
				message: t('accept_counter_failed'),
				kind: 'error',
			});

			close();
		},
	});

	const handleAcceptSubmit = () => {
		setAccepteeId(userId);
		mutate({
			id: lastCounter._key,
			environment: lastCounter.environment,
		});
	};

	return (
		<Modal
			data-test="accept-counter"
			size={ModalSize.Tiny}
			type={ModalType.Success}
			open={open}
			close={close}
			isOpen={isOpen}
			trigger={trigger}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<Styled.SummaryModalTitle>
					{t('confirm_accepting_a_counter')}
				</Styled.SummaryModalTitle>
				<ModalSubTitle>
					{t(subheadingTranslationKey, {
						counterparty: lastCounter.user.name,
					})}
				</ModalSubTitle>
			</ModalHeaderWrapper>
			<Styled.SummaryModalContent>
				<AcceptCounterModalContent order={order} counters={counters} />
			</Styled.SummaryModalContent>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<PositiveButton
					loading={isLoading}
					data-test="submit-accept-counter"
					onClick={handleAcceptSubmit}
				>
					{t('accept')}
				</PositiveButton>
			</ButtonsWrapper>
		</Modal>
	);
};

const AcceptCounterModalContent = ({ order, counters }) => {
	const { isPaperOrder } = useOrderDetails(order);

	if (isPaperOrder) {
		return <AcceptPaperCounterSummary order={order} counters={counters} />;
	}
	return <AcceptPhysicalCounterSummary order={order} counters={counters} />;
};
