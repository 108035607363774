/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

//helpers
import { media } from 'src/_helpers/media-queries';
import { h700, h400, t150, t200 } from 'src/components/Typography/fonts';

export const InfoWrapper = styled.ul`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 16px;
	${h400}
	h1 {
		margin-right: 23px;
		${media.xl`
		  margin-right: 42px;
    `}
	}
`;

export const TradeInfoWrapper = styled.ul`
	display: flex;
	flex-wrap: wrap;
	${h400}
`;

export const InfoItem = styled.li`
	margin-right: 23px;
	margin-bottom: 16px;

	span {
		margin-right: 8px;
		color: var(--neutral-300);
		${t150}
	}

	${media.xl`
        margin-right: 42px;
    `}
`;

export const InfoItemColumn = styled.li`
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	margin-right: 16px;
	width: calc((100% / 3) - 16px);

	${media.ll`
		width: calc((100% / 4) - 16px);
	`}

	${media.xl`
		width: calc((100% / 5) - 16px);
		margin-right: 22px;
	`}
`;

export const Type = styled.span`
	color: var(--neutral-300);
	margin-bottom: 8px;
	${t200}
	font-size: 14px;
	line-height: 16px;

	${media.xl`
		font-size: 15px;
		line-height: 18px;
	`}
`;

export const Info = styled.span`
	${h400}
	font-size: 14px;
	line-height: 16px;

	${media.xl`
		font-size: 15px;
		line-height: 18px;
	`}
`;

export const InfoTitle = styled.div`
	${h700}
	font-size: 18px;
	margin-bottom: 24px;
`;

export const SpecName = styled.div`
	${t150}
	color: var(--neutral-300);
	margin-bottom: 11px;
`;

export const SpecValue = styled.div`
	display: flex;
`;

export const SpecLabel = styled.span`
	${t150}
	color: var(--neutral-300);
	margin-right: 8px;
`;
