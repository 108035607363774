/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import loaderImage from 'src/assets/icons/loader.png';
import { ImageContainer } from '../ImageContainer/ImageContainer';

export const LoaderSpinning = () => {
	return <LoaderWrapper src={loaderImage} />;
};

export const LoaderWrapper = styled(ImageContainer)`
	animation-name: spin;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

export const Header = styled.h2`
	margin: 0 auto 26px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.2px;
	color: var(--login-header-color);
`;

export const FormRow = styled.div`
	position: relative;
	padding-bottom: 32px;
`;

export const RegisterFormRow = styled.div`
	position: relative;
	padding-bottom: 10px;
`;

export const ErrorMessage = styled.div`
	font-family: var(--font-base);
	font-size: 10px;
	color: var(--red-400);
	position: absolute;
	bottom: 10px;
	left: 0;

	&::before {
		content: '+';
		display: inline-block;
		transform: rotate(-45deg) translateX(-2px);
		font-size: 16px;
		font-weight: 500;
	}
`;

export const Form = styled.form`
	@media screen and (min-width: 767px) {
		position: relative;
		top: auto;
		left: auto;
		width: 100%;
		margin: 0 auto 30px;
		min-width: 90%;
	}
`;
