/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmailFormatValid } from 'src/_helpers/email';
import { useSelector } from 'react-redux';
import { getUserEmail } from 'src/_store/selectors';
import { useMutation, useQueryClient } from 'react-query';
import { sendInvite } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { InviteViaEmailCardComponent } from './InviteViaEmailCardComponent';
import { InviteViaEmailModalContent } from './InviteViaEmailModalContent';

export const Error = {
	InvalidEmail: 'invalid_email',
	PendingInvitationOrAlreadyContact: 'invite_contact_error_message',
	InvitingSelf: 'you_cannot_add_yourself_as_contact',
};

const noop = () => {};

export const InviteViaEmailCard = ({ close = noop, isModal = false }) => {
	const { t } = useTranslation();
	const [error, setError] = useState(null);
	const emailRef = useRef();
	const userEmail = useSelector(getUserEmail);
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const { isLoading, mutate } = useMutation(sendInvite, {
		onSuccess: data => {
			if (!!data?.added) {
				emailRef.current.value = '';
				setError(null);
				addToast({ message: t('invite_sent') });
				queryClient.refetchQueries(['user-network-invites-sent']);
				queryClient.refetchQueries(['user-network-invites-received']);
			}

			if (data?.errors?.pending_invitations || data?.errors?.already_contact) {
				setError(Error.PendingInvitationOrAlreadyContact);
			}

			if (isModal) {
				close();
			}
		},
		onError: () => {
			addToast({
				kind: 'error',
				message: t('something_went_wrong'),
			});
			setError(null);
		},
	});

	const sendInvitation = () => {
		const email = emailRef.current.value;

		if (!email || !isEmailFormatValid(email)) {
			setError(Error.InvalidEmail);
			return;
		}

		if (email.toLowerCase().trim() === userEmail.toLowerCase().trim()) {
			setError(Error.InvitingSelf);
			return;
		}

		mutate({ emails: [email] });
	};

	const contentComponentProps = {
		onInvitationSend: sendInvitation,
		isLoading,
		error,
		ref: emailRef,
	};

	return isModal ? (
		<InviteViaEmailModalContent {...contentComponentProps} close={close} />
	) : (
		<InviteViaEmailCardComponent {...contentComponentProps} />
	);
};
