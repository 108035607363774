/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { createContext, useContext } from 'react';

export const TradeDataContext = createContext({
	tradeData: {},
	isLoadingTradeDetails: false,
	tradeId: null,
	accountUserId: null,
	tradeStringData: [],
	isLoadingTradeString: false,
	tradeStep: null,
	stepCompleted: null,
	counterpartiesUserName: null,
	tradeMarket: null,
	circleOutSourceTradeId: null,
});

export const useTradeDataContext = () => {
	return useContext(TradeDataContext);
};
