/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { QuoteType } from 'src/constants/contract';
import { defaultFirmTimeValue, defaultIndicativeTimeValue } from '../model';
import { getCurrentTzOrDefault } from 'src/components/DateTimeTzPicker';
import { getTimeOptionMinutes } from 'src/_helpers/timeRange';

export const getDefaultValidity = (
	orderTypeValue,
	presetValidities = { firm: defaultFirmTimeValue, indicative: defaultIndicativeTimeValue }
) => {
	const timeValue =
		orderTypeValue === QuoteType.Firm ? presetValidities.firm : presetValidities.indicative;

	return {
		tz: getCurrentTzOrDefault(),
		localDate: moment().add(getTimeOptionMinutes(timeValue), 'minutes').toDate(),
		time: timeValue,
	};
};
