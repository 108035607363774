/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { h500 } from 'src/components/Typography/fonts';
import { Button } from '../Buttons/Button';
import { ToastPrimaryButton } from '../Buttons/ToastButtons/ToastPrimaryButton';
import { ToastNegativeButton } from '../Buttons/ToastButtons/ToastNegativeButton';
import { ToastWarningButton } from '../Buttons/ToastButtons/ToastWarningButton';
import { ToastPositiveButton } from '../Buttons/ToastButtons/ToastPositiveButton';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { H400 } from '../Typography';

export const ToastContent = styled.div`
	padding: 12px 56px;
	${props => props.$embeded && `border-radius: var(--default-border-radius);`}
	max-height: 50px;
	height: 50px;
	color: ${({ theme }) => theme?.color || 'inherit'};
	background: ${({ theme }) => theme.background};
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.4);

	& > svg {
		flex-shrink: 0;
	}
`;

const onboardingPageToast = css`
	--_height: 68px;
	top: 0;
	z-index: var(--z-index-top-most);
	height: var(--_height);

	> div {
		height: var(--_height) !important;
		max-height: var(--_height) !important;
	}
`;

export const ToastContainer = styled.div`
	right: 0;
	left: 0;
	position: fixed;
	top: 56px;
	// errors are above all other toasts
	z-index: ${({ $kind, $modal }) =>
		$kind === 'error'
			? $modal === true
				? `var(--z-index-toast-modal)`
				: `var(--z-index-toast-error)`
			: $modal === true
			? `var(--z-index-toast-modal)`
			: `var(--z-index-toast-temporary)`};
	animation: fadein 300ms;
	${({ $topPage }) => $topPage && onboardingPageToast};
`;

export const ToastMessage = styled(H400)`
	margin-left: 16px;
	z-index: var(--z-index-toast-temporary);
`;

export const StyledCloseIcon = styled(CloseIcon)`
	color: ${({ theme }) => theme.closeIconColor};
	width: var(--icon-small);
	height: var(--icon-small);
`;

export const CloseButton = styled(Button)`
	position: absolute;
	top: 50%;
	right: 1vw;
	transform: translateY(-50%);
	width: auto;
`;

const toastButtonStyle = css`
	width: 148px;
	height: 36px;
	margin-left: 18px;
	${h500};
`;

export const StyledPrimaryButton = styled(ToastPrimaryButton)`
	${toastButtonStyle}
`;

export const StyledNegativeButton = styled(ToastNegativeButton)`
	${toastButtonStyle}
`;

export const StyledPositiveButton = styled(ToastPositiveButton)`
	${toastButtonStyle}
`;

export const StyledWarningButton = styled(ToastWarningButton)`
	${toastButtonStyle}
`;
