/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import deepEqual from 'fast-deep-equal';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { FlagNames } from 'src/constants/flags';
import { ROUTES } from 'src/constants/routes';
import { Permission } from 'src/containers/Permissions/Permission';
import { Permissions } from 'src/constants/permissions';
import { useActiveMarket } from 'src/_routes/useActiveMarket';
import { useFeatureFlags } from 'src/featureFlags/FeatureFlagsContext';
import { Market } from 'src/constants/contract';
import { BlockNavigationLink } from 'src/containers/BlockNavigationProvider/BlockNavigationLink';
import { LinkTypes } from 'src/components/Link/Link';

const menuItems = (t, market) => [
	{
		to: ROUTES.overview,
		name: t('overview'),
		featureFlag: FlagNames.OverviewPage,
	},
	{
		to: ROUTES.root,
		name: t('markets'),
	},
	{
		to: ROUTES.execution,
		name: t('execution'),
	},
	{
		to: ROUTES.teamsDashboard,
		name: t('teams'),
		disableRule: market === Market.Paper,
	},
	{
		to: ROUTES.userNetwork,
		name: t('network'),
	},
	{
		to: ROUTES.kyc,
		name: t('KYC'),
		featureFlag: FlagNames.KYC,
		permission: Permissions.KYC_VIEW,
	},
	{
		to: ROUTES.news,
		name: t('news'),
		featureFlag: FlagNames.News,
	},
	{
		to: ROUTES.guidebook,
		name: t('guide'),
		featureFlag: FlagNames.Guidebook,
	},
];

export const NavigationLinks = () => {
	const { t } = useTranslation();
	const market = useActiveMarket();
	const { isFlagEnabled } = useFeatureFlags();

	const items = menuItems(t, market)
		.map(i => {
			const { featureFlag, featureFlagExclusionRule, disableRule, ...item } = i;

			if (disableRule) {
				return false;
			}

			if (!featureFlag || featureFlagExclusionRule || isFlagEnabled(featureFlag) === true) {
				return item;
			}

			return false;
		})
		.filter(item => item);

	return <NavigationListItems items={items} />;
};

const ListItems = ({ items }) =>
	items.map(item => {
		const menuItem = (
			<Menu.Item
				as={BlockNavigationLink}
				key={item.name}
				name={item.name}
				to={item.to}
				type={LinkTypes.NAVLINK}
			/>
		);

		if (item.permission) {
			return <Permission key={item.name} name={item.permission} renderOn={() => menuItem} />;
		}

		return menuItem;
	});

const NavigationListItems = React.memo(ListItems, deepEqual);
