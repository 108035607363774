/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';

export const useSpecificationsSectionOptions = () => {
	const { gradeValue, isCustomGrade, specsList, customSpecsValue } = useWatchPhysicalFields();

	return {
		gradeValue,
		isCustomGrade,
		specsList,
		customSpecsValue,
	};
};
