/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from 'src/designSystem/Form/TextArea/TextArea';
import { tTradeDocumentType, TradeDocumentType } from 'src/constants/trade-document-type';
import { ReactComponent as DocumentRequestIcon } from 'src/assets/icons/document-request.svg';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { Checkbox } from 'src/designSystem/Form/Checkbox/Checkbox';
import {
	Modal,
	ButtonsWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	CancelButton,
} from 'src/components/Modal';
import { ModalSize } from 'src/constants/modal';
import * as Styled from './styled';

export const DocumentRequestModal = ({
	trigger,
	open,
	close,
	isOpen,
	onDocumentClick,
	blockedTypes,
	setNotes,
	hasRequestedNotes,
	isLoading,
	onSubmit,
	canSubmit,
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			data-test="request-documents-modal"
			size={ModalSize.Large}
			close={close}
			open={open}
			isOpen={isOpen}
			trigger={trigger}
		>
			<ModalHeaderWrapper>
				<Styled.IconWrapper>
					<DocumentRequestIcon />
				</Styled.IconWrapper>
				<ModalTitle>{t('request_documents_title')}</ModalTitle>
			</ModalHeaderWrapper>
			<Styled.Subtitle>{t('please_select_requested_documents')}</Styled.Subtitle>
			<Styled.DocumentTypesGrid>
				{Object.values(TradeDocumentType).map((type, index) => {
					const isDocumentAlreadyRequested = blockedTypes.includes(type);

					return (
						<Checkbox
							key={type}
							label={tTradeDocumentType(t, type)}
							name="document-type"
							data-test={`document-type-${index}`}
							value={type}
							disabled={isDocumentAlreadyRequested}
							defaultChecked={isDocumentAlreadyRequested}
							onChange={onDocumentClick}
						/>
					);
				})}
			</Styled.DocumentTypesGrid>
			{!hasRequestedNotes ? (
				<Styled.NotesWrapper>
					<TextArea
						placeholder={t('instruction_placeholder_textarea')}
						data-test="request-documents-notes"
						resize={false}
						onChange={event => setNotes(event.target.value)}
					/>
				</Styled.NotesWrapper>
			) : (
				<Styled.NotesSeparator />
			)}
			<ButtonsWrapper>
				<CancelButton onClick={close} data-test="cancel-button" />
				<PrimaryButton
					loading={isLoading}
					onClick={onSubmit}
					data-test="request-documents-button"
					disabled={!canSubmit}
				>
					{t('save')}
				</PrimaryButton>
			</ButtonsWrapper>
		</Modal>
	);
};
