/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from 'src/designSystem/Form/ToggleButton/ToggleButton';
import * as Styled from './styled';

const documentsBypassLabel = {
	true: 'yes',
	false: 'no',
};

export const DocumentBypassToggle = ({ documentBypass, onChange }) => {
	const [value, setValue] = useState(documentBypass);
	const { t } = useTranslation();

	const handleChange = useCallback(
		newValue => {
			if (newValue !== value) {
				setValue(newValue);
				onChange(newValue);
			}
		},
		[onChange, value]
	);

	return (
		<Form>
			<Styled.FormField>
				<ToggleButton
					className="toggle-button"
					dataTest="trade-documents-bypass"
					options={Object.keys(documentsBypassLabel).map(value => ({
						text: t(documentsBypassLabel[value]),
						value,
					}))}
					value={`${value}`}
					onChange={handleChange}
					buttonSizes={[55, 50]}
				/>
			</Styled.FormField>
		</Form>
	);
};
