/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';

export const KYCButton = styled(SecondaryButton)`
	width: 127px;
`;
