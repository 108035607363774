/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
	SectionContainer,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionTitle,
} from '../../../styled';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { CreatedAtText } from '../../AdditionalInformationSection/styled';
import { CopyOrderNegotiationLink } from 'src/components/ViewOrderDrawer/components/CopyOrderNegotiationLink/CopyOrderNegotiationLink';

export const AdditionalInformationSection = ({ order, changes }) => {
	const { t } = useTranslation();

	const createdDate = moment(order.created_at).format('D MMMM YYYY, h:mm A zz');
	const updatedDate =
		!order.version || order.version === 1
			? null
			: moment(order.version_created_at).format('D MMMM YYYY, h:mm A zz');

	const isTraded = !!order.trade_id;
	const isCounter =
		order._id?.startsWith('counter_orders/') || !!changes || order.is_counter_preview;

	return (
		<SectionContainer data-test="additional-information-section">
			<SectionTitle>{t('additional_information')}</SectionTitle>
			<SectionGrid>
				<CopyOrderNegotiationLink order={order} isCounter={isCounter} />
				<SectionGridContent>
					<SectionGridLabel>{isTraded ? t('trade_date') : t('created')}</SectionGridLabel>
					<CreatedAtText data-test="additional-information-section-created-at">
						{!!updatedDate ? <OverflowText>{createdDate}</OverflowText> : createdDate}
					</CreatedAtText>
				</SectionGridContent>
				{!!updatedDate && (
					<SectionGridContent>
						<SectionGridLabel>{t('edited')}</SectionGridLabel>
						<CreatedAtText data-test="additional-information-section-updated-at">
							<OverflowText>{updatedDate}</OverflowText>
						</CreatedAtText>
					</SectionGridContent>
				)}
			</SectionGrid>
		</SectionContainer>
	);
};
