/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { API_URL } from './model';

export const getWebGuidebook = async ({ version }) => {
	try {
		const response = await fetch(
			API_URL +
				`/cms/api/guidebooks?filters[version][$eq]=${version}&pagination[page]=1&pagination[pageSize]=1&populate[chapters][populate]=*`,
			{
				method: 'GET',
				credentials: 'include',
			}
		);

		const chapters = await response
			.json()
			.then(response => response.data[0]?.attributes.chapters.map(chaptersMapper) || []);

		return chapters;
	} catch (e) {
		console.log('Error:', e);
		throw e;
	}
};

export const getVideoGuide = async ({ version }) => {
	try {
		const response = await fetch(
			API_URL +
				`/cms/api/mobile-app-video-tutorials?filters[version][$eq]=${version}&pagination[page]=1&pagination[pageSize]=1&populate[chapters][populate]=*`,
			{
				method: 'GET',
				credentials: 'include',
			}
		);

		const chapters = await response
			.json()
			.then(response => response.data[0]?.attributes.chapters.map(chaptersMapper) || []);

		return chapters;
	} catch (e) {
		console.log('Error:', e);
		throw e;
	}
};

const titleToKebabCase = string =>
	string
		.toLowerCase()
		.replace(/[^a-zA-Z0-9\s]/g, '')
		.replaceAll(' ', '-');

const chaptersMapper = ({ subChapters, title, ...restProps }) => {
	const mappedSubchapters = subChapters.map(
		({ title: subChapterTitle, ...restSubChapterProps }) => ({
			title: subChapterTitle,
			anchor: `${titleToKebabCase(title)}_${titleToKebabCase(subChapterTitle)}`,
			...restSubChapterProps,
		})
	);

	return {
		title,
		anchor: titleToKebabCase(title),
		subChapters: mappedSubchapters,
		...restProps,
	};
};
