/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState, useCallback } from 'react';

export function useTogglable(initialState = false) {
	const [isOpen, setOpen] = useState(initialState);
	const open = useCallback(() => setOpen(true), [setOpen]);
	const close = useCallback(() => setOpen(false), [setOpen]);
	const toggle = useCallback(() => setOpen(open => !open), [setOpen]);

	return [isOpen, { open, close, toggle }];
}
