/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { Environment, Market, OrderType, PriceType, Role } from 'src/constants/contract';
import { OrderStatus } from 'src/constants/orderStatus';
import { findSelectedFuturesContractObject } from './futuresContracts';

export const useOrderDetails = order => {
	const currentUserId = useSelector(getUserId);

	const isBrokerOrder = order.role === Role.Broker;
	const isBrokerUser = isBrokerOrder && order.user_id === currentUserId;
	const isMyOrder = order.is_my_order;
	const isBasisPrice = order.price_type === PriceType.Basis;
	const isSellOrder = order.order_type === OrderType.Sell;
	const isHiddenOrder = order.hidden;
	const isIdentityRevealed = order.is_identity_revealed;
	const isOTCOrder = order.environment === Environment.OTC;
	const isPaperOrder = order.market === Market.Paper;
	const isMyTeamOrder = !!order.is_my_team_order;
	const isMyContactOrder = !!order.is_my_contact_order;
	const isIndicativeOrder = order.is_indicative;
	const isTeamOrderAndInRecipientsList =
		isMyTeamOrder && order?.recipients?.includes(currentUserId);
	const isOrderReceivedByTeamMember = !!order.is_received_by_teammate;
	const isTeamOTC = isOrderReceivedByTeamMember || isMyTeamOrder;
	const isTeamMemberOrder = isOrderReceivedByTeamMember
		? !isOrderReceivedByTeamMember
		: isTeamOrderAndInRecipientsList;
	const isActionButtonVisible = isOTCOrder && isTeamOTC ? isTeamMemberOrder : true;
	const isMyCompanyOrder = order.is_my_company_order;
	const isTraded = order.is_traded === true;
	const isFirmOrder = order.is_indicative === false;
	const isExpired = moment(order.validity).isBefore(Date.now());
	const mutualContactApproval = order.mutual_contact_approval;

	// Status
	const isTerminatedOrNotAvailable = [OrderStatus.Terminated, OrderStatus.NotAvailable].includes(
		order.status
	);
	const isOrderNotAvailable = order.status === OrderStatus.NotAvailable;

	const canWithdraw = isMyOrder && !isTerminatedOrNotAvailable && !isExpired;
	// for now it seems that the rules for editing are exactly the same as for withdrawing, but let's create separate flag in case of future changes
	const canEdit = canWithdraw;

	const visibleOrderOrIdentityRevealed = isIdentityRevealed || !isHiddenOrder;
	const canChat = isMyContactOrder && visibleOrderOrIdentityRevealed;

	const futuresContract = isBasisPrice ? findSelectedFuturesContractObject(order) : null;

	const orderOwnerPrincipal = order.order_owner_principal;

	const user = order.user;

	return {
		isBrokerOrder,
		isBrokerUser,
		isMyOrder,
		isBasisPrice,
		isSellOrder,
		isHiddenOrder,
		isIdentityRevealed,
		isOTCOrder,
		isPaperOrder,
		isMyTeamOrder,
		isActionButtonVisible,
		isOrderReceivedByTeamMember,
		isMyCompanyOrder,
		isExpired,
		isTerminatedOrNotAvailable,
		canWithdraw,
		canEdit,
		isTraded,
		isFirmOrder,
		isOrderNotAvailable,
		isMyContactOrder,
		isIndicativeOrder,
		canChat,
		futuresContract,
		mutualContactApproval,
		orderOwnerPrincipal,
		user,
	};
};
