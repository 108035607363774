/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useSettings } from 'src/components/Menus/UserProfileDropdown/useSettings';
import { usePermissionContext } from '../Permissions/PermissionsContext';
import { Permissions } from 'src/constants/permissions';

export const useAppTour = (version, shouldUpdateLastSeenVersion) => {
	const { lastTourVersionSeen, isLoading, saveLastSeenTourVerions } = useSettings();
	const { hasPermission } = usePermissionContext();

	const hasAlreadySeenTour = lastTourVersionSeen >= version;

	useEffect(() => {
		if (!isLoading && !hasAlreadySeenTour && shouldUpdateLastSeenVersion) {
			saveLastSeenTourVerions(version);
		}
	}, [
		hasAlreadySeenTour,
		isLoading,
		saveLastSeenTourVerions,
		version,
		shouldUpdateLastSeenVersion,
	]);

	return isLoading ? false : !hasAlreadySeenTour && hasPermission(Permissions.ORDER_CREATE);
};
