/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled, { css } from 'styled-components';
import { h400 } from 'src/components/Typography';
import { media } from 'src/_helpers';

export const StepItemWrapper = styled.div`
	border-radius: 4px;
	background: var(--neutral-850);
	color: var(--neutral-300);
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	height: 48px;
	padding: 15px 22px 15px 16px;
	${h400}

	${media.ll`
		padding: 15px 26px 15px 20px;
	`}

	${media.xl`
		padding: 16px 32px 16px 24px;
		height: 56px;
	`}

	&:hover {
		background: var(--neutral-800);
		cursor: pointer;
	}

	${({ $isActive }) =>
		$isActive &&
		css`
			color: var(--text-color-default);
			background: var(--neutral-800);
		`}
`;

export const ActiveBar = styled.div`
	position: absolute;
	left: 0;
	height: 100%;
	width: 3px;
	background: var(--primary-400);
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
`;
