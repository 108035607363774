/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { WebsocketChannelType } from 'src/constants/websockets';
import { getUserId } from 'src/_store/selectors';
import { useWebsocketsContext } from './WebsocketsProvider';

export const useWebsocket = (channel, topic, callback) => {
	const userId = useSelector(getUserId);
	const { ablyInstance } = useWebsocketsContext();

	const channelName =
		channel === WebsocketChannelType.Private && userId ? `user:${userId}` : channel;

	useEffect(() => {
		if (ablyInstance == null) {
			return;
		}

		const channel = ablyInstance.channels.get(channelName);

		if (Array.isArray(topic)) {
			topic.forEach(topicItem => {
				channel.subscribe(topicItem, callback);
			});
		} else {
			channel.subscribe(topic, callback);
		}

		return () => {
			if (Array.isArray(topic)) {
				topic.forEach(topicItem => {
					channel.unsubscribe(topicItem, callback);
				});
			} else {
				channel.unsubscribe(topic, callback);
			}
		};
	}, [ablyInstance, channelName, topic, callback]);
};
