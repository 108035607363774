/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useToast } from 'src/components/Toast';
import { addUsersToTrade } from 'src/_api/trades.api';
import { media } from 'src/_helpers/media-queries';
import { h600, t100 } from 'src/components/Typography';
import {
	CancelButton,
	ButtonsWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	ModalIconWrapper,
} from 'src/components/Modal';
import { ModalContent } from './CommonComponents';
import { ModalType } from 'src/constants/modal';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';

export const UsersSummaryModalContent = ({ close, selectedUsers, tradeId, onSubmit }) => {
	const { t } = useTranslation();
	const { addToast } = useToast();

	const { mutate, isLoading: isMutationInProgress } = useMutation(addUsersToTrade, {
		onSuccess: () => {
			const count = selectedUsers.length;
			addToast({
				message:
					count === 1
						? t('1_user_added_to_trade')
						: t('n_users_added_to_trade', { count }),
				kind: 'success',
			});
			onSubmit?.();
		},
		onError: () => {
			close();
			addToast({
				message: t('something_went_wrong'),
				kind: 'error',
			});
		},
	});

	const handleUsersListSubmit = useCallback(() => {
		mutate({
			tradeId,
			users: selectedUsers.map(user => user.contact_user_id),
		});
	}, [mutate, selectedUsers, tradeId]);

	const usersCount = selectedUsers.length;
	const modalTitle =
		usersCount === 1 ? t('confirm_one_user') : t('confirm_n_users', { usersCount });

	const modalSubTitle =
		usersCount === 1
			? t('add_user_to_trade_summary_modal_subtitle')
			: t('add_users_to_trade_summary_modal_subtitle');

	return (
		<>
			<StyledModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{modalTitle}</ModalTitle>
				<ModalSubTitle>{modalSubTitle}</ModalSubTitle>
			</StyledModalHeaderWrapper>
			<ScrollWrapper className="users-list">
				{selectedUsers
					.sort((a, b) => (a.lowerCaseName < b.lowerCaseName ? -1 : 1))
					.map(user => (
						<div key={user.contact_user_id}>
							<CompanyName>{user.company_name}</CompanyName>
							<UserName>{user.name}</UserName>
						</div>
					))}
			</ScrollWrapper>
			<ButtonsWrapper>
				<CancelButton text={t('back')} onClick={close} />
				<PrimaryButton
					loading={isMutationInProgress}
					data-test="submit-users-to-trade"
					onClick={handleUsersListSubmit}
				>
					{usersCount === 1 ? t('add_user') : `${t('add_users')} (${usersCount})`}
				</PrimaryButton>
			</ButtonsWrapper>
		</>
	);
};

const ScrollWrapper = styled(ModalContent)`
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 198px;

	${media.xl`
		max-height: 358px;
  `}

	> div {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 18px;
		padding: 0 23px;

		> div {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

const CompanyName = styled.div`
	${t100}
	color: var(--neutral-300);
	font-size: 14px;
	line-height: 21px;
`;

const UserName = styled.div`
	${h600}
	font-size: 14px;
	color: #fff;
`;

const ModalSubTitle = styled.div`
	${t100}
	color: var(--neutral-300);
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	padding-bottom: 4px;
`;

const StyledModalHeaderWrapper = styled(ModalHeaderWrapper)`
	padding: 0 12px;
`;
