/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Button } from './Button';
import styled from 'styled-components';

export const StrokeButton = styled(Button)`
	background: transparent;
	border: 2px solid var(--neutral-600);

	&:hover {
		background: transparent;
		border-color: var(--primary-200);
		color: var(--white);
	}

	&:active,
	&.active,
	&:focus,
	&.loading {
		background: transparent;
		border-color: var(--primary-400);
		color: var(--white);
	}

	&.loading {
		background: transparent;
		color: var(--primary-400);
	}

	&.disabled,
	&:disabled {
		background: transparent;
		border-color: var(--neutral-800);

		svg {
			color: var(--neutral-300);
		}
	}
`;
