/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useSelector } from 'react-redux';
import { CounterStatus } from 'src/constants/counterStatus';
import { isExpired } from 'src/_helpers/date';
import { getUserId } from 'src/_store/selectors';
import { isNilOrEmpty } from 'src/_helpers';
import { IncoType, Market } from 'src/constants/contract';
import { PKPG_PRODUCT_ID } from 'src/constants/product';

export const useCounterFooter = ({ order, counter, isLastCounter }) => {
	const userId = useSelector(getUserId);
	const isMyCounter = counter.user_id === userId;
	const lastCounter = isLastCounter ? counter : null;

	const isTraded =
		lastCounter &&
		counter.status === CounterStatus.Confirmed &&
		!isNilOrEmpty(lastCounter?.trade_id);

	const isNotAvailable = counter.status === CounterStatus.NotAvailable;

	const canCounter =
		isLastCounter &&
		!isMyCounter &&
		!isTraded &&
		(counter.status === CounterStatus.Active ||
			counter.status === CounterStatus.Expired ||
			isExpired(order.validity)) &&
		counter.status !== CounterStatus.Confirmed;

	const canAccept =
		isLastCounter &&
		!isMyCounter &&
		!counter.is_indicative &&
		!isExpired(counter.validity) &&
		counter.status !== CounterStatus.Terminated &&
		counter.status !== CounterStatus.NotAvailable &&
		!isTraded;

	const canFirmUp = counter.is_indicative && canCounter;

	const isRenduCounter = lastCounter?.inco_id === IncoType.RENDU;
	const isPKPGOrder =
		lastCounter?.product_id === PKPG_PRODUCT_ID && lastCounter?.market === Market.Paper;
	const isUnavailableTrade = isPKPGOrder || isRenduCounter;

	return {
		canAccept,
		canCounter,
		canFirmUp,
		isTraded,
		isNotAvailable,
		isUnavailableTrade,
		isPKPGOrder,
		isRenduCounter,
		lastCounter,
	};
};
