/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { SummaryRow } from 'src/containers/Order/common/OrderSummaryModal/SummaryRow';
import { useAcceptPaperOrderSummary } from './useAcceptPaperOrderSummary';

export const AcceptPaperOrderSummary = ({ order }) => {
	const { dataToDisplay } = useAcceptPaperOrderSummary(order);

	return dataToDisplay.map(({ title, value }, index) => (
		<SummaryRow key={index} title={title} value={value} />
	));
};
