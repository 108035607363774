/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import { MaterialMultiSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialMultiSelectInput';
import { FoldableSection } from '../../../components/FoldableSection/FoldableSection';
import { PortDropdown } from '../../../components/PortDropdown/PortDropdown';
import { useQuoteSectionOptions } from './useQuoteSectionOptions';
import { ProductDropdown } from '../../../components/ProductDropdown/ProductDropdown';
import { getShipmentCutOffDate } from 'src/_helpers/date';
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';
import { ContextualGuidePhysicalOrderQuote } from 'src/components/ContextualGuide';

export const QuoteSection = () => {
	const { t } = useTranslation();

	const {
		formState: { errors },
		setValue,
	} = useFormContext();

	const {
		quote,
		inco,
		harvest,
		speciality,
		date,
		grades,
		origins,
		port,
		setOriginByGrade,
		setIsOriginByGrade,
		canHideOptional,
	} = useQuoteSectionOptions();
	const { portsArray } = useWatchPhysicalFields();

	const updateShipmentQuantity = useCallback(
		value => {
			if (portsArray.length === 1) {
				setValue('ports.0.volume', value);
			}
		},
		[portsArray.length, setValue]
	);

	return (
		<FoldableSection
			title={t('quote')}
			GuideComponent={ContextualGuidePhysicalOrderQuote}
			canToggle={canHideOptional}
		>
			<Controller
				mandatory
				name="type"
				render={({ field }) => (
					<MaterialSelectInput
						required
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('quote')}
						options={quote.options}
						disabled={!quote.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name="volume"
				render={({ field }) => (
					<MaterialNumberInput
						required
						error={!!errors[field.name]}
						data-test={field.name}
						id="volume"
						label={t('quantity')}
						fixedDecimalScale={false}
						parameters={t('mt')}
						{...field}
						onChange={value => {
							updateShipmentQuantity(value);
							field.onChange(value);
						}}
					/>
				)}
			/>
			<Controller
				mandatory
				name="productAndType"
				render={({ field }) => (
					<ProductDropdown
						error={!!errors[field.name]}
						data-test={field.name}
						required
						label={t('product')}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name="inco"
				render={({ field }) => (
					<MaterialSelectInput
						required
						search={(list, phrase) => {
							const lowerCasePhrase = phrase.toLowerCase();
							return list.filter(option =>
								option.text.toLowerCase().includes(lowerCasePhrase)
							);
						}}
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('inco')}
						options={inco.options}
						disabled={!inco.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name="port"
				render={({ field }) => (
					<PortDropdown
						error={!!errors[field.name]}
						data-test={field.name}
						required
						label={t('port')}
						onValueUpdate={port.addName}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name="date"
				defaultValue={null}
				render={({ field }) => (
					<MaterialDateInput
						{...field}
						required
						rightAligned
						id={field.name}
						data-test={field.name}
						label={date.label}
						error={!!errors[field.name]}
						cutOffDate={getShipmentCutOffDate()}
						initialVisibleMonth={date.initialValue}
					/>
				)}
			/>
			<Controller
				mandatory
				name="origin"
				render={({ field }) => (
					<MaterialMultiSelectInput
						key={`origins-dropdown${origins.ready ? '' : '-loading'}`}
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('origin')}
						options={origins.options}
						disabled={!origins.ready}
						{...field}
						onChange={val => {
							origins.saveObject(val);
							setIsOriginByGrade(false);
							field.onChange(val);
						}}
						isValueDependent
					/>
				)}
			/>
			<Controller
				mandatory
				name="grade"
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						required
						data-test={field.name}
						label={t('grade')}
						options={grades.options}
						disabled={!grades.ready}
						{...field}
						onChange={val => {
							setOriginByGrade(val);
							field.onChange(val);
						}}
					/>
				)}
			/>
			<Controller
				name="harvest"
				mandatory={harvest.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('harvest')}
						options={harvest.options}
						disabled={!harvest.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				name="speciality"
				mandatory={speciality.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('speciality')}
						options={speciality.options}
						disabled={!speciality.ready}
						{...field}
					/>
				)}
			/>
		</FoldableSection>
	);
};
