/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const VosborMobileAppID = 'exchange.vosbor.mobile';

export const VosborMobileAppStoreLink = 'https://apps.apple.com/us/app/vosbor/id1571306210';
export const VosborMobileGooglePlayLink =
	'https://play.google.com/store/apps/details?id=exchange.vosbor.mobile';

export const MobilePlatform = {
	Android: 'android',
	IOS: 'ios',
};
