/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { call, put, select } from 'redux-saga/effects';
import * as Api from 'src/_api';
import jwtDecode from 'jwt-decode';
import {
	loginUserError,
	loginUser2FAError,
	loginUserSuccess,
	logoutUserError,
	logoutUserSuccess,
	loginUserShow2FA,
	setSelected2FAmethod,
	loginUserShowTermsAndConditions,
	registerUserError,
	registerUserSuccess,
	resetUserConfirmError,
	resetUserConfirmSuccess,
	userIsLoggedIn,
	userIsNotLoggedIn,
} from '../_store/actions';
import { getUserRequestedPath } from 'src/_store/selectors/user.selectors';
import { getPermissions, getHomepage } from 'src/_helpers/permissions';
import {
	PASSWORD_ALREADY_USED,
	SECURITY_REASONS_CHANGE_PASSWORD,
	INCORRECT_LOGIN_OR_PASSWORD,
} from 'src/_api/errors';
import { ROUTES, PUBLIC_ROUTES } from 'src/constants/routes';
import { setCSRFToken } from 'src/_api';

export function* registerUser(action) {
	try {
		const { navigate, user } = action.payload;
		const response = yield call(Api.registerUser, user);
		if (response.ok) {
			yield put(registerUserSuccess({ isRegistered: true, error: false }));
			yield call(navigate, ROUTES.registrationCompleted, {
				state: { email: user.email },
			});
		} else {
			yield put(registerUserError({ error: 'Error' }));
		}
	} catch (error) {
		yield put(registerUserError({ error: 'Error' }));
	}
}

export function* loginUser(action) {
	try {
		const { callback, navigate, addToast, t, ...payload } = action.payload;
		const response = yield call(Api.loginUser, payload);

		if (response.data['terms_and_conditions_acceptance_required']) {
			setCSRFToken(response.data.csrf);
			yield put(loginUserShowTermsAndConditions());
		} else if (response.ok) {
			const decodedData = jwtDecode(response.data.jwt);
			const homepage = getHomepage(response.data);

			setCSRFToken(response.data.csrf);

			yield put(
				loginUserSuccess({
					error: '',
					session: { ...decodedData },
					homepage,
					permissions: getPermissions(response.data.permissions),
				})
			);
			callback && callback();

			const requestedPath = yield select(getUserRequestedPath);

			if (requestedPath && requestedPath !== ROUTES.root) {
				yield call(navigate, requestedPath);
			} else {
				yield call(navigate, homepage);
			}
		} else if (response.data['2fa_method']) {
			yield put(setSelected2FAmethod(response.data));
		} else if (response.data['2fa_methods']) {
			yield put(loginUserShow2FA(response.data));
		} else if (response.status === 409) {
			yield put(loginUser2FAError());
		} else if (response.status === 418) {
			//user is not yet approved to access app
			if (response.error?.type === 'user_not_approved') {
				yield call(navigate, ROUTES.awaitAccountApproval);
			} else {
				yield put(loginUserError({ error: response.error?.type || response.error }));
			}
		} else if (response.status === 426) {
			const { email } = payload;

			yield call(
				navigate,
				`${ROUTES.sendPasswordResetEmail}?email=${encodeURIComponent(
					email
				)}&reason=${SECURITY_REASONS_CHANGE_PASSWORD}`
			);
		} else {
			yield put(loginUserError({ error: INCORRECT_LOGIN_OR_PASSWORD }));
		}
	} catch (error) {
		yield put(loginUserError({ error: INCORRECT_LOGIN_OR_PASSWORD }));
	}
}

export function* logoutUser(action) {
	const { navigate } = action.payload;

	try {
		const response = yield call(Api.logoutUser);

		if (!response.ok) {
			throw Error('Error');
		}
		yield put(logoutUserSuccess());
		yield call(navigate, '/login');
	} catch (error) {
		yield put(logoutUserError({ error: 'Error' }));
		yield call(navigate, '/error', {
			state: { errorText: 'Could not log out' },
		});
	}
}

export function* resetUserConfirm(action) {
	try {
		const { navigate, addToast, t, ...payload } = action.payload;
		const response = yield call(Api.resetUserConfirm, { ...payload });

		if (response.ok) {
			yield put(resetUserConfirmSuccess());
			yield call(addToast, {
				kind: 'success',
				message: t('password_has_been_changed'),
				topPage: true,
				timeout: 5000,
				embeded: true,
			});
			yield call(navigate, ROUTES.root, {
				state: { email: payload.email, password: payload.password },
			});
		} else if (response.status === 409) {
			yield put(resetUserConfirmError({ error: PASSWORD_ALREADY_USED }));
		} else {
			yield put(resetUserConfirmError({ error: 'Error' }));
		}
	} catch (error) {
		yield put(resetUserConfirmError({ error: 'Error' /* error.message not used for now*/ }));
	}
}

export function* isUserLoggedIn() {
	let pathToRemember = document.location.pathname;

	if (PUBLIC_ROUTES.includes(pathToRemember)) {
		pathToRemember = ROUTES.root;
	}

	try {
		const response = yield call(Api.checkAuth);

		if (response.ok) {
			setCSRFToken(response.data.csrf);

			const decodedData = jwtDecode(response.data.jwt);

			yield put(
				userIsLoggedIn({
					error: '',
					session: decodedData,
					homepage: getHomepage(response.data),
					permissions: getPermissions(response.data.permissions),
				})
			);
		} else {
			yield put(userIsNotLoggedIn(pathToRemember));
		}
	} catch (error) {
		yield put(userIsNotLoggedIn(pathToRemember));
	}
}
