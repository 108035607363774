/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import * as Styled from './styled';

export const RadioButton = ({ label, value, checked, onChange, ...rest }) => {
	return (
		<Styled.Radio label={label} value={value} checked={checked} onChange={onChange} {...rest} />
	);
};
