/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { toPath } from 'src/_helpers';
import { useCalendarSpreadFields } from '../../useCalendarSpreadFields';
import { Controller } from 'react-hook-form';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { useWatchPaperCounterFields } from 'src/components/ViewOrderDrawer/CounterForm/helpers/paper/useWatchPaperCounterFields';
import { QuoteType } from 'src/constants/contract';

const VolumeField = ({ path, t, lastCounter }) => {
	const { volume } = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	const { orderTypeValue } = useWatchPaperCounterFields(path);

	return (
		<Controller
			mandatory={orderTypeValue === QuoteType.Firm}
			name={toPath(path, 'volume')}
			render={({ field, fieldState: { error } }) => (
				<MaterialNumberInput
					required={orderTypeValue === QuoteType.Firm}
					error={!!error}
					data-test={field.name}
					id="volume"
					label={t('quantity')}
					fixedDecimalScale={false}
					parameters={t('price_unit_short_metric_ton')}
					controlledState
					hasChanged={volume.hasChanged}
					isAllowed={value => value.floatValue !== 0}
					{...field}
				/>
			)}
		/>
	);
};

export const counterVolumeField = props => <VolumeField {...props} />;
