/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { fetchFromExchangeApi, map404ToEmptyList } from './model';
import { getAvailableHarvestYears, specialities } from 'src/constants/product';
import { caseInsensitiveEq } from 'src/_helpers/utils';

export const getEnvPrefix = env => (env ? env + '/' : '');

export const getCompanies = query => {
	return fetchFromExchangeApi('list/companies', {
		method: 'GET',
		query: {
			_order: 'ASC',
			_sort: 'name',
			...query,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getProductTypes = async query => {
	return fetchFromExchangeApi('list/types', {
		method: 'GET',
		query: {
			_order: 'ASC',
			_sort: 'name',
			...query,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getProducts = async query => {
	return fetchFromExchangeApi('list/products', {
		method: 'GET',
		query: {
			_order: 'ASC',
			_sort: 'name',
			...query,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getGrades = async query => {
	return fetchFromExchangeApi('list/grades', {
		method: 'GET',
		query: {
			_order: 'ASC',
			_sort: 'name',
			...query,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const listProducts = async ({ queryKey: [key, { market }] }) => {
	const products = await getProducts({ market });

	return products;
};

export const getCountries = async query => {
	const countries = await fetchFromExchangeApi('list/countries', {
		method: 'GET',
		query: {
			_order: 'ASC',
			_sort: 'name',
			...query,
		},
		mapStatusToResult: map404ToEmptyList,
	});

	return countries;
};

export const getOrigins = async query => {
	const origins = await fetchFromExchangeApi('list/origins', {
		method: 'GET',
		query: {
			_order: 'ASC',
			_sort: 'name',
			...query,
		},
		mapStatusToResult: map404ToEmptyList,
	});

	return origins;
};

export const getNormalizedCountries = async ({ query }) => {
	const countries = await getCountries(query);

	const normalizedCountries = countries.reduce((acc, country) => {
		return {
			...acc,
			[country._key]: country,
		};
	}, {});

	return normalizedCountries;
};

export const getRegions = async () => {
	return fetchFromExchangeApi('list/regions', {
		method: 'GET',
		query: {
			_order: 'ASC',
			_sort: 'name',
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getCountry = async id => {
	return fetchFromExchangeApi(`read/countries/${id}`, {
		method: 'GET',
	});
};

export const listIncoTerms = () => {
	return fetchFromExchangeApi('list/incos', {
		method: 'GET',
		query: {
			_order: 'ASC',
			_sort: 'seller_responsibility',
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getPort = id => {
	return fetchFromExchangeApi(`read/ports/${id}`, {
		method: 'GET',
	});
};

export const searchPorts = query => {
	return fetchFromExchangeApi('search/ports_view', {
		method: 'GET',
		mapStatusToResult: map404ToEmptyList,
		query: {
			...query,
			_limit: 100,
		},
	});
};

export const getProductByName = async q => {
	const result = (await getProducts()).find(product => caseInsensitiveEq(product.name, q));

	if (!result) {
		return null;
	}

	return result;
};

export const searchAllPorts = async ({ q }) => {
	let query = {
		_limit: 100,
	};
	if (q) {
		query = {
			...query,
			q,
		};
	}
	return fetchFromExchangeApi('search/ports_view', {
		method: 'GET',
		mapStatusToResult: map404ToEmptyList,
		query,
	});
};

export const listTerms = (typeID, market) => {
	const query = {
		_order: 'ASC',
		_sort: 'name',
		type_id: typeID,
	};

	if (market) {
		query.market = market;
	}

	return fetchFromExchangeApi('list/terms', {
		method: 'GET',
		query,
		mapStatusToResult: map404ToEmptyList,
	});
};

export const uploadOrderFiles = async ({ file, signal }) => {
	const formData = new FormData();
	if (file) {
		formData.append('file', file);
		formData.append('name', file.name);
	}
	return fetchFromExchangeApi('upload/files', {
		method: 'POST',
		body: formData,
		signal,
	});
};

export const getProductDetails = async ({ productId }) => {
	const grades = await getGrades({ product_id: productId });

	return {
		specialities,
		harvestYears: getAvailableHarvestYears().map(year => {
			return {
				_key: year,
				name: year,
			};
		}),
		grades: grades.sort((a, b) => !!a.is_custom - !!b.is_custom),
	};
};

export const getCountriesAndRegions = async () => {
	const [countries, regions] = await Promise.all([getCountries(), getRegions()]);

	return {
		countries,
		regions,
	};
};
