/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useState } from 'react';
import { millisecondsRemaining } from './date';

const maxDelayValue = 2147483647;

export const useUpdateAtTime = dateTime => {
	const [shouldUpdate, setShouldUpdate] = useState(false);

	useEffect(() => {
		setShouldUpdate(false);
	}, [dateTime]);

	useEffect(() => {
		const validityDiff = dateTime ? millisecondsRemaining(dateTime) : 0;

		let timeout = null;

		if (validityDiff > 0 && validityDiff < maxDelayValue) {
			timeout = setTimeout(() => setShouldUpdate(true), validityDiff);
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [dateTime]);

	return shouldUpdate;
};
