/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { H700 } from 'src/components/Typography';
import { media } from 'src/_helpers';

export const LogsWrapper = styled.div`
	overflow-y: auto;
	height: 100%;
	margin-right: 4px;
	padding: 0 28px 20px 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	${media.ll`
		margin-right: 8px;
		padding: 0 24px 20px 32px;
	`}

	${media.xl`
		padding: 0 72px 20px 48px;
	`}
`;

export const ActivityLogHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 32px;

	${media.xl`
		padding: 0 48px;
	`}
`;

export const ActivityLogTitle = styled(H700)`
	font-size: 18px;
`;

export const EmptyMessageContainer = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
