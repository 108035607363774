/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, cloneElement } from 'react';
import { SpreadPriceFormat } from 'src/constants/contract';
import { counterSpreadTypeField } from './fields/SpreadTypeField';
import { counterSpreadPriceField } from './fields/SpreadPriceField';
import { counterNetBalanceField } from './fields/NetBalanceField';
import { counterVolumeField } from './fields/VolumeField';
import { counterFirstLegPriceField } from './fields/FirstLegPriceField';
import { counterQuoteTypeField } from './fields/QuoteTypeField';
import { counterPrincipalField } from './fields/PrincipalField';
import { useTranslation } from 'react-i18next';
import { counterPrincipalVisibilityField } from './fields/PrincipalVisibilityField';
import { counterRunsField } from './fields/RunsField';
import { counterTotalQuantityField } from './fields/TotalQuantityField';

const createFieldWithProps = (fieldComponentFn, index, commonProps) =>
	cloneElement(fieldComponentFn(commonProps), { key: `${commonProps.path}-${index}` });

const getSpreadFields = (fields, commonProps) =>
	fields.map((fieldComponentFn, index) =>
		createFieldWithProps(fieldComponentFn, index, commonProps)
	);

export const useCounterSpreadStrategy = ({ path, lastCounter }) => {
	const { t } = useTranslation();

	const commonFieldProps = useMemo(() => ({ path, t, lastCounter }), [lastCounter, path, t]);

	const spreadPriceFormatStrategy = lastCounter.spread_details.price_format;

	const spreadCounterFields = useMemo(
		() => ({
			[SpreadPriceFormat.Spread]: [
				counterSpreadPriceField,
				counterRunsField,
				counterTotalQuantityField,
				counterFirstLegPriceField,
				counterQuoteTypeField,
				counterPrincipalField,
				counterPrincipalVisibilityField,
			],
			[SpreadPriceFormat.PayCash]: [
				counterSpreadTypeField,
				counterSpreadPriceField,
				counterNetBalanceField,
				counterVolumeField,
				counterFirstLegPriceField,
				counterQuoteTypeField,
				counterPrincipalField,
				counterPrincipalVisibilityField,
			],
		}),
		[]
	);

	const spreadStrategyCounterFields = useMemo(
		() => getSpreadFields(spreadCounterFields[spreadPriceFormatStrategy], commonFieldProps),
		[spreadCounterFields, spreadPriceFormatStrategy, commonFieldProps]
	);

	return { spreadCounterFields: spreadStrategyCounterFields };
};
