/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { H600 } from 'src/components/Typography';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { useProductSectionOptions } from './useProductSectionOptions';
import { ReactComponent as ExternalLinkIcon } from 'src/assets/icons/external_link_icon.svg';
import { FieldContainer, SectionMeta } from '../../../components/FoldableSection/styled';
import * as Styled from './styled';

export const ProductSection = ({ canChangeInstrument }) => {
	const { t } = useTranslation();

	const {
		formState: { errors },
	} = useFormContext();

	const { productsPresets, terms, instruments } = useProductSectionOptions();

	return (
		<Styled.ProductSectionWrapper>
			<SectionMeta>
				<H600>{t('product')}</H600>
			</SectionMeta>
			<FieldContainer>
				<Controller
					name="presetID"
					render={({ field }) => (
						<MaterialSelectInput
							required
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('contract')}
							options={productsPresets.options}
							disabled={!productsPresets.ready}
							{...field}
							onChange={value => {
								field.onChange(value);
								productsPresets.onChange(value);
							}}
						/>
					)}
				/>
				<Controller
					name="instrument"
					render={({ field }) => (
						<MaterialSelectInput
							required
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('instrument')}
							options={instruments.options}
							disabled={!canChangeInstrument || !instruments.ready}
							{...field}
							onChange={value => {
								field.onChange(value);
								instruments.onChange(value);
							}}
						/>
					)}
				/>
				{terms && (
					<Styled.ContractLinkWrapper>
						<Styled.ContractLabel>{t('contract_terms')}</Styled.ContractLabel>
						<Styled.ContractLink href={terms.url} target="_blank">
							{terms.name} <ExternalLinkIcon />
						</Styled.ContractLink>
					</Styled.ContractLinkWrapper>
				)}
			</FieldContainer>
		</Styled.ProductSectionWrapper>
	);
};
