/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
import { h400, T150 } from 'src/components/Typography/fonts';
import { Languages } from 'src/constants/settings';

export const PeriodHeader = ({
	startDate,
	endDate,
	onClear,
	isContinuous = false,
	continuousPeriod,
	ResetIcon = StyledCloseIcon,
}) => {
	const { t } = useTranslation();

	const shouldDisplayResetButton = startDate && endDate;
	const isChinese = moment.locale() === Languages.Chinese.moment;

	return (
		<Wrapper className="period-header">
			{!isContinuous && !continuousPeriod ? (
				<>
					<PeriodDate className="period-start-date" $isChinese={isChinese}>
						<PeriodLabel $isChinese={isChinese}>{t('from')}</PeriodLabel>
						{startDate || '---'}
					</PeriodDate>
					<PeriodSeparator />
					<PeriodDate className="period-end-date" $isChinese={isChinese}>
						<PeriodLabel $isChinese={isChinese}>{t('to')}</PeriodLabel>
						{endDate || '---'}
						{shouldDisplayResetButton && (
							<ResetIcon data-test="clear-range" onClick={onClear} />
						)}
					</PeriodDate>
				</>
			) : (
				<PeriodDate $isContinuous={isContinuous}>
					<PeriodLabel>{t('period')}</PeriodLabel>
					{continuousPeriod || '---'}
					{shouldDisplayResetButton && (
						<ResetIcon data-test="clear-range" onClick={onClear} />
					)}
				</PeriodDate>
			)}
		</Wrapper>
	);
};

const StyledCloseIcon = styled(DeleteIcon)`
	width: 15px;
	height: 15px;
	color: #72779f;
`;

const PeriodSeparator = styled.div`
	background-color: var(--neutral-300);
	height: 29px;
	width: 1px;
`;

const Wrapper = styled.div`
	${h400};
	display: flex;
	align-items: center;
	padding: 0 24px 13px;
	background-color: var(--date-picker-background);
	gap: 16px;
`;

const PeriodDate = styled(T150)`
	margin: auto;
	min-width: 110px;
	flex: 1;
	position: relative;
	${({ $isChinese }) =>
		!$isChinese &&
		`
		display: flex;
		align-items: center;
  `}

	& svg {
		cursor: pointer;
		${({ $isContinuous }) => $isContinuous && 'margin-left: 10px;'}
	}

	&.period-end-date {
		& svg {
			margin-left: auto;
			${({ $isContinuous }) => !$isContinuous && 'align-self: flex-start;'}
			min-width: 15px;

			${({ $isChinese }) =>
				$isChinese &&
				`
				align-self: center;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			`}
		}
	}
`;

const PeriodLabel = styled.label`
	color: var(--text-color-secondary) !important;
	padding: ${({ $isChinese }) => (!$isChinese ? '0 10px 0 0' : '0')};

	${({ $isChinese }) =>
		$isChinese &&
		`
		max-width: 39px;
  `}
`;
