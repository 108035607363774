/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { H600 } from 'src/components/Typography/fonts';
import { tSpecDirection } from 'src/constants/product';
import {
	TradeInfoWrapper,
	InfoTitle,
	SpecName,
	SpecValue,
	SpecLabel,
} from '../../../sections/common';
import * as Styled from '../styledTradeInfoComponents';

export const SpecSection = ({ specInfo }) => {
	const { t } = useTranslation();

	return (
		<>
			<InfoTitle>{t('specifications')}</InfoTitle>
			<TradeInfoWrapper>
				{(specInfo || []).map(({ _key, spec, value }, index) => {
					const testSpecName = spec?.name.toLowerCase().replace(/ /g, '-');
					return (
						<Styled.InfoItemColumn key={`${index}-${_key}`}>
							<SpecName>{spec.name}</SpecName>
							<SpecValue data-test={`trade-details-spec-${testSpecName}`}>
								<SpecLabel>{tSpecDirection(t, spec)}</SpecLabel>
								<H600 as="span">
									{` ${value}`}&nbsp;
									{spec?.unit}
								</H600>
							</SpecValue>
						</Styled.InfoItemColumn>
					);
				})}
			</TradeInfoWrapper>
		</>
	);
};
