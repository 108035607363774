/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { CalendarSpreadPayment } from 'src/constants/contract';
import { useWatchPaperCounterFields } from 'src/components/ViewOrderDrawer/CounterForm/helpers/paper/useWatchPaperCounterFields';
import { useCalendarSpreadFields } from '../../useCalendarSpreadFields';
import { InformativeField } from 'src/components/CreateOrderDrawer/paper/sections/ContractAndPricingSection/spread/fields/InformativeField';
import { formatFloatNumberToPriceFormat } from 'src/_helpers/price';

const NetBalanceField = ({ path, t, lastCounter }) => {
	const { priceValue, spreadTypeValue } = useWatchPaperCounterFields(path);

	const { currency, unit } = useCalendarSpreadFields({
		path,
		lastCounter,
	});

	const currencyUnitLabel = `${currency}/${t(`price_unit_short_${unit}`)}`;

	const getPayReceiveText = () => {
		if (!priceValue || spreadTypeValue === CalendarSpreadPayment.Even) {
			return '---';
		}

		const key = spreadTypeValue === CalendarSpreadPayment.Pay ? 'you_pay' : 'you_receive';

		return `${t(key, {
			value: formatFloatNumberToPriceFormat(priceValue),
		})} ${currencyUnitLabel}`;
	};

	return (
		<InformativeField
			title={t('net_balance')}
			subtitle={getPayReceiveText()}
			dataTest="net-balance-field"
		/>
	);
};

export const counterNetBalanceField = props => <NetBalanceField {...props} />;
