/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ROUTES } from 'src/constants/routes';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { useShouldRenderDrawer } from '../../useShouldRenderDrawer';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { ConfirmDiscardChangesModal } from '../ConfirmDiscardChangesModal/ConfirmDiscardChangesModal';

export const CopyOrderButton = ({
	Component = SecondaryButton,
	hidden,
	shouldShowWarning,
	...props
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [isConfirmationVisible, setConfirmationVisible] = useState(false);

	const shouldRenderDrawer = useShouldRenderDrawer();

	const {
		[DrawerContextKeys.createDrawer]: {
			visibility: [
				isCreateOrderVisible,
				{ open: openCreateOrderDrawer, close: closeCreateOrderDrawer },
			],
		},
		[DrawerContextKeys.viewDrawer]: {
			visibility: [isViewOrderVisible, { close: closeViewOrderDrawer }],
			setCurrentOrderInfo,
		},
	} = useDrawerContext();

	const handleCopyOrder = () => {
		if (!shouldRenderDrawer) {
			navigate(ROUTES.root);
		}
		if (isCreateOrderVisible) {
			closeCreateOrderDrawer();
		}
		if (isViewOrderVisible) {
			closeViewOrderDrawer();
			setCurrentOrderInfo(null);
		}
		openCreateOrderDrawer(props);
	};

	const handleButtonClick = () => {
		if (shouldShowWarning) {
			setConfirmationVisible(true);
		} else {
			handleCopyOrder();
		}
	};

	const handleConfirmDischargeModal = () => {
		setConfirmationVisible(false);
		handleCopyOrder();
	};

	if (hidden) {
		return null;
	}

	return (
		<>
			<Component data-test="copy-order-button" onClick={handleButtonClick}>
				{t('copy')}
			</Component>
			{isConfirmationVisible && (
				<ConfirmDiscardChangesModal
					onConfirm={handleConfirmDischargeModal}
					onCancel={() => setConfirmationVisible(false)}
				/>
			)}
		</>
	);
};
