/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useLayoutEffect, useRef, useState } from 'react';

export const useScrollVisibility = () => {
	const scrollableWrapperRef = useRef();
	const [isScrollVisible, setIsScrollVisible] = useState(false);

	useLayoutEffect(() => {
		if (!scrollableWrapperRef.current) {
			return;
		}

		const scrollableWrapper = scrollableWrapperRef.current;

		const checkScrollVisibility = element => {
			setIsScrollVisible(element.scrollHeight > element.clientHeight);
		};

		checkScrollVisibility(scrollableWrapper);

		const disconnectResize = observeElementResize(scrollableWrapper, entities => {
			checkScrollVisibility(scrollableWrapper);
		});

		return () => {
			disconnectResize();
		};
	}, []);

	return {
		scrollableWrapperRef,
		isScrollVisible,
	};
};

const observeElementResize = (element, onResize) => {
	if (!element) {
		return () => {};
	}

	const resizeObserver = 'ResizeObserver' in window ? new ResizeObserver(onResize) : null;

	resizeObserver?.observe(element);

	return () => {
		resizeObserver?.disconnect();
	};
};
