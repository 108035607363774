/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { OrderType, CalendarSpreadPayment, tCurrency } from 'src/constants/contract';
import { calculatePayCashDirection, calculateSpreadPrice } from 'src/_helpers/spread';
import { formatFloatNumberToPriceFormat } from 'src/_helpers/price';
import * as Styled from './styled';

export const PayCashInfo = ({ order }) => {
	const { t } = useTranslation();
	const userId = useSelector(getUserId);

	const {
		currency,
		price_unit,
		price,
		counter_order_type,
		order_type,
		is_order_preview,
		user_id,
		spread_details,
		is_counter_preview,
		trade_id,
	} = order;

	const assetType = counter_order_type || order_type;
	const isBuyer = assetType === OrderType.Buy;

	// For order preview (owner) we need to use calculated price depending on position set in form
	const spreadTypeFromForm = spread_details.spread_type;
	const isPreview = is_order_preview || is_counter_preview;

	const priceForCalculations = !isPreview
		? price
		: calculateSpreadPrice(isBuyer, spreadTypeFromForm, price);

	const spreadDirection = calculatePayCashDirection(isBuyer, priceForCalculations);

	if (spreadDirection === CalendarSpreadPayment.Even) {
		return null;
	}

	const isMyOrderOrCounter = user_id === userId || is_order_preview;

	const userSide = assetType === OrderType.Sell ? 'seller' : 'buyer';

	const firstLegUserSide = `first_leg_${userSide}`;

	const priceAbsoluteValue = formatFloatNumberToPriceFormat(Math.abs(price));

	const ownerPayCashInfoKey = spreadDirection => {
		return spreadDirection === CalendarSpreadPayment.Pay
			? t('you_pay', {
					value: priceAbsoluteValue,
			  })
			: t('you_receive', {
					value: priceAbsoluteValue,
			  });
	};

	const currencyAndUnit = `${tCurrency(t, currency)}/${t(`price_unit_short_${price_unit}`, {
		lng: 'en',
	})}`;

	const counterpartyPayCashInfoKey =
		t(
			`${firstLegUserSide}_${
				spreadDirection === CalendarSpreadPayment.Pay ? 'pays' : 'receives'
			}`,
			{
				value: priceAbsoluteValue,
			}
		) +
		' ' +
		currencyAndUnit;

	const isTraded = !!trade_id;

	const tradedUserSide =
		order.user_id === userId
			? order.counter_order_type
			: order.counter_order_type === OrderType.Buy
			? OrderType.Sell
			: OrderType.Buy;

	const tradedSpreadDirection = calculatePayCashDirection(
		tradedUserSide === OrderType.Buy,
		price
	);

	const tradedCounterLabel = ownerPayCashInfoKey(tradedSpreadDirection) + ' ' + currencyAndUnit;

	return (
		<Styled.PayCashInfo>
			{isTraded
				? tradedCounterLabel
				: isMyOrderOrCounter
				? ownerPayCashInfoKey(spreadDirection) + ' ' + currencyAndUnit
				: counterpartyPayCashInfoKey}
		</Styled.PayCashInfo>
	);
};
