/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

export function VolumeDisplay({ as: Component = Fragment, value, ...rest }) {
	const { t } = useTranslation();

	return (
		<Component>
			<NumberFormat
				thousandSeparator
				displayType="text"
				value={value}
				suffix={` ${t('mt')}`}
				{...rest}
			/>
		</Component>
	);
}
