/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { QuoteSection } from './sections/QuoteSection/QuoteSection';
import { CounterFormPhysicalFooter } from './components/CounterFormFooter/CounterFormPhysicalFooter';
import { SectionSeparator } from 'src/components/CreateOrderDrawer/components/SectionSeparator/SectionSeparator';
import { SpecificationsSection } from './sections/SpecificationsSection/SpecificationsSection';
import { PricingSection } from './sections/PricingSection/PricingSection';
import { ShipmentSection } from './sections/ShipmentSection/ShipmentSection';
import { AdditionalInfoSection } from './sections/AdditionalInfoSection/AdditionalInfoSection';
import { DocumentsSection } from './sections/DocumentsSection/DocumentsSection';
import { CounterPreviewContent } from './components/CounterPreview/CounterPreviewContent';
import { CounterPreviewFooter } from './components/CounterPreview/CounterPreviewFooter';
import * as Styled from './styled';

export const CounterPhysicalForm = ({
	setCounterForm,
	lastCounter,
	isPreviewMode,
	formMethods,
	onSubmit,
	isSubmitting,
}) => {
	const [isUploaddingFiles, setIsUploadingFiles] = useState(false);

	return (
		<FormProvider {...formMethods}>
			<Styled.Form>
				{isPreviewMode ? (
					<>
						<CounterPreviewContent />
						<CounterPreviewFooter onSubmit={onSubmit} isSubmitting={isSubmitting} />
					</>
				) : (
					<>
						<Styled.FormContent>
							<QuoteSection lastCounter={lastCounter} />
							<SectionSeparator />
							<SpecificationsSection lastCounter={lastCounter} />
							<SectionSeparator />
							<PricingSection lastCounter={lastCounter} />
							<SectionSeparator />
							<ShipmentSection />
							<SectionSeparator />
							<AdditionalInfoSection lastCounter={lastCounter} />
							<DocumentsSection
								lastCounter={lastCounter}
								setIsUploadingFiles={setIsUploadingFiles}
							/>
						</Styled.FormContent>
						<CounterFormPhysicalFooter
							disableSubmit={isUploaddingFiles}
							onSubmit={onSubmit}
							onCancel={() => setCounterForm(false, null)}
						/>
					</>
				)}
			</Styled.Form>
		</FormProvider>
	);
};
