/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { PaperInstruments } from 'src/constants/contract';
import { OutrightSection } from './OutrightSection';
import { CalendarSpreadSection } from './CalendarSpreadSection';

export const ContractAndPricingSection = ({ order, changes = {} }) => {
	return order.instrument === PaperInstruments.Spread ? (
		<CalendarSpreadSection order={order} changes={changes} />
	) : (
		<OutrightSection order={order} changes={changes} />
	);
};
