/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import * as Styled from './styled';
import { ReactComponent as LoadingIcon } from 'src/assets/icons/loading_icon.svg';

export const ButtonSize = {
	Large: 'large',
	Medium: 'medium',
	Small: 'small',
};

export const Button = React.forwardRef(
	(
		{
			to = null,
			size = ButtonSize.Medium,
			loading,
			active,
			darker,
			disabled = false,
			fixedPadding,
			className,
			children,
			...rest
		},
		ref
	) => {
		const buttonProps = {
			className: clsx(
				className,
				loading && 'loading',
				active && 'active',
				darker && 'darker',
				disabled && 'disabled',
				size
			),
			$fixedPadding: fixedPadding,
			ref: ref,
			...rest,
		};

		if (to) {
			return (
				<Styled.StyledLink to={to} {...buttonProps}>
					{loading ? <LoadingIcon /> : children}
				</Styled.StyledLink>
			);
		} else {
			return (
				<Styled.Button type="button" {...buttonProps} disabled={disabled}>
					{loading ? <LoadingIcon /> : children}
				</Styled.Button>
			);
		}
	}
);
