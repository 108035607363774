/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const isCounterpartyAContact = (counterpartyId, contacts = [], groups = []) => {
	let contactFound = false;

	if (typeof counterpartyId !== 'string') {
		return true;
	}

	contacts.forEach(contact => {
		if (contact.contact_user_id === counterpartyId) {
			contactFound = true;
		}
	});

	groups.forEach(group => {
		if (group._key === counterpartyId) {
			contactFound = true;
		} else {
			(group.users || []).forEach(contact => {
				if (contact.contact_user_id === counterpartyId) {
					contactFound = true;
				}
			});
		}
	});

	return contactFound;
};
