/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { addTradeDocument } from 'src/_api';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'src/components/Toast';
import { DocumentUploadModalView } from './DocumentUploadModalView';
import { useTradeLocation } from 'src/_helpers/useTradeLocation';

export const DocumentUploadModal = ({ file, onComplete }) => {
	const { t } = useTranslation();
	const { tradeId, api } = useTradeLocation();
	const queryClient = useQueryClient();
	const { addToast } = useToast();
	const { isLoading, mutate } = useMutation(addTradeDocument, {
		onError: () => {
			addToast({
				kind: 'error',
				message: t('document_upload_failed'),
			});
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries(['trade', tradeId]);
			await queryClient.refetchQueries(['activity_logs', tradeId]);
			onComplete();
		},
	});

	const onSubmit = useCallback(
		formState => {
			mutate({
				tradeId,
				file,
				api,
				...formState,
			});
		},
		[mutate, tradeId, file, api]
	);

	return (
		<DocumentUploadModalView
			file={file}
			onComplete={onComplete}
			isLoading={isLoading}
			onSubmit={onSubmit}
		/>
	);
};
