/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';

export const Container = styled.div`
	border-radius: 32px;
	color: #fff;
	padding: 10px 12px;
	background: var(--neutral-800);
	display: inline-block;
	height: 35px;

	${media.xl`
		padding: 12px 16px;
		height: 40px;
	`}
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;
