/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useLayoutEffect, useState } from 'react';

export const useIsOverflowed = (ref, dep) => {
	const [isOverflowed, setIsOverflow] = useState(false);

	useLayoutEffect(() => {
		if (!ref.current) {
			return;
		}

		const compareSize = () => {
			const compare = ref.current.scrollWidth > ref.current.clientWidth;

			setIsOverflow(compare);
		};

		compareSize();
		window.addEventListener('resize', compareSize);

		return () => window.removeEventListener('resize', compareSize);
	}, [ref, dep]);

	return isOverflowed;
};
