/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'src/components/Input/LabeledInput';
import {
	StyledForm,
	Header,
	SendButton,
} from 'src/containers/ResendConfirmationEmail/ResendEmailForm';
import { isStrong, PasswordStrengthDetails } from 'src/components/Input/PasswordStrengthDetails';
import { FormRow } from 'src/components/Layout/LandingForm';
import * as Styled from './styled';

export const ResetPasswordForm = ({
	password,
	password2,
	email,
	isPasswordAlreadyUsed,
	isLoading,
	onSubmit,
	onInputChange,
}) => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const { t } = useTranslation();

	const handleSubmit = e => {
		e.preventDefault();
		setIsSubmitted(true);

		if (!isPasswordAlreadyUsed && isStrongPassword && doPasswordsMatch) {
			onSubmit();
		}
	};

	const isStrongPassword = isStrong(password);

	const doPasswordsMatch = password.length > 0 && password2.length > 0 && password === password2;

	const hasStartedTyping = password.length > 0 || password2.length > 0;

	const shouldShowFormErrors = hasStartedTyping || isSubmitted;

	return (
		<>
			<Header>{t('reset_form_title')}</Header>
			<StyledForm name="form" onSubmit={handleSubmit}>
				<FormRow>
					<LabeledInput
						mandatory
						label={t('password_input_label')}
						placeholder={t('password_input_placeholder')}
						name="password"
						type="password"
						onChange={onInputChange}
						className={isSubmitted && !isStrongPassword ? 'is-invalid' : 'input-field'}
						autoComplete="new-password"
						value={password}
					/>
					<PasswordStrengthDetails password={password} polluted={isSubmitted} />
				</FormRow>
				<FormRow>
					<LabeledInput
						mandatory
						label={t('repeat_password_input_label')}
						placeholder={t('repeat_password_input_placeholder')}
						name="password2"
						type="password"
						onChange={onInputChange}
						className={
							isSubmitted && password !== password2 ? 'is-invalid' : 'input-field'
						}
						autoComplete="new-password"
						value={password2}
					/>
					<Styled.ErrorPlaceholder>
						{isSubmitted && !isStrongPassword && (
							<Styled.ErrorMessage>
								{t('password_must_meet_complexity_criteria')}
							</Styled.ErrorMessage>
						)}
						{isSubmitted && !doPasswordsMatch && (
							<Styled.ErrorMessage>{t('password_not_match')}</Styled.ErrorMessage>
						)}
						{shouldShowFormErrors && isPasswordAlreadyUsed && (
							<Styled.ErrorMessage>{t('password_already_used')}</Styled.ErrorMessage>
						)}
					</Styled.ErrorPlaceholder>
				</FormRow>
				<SendButton disabled={!email?.length} loading={isLoading} type="submit">
					{t('continue_btn')}
				</SendButton>
			</StyledForm>
		</>
	);
};
