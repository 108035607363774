/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { fetchFromExchangeApi, map404ToEmptyList, map404ToNull } from './model';
import {
	createVaultSchema,
	createVaultDocumentsSchema,
	renameVaultSchema,
	renameVaultDocumentSchema,
	commentVaultDocumentSchema,
} from './model/model.vaults';

export const fetchVaults = query => {
	return fetchFromExchangeApi('vaults', {
		query,
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getVaults = async query => {
	const vaults = await fetchVaults(query);

	const normalizedVaults = vaults.reduce((acc, item) => {
		return {
			...acc,
			[item._key]: item,
		};
	}, {});

	return normalizedVaults;
};

export const createVault = async body => {
	try {
		await createVaultSchema.validate(body);
		return fetchFromExchangeApi('vaults', {
			method: 'POST',
			body,
			mapStatusToResult: map404ToNull,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const renameVault = async ({ vaultId, body }) => {
	try {
		await renameVaultSchema.validate(body);
		return fetchFromExchangeApi(`vaults/${vaultId}/rename`, {
			method: 'POST',
			body,
			mapStatusToResult: map404ToNull,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const deleteVault = async ({ vaultId }) => {
	return fetchFromExchangeApi(`vaults/${vaultId}/delete`, {
		method: 'POST',
		mapStatusToResult: map404ToNull,
	});
};

export const getVault = async ({ vaultId }) => {
	const vault = await fetchFromExchangeApi(`vaults/${vaultId}`, {
		mapStatusToResult: map404ToEmptyList,
	});

	return vault;
};

export const createVaultDocuments = async ({ vaultId, body }) => {
	try {
		await createVaultDocumentsSchema.validate(body);
		return fetchFromExchangeApi(`vaults/${vaultId}/documents`, {
			method: 'POST',
			body,
			mapStatusToResult: map404ToNull,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const renameVaultDocument = async ({ vaultId, documentId, body }) => {
	try {
		await renameVaultDocumentSchema.validate(body);
		return fetchFromExchangeApi(`vaults/${vaultId}/documents/${documentId}/rename`, {
			method: 'POST',
			body,
			mapStatusToResult: map404ToNull,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const addCommentVaultDocument = async ({ vaultId, documentId, body }) => {
	try {
		await commentVaultDocumentSchema.validate(body);
		return fetchFromExchangeApi(`vaults/${vaultId}/documents/${documentId}/comment`, {
			method: 'POST',
			body,
			mapStatusToResult: map404ToNull,
		});
	} catch (error) {
		return await Promise.reject(error);
	}
};

export const deleteVaultDocument = async ({ vaultId, documentId }) => {
	return fetchFromExchangeApi(`vaults/${vaultId}/documents/${documentId}/delete`, {
		method: 'POST',
		mapStatusToResult: map404ToNull,
	});
};
