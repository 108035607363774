/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useLayoutEffect } from 'react';
import { ThemeNames } from 'src/constants/settings';
import { ThemeSettingsContent } from './ThemeSettingsContent';

export const ThemeSettingsDropdown = ({ currentTheme, updateTheme }) => {
	useLayoutEffect(() => {
		if (currentTheme === ThemeNames.standard) {
			document.documentElement.classList.remove(ThemeNames.alternative);
			document.documentElement.classList.remove('colorblindness');
		}
		if (currentTheme === ThemeNames.alternative) {
			document.documentElement.classList.add(ThemeNames.alternative);
			document.documentElement.classList.remove('colorblindness');
		}
		if (currentTheme === ThemeNames.standardColorblindness) {
			document.documentElement.classList.remove(ThemeNames.alternative);
			document.documentElement.classList.add('colorblindness');
		}
		if (currentTheme === ThemeNames.alternativeColorblindness) {
			document.documentElement.classList.add(ThemeNames.alternative);
			document.documentElement.classList.add('colorblindness');
		}
	}, [currentTheme]);

	if (!currentTheme) {
		return null;
	}

	return <ThemeSettingsContent currentTheme={currentTheme} onChange={updateTheme} />;
};
