/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const Colors = {
	Neutral400: 'var(--neutral-400)',
	Neutral700: 'var(--neutral-700)',
	Neutral850: 'var(--neutral-850)',
	Neutral1000: 'var(--neutral-1000)',
};
