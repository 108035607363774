/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { Modal, ButtonsWrapper } from 'src/components/Modal';
import { AddUsersToTradeModalContent } from './AddUsersToTradeModalContent';
import { ModalSize, ModalType } from 'src/constants/modal';

export const AddUsersToTradeModal = ({
	trigger,
	usersAlreadyInTrade,
	open,
	close,
	isOpen,
	openSummaryModal,
	openGroupModal,
	selectedUsers,
	setSelectedUsers,
	selectedGroups,
	setSelectedGroups,
}) => {
	return (
		<StyledModal
			trigger={trigger}
			type={ModalType.Info}
			size={ModalSize.Tiny}
			close={close}
			isOpen={isOpen}
			open={open}
			closeOnDimmerClick={false}
			data-test="add-users-to-trade-modal"
		>
			<AddUsersToTradeModalContent
				close={close}
				usersAlreadyInTrade={usersAlreadyInTrade}
				openSummaryModal={openSummaryModal}
				openGroupModal={openGroupModal}
				selectedUsers={selectedUsers}
				selectedGroups={selectedGroups}
				setSelectedUsers={setSelectedUsers}
				setSelectedGroups={setSelectedGroups}
			/>
		</StyledModal>
	);
};

const StyledModal = styled(Modal)`
	&.ui.tiny.modal {
		height: 618px;
		${media.xl`
			height: 675px;
		`}

		.content {
			padding: 24px 12px;
		}

		.trade-users-content {
			padding: 0 20px;
		}

		.users-list {
			padding-right: 16px;
			margin-right: -20px;
		}

		.groups-list {
			padding-right: 0;
		}

		${ButtonsWrapper} {
			margin-top: 20px;
			padding: 0 20px;

			${media.xl`
        margin-top: 40px;
      `}
		}
	}
`;
