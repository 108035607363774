/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ResetEmail } from './ResetEmail';
import { ResetPassword } from './ResetPassword';
import { usePageTitle } from 'src/_helpers/usePageTitle';
import { OnboardingLayout } from 'src/components/OnboardingLayout/OnboardingLayout';
import { useToast } from 'src/components/Toast';
import { useTranslation } from 'react-i18next';
import { OnboardingFormId } from 'src/constants/user';

export const ResetPage = () => {
	const { t } = useTranslation();
	const { addToast } = useToast();

	const query = new URLSearchParams(useLocation().search);
	const email = query.get('email');
	const isExpired = query.get('error') === 'expired';

	usePageTitle('title_reset_password');

	useEffect(() => {
		if (isExpired) {
			addToast({
				kind: 'error',
				message: t('reset_password_link_has_expired'),
				topPage: true,
				timeout: 5000,
				embeded: true,
			});
		}
	}, [isExpired, addToast, t]);

	return (
		<OnboardingLayout className="reset-email" formTestId={OnboardingFormId.ResetEmail}>
			{email && email.length ? <ResetPassword email={email} /> : <ResetEmail />}
		</OnboardingLayout>
	);
};
