/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { formatPrice } from './price';
import { OrderType, Market, tCurrency } from 'src/constants/contract';
import { Dateformat, parseDateStringAsUTC } from 'src/_helpers/date';
import { futuresContractLabel } from './futuresContracts';
import { OriginsListMode, formatOriginsList } from './origins';
import { formatTradeDocuments } from './formatTradeDocuments';
import { UserSide } from 'src/constants/user';

export function formatTradeDetails(t, response, accountUserId, settings = {}) {
	if (!response || Object.keys(response).length === 0) {
		return null;
	}

	settings.priceLabel = settings.priceLabel || 'price_per_mt';

	const {
		volume,
		harvest_year,
		role,
		market,
		product,
		price,
		buyer_company,
		buyer_user,
		seller_company,
		order_type,
		seller_user,
		speciality,
		origin_country,
		origin_countries,
		delivery_date_from,
		order_id,
		grade,
		type,
		inco,
		price_type,
		price_unit,
		tolerance,
		futures_contract,
		futures_contract_date,
		payment,
		ports_discharging,
		ports_loading,
		primary_ports,
		delivery_date_to,
		inspection,
		shipment_type,
		created_at,
		trade_step,
		buyer_done,
		seller_done,
		seller_documents,
		buyer_documents,
		intention_duration,
		declaration_duration,
		orderspecs,
		delivery_mode,
		users = [],
		buyer_user_ids = [],
		seller_user_ids = [],
		terms_id,
		performance_bond_percent,
		currency,
		requested_documents_types,
		requested_documents_notes,
		seller_broker_user,
		seller_broker_company,
		buyer_broker_user,
		buyer_broker_company,
		seller_broker_user_id,
		buyer_broker_user_id,
		buyer_user_id,
		seller_user_id,
		price_increment_type,
		price_increment_basis_shipment_from,
		price_increment_basis_shipment_to,
		price_increment,
		price_increment_basis_shipment_mode,
	} = response;

	const isSellerSide = seller_user_ids.includes(accountUserId);
	const isBuyerSide = buyer_user_ids.includes(accountUserId);

	const isPrincipal =
		(!!seller_broker_user_id && seller_user_id === accountUserId) ||
		(!!buyer_broker_user_id && buyer_user_id === accountUserId);

	const tradeDate = created_at ? moment(created_at).format('LL') : null;

	const dateFormat = date => {
		if (delivery_mode === Dateformat.Months) {
			return moment.utc(date).format('MMMM YYYY');
		}
		return moment(parseDateStringAsUTC(date)).format('ll');
	};

	const dateFormatMonths = (date_from, date_to) => {
		const date_from_year = moment.utc(date_from).format('YYYY');
		const date_from_month = moment.utc(date_from).format('MMM');
		const date_to_year = moment.utc(date_to).format('YYYY');
		const date_to_month = moment.utc(date_to).format('MMM');
		let returnedMonth = '';
		let returnedYear = '';

		if (date_from_year === date_to_year) {
			returnedYear = date_from_year;
		} else {
			returnedYear = `${date_from_year}/${date_to_year}`;
		}

		if (date_from_month === date_to_month) {
			returnedMonth = `${date_from_month}`;
		} else {
			returnedMonth = `${date_from_month} - ${date_to_month}`;
		}

		return `${returnedMonth} ${returnedYear}`;
	};

	const formattedPrice = formatPrice(price, {
		currency: tCurrency(t, currency),
	});

	const formattedPriceIncrement = formatPrice(price_increment, {
		currency: tCurrency(t, currency),
	});

	return {
		...response,
		requested_documents_types: requested_documents_types || [],
		has_requested_notes: !!requested_documents_notes,
		trade_step,
		step_completed: isSellerSide ? seller_done : buyer_done,
		// TODO: Adjust header info values once we move to new physical trade view
		header_info: {
			orderId: order_id,
			volume: volume,
			price: formattedPrice,
			price_unit,
			shipment: delivery_date_from && moment(delivery_date_from).format('MMMM YYYY'),
			product: product?.name,
			trade_date: tradeDate,
			inco: inco?.name,
			primary_ports,
		},
		counterparties_user_name: isSellerSide ? buyer_user?.name : seller_user?.name,
		...(seller_broker_user && {
			seller_broker_info: {
				...seller_broker_user,
				side: UserSide.Seller,
				company: seller_broker_company?.name,
			},
		}),
		...(buyer_broker_user && {
			buyer_broker_info: {
				...buyer_broker_user,
				side: UserSide.Buyer,
				company: buyer_broker_company?.name,
			},
		}),
		counterparties_seller_info: {
			...seller_user,
			id: seller_user?._key,
			company: seller_company?.name,
			trader_name: seller_user?.name,
			country: seller_company?.country_code,
		},
		counterparties_buyer_info: {
			...buyer_user,
			id: buyer_user?._key,
			company: buyer_company?.name,
			trader_name: buyer_user?.name,
			country: buyer_company?.country_code,
		},
		order_info: [
			{
				label: 'quote',
				value: ['sell', 'offer'].includes(order_type) ? t('offer') : t('bid'),
			},
			{
				label: 'company',
				value: order_type === OrderType.Sell ? seller_company?.name : buyer_company?.name,
			},
			{ label: 'role', value: t(role) },
			{
				label: 'market',
				value: market,
				shouldDisplayDelta: market === Market.Paper,
			},
		],
		product_info: [
			{ label: 'product_type', value: type?.name },
			{ label: 'product', value: product?.name },
			{ label: 'grade', value: grade?.name },
			{ label: 'harvest', value: harvest_year },
			{
				label: 'origin',
				value: formatOriginsList(
					origin_countries || (origin_country ? [origin_country] : null),
					'name',
					OriginsListMode.full
				),
			},
			{ label: 'speciality', value: t(speciality) },
		],
		pricing: [
			{ label: 'price_type', value: t(price_type) },
			{ label: settings.priceLabel, value: formattedPrice },
			{
				label: 'futures_market',
				value: futuresContractLabel(t, product?.futures_contracts, futures_contract),
			},
			{
				label: 'futures_month',
				value: futures_contract_date && moment(futures_contract_date).format('MMMM YYYY'),
			},
			{ label: 'payment', value: payment },
		],
		spec: {
			name: grade?.name,
			specs: grade?.is_custom
				? (grade?.gradespecs || []).map(gradeSpec => ({
						...gradeSpec,
						value: orderspecs?.find(
							orderSpec => orderSpec.spec_id === gradeSpec.spec_id
						)?.value,
				  }))
				: grade?.gradespecs,
		},
		shipment: {
			inco: inco?.name,
			ports_loading: ports_loading?.map((port, index) => ({
				...port,
				order: index + 1,
			})),
			ports_discharging: ports_discharging?.map((port, index) => ({
				...port,
				order: index + 1,
			})),
			delivery_date_from: delivery_date_from && dateFormat(delivery_date_from),
			delivery_date_to: delivery_date_to && dateFormat(delivery_date_to),
			delivery_date_range:
				delivery_date_from && delivery_date_to && delivery_mode === Dateformat.Months
					? dateFormatMonths(delivery_date_from, delivery_date_to)
					: null,
			inspection,
			shipment_type,
			quantity: {
				value: volume,
				units: t('mt'),
			},
			tolerance: { value: tolerance, units: '%' },
		},
		shipment_pricing: {
			inco: inco?.name,
			delivery_date_from_to:
				delivery_date_from &&
				delivery_date_to &&
				dateFormatMonths(delivery_date_from, delivery_date_to),
			quantity: {
				value: volume,
				units: t('mt'),
			},
			price_type: t(price_type),
			futures_market: futuresContractLabel(t, product?.futures_contracts, futures_contract),
			futures_month:
				futures_contract_date && moment(futures_contract_date).format('MMMM YYYY'),
			price: {
				label: settings.priceLabel,
				value: formatPrice(price),
			},
		},
		additional_information: {
			created_at: tradeDate,
			performance_bond_percent,
		},
		isSellerSide,
		isBuyerSide,
		account_user_info: isSellerSide
			? {
					user: seller_user,
					company: seller_company,
			  }
			: {
					user: buyer_user,
					company: buyer_company,
			  },
		seller_documents: formatTradeDocuments(seller_documents),
		buyer_documents: formatTradeDocuments(buyer_documents),
		intention_duration,
		declaration_duration,
		tradeUsers: users,
		tradeUsersIds: [...buyer_user_ids, ...seller_user_ids],
		buyer_user_ids,
		seller_user_ids,
		terms_id,
		isSellerOrBuyer: seller_user?._key === accountUserId || buyer_user?._key === accountUserId,
		isPrincipal,
		isBroker: buyer_broker_user_id === accountUserId || seller_broker_user_id === accountUserId,
		tradeHasPrincipal: buyer_user_id && seller_user_id,
		brokerId: buyer_broker_user_id ? buyer_broker_user_id : seller_broker_user_id,
		price_increment_type,
		price_increment_basis_shipment_from,
		price_increment_basis_shipment_to,
		price_increment: formattedPriceIncrement,
		price_increment_basis_shipment_mode,
	};
}
