/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useState } from 'react';
import { useDebouncedValue } from 'src/shared/useDebouncedValue';

export const useScrollToActiveElement = (linksRefs, containerRef, activeChapter) => {
	const [scrollPosition, setScrollPosition] = useState();
	const debouncedScrollPosition = useDebouncedValue(scrollPosition, 100);

	useEffect(() => {
		containerRef.current?.scroll({
			top: debouncedScrollPosition,
			behavior: 'smooth',
		});
	}, [debouncedScrollPosition, containerRef]);

	useEffect(() => {
		if (linksRefs.current?.[activeChapter] && containerRef.current) {
			let newPosition;
			const linkBottomPosition =
				linksRefs.current[activeChapter].offsetTop +
				linksRefs.current[activeChapter].clientHeight;
			const scrollBottomPosition =
				containerRef.current.clientHeight + containerRef.current.scrollTop;

			if (linkBottomPosition > scrollBottomPosition) {
				newPosition = linkBottomPosition - containerRef.current.clientHeight;
			} else if (
				linksRefs.current[activeChapter].offsetTop < containerRef.current.scrollTop
			) {
				newPosition = linksRefs.current[activeChapter].offsetTop;
			}

			if (newPosition !== undefined) {
				setScrollPosition(newPosition);
			}
		}
	}, [containerRef, linksRefs, activeChapter]);
};
