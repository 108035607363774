/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled from 'styled-components';

export const ViewsWrapper = styled.div`
	justify-content: space-between;
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 12px;

	${({ $isVisible }) =>
		$isVisible &&
		`
		margin-top: 10px;
		margin-bottom: 8px;
		height: 18px;
  `}
`;

export const Edited = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 12px;
	line-height: 18px;
	color: var(--text-color-secondary);
`;

export const ViewCount = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 12px;
	line-height: 18px;
	color: var(--text-color-secondary);

	${Edited} {
		&:before {
			content: '•';
		}
	}
`;

export const PayCashInfo = styled.div`
	color: var(--neutral-300);
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
`;
