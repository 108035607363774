/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { m100 } from 'src/components/Typography';
import { ReactComponent as NavNext } from './nav_next.svg';
import { ReactComponent as NavPrev } from './nav_prev.svg';

export const NavButton = styled.button`
	position: absolute;
	top: 17px;

	width: 32px;
	height: 32px;
	border-radius: var(--default-border-radius);

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--neutral-700);
	color: var(--neutral-300);

	&:hover {
		background: var(--neutral-300);
		color: white;
	}
`;

export const NavPrevButton = styled(NavButton).attrs({
	type: 'button',
	children: <NavPrev />,
})`
	left: 22px;
`;
export const NavNextButton = styled(NavButton).attrs({
	type: 'button',
	children: <NavNext />,
})`
	right: 22px;
`;

export const Section = styled.section`
	width: 100%;
	background-color: var(--date-picker-background);
	border-bottom-left-radius: var(--large-border-radius);
	border-bottom-right-radius: var(--large-border-radius);
	${m100}
`;
