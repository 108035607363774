/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { List } from 'semantic-ui-react';

export const StyledListItem = styled(List.Item)`
	.ui.selection.list &.item.active {
		background-color: var(--date-picker-active-background);
		color: var(--text-color-default);
	}
	.ui.selection.list &.item {
		color: var(--text-color-secondary);
	}
`;

export const StyledList = styled(List)`
	.ui.list& {
		font-size: 0.8rem;
		margin-top: 0;
	}
`;

export const ListHeader = styled.div`
	position: sticky;
	top: 0;
	background: var(--neutral-850);
	padding-bottom: 8px;
	text-align: ${({ align }) => align || 'center'};
	font-size: 13px;
`;
