/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as Styled from './styled';
import { M50 } from 'src/components/Typography';
import { buildHeaderLink } from '../../../helpers/header';
import { ReactComponent as ChainIcon } from 'src/assets/icons/chain.svg';
import { TradeProductInfo } from '../TradeProductInfo/TradeProductInfo';
import { UsersSection } from '../../UsersSection/UsersSection';
import { ButtonsSection } from '../ButtonsSection/ButtonsSection';

export const ExecutionHeader = ({ tradeData }) => {
	const { t } = useTranslation();
	const { tradeId } = useParams();

	const {
		environment,
		order_id,
		first_counter_id,
		isSellerOrBuyer,
		isBroker,
		isPrincipal,
	} = tradeData;

	const { url, text, dataTest } = buildHeaderLink({
		environment,
		orderId: order_id,
		counterId: first_counter_id,
		t,
	});

	const displayOrderLink = (isSellerOrBuyer && !isPrincipal) || isBroker;

	return (
		<>
			<Styled.HeaderWrapper>
				{displayOrderLink && (
					<Styled.OrderLink to={url}>
						<ChainIcon width="12" height="12" />
						<M50 data-test={dataTest}>{text}</M50>
					</Styled.OrderLink>
				)}
				<Styled.TradeTitle
					$isPrincipal={isPrincipal}
					data-test={`trade-details-header-info-id-${tradeId}`}
				>
					{t('trade')} #{tradeId}
				</Styled.TradeTitle>
			</Styled.HeaderWrapper>
			<TradeProductInfo tradeData={tradeData} />
			<Styled.UserActionSection>
				<UsersSection tradeData={tradeData} />
				<ButtonsSection className="buttons-section" tradeData={tradeData} />
			</Styled.UserActionSection>
		</>
	);
};
