/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const isNil = value => value === null || value === undefined;
export const isNotNil = value => !isNil(value);
export const isNilOrEmpty = value => isNil(value) || value === '';

export const indexBy = (selector, list) =>
	list.reduce((acc, item) => ({ ...acc, [selector(item)]: item }), {});

export const uniqBy = (getKey, list) =>
	list.filter((x, index) => list.findIndex(item => getKey(item) === getKey(x)) === index);

export function toggle(items, item) {
	if (items.some(p => p === item)) {
		return items.filter(p => p !== item);
	}
	return [...items, item];
}

export function toggleBy(getKey, items, toggleElement) {
	if (items.some(item => getKey(item) === getKey(toggleElement))) {
		return items.filter(item => getKey(item) !== getKey(toggleElement));
	}
	return [...items, toggleElement];
}

export function toggleByKey(items, item) {
	return toggleBy(x => x._key, items, item);
}

export function caseInsensitiveEq(a, b) {
	return a?.toLowerCase?.() === b?.toLowerCase?.();
}
