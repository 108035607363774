/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

const DefaultWrapper = ({ value }) => <>{value}</>;

export const DashEmpty = ({ as = DefaultWrapper, ...props }) => {
	const WrappingComponent = as;
	const displayValue = props.value ?? false;

	if (!displayValue) {
		return '---';
	}

	return <WrappingComponent {...props} />;
};
