/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flags } from 'src/featureFlags/Flags';
import { Dropdown } from 'semantic-ui-react';
import { ROUTES } from 'src/constants/routes';
import { Permission } from 'src/containers/Permissions/Permission';
import { Permissions } from 'src/constants/permissions';
import { Avatar, AvatarType, DotType } from 'src/shared/components/Avatar/Avatar';
import { h400, t150 } from 'src/components/Typography';
import { getUserFullName, getUserId, getUserInitials } from 'src/_store/selectors';
import { useEmit } from 'src/shared/useEmit';
import { useSettings } from './useSettings';
import { ReactComponent as LogoutIcon } from 'src/assets/icons/logout_icon.svg';
import { ReactComponent as SupportIcon } from 'src/assets/icons/support_icon.svg';
import { ReactComponent as BookIcon } from 'src/assets/icons/book.svg';
import { ReactComponent as TourIcon } from 'src/assets/icons/product-tour.svg';
import { ReactComponent as UserNetworkFindUsersIcon } from 'src/assets/icons/user_groups.svg';
import { FlagNames } from 'src/constants/flags';
import { LanguageSettings } from './LanguageSettings/LanguageSettings';
import { ThemeSettingsDropdown } from './ThemeSettings/ThemeSettings';
import { VisibilitySettingsDropdown } from './VisibilitySettings/VisibilitySettings';
import { media } from 'src/_helpers';
import { ChatEvent } from 'src/shared/constants';
import { useUserPresenceContext } from 'src/websockets/UserPresenceProvider';

export const UserProfileDropdown = () => {
	const { t } = useTranslation();
	const { first_name, last_name } = useSelector(getUserFullName);
	const userInitials = useSelector(getUserInitials);
	const userId = useSelector(getUserId);

	const {
		currentTheme,
		avatarColor,
		updateTheme,
		hideVisibility,
		switchToLanguage,
		supportConversationId,
	} = useSettings();
	const { isUserActive } = useUserPresenceContext();
	const isOnline = isUserActive(userId);
	const navigate = useNavigate();

	const openSupportChat = useEmit(ChatEvent.Open, {
		userId: supportConversationId,
	});

	return (
		<UserProfileDropdownMenu
			data-test="user-dropdown-menu"
			item
			direction="left"
			icon={null}
			trigger={
				<ProfileSummary>
					<Avatar
						data-test="user-dropdown-menu-avatar"
						size={AvatarType.Medium}
						initials={userInitials}
						backgroundColor={avatarColor || 'var(--neutral-1000)'}
						dotType={isOnline ? DotType.Online : DotType.Offline}
						dotBackgroundColor="var(--neutral-1000)"
					/>
					<UserName data-test="user-dropdown-menu-username">{`${first_name} ${last_name}`}</UserName>
				</ProfileSummary>
			}
		>
			<Dropdown.Menu>
				<Dropdown.Item
					data-test="user-network-find-users"
					icon={<UserNetworkFindUsersIcon className="icon" />}
					text={t('find_users')}
					onClick={() => navigate(`${ROUTES.userNetwork}/${ROUTES.userNetworkAllUsers}`)}
				/>
				<VisibilitySettingsDropdown isOnline={isOnline} hideVisibility={hideVisibility} />
				<Flags
					authorizedFlags={[FlagNames.ChangeLanguage]}
					renderOn={() => <LanguageSettings switchToLanguage={switchToLanguage} />}
				/>
				<ThemeSettingsDropdown currentTheme={currentTheme} updateTheme={updateTheme} />
				<Flags
					authorizedFlags={[FlagNames.Guidebook]}
					renderOn={() => (
						<Dropdown.Item
							data-test="guidebook"
							icon={<BookIcon className="icon" />}
							name="guidebook"
							text={t('user_guide')}
							onClick={() => navigate(`${ROUTES.guidebook}`)}
						/>
					)}
				/>
				<Permission
					name={Permissions.ORDER_CREATE}
					renderOn={() => (
						<Dropdown.Item
							data-test="product_tour"
							icon={<TourIcon className="icon" />}
							name="product_tour"
							text={t('product_tour')}
							onClick={() =>
								navigate(`${ROUTES.root}`, {
									state: { showAppTour: true },
								})
							}
						/>
					)}
				/>
				<Flags
					authorizedFlags={[FlagNames.Chat]}
					renderOn={() => (
						<Dropdown.Item
							data-test="support"
							icon={<SupportIcon className="icon" />}
							name="support"
							text={t('support')}
							onClick={openSupportChat}
						/>
					)}
				/>
				<Dropdown.Item
					data-test="logout"
					icon={<LogoutIcon className="icon" />}
					name="signout"
					text={t('sign_out')}
					onClick={() => navigate(ROUTES.signOut)}
				/>
			</Dropdown.Menu>
		</UserProfileDropdownMenu>
	);
};

const ProfileSummary = styled.div`
	display: flex;
	align-items: center;
`;

const UserProfileDropdownMenu = styled(Dropdown)`
	height: 100%;
	padding-left: 16px !important;
	margin-left: 0 !important;

	.ui.menu &.ui.dropdown .menu {
		padding: 8px 0 !important;
		border-radius: 4px;
		background: var(--neutral-850);
		width: 266px;
		margin-right: 22px;
		margin-top: 8px;

		&:before {
			content: '';
			position: absolute;
			right: 16px;
			top: -7px;
			width: 14px;
			height: 14px;
			background: var(--neutral-850);
			transform: rotate(45deg);
		}

		& .item,
		.item.dropdown-child {
			display: flex;
			gap: 10px;

			&.selected {
				background: var(--neutral-850) !important;

				&:hover {
					background: var(--neutral-800) !important;
				}
			}

			&:hover {
				background: var(--neutral-800) !important;
			}

			& .icon {
				margin-right: 0 !important;
			}

			& svg:first-of-type {
				width: 22px;
			}

			& svg:last-of-type {
				margin-left: auto;
			}

			& span {
				${h400}
			}
		}
	}

	> .item {
		${t150}
		padding: 8px 24px;
		height: 42px;
	}
`;

const UserName = styled.div`
	margin: 0 16px;
	display: none;
	color: #fff;
	${h400}

	${media.l`
		display: block;
	`}
`;
