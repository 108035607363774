/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { ChartContainerID, Orientation } from 'src/constants/chart';
import { useChartFilters } from '../helpers/useChartFilters';
import { MobileChartPlaceholder } from './MobileChartPlaceholder';
import {
	mobileLandscapeChartStateStorageKey,
	mobileLandscapeChartResolutionKey,
} from 'src/constants/storageKeys';
import { useSaveMobileChartSettings } from './useSaveMobileChartSettings';
import * as Styled from '../styled';

export const LandscapeMobileChart = () => {
	const chartActiveFilters = useChartFilters();

	const { entriesLength, isLoading } = useSaveMobileChartSettings({
		orientation: Orientation.Landscape,
		chartStateStorageKey: mobileLandscapeChartStateStorageKey,
		mobileChartResolutionKey: mobileLandscapeChartResolutionKey,
	});

	return (
		<>
			<MobileChartPlaceholder
				entriesLength={entriesLength}
				isLoading={isLoading}
				chartActiveFilters={chartActiveFilters}
			/>
			<Styled.ChartContainer id={ChartContainerID.Mobile} />
		</>
	);
};
