/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoWrapper, Type, Info, InfoTitle } from '../../../sections/common';
import * as Styled from './styled';

export const AdditionalInfoSection = ({ info }) => {
	const { t } = useTranslation();

	return (
		<>
			<InfoTitle>{t('additional_information')}</InfoTitle>
			<InfoWrapper>
				<Styled.InfoItem>
					<Type>{t('trade_date')}</Type>
					<Info data-test="trade-details-additional-info-trade-date">
						{info.created_at}
					</Info>
				</Styled.InfoItem>
			</InfoWrapper>
		</>
	);
};
