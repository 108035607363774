/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';
import { MomentLocales } from 'src/constants/settings';
import { languageDetector } from './_helpers/language';

const presetConfig = process.env.I18N_CONFIG && JSON.parse(process.env.I18N_CONFIG);
const version = process.env.REACT_APP_GIT_COMMIT || 'latest';

i18n.use(initReactI18next)
	.use(Backend)
	.use(languageDetector)
	.init(
		presetConfig || {
			fallbackLng: 'en',
			debug: false,
			backend: {
				// for all available options read the backend's repository readme file
				loadPath: `/js/v1/locales/{{lng}}/{{ns}}.json?version=${version}`,
			},
			interpolation: {
				escapeValue: false, // not needed for react as it escapes by default
			},
			// special options for react-i18next
			// learn more: https://react.i18next.com/components/i18next-instance
			react: {
				useSuspense: false,
			},
			detection: {
				order: ['domainLanguageDetector'],
			},
		},
		() => {
			const momentLocale = MomentLocales[i18n.language] || 'en-gb';
			moment.locale(momentLocale);
		}
	);

export default i18n;
