/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const useCounterPreview = order => {
	const additionalInfoOptionalFields = [
		'harvest_year',
		'speciality',
		'performance_bond_percent',
		'terms_id',
		'contract_number',
		'first_counter_id',
		'file_contract',
	];

	const shouldShowAdditionalInfoSection = additionalInfoOptionalFields.some(key => !!order[key]);

	return {
		shouldShowAdditionalInfoSection,
	};
};
