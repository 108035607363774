/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const ModalType = {
	Info: 'info',
	Success: 'success',
	Warning: 'warning',
};

export const ModalSize = {
	Tiny: 'tiny',
	Large: 'large',
};
