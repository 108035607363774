/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useParams } from 'react-router-dom';

export const useTradeLocation = () => {
	const { tradeId } = useParams();

	return {
		tradeId,
		api: {
			path: 'trade',
			queryParam: 'trade_id',
		},
	};
};
