/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserHomepage } from 'src/_store/selectors';
import { Toast } from 'src/components/Toast';
import { Loader } from 'semantic-ui-react';
import { captureError } from 'src/_helpers/error';
import { ROUTES } from 'src/constants/routes';
import { useMarket } from 'src/_routes/useActiveMarket';
import { Market } from 'src/constants/contract';
import { FlagNames } from 'src/constants/flags';
import { useFeatureFlags } from 'src/featureFlags/FeatureFlagsContext';

export const ERRORS = {
	accessDenied: 'ACCESS_DENIED',
};

export const ErrorBoundary = ({ children }) => {
	const [hasError, setHasError] = useState(false);
	const [shouldRedirect, setShouldRedirect] = useState(false);
	const location = useLocation();
	const userHomepage = useSelector(getUserHomepage);
	const [activeMarket] = useMarket();
	const featureFlagsContext = useFeatureFlags();
	const { isFlagEnabled } = featureFlagsContext;

	// including `hasError` as a dependency will perform infninit reload
	// so it must remain rely only in `location.key`
	useEffect(
		() => {
			if (hasError) {
				setHasError(false);
			}
			if (shouldRedirect) {
				setShouldRedirect(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[location.key]
	);

	if (shouldRedirect) {
		if (
			userHomepage === ROUTES.root &&
			activeMarket === Market.Paper &&
			!isFlagEnabled(FlagNames.PaperOtcOrders)
		) {
			return <Navigate to={ROUTES.exchange} />;
		}

		return <Navigate to={userHomepage} />;
	}

	return (
		<ErrorBoundaryInner
			hasError={hasError}
			setHasError={setHasError}
			setShouldRedirect={setShouldRedirect}
		>
			{children}
		</ErrorBoundaryInner>
	);
};

export class ErrorBoundaryInner extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Only if the Error name is 'ChunkLoadError' means
		// it's from loading a non-existing chunk and
		// then we can safely full reload the page (with same url)
		if (error?.name === 'ChunkLoadError') {
			captureError(error, 'warning', { method: 'getDerivedStateFromError' });
		}
		return { hasError: true, error };
	}

	componentDidUpdate(prevProps, _previousState) {
		if (!this.props.hasError && prevProps.hasError) {
			this.setState({ hasError: false });
		}
	}

	componentDidCatch(error, errorInfo) {
		if (error.message === ERRORS.accessDenied) {
			this.props.setHasError(false);
			this.props.setShouldRedirect(true);
		} else {
			captureError(error, 'warning', { method: 'componentDidCatch' });
			this.props.setHasError(true);
			this.setState({ ...this.state, hasError: true, error: error });
		}
	}

	render() {
		if (this.props.hasError) {
			return (
				<>
					{this.state?.error?.name !== 'ChunkLoadError' && (
						<Container>
							<Toast
								kind="error"
								message={<Trans i18nKey="something_went_wrong" />}
							/>
						</Container>
					)}
					{this.state?.error?.name === 'ChunkLoadError' && (
						<CenterContainer>
							<Loader active inline="centered" size="large" />
						</CenterContainer>
					)}
				</>
			);
		}

		return this.props.children;
	}
}

const Container = styled.div`
	max-width: 600px;
	margin: 64px auto;
	text-align: center;
`;

const CenterContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
`;
