/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import { h200 } from '../Typography';
import { ReactComponent as CloseIcon } from 'src/assets/icons/cross.svg';
import { media } from 'src/_helpers';

export const InputError = ({ message }) => {
	return (
		<ErrorContainer data-test="error-message-container">
			<CloseIcon width={12} height={12} />
			<ErrorMessage data-test="error-message">{message}</ErrorMessage>
		</ErrorContainer>
	);
};

const ErrorContainer = styled.div`
	display: flex;
	align-items: center;

	svg {
		margin-right: 3px;
		rect {
			fill: var(--red-400);
		}
	}

	${media.ll`
		bottom: 16px;
	`}
`;

const ErrorMessage = styled.span`
	${h200};
	color: var(--red-400);
	position: relative;
	top: 1px;
`;
