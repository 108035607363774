/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { formatValidity } from 'src/_helpers/date';
import * as Styled from './styled';

export const Comment = ({
	counterNumber = 0,
	isOwn,
	message,
	order,
	showLocalTime = false,
	showLongLabel = false,
}) => {
	const { t } = useTranslation();

	const commentAuthor =
		isOwn || !order.hidden || order.is_identity_revealed
			? `${order.user.first_name} ${order.user.last_name}`
			: t('order_creator');

	const titlePrefix =
		counterNumber === 0
			? t('order')
			: showLongLabel
			? `${t('counter')} #${counterNumber}`
			: `#${counterNumber}`;
	const title = `${titlePrefix} - ${commentAuthor}`;
	const time = showLocalTime
		? formatValidity(order.created_at, t)
		: moment(order.created_at).fromNow();

	return (
		<Styled.CommentWrapper $isOwn={isOwn}>
			<Styled.CommentHeader $isOwn={isOwn}>
				<Styled.CommentAuthor>{title}</Styled.CommentAuthor>
				<Styled.CommentSubtitle>({time})</Styled.CommentSubtitle>
			</Styled.CommentHeader>
			<Styled.CommentMessage>{message}</Styled.CommentMessage>
		</Styled.CommentWrapper>
	);
};
