/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTogglable } from 'src/_helpers/useTogglable';
import {
	Modal,
	ModalHeaderWrapper,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalSubTitleWithMargin,
	ModalTitle,
	CancelButton,
} from 'src/components/Modal';
import { ModalSize, ModalType } from 'src/constants/modal';
import { NegativeButton } from 'src/components/Buttons/NegativeButton';

export const CancelNavigationModal = ({ trigger, onConfirm }) => {
	const [isOpen, { open, close }] = useTogglable();
	const { t } = useTranslation();

	const handleConfirm = () => {
		close();
		onConfirm?.();
	};

	return (
		<Modal
			data-test="cancel-navigation-modal"
			size={ModalSize.Tiny}
			type={ModalType.Warning}
			open={open}
			close={close}
			isOpen={isOpen}
			trigger={trigger}
			closeOnDimmerClick={false}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{t('discard_changes_title')}</ModalTitle>
				<ModalSubTitleWithMargin>
					{t('your_changes_will_not_be_saved')}
				</ModalSubTitleWithMargin>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<NegativeButton onClick={handleConfirm}>
					{t('discard_changes_button')}
				</NegativeButton>
			</ButtonsWrapper>
		</Modal>
	);
};
