/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CancelButton, ModalTitle } from 'src/components/Modal';
import { LoaderSpinning } from 'src/components/Layout/LandingForm';
import { ModalSize } from 'src/constants/modal';
import { UserAvatar } from 'src/components/UserAvatar/UserAvatar';
import * as Styled from './styled';

export const UsersModal = ({
	trigger,
	openModal,
	closeModal,
	isModalOpen,
	storeModalsData,
	users,
	canBeRemoved = () => false,
	isLoading,
	dataTestPrefix = 'trade',
}) => {
	const { t } = useTranslation();

	return (
		<>
			<Styled.StyledModal
				data-test="trade-details-users-modal"
				size={ModalSize.Tiny}
				close={() => closeModal(0)}
				isOpen={isModalOpen(0)}
				open={() => openModal(0)}
				trigger={trigger}
			>
				<Styled.StyledModalHeaderWrapper>
					<ModalTitle>{t('users')}</ModalTitle>
				</Styled.StyledModalHeaderWrapper>
				<Styled.ModalContent>
					{isLoading ? (
						<Styled.LoaderWrapper>
							<LoaderSpinning />
						</Styled.LoaderWrapper>
					) : (
						<Styled.UserList>
							{users.map(user => (
								<Styled.User
									key={user._key}
									data-test={`${dataTestPrefix}-user-${user._key}`}
								>
									<UserAvatar
										onModalActionClick={() => closeModal(0)}
										user={user}
									/>
									{canBeRemoved(user._key) && (
										<Styled.RemoveIconWrapper>
											<Styled.StyledRemoveIcon
												onClick={() => {
													storeModalsData(0, {
														traderId: user._key,
														traderName: user.name,
													});
													openModal(1);
												}}
												data-test={`remove-trade-user-${user._key}`}
											/>
										</Styled.RemoveIconWrapper>
									)}
								</Styled.User>
							))}
						</Styled.UserList>
					)}
				</Styled.ModalContent>
				<Styled.StyledButtonsWrapper>
					<CancelButton onClick={() => closeModal(0)} text="close" />
				</Styled.StyledButtonsWrapper>
			</Styled.StyledModal>
		</>
	);
};
