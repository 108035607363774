/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';

export const calculateClosestFuturesMonth = ({ startDate, endDate }, futuresMonthOptions) => {
	if (
		!startDate ||
		!endDate ||
		!futuresMonthOptions ||
		moment(startDate).utc().month() !== moment(endDate).utc().month()
	) {
		return;
	}

	const ts = moment(startDate).valueOf();

	return futuresMonthOptions.find(({ value }) => value >= ts);
};
