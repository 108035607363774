/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { t100 } from 'src/components/Typography';

export const RichTextBody = styled.div`
	${t100};
	color: rgba(var(--neutral-200-rgb), 0.8);
	overflow-wrap: break-word;

	a {
		color: var(--green-400);
		text-decoration: underline;

		&:hover {
			opacity: 0.5;
		}
	}

	& h1,
	h2 {
		color: #fff;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	& h1 {
		font-size: 20px;
		font-weight: var(--h-700-font-weight);
		letter-spacing: var(--h-700-letter-spacing);
		line-height: var(--h-700-line-height);
	}

	& h2 {
		font-size: 16px;
		font-weight: var(--h-700-font-weight);
		letter-spacing: var(--h-700-letter-spacing);
		line-height: var(--h-700-line-height);
	}

	& p {
		font-size: 13px;
	}

	& img {
		cursor: pointer;
	}

	span.guidebook-pointer {
		display: inline-block;
		text-align: center;
		height: 16px;
		width: 16px;
		background-color: #ffd856;
		color: #090c1b;
		border-radius: 50%;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 16px;
		vertical-align: text-bottom;
	}

	ul {
		list-style-type: disc;
		padding-left: 24px;
		margin-block-start: 1rem;
		margin-block-end: 1rem;
		ul {
			list-style-type: circle;
			ul {
				list-style-type: square;
			}
		}
		p {
			margin-bottom: 0;
		}
	}
`;

export const RichTextEditBody = styled.div`
	${t100};
	color: #fff;
	overflow-wrap: break-word;

	a {
		color: var(--green-400);
		text-decoration: underline;

		&:hover {
			opacity: 0.5;
		}
	}

	& h1,
	h2 {
		color: #fff;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	& h1 {
		font-size: 20px;
		font-weight: var(--h-700-font-weight);
		letter-spacing: var(--h-700-letter-spacing);
		line-height: var(--h-700-line-height);
	}

	& h2 {
		font-size: 16px;
		font-weight: var(--h-700-font-weight);
		letter-spacing: var(--h-700-letter-spacing);
		line-height: var(--h-700-line-height);
	}

	& p {
		font-size: 13px;
	}

	& img {
		cursor: pointer;
	}
`;
