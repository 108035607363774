/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const WebsocketChannelType = {
	Private: 'private', // user:userId
	Presence: 'status',
	NewExchangeOrder: 'new_exchange_order', // Note: Legacy part: channel and event type have the same names
	ExchangeUpdates: 'exchange:updates', // Note: Supports 'withdrawn_order' & 'edited_exchange_order' event types
};

export const PrivateWebsocketEventType = {
	NewOtcOrder: 'new_otc_order', // Note: comes twice (version for owner and counterparties; this is a BE bug)
	NewNotification: 'new_notification',
	NotificationsRead: 'notifications_read',
	NewCounter: 'new_counter',
	Chat: 'chat_new_message',
	NewTrade: 'new_trade',
	ContactAdded: 'new_contact',
	ContactRemoved: 'removed_contact',
	EditedOTCOrder: 'edited_otc_order',
};

export const PublicWebsocketEventType = {
	NewExchangeOrder: 'new_exchange_order',
	WithdrawnOrder: 'withdrawn_order',
	EditedExchangeOrder: 'edited_exchange_order',
	NewTradePrice: 'new_trade_price',
};
