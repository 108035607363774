/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import { PriceType, tCurrency, tPriceType } from 'src/constants/contract';
import { formatPrice } from 'src/_helpers/price';
import { formatDateRange, parseDateStringAsUTC } from 'src/_helpers/date';
import { VolumeDisplay } from 'src/components/Volume';
import { useTranslation } from 'react-i18next';
import { OverflowArray } from 'src/components/Tooltip/OverflowText';
import { futuresContractLabel } from 'src/_helpers/futuresContracts';
import { formatOriginsList, OriginsListMode } from 'src/_helpers/origins';
import { CompanyHeader } from 'src/constants/contract';
import { useSellerBrokerBuyer } from 'src/_helpers/order';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { tPriceUnit } from 'src/constants/product';

export const useAcceptPhysicalCounterSummary = (order, counters) => {
	const { t } = useTranslation();

	const orderAndCounters = [order, ...counters];
	const lastCounter = orderAndCounters.at(-1);

	const { isBrokerOrder } = useOrderDetails(order);

	const { sellerCompany, buyerCompany, brokerCompany } = useSellerBrokerBuyer(order, counters);

	const isBasisPriceType = lastCounter.price_type === PriceType.Basis;

	const originValue =
		order?.origin_countries || (order?.origin_country ? [order.origin_country] : null);

	const incoWithPortNames = lastCounter.primary_ports
		.map((port, index) => {
			if (index === 0) {
				return `${lastCounter.inco.name}, ${port?.name}`;
			}

			return port?.name;
		})
		.filter(Boolean);

	const dataToDisplay = [
		{
			title: t('seller'),
			value: sellerCompany,
		},
		isBrokerOrder && {
			title: `${t('broker')} (${t(CompanyHeader[order.order_type])})`,
			value: brokerCompany,
		},
		{
			title: t('buyer'),
			value: buyerCompany,
		},
		{
			title: t('product'),
			value: `${order.product.name}, ${order.grade.name}`,
		},
		{
			title: t('inco'),
			value: <OverflowArray texts={incoWithPortNames} />,
		},
		originValue && {
			title: t('origin'),
			value: formatOriginsList(originValue, 'name', OriginsListMode.tooltip, t),
		},
		{
			title: t('quantity'),
			value: <VolumeDisplay value={lastCounter.volume} />,
		},
		{
			title: t('shipment'),
			value: formatDateRange({
				format: lastCounter.delivery_mode,
				startDate: parseDateStringAsUTC(lastCounter.delivery_date_from),
				endDate: parseDateStringAsUTC(lastCounter.delivery_date_to),
			}),
		},
		{
			title: t('price_type'),
			value: tPriceType(t, lastCounter.price_type),
		},
		{
			title: tPriceUnit(t, lastCounter.price_unit),
			value: formatPrice(lastCounter.price, {
				currency: tCurrency(t, lastCounter.currency),
			}),
		},
		isBasisPriceType && {
			title: t('futures_contract'),
			value: `${futuresContractLabel(
				t,
				lastCounter.product.futures_contracts,
				lastCounter.futures_contract
			)} ${moment(lastCounter.futures_contract_date).format('MMM YYYY')}`,
		},
	].filter(Boolean);

	return { dataToDisplay };
};
