/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useManageFiles } from 'src/_helpers/useManageFiles';
import { CUSTOM_ADDITIONAL_ORDER_DOCUMENTS } from 'src/constants/files';
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';

export const useDocuments = () => {
	const { setValue } = useFormContext();

	const { allDocumentsUploadedValue, filesArrayValue } = useWatchPhysicalFields();

	const { files, onChangeHandler, deleteHandler, successHandler } = useManageFiles(
		null,
		filesArrayValue
	);

	const handleOnChangeHandler = ({ target }) => {
		onChangeHandler({ target }, () => setValue('allDocumentsUploaded', false));
	};

	useEffect(() => {
		const uploadedFilesCount = files.filter(file => file?.isUploaded).length;

		if (uploadedFilesCount >= files.filter(file => file).length) {
			setValue('allDocumentsUploaded', true);
			const fileIds = files.filter(file => file).map(({ file }) => file.id);
			setValue('documents', fileIds);
		} else {
			setValue('allDocumentsUploaded', false);
		}
	}, [files, setValue]);

	useEffect(() => {
		setValue(
			'documentNames',
			files.map(({ file }) => ({ name: file.name, id: file.id }))
		);
		setValue('filesArray', files);
	}, [files, setValue]);

	return {
		accept: CUSTOM_ADDITIONAL_ORDER_DOCUMENTS,
		onChange: handleOnChangeHandler,
		onDelete: deleteHandler,
		onUploaded: successHandler,
		files,
		isUploading: !allDocumentsUploadedValue,
	};
};
