/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const CurrencyUnitSeparator = styled.span`
	background: var(--neutral-600);
	width: 2px;
	height: 22px;
	margin-right: 8px;
	border-radius: 50%;
`;

export const WideField = styled.div`
	grid-column: span 2;

	& .ui.active.selection.dropdown.upward .menu {
		margin-bottom: 12px;
	}
`;
