/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const ChatEvent = {
	Open: 'chat.open',
	Close: 'chat.close',
	Toggle: 'chat.toggle',
	MarkConversationsAsRead: 'chat.mark_conversations_as_read',
	VisibilityStatus: 'chat.visibility_status',
	UpdateUserPresenceList: 'chat.update_user_presence_list',
	InviteUser: 'invite_users.open',
	NewMessage: 'chat.new_message',
	UpdateActiveConversationId: 'chat.update_active_conversation_id',
};
