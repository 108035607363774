/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const ROUTES = {
	root: '/',
	exchange: '/exchange',
	otc: '/otc',
	overview: '/overview',
	error: '/error',
	reset: '/reset',
	sendPasswordResetEmail: '/send-password-reset-email',
	myTradebook: 'my-tradebook',
	closedTrades: 'closed-trades',
	execution: '/execution',
	tradeDetails: '/execution/trade/:tradeId',
	myAccount: '/my-account',
	myAccountContacts: 'contacts',
	login: '/login',
	signOut: '/signout',
	chart: '/chart',
	mobileGuide: '/mobile-guide',
	videoGuide: '/video-guide',
	register: '/register',
	registrationCompleted: '/registration-completed',
	resendConfirmationEmail: '/resend-confirmation-email',
	awaitAccountApproval: '/await-account-approval',
	confirmEmail: '/confirm-email',
	kyc: '/kyc',
	kycPendingRequest: 'pending-requests',
	kycActiveConversationsList: 'active-conversations',
	kycActiveConversation: 'active-conversations/:conversationId',
	kycMyDocuments: 'my-kyc-documents',
	kycMyDocumentsVault: 'my-kyc-documents/:vaultId',
	kycTrackRecord: 'track-record',
	news: '/news',
	articleDetails: ':articleId',
	newArticle: 'new-article',
	editArticle: 'edit-article/:articleId',
	userNetwork: '/user-network',
	userNetworkApprovedContacts: 'approved-contacts',
	userNetworkContacts: 'contacts',
	userNetworkUserId: ':userId',
	userNetworkAllUsers: 'all-users',
	userNetworkGroups: 'groups',
	userNetworkGroupDetails: ':groupId',
	userNetworkGroupCreate: 'create',
	userNetworkGroupEdit: 'edit',
	userNetworkGroupRemove: 'remove',
	userNetworkTeams: 'teams',
	userNetworkTeamDetails: ':teamId',
	userNetworkTeamCreate: 'create',
	userNetworkTeamEdit: 'edit',
	userNetworkTeamRemove: 'remove',
	userNetworkInvites: 'invites',
	userNetworkInvitesSent: 'sent',
	userNetworkInvitesReceived: 'received',
	userNetworkInvitesArchived: 'archived',
	teamsDashboard: '/teams',
	guidebook: '/guide',
	physicalExecutionTradeConfirmation: 'confirmation',
	physicalExecutionSignatures: 'signatures',
	physicalExecutionShippingAdvice: 'shipping-advice',
	physicalExecutionVesselNomination: 'vessel-nomination',
	physicalExecutionInstructions: 'instructions',
	physicalExecutionShippingDocuments: 'shipping-documents',
	physicalExecutionShippingTender: 'shipping-tender',
	physicalExecutionInvoicing: 'invoicing',
	deleteAccount: '/delete-account',
	deleteAccountRequestSent: '/delete-account-request-sent',
};

export const PUBLIC_ROUTES = [
	ROUTES.reset,
	ROUTES.sendPasswordResetEmail,
	ROUTES.root,
	ROUTES.register,
	ROUTES.registrationCompleted,
	ROUTES.resendConfirmationEmail,
	ROUTES.error,
	ROUTES.confirmEmail,
];

export const STANDALONE_ROUTES = [ROUTES.chart, ROUTES.mobileGuide, ROUTES.videoGuide];

export const HEADLESS_ROUTES = [ROUTES.reset, ROUTES.sendPasswordResetEmail, ...STANDALONE_ROUTES];

export const isHeadlessRoute = route => HEADLESS_ROUTES.includes(route);

export const isStandaloneRoute = route => STANDALONE_ROUTES.includes(route);
