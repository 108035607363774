/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { Modal as SemanticUIModal } from 'semantic-ui-react';
import { H600, T100 } from 'src/components/Typography/fonts';

export const StyledModal = styled(SemanticUIModal)`
	background: var(--neutral-850);

	&.ui.modal {
		border-radius: var(--bigger-border-radius);

		.content {
			border-radius: var(--bigger-border-radius);
		}
	}
`;

export const ModalContent = styled(SemanticUIModal.Content)`
	min-height: 150px;

	&:before {
		content: '';
		width: 100%;
		height: 200px;
		position: absolute;
		border-radius: var(--bigger-border-radius);
		top: 0;
		left: 0;
		background: linear-gradient(
			180deg,
			${props => props.$theme.gradient} 0%,
			rgba(33, 37, 70, 0) 100%
		);
	}
`;

export const IconWrapper = styled.div`
	width: 56px;
	height: 56px;
	border-radius: 50%;
	background: var(--neutral-850);
	margin-bottom: 16px;
	display: flex;
	justify-content: center;
	align-items: center;

	.modal-warning-icon {
		position: relative;
		top: 4px;
	}
`;

export const ButtonsWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 16px;
`;

export const ModalHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

export const ModalTitle = styled(H600)`
	color: #fff;
	margin-bottom: 16px;
`;

export const ModalSubTitle = styled(T100)`
	color: var(--neutral-300);
	text-align: center;
`;

export const ModalSubTitleWithMargin = styled(T100)`
	color: var(--neutral-300);
	text-align: center;
	margin-bottom: 40px;
`;

export const ChildrenContainer = styled.div`
	position: relative;
`;

export const CloseButton = styled.div`
	position: absolute;
	right: 24px;
	top: 24px;
	cursor: pointer;
	color: var(--neutral-200);

	&:hover {
		color: var(--white);
	}
`;
