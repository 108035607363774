/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useEffect, useState, createContext, useContext } from 'react';
import { ToastNotification } from './Toast';

export const ToastContext = createContext({ addToast: () => {}, removeAllToasts: () => {} });

export function useToast() {
	return useContext(ToastContext);
}

export function ToastProvider({ children, defaultTimeout = 3000 }) {
	const [toasts, setToasts] = useState([]);
	const [permanentToasts, setPermanentToasts] = useState(0);

	const increasePermanentToastsCount = useCallback(
		() => setPermanentToasts(count => count + 1),
		[]
	);
	const decreasePermanentToastsCount = useCallback(
		() => setPermanentToasts(count => count - 1),
		[]
	);

	const removeToast = useCallback(
		toastId => {
			setToasts(toasts => toasts.filter(t => t.id !== toastId));
		},
		[setToasts]
	);

	const removeAllToasts = useCallback(() => {
		setToasts([]);
	}, []);

	useEffect(() => {
		const timers = toasts
			.filter(toast => !toast.fixed)
			.map(toast => {
				return setTimeout(() => removeToast(toast.id), toast.timeout || defaultTimeout);
			});
		return () => {
			timers.forEach(clearTimeout);
		};
	}, [defaultTimeout, toasts, removeToast]);

	const addToast = useCallback(toast => {
		setToasts(toasts => [
			...toasts,
			{
				...toast,
				id: Math.random(),
			},
		]);
	}, []);

	const state = {
		addToast,
		removeAllToasts,
		permanentToasts,
		increasePermanentToastsCount,
		decreasePermanentToastsCount,
	};

	return (
		<ToastContext.Provider value={state}>
			{children}
			<div>
				{toasts.map(toast => (
					<ToastNotification
						key={toast.id}
						onClose={() => removeToast(toast.id)}
						{...toast}
					/>
				))}
			</div>
		</ToastContext.Provider>
	);
}
