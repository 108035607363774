/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Fragment, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@headlessui/react';
import { TextInput } from 'src/designSystem/Form/TextInput/TextInput';
import { useOnClickOutside } from 'src/_helpers/useOnClickOutside';
import { arraysOfObjectsEqual } from 'src/_helpers';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import {
	getDisplayValueDefault,
	sortAndFilterOptions,
	sortAndFilterGroups,
	getItemsByIds,
	getGroupItemsByIds,
	renderOptionDefault,
} from './helpers';
import * as Styled from './styled';

export const MultiSelect = ({
	options = [],
	groups = [],
	label = '',
	placeholder = '',
	error = false,
	disabled = false,
	search = false,
	value = [],
	onChange = () => {},
	dataTest,
	renderOption = renderOptionDefault,
	getDisplayValue = getDisplayValueDefault,
	OptionsWrapper = Styled.OptionsWrapper,
	isValueDependent = false,
	noDataPlaceholder: NoDataComponent = NoSearchDataPlaceholder,
}) => {
	const [selectedOptions, setSelectedOptions] = useState(
		groups.length ? getGroupItemsByIds(groups, value) : getItemsByIds(options, value)
	);
	const [query, setQuery] = useState('');

	const buttonRef = useRef();
	const inputRef = useRef();

	const optionsToDisplay = groups.length
		? sortAndFilterGroups(groups, query, selectedOptions)
		: sortAndFilterOptions(options, query, selectedOptions);

	const noOptionsToDisplay = optionsToDisplay.filter(o => !o.header).length === 0;

	const clearQuery = () => {
		setQuery('');
		inputRef.current.value = '';
	};

	const handleChange = options => {
		const sortedOptions = [...options].sort((a, b) => {
			const groupScore = +!!b.group - +!!a.group;
			const alphaScore = a.text.toLowerCase().localeCompare(b.text.toLowerCase());
			return groupScore * 10 + alphaScore;
		});

		setSelectedOptions(sortedOptions);
		onChange(sortedOptions);
	};

	const handleOptionClick = () => {
		clearQuery();
	};

	const handleSelectionClear = () => {
		clearQuery();
		setSelectedOptions([]);
		onChange([]);
	};

	// THIS USE EFFECT IS USED AT THE MOMENT ONLY FOR ORIGINS IN PHYSICAL CREATE ORDER
	useEffect(() => {
		const isArrayOfStrings = value.every(i => typeof i === 'string');
		if (
			isValueDependent &&
			(!isArrayOfStrings || !value.length) &&
			!arraysOfObjectsEqual(value, selectedOptions)
		) {
			setSelectedOptions(value);
		}
	}, [value, setSelectedOptions, selectedOptions, isValueDependent]);

	const handleInputChange = event => setQuery(event.target.value);

	useOnClickOutside(
		buttonRef,
		() => {
			clearQuery();
		},
		false
	);

	return (
		<Styled.Wrapper data-test={dataTest} className="multi-select-wrapper">
			{label && <Styled.Label className={clsx({ error })}>{label}</Styled.Label>}
			<Combobox
				as="div"
				value={selectedOptions}
				onChange={handleChange}
				multiple
				disabled={disabled}
			>
				{({ open }) => (
					<Styled.ContentWrapper className={clsx('content-wrapper', { search })}>
						{search && <Styled.SearchIcon className="search-icon" />}
						<Styled.InputWrapper>
							<Combobox.Input
								as={TextInput}
								error={error}
								onChange={handleInputChange}
								placeholder={selectedOptions.length === 0 ? placeholder : ''}
								className="combobox-text-input"
								ref={inputRef}
							/>
							{query === '' && (
								<label data-test="multiselect-label">
									{getDisplayValue(selectedOptions)}
								</label>
							)}
							<Combobox.Button onClick={clearQuery} ref={buttonRef} />
							{selectedOptions.length > 0 ? (
								<Styled.ClearIcon
									className="clear-icon"
									onClick={handleSelectionClear}
									data-test="multiselect-clear"
								/>
							) : (
								<Styled.ExpandIcon className={clsx('expand-icon', { open })} />
							)}
						</Styled.InputWrapper>
						<OptionsWrapper className="options-container">
							<Combobox.Options className="options" data-test="multiselect-options">
								{noOptionsToDisplay ? (
									inputRef.current?.value ? (
										<NoSearchDataPlaceholder />
									) : (
										<NoDataComponent />
									)
								) : (
									optionsToDisplay.map(option => (
										<Combobox.Option
											as={Fragment}
											key={option.id}
											value={option}
											onClick={handleOptionClick}
											disabled={option.header}
										>
											{({ selected }) => (
												<Styled.OptionWrapper
													className={clsx(
														{
															selected,
															header: option.header,
														},
														'options-container'
													)}
													data-test={`option-${option.id}`}
													key={`option-${option.id}${
														selected ? '-selected' : ''
													}`}
												>
													<OverflowText>
														{renderOption(option)}
													</OverflowText>
												</Styled.OptionWrapper>
											)}
										</Combobox.Option>
									))
								)}
							</Combobox.Options>
						</OptionsWrapper>
					</Styled.ContentWrapper>
				)}
			</Combobox>
		</Styled.Wrapper>
	);
};

const NoSearchDataPlaceholder = () => {
	const { t } = useTranslation();

	return (
		<Styled.OptionWrapper className="disabled" data-test="multiselect-no-results">
			<span>{t('no_results_found')}</span>
		</Styled.OptionWrapper>
	);
};
