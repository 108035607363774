/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { ContextualGuide } from './ContextualGuide';

export const ContextualGuideMarkets = () => <ContextualGuide i18nKey="markets" />;

export const ContextualGuideOrderbook = () => <ContextualGuide i18nKey="orderbook" />;

export const ContextualGuideMyTabs = () => <ContextualGuide i18nKey="my_tabs" />;

export const ContextualGuideChart = () => <ContextualGuide i18nKey="chart" />;

export const ContextualGuidePhysicalOrderQuote = () => (
	<ContextualGuide i18nKey="physical_order_quote" />
);

export const ContextualGuidePhysicalOrderPricing = () => (
	<ContextualGuide i18nKey="physical_order_pricing" />
);

export const ContextualGuidePhysicalOrderShipment = () => (
	<ContextualGuide i18nKey="physical_order_shipment" />
);

export const ContextualGuidePhysicalOrderAdditionalInfo = () => (
	<ContextualGuide i18nKey="physical_order_additional_info" />
);

export const ContextualGuidePaperOrder = () => <ContextualGuide i18nKey="paper_order" />;

export const ContextualGuideContacts = () => <ContextualGuide i18nKey="contacts" />;
