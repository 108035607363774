/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';
import { Link } from 'src/components/Link/Link';
import { h800 } from 'src/components/Typography';

export const TradeTitle = styled.h3`
	${h800}
	font-size: 18px;

	${media.ll`
		font-size: 20px;
	`}

	${media.xl`
		${h800}
	`}

	${({ $isPrincipal }) =>
		$isPrincipal &&
		`
    margin-top: 16px;
  `}
`;

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	justify-content: center;
	gap: 8px;

	${media.xl`
		padding-left: 48px;
	`}
`;

export const OrderLink = styled(Link)`
	display: flex;
	gap: 8px;
	color: var(--neutral-300);
	width: fit-content;
	align-items: center;

	svg {
		width: 16px;
		height: 16px;
	}

	&:hover {
		color: #fff;
	}
`;

export const UserActionSection = styled.div`
	display: flex;
	gap: 16px;
	justify-self: end;
	align-items: center;
	padding-right: 20px;

	${media.ll`
		gap: 24px;
	`}

	${media.xl`
		padding-right:48px
	`}
`;
