/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, useMemo } from 'react';
import i18n from 'i18next';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { h400, t100, m50 } from 'src/components/Typography/fonts';
import moment from 'moment';
import { getCapitalLetter } from 'src/_helpers/getCapitalLetter';
import { Avatar } from 'src/shared/components/Avatar/Avatar';
import { ReactComponent as Chevron } from 'src/assets/icons/chevron.svg';
import { UserStatus } from 'src/components/UserStatus/UserStatus';
import { activityTypes } from 'src/constants/activityLogTypes';

export const ActivityLogItem = ({
	item: { key, date, userName, info, icon, type, userStatus },
	users,
	index,
}) => {
	const [openItem, setOpenItem] = useState(false);
	const { t } = useTranslation();

	const usersData = useMemo(
		() =>
			users.map(({ user_name, user_id, created_at, user_status }) => {
				const name = user_name.split(' ');
				const userCapital = `${getCapitalLetter(name[0])}${getCapitalLetter(name[1])}`;
				return {
					user_id,
					userCapital,
					user_name,
					user_status,
					created_at: moment(created_at).format('LLLL'),
				};
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[users, i18n.language]
	);

	const shouldShowTraderName = type !== activityTypes.ACTIVITY_TYPE_TRADE_CREATED;

	return (
		<LogItem
			key={key}
			onClick={() => setOpenItem(prev => !prev)}
			data-test={`activity-log-item-${index}`}
		>
			<LogImage>{icon}</LogImage>
			<div>
				<Status className="log-item--status">
					{shouldShowTraderName && (
						<>
							<Trader>{userName}</Trader>
							<UserStatus status={userStatus} />
							<span>&nbsp;</span>
						</>
					)}
					{info}
				</Status>
				<LogDateUsersWrapper>
					<Date>{moment(date).format('LLL')}</Date>
					{usersData.length ? (
						<>
							<SeenByLabel $isOpen={openItem}>{t('seen_by')}</SeenByLabel>
							<UsersList data-test="activity-log-users-list">
								{usersData.map(({ userCapital, user_id }, index) => {
									if (index > 2) {
										return null;
									}

									const avatarInitials =
										index === 2 ? `+${usersData.length - 2}` : userCapital;
									return (
										<UserItem key={`avatar-${user_id}`} index={index}>
											<Avatar
												initials={avatarInitials}
												backgroundColor={'var(--neutral-500)'}
												withBorder
											/>
										</UserItem>
									);
								})}
							</UsersList>
							<StyledChevron $isOpen={openItem} />
						</>
					) : null}
				</LogDateUsersWrapper>
				{usersData.length ? (
					<ReceiptsList isOpen={openItem}>
						{usersData.map(({ user_name, created_at, user_id, user_status }) => {
							return (
								<ReceiptItem key={`receipt-item-${user_id}`}>
									{user_name} <UserStatus status={user_status} />-{' '}
									<Date>{created_at}</Date>
								</ReceiptItem>
							);
						})}
					</ReceiptsList>
				) : null}
			</div>
		</LogItem>
	);
};

const LogItem = styled.div`
	display: flex;
	justify-content: flex-start;
	position: relative;

	&:after {
		position: absolute;
		bottom: -12px;
		left: calc(32px / 2 - 1px);
		content: '';
		display: block;
		width: 2px;
		height: calc(100% - 24px);
		background: #292f4e;
	}

	&:last-child {
		&:after {
			display: none;
		}
	}

	.log-item--status > .deactivated-icon {
		left: 0px;
		margin-right: 0px;
	}

	.deactivated-icon {
		min-width: 14px;
		width: 14px;
		position: relative;
		top: 5px;
		left: -2px;
		margin-right: 3px;
	}

	&:hover {
		cursor: pointer;
		.log-item--status {
			color: var(--text-color-default);
			text-decoration: underline;
			text-decoration-color: white;
		}

		.log-item--status > .deactivated-icon {
			color: white;
		}
	}
`;

const Status = styled.div`
	margin-bottom: 4px;
	${t100}
	color: var(--neutral-300);
	word-break: break-word;

	.highlight {
		color: var(--text-color-default);
	}
`;

const Trader = styled.span`
	${h400}
	color: var(--text-color-default);
`;

const Date = styled.span`
	${m50}
	color: var(--neutral-300);
`;

const SeenByLabel = styled.span`
	position: relative;
	${m50}
	color: var(--neutral-300);
	max-width: 0px;
	margin-left: 0;
	opacity: 0;
	transition: all 100ms 0s;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: -8px;
		transform: translateY(-50%);
		display: block;
		width: 2px;
		height: 2px;
		border-radius: 50%;
		background: var(--neutral-300);
	}

	${({ $isOpen }) =>
		$isOpen &&
		`
         margin-left: 16px;
         max-width: 999px;
         opacity: 1;
         transition: all 400ms 0s;
       `}
`;

const LogImage = styled.div`
	margin-right: 16px;
`;

const LogDateUsersWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const UsersList = styled.ul`
	position: relative;
	display: flex;
	margin: 0px 8px;
`;

const UserItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;

	&:not(:last-child) {
		margin-right: -4px;
	}
`;

const StyledChevron = styled(Chevron)`
	color: var(--neutral-300);

	${({ $isOpen }) => $isOpen && `transform: rotate(180deg);`};
`;

const ReceiptsList = styled.ul`
	max-height: 0px;
	opacity: 0;
	transition: max-height 200ms ease, opacity 200ms ease;

	${({ isOpen }) =>
		isOpen &&
		`
         margin-top: 8px;
         transition: max-height 300ms ease, opacity 200ms ease 200ms;
         max-height: 999px;
         opacity: 1;
 
         li {
             max-height: 999px;
             transition: max-height 0s;
         }
       `}
`;

const ReceiptItem = styled.li`
	${m50}
	margin-bottom: 5px;
	max-height: 0px;
	transition: max-height 200ms ease;
`;
