/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export function once(fn) {
	let result;
	let executed = false;

	if (typeof fn !== 'function') {
		throw new TypeError('Expected a function');
	}
	return (...args) => {
		if (!executed) {
			executed = true;
			result = fn.apply(this, args);
		}
		return result;
	};
}
