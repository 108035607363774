/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { H900, H600 } from 'src/components/Typography';
import { tCurrency } from 'src/constants/contract';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { useSpreadPricePill } from './useSpreadPricePill';
import * as Styled from './styled';

export const SpreadPricePill = ({ order, changed }) => {
	const { t } = useTranslation();
	const { isExpired, isTerminatedOrNotAvailable } = useOrderDetails(order);

	const { currency, price_unit, counterType } = order;

	const isCounter = !!counterType;

	const { firstLine, secondLine } = useSpreadPricePill(order);

	return (
		<Styled.SpreadPill
			$isOrderClosed={!isCounter && (isExpired || isTerminatedOrNotAvailable)}
			$isChanged={changed}
			data-test="price-pill"
		>
			<Styled.OrderType>
				<H600 as="span">{firstLine}</H600>
			</Styled.OrderType>
			<Styled.PriceDetails>
				<H600 as="span">{`${tCurrency(t, currency)}/${t(`price_unit_short_${price_unit}`, {
					lng: 'en',
				})}`}</H600>
				<H900 as="span">{secondLine}</H900>
			</Styled.PriceDetails>
		</Styled.SpreadPill>
	);
};
