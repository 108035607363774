/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-modal.svg';
import { ReactComponent as WarnIcon } from 'src/assets/icons/modal_warning_icon.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/modal_check_icon.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/modal_info_icon.svg';
import { ModalType, ModalSize } from 'src/constants/modal';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import * as Styled from './styled';

const getModalTheme = type => {
	switch (type) {
		case ModalType.Warning:
			return {
				color: 'var(--red-400)',
				gradient: 'rgba(237, 82, 82, 0.2)',
			};

		case ModalType.Success:
			return {
				color: 'var(--green-400)',
				gradient: 'rgba(1, 254, 222, 0.12)',
			};

		default:
			return {
				color: 'var(--primary-400)',
				gradient: 'rgba(54, 60, 219, 0.2)',
			};
	}
};

const IconType = {
	[ModalType.Warning]: <WarnIcon className="modal-warning-icon" />,
	[ModalType.Success]: <CheckIcon className="modal-success-icon" />,
	[ModalType.Info]: <InfoIcon className="modal-info-icon" />,
};

export const Modal = ({
	size = ModalSize.Tiny,
	type,
	trigger,
	children,
	isOpen,
	open,
	close,
	closeOnDimmerClick,
	withCloseIcon = false,
	...props
}) => (
	<Styled.StyledModal
		trigger={trigger}
		size={size}
		onOpen={open}
		onClose={close}
		open={isOpen}
		closeOnDimmerClick={closeOnDimmerClick}
		{...props}
	>
		<Styled.ModalContent $theme={getModalTheme(type)}>
			<Styled.ChildrenContainer>{children}</Styled.ChildrenContainer>
		</Styled.ModalContent>
		{withCloseIcon && (
			<Styled.CloseButton onClick={close}>
				<CloseIcon />
			</Styled.CloseButton>
		)}
	</Styled.StyledModal>
);

export const ModalIconWrapper = ({ type }) => {
	return <Styled.IconWrapper>{IconType[type]}</Styled.IconWrapper>;
};

export const CancelButton = ({ text = 'cancel', ...props }) => {
	const { t } = useTranslation();

	return (
		<SecondaryButton darker {...props}>
			{t(text)}
		</SecondaryButton>
	);
};
