/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { zhCN, enUS } from 'date-fns/locale';
import { h600 } from 'src/components/Typography';

registerLocale('zh-cn', zhCN);
registerLocale('en', enUS);
registerLocale('en-gb', enUS);

export const InlineDatepicker = styled(props => {
	const currentLocale = moment.locale();
	return <DatePicker {...props} calendarClassName={props.className} locale={currentLocale} />;
}).attrs(() => ({ inline: true }))`
	.react-datepicker& {
		width: 416px;
		background-color: inherit;
		color: inherit;
		border: none;
		border-radius: 0;

		.react-datepicker__header {
			background: var(--date-picker-background);
			color: var(--text-color-default);
			border-bottom: 1px solid var(--date-picker-background);
			padding: 22px 14px 0;
		}

		.react-datepicker__month-container {
			color: var(--text-color-secondary);
			width: 416px;
		}

		.react-datepicker__current-month {
			${h600}
			margin-bottom: 24px;
		}

		.react-datepicker__day,
		.react-datepicker__current-month {
			color: var(--text-color-default);
		}

		.react-datepicker__day,
		.react-datepicker__day-name {
			width: 32px;
			line-height: 32px;
			font-size: 13px;
			margin: 2px 12px;
		}

		.react-datepicker__day.react-datepicker__day--outside-month,
		.react-datepicker__day-name {
			color: var(--neutral-300);
		}

		.react-datepicker__day--keyboard-selected,
		.react-datepicker__month-text--keyboard-selected,
		.react-datepicker__quarter-text--keyboard-selected,
		.react-datepicker__year-text--keyboard-selected {
			background-color: var(--date-picker-background);
			border-radius: var(--default-border-radius);
		}

		.react-datepicker__day--selected {
			background-color: var(--date-picker-active-background);
			border-radius: var(--simple-border-radius);
			color: var(--text-color-default);
		}

		.react-datepicker__day--disabled {
			color: var(--neutral-300);
		}

		.react-datepicker__day--disabled:hover {
			color: var(--date-picker-hover-background) !important;
			background-color: transparent !important;
		}

		.react-datepicker__day:hover,
		.react-datepicker__month-text:hover,
		.react-datepicker__quarter-text:hover,
		.react-datepicker__year-text:hover {
			color: var(--text-color-default);
			background-color: var(--date-picker-hover-background);
		}

		.react-datepicker__navigation {
			top: 15px;
			width: 32px;
			height: 32px;
			background-color: var(--neutral-700);
			border: 0;
			border-radius: var(--default-border-radius);

			&:before {
				border-color: var(--neutral-300);
				border-style: solid;
				border-width: 3px 3px 0 0;
				content: '';
				display: block;
				height: 10px;
				width: 10px;
				position: absolute;
				transform: rotate(45deg);
				right: 12px;
				top: 10px;
			}

			&:hover {
				background-color: var(--date-picker-hover-background);

				&:before {
					border-color: #fff;
				}
			}
		}

		.react-datepicker__navigation--previous {
			left: 22px;

			&:before {
				border-width: 0 0 3px 3px;
				right: auto;
				left: 12px;
			}
		}

		.react-datepicker__navigation--next {
			right: 22px;

			&:before {
				border-width: 3px 3px 0 0;
			}
		}
	}
`;
