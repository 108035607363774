/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	DefaultValidityOptions,
	translateTimeRangeOptions,
	formatTradingSessionTime,
} from 'src/_helpers/timeRange';
import * as Styled from './styledTimeRange';

export const TimeRange = ({ time, validityOptions, onTimeChange }) => {
	const { t } = useTranslation();

	const timeOptions =
		validityOptions?.options || translateTimeRangeOptions(DefaultValidityOptions, t);
	const tradingSessions = validityOptions?.tradingSessions || null;

	return (
		<Styled.Section data-test="select-time-range">
			<Styled.PeriodContainer data-test="quick-date-options">
				{timeOptions.map(option => (
					<Styled.PeriodButton
						type="button"
						aria-label={`Choose ${option.label}`}
						key={option.key}
						onClick={() => onTimeChange(option.key)}
						className={time === option.key ? 'selected selected__0' : ''}
					>
						{option.label}
					</Styled.PeriodButton>
				))}
			</Styled.PeriodContainer>
			{tradingSessions && (
				<Styled.PeriodContainer data-test="trading-session-time-options">
					<Styled.ExtendedPeriodTitle>
						{t(tradingSessions.header)}
					</Styled.ExtendedPeriodTitle>
					{tradingSessions.items.map(sessionTime => (
						<Styled.PeriodButton
							type="button"
							aria-label={`Choose ${sessionTime}`}
							key={sessionTime}
							onClick={() => onTimeChange(sessionTime)}
							className={time === sessionTime ? 'selected selected__0' : ''}
						>
							{formatTradingSessionTime(sessionTime)}
						</Styled.PeriodButton>
					))}
				</Styled.PeriodContainer>
			)}
		</Styled.Section>
	);
};
