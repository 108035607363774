/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import qs from 'querystring';
import { getApiUrl } from 'src/chat/api/getApiUrl';
import { escapeHref } from 'src/chat/helpers/escapeHref';
import { withCSRFTokenHeader } from 'src/_api';

export const ServicePrefix = {
	Exchange: '',
	Chat: '/chat',
};

export const API_URL = getApiUrl(document.location);

export async function fetchFromApi(
	url,
	{ query, mapStatusToResult, body, urlServicePrefix = ServicePrefix.Exchange, ...params }
) {
	const apiUrl = getApiUrl(document.location, urlServicePrefix);
	const baseUrl = `${apiUrl}${urlServicePrefix}/v1`;

	const requestUrl = `${baseUrl}/${url}${query ? `?${qs.encode(query)}` : ''}`;

	const response = await fetch(requestUrl, {
		credentials: 'include',
		...(body && { body: formatBody(body) }),
		...params,
		headers: withCSRFTokenHeader(params.headers),
	});

	if (response?.status === 401) {
		document.location.href = escapeHref('/');
		return;
	}

	const maybeResultFromStatus = mapStatusToResult?.(response.status);

	if (maybeResultFromStatus || maybeResultFromStatus === null) {
		return maybeResultFromStatus;
	}

	return parseResponse(response);
}

const formatBody = body => {
	if (body instanceof FormData) {
		return body;
	}

	return JSON.stringify(body);
};

export const statusMap = {
	200: 'OK',
	400: 'Bad request',
	401: 'Unauthorized',
	402: 'Payment required, account was not approved',
	403: 'Forbidden',
	404: 'Not found',
	418: 'ValidationError',
	500: 'Internal Server Error',
	503: 'Service Unavailable',
};

export async function parseResponse(resp) {
	let res;

	try {
		res = await resp.json();
	} catch (e) {
		res = {
			error: statusMap[resp.status],
			message: res?.message || res?.error || undefined,
		};

		if (resp.status !== 200) {
			console.log('HTTP Request Error', res);

			// react-query relies on error thrown for setting isError flag
			throw new Error(e);
		}
	}

	return res;
}

export function map404ToEmptyList(status) {
	if (status === 404) {
		return [];
	}
}

export function map404ToEmptyObject(status) {
	if (status === 404) {
		return {};
	}
}

export function map404ToNull(status) {
	if (status === 404) {
		return null;
	}
}

export const mapFalsyResponseToEmptyArray = res => res || [];
