/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { H500 } from 'src/components/Typography';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { media } from 'src/_helpers';

export const FooterWrapper = styled.div`
	background: var(--neutral-850);
	display: flex;
	grid-column: 1 / 3;
	justify-content: space-between;
	align-items: center;
	padding: 0 32px 0 26.4px;

	${media.ll`
		padding-left: 30.4px;
	`}

	${media.xl`
		padding: 0 48px 0 56px;
	`}
`;

export const StepStatus = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

export const CircleWrapper = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StepStatusInfo = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 6px;
`;

export const StatusHeader = styled(H500)`
	color: var(--text-color-secondary);
`;

export const Status = styled(H500)`
	color: var(--text-color-default);
`;

export const CompleteButton = styled(PrimaryButton)`
	width: 170px;

	${media.ll`
		width: 180px;
	`}

	${media.xl`
		width: 230px;
	`}
`;
