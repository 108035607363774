/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const splitDateIntoLines = date => {
	const separator = ' - ';
	const separatorIndex = date.indexOf(separator);

	if (separatorIndex > 0) {
		return [
			date.substring(0, separatorIndex + separator.length),
			date.substring(separatorIndex + separator.length),
		];
	}

	const dateTokens = date.split(' ');
	if (dateTokens.length === 2) {
		return dateTokens;
	} else if (dateTokens.length > 1) {
		const lastToken = dateTokens.pop();

		return [dateTokens.join(' '), lastToken];
	}

	return [date];
};
