/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as ArrowRightIcon } from 'src/assets/icons/arrow_right.svg';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';

export const Negotiation = styled.div`
	--_buttonWidth: 200px;
	--_buttonsHeight: 56px;
	position: relative;
	display: grid;
	grid-template-rows: 1fr;
	overflow: hidden;

	.drawer-content {
		> div {
			> div:last-of-type {
				padding-bottom: 45px;
			}
		}
	}
`;

export const FirmUpButton = styled(PrimaryButton)`
	&.disabled {
		background-color: var(--neutral-700);
	}
`;

export const Buttons = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: var(--_buttonsHeight);
	padding: 8px 24px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1;

	button {
		width: var(--_buttonWidth);
		border-radius: 32px;
	}

	.button_placeholder {
		width: var(--_buttonWidth);
	}
`;

export const ArrowRight = styled(ArrowRightIcon)``;

export const ArrowLeft = styled(ArrowRightIcon)`
	transform: rotate(180deg);
`;

export const NegotiationContent = styled.div`
	height: 100%;
`;
