/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { h400, t100 } from 'src/components/Typography';
import { NameContainer } from 'src/components/UserStatus/UserStatus';

export const Name = styled.span`
	${h400}
	padding-top: 1px;

	${media.xl`
		padding-top: 5px;
	`}
`;

export const UserInfo = styled.div`
	${t100}
	color: var(--neutral-300);
	gap: 4px;
	display: flex;
	align-items: center;
`;

export const CompanyCountryCode = styled.div`
	&::before {
		content: '•';
		margin-right: 4px;
	}
`;

export const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-height: 52px;
	min-height: 34px;
	gap: 3px;
	margin-left: 8px;
	max-width: 330px;

	> div {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	${media.xl`
		max-height: 62px;
	`}
`;

export const UserDetailsContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const UserNameContainer = styled(NameContainer)`
	> svg {
		position: relative;
		top: 2px;
	}
`;
