/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import * as Styled from './styled';

export const Label = ({ className, required, error, children, ...labelProps }) => (
	<Styled.Label
		className={clsx(className, {
			error,
			required,
		})}
		{...labelProps}
	>
		{children}
	</Styled.Label>
);
