/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPlaceholderMeta, PlaceholderType } from 'src/constants/chart';
import { isNil } from 'src/_helpers';
import * as Styled from '../styled';

export const MobileChartPlaceholder = ({ entriesLength, isLoading, chartActiveFilters }) => {
	if (!chartActiveFilters) {
		return <Placeholder type={PlaceholderType.InvalidFilters} />;
	}

	const areNoResults =
		!isLoading && !isNil(entriesLength) && !entriesLength && chartActiveFilters;

	if (areNoResults) {
		return <Placeholder type={PlaceholderType.NoResults} />;
	}

	if (isLoading) {
		return <Placeholder type={PlaceholderType.IsLoading} />;
	}

	return;
};

const Placeholder = ({ type }) => {
	const { t } = useTranslation();
	const { text, icon } = getPlaceholderMeta(t, type);

	return (
		<Styled.PlaceholderContainer>
			{icon}
			<Styled.PlaceholderText>{text}</Styled.PlaceholderText>
		</Styled.PlaceholderContainer>
	);
};
