/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { H900, H600 } from 'src/components/Typography';
import { tCurrency, Market } from 'src/constants/contract';
import { formatPriceWithoutCurrency } from 'src/_helpers/price';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import * as Styled from './styled';
import { getCounterType } from 'src/components/ViewOrderDrawer/CounterForm/helpers/getCounterType';
import { getTypeLabel } from './getTypeLabel';
import { formatProductAndFuturesCode } from 'src/_helpers/futuresContracts';

export const PricePill = ({ order, changed }) => {
	const { t } = useTranslation();

	const { isExpired, isTerminatedOrNotAvailable, futuresContract } = useOrderDetails(order);

	const {
		price,
		currency,
		price_unit,
		order_type,
		has_price,
		futures_contract_date,
		trade_id,
		is_counter_preview,
		is_indicative,
	} = order;

	const userId = useSelector(getUserId);

	let counterType = order.counterType;

	if (order.market === Market.Physical && is_counter_preview) {
		counterType = getCounterType(order, userId);
	}

	const isCounter = !!counterType;
	const isTraded = !!trade_id;

	const priceSuffix =
		futuresContract &&
		formatProductAndFuturesCode(futuresContract.product_code, futures_contract_date);

	const typeLabel = getTypeLabel(t, is_indicative, counterType || order_type);

	return (
		<Styled.PricePill
			$type={isTraded ? 'traded' : counterType || order_type}
			$isOrderClosed={!isCounter && (isExpired || isTerminatedOrNotAvailable)}
			$isChanged={changed}
			data-test="price-pill"
		>
			<Styled.OrderType>
				<H600 as="span">{isTraded ? t('traded') : typeLabel}</H600>
			</Styled.OrderType>
			<Styled.PriceDetails>
				<H600 as="span">{`${tCurrency(t, currency)}/${t(`price_unit_short_${price_unit}`, {
					lng: 'en',
				})}`}</H600>
				<H900 as="span">
					{has_price !== false ? formatPriceWithoutCurrency(price) : '---'}
					{priceSuffix && ' ' + priceSuffix}
				</H900>
			</Styled.PriceDetails>
		</Styled.PricePill>
	);
};
