/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers';
import { h600, h200, h400, m200 } from '../Typography';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { PrimaryButton } from '../Buttons/PrimaryButton';

const contentPadding = css`
	padding: 0 16px;
	${media.ll`
		padding: 0 20px;
	`}

	${media.xl`
		padding: 0 24px;
	`};
`;

export const TopContent = styled.div`
	${contentPadding}
	position: relative;

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 18px;
		background: linear-gradient(180deg, #0f1328 0%, rgba(15, 19, 40, 0) 100%);
		top: 100%;
		left: 0;
		z-index: 1;
		display: none;
	}

	${({ $isScrolling }) =>
		$isScrolling &&
		`
		&:before {
			display: block;
		}
	`}
`;

export const DrawerContentWrapper = styled.div`
	display: grid;
	grid-template-rows: minmax(min-content, max-content) 1fr;
	overflow: hidden;
	height: 100%;

	&:before {
		content: '';
		position: absolute;
		top: calc(100% - 20px);
		left: 0;
		width: 100%;
		height: 20px;
		background: linear-gradient(180deg, #0e132a 0%, rgba(14, 19, 42, 0) 100%);
		transform: rotate(180deg);
		z-index: 1;

		${media.ll`
			top: calc(100% - 30px);
			height: 30px;
		`};

		${media.xl`
			top: calc(100% - 40px);
			height: 40px;
		`};
	}
`;

export const DrawerContentDetailsWrapper = styled.div`
	width: 468px;

	${media.ll`
		width: 520px;
	`}

	${media.xl`
		width: 602px;
	`};
`;

export const ScrollableContent = styled.div`
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	${contentPadding}
	padding-bottom: 12px;

	${media.ll`
		padding-bottom: 12px;
	`}

	${media.xl`
		padding-bottom: 12px;
	`};
`;

export const OrderCreatorShipmentSectionContainer = styled.div`
	display: flex;
	gap: 8px;

	${media.xl`
		gap: 16px;
	`}
`;

export const SectionTitle = styled.h4`
	${h600}
`;

export const SectionTitleWithInfo = styled(SectionTitle)`
	display: flex;
	justify-content: space-between;
`;

export const SectionTitleInfo = styled.span`
	height: 20px;
	color: var(--neutral-300);
	border: 1px solid var(--neutral-400);
	border-radius: 4px;
	padding: 3px 8px;
	${m200}
`;

export const SectionContainer = styled.div`
	position: relative;
	padding: 17px 0;

	${media.ll`
		padding: 21px 0;
	`}

	${media.xl`
		padding: 26px 0;
	`}

	& > h4 {
		margin-bottom: 19px;

		${media.ll`
			margin-bottom: 23px;
		`}

		${media.xl`
			margin-bottom: 27px;
		`}
	}
`;

export const SectionGrid = styled.div`
	display: grid;
	grid-gap: 12px 8px;
	grid-template-columns: repeat(4, 111px);

	${media.ll`
		grid-template-columns: repeat(4, 124px);
	`}

	${media.xl`
		grid-gap: 22px 16px;
		grid-template-columns: repeat(4, 139px);
	`}

	&:not(:last-child) {
		margin-bottom: 8px;

		${media.ll`
			margin-bottom: 14px;
		`}

		${media.xl`
			margin-bottom: 23px;
		`}
	}

	${({ $fullWidth }) =>
		$fullWidth &&
		`
		grid-template-columns: 1fr !important;

		label, span {
			max-width: initial;
		}
	`}
`;

export const SectionGridContent = styled.div`
	display: grid;
	gap: 2px;

	&.changed {
		position: relative;
		z-index: 1;
		padding: 0 6px;

		${media.xl`
			padding: 0 8px;
		`}

		&:before {
			content: '';
			background-color: var(--counter-highlight-color);
			inset: -3px 0;
			border-radius: 4px;
			position: absolute;
			z-index: -1;

			${media.xl`
				border-radius: var(--large-border-radius);
				inset: -7px 0;
			`}
		}
	}

	&.wide {
		grid-column: 1 / span 2;

		span,
		div {
			max-width: none;
			width: auto;
		}
	}

	${media.ll`
		gap: 3px;
	`}

	${media.xl`
		gap: 4px;
	`}
`;

export const SectionGridLabel = styled.label`
	${h200}
	color: var(--neutral-100);
	max-width: 111px;
	min-height: 13px;

	${media.ll`
		max-width: 108px;
	`}

	${media.xl`
		max-width: 128px;
	`}
`;

export const SectionGridText = styled.span`
	${h400}
	color: #fff;
	max-width: 104px;
	${({ $alignSelf }) =>
		$alignSelf &&
		`
		align-self: ${$alignSelf};
	`}

	${media.ll`
		max-width: 108px;
	`}

	${media.xl`
		max-width: 128px;
	`}
`;

export const SectionGridTextWide = styled(SectionGridText)`
	max-width: none !important;
`;

export const Divider = styled.div`
	position: relative;
	width: 100%;
	height: 2px;

	& + & {
		display: none;
	}

	&:before {
		content: '';
		width: calc(100% + 32px);
		height: 2px;
		background: var(--neutral-1000);
		position: absolute;
		top: 0;
		left: -16px;

		${media.ll`
			width: calc(100% + 40px);
			left: -20px;
		`}

		${media.xl`
			width: calc(100% + 48px);
			left: -24px;
		`}
	}

	${props =>
		props.$bottomSpacing &&
		css`
			margin-bottom: ${props.$bottomSpacing}px;
		`}

	${props =>
		props.$topSpacing &&
		css`
			margin-top: ${props.$topSpacing}px;
		`}
`;

export const ActionButtons = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 20px;

	& a,
	button {
		width: 160px;
		height: 36px;

		${media.xl`
			width: 190px;
			height: 40px;
		`}
	}

	${props =>
		props.$isTraded &&
		css`
			& a,
			button {
				width: 144px;

				${media.ll`
					width: 160px;
				`}

				${media.xl`
					width: 190px;
				`}
			}
		`}

	${props =>
		props.$hasThreeButtons &&
		css`
			gap: 16px;

			a,
			button {
				width: 100px;

				${media.xl`
					width: 121px;
				`}
			}
		`};
`;

export const CounterButton = styled(SecondaryButton)`
	&.darker.disabled {
		background-color: var(--neutral-700);
	}
`;

export const CopyButton = styled(SecondaryButton).attrs({ darker: true })``;

export const AcceptButton = styled(PrimaryButton)`
	&.disabled {
		background-color: var(--neutral-700);
	}
`;

export const FilesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	${media.ll`
		gap: 16px;
	`}

	> div {
		width: max-content;
		max-width: 468px;

		${media.ll`
			max-width: 520px;
		`}

		${media.xl`
			max-width: 602px;
		`}
	}
`;
