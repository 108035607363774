/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers/media-queries';

export const Page = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 29px 16px 0 20px;
	height: 100%;

	${media.ll`
		padding-top: 65px;
	`}

	${media.xl`
		padding-top: 67px;
		padding-left: 48px;
		gap: 24px;
	`}
`;

export const Container = styled.div`
	display: grid;
	gap: 8px;
	grid-template-columns: 180px 1fr 288px;

	${media.ll`
		grid-template-columns: 200px 1fr 310px;
		gap: 16px;
	`}

	${media.xl`
		grid-template-columns: 250px 992px 440px;
		gap: 48px;
	`}
`;

export const LeftColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const Content = styled.div`
	flex: 1;
`;

export const Header = styled.h1`
	font-weight: 400;
	font-size: 26px;
	line-height: 30px;
	min-height: 30px;
	color: var(--text-color-default);
`;

export const sideSection = css`
	max-width: 284px;

	${media.ll`
		max-width: 440px;
	`}
`;
