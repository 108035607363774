/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialDateTimeInput } from 'src/designSystem/Form/MaterialInput/MaterialDateTimeInput';
import { Controller, useFormContext } from 'react-hook-form';
import { isBeforeToday } from 'src/_helpers/date';
import { DrawerFooter } from 'src/components/Drawer/Drawer';
import { useViewOrderDrawerContext } from 'src/components/ViewOrderDrawer/ViewOrderDrawerProvider';
import { useCounterValidity } from './useCounterValidity';
import * as Styled from './styled';

export const CounterFormPaperFooter = ({ onSubmit, onCancel, disableSubmit = false }) => {
	const { t } = useTranslation();

	const {
		formState: { errors },
		setValue,
	} = useFormContext();

	const {
		orderEditedWithoutNegotiation,
		negotiationsWithdrawn,
		orderWithdrawn,
	} = useViewOrderDrawerContext();

	const validityOptions = useCounterValidity();

	const hasOrderUpdated =
		orderWithdrawn || orderEditedWithoutNegotiation || negotiationsWithdrawn;

	return (
		<DrawerFooter className="create-order-drawer-footer">
			<Controller
				name="validity"
				render={({ field }) => (
					<MaterialDateTimeInput
						required
						label={t('validity')}
						id="validity"
						error={!!errors[field.name]}
						popupHeaderTitle={t('validity')}
						readOnly
						filterDate={date => !isBeforeToday(date)}
						renderDateToggleButton
						isTimeDefault
						validityOptions={validityOptions}
						{...field}
						onChange={value => {
							field.onChange(value);
							setValue('validityChanged', true);
						}}
					/>
				)}
			/>
			<Styled.Buttons>
				<Styled.CancelButton onClick={onCancel} disabled={hasOrderUpdated}>
					{t('back')}
				</Styled.CancelButton>
				<Styled.PreviewButton
					onClick={onSubmit}
					disabled={disableSubmit || hasOrderUpdated}
				>
					{t('preview_counter')}
				</Styled.PreviewButton>
			</Styled.Buttons>
		</DrawerFooter>
	);
};
