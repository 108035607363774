/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { ReactComponent as ArrowsGreenRed } from 'src/assets/icons/arrows-green-red.svg';
import { ReactComponent as ArrowsRedGreen } from 'src/assets/icons/arrows-red-green.svg';
import { ReactComponent as ArrowsBlueOrange } from 'src/assets/icons/arrows-blue-orange.svg';
import { ReactComponent as ArrowsOrangeBlue } from 'src/assets/icons/arrows-orange-blue.svg';

export const ThemeNames = {
	standard: 'standard',
	alternative: 'alternative',
	standardColorblindness: 'standardColorblindness',
	alternativeColorblindness: 'alternativeColorblindness',
};

export const ThemeColors = {
	green: 'green',
	red: 'red',
	blue: 'blue',
	orange: 'orange',
};

export const ThemeSettings = [
	{
		key: ThemeNames.standard,
		icon: ArrowsGreenRed,
		labels: [
			{
				color: 'var(--green)',
				key: 'settings_theme_green_buy',
			},
			{
				color: 'var(--red)',
				key: 'settings_theme_red_sell',
			},
		],
	},
	{
		key: ThemeNames.alternative,
		icon: ArrowsRedGreen,
		labels: [
			{
				color: 'var(--red)',
				key: 'settings_theme_red_buy',
			},
			{
				color: 'var(--green)',
				key: 'settings_theme_green_sell',
			},
		],
	},
	{
		key: ThemeNames.standardColorblindness,
		icon: ArrowsBlueOrange,
		labels: [
			{
				color: 'var(--primary-200)',
				key: 'settings_theme_blue_buy',
			},
			{
				color: 'var(--yellow-200)',
				key: 'settings_theme_orange_sell',
			},
		],
	},
	{
		key: ThemeNames.alternativeColorblindness,
		icon: ArrowsOrangeBlue,
		labels: [
			{
				color: 'var(--yellow-200)',
				key: 'settings_theme_orange_buy',
			},
			{
				color: 'var(--primary-200)',
				key: 'settings_theme_blue_sell',
			},
		],
	},
];

export const allMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const extendedLimitOptions = [10, 20, 50, 100];

export const Languages = {
	English: {
		code: 'en',
		flag: 'gb',
		moment: 'en-gb',
	},
	Chinese: {
		code: 'zh',
		flag: 'cn',
		moment: 'zh-cn',
	},
};

export const MomentLocales = {
	[Languages.English.code]: Languages.English.moment,
	[Languages.Chinese.code]: Languages.Chinese.moment,
};

export const LanguageThemes = {
	[Languages.English.code]: {
		default: ThemeNames.standard,
		colorblind: ThemeNames.standardColorblindness,
	},
	[Languages.Chinese.code]: {
		default: ThemeNames.alternative,
		colorblind: ThemeNames.alternativeColorblindness,
	},
};
