/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { createContext, useContext } from 'react';

export const TradeDataContext = createContext({
	tradeData: {},
	activeStep: null,
	completedSteps: [],
});

export const useTradeDataContext = () => {
	const { tradeData, activeStep, completedSteps } = useContext(TradeDataContext);

	return { tradeData, activeStep, completedSteps };
};
