/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Button } from './Button';
import styled from 'styled-components';

export const PrimaryButton = styled(Button)`
	background: var(--primary-400);
	box-shadow: 0px 2px 30px rgba(66, 0, 255, 0.25);

	&:hover {
		background: var(--primary-200);
		color: var(--white);
	}

	&.disabled,
	&:disabled {
		box-shadow: none;
	}

	&.loading {
		background: var(--primary-600);
		color: var(--white);
	}
`;
