/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useTranslation } from 'react-i18next';
import { OrderType } from 'src/constants/contract';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';

export const Labels = {
	Buyer: 'buyer',
	Seller: 'seller',
};

export const getLabels = firstLabel => {
	return firstLabel === Labels.Seller
		? [Labels.Seller, Labels.Buyer]
		: [Labels.Buyer, Labels.Seller];
};

export const useCalendarSpreadLegUsers = order => {
	const { t } = useTranslation();
	const currentUserId = useSelector(getUserId);

	const hiddenLabel = t('hidden');
	const notProvidedLabel = t('not_provided');
	const emptyLabel = '---';
	const orderPrincipal = order.order_owner_principal.hidden
		? hiddenLabel
		: order.order_owner_principal.company?.name || notProvidedLabel;

	if (order.is_order_preview || order.is_order) {
		return {
			userLabels: getLabels(
				order.order_type === OrderType.Sell ? Labels.Seller : Labels.Buyer
			),
			users: [orderPrincipal, emptyLabel],
		};
	}

	if (order.is_counter_preview) {
		const isOrderOwner = order.user_id === order.order_user_id;

		return {
			userLabels: getLabels(
				order.spreadMeta.orderCounterType === OrderType.Sell ? Labels.Buyer : Labels.Seller
			),
			users: [
				isOrderOwner ? orderPrincipal : order.user?.company?.name,
				!isOrderOwner ? orderPrincipal : order.spreadMeta.user?.company?.name,
			],
		};
	}

	const counterPrincipal = order.counter_order_user_company.name;
	const counterparties = {
		buyer: null,
		seller: null,
	};
	if (order.order_type === OrderType.Sell) {
		counterparties.seller = orderPrincipal;
		counterparties.buyer = counterPrincipal;
	} else {
		counterparties.seller = counterPrincipal;
		counterparties.buyer = orderPrincipal;
	}

	let firstUserLabel;
	if (!!order.trade_id) {
		// When we display a confirmed counter, we need to take under consideration
		// who is displaying the counter, and display their company in the first place
		if (order.order_user_id === currentUserId) {
			firstUserLabel = order.order_type === OrderType.Buy ? Labels.Buyer : Labels.Seller;
		} else {
			firstUserLabel = order.order_type === OrderType.Buy ? Labels.Seller : Labels.Buyer;
		}
	} else {
		//When a counter is not confirmed, we always display the creator of the counter in the first place
		firstUserLabel = order.counterType === OrderType.Buy ? Labels.Buyer : Labels.Seller;
	}

	const users =
		firstUserLabel === Labels.Buyer
			? [counterparties.buyer, counterparties.seller]
			: [counterparties.seller, counterparties.buyer];

	return {
		userLabels: getLabels(firstUserLabel),
		users,
	};
};
