/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
	CargoType,
	IncoType,
	Inspection,
	isShipmentInco,
	tCargoType,
	tInspection,
} from 'src/constants/contract';
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';

export const useShipmentSectionOptions = () => {
	const { t } = useTranslation();

	const { setValue, getValues } = useFormContext();
	const portFields = useFieldArray({ name: 'ports' });

	const {
		incoValue,
		cargoType,
		portsArray,
		portNames,
		volumeValue,
		inspectionValue,
	} = useWatchPhysicalFields();

	/** TOLERANCE */
	const toleranceLabel = !incoValue
		? t('tolerance')
		: isShipmentInco(incoValue)
		? t('tolerance_sellers_option')
		: t('tolerance_buyers_option');

	/** CARGO TYPE */
	const cargoTypeOptions = Object.values(CargoType).map(value => ({
		text: tCargoType(t, value),
		value,
	}));

	useEffect(() => {
		if (incoValue === IncoType.RENDU) {
			setValue('cargoType', CargoType.Bulk);
		}
	}, [cargoType, incoValue, setValue]);

	/** INSPECTION */
	const inspectionOptions = Object.values(Inspection).map(value => ({
		text: tInspection(t, value),
		value,
	}));

	/** PORTS */
	const portLabel = !incoValue
		? t('loading_discharge_port')
		: isShipmentInco(incoValue)
		? t('discharge_port')
		: t('loading_port');

	const portVolumeLabel = !incoValue
		? t('loading_discharge_quantity')
		: isShipmentInco(incoValue)
		? t('discharge_quantity')
		: t('loading_quantity');

	const portRateLabel = !incoValue
		? t('loading_discharge_rate')
		: isShipmentInco(incoValue)
		? t('discharge_rate')
		: t('loading_rate');

	const addNewPort = () => {
		portFields.append({ name: '', volume: null, terminal: '', rate: null });
		if (portFields.fields.length === 1) {
			setValue('ports.0.volume', null);
		}
	};

	const deletePort = index => {
		portFields.remove(index);
		if (portFields.fields.length === 2) {
			setValue('ports.0.volume', volumeValue);
		}
	};

	const addPortName = (portKey, portName, countryCode) => {
		const oldPortNames = getValues('portNames') || {};
		if (portKey && portName && !oldPortNames[portKey]) {
			setValue('portNames', {
				...oldPortNames,
				[portKey]: `${portName}, ${countryCode}`,
			});
		}
	};

	const hasPortParamValue = param => index => {
		return !!portsArray[index]?.[param];
	};

	const hasPortValue = index => {
		if (index === 0) {
			return (
				hasPortParamValue('rate')(index) ||
				hasPortParamValue('terminal')(index) ||
				(portsArray.length > 1 && hasPortParamValue('volume')(index))
			);
		} else {
			return true;
		}
	};

	return {
		tolerance: { label: toleranceLabel },
		cargoType: {
			options: cargoTypeOptions,
			ready: incoValue !== IncoType.RENDU,
		},
		inspection: { options: inspectionOptions, ready: true, hasValue: inspectionValue },
		ports: {
			label: portLabel,
			names: portNames || [],
			list: portsArray.length === 1 ? portsArray : portFields.fields,
			add: addNewPort,
			addName: addPortName,
			delete: deletePort,
			hasValue: hasPortValue,
		},
		portVolumes: {
			label: portVolumeLabel,
			maxValue: parseInt(volumeValue),
			ready: portsArray.length > 1,
			defaultValue: volumeValue,
			hasValue: portsArray.length === 1 ? () => false : hasPortParamValue('volume'),
		},
		portTerminals: { hasValue: hasPortParamValue('terminal') },
		portRates: { label: portRateLabel, hasValue: hasPortParamValue('rate') },
	};
};
