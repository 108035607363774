/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContent } from 'semantic-ui-react';
import { useTogglable } from 'src/_helpers/useTogglable';
import {
	Modal,
	CancelButton,
	ButtonsWrapper,
	ModalHeaderWrapper,
	ModalTitle,
	ModalIconWrapper,
} from 'src/components/Modal';
import { ModalType, ModalSize } from 'src/constants/modal';
import * as Styled from './styled';

export const SettlementModal = ({ text, companyName }) => {
	const [isOpen, { open, close }] = useTogglable();
	const { t } = useTranslation();

	return (
		<Modal
			data-test="settlement-modal"
			size={ModalSize.Tiny}
			close={close}
			isOpen={isOpen}
			open={open}
			trigger={<Styled.DetailsLink>{t('details')}</Styled.DetailsLink>}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{t('settlement_suggestion_for_company', { companyName })}</ModalTitle>
			</ModalHeaderWrapper>
			<ModalContent>
				<Styled.SettlementText>{text}</Styled.SettlementText>
			</ModalContent>
			<ButtonsWrapper>
				<CancelButton
					onClick={close}
					data-test="close-settlement-modal"
					text={t('close')}
				/>
			</ButtonsWrapper>
		</Modal>
	);
};
