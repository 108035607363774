/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, forwardRef } from 'react';
import clsx from 'clsx';
import { isNil } from 'src/_helpers';
import { Label } from '../Label/Label';
import { NumberInput } from '../NumberInput/NumberInput';
import { Select } from '../Select/Select';
import * as Styled from './styled';

export const MaterialNumberInputWithOptions = forwardRef(
	(
		{
			label,
			value,
			error,
			idInput,
			idSelect,
			className,
			options,
			required,
			onChange,
			onOptionChange,
			optionValue,
			disableInput,
			disableDropdown,
			allowNegative,
			forceNumber = true,
			controlledState = false,
			hasChanged = false,
			...props
		},
		ref
	) => {
		const [initialOptionValue] = useState(optionValue);
		const hasValue = !isNil(value) && !disableInput;
		const filledControlledState = controlledState === true && hasChanged === false && hasValue;
		const filledState = controlledState ? hasChanged === true : hasValue;

		const { decimalScale, fixedDecimalScale, ...selectProps } = props;

		const defaultInputProps = {
			thousandSeparator: true,
			fixedDecimalScale: true,
			decimalScale: 2,
			autoComplete: 'off',
			allowLeadingZero: true,
		};

		const optionValueToShow = optionValue || options?.[0]?.value || null;
		const hasOptionChanged = optionValue !== initialOptionValue;

		return (
			<Styled.InputContainer
				className={clsx(
					'split-field',
					required && 'required',
					filledState && 'input-filled',
					filledControlledState && 'input-filled-controlled',
					error && 'error',
					className
				)}
			>
				<NumberInput
					id={idInput}
					className="number-field"
					value={value}
					onValueChange={val => onChange(forceNumber ? +val.value : val.value)}
					{...defaultInputProps}
					{...props}
					allowNegative={allowNegative}
					disabled={disableInput}
				/>
				<Label htmlFor={idInput}>
					{label}
					{required && <span className="asterisk">*</span>}
				</Label>
				<Styled.Separator />
				<Select
					id={idSelect}
					className={clsx('select-field', hasOptionChanged && 'changed')}
					options={options}
					required
					value={optionValueToShow}
					onChange={onOptionChange}
					{...selectProps}
					disabled={disableDropdown}
				/>
				{hasValue && <Styled.ValidCheckIcon />}
			</Styled.InputContainer>
		);
	}
);
