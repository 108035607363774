/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';

export function Dimmer({ active, children, ...props }) {
	return active ? <DimmerStyled {...props}>{children}</DimmerStyled> : null;
}

const DimmerStyled = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--neutral-1000);
	opacity: ${({ fullOpacity }) => (fullOpacity ? '1' : '0.4')};
`;
