/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import {
	tEnvironment,
	calculate2ndLegPrice,
	LegType,
	QuoteType,
	tQuoteValueType,
	OrderType,
	SpreadPriceFormat,
	Market,
} from 'src/constants/contract';
import { formatDateMonthYear, formatDateRange, parseDateStringAsUTC } from 'src/_helpers/date';
import { ReactComponent as ExternalLinkIconSVG } from 'src/assets/icons/external_link_icon.svg';
import { SpreadLegRow } from 'src/components/ViewOrderDrawer/components/SpreadLegRow/SpreadLegRow';
import { useCalendarSpreadLegUsers } from './useCalendarSpreadLegUsers';
import {
	SectionContainer,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
	Divider,
} from '../../../styled';
import {
	CopyTradeLink,
	CopyOrderNegotiationLink,
} from 'src/components/ViewOrderDrawer/components/CopyOrderNegotiationLink/CopyOrderNegotiationLink';
import { CreatedAtText } from '../../AdditionalInformationSection/styled';
import { formatProductAndFuturesCode } from 'src/_helpers/futuresContracts';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import {
	calculateSpreadPrice,
	legShipmentLabel,
	flatBasisSpreadPriceFormat,
} from 'src/_helpers/spread';
import { isNil } from 'src/_helpers/utils';
import { getCurrencyAndUnitOfOrderOrCounter } from 'src/_helpers/price';
import { PKPG_PRODUCT_ID } from 'src/constants/product';
import * as Styled from './styled';

export const CalendarSpreadSection = ({ order, changes = {} }) => {
	const { t } = useTranslation();
	const { futuresContract, isBrokerOrder, isBasisPrice } = useOrderDetails(order);

	const { userLabels, users } = useCalendarSpreadLegUsers(order);

	const lastCounterType = order.counter_order_type || order.order_type;

	const isFirstLegBuyer = lastCounterType === OrderType.Buy;
	const isPayCashSpread = order.spread_details.price_format === SpreadPriceFormat.PayCash;

	const spreadValuePrice =
		order.is_order_preview || order.is_counter_preview
			? isPayCashSpread
				? calculateSpreadPrice(
						isFirstLegBuyer,
						order.spread_details.spread_type,
						order.price
				  )
				: order.price
			: order.price;

	// Note: price is undefined when the field is not touched, but it's empty string when the field was filled and then cleared
	const has1stLegPrice = order.firstLegPrice !== undefined && order.firstLegPrice !== '';

	const firstLegPriceSuffix = formatProductAndFuturesCode(
		futuresContract?.product_code,
		order.firstLegFuturesMonth
	);

	const secondLegPriceSuffix = formatProductAndFuturesCode(
		futuresContract?.product_code,
		order.secondLegFuturesMonth
	);

	const tradeIds = order.trade_ids || [];
	const isTraded = !!tradeIds.length;
	const [firstLegTradeID, secondLegTradeID] = tradeIds;
	const currencyAndUnit = getCurrencyAndUnitOfOrderOrCounter(order, t);

	const firstLegPriceFormatted = flatBasisSpreadPriceFormat(
		order.firstLegPrice,
		isBasisPrice,
		currencyAndUnit,
		firstLegPriceSuffix
	);

	const secondLegPriceFormatted = flatBasisSpreadPriceFormat(
		calculate2ndLegPrice(order.firstLegPrice, spreadValuePrice),
		isBasisPrice,
		currencyAndUnit,
		secondLegPriceSuffix
	);

	const isExecutionNotAvailable =
		order.product_id === PKPG_PRODUCT_ID && order.market === Market.Paper;

	const firstLegData = [
		{
			label: t(userLabels[0]),
			value: users[0],
		},
		{
			label: t(userLabels[1]),
			value: users[1],
		},
		{
			label: t(legShipmentLabel(order, 0)),
			value: isPayCashSpread
				? formatDateMonthYear(order.firstLegMonth.startDate)
				: formatDateRange({
						startDate: parseDateStringAsUTC(order.firstLegMonth.startDate),
						endDate: parseDateStringAsUTC(order.firstLegMonth.endDate),
						format: order.firstLegMonth.format,
				  }),
		},
		{
			label: t('first_leg_price'),
			value: firstLegPriceFormatted,
			changed: changes.firstLegPrice,
		},
		isTraded && {
			label: t('trade_id'),
			wide: true,
			value: (
				<CopyTradeLink tradeId={firstLegTradeID} skipCopying={isExecutionNotAvailable} />
			),
		},
	].filter(Boolean);

	const secondLegData = [
		{
			label: t(userLabels[0]),
			value: users[1],
		},
		{
			label: t(userLabels[1]),
			value: users[0],
		},
		{
			label: t(legShipmentLabel(order, 1)),
			value:
				order.spread_details.price_format === SpreadPriceFormat.PayCash
					? formatDateMonthYear(order.secondLegMonth.startDate)
					: formatDateRange({
							startDate: parseDateStringAsUTC(order.secondLegMonth.startDate),
							endDate: parseDateStringAsUTC(order.secondLegMonth.endDate),
							format: order.secondLegMonth.format,
					  }),
		},
		{
			label: t('second_leg_price'),
			value: secondLegPriceFormatted,
			changed: has1stLegPrice
				? changes.firstLegPrice || changes.price
				: changes.firstLegPrice,
		},
		isTraded && {
			label: t('trade_id'),
			wide: true,
			value: (
				<CopyTradeLink tradeId={secondLegTradeID} skipCopying={isExecutionNotAvailable} />
			),
		},
	].filter(Boolean);

	const isCounter = order._id?.startsWith('counter_orders/') || order.is_counter_preview;

	const createdDate = order.created_at
		? moment(order.created_at).format('D MMMM YYYY, h:mm A zz')
		: null;
	const updatedDate =
		!order.version || order.version === 1
			? null
			: moment(order.version_created_at).format('D MMMM YYYY, h:mm A zz');

	const quoteType = order.is_indicative ? QuoteType.Indicative : QuoteType.Firm;
	const orderTypeLabel =
		order.counterIndex > 0 || order.is_counter_preview
			? t('counter_type')
			: t('order_type_label');

	return (
		<>
			<SpreadLegRow
				legType={LegType.First}
				data={firstLegData}
				isBrokerOrder={isBrokerOrder}
			/>
			<Divider />
			<SpreadLegRow legType={LegType.Second} data={secondLegData} />
			<Divider />
			<SectionContainer data-test="contract-and-pricing-section">
				<SectionGrid>
					{!isNil(order.runs) && (
						<SectionGridContent className={clsx({ changed: changes.runs })}>
							<SectionGridLabel>{t('runs')}</SectionGridLabel>
							<SectionGridText data-test="pricing-section-runs">
								{order.runs || '---'}
							</SectionGridText>
						</SectionGridContent>
					)}
					{!isTraded && (
						<SectionGridContent className={clsx({ changed: changes.is_indicative })}>
							<SectionGridLabel>{orderTypeLabel}</SectionGridLabel>
							<SectionGridText data-test="pricing-section-order-type">
								{tQuoteValueType(t, quoteType)}
							</SectionGridText>
						</SectionGridContent>
					)}
					{!isTraded && (
						<SectionGridContent>
							<SectionGridLabel>{t('environment')}</SectionGridLabel>
							<SectionGridText data-test="contract-and-pricing-section-environment">
								{tEnvironment(t, order.environment)}
							</SectionGridText>
						</SectionGridContent>
					)}
					<SectionGridContent>
						<SectionGridLabel>{t('contract_terms')}</SectionGridLabel>
						<SectionGridText data-test="contract-and-pricing-section-contract">
							<Styled.ContractLink
								target="_blank"
								rel="noreferrer"
								href={order.terms.original_link}
							>
								{order.terms.name} <ExternalLinkIconSVG />
							</Styled.ContractLink>
						</SectionGridText>
					</SectionGridContent>
					{!isTraded && <CopyOrderNegotiationLink order={order} isCounter={isCounter} />}
					{createdDate && (
						<SectionGridContent>
							<SectionGridLabel>
								{isTraded ? t('trade_date') : t('created')}
							</SectionGridLabel>
							<CreatedAtText data-test="additional-information-section-created-at">
								{!!updatedDate || !isTraded ? (
									<OverflowText>{createdDate}</OverflowText>
								) : (
									createdDate
								)}
							</CreatedAtText>
						</SectionGridContent>
					)}
					{!!updatedDate && (
						<SectionGridContent>
							<SectionGridLabel>{t('edited')}</SectionGridLabel>
							<CreatedAtText data-test="additional-information-section-updated-at">
								<OverflowText>{updatedDate}</OverflowText>
							</CreatedAtText>
						</SectionGridContent>
					)}
				</SectionGrid>
			</SectionContainer>
		</>
	);
};
