/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import i18n from 'src/i18n';
import { useSelector } from 'react-redux';
import { ExecutionHeader } from '../components/physical/ExecutionHeader/ExecutionHeader';
import { formatTradeDetails } from 'src/_helpers/formatTradeDetails';
import { getUserId, showRefreshToast } from 'src/_store/selectors';
import { tPriceUnit } from 'src/constants/product';
import { ExecutionContent } from '../components/physical/ExecutionContent/ExecutionContent';
import { StickyFooter } from '../components/physical/StickyFooter/StickyFooter';
import { TradeDataContext } from './TradeDataContext';
import { PhysicalTradeStep } from 'src/constants/physicalTrade';
import { useCompletedSteps } from './useCompletedSteps';
import { useAllowedSteps } from './useAllowedSteps';
import { useTradePageTitle } from 'src/_helpers/usePageTitle';
import { useNotificationUpdater } from 'src/components/Menus/Notifications/useNotificationUpdater';
import { RefreshToast } from 'src/components/RefreshToast/RefreshToast';
import * as Styled from './styled';

export const PhysicalExecution = ({ data }) => {
	const [isCompleteButtonDisabled, setCompleteButtonDisabled] = useState(false);

	const { t } = useTranslation();
	const { pathname } = useLocation();
	const accountUserId = useSelector(getUserId);
	const currentLanguage = i18n.language;
	const tradeRoutePage = pathname.split('/').at(-1).replace(/-/g, '_');
	const activeStep = Object.values(PhysicalTradeStep).find(item => item === tradeRoutePage);
	const queryClient = useQueryClient();

	useNotificationUpdater({});

	const shouldDisplayRefreshToast = useSelector(showRefreshToast);

	/**
	 * We need to refetch the data when the step is changed
	 * because in the meantime some documents may be uploaded or some steps may be completed
	 **/
	useEffect(() => {
		queryClient.invalidateQueries(['trade', data._key]);
	}, [activeStep, data._key, queryClient]);

	const priceLabel = tPriceUnit(t, data.price_unit);

	const tradeData = useMemo(
		() => formatTradeDetails(t, data, accountUserId, { priceLabel }),
		// NOTE: we want to recalculate order details when the locale changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, data, accountUserId, priceLabel, currentLanguage]
	);

	const completedSteps = useCompletedSteps(tradeData);

	const shouldShowStickyFooter = activeStep !== PhysicalTradeStep.TRADE_CONFIRMATION;

	useAllowedSteps(activeStep, tradeData.inco_id);

	useTradePageTitle(data, accountUserId);

	return (
		<Styled.GridWrapper className={clsx({ withFooter: shouldShowStickyFooter })}>
			{shouldDisplayRefreshToast && <RefreshToast />}
			<ExecutionHeader tradeData={tradeData} />
			<TradeDataContext.Provider value={{ tradeData, activeStep, completedSteps }}>
				<ExecutionContent
					setCompleteButtonDisabled={setCompleteButtonDisabled}
					shouldShowStickyFooter={shouldShowStickyFooter}
				/>
				{shouldShowStickyFooter && (
					<StickyFooter isCompleteButtonDisabled={isCompleteButtonDisabled} />
				)}
			</TradeDataContext.Provider>
		</Styled.GridWrapper>
	);
};
