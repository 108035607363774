/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { useCalendarSpreadSection } from '../../useCalendarSpreadSection';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';

const FirstLegFuturesMonth = ({ path, t }) => {
	const { firstLegFuturesMonth } = useCalendarSpreadSection({ path });
	const { setValue } = useFormContext();

	return (
		<Controller
			name={toPath(path, 'firstLegFuturesMonth')}
			mandatory
			render={({ field, fieldState: { error } }) => (
				<MaterialSelectInput
					required
					error={!!error}
					data-test={field.name}
					label={t('first_leg_futures')}
					options={firstLegFuturesMonth.options}
					{...field}
					onChange={value => {
						field.onChange(value);
						setValue(toPath(path, 'firstLegFuturesMonthChanged'), true);
					}}
				/>
			)}
		/>
	);
};
export const firstLegFuturesMonth = (path, t) => <FirstLegFuturesMonth path={path} t={t} />;
