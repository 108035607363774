/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRight } from 'src/assets/icons/arrow_right.svg';
import { ReactComponent as Shield } from 'src/assets/icons/shield_filled.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';
import { H400, t100, m50 } from 'src/components/Typography/fonts';
import { getCapitalLetter } from 'src/_helpers/getCapitalLetter';
import {
	InitialsCircle,
	InitialsCircleWrapper,
	UserDetails,
	UserListItem as GroupListItem,
} from 'src/components/SearchableContactsList/styledComponents';
import { Avatar, AvatarType } from 'src/shared/components/Avatar/Avatar';
import { OverflowText } from 'src/components/Tooltip/OverflowText';

export const SingleGroup = ({
	group,
	onClick,
	updateGroupUsers,
	selectedGroups = [],
	openGroupModal,
}) => {
	const { t } = useTranslation();

	const name = group.name;
	const groupId = group._key;
	const allUsersCount = group.users.length;

	const { isSelected, selectedGroup, selectedUsersCount } = useMemo(() => {
		const selectedGroup = selectedGroups.find(group => group._key === groupId);
		const isSelected = !!selectedGroup;
		const selectedUsersCount = isSelected
			? selectedGroup.users.filter(user => user.isSelected).length
			: 0;

		return {
			isSelected,
			selectedGroup,
			selectedUsersCount,
		};
	}, [groupId, selectedGroups]);

	return (
		<GroupListItem
			onClick={() => onClick(group)}
			$selected={isSelected}
			className={isSelected ? 'group-item-selected' : 'group-item'}
		>
			<InitialsCircleWrapper data-test={`group-${group._key}`}>
				<InitialsCircle className="initials" $selected={isSelected}>
					<Avatar
						initials={`${getCapitalLetter(name[0])}`}
						size={AvatarType.Large}
						backgroundColor={'var(--neutral-400)'}
						isSelected={isSelected}
					/>
				</InitialsCircle>
			</InitialsCircleWrapper>
			<GroupDetails>
				<GroupData>
					<H400>
						<OverflowText>{group.name}</OverflowText>
					</H400>
					{isSelected && (
						<GroupDetailsButton
							onClick={event => {
								event.stopPropagation();
								openGroupModal({
									group: selectedGroup,
									deselectGroup: () => onClick(group),
									updateGroupUsers: users => updateGroupUsers(users),
								});
							}}
						>
							{t('n_items_selected', {
								selected: selectedUsersCount,
								total: allUsersCount,
							})}
							<ArrowRight />
						</GroupDetailsButton>
					)}
				</GroupData>
				{group.externalUsersCount > 0 && (
					<ExternalUsers className="external-users">
						<Shield />
						{group.externalUsersCount === 1
							? t('1_external_user')
							: t('n_external_users', { count: group.externalUsersCount })}
					</ExternalUsers>
				)}
				{isSelected && <StyledCheckIcon />}
			</GroupDetails>
		</GroupListItem>
	);
};

const GroupDetails = styled(UserDetails)`
	justify-content: space-between;
`;

const GroupData = styled.div`
	display: flex;
	flex-direction: column;

	h4 {
		width: 150px;
	}
`;

const GroupDetailsButton = styled.button`
	border: 0;
	margin: 0;
	padding: 0;
	text-align: left;
	display: inline-block;
	${t100}
	color: var(--green-200);
	margin-top: 2px;
	cursor: pointer;

	svg {
		height: 8px;
		margin-left: 6px;
	}
`;

const ExternalUsers = styled.div`
	${m50}
	color: var(--neutral-300);
	font-size: 12px;
	margin-right: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;

	svg:first-of-type {
		margin-right: 7px;
	}
`;

const StyledCheckIcon = styled(CheckIcon)`
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	width: 16px;
	height: 16px;
	margin-right: 12px;
`;
