/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useWatchPaperCounterFields } from 'src/components/ViewOrderDrawer/CounterForm/helpers/paper/useWatchPaperCounterFields';
import { InformativeField } from 'src/components/CreateOrderDrawer/paper/sections/ContractAndPricingSection/spread/fields/InformativeField';
import NumberFormat from 'react-number-format';

const TotalQuantityField = ({ path, t }) => {
	const { totalVolumeValue, runsValue } = useWatchPaperCounterFields(path);

	return (
		<InformativeField
			title={t('total_quantity')}
			subtitle={
				!!runsValue ? (
					<>
						<NumberFormat
							thousandSeparator
							displayType="text"
							value={totalVolumeValue}
						/>{' '}
						{t('price_unit_short_metric_ton')}
					</>
				) : (
					<>---</>
				)
			}
			dataTest="total-quantity-field"
		/>
	);
};

export const counterTotalQuantityField = props => <TotalQuantityField {...props} />;
