/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import background_m from 'src/assets/images/background-login-page-m.jpg';
import background_l from 'src/assets/images/background-login-page-l.jpg';
import background_xl from 'src/assets/images/background-login-page-xl.jpg';
import { ReactComponent as LogoSVG } from 'src/assets/images/logo-dark.svg';
import { media } from 'src/_helpers/media-queries';
import { LanguageDropdown as BaseLanguageDropdown } from '../LanguageDropdown/LanguageDropdown';

// This component is used in pages using
// the OnboardingLayout component
// after title
export const OnboardingSeparatorLine = styled.div`
	margin-bottom: 26px;
	width: 100px;
	height: 2px;
	background: var(--call-to-action-gradient);
`;

export const LayoutWrapper = styled.div`
	inset: 0;
	background: #fff;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	overflow-y: auto;
	height: 100dvh;

	${media.s`
		position: absolute;
		background-image: url("${background_m}");
		background-size: cover;
		background-position: top center;
	`}

	${media.l`
		background-image: url("${background_l}");
	`}

	${media.xl`
		background-image: url("${background_xl}");
	`}
`;

export const WhiteBoxWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	min-height: 100%;
	z-index: 5;
	margin: 0 auto;
	width: 476px;
	max-width: 100vw;

	${media.m`
		margin-left: 120px;
		margin-right: 0;
	`}

	${media.ll`
		width: 500px;
	`}

	${media.xl`
		width: 540px;
		margin-left: 180px;
	`}

	.login-form-control {
		> div:first-of-type {
			margin-bottom: 4px;

			span {
				color: var(--login-header-color);
				font-size: 13px;
			}

			${media.xl`
				margin-bottom: 8px;

				span {
					font-size: 14px;
				}
			`};
		}
	}

	.form-control.ui.input {
		> input {
			padding: 14px 16px 15px;
			font-size: 13px;

			${media.xl`
				padding: 20px;
				font-size: 14px;
			`};
		}

		&.is-invalid > input {
			border-color: var(--red-400) !important;
		}
	}
`;

export const WhiteBox = styled.div`
	width: 100%;
	background-color: var(--login-form-color);
	padding: 28px;
	overflow-y: auto;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	${media.s`
		box-shadow: 0px 30px 120px 0px rgba(133, 77, 45, 0.17);
		border-radius: var(--large-border-radius);
	`}

	${media.ll`
		padding: 40px;
	`}

	${media.xl`
		padding: 60px;
	`}

	&.auth-method {
		padding: 64px 60px 40px;
	}
`;

export const Logo = styled(LogoSVG)`
	width: auto;
	height: ${({ $smallLogo }) => ($smallLogo ? '12px' : '20px')};
	margin-bottom: 49px;
`;

export const LanguageDropdown = styled(BaseLanguageDropdown)`
	&.dropdown {
		position: absolute;
		top: 30px;
		right: 68px;
		z-index: 1;
	}
`;
