/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { forwardRef } from 'react';

import NumberFormat from 'react-number-format';

import { TextInput } from '../TextInput/TextInput';

/**
 * @props Combined props of `TextInput` and `NumberFormat` components
 */
export const NumberInput = forwardRef(({ value, onChange, ...restProps }, inputRef) => (
	<NumberFormat
		ref={inputRef}
		thousandSeparator
		fixedDecimalScale
		decimalScale={2}
		allowNegative={false}
		autoComplete="off"
		value={value ?? ''}
		allowedDecimalSeparators={['.', ',']}
		onValueChange={({ floatValue }) =>
			onChange?.(restProps.allowLeadingZero ? floatValue ?? null : floatValue || null)
		}
		{...restProps}
		customInput={TextInput}
	/>
));
