/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useMemo } from 'react';
import { useQueryParams } from 'src/_helpers/router';

export const useChartFilters = () => {
	const { asset } = useQueryParams();
	return useMemo(() => asset && JSON.parse(asset), [asset]);
};
