/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { isNilOrEmpty } from 'src/_helpers';

export const Select = React.forwardRef(({ onChange, ...props }, ref) => {
	const { t } = useTranslation();

	return (
		<Form.Dropdown
			selectOnBlur={false}
			selection
			clearable={!props.required}
			onChange={(ev, data) => {
				onChange(data.value === '' ? null : data.value);
			}}
			{...(isNilOrEmpty(props.value) &&
				isNilOrEmpty(props.placeholder) && {
					text: ' ',
				})}
			noResultsMessage={props.loading ? null : t('no_results_found')}
			{...props}
		/>
	);
});
