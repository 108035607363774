/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { getTrackData } from 'src/_helpers/getTrackData';
import { fetchFromExchangeApi, map404ToEmptyList } from './model';

export const getAnalytics = async query => {
	return fetchFromExchangeApi(`query/analytics`, {
		method: 'GET',
		query: {
			...query,
		},
		mapStatusToResult: map404ToEmptyList,
	});
};

export const trackAnalytics = async body => {
	const track = await getTrackData({ ...body });

	return fetchFromExchangeApi(`track/analytics`, {
		method: 'POST',
		body: {
			...track,
		},
	});
};
