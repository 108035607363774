/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { useMyContactsCountQuery } from 'src/_api/queries';
import { ModalsWrapper } from '../paper/AddUsersToTrade/ModalsWrapper';

export const AddUsersToTradeButton = ({ tradeId, usersAlreadyInTrade }) => {
	const { t } = useTranslation();

	const { data = {}, isLoading } = useMyContactsCountQuery();

	const buttonDisabled = isLoading || data.count === 0;

	return (
		<div className={isLoading ? 'loading' : ''}>
			<ModalsWrapper
				tradeId={tradeId}
				usersAlreadyInTrade={usersAlreadyInTrade}
				trigger={
					<SecondaryButton
						darker
						fixedPadding
						disabled={buttonDisabled}
						className="add-users-to-trade-button"
					>
						{t('add_users')}
					</SecondaryButton>
				}
			/>
		</div>
	);
};
