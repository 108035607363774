/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { media } from 'src/_helpers';

export const ButtonsSectionWrapper = styled.div`
	display: flex;
	margin-left: auto;

	.add-users-to-trade-button {
		width: 100px;

		${media.ll`
			width: 120px;
		`}
	}
`;

export const CopyButton = styled(SecondaryButton)`
	margin-left: 8px;
	width: 100px;

	${media.ll`
		width: 120px;
	`}
`;
