/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';
import { M50 } from 'src/components/Typography';
import { ReactComponent as Info } from 'src/assets/icons/info_24x24.svg';

export const Wrapper = styled.div`
	display: none;

	${media.ll`
		display: flex;
		margin-top: 24px;
		border-radius: var(--bigger-border-radius);
		background: var(--neutral-850);
		color: var(--neutral-200);
		gap: 10px;
		margin-bottom: 32px;
		padding: 20px 16px 20px 16px;
	`}

	${media.xl`
		padding: 20px;
	`}
`;

export const InfoIcon = styled(Info)`
	width: 24px;
	height: 24px;
	flex-shrink: 0;
`;

export const InfoText = styled(M50)`
	flex: 1;
	line-height: 18px;
	font-size: 12px;
`;
