/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import {
	REGISTER_USER_REQUEST,
	REGISTER_USER_SUCCESS,
	REGISTER_USER_ERROR,
	LOGIN_USER_REQUEST,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_ERROR,
	LOGIN_2FA_INCORRECT_CODE,
	LOGIN_SET_SELECTED_2FA_METHOD,
	LOGIN_USER_SHOW_2FA,
	LOGIN_USER_SHOW_TERMS_AND_CONDITIONS,
	LOGOUT_USER_REQUEST,
	LOGOUT_USER_SUCCESS,
	LOGOUT_USER_ERROR,
	RESET_USER_CONFIRM_REQUEST,
	RESET_USER_CONFIRM_SUCCESS,
	RESET_USER_CONFIRM_ERROR,
	USER_IS_NOT_LOGGED_IN,
	IS_USER_LOGGED_IN_REQUEST,
	USER_IS_LOGGED_IN,
	RESET_USER_ERRORS,
	SET_AVATAR_COLOR,
	SET_COMPANY_NAME,
	SET_COMPANY_AVATAR_COLOR,
} from 'src/constants/actionTypes';

// Register
export const registerUserRequest = payload => ({
	type: REGISTER_USER_REQUEST,
	payload,
});

export const registerUserSuccess = payload => ({
	type: REGISTER_USER_SUCCESS,
	payload,
});

export const registerUserError = payload => ({
	type: REGISTER_USER_ERROR,
	payload,
});

// Login
export const loginUserRequest = payload => ({
	type: LOGIN_USER_REQUEST,
	payload,
});

export const setSelected2FAmethod = payload => ({
	type: LOGIN_SET_SELECTED_2FA_METHOD,
	payload,
});

export const loginUserSuccess = payload => ({
	type: LOGIN_USER_SUCCESS,
	payload,
});

export const loginUserError = payload => ({
	type: LOGIN_USER_ERROR,
	payload,
});

export const loginUser2FAError = payload => ({
	type: LOGIN_2FA_INCORRECT_CODE,
	payload,
});

export const loginUserShow2FA = payload => ({
	type: LOGIN_USER_SHOW_2FA,
	payload,
});

export const loginUserShowTermsAndConditions = payload => ({
	type: LOGIN_USER_SHOW_TERMS_AND_CONDITIONS,
	payload,
});

// Logout
export const logoutUserRequest = payload => ({
	type: LOGOUT_USER_REQUEST,
	payload,
});

export const logoutUserSuccess = () => ({
	type: LOGOUT_USER_SUCCESS,
});

export const logoutUserError = payload => ({
	type: LOGOUT_USER_ERROR,
	payload,
});

// Reset confirm password
export const resetUserConfirmRequest = payload => ({
	type: RESET_USER_CONFIRM_REQUEST,
	payload,
});

export const resetUserConfirmSuccess = payload => ({
	type: RESET_USER_CONFIRM_SUCCESS,
	payload,
});

export const resetUserConfirmError = payload => ({
	type: RESET_USER_CONFIRM_ERROR,
	payload,
});

// IsAuth
export const isUserLoggedInRequest = () => ({
	type: IS_USER_LOGGED_IN_REQUEST,
});

export const userIsLoggedIn = payload => ({
	type: USER_IS_LOGGED_IN,
	payload,
});

export const userIsNotLoggedIn = payload => ({
	type: USER_IS_NOT_LOGGED_IN,
	payload,
});

export const resetUserErrors = () => ({
	type: RESET_USER_ERRORS,
});

// Additional user details
export const setUserAvatarColor = payload => ({
	type: SET_AVATAR_COLOR,
	payload,
});

export const setUserCompanyName = payload => ({
	type: SET_COMPANY_NAME,
	payload,
});

export const setUserCompanyAvatarColor = payload => ({
	type: SET_COMPANY_AVATAR_COLOR,
	payload,
});
