/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { KeyDetailsPill } from '../styled';

export const PricePill = styled(KeyDetailsPill)`
	background: var(--drawer-pill-${props => props.$type});
	color: var(--text-for-${props => props.$type});
	white-space: nowrap;

	${({ $isOrderClosed, $type }) =>
		$isOrderClosed &&
		`
		background: var(--${$type}-closed);
	`}

	${({ $isChanged }) =>
		$isChanged &&
		`
		background: var(--primary-800);
		color: var(--text-color-default);
	`}
`;

export const SpreadPill = styled(PricePill)`
	background: var(--neutral-400);
	color: var(--white);

	${({ $isOrderClosed }) =>
		$isOrderClosed &&
		`
		background: var(--neutral-500);
		color: var(--neutral-100);
	`}

	${({ $isChanged }) =>
		$isChanged &&
		`
		background: var(--primary-800);
		color: var(--text-color-default);
	`}
`;

export const PriceDetails = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: flex-end;
	gap: 4px;
`;

export const OrderType = styled.div`
	text-align: right;
`;

export const CurrencyPriceUnitContainer = styled.div`
	display: flex;
`;
