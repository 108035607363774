/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { ROUTES } from 'src/constants/routes';

export const buildHeaderLink = ({ environment, orderId, counterId, circleOutSourceTradeId, t }) => {
	if (circleOutSourceTradeId) {
		return {
			url: `${ROUTES.execution}/trade/${circleOutSourceTradeId}`,
			text: `${t('trade_ref_no')} #${circleOutSourceTradeId}`,
			dataTest: `trade-ref-no-link`,
		};
	} else if (environment && orderId && counterId) {
		return {
			url: `/?orderEnvironment=${environment}&orderId=${orderId}&negotiationId=${counterId}`,
			text: t('negotiation'),
			dataTest: `order-link`,
		};
	}
	return { url: '', text: '', dataTest: '' };
};
