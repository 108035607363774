/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useCallback, useEffect, useRef, useState } from 'react';

export const useIsScrolling = scrollRef => {
	const [isScrollingToElement, setIsScrollingToElement] = useState(false);
	const endScrollingCallback = useRef();

	const handleIsScrolling = useCallback((value, callback) => {
		setIsScrollingToElement(value);
		endScrollingCallback.current = callback;
	}, []);

	useEffect(() => {
		const scrollElement = scrollRef.current;
		let scrollTimer;

		const callback = () => {
			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}

			scrollTimer = setTimeout(() => {
				scrollElement?.removeEventListener('scroll', callback);
				setIsScrollingToElement(false);
				endScrollingCallback.current?.();
			}, 150);
		};

		if (isScrollingToElement) {
			scrollElement?.addEventListener('scroll', callback);
		}

		return () => {
			scrollElement?.removeEventListener('scroll', callback);
			if (scrollTimer) {
				clearTimeout(scrollTimer);
			}
		};
	}, [isScrollingToElement, scrollRef]);

	return { isScrollingToElement, handleIsScrolling };
};
