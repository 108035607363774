/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import * as Styled from './styled';

export const ToggleButton = React.forwardRef(
	(
		{
			className,
			name,
			value,
			options,
			onChange,
			disabled,
			dataTest,
			error,
			buttonSizes = [],
			...props
		},
		ref
	) => {
		return (
			<>
				<input readOnly hidden name={name} value={value || ''} ref={ref} />
				<Styled.ButtonGroup
					toggle
					className={clsx(className, disabled && 'disabled')}
					$isError={error}
				>
					{options.map((option, index) => (
						<Styled.ToggleButton
							key={option.value}
							onClick={() => onChange(option.value)}
							active={value === option.value}
							disabled={disabled}
							$width={buttonSizes?.[index]}
							$disabledValue={value === option.value}
							data-test={dataTest ? `${dataTest}-${index}` : `toggle-button-${index}`}
							fixedPadding
							{...props}
						>
							{option.text}
						</Styled.ToggleButton>
					))}
				</Styled.ButtonGroup>
			</>
		);
	}
);
