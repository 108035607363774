/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { H600 } from 'src/components/Typography';
import { PrimaryButton as PrimaryButtonComponent } from 'src/components/Buttons/PrimaryButton';
import styled from 'styled-components';

export const Container = styled.div`
	padding: 25px 32px;
`;

export const Header = styled(H600)`
	margin: 10px 0;
`;

export const Info = styled.div`
	font-size: 12px;
	color: var(--text-color-secondary);
	margin: 10px 0;
`;

export const PrimaryButton = styled(PrimaryButtonComponent)`
	margin-top: 10px;
`;

export const Error = styled.div`
	margin: 10px 0;
	color: var(--red);
	font-size: 12px;
`;
