/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { queryCompanies } from 'src/_api';
import { setUserCompanyName, setUserCompanyAvatarColor } from 'src/_store/actions';
import { getUserId } from 'src/_store/selectors';

const useCompanyNameLoader = () => {
	const userId = useSelector(getUserId);
	const dispatch = useDispatch();

	useQuery(['companies', { userId }], queryCompanies, {
		onSuccess: companies => {
			dispatch(setUserCompanyName(companies[0]?.name));
			dispatch(setUserCompanyAvatarColor(companies[0]?.avatar_color));
		},
	});
};

export const CompanyNameLoader = () => {
	useCompanyNameLoader();

	return null;
};
