/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const Permissions = {
	ORDER_CREATE: 'ORDER_CREATE',
	ORDER_NEGOTIATION: 'ORDER_NEGOTIATION',
	KYC_REQUEST: 'KYC_REQUEST',
	ORDER_ACTIONS: 'ORDER_ACTIONS',
	TRACK_RECORD_CREATE: 'TRACK_RECORD_CREATE',
	NEWS_CREATE: 'NEWS_CREATE',
	CONTACT_INVITATION_CREATE: 'CONTACT_INVITATION_CREATE',
	KYC_VIEW: 'KYC_VIEW',
	TRACK_RECORD_VIEW: 'TRACK_RECORD_VIEW',
};

export const allPermissions = Object.keys(Permissions);
