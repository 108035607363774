/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DocumentRequestIcon } from 'src/assets/icons/document-request.svg';
import { TradeDocumentType, tTradeDocumentType } from 'src/constants/trade-document-type';
import { Modal, ModalHeaderWrapper, CancelButton } from 'src/components/Modal';
import { ModalSize } from 'src/constants/modal';
import { RadioButton } from 'src/designSystem/Form/RadioButton/RadioButton';
import * as Styled from './styled';

const isFormValid = ({ document_type, notes }) => {
	return !!document_type && (document_type !== TradeDocumentType.OtherDocument || !!notes);
};

export const DocumentUploadModalView = ({ file, onComplete, onSubmit, isLoading }) => {
	const [formState, setFormState] = useState({});
	const isOtherDocument = formState.document_type === TradeDocumentType.OtherDocument;

	const { t } = useTranslation();
	const isValid = isFormValid(formState);

	return (
		<Modal
			data-test="document-upload"
			size={ModalSize.Large}
			close={onComplete}
			isOpen={!!file}
			closeOnDimmerClick={false}
			withCloseIcon
		>
			<ModalHeaderWrapper>
				<Styled.IconWrapper>
					<DocumentRequestIcon />
				</Styled.IconWrapper>
				<Styled.StyledModalTitle>{t('upload_documents')}</Styled.StyledModalTitle>
			</ModalHeaderWrapper>
			<Styled.SelectDocument>{t('select_type_of_document')}</Styled.SelectDocument>
			<Styled.DocumentTypesGrid>
				{Object.values(TradeDocumentType).map(type => (
					<RadioButton
						key={type}
						label={tTradeDocumentType(t, type)}
						name="document-type"
						data-test="document-type"
						value={type}
						checked={formState.document_type === type}
						onChange={() =>
							setFormState(state => ({
								...state,
								notes: '',
								document_type: type,
							}))
						}
					/>
				))}
			</Styled.DocumentTypesGrid>
			{isOtherDocument && (
				<Styled.DocumentTypeInput
					placeholder={t('type_other_document_type')}
					fluid
					value={formState.notes}
					onChange={ev => {
						setFormState(state => ({
							...state,
							notes: ev.target.value,
						}));
					}}
				/>
			)}
			<Styled.StyledButtonsWrapper>
				<CancelButton disabled={isLoading} onClick={onComplete} />
				<PrimaryButton
					disabled={!isValid}
					loading={isLoading}
					data-test="confirm-upload"
					onClick={() => {
						setFormState(state => ({
							...state,
							notes: '',
							document_type: '',
						}));
						onSubmit(formState);
					}}
				>
					{t('upload')}
				</PrimaryButton>
			</Styled.StyledButtonsWrapper>
		</Modal>
	);
};
