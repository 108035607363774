/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useFormContext } from 'react-hook-form';

export const useWatchPhysicalCounterFields = () => {
	const { watch } = useFormContext();

	const volumeValue = watch('volume');
	const productAndTypeValue = watch('productAndType');
	const [productValue, productTypeValue] = productAndTypeValue
		? productAndTypeValue.split('|')
		: [null, null];
	const incoValue = watch('inco');
	const gradeValue = watch('grade');
	const customSpecsValue = watch('customSpecs');
	const portValue = watch('port');
	const dateValue = watch('date');
	const originValue = watch('origin');
	const priceTypeValue = watch('priceType');
	const orderTypeValue = watch('orderType');
	const orderTypeChangedValue = watch('orderTypeChanged');
	const priceIncrementTypeValue = watch('priceIncrementType');
	const priceValue = watch('price');
	const paymentValue = watch('payment');
	const currencyUnitValue = watch('currencyUnit');
	const priceIncrementValue = watch('priceIncrement');
	const priceIncrementBasisShipmentValue = watch('priceIncrementBasisShipment');
	const futuresMonthValues = watch('futuresMonth');
	const cargoTypeValue = watch('cargoType');
	const toleranceValue = watch('tolerance');
	const inspectionValue = watch('inspection');
	const portNames = watch('portNames');
	const portsArray = watch('ports') || [];
	const contractTermsValue = watch('contractTerms');
	const contractNumberValue = watch('contractNumber');
	const performanceBondValue = watch('performanceBond');
	const orderOwnerPrincipalIdValue = watch('order_owner_principal_id');
	const principalIdValue = watch('principalId');
	const presetIDValue = watch('presetID');

	return {
		volumeValue,
		productValue,
		productTypeValue,
		incoValue,
		gradeValue,
		customSpecsValue,
		portValue,
		dateValue,
		originValue,
		priceTypeValue,
		orderTypeValue,
		orderTypeChangedValue,
		priceIncrementTypeValue,
		priceValue,
		paymentValue,
		currencyUnitValue,
		priceIncrementValue,
		priceIncrementBasisShipmentValue,
		futuresMonthValues,
		cargoTypeValue,
		toleranceValue,
		inspectionValue,
		portNames,
		portsArray,
		contractTermsValue,
		contractNumberValue,
		performanceBondValue,
		principalIdValue,
		orderOwnerPrincipalIdValue,
		presetIDValue,
	};
};
