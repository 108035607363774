/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useState, useEffect } from 'react';

export const FooterActiveTabKey = 'exchange-active-tab';
export const storageKeyNamePrefix = 'vosbor-togglable-';

export const FooterTabs = {
	MyOrders: 0,
	MyNegotiations: 1,
	MyTrades: 2,
	MyOrderHistory: 3,
	MyNegotiationHistory: 4,
};

export const isFooterTabActive = id => sessionStorage.getItem(FooterActiveTabKey) === id.toString();

export const useAllOpenMotherPaperOrders = () => {
	const [openStates, setOpenStates] = useState([]);

	useEffect(() => {
		const getOpenStates = () => {
			const openItems = [];

			for (let i = 0; i < sessionStorage.length; i++) {
				const key = sessionStorage.key(i);

				if (key.startsWith(storageKeyNamePrefix)) {
					const value = sessionStorage.getItem(key);

					if (value === 'true') {
						openItems.push(key.replace(storageKeyNamePrefix, ''));
					}
				}
			}

			setOpenStates(openItems);
		};

		getOpenStates();

		const handleStorageChange = () => {
			getOpenStates();
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	return openStates;
};
