/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers/media-queries';
import { encodeContentUrl } from 'src/_helpers/encodeContentUrl';
import { ReactComponent as BaseSearchIcon } from 'src/assets/icons/search.svg';
import { h300, h400 } from 'src/components/Typography/fonts';

export const Wrapper = styled.div`
	width: 100%;
`;

export const Label = styled.label`
	color: var(--text-color-secondary);
	display: block;
	${h300}
	margin: 0 0 6px;
	text-transform: none;

	&.error {
		color: var(--input-error-label-text-color);
	}
`;

export const InputWrapper = styled.div`
	position: relative;

	button {
		position: absolute;
		inset: 0;
		z-index: 1;
		width: 100%;
		cursor: text;
	}

	label {
		position: absolute;
		inset: 0;
		padding: 10px 16px;
		${h400};
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;

		span {
			overflow: hidden;
			text-overflow: ellipsis;

			&.single {
				max-width: calc(100% - 20px);
			}

			&.multi:first-of-type {
				max-width: calc(100% - 45px);
				margin-right: 4px;
			}
		}

		i + span.single {
			max-width: calc(100% - 40px);
		}

		i + .multi:first-of-type {
			max-width: calc(100% - 65px);
		}
	}

	.search & {
		label {
			left: 28px;
		}

		input {
			margin-left: 24px;
		}
	}
`;

const icon = css`
	position: absolute;
	right: 16px;
	z-index: 2;
	cursor: pointer;
`;

export const ClearIcon = styled.div`
	${icon}
	top: 10px;

	${() =>
		encodeContentUrl(
			'<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.7265 4.47376C13.1353 4.04961 13.1229 3.37435 12.6987 2.96552C12.2746 2.55669 11.5993 2.5691 11.1905 2.99324L7.9998 6.30343L4.80909 2.99324C4.40026 2.5691 3.725 2.55669 3.30085 2.96552C2.87671 3.37435 2.8643 4.04961 3.27313 4.47376L6.51828 7.84043L2.96519 11.5266C2.55636 11.9507 2.56877 12.626 2.99291 13.0348C3.41706 13.4436 4.09232 13.4312 4.50115 13.0071L7.9998 9.37743L11.4984 13.0071C11.9073 13.4312 12.5825 13.4436 13.0067 13.0348C13.4308 12.626 13.4432 11.9507 13.0344 11.5266L9.48132 7.84043L12.7265 4.47376Z" fill="white"/></svg>'
		)}
`;

export const SearchIcon = styled(BaseSearchIcon)`
	position: absolute;
	left: 14px;
	top: 12px;
	pointer-events: none;
	z-index: 1;
	color: var(--neutral-300);

	${media.ll`
		top: 14px;
	`}
`;

export const ExpandIcon = styled.div`
	${icon}
	opacity: 0.8;
	pointer-events: none;
	top: 10px;

	${() =>
		encodeContentUrl(
			'<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.29289 5.29289C3.68342 4.90237 4.31658 4.90237 4.70711 5.29289L8 8.58579L11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711L8.70711 10.7071C8.31658 11.0976 7.68342 11.0976 7.29289 10.7071L3.29289 6.70711C2.90237 6.31658 2.90237 5.68342 3.29289 5.29289Z" fill="#757BA9"/></svg>'
		)};

	&.open {
		transform: rotate(180deg) !important;
	}

	${media.ll`
		top: 12px;
	`}
`;

export const ContentWrapper = styled.div`
	position: relative;
`;

export const OptionsWrapper = styled.div`
	position: absolute;
	display: flex;
	min-width: 100%;
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
	z-index: 11;

	.options {
		max-height: 226px;
		min-width: 100%;
		flex-shrink: 1;
		overflow-x: hidden;
		overflow-y: auto;
		background: var(--neutral-700);
		border-radius: var(--large-border-radius);
		margin-top: 2px;
		padding-top: 6px;
	}
`;

export const UpwardOptionsWrapper = styled(OptionsWrapper)`
	transform: translateY(calc(-100% - 46px));
`;

export const OptionWrapper = styled.li`
	display: flex;
	cursor: pointer;
	height: 32px;
	display: flex;
	padding: 10px 16px 11px;
	align-items: center;
	gap: 4px;
	min-width: 100%;

	&.disabled {
		pointer-events: none;
	}

	&:hover {
		background: var(--neutral-600);

		span {
			color: var(--text-color-default);
		}
	}

	span {
		${h300}
		font-size: 13px;
		color: var(--text-color-secondary);
		white-space: nowrap;
		flex: 1;
		user-select: none;
	}

	&.selected {
		background: var(--neutral-600);
		position: relative;

		span {
			color: var(--text-color-default);
			max-width: calc(100% - 70px);
			overflow: hidden;
			text-overflow: ellipsis;

			${media.xl`
				max-width: calc(100% - 50px);
			`}
		}

		&:after {
			position: absolute;
			right: 16px;
			margin-left: auto;

			${() =>
				encodeContentUrl(
					'<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4.6L3.93878 7L9 1" stroke="white" stroke-width="2" stroke-linecap="round" /></svg>'
				)};
		}
	}
`;

export const NoResults = styled.div`
	background: var(--neutral-700);
	border-radius: var(--large-border-radius);
	margin-top: 2px;
`;
