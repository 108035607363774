/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserConfirmRequest, resetUserConfirmError } from 'src/_store/actions';
import { userAuthError, userAuthLoading } from 'src/_store/selectors';
import { PASSWORD_ALREADY_USED } from 'src/_api/errors';
import { ResetPasswordForm } from './ResetPasswordForm';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'src/components/Toast';
import { useTranslation } from 'react-i18next';

export const ResetPassword = ({ email }) => {
	const { t } = useTranslation();
	const [inputs, setInputs] = useState({
		password: '',
		password2: '',
	});
	const dispatch = useDispatch();
	const isLoading = useSelector(userAuthLoading);
	const userError = useSelector(userAuthError);
	const navigate = useNavigate();
	const { addToast } = useToast();

	useEffect(() => {
		if (userError) {
			if (userError === PASSWORD_ALREADY_USED) {
				addToast({
					kind: 'error',
					message: t(PASSWORD_ALREADY_USED),
					topPage: true,
					timeout: 5000,
					embeded: true,
				});
			} else {
				addToast({
					kind: 'error',
					message: t('reset_password_link_has_expired'),
					topPage: true,
					timeout: 5000,
					embeded: true,
				});
			}
			dispatch(resetUserConfirmError({}));
		}
	}, [userError, addToast, t, dispatch]);

	useEffect(() => {
		if (inputs.password.length > 0 && inputs.password === inputs.password2) {
			dispatch(resetUserConfirmError({}));
		}
	}, [inputs, dispatch]);

	const handleInputChange = useCallback(e => {
		const { name, value } = e.target;
		setInputs(inputs => ({ ...inputs, [name]: value }));
	}, []);

	const handleSubmit = useCallback(
		e => {
			if (isLoading) {
				return;
			}

			if (inputs.password && email) {
				dispatch(
					resetUserConfirmRequest({
						email: email,
						password: inputs.password,
						navigate,
						addToast,
						t,
					})
				);
			}
		},
		[dispatch, email, inputs.password, isLoading, navigate, addToast, t]
	);

	const isPasswordAlreadyUsed = userError === PASSWORD_ALREADY_USED;

	return (
		<ResetPasswordForm
			{...inputs}
			email={email}
			isPasswordAlreadyUsed={isPasswordAlreadyUsed}
			onInputChange={handleInputChange}
			onSubmit={handleSubmit}
			isLoading={isLoading}
		/>
	);
};
