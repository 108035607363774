/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { media } from 'src/_helpers';

export const Button = styled(SecondaryButton)`
	width: 120px;
	margin-right: 4px;

	${media.ll`
		margin-right: 12px;
		height: 36px;
	`}

	${media.xl`
		width: 126px;
		height: 40px;
		margin-right: 16px;
	`}
`;
