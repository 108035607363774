/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useQueryClient } from 'react-query';

import { OrderStatus } from 'src/constants/orderStatus';
import { millisecondsRemaining } from 'src/_helpers/date';
import { useUpdateEvery } from 'src/_helpers/useUpdateEvery';
import { useUpdateAtTime } from 'src/_helpers/useUpdateAtTime';
import { useRealTimeUpdates } from './helpers/useRealTimeUpdates';

export const ViewOrderDrawerContext = createContext({
	orderEdited: false,
	orderEditedWithoutNegotiation: false,
	orderWithdrawn: false,
	negotiationsWithdrawn: false,
	negotiationUpdated: false,
	tradeCreated: false,
	setOrderAndNegotiationUpToDate: () => {},
	setAccepteeId: () => {},
	accepteeId: null,
});

const defaultSecondsToUpdate = 30;

export const ViewOrderDrawerProvider = ({ order, negotiation, children }) => {
	const queryClient = useQueryClient();
	const [accepteeId, setAccepteeId] = useState(null);

	const {
		orderEdited,
		orderEditedWithoutNegotiation,
		orderWithdrawn,
		negotiationsWithdrawn,
		negotiationUpdated,
		setOrderAndNegotiationUpToDate,
		tradeCreated,
	} = useRealTimeUpdates({ order, negotiation, accepteeId, setAccepteeId });

	const isOrderExpired = moment().isAfter(order.validity) || order.status === OrderStatus.Expired;
	const msToExpire = isOrderExpired ? 0 : millisecondsRemaining(order.validity);
	const secondsToUpdate = msToExpire > 2 * defaultSecondsToUpdate ? defaultSecondsToUpdate : 0;

	useUpdateEvery(secondsToUpdate * 1000);

	const hasJustExpired = useUpdateAtTime(order.validity);

	useEffect(() => {
		if (hasJustExpired) {
			queryClient.invalidateQueries(['order', order._key]);
		}
	}, [hasJustExpired, queryClient, order._key]);

	return (
		<ViewOrderDrawerContext.Provider
			value={{
				hasOrderExpired: hasJustExpired,
				orderEdited,
				orderEditedWithoutNegotiation,
				orderWithdrawn,
				negotiationsWithdrawn,
				negotiationUpdated,
				tradeCreated,
				setOrderAndNegotiationUpToDate,
				setAccepteeId,
				accepteeId,
			}}
		>
			{children}
		</ViewOrderDrawerContext.Provider>
	);
};

export const useViewOrderDrawerContext = () => useContext(ViewOrderDrawerContext);
