/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Tooltip, TooltipTrigger, TooltipContent } from '../Tooltip/Tooltip';
import { ReactComponent as DeactivatedIcon } from '../../assets/icons/deactivated.svg';
import { UserStatus as UserStatusData } from 'src/constants/user';

export const UserStatus = ({ status }) => {
	const { t } = useTranslation();

	if (status !== UserStatusData.DEACTIVATED) {
		return null;
	}

	return (
		<Tooltip placement="bottom">
			<TooltipTrigger>
				<StyledDeactivatedIcon className="deactivated-icon" data-test="deactivated-icon" />
			</TooltipTrigger>
			<TooltipContent>{t('deactivated')}</TooltipContent>
		</Tooltip>
	);
};

const StyledDeactivatedIcon = styled(DeactivatedIcon)`
	margin: 0 3px;
	min-width: 18px;
	width: 18px;
	min-height: 18px;
	height: 18px;
	color: var(--neutral-300);
	cursor: pointer;
	align-self: baseline;
`;

export const NameContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const FullWidthNameContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	& svg {
		align-self: center;
	}
`;
